import React from 'react';
import { Box } from '@material-ui/core';

import Disclaimer from '../PlansFactory/Disclaimer';

const DisclaimerHOC: React.FC = ({ children }) => {
  return (
    <Box>
      {children}
      <Disclaimer />
    </Box>
  );
};

export default DisclaimerHOC;
