import React, { useState } from 'react';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { bindActionCreators, Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { connect, useSelector } from 'react-redux';
import SectionTitle from 'components/SectionTitle';
import EnrollmentsIssues from 'components/EnrollmentsIssues';
import PlanSummaryModal from 'components/PlanSummaryModal';
import RestartRegistrationModal from 'components/Modal/RestartRegistrationModal';
import EnrollmentStatus from 'enums/enrollmentStatus';
import ExternalRegistrationStep from 'enums/externalRegistrationStep';
import plansDomain from 'api/domains/plans';
import enrollmentsDomain from 'api/domains/enrollments';
import userDomain from 'api/domains/user';
import { loadCurrentUser } from 'redux/sagas/user/routines';
import { AppStoreInterface } from 'store/app';
import { Button, Link } from '@material-ui/core';
import { CarrierLinksInterface } from 'types/user';
import DashboardIssuesSkeleton from './DashboardIssuesSkeleton';
import DashboardPlansSkeleton from './DashboardPlansSkeleton';
import DeclinedPlansTable from './DeclinedPlansTable';
import ElectedPlansTable from './ElectedPlansTable';

import styles from './styles.module.scss';

interface IProps {
  actions: {
    loadCurrentUser: typeof loadCurrentUser.trigger;
  };
}

const getPlansSummary = async () => {
  const { data } = await plansDomain.getPlansSummary();
  return data;
};

const getEnrollmentsIssues = async () => {
  const { data } = await enrollmentsDomain.getEnrollmentsIssues();
  return data;
};

const Dashboard: React.FC<IProps> = ({ actions }) => {
  const [chosenPlanId, setChosenPlanId] = useState<string>();
  const [restartRegistrationModal, setRestartRegistrationModal] = useState(
    false
  );

  const {
    newHire,
    openEnrollmentEndDate,
    eligibilityDate,
    links,
  } = useSelector((state: AppStoreInterface) => state.user);
  const { isLoading: plansSummaryLoading, data: plansSummary } = useQuery(
    'plansSummary',
    getPlansSummary,
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    isLoading: enrollmentsIssuesLoading,
    data: enrollmentsIssues,
  } = useQuery('enrollmentsIssues', getEnrollmentsIssues, {
    refetchOnWindowFocus: false,
  });

  const choosePlan = (planId: string) => {
    setChosenPlanId(planId);
  };

  const handleCardActionClick = async () => {
    try {
      const step = (ExternalRegistrationStep.Enrollments - 1).toString();
      await userDomain.saveRegistrationStep(step);
      actions.loadCurrentUser();
    } catch (e) {
      console.error(e);
    }
  };

  const electedPlans =
    plansSummary &&
    plansSummary.filter((plan) => +plan.status > +EnrollmentStatus.NotSelected);

  const declinedPlans =
    plansSummary &&
    plansSummary.filter((plan) => +plan.status < +EnrollmentStatus.NotSelected);

  const isLoading = plansSummaryLoading || enrollmentsIssuesLoading;
  const isStillOEE =
    openEnrollmentEndDate && !moment().isAfter(openEnrollmentEndDate, 'day');
  const modifyEnrollmentsVisible = newHire || isStillOEE;
  const diffDays = (date: string) => {
    var now = moment(new Date());
    var end = moment(new Date(date));
    var duration = moment.duration(end.diff(now));
    return Math.ceil(duration.asDays());
  };
  const chooseLink = (link: CarrierLinksInterface) => {
    switch (link.planTypeId) {
      case 1:
        return {
          label: 'Medical Directory',
          link: link.medicalDirectory,
          rxLink: link.rxDirectory,
        };
      case 2:
      case 32:
      case 42:
        return {
          label: 'Dental Directory',
          link: link.dentalDirectory,
        };
      case 8:
      case 38:
      case 48:
        return {
          label: 'Vision Directory',
          link: link.visionDirectory,
        };
    }
  };

  const mappedLinks = links?.map((link) => chooseLink(link));
  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Dashboard</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <SectionTitle title="Dashboard" />
        <Grid container className={styles.mainGrid}>
          <Grid sm={12} md={9} item className={styles.plansGrid}>
            <Typography className={styles.tableTitle} variant="subtitle2">
              Benefits Snapshot
            </Typography>
            {isLoading ? (
              <DashboardPlansSkeleton />
            ) : (
              <>
                <Box mb="3em">
                  <ElectedPlansTable
                    plans={electedPlans}
                    onPlanClick={choosePlan}
                  />
                </Box>
                <Box mb="3em">
                  <DeclinedPlansTable plans={declinedPlans} />
                </Box>
              </>
            )}
          </Grid>
          <Grid sm={12} md={3} item>
            {modifyEnrollmentsVisible && (
              <>
                <Typography className={styles.tableTitle} variant="subtitle2" />
                <Box className={styles.modifyEnrollmentContainer}>
                  <Card className={styles.modifyEnrollmentCard}>
                    <Box className={styles.header}>
                      You can still make changes to your benefits
                    </Box>
                    <CardContent className={styles.card}>
                      <Typography variant="body1">
                        {newHire ? (
                          <>
                            <Typography className={styles.mainText}>
                              {+diffDays(eligibilityDate as string) < 1
                                ? 'Today is the last day.'
                                : `You have ${diffDays(
                                    eligibilityDate as string
                                  )} ${
                                    +diffDays(eligibilityDate as string) <= 1
                                      ? 'day'
                                      : 'days'
                                  } left.`}
                            </Typography>
                            <Typography className={styles.secondText}>
                              Please make any changes to your benefits by{' '}
                              {eligibilityDate}.
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography className={styles.mainText}>
                              {+diffDays(openEnrollmentEndDate as string) < 1
                                ? 'Today is the last day.'
                                : `You have ${diffDays(
                                    openEnrollmentEndDate as string
                                  )} ${
                                    +diffDays(
                                      openEnrollmentEndDate as string
                                    ) <= 1
                                      ? 'day'
                                      : 'days'
                                  } left.`}
                            </Typography>
                            <Typography variant="body1">
                              Open Enrollment Ends {openEnrollmentEndDate}
                            </Typography>
                          </>
                        )}
                      </Typography>
                      <Button
                        className={styles.btnEnrollment}
                        variant="contained"
                        color="primary"
                        onClick={() => setRestartRegistrationModal(true)}
                      >
                        Modify Selections
                      </Button>
                    </CardContent>
                  </Card>
                </Box>
                {isLoading ? (
                  <DashboardIssuesSkeleton />
                ) : (
                  <Box mt="2em">
                    <EnrollmentsIssues issues={enrollmentsIssues} />
                  </Box>
                )}
              </>
            )}

            <Typography className={styles.tableTitle} variant="subtitle2" />
            <Box className={styles.modifyEnrollmentContainer}>
              <Card className={styles.modifyEnrollmentCard}>
                <Box className={styles.header}>Carrier links:</Box>
                <CardContent className={styles.card}>
                  <Typography variant="body1">
                    {mappedLinks && mappedLinks.length !== 0 ? (
                      mappedLinks.map((link) =>
                        link && (link.link || link.rxLink) ? (
                          <>
                            <Typography className={styles.mainText}>
                              {link?.label}
                            </Typography>
                            <Link
                              className={styles.linkText}
                              href={link?.link}
                              target="blank"
                            >
                              {
                                link?.link
                                  ?.substring(link.link.indexOf('://') + 3)
                                  .split('/')[0]
                              }
                            </Link>
                            {link?.rxLink && (
                              <>
                                <Typography className={styles.mainText}>
                                  Rx Directory
                                </Typography>
                                <Link
                                  className={styles.linkText}
                                  href={link?.link}
                                  target="blank"
                                >
                                  {
                                    link?.rxLink
                                      .substring(
                                        Number(
                                          String(link?.rxLink).indexOf('://')
                                        ) + 3
                                      )
                                      .split('/')[0]
                                  }
                                </Link>
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      )
                    ) : (
                      <>
                        <Typography className={styles.mainText}>
                          There are no available carrier links.
                        </Typography>
                      </>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
        {!!chosenPlanId && (
          <PlanSummaryModal
            isOpen={!!chosenPlanId}
            planId={chosenPlanId}
            handleClose={() => setChosenPlanId(undefined)}
          />
        )}
        <RestartRegistrationModal
          open={restartRegistrationModal}
          handleClose={() => setRestartRegistrationModal(false)}
          handleSubmit={handleCardActionClick}
        />
      </Box>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      loadCurrentUser: typeof loadCurrentUser.trigger;
    }
  >(
    {
      loadCurrentUser: loadCurrentUser.trigger,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(Dashboard);
