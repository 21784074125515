import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'constants/app';
import { EMPTY_VALUE } from 'constants/placeholders';

export const getDateAndAgeFromString = (
  stringDate: string
): [boolean, Date?, number?] => {
  const date = moment(stringDate, DEFAULT_DATE_FORMAT).toDate();
  if (Number.isNaN(date.valueOf())) {
    return [false];
  }
  return [true, date, moment(new Date()).diff(date, 'years')];
};

export const getDateWithAgeString = (stringDate: string): string => {
  const [isValid, , age] = getDateAndAgeFromString(stringDate);
  if (!isValid) {
    return EMPTY_VALUE;
  }
  return `${stringDate} (age: ${age})`;
};
