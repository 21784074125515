import IBasicOption from 'models/IBasicOption';

export interface AppOptionsInterface {
  smoker: IBasicOption<number>[];
  gender: IBasicOption<number>[];
  state: IBasicOption<string>[];
  securityQuestions: IBasicOption<number>[];
  maritalStatus: IBasicOption<number>[];
  lastNameReason: IBasicOption<number>[];
  relationshipType: IBasicOption<number>[];
  accessGroup: IBasicOption<number>[];
  employmentStatus: IBasicOption<number>[];
  officeLocation: IBasicOption<number>[];
  employmentType: IBasicOption<number>[];
  qualifyingEvent: IBasicOption<number>[];
  logEventType: IBasicOption<number>[];
  planType: IBasicOption<number>[];
  workEligibilityType: IBasicOption<number>[];
  w4FilingStatus: IBasicOption<number>[];
  payrollMethod: IBasicOption<number>[];
  payrollAmountType: IBasicOption<number>[];
  payrollAccountType: IBasicOption<number>[];
  eeoRace: IBasicOption<number>[];
  eeoJobCategory: IBasicOption<number>[];
  eeoMilitaryStatus: IBasicOption<number>[];
  beneficiaryRelationshipType: IBasicOption<number>[];
  beneficiaryType: IBasicOption<number>[];
}

export const initOptionsStore = (): AppOptionsInterface => ({
  gender: [],
  smoker: [],
  state: [],
  securityQuestions: [],
  maritalStatus: [],
  lastNameReason: [],
  relationshipType: [],
  accessGroup: [],
  employmentStatus: [],
  officeLocation: [],
  employmentType: [],
  qualifyingEvent: [],
  logEventType: [],
  planType: [],
  workEligibilityType: [],
  w4FilingStatus: [],
  payrollMethod: [],
  payrollAmountType: [],
  payrollAccountType: [],
  eeoRace: [],
  eeoJobCategory: [],
  eeoMilitaryStatus: [],
  beneficiaryRelationshipType: [],
  beneficiaryType: [],
});
