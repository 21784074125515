import ILoginCredentials from 'models/ILoginCredentials';
import ICreateAccount from 'models/ICreateAccount';
import IShortEmployeeData from 'models/IShortEmployeeData';
import ISecretQuestion from 'models/ISecretQuestion';
import IAuthResponse from 'models/IAuthResponse';
import IZipLookupResponse from 'models/IZipLookupResponse';
import IChangePasswordPayload from 'models/IChangePasswordPayload';
import IChangeTemporaryPassword from 'models/IChangeTemporaryPassword';
import IChangeSecurityQuestion from 'models/IChangeSecurityQuestion';
import AuditLogUserType from 'enums/auditLogUserType';
import { IAuditLogDetails } from 'types/auditLogs';
import {
  IEmployeeBasicData,
  IEmployee,
  IEmployeePersonal,
  IEditEmployeeEndpointFormat,
  UsernameValidation,
  IAddEmployeeEndpointFormat,
  IEmployeeTerminationEndpointPayload,
} from 'types/employee';
import {
  IMessageInfo,
  IPrivacyNotice,
  UpdateUserProfilePayload,
  UserGeneralInfoInterface,
  ISendMessages,
} from 'types/user';
import { DependentInterface } from 'types/dependents';
import api from '../adapter';

export class UserDomain {
  login(body: ILoginCredentials) {
    return api.makeRequest<IAuthResponse>('/login', 'POST', body);
  }

  logout() {
    return api.makeRequest('/logout', 'POST', { test: 'test' }); // To Do - delete test. Currently payload is required.
  }

  loadCurrentUser() {
    return api.makeRequest<UserGeneralInfoInterface>('/me', 'GET');
  }

  changePassword(payload: IChangePasswordPayload) {
    return api.makeRequest('/password', 'POST', payload);
  }

  changeTemporaryPassword(payload: IChangeTemporaryPassword) {
    return api.makeRequest<void>('/auth/set-password', 'POST', payload);
  }

  saveRegistrationStep(step: string) {
    return api.makeRequest('/me', 'PUT', { step });
  }

  forgotPassword(body: { email: string }) {
    return api.makeRequest('/auth/forgot-password', 'POST', body);
  }

  register(body: ICreateAccount) {
    return api.makeRequest<IAuthResponse>('/registration', 'POST', body);
  }

  verifyPassword(password: string) {
    return api.makeRequest<{ success: boolean }>(
      'auth/verify-password',
      'POST',
      { password }
    );
  }

  getEmployeeShortData(token?: string) {
    return api.makeRequest<IShortEmployeeData>(
      token ? `/registration?token=${token}` : '/registration',
      'GET'
    );
  }

  resetPassword(body: { token: string; answer: string; password: string }) {
    return api.makeRequest('/auth/reset-password', 'POST', body);
  }

  getEmployeeData() {
    return api.makeRequest<IEmployee>('/employee', 'GET');
  }

  validateUsername(username: string, employeeId?: string) {
    return api.makeRequest<UsernameValidation>(
      `/employees${
        employeeId ? `/${employeeId}` : ''
      }/validate?username=${username}`,
      'GET'
    );
  }

  getEmployeePersonalData(password: string) {
    return api.makeRequest<IEmployeePersonal>('/employee/personal', 'POST', {
      password,
    });
  }

  getSecretQuestion(body: { token: string }) {
    return api.makeRequest<ISecretQuestion>('/auth/question', 'POST', body);
  }

  updateEmployee(body: UpdateUserProfilePayload) {
    return api.makeRequest<void>('/employee', 'PUT', body);
  }

  zipCodeLookup(zip: string) {
    return api.makeRequest<IZipLookupResponse>(
      '/zip-lookup',
      'GET',
      undefined,
      undefined,
      { zip }
    );
  }

  getEmployeeById(id: string) {
    return api.makeRequest<IEmployee>(`/employees/${id}/employee`, 'GET');
  }

  terminateEmployeeById(body: IEmployeeTerminationEndpointPayload, id: string) {
    return api.makeRequest<IEmployeeTerminationEndpointPayload>(
      `/employees/${id}/terminate`,
      'PUT',
      body
    );
  }

  addEmployee(body: IAddEmployeeEndpointFormat) {
    return api.makeRequest<IEmployee>('/employees', 'POST', body);
  }

  editEmployee(id: string, body: IEditEmployeeEndpointFormat) {
    return api.makeRequest<void>(`/employees/${id}`, 'PUT', body);
  }

  impersonateEmployee(id: string) {
    return api.makeRequest<IAuthResponse>(
      `/employees/${id}/impersonate`,
      'GET'
    );
  }

  checkActivity() {
    return api.makeRequest<{ success: boolean }>('/check', 'GET');
  }

  getPrivacyNoticeData() {
    return api.makeRequest<IPrivacyNotice>('/agency', 'GET');
  }

  getLogDetails(userType: AuditLogUserType, id: string) {
    return api.makeRequest<IAuditLogDetails>(
      `/audits-${userType}/${id}`,
      'GET'
    );
  }

  getDependets() {
    return api.makeRequest<DependentInterface[]>('/dependents', 'GET');
  }

  getEmployees() {
    return api.makeRequest<IEmployeeBasicData[]>('/employees', 'GET');
  }

  getMessageBoard() {
    return api.makeRequest<IMessageInfo>('/message-board', 'GET');
  }

  sendEmails(body: ISendMessages) {
    return api.makeRequest('/send-emails', 'POST', body);
  }

  updateSecurityQuestion(payload: IChangeSecurityQuestion) {
    return api.makeRequest('/security-question', 'POST', payload);
  }

  updatePrivacyPolicy() {
    return api.makeRequest('/update-privacy-policy', 'POST');
  }
}

export default new UserDomain();
