import CaseStyle from 'enums/caseStyle';

export const toCamel = (s: string) => {
  return s.replace(/([_][a-z])/gi, ($1) => $1.toUpperCase().replace('_', ''));
};

export const toSnake = (s: string) => {
  return s.replace(/[\w]([A-Z])/g, (m) => m[0] + '_' + m[1].toLowerCase());
};

const isArray = (a: any) => {
  return Array.isArray(a);
};

const isObject = (o: any) => {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const caseConverter = (val: any, caseStyle: CaseStyle) => {
  if (isObject(val)) {
    const newObj: { [key: string]: any } = {};
    let converter: (s: string) => string;

    switch (caseStyle) {
      case CaseStyle.Camel:
        converter = toCamel;
        break;
      case CaseStyle.Snake:
        converter = toSnake;
        break;
    }

    Object.keys(val).forEach((k) => {
      newObj[converter(k)] = caseConverter(val[k], caseStyle);
    });

    return newObj;
  } else if (isArray(val)) {
    return val.map((item: any) => caseConverter(item, caseStyle));
  }

  return val;
};

export default caseConverter;
