import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IBasicOption from 'models/IBasicOption';
import { DependentInterface, IDependentPersonal } from 'types/dependents';
import DependentCardItem from './DependentCardItem';

import styles from './styles.module.scss';

interface IProps {
  dependents: DependentInterface[] | null;
  gender: IBasicOption<number>[];
  handleHidePersonalData: () => void;
  handleOpenModal: () => void;
  handleShowPersonalData: (password: string) => Promise<void>;
  isPasswordPrompt: boolean;
  personalData: IDependentPersonal[];
  hideSsn: boolean;
}

const DependentCardList: React.FC<IProps> = ({
  dependents,
  gender,
  handleHidePersonalData,
  handleOpenModal,
  handleShowPersonalData,
  isPasswordPrompt,
  personalData,
  hideSsn,
}) => {
  let dependentsWithPersonalData: DependentInterface[] | undefined;

  if (dependents) {
    dependentsWithPersonalData = dependents.map((dependent) => {
      const matchedPersonalData = personalData.find(
        (data) => +data.id === +dependent.id
      );
      return matchedPersonalData
        ? { ...dependent, ...matchedPersonalData }
        : dependent;
    });
  }

  return (
    <Box className={styles.dependentsBox}>
      {dependentsWithPersonalData && dependentsWithPersonalData.length > 0 ? (
        <Grid container spacing={3} className={styles.cardsGridContainer}>
          {dependentsWithPersonalData.map((dependent) => (
            <Grid key={dependent.id} item xs={12} sm={6} lg={6}>
              <DependentCardItem
                dependent={dependent}
                gender={gender}
                handleHidePersonalData={handleHidePersonalData}
                handleOpenModal={handleOpenModal}
                handleShowPersonalData={handleShowPersonalData}
                isPasswordPrompt={isPasswordPrompt}
                hideSsn={hideSsn}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box className={styles.noData}>
          <Typography variant="body1" className={styles.placeholder}>
            This employee doesn't have any dependents
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DependentCardList;
