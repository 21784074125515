import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { bindActionCreators, Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';
import SectionTitle from 'components/SectionTitle';
import ProfileCardList from 'components/ProfileCardList';
import RestartRegistrationModal from 'components/Modal/RestartRegistrationModal';
import OpenEnrollmentsInfo from 'components/OpenEnrollmentsInfo';
import PasswordPrompt from 'components/PasswordPrompt';
import IBasicOption from 'models/IBasicOption';
import userDomain from 'api/domains/user';
import ExternalRegistrationStep from 'enums/externalRegistrationStep';
import { loadCurrentUser } from 'redux/sagas/user/routines';
import { IEmployeePersonal } from 'types/employee';
import { AppStoreInterface } from 'store/app';
import { useQuery } from 'react-query';
import ProfileSkeleton from './ProfileSkeleton';

import styles from './styles.module.scss';

interface IProps {
  maritalStatus: IBasicOption<number>[];
  gender: IBasicOption<number>[];
  employmentStatus: IBasicOption<number>[];
  testUser: string | null;
  impersonatingUserId: string | null;
  newHire: boolean | null;
  openEnrollmentEndDate: string | null;
  eligibilityDate: string | null;
  actions: {
    loadCurrentUser: typeof loadCurrentUser.trigger;
  };
}

const getEmployee = async () => {
  const { data } = await userDomain.getEmployeeData();
  return data;
};

const Profile: React.FC<IProps> = ({
  maritalStatus,
  gender,
  testUser,
  impersonatingUserId,
  newHire,
  openEnrollmentEndDate,
  eligibilityDate,
  employmentStatus,
  actions,
}) => {
  const [restartRegistrationModal, setRestartRegistrationModal] = useState(
    false
  );
  const [passwordPromptOpen, setPasswordPromptOpen] = useState(false);
  const [personalData, setPersonalData] = useState<IEmployeePersonal | null>(
    null
  );
  const { data: employee, status } = useQuery('employee', getEmployee, {
    refetchOnWindowFocus: false,
  });

  const handleShowPersonalData = async (password: string) => {
    try {
      const { data } = await userDomain.getEmployeePersonalData(password);
      setPersonalData(data);
    } catch (err) {
      throw err;
    }
  };

  const handleHidePersonalData = () => {
    setPersonalData(null);
  };

  const handleEditProfileClick = async () => {
    try {
      const step = ExternalRegistrationStep.Account.toString(); // This is a special case since Account step equals 2
      await userDomain.saveRegistrationStep(step);
      actions.loadCurrentUser();
    } catch (e) {
      console.error(e);
    }
  };

  const isPasswordPrompt = !(
    parseInt(testUser as string) || !!impersonatingUserId
  );

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Profile</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <Box className={styles.contentWrapper}>
          <SectionTitle title="My Profile" />
          <Grid container className={styles.mainGrid}>
            <Grid sm={12} md={9} item className={styles.profileGrid}>
              {status === 'loading' ? (
                <ProfileSkeleton />
              ) : (
                <ProfileCardList
                  employee={employee!}
                  personalData={personalData}
                  maritalStatus={maritalStatus}
                  gender={gender}
                  employmentStatus={employmentStatus}
                  handleHidePersonalData={handleHidePersonalData}
                  handleOpenModal={() => setPasswordPromptOpen(true)}
                  handleShowPersonalData={handleShowPersonalData}
                  isPasswordPrompt={isPasswordPrompt}
                />
              )}
            </Grid>
            <Grid sm={12} md={3} item className={styles.infoGrid}>
              <OpenEnrollmentsInfo
                eligibilityDate={eligibilityDate}
                openEnrollmentEndDate={openEnrollmentEndDate}
                newHire={newHire}
                handleOpenConfirmModal={() => setRestartRegistrationModal(true)}
                btnName="Edit Profile"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <PasswordPrompt
          isOpen={passwordPromptOpen}
          handleClose={() => setPasswordPromptOpen(false)}
          onSubmit={handleShowPersonalData}
        />
        <RestartRegistrationModal
          open={restartRegistrationModal}
          handleClose={() => setRestartRegistrationModal(false)}
          handleSubmit={handleEditProfileClick}
        />
      </Box>
    </>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  maritalStatus: store.options.maritalStatus,
  gender: store.options.gender,
  employmentType: store.options.employmentType,
  testUser: store.user.testUser,
  impersonatingUserId: store.user.impersonatingUserId,
  newHire: store.user.newHire,
  openEnrollmentEndDate: store.user.openEnrollmentEndDate,
  eligibilityDate: store.user.eligibilityDate,
  employmentStatus: store.options.employmentStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      loadCurrentUser: typeof loadCurrentUser.trigger;
    }
  >(
    {
      loadCurrentUser: loadCurrentUser.trigger,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
