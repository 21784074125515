import IColorConfiguration from 'models/IColorConfiguration';
import * as actionTypes from './actionTypes';
import { createAction, ActionsUnion } from '../../../helpers/createAction';
import { UserStoreInterface } from '../../../store/user';

export const Actions = {
  addUserData: (payload: UserStoreInterface) =>
    createAction(actionTypes.ADD_USER_DATA, payload),

  editUserData: (payload: Partial<UserStoreInterface>) =>
    createAction(actionTypes.EDIT_USER_DATA, payload),

  clearFeedback: (payload: Partial<UserStoreInterface>) =>
    createAction(actionTypes.CLEAR_FEEDBACK, payload),

  setShowPasswordAskModal: (payload: boolean) =>
    createAction(actionTypes.SHOW_PASSWORD_ASK_MODAL, payload),

  changeColorConfiguration: (payload: IColorConfiguration) =>
    createAction(actionTypes.CHANGE_COLOR_CONFIGURATION, payload),
};

export type ActionsTypes = ActionsUnion<typeof Actions>;
