import React, { useRef, useState, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';

import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import Signature from 'components/Signature';
import StepperButtonsComponent from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import IOnboardingStepProps from 'models/IOnboardingStepProps';
import onboardingDomain from 'api/domains/onboarding';

const AddSignature: React.FC<IOnboardingStepProps> = (props) => {
  const { index, count, moveBack, moveForward, setIsLoading, setError } = props;

  const [signatureStored, setSignatureStored] = useState(false);
  const sigCanvas = useRef<SignaturePad>(null);

  useEffect(() => {
    const handleResize = () => {
      setSignatureStored(false);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const onSaveSignature = async (canvas: HTMLCanvasElement) => {
    try {
      await setIsLoading(true);
      const base64Signature = canvas.toDataURL();
      await onboardingDomain.signature({
        signature: base64Signature,
      });
      await moveForward();
    } catch (e) {
      console.error(e);
      await setIsLoading(false);
      if (setError) {
        setError('Server error!');
      }
    } finally {
      await setIsLoading(false);
    }
  };

  const onSave = () => {
    if (sigCanvas.current) {
      const canvas = sigCanvas.current.getTrimmedCanvas();
      onSaveSignature(canvas);
    }
    return;
  };

  const check = () => {
    if (sigCanvas.current && signatureStored) {
      const canvas = sigCanvas.current?.getTrimmedCanvas();
      return canvas.width === 1 && canvas.height === 1;
    }
    return true;
  };

  return (
    <>
      <ContentTableBox title="Signature" index={index} count={count} isBox>
        <Signature
          sigCanvas={sigCanvas}
          setSignatureStored={setSignatureStored}
        />
      </ContentTableBox>
      <StepperButtonsComponent
        handleNext={onSave}
        handleBack={moveBack}
        disabledNext={check()}
        hideBackBtn={index === 3}
      />
    </>
  );
};

export default AddSignature;
