import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { FiberManualRecordRounded } from '@material-ui/icons';

import styles from './styles.module.scss';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

interface IProps {
  open: boolean;
  autoHideDuration?: number | null;
  severity: AlertProps['severity'];
  feedbackMessage: string;
  clearFeedback: () => void;
  displayInTheMiddle?: boolean;
}

export type FeedbackerSeverity = AlertProps['severity'];

const Feedbacker: React.FC<IProps> = ({
  open,
  autoHideDuration,
  severity,
  feedbackMessage,
  clearFeedback,
  displayInTheMiddle = true,
}) => {
  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    clearFeedback();
  };
  const getStylesBorder = () => {
    switch (severity) {
      case 'success':
        return styles.successBorder;
      case 'error':
        return styles.errorBorder;
      case 'info':
        return styles.infoBorder;
      default:
        return styles.warningBorder;
    }
  };

  return (
    <Snackbar
      className={displayInTheMiddle ? styles.displayInTheMiddle : '-'}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleAlertClose}
    >
      <Alert
        className={`${styles.box} ${getStylesBorder()}`}
        iconMapping={{
          info: <FiberManualRecordRounded style={{ color: '#90B9F5' }} />,
          error: <FiberManualRecordRounded style={{ color: '#E51212' }} />,
          success: <FiberManualRecordRounded style={{ color: '#78B7A3' }} />,
          warning: <FiberManualRecordRounded style={{ color: '#FE805C' }} />,
        }}
        onClose={handleAlertClose}
        severity={severity}
      >
        {feedbackMessage}
      </Alert>
    </Snackbar>
  );
};

export default Feedbacker;
