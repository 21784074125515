import React, { ChangeEvent, useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import {
  makeStyles,
  createStyles,
  Theme,
  InputBase,
  withStyles,
  SvgIcon,
  Box,
} from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';
import { ReactComponent as selectIcon } from 'assets/select.svg';
import { ReactComponent as deleteIcon } from 'assets/delete.svg';

import styles from './styles.module.scss';

interface Props {
  columnDef: any;
  onFilterChanged: Function;
}

const CustomInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '10px',
      width: '100%',
    },
    input: {
      padding: '15px 14px',
      borderRadius: '4px',
      position: 'relative',
      border: '1px solid #E1E2E7',
      '& .Mui-error': {
        '& fieldset': {
          border: '1px solid #E51212',
        },
      },
      '&:focus': {
        background: 'transparent',
        borderRadius: '4px',
        border: '1px solid #4979C0',
      },
      '&:hover': {
        background: 'transparent',
        borderRadius: '4px',
        border: '1px solid #4979C0',
      },
    },
  })
)(InputBase);

const CustomSelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'auto',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#3C4257',
    },
  })
)(Select);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    control: {
      minWidth: '250px !important',
      width: '100%',
    },
    icon: {
      position: 'absolute',
      right: '0',
      marginTop: '10x',
      marginRight: '25px',
      display: 'inline-block',
      width: '15px',
      height: '7px',
      cursor: 'pointer',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      background: 'rgba(144, 185, 245, 0.3)',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#4979C0',
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#252733',
    },
    iconChip: {
      width: '8px',
      height: '8px',
      cursor: 'pointer',
      marginRight: '10px',
    },
    dropdownStyle: {
      minWidth: '150px',
      maxWidth: '200px',
      overflow: 'auto',
      maxHeight: '300px',
      padding: 0,
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '& .MuiList-root': {
        width: 'max-content !important',
        padding: 0,
        overflow: 'auto',
      },

      borderRadius: '4px',
      '& .Mui-selected': {
        background: '#A8E0FF',
      },
    },
    placeholder: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#9FA2B4',
    },
    box: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#252733',
    },
  })
);
const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);

const LookupFilter: React.FC<Props> = ({ columnDef, onFilterChanged }) => {
  const [selectedFilter, setSelectedFilter] = useState([] as Array<any>);
  const classes = useStyles();
  useEffect(() => {
    if (
      columnDef?.tableData?.filterValue !== undefined &&
      columnDef?.tableData?.filterValue !== null
    ) {
      setSelectedFilter(columnDef?.tableData?.filterValue || []);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setSelectedFilter(columnDef?.tableData?.filterValue || []);
    // eslint-disable-next-line
  }, [columnDef?.tableData?.filterValue]);
  const handleDelete = (value: string) => {
    const selected = selectedFilter.filter((v) => v !== value);
    setSelectedFilter(selected);
    onFilterChanged(columnDef?.tableData?.id, selected);
  };
  return (
    <Box className={classes.box}>
      <FormControl
        variant="outlined"
        fullWidth
        style={{ width: '100%' }}
        className={classes.control}
      >
        <CustomSelect
          placeholder="Select"
          variant="outlined"
          multiple
          value={selectedFilter}
          onClose={() => {
            if (columnDef?.filterOnItemSelect !== true)
              onFilterChanged(columnDef?.tableData?.id, selectedFilter);
          }}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            classes: { paper: classes.dropdownStyle },
          }}
          IconComponent={() => (
            <SvgIcon
              className={classes.icon}
              component={selectIcon}
              width="11"
              height="6"
              viewBox="0 0 11 6"
            />
          )}
          onChange={(
            event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
            child: React.ReactNode
          ) => {
            setSelectedFilter(event.target.value as any);
            if (columnDef?.filterOnItemSelect === true)
              onFilterChanged(columnDef?.tableData?.id, event.target.value);
          }}
          input={
            <CustomInput
              id={'select-multiple-checkbox' + String(columnDef?.tableData?.id)}
            />
          }
          renderValue={() => <Box className={classes.placeholder}>Select</Box>}
          displayEmpty
          style={{
            marginTop: 0,
          }}
        >
          {Object.keys(columnDef.lookup).map((key) => (
            <MenuItem key={key} value={key} className={styles.option}>
              <CustomCheckbox
                icon={
                  <SvgIcon
                    component={offIcon}
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                  />
                }
                checkedIcon={
                  <SvgIcon
                    component={onIcon}
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                  />
                }
                checked={selectedFilter.indexOf(key as never) > -1}
              />
              <ListItemText
                className={classes.text}
                primary={columnDef?.lookup[key]}
              />
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
      <Box>
        <div className={classes.chips}>
          {(selectedFilter as Array<any>).map((value) => (
            <Chip
              deleteIcon={
                <SvgIcon
                  className={classes.iconChip}
                  component={deleteIcon}
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                />
              }
              key={value}
              label={columnDef?.lookup[value]}
              className={classes.chip}
              onDelete={() => handleDelete(value)}
            />
          ))}
        </div>
      </Box>
    </Box>
  );
};
export default LookupFilter;
