import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useQuery, useMutation, queryCache } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import Skeleton from 'components/Skeleton';
import SectionTitle from 'components/SectionTitle';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';

import userDomain from 'api/domains/user';
import { ISendMessages } from 'types/user';
import Feedbacker from 'components/Feedbacker';
import IBasicOption from 'models/IBasicOption';
import Input from 'components/Forms/Input/InputComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';

import EmailTable from './EmailTable';
import styles from './styles.module.scss';

const getMessageInfo = async () => {
  const { data } = await userDomain.getMessageBoard();
  return data;
};

const sendEmailsToEmployees = async (emailsData: ISendMessages) => {
  const { data } = await userDomain.sendEmails(emailsData);
  return data;
};
const useStyles = makeStyles({
  tab: {
    '& .MuiTabs-flexContainer': {
      overflow: 'auto',
    },
  },
  indicator: {
    display: 'none',
  },
  flexContainer: {
    overflow: 'auto',
  },
  selected: {
    borderRadius: '4px',
    background: '#d2dcec',
  },
  label: {
    color: '#9FA2B4',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
  },
  labelSelected: {
    color: '#4979C0',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
  },
});
const EmailCommunications: React.FC = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [recipients, setRecipients] = useState(
    [] as { id: string; email: string }[]
  );
  const [subject, setSubject] = useState('');
  // eslint-disable-next-line
  const [error, setError] = useState('');
  const [successFeedback, setSuccessFeedback] = useState<string | null>(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const updateTextDescription = (state: EditorState) => {
    setEditorState(state);
  };
  const { data: messageInfo, isLoading: filesLoading } = useQuery(
    'messageInfo',
    getMessageInfo,
    {
      refetchOnWindowFocus: false,
    }
  );

  const isSelected = (id: string) => {
    return Boolean(recipients.find((recipient) => recipient.id === id));
  };

  const isAlllSelected = () => {
    return recipients.length === messageInfo?.employees.length;
  };

  const onSelectAllClick = () => {
    if (isAlllSelected()) {
      setRecipients([]);
    } else {
      const temp: { id: string; email: string }[] = [];
      messageInfo?.employees.forEach((employee) => {
        temp.push({ id: employee.id, email: employee.email });
      });
      setRecipients(temp);
    }
  };

  const onSelectClick = (id: string) => {
    if (isSelected(id)) {
      const temp = recipients.filter((idEmployee) => idEmployee.id !== id);
      setRecipients(temp);
    } else {
      const employee = messageInfo?.employees.find(
        (employee) => employee.id === id
      );
      setRecipients([...recipients, { id, email: employee?.email }]);
    }
  };

  const [sendEmails] = useMutation(sendEmailsToEmployees, {
    onSuccess: () => {
      queryCache.refetchQueries('messageInfo');
    },
  });

  const onSendEmails = async () => {
    try {
      const emails = {} as { [key: string]: string };
      recipients.forEach((recipient) => {
        emails[recipient.id] = recipient.email;
      });
      await sendEmails({
        recipients: emails,
        emailType: tabValue + 1,
        comment: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        periodId: messageInfo?.openEnrollmentPeriod?.id,
      });
      setSuccessFeedback('Emails sent successfully!');
    } catch (err) {
      setError('Server error');
    }
  };

  const tabs: IBasicOption<React.ReactNode>[] = [
    {
      label: 'Welcome Email',
      value: (
        <EmailTable
          messageData={messageInfo}
          isSelected={isSelected}
          isAllSelected={isAlllSelected}
          onSelectClick={onSelectClick}
          onSelectAllClick={onSelectAllClick}
        />
      ),
    },
    {
      label: 'Open Enrollment Invite',
      value: (
        <EmailTable
          messageData={messageInfo}
          isSelected={isSelected}
          isAllSelected={isAlllSelected}
          onSelectClick={onSelectClick}
          onSelectAllClick={onSelectAllClick}
        />
      ),
    },
    {
      label: 'Custom',
      value: (
        <>
          <Box className={styles.inputWrapper}>
            <Input
              className={styles.input}
              inversePositionIcon
              value={subject}
              name="subject"
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Email Subject"
              label="Email Subject"
            />
          </Box>
          <Box className={styles.editorBlock}>
            <Editor
              toolbarClassName={styles.toolbar}
              editorClassName={styles.editor}
              editorState={editorState}
              onEditorStateChange={updateTextDescription}
            />
          </Box>
          <EmailTable
            messageData={messageInfo}
            isSelected={isSelected}
            isAllSelected={isAlllSelected}
            onSelectClick={onSelectClick}
            onSelectAllClick={onSelectAllClick}
          />
        </>
      ),
    },
  ];

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Communications</title>
      </Helmet>
      <Box className={styles.emailContainer}>
        <SectionTitle title="Communications" />
        {filesLoading ? (
          <Skeleton variant="rect" height="3em" />
        ) : (
          <Box className={styles.pageContainer}>
            <Tabs
              className={classes.tab}
              classes={{
                indicator: classes.indicator,
              }}
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {tabs.map((tab, index) => (
                <Tab
                  classes={{
                    selected: classes.selected,
                  }}
                  key={index}
                  label={
                    <Typography
                      className={
                        index === tabValue
                          ? classes.labelSelected
                          : classes.label
                      }
                    >
                      {tab.label}
                    </Typography>
                  }
                  disabled={index === 1 && !messageInfo?.openEnrollmentPeriod}
                  value={index}
                />
              ))}
            </Tabs>
            <Box mt="1em">
              {messageInfo?.userCounts[2] && (
                <SimpleAlert className={styles.alertMessage} type="error">
                  {messageInfo.userCounts[2] === '1'
                    ? 'There is 1 employee that is '
                    : `There are ${messageInfo.userCounts[2]} employees that are `}
                  not properly set up. They will not get email notifications.
                </SimpleAlert>
              )}
              {tabs[tabValue].value}
              <Box className={styles.btnWrapper}>
                <SubmitButton
                  className={styles.btnAdd}
                  onClick={() => onSendEmails()}
                  label="Send emails"
                />
              </Box>
              <Feedbacker
                open={!!successFeedback}
                autoHideDuration={5000}
                clearFeedback={() => setSuccessFeedback(null)}
                severity="success"
                feedbackMessage={successFeedback as string}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default EmailCommunications;
