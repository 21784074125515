import { DomainTaxWithholdingI, TaxWithholdingI } from 'types/onboarding';

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const convertProps = (data: DomainTaxWithholdingI) => {
  return {
    federalAdditionalWithholding: data.federalAdditionalWithholding,
    federalWithholdingAllowance: data.federalWithholdingAllowance,
    federalFilingStatus: Number(data.federalFilingStatus),
    federalExemption: data.federalExemption === '1' ? true : false,
  };
};

export const convertToDomain = (
  data: TaxWithholdingI
): DomainTaxWithholdingI => {
  return {
    federalAdditionalWithholding: data.federalAdditionalWithholding,
    federalWithholdingAllowance: data.federalWithholdingAllowance,
    federalFilingStatus: String(data.federalFilingStatus),
    federalExemption: data.federalExemption ? '1' : '0',
  };
};
