/* eslint-disable */
import { initUserStore, UserStoreInterface } from 'store/user';
import { login, logout, loadCurrentUser } from 'redux/sagas/user/routines';
import {
  CLEAR_FEEDBACK,
  EDIT_USER_DATA,
  SHOW_PASSWORD_ASK_MODAL,
  CHANGE_COLOR_CONFIGURATION,
} from './actionTypes';

const user = (state = initUserStore(), action: any): UserStoreInterface => {
  switch (action.type) {
    case login.REQUEST:
      return {
        ...state,
        logining: true,
      };
    case login.SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case login.FAILURE:
      return {
        ...state,
        error: action.payload.error as string | null,
      };
    case login.FULFILL:
      return {
        ...state,
        logining: false,
      };
    case loadCurrentUser.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loadCurrentUser.SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case loadCurrentUser.FULFILL:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_FEEDBACK:
      return {
        ...state,
        error: action.payload.error as string | null,
      };
    case EDIT_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SHOW_PASSWORD_ASK_MODAL:
      return {
        ...state,
        passwordAskModalVisible: action.payload,
      };
    case logout.SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
      };
    case CHANGE_COLOR_CONFIGURATION:
      return {
        ...state,
        colorConfiguration: action.payload,
      };
    default:
      return state;
  }
};

export default user;
