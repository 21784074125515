import React, { ChangeEvent } from 'react';

import { IModifiedFormQuestion } from 'types/additionalInfo';
import FormQuestionsFieldType from 'enums/formQuestionsFieldType';
import TextField from './TextField';
import RadioGroupField from './RadioGroupField';
import CheckboxField from './CheckboxField';

interface IProps {
  question: IModifiedFormQuestion;
  mapKey: string;
  index: number;
  error: boolean;
  handleQuestionChange: (
    key: string,
    idx: number,
    value: string | boolean
  ) => void;
  handleErrorCheck: (key: string, idx: number, value: string | boolean) => void;
  helperText?: string;
}

const FieldFactory: React.FC<IProps> = (props) => {
  const {
    question,
    mapKey,
    index,
    handleQuestionChange,
    handleErrorCheck,
    error,
    helperText,
  } = props;

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handleQuestionChange(mapKey, index, value);
    handleErrorCheck(mapKey, index, value);
  };

  const handleTextFieldBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    handleErrorCheck(mapKey, index, value);
  };

  const handleRadioChange = (_: any, value: string) => {
    handleQuestionChange(mapKey, index, value);
    handleErrorCheck(mapKey, index, value);
  };

  const handleCheckboxChange = (_: any, checked: boolean) => {
    handleQuestionChange(mapKey, index, checked);
    handleErrorCheck(mapKey, index, checked);
  };

  const getValue = () => {
    if (question.dependent) {
      return question.dependent.info || '';
    }
    return question.info || '';
  };

  switch (question.fieldType) {
    case FormQuestionsFieldType.Text:
      return (
        <TextField
          label={question.userPrompt}
          value={getValue() as string}
          onChange={handleTextFieldChange}
          onBlur={handleTextFieldBlur}
          error={Boolean(error) ? helperText : ''}
          helperText={helperText}
          typeInput={question.fieldType}
        />
      );
    case FormQuestionsFieldType.Radio:
      return (
        <RadioGroupField
          question={question}
          value={getValue()}
          onChange={handleRadioChange}
          error={error}
          helperText={helperText}
        />
      );
    case FormQuestionsFieldType.CheckBox:
      return (
        <CheckboxField
          checked={!!getValue()}
          onChange={handleCheckboxChange}
          userPrompt={question.userPrompt}
        />
      );
    default:
      return <div>[WIP] FieldType not supported</div>;
  }
};

export default FieldFactory;
