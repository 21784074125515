import * as yup from 'yup';
import { PHONE_REG_EXP, SSN_REG_EXP, ZIP_REG_EXP } from 'constants/regExps';
import userDomain from 'api/domains/user';

export const checkEmailUniqueness = async (value?: string) => {
  const schema = {
    email,
  };

  if (!value || !schema.email.isValidSync(value)) {
    return true;
  }

  try {
    const { data } = await userDomain.validateUsername(value);
    return data.success;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const email = yup.string().email('Please enter a valid email');

export const password = yup
  .string()
  .required('Field is required')
  .min(5, 'Password should contain at least 5 characters')
  .max(20, 'Password should contain maximum of 20 characters');

export const passwordLogin = yup.string().required('Field is required');

export const reEnteredPassword = yup
  .string()
  .oneOf([yup.ref('password')], 'Passwords must match')
  .required('Field is required');

export const phone = yup
  .string()
  .matches(PHONE_REG_EXP, 'Phone number is not valid');

export const ssn = yup
  .string()
  .matches(SSN_REG_EXP, 'Invalid SSN format')
  .notOneOf(['000-00-0000'], 'Invalid SSN format');

export const childSSN = yup
  .string()
  .matches(
    SSN_REG_EXP,
    "If you don't have Social Security Number yet, please enter 000-00-0000"
  );

export const zip = yup.string().matches(ZIP_REG_EXP, 'Invalid ZIP format');

export const date = (typeError?: string) =>
  yup
    .date()
    .typeError(typeError || 'This is not a valid date')
    .required('Field is required')
    .nullable();
