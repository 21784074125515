import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import {
  makeStyles,
  createStyles,
  Theme,
  FormControl,
  withStyles,
  SvgIcon,
  Box,
} from '@material-ui/core';
import { ReactComponent as lookIcon } from 'assets/look.svg';

interface Props {
  columnDef: any;
  onFilterChanged: Function;
}
const CustomTextField = withStyles({
  root: {
    '& .Mui-error': {
      '& .MuiOutlinedInput-input': {
        padding: '17px 14px',
        border: '1px solid #E51212',
        borderRadius: '5px',
        backgroundColor: '#FFE3E3',
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4979C0',
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      '& fieldset': {
        borderColor: '#E1E2E7',
      },
      '&:hover fieldset': {
        borderColor: '#4979C0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #4979C0',
      },
    },
  },
})(TextField);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    control: {
      minWidth: '250px !important',
      width: '100%',
    },
    box: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);
const SearchFilter: React.FC<Props> = ({ columnDef, onFilterChanged }) => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <FormControl
        variant="outlined"
        fullWidth
        style={{ width: '100%' }}
        className={classes.control}
      >
        <CustomTextField
          variant="outlined"
          style={columnDef?.type === 'numeric' ? { float: 'right' } : {}}
          type={columnDef?.type === 'numeric' ? 'number' : 'search'}
          value={columnDef?.tableData?.filterValue || ''}
          placeholder="Filter by"
          onChange={(event) => {
            onFilterChanged(columnDef?.tableData?.id, event?.target?.value);
          }}
          inputProps={{ 'aria-label': `filter data by ${columnDef?.title}` }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Search">
                  <SvgIcon
                    component={lookIcon}
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                  />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Box>
  );
};
export default SearchFilter;
