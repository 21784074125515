import React from 'react';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Box,
  withStyles,
  SvgIcon,
} from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';

import { ReactComponent as helpIcon } from 'assets/infoIcon.svg';

import ContentBox from 'components/ContentBox/ContentBoxComponent';
import PopoverComponent from 'components/Popper/PopoverComponent';
import { CheckboxData } from './types';
import styles from './styles.module.scss';

interface IProps {
  error: boolean;
  handleChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxes: CheckboxData[];
  moreInfo?: string;
}
const CustomFormControlLabel = withStyles({
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
})(FormControlLabel);

const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);
const CheckboxForm: React.FC<IProps> = ({
  error,
  checkboxes,
  handleChange,
}) => {
  return (
    <Box className={styles.checkBox}>
      <FormGroup>
        {error && (
          <FormLabel error={error} component="legend">
            Please select one of the four options*
          </FormLabel>
        )}
        {checkboxes.map((data, i) => (
          <div key={`${data.name}.${i}`} className={styles.checkboxContainer}>
            <CustomFormControlLabel
              control={
                <CustomCheckbox
                  checked={data.checked}
                  onChange={handleChange}
                  name={data.name}
                  icon={
                    <SvgIcon
                      component={offIcon}
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                    />
                  }
                  checkedIcon={
                    <SvgIcon
                      component={onIcon}
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                    />
                  }
                />
              }
              label={data.label}
            />
            {data.moreInfo && (
              <PopoverComponent
                popoverMessage={
                  <ContentBox className={styles.itemPopperMessage}>
                    <p>{data.moreInfo}</p>
                  </ContentBox>
                }
                children={
                  <SvgIcon
                    component={helpIcon}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  />
                }
              />
            )}
          </div>
        ))}
      </FormGroup>
    </Box>
  );
};

export default CheckboxForm;
