import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import RadioButtonsGroup from 'components/Forms/RadioGroup/RadioGroupComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import { IMidyearChangeData } from 'types/enrollments';
import styles from './styles.module.scss';

interface IProps {
  eventType?: string | null;
  setEventType: (reason: string) => void;
  setStep: (data: IMidyearChangeData) => void;
  maritalStatusId: string | number | null;
}

const StepOne: React.FC<IProps> = ({
  eventType,
  setEventType,
  setStep,
  maritalStatusId,
}) => {
  let options = [
    {
      value: '1',
      label: 'My child was born / I adopted a child',
    },
    { value: '2', label: 'I got married' },
    { value: '3', label: 'My spouse lost coverage' },
    { value: '4', label: 'My child lost coverage' },
    { value: '5', label: 'I divorced / legally separated from my spouse' },
    { value: '6', label: 'My spouse has died' },
    {
      value: '7',
      label:
        'My child is no longer considered a dependent because of age, work, or school status',
    },
    { value: '8', label: 'I would like to remove dependents from my benefits' },
    { value: '9', label: 'I would like to decline my coverage' },
  ];
  if (maritalStatusId && +maritalStatusId === 2) {
    options = options.filter((o) => o.value !== '2');
  }
  const onChangeReason = (value: string) => {
    setEventType(value);
    setStep({ step: 2, eventType: value });
  };
  return (
    <Box className={styles.wrapper}>
      <SimpleAlert type="info">
        <Typography className={styles.stepText}>
          You may change some of your benefits as a result of a life event
          without waiting for the next open enrollment period.
        </Typography>
        <Typography className={styles.stepText}>
          Keep in mind that you have 30 days after the life event to request
          enrollment in the benefits.
        </Typography>
      </SimpleAlert>

      <Typography className={styles.stepTextSecondary}>
        To get started, please select what type of event happened in your life:
      </Typography>
      <RadioButtonsGroup
        options={options}
        name="eventType"
        onChange={onChangeReason}
        value={eventType}
      />
    </Box>
  );
};

export default StepOne;
