export const NOT_US_CITIZEN =
  'Noncitizen nationals of the United States are persons born in American Samoa, certain former citizens of the former Trust Territory of the Pacific Islands, and certain children of noncitizen nationals born abroad.';

export const RESIDENT =
  'A lawful permanent resident is any person who is not a U.S. citizen and who resides in the United States under legally recognized and lawfully recorded permanent residence as an immigrant. The term "lawful permanent resident" includes conditional residents.';

export const LEGAL_ALIEN =
  'If you are not a citizen or national of the United States or a lawful permanent resident, but are authorized to work in the United States, check this box.';

export const USCIS_NUMBER =
  'Enter either your Alien Registration Number (A-Number) or USCIS Number. At this time, the USCIS Number is the same as the A-Number without the "A" prefix.';

export const ALIEN_REGISTRATION_NUMBER =
  'Enter your Alien Registration Number (A-Number)/USCIS Number. At this time, the USCIS Number is the same as your A-Number without the "A" prefix. If you have not received an A-Number/USCIS Number, leave this field blank and record your Admission Number.';

export const ADDMISION_NUMBER = `Enter your Admission Number if you have not received an A-Number/USCIS Number. 
  You can find your Admission Number on Form I-94, "Arrival-Departure Record", or as directed by USCIS or U.S. 
  Customs and Border Protection (CBP).`;

export const LEGAL_ALIEN_ADDITIONAL = `If you obtained your admission number from CBP in connection with your
  arrival in the United States, then also enter information about the foreign passport you used to enter the
  United States (number and country of issuance). However, if you obtained your admission number from
  USCIS within the United States, or you entered the United States without a foreign passport, leave these
  fields blank.`;

export const EXPIRATION_DATE = `Enter the date that your employment authorization expires, if any. Aliens
  whose employment authorization does not expire, such as refugees, asylees, and certain citizens of the
  Federated States of Micronesia, the Republic of the Marshall Islands, or Palau, may leave this blank.`;
