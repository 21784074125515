import React from 'react';
import Grid from '@material-ui/core/Grid';

import Skeleton from 'components/Skeleton';

import styles from './styles.module.scss';

const loadingItems = Array(2).fill(true);

const DependentsSkeleton: React.FC = () => {
  return (
    <Grid
      container
      wrap="wrap"
      spacing={3}
      className={styles.cardsGridContainer}
    >
      {loadingItems.map((_, i) => (
        <Grid item xs={3} key={i}>
          <Skeleton variant="rect" height="11.875em" />
        </Grid>
      ))}
    </Grid>
  );
};

export default DependentsSkeleton;
