import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Box } from '@material-ui/core';

import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import StepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';

import ICommonRegistrationStepProps from 'models/ICommonRegistrationStepProps';
import { fromExternalEEOToEEO } from 'mappers/onboarding/fromExternalEEOToEEO';
import IBasicOption from 'models/IBasicOption';
import { AppStoreInterface } from 'store/app';
import { IEEOPayload } from 'types/onboarding';

import {
  eeoJobCategoryOptions,
  eeoMilitaryStatusOptions,
  eeoRaceOptionsOptions,
  genderOptions as genderOptionsRoutin,
} from 'redux/sagas/options/routines';
import onboardingDomain from 'api/domains/onboarding';
import EEOTitle from './EEOTitle';
import EEOFrom from './EEOForm';

import styles from './styles.module.scss';

export interface IEEOActions {
  fetchEeoJobCategoryOptions: typeof eeoJobCategoryOptions.trigger;
  fetchEeoMilitaryStatusOptions: typeof eeoMilitaryStatusOptions.trigger;
  fetchEeoRaceOptionsOptions: typeof eeoRaceOptionsOptions.trigger;
  fetchGenderOptions: typeof genderOptionsRoutin.trigger;
}

export interface IEEOProps extends ICommonRegistrationStepProps {
  actions: IEEOActions;
  eeoJobCategoryOptions: IBasicOption<number>[];
  eeoMilitaryStatusOptions: IBasicOption<number>[];
  eeoRaceOptionsOptions: IBasicOption<number>[];
  genderOptions: IBasicOption<number>[];
}

const EEO: React.FC<IEEOProps> = (props) => {
  const {
    index,
    count,
    moveForward,
    moveBack,
    setIsLoading,
    setError,
    actions,
    eeoJobCategoryOptions,
    eeoMilitaryStatusOptions,
    eeoRaceOptionsOptions,
    genderOptions,
  } = props;

  const [eeo, setNewEEO] = React.useState<IEEOPayload>({
    eeoRace: null,
    eeoMilitaryStatus: null,
    eeoJobCategory: null,
    eeoGender: null,
  });

  React.useEffect(() => {
    actions.fetchEeoJobCategoryOptions();
    actions.fetchEeoMilitaryStatusOptions();
    actions.fetchEeoRaceOptionsOptions();
    actions.fetchGenderOptions();
  }, [actions]);

  React.useEffect(() => {
    setIsLoading(true);
    onboardingDomain
      .getEEO()
      .then(({ data }) => setNewEEO(fromExternalEEOToEEO(data)))
      .catch(() => setError('Error, when trying to fetch eeo info'))
      .finally(() => setIsLoading(false));
  }, [setError, setIsLoading]);

  const handleNext = async () => {
    try {
      setIsLoading(true);
      await onboardingDomain.updateEEO(eeo);
      await moveForward();
    } catch (e) {
      setError('Error, when trying to change EEO');
    } finally {
      setIsLoading(false);
    }
  };

  const renderStepperButtons = () => {
    return (
      <StepperButtons
        handleNext={handleNext}
        handleBack={moveBack}
        hideBackBtn={index === 3}
      />
    );
  };

  return (
    <>
      <ContentTableBox title="EEO" index={index} count={count} isBox>
        <Box className={styles.main}>
          <Box className={styles.eeoContainer}>
            <EEOTitle />
            <EEOFrom
              eeo={eeo}
              genderOptions={genderOptions}
              eeoJobCategoryOptions={eeoJobCategoryOptions}
              eeoMilitaryStatusOptions={eeoMilitaryStatusOptions}
              eeoRaceOptionsOptions={eeoRaceOptionsOptions}
              onChange={setNewEEO}
            />
          </Box>
        </Box>
      </ContentTableBox>
      {renderStepperButtons()}
    </>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  eeoJobCategoryOptions: store.options.eeoJobCategory,
  eeoMilitaryStatusOptions: store.options.eeoMilitaryStatus,
  eeoRaceOptionsOptions: store.options.eeoRace,
  genderOptions: store.options.gender,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<{}, any>(
    {
      fetchEeoJobCategoryOptions: eeoJobCategoryOptions.trigger,
      fetchEeoMilitaryStatusOptions: eeoMilitaryStatusOptions.trigger,
      fetchEeoRaceOptionsOptions: eeoRaceOptionsOptions.trigger,
      fetchGenderOptions: genderOptionsRoutin.trigger,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EEO);
