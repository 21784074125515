import React, { ChangeEvent, FocusEvent } from 'react';
import Box from '@material-ui/core/Box';
import Input from 'components/Forms/Input/InputComponent';

interface IProps {
  label: string;
  helperText?: string;
  error: boolean;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const EmailField: React.FC<IProps> = ({
  label,
  value,
  onChange,
  onBlur,
  helperText,
}) => {
  return (
    <Box mb="1em">
      <Input
        value={value}
        label={label}
        name={label}
        onChange={onChange}
        onBlur={onBlur}
        error={helperText}
      />
    </Box>
  );
};

export default EmailField;
