import React from 'react';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { ReactComponent as datepickerIcon } from 'assets/datepicker.svg';
import { withStyles, SvgIcon } from '@material-ui/core';
import setClassNames from 'classnames';
import { DEFAULT_DATE_FORMAT } from 'constants/app';

import styles from './styles.module.scss';

export interface IDatePickerProps
  extends Pick<
    KeyboardDatePickerProps,
    | 'disableToolbar'
    | 'variant'
    | 'format'
    | 'margin'
    | 'id'
    | 'value'
    | 'onChange'
    | 'onError'
    | 'label'
    | 'name'
    | 'className'
    | 'disabled'
    | 'error'
    | 'helperText'
    | 'minDate'
    | 'maxDate'
    | 'size'
    | 'inputVariant'
    | 'onBlur'
  > {}

const CustomDatePicker = withStyles({
  root: {
    '& .MuiFormHelperText-root.Mui-error': {
      border: 'none',
      borderRadius: '5px',
      backgroundColor: '#ffff',
    },
    '& .Mui-error': {
      border: '1px solid #E51212',
      borderRadius: '5px',
      backgroundColor: '#FFE3E3',
    },
    '& .MuiSelect-underline:after': {
      borderBottomColor: '#4979C0 !important',
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      '& fieldset': {
        borderColor: '#E1E2E7 !important',
      },
      '&:hover fieldset': {
        borderColor: '#4979C0 !important',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #4979C0 !important',
      },
    },
  },
})(KeyboardDatePicker);

const DatePicker: React.FC<IDatePickerProps> = (props) => {
  const classNames = setClassNames(props.className, styles.datepicker);
  return (
    <CustomDatePicker
      autoOk
      variant="inline"
      inputVariant="outlined"
      placeholder="mm/dd/yy"
      keyboardIcon={<SvgIcon component={datepickerIcon} />}
      className={`${styles.datepicker} ${classNames} `}
      PopoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      {...props}
    />
  );
};

DatePicker.defaultProps = {
  value: null,
  disabled: false,
  disableToolbar: true,
  format: DEFAULT_DATE_FORMAT,
  inputVariant: 'outlined',
};

export default DatePicker;
