import IBasicOption from 'models/IBasicOption';
import { IOpenEnrollment } from 'types/statusDashboard';

const fromOpenEnrollmentToBasicOption = (
  openEnrollment: IOpenEnrollment
): IBasicOption<string> => ({
  label: openEnrollment.title,
  value: openEnrollment.id,
});

export default fromOpenEnrollmentToBasicOption;
