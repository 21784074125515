import React, { useState } from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import Input from 'components/Forms/Input/InputComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import plansDomain from 'api/domains/plans';
import getCurrency from 'helpers/getCurrency';
import convertCurrencyToNumber from 'helpers/convertCurrencyToNumber';
import ExternalPlanType from 'enums/externalPlanType';
import { IEnrollment, IUpdateEnrollmentPayload } from 'types/enrollments';
import { AppStoreInterface } from 'store/app';

import styles from './styles.module.scss';

enum ContributionType {
  Self,
  Family,
  Catchup,
}

interface IProps {
  planId: string;
  enrollment?: IEnrollment;
  updateEnrollment: (
    id: string,
    enrollment: IUpdateEnrollmentPayload
  ) => void | Promise<void>;
}

const getMaxContributionAmount = async (_key: string, planId: string) => {
  const { data } = await plansDomain.getMaxContributionAmountHSA(planId);
  return data;
};

const HSAPlanForm: React.FC<IProps> = (props) => {
  const { planId, enrollment, updateEnrollment } = props;
  const {
    userRegistration: {
      profile: { birthDate },
    },
  } = useSelector((state: AppStoreInterface) => state);

  const initialContributionAmount = enrollment
    ? (enrollment.hsaContributionAmount as string)
    : '';

  const [contributionError, setContributionError] = useState('');
  const [contributionAmount, setContributionAmount] = useState(
    initialContributionAmount
  );

  const { isLoading, data: maxContributionAmount } = useQuery(
    ['hsaPlanContributionLimit', planId],
    getMaxContributionAmount,
    { refetchOnWindowFocus: false }
  );

  const handleEmployeeAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    if (maxContributionAmount) {
      const maxAmount = maxContributionAmount.limit;
      const amount = convertCurrencyToNumber(
        maxContributionAmount.contribution
      );
      if (convertCurrencyToNumber(value) > maxAmount) {
        setContributionError(
          `Your contribution amount exceeds annual limit because your employer is contributing $${amount} toward your HSA`
        );
      } else {
        setContributionError('');
      }
    }
    setContributionAmount(value);
  };

  const onSubmit = async () => {
    await updateEnrollment(ExternalPlanType.HSA, {
      planId,
      hsaContributionAmount: contributionAmount,
    });
  };

  const getContribution = (type: ContributionType) => {
    switch (type) {
      case ContributionType.Self:
        return getCurrency(maxContributionAmount?.data.single);
      case ContributionType.Family:
        return getCurrency(maxContributionAmount?.data.family);
      case ContributionType.Catchup:
        return getCurrency(maxContributionAmount?.data.catchup);
    }
  };

  if (isLoading) return null;

  return (
    <Box className={styles.fsaFormContainer}>
      <SimpleAlert
        title={`Your annual contribution limit is ${getCurrency(
          maxContributionAmount?.limit
        )}`}
        type="info"
        className={styles.infoMessage}
      >
        <Box mt="1.5em">
          <Typography variant="subtitle2">
            {maxContributionAmount?.planYear} HSA Annual Contribution Limits:
          </Typography>
          <ul>
            <li>
              Self only:{' '}
              <span className={styles.contributionAmount}>
                {getContribution(ContributionType.Self)}
              </span>
            </li>
            <li>
              Family:{' '}
              <span className={styles.contributionAmount}>
                {getContribution(ContributionType.Family)}
              </span>
            </li>
            <li>
              Catch-up contribution if you are 55 or older:{' '}
              <span className={styles.contributionAmount}>
                {getContribution(ContributionType.Catchup)}
              </span>
            </li>
          </ul>
          <Typography variant="subtitle2">
            Your employer is contributing{' '}
            {getCurrency(maxContributionAmount?.contribution)} into your HSA
            account.
          </Typography>
        </Box>
      </SimpleAlert>
      <Box mt="2em">
        <NumberFormat
          customInput={Input}
          prefix="$"
          variant="outlined"
          fullWidth
          label="Your Annual Contribution Amount"
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator
          value={contributionAmount}
          onChange={handleEmployeeAmountChange}
          error={contributionError}
          helperText={contributionError}
        />
      </Box>
      {moment().diff(birthDate, 'years') >= 65 && (
        <SimpleAlert type="warning">
          If you enroll in Medicare Part A and/or B, you can no longer
          contribute pre-tax dollars to your HSA. However, you may continue to
          withdraw money from your HSA after you enroll in Medicare to help pay
          for medical expenses, such as deductibles, premiums, copayments, and
          coinsurances. If you use the account for qualified medical expenses,
          its funds will continue to be tax-free.
        </SimpleAlert>
      )}
      <Box className={styles.fsaSubmitButtonContainer}>
        <SubmitButton
          disabled={!!contributionError}
          label="Enroll"
          onClick={onSubmit}
        />
      </Box>
    </Box>
  );
};

export default HSAPlanForm;
