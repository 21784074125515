import React, { useRef } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import fileDomain from 'api/domains/file';
import { IEmployeeFile, IShortFile } from 'types/files';

import styles from './styles.module.scss';

interface IProps {
  files?: IShortFile[] | IEmployeeFile[] | null;
  setLoading: (value: React.SetStateAction<boolean>) => void;
  personal?: boolean;
}

const FilesList: React.FC<IProps> = ({ files, setLoading, personal }) => {
  const category = useRef<{ name: string; id: number | null } | null>(null);

  const handleOpenFileInNewTab = async (id: string, title: string) => {
    try {
      setLoading(true);
      const { data } = await fileDomain.downloadFile(id);
      const fileURL = URL.createObjectURL(data);
      let tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', title);
      tempLink.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const categories: Array<{ name: string; id: number | null }> = [];

  const renderCategory = (files: IEmployeeFile[], file: IEmployeeFile) => {
    if (!file.categoryName) return null;
    let shouldRender = false;
    if (files[0].categoryName === 'Other') shouldRender = true;
    if (!category.current) {
      category.current = { name: file.categoryName, id: file.categoryId };
      shouldRender = true;
    } else {
      if (category.current.id !== file.categoryId) {
        category.current = { name: file.categoryName, id: file.categoryId };
        shouldRender = true;
      } else if (
        categories.length === 0 &&
        category.current.id === file.categoryId
      ) {
        shouldRender = true;
      }
    }
    return shouldRender
      ? { name: file.categoryName, id: file.categoryId }
      : null;
  };

  (files as IShortFile[]).forEach((file, i) => {
    const result = renderCategory(
      files as IEmployeeFile[],
      file as IEmployeeFile
    );
    if (result && !categories.includes(result)) categories.push(result);
  });

  const getCategoriesList = (category: {
    name: string;
    id: number | null;
  }): IShortFile[] => {
    return (files as IEmployeeFile[]).filter(
      (file) =>
        file.categoryName === category.name && file.categoryId === category.id
    );
  };
  return (
    <>
      {files && files.length ? (
        !personal ? (
          categories.map((category) => (
            <ContentContainer
              title={
                categories.length === 1 && category.name === 'Other'
                  ? 'Company Files'
                  : category.name
              }
            >
              <TableContainer component={Box}>
                <Table className={styles.filesTable}>
                  <TableBody>
                    {getCategoriesList(category).map((file) => (
                      <TableRow
                        onClick={() =>
                          handleOpenFileInNewTab(file.id, file.title)
                        }
                        key={file.id}
                      >
                        <TableCell />
                        <TableCell component="th" scope="row">
                          {file.title}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </ContentContainer>
          ))
        ) : (
          <ContentContainer title="Personal Files">
            <TableContainer component={Box}>
              <Table className={styles.filesTable}>
                <TableBody>
                  {(files as IShortFile[]).map((file) => (
                    <TableRow
                      onClick={() =>
                        handleOpenFileInNewTab(file.id, file.title)
                      }
                      key={file.id}
                    >
                      <TableCell />
                      <TableCell component="th" scope="row">
                        {file.title}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ContentContainer>
        )
      ) : (
        <Box className={styles.noData}>
          <Typography variant="body1" className={styles.placeholder}>
            No files are available at this time
          </Typography>
        </Box>
      )}
    </>
  );
};

export default FilesList;
