import React from 'react';
import Box from '@material-ui/core/Box';

import Skeleton from 'components/Skeleton';

const loadingItems = Array(2).fill(true);

const DashboardPlansSkeleton: React.FC = () => {
  return (
    <>
      {loadingItems.map((_, i) => (
        <Box mb="1em" key={i}>
          <Skeleton variant="rect" height="17.8em" />
        </Box>
      ))}
    </>
  );
};

export default DashboardPlansSkeleton;
