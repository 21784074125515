import { Query } from 'material-table';

import { toSnake } from 'helpers/caseConverter';
import IPaginatedData from 'models/IPaginatedData';
import AuditLogUserType from 'enums/auditLogUserType';
import { IAuditLog } from 'types/auditLogs';
import api from '../adapter';

export class AuditDomain {
  getList(query: Query<any>, userType: AuditLogUserType) {
    const perPage = query.pageSize;
    const page = query.page + 1;
    const filters = query.filters;
    const orderDirection = query.orderDirection;
    const orderBy = query.orderBy ? toSnake(query.orderBy.field as string) : '';
    let filterParams: { [key: string]: string } = {};

    if (filters) {
      filterParams = filters.reduce((acc, curr) => {
        const value = curr.value;
        return {
          [curr.column.field!]: Array.isArray(value) ? value.join() : value,
          ...acc,
        };
      }, {});
    }

    return api.makeRequest<IPaginatedData<IAuditLog>>(
      `/audits-${userType}`,
      'GET',
      undefined,
      undefined,
      {
        perPage,
        page,
        orderDirection,
        orderBy,
        ...filterParams,
      }
    );
  }
}

export default new AuditDomain();
