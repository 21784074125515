import React from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  SvgIcon,
  withStyles,
} from '@material-ui/core';

import { IOpenEnrollmentsUserInfo } from 'types/statusDashboard';
import { IPlanGroup } from 'types/plans';
import { ReactComponent as enrolledIcon } from 'assets/enrolled.svg';
import ProgressBar from 'components/ProgressBar/ControlledProgressBarComponent';
import styles from './styles.module.scss';

interface IStatusDashboardTableProps {
  planTypes: IPlanGroup[];
  usersData: IOpenEnrollmentsUserInfo[];
}

const LargeFixedCell = withStyles({
  root: {
    minWidth: '170px',
  },
})(TableCell);

const SmallFixedCell = withStyles({
  root: {
    minWidth: '170px',
  },
})(TableCell);

const GreySmallFixedCell = withStyles({
  root: {
    background: '#f6f6f8',
    minWidth: '170px',
  },
})(TableCell);

const getUserFullName = (user: IOpenEnrollmentsUserInfo): string => {
  return `${user.lastName}, ${user.firstName}`;
};

const StatusDashboardTable: React.FC<IStatusDashboardTableProps> = ({
  planTypes,
  usersData,
}) => {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const renderEnrollmentStatusCell = (
    userData: IOpenEnrollmentsUserInfo,
    planTypeId: string
  ) => {
    const enrollmentData = userData.enrollments.find(
      (enrollment) => enrollment.planTypeId === planTypeId
    );
    if (!enrollmentData || !enrollmentData.available) {
      return <GreySmallFixedCell />;
    }
    return <SmallFixedCell>{enrollmentData.status || ''}</SmallFixedCell>;
  };

  /*
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, usersData.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  */

  return (
    <Box className={styles.dashboardTableContainer}>
      <TableContainer>
        <Table className={styles.statusDashboardTable} size="small">
          <TableHead>
            <TableRow>
              <LargeFixedCell className={styles.freezeFirstColumn}>
                Employee Name
              </LargeFixedCell>
              <LargeFixedCell className={styles.freezeSecondColumn}>
                Completion
              </LargeFixedCell>
              <SmallFixedCell>Signature</SmallFixedCell>
              {planTypes.map((planType) => (
                <SmallFixedCell>{planType.title}</SmallFixedCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {usersData
              .sort((a, b) => (a.lastName > b.lastName ? 1 : -1))
              .map((userData) => (
                <TableRow>
                  <LargeFixedCell className={styles.freezeFirstColumn}>
                    {getUserFullName(userData)}
                  </LargeFixedCell>
                  {userData.signatureRequired ? (
                    <LargeFixedCell className={styles.freezeSecondColumn}>
                      <Box className={styles.cellStatus}>
                        <ProgressBar
                          max={100}
                          value={userData.progress}
                          fixedHeight
                        />
                      </Box>
                    </LargeFixedCell>
                  ) : (
                    <GreySmallFixedCell></GreySmallFixedCell>
                  )}
                  {userData.signatureRequired ? (
                    <SmallFixedCell>
                      <Box className={styles.cellSignature}>
                        {userData.signature && (
                          <SvgIcon
                            component={enrolledIcon}
                            className={styles.enrolledIcon}
                          />
                        )}
                      </Box>
                    </SmallFixedCell>
                  ) : (
                    <GreySmallFixedCell></GreySmallFixedCell>
                  )}
                  {planTypes.map((planType) =>
                    renderEnrollmentStatusCell(userData, planType.id)
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StatusDashboardTable;
