import ApiError from 'enums/apiError';
import { PartialUserAccount, PartialUserProfile } from 'types/user';
import { DependentInterface } from 'types/dependents';
import { IEnrollment } from 'types/enrollments';
import { IFullPlan } from 'types/plans';
import { BeneficiaryInterface } from 'types/beneficiaries';

export interface UserRegistrationStoreInterface {
  account: PartialUserAccount;
  profile: PartialUserProfile;
  dependents: DependentInterface[];
  plans: IFullPlan[];
  enrollments: IEnrollment[];
  beneficiaries: BeneficiaryInterface[];
  registrationTokenError: ApiError | null;
  enrollmentsIsLoading: boolean;
  plansIsLoading: boolean;
  profileIsLoading: boolean;
  loading: boolean;
}

export const initUserRegistrationStore = (): UserRegistrationStoreInterface => ({
  account: {},
  profile: {},
  dependents: [],
  plans: [],
  enrollments: [],
  beneficiaries: [],
  loading: true,
  registrationTokenError: null,
  enrollmentsIsLoading: false,
  plansIsLoading: false,
  profileIsLoading: false,
});
