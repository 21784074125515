import { IAction, IActionPayload } from 'types/actions';
import api from '../adapter';

class ActionCenterDomain {
  private getQueryParams(
    employeeId?: string | number,
    planTypeId?: string | number
  ): string {
    if (!employeeId && !planTypeId) return '';
    let queryParams = '?';
    if (employeeId) queryParams += `employeeId=${employeeId}`;
    if (planTypeId) queryParams += `&planTypeId=${planTypeId}`;
    return queryParams;
  }

  getList(employeeId?: string | number, planTypeId?: string | number) {
    return api.makeRequest<IAction[]>(
      `/actions?${this.getQueryParams(employeeId, planTypeId)}`,
      'GET'
    );
  }

  approve(payload: Partial<IActionPayload>) {
    return api.makeRequest<void>('/actions', 'POST', payload);
  }
}

export default new ActionCenterDomain();
