import React from 'react';
import { TextField, TextFieldProps } from 'formik-material-ui';
import {
  FormLabel,
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  SvgIcon,
} from '@material-ui/core';
import { ReactComponent as selectIcon } from 'assets/select.svg';

const CustomTextField = withStyles({
  root: {
    '& ::placeholder': {
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#9FA2B4',
      opacity: 0.8,
    },
    marginTop: '10px',
    '& .MuiFormHelperText-root.Mui-error': {
      border: 'none',
      borderRadius: '5px',
      backgroundColor: '#ffff',
    },
    '& .Mui-error': {
      border: '1px solid #E51212',
      borderRadius: '5px',
      backgroundColor: '#FFE3E3',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4979C0',
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      '& fieldset': {
        borderColor: '#E1E2E7',
      },
      '&:hover fieldset': {
        borderColor: '#4979C0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #4979C0',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '16px',
      color: '#252733',
    },
    control: {
      marginBottom: '10px',
    },
    errorInput: {
      '& .MuiSelect-outlined': {
        border: '1px solid #E51212',
        background: '#FFE3E3',
      },
    },
    icon: {
      position: 'absolute',
      right: '0',
      marginTop: '10x',
      marginRight: '25px',
      display: 'inline-block',
      width: '15px',
      height: '7px',
      cursor: 'pointer',
    },
    dropdownStyle: {
      maxHeight: '150px',
      '& .MuiList-padding': {
        padding: 0,
      },
      borderRadius: '4px',
      '& .Mui-selected': {
        borderRadius: '4px',
        background: '#A8E0FF',
      },
    },
  })
);

const FormikInput: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles();
  const { select, label, ...other } = props;
  return (
    <>
      <FormLabel component="legend" className={classes.label}>
        {label}
      </FormLabel>
      <CustomTextField
        variant="outlined"
        placeholder={`Enter ${label}`}
        select={select}
        SelectProps={{
          MenuProps: {
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            classes: { paper: classes.dropdownStyle },
          },
          displayEmpty: true,
          placeholder: 'Enter',
          IconComponent: () => (
            <SvgIcon
              className={classes.icon}
              component={selectIcon}
              width="11"
              height="6"
              viewBox="0 0 11 6"
            />
          ),
        }}
        {...other}
      />
    </>
  );
};

export default FormikInput;
