import moment from 'moment';
import IBasicOption from 'models/IBasicOption';
import { DependentInterface } from 'types/dependents';
import { DEFAULT_DATE_FORMAT } from 'constants/app';

const getInitialValues = (
  genderOptions: IBasicOption<number>[],
  smokerOptions: IBasicOption<number>[],
  relationshipOptions: IBasicOption<number>[],
  childHaveAnotherLastNameReasons: IBasicOption<number>[],
  requireSsn: string | null,
  userLastName?: string,
  dependent?: DependentInterface
) => {
  const smokerRes =
    dependent &&
    smokerOptions.find(
      (option) => String(option.value) === String(dependent.smoker)
    )?.value;
  return {
    userLastName,
    firstName: dependent?.firstName || '',
    middleName: dependent?.middleName || '',
    lastName: dependent?.lastName || '',
    ssn: dependent?.ssn || '',
    email: dependent?.email || '',
    relationshipTypeId:
      (dependent &&
        relationshipOptions.find(
          (relation) =>
            relation.value === +(dependent.relationshipTypeId as string)
        )?.value) ||
      '',
    lastNameReasonId:
      (dependent &&
        childHaveAnotherLastNameReasons.find(
          (reason) => reason.value === +(dependent.lastNameReasonId as string)
        )?.value) ||
      '',
    gender:
      (dependent &&
        genderOptions.find(
          (option) => +option.value === +(dependent.gender as string)
        )?.value) ||
      '',
    smoker: smokerRes === 0 || smokerRes ? smokerRes : '',
    birthDate: dependent
      ? typeof dependent.birthDate === 'string'
        ? moment(dependent.birthDate, DEFAULT_DATE_FORMAT).toDate()
        : dependent.birthDate
      : null,
    disabled: Boolean(dependent?.disabled && Number(dependent?.disabled) === 1),
    requireSsn: Boolean(requireSsn && +requireSsn),
  };
};

export default getInitialValues;
