import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators, Dispatch } from 'redux';
import { Add } from '@material-ui/icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import IBasicOption from 'models/IBasicOption';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { isEqual } from 'lodash';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';
import { Actions as RegistrationActions } from 'redux/reducers/userRegistration/actions';
import { Actions as UserActions } from 'redux/reducers/user/actions';
import { AppStoreInterface } from 'store/app';
import ExternalRegistrationStep from 'enums/externalRegistrationStep';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import DependentsList from 'components/DependentList/DependentsList';
import StepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import ConfirmationModalWithCancel from 'components/Modal/ConfirmationModalWithCancel';
import CheckBox from 'components/Forms/Checkbox/CheckboxComponent';
import Feedbacker, { FeedbackerSeverity } from 'components/Feedbacker';
import { loadUserDependents } from 'redux/sagas/userRegistration/routines';

import { fromDependentToCreateDependent } from 'mappers/dependents/fromDependentToCreateDependent';
import { fromDependentToUpdateDependent } from 'mappers/dependents/fromDependentToUpdateDependent';
import { fromDependentToDependentAddress } from 'mappers/dependents/fromDependentToDependentAddress';
import { fromUserProfileToUserAddress } from 'mappers/employee/fromProfileInterfaceToAddress';

import { DEPENDENTS_SETTINGS_ALERT } from 'constants/messages';
import dependentsDomain from 'api/domains/dependents';
import ICommonRegistrationStepProps from 'models/ICommonRegistrationStepProps';
import {
  DependentInterface,
  DependentInterfaceWithoutId,
  DependentAddressInterface,
} from 'types/dependents';
import { UserAddress } from 'types/user';
import ssnToMaskedSsn from 'helpers/ssnToMaskedSnn';
import { validateDependentsFields } from 'helpers/validateDependents';
import { withStyles } from '@material-ui/core';
import DependentAddressForm from './DependentAddressForm';
import AddDependentsModal from './AddDependentModal';
import DependentsSkeleton from './DependentsSkeleton';
import styles from './styles.module.scss';

interface DependentsSettingsPropsFromStateInterface {
  actions: {
    createDependent: typeof RegistrationActions.createDependent;
    editDependent: typeof RegistrationActions.editDependent;
    deleteDependent: typeof RegistrationActions.deleteDependent;
    loadUserDependents: typeof loadUserDependents.trigger;
    editUserData: typeof UserActions.editUserData;
  };
  userAddress: UserAddress;
  userFirstName?: string;
  userLastName?: string;
  dependents: DependentInterface[];
  dependentLoading: boolean;
  requireSsn: string | null;
  stateOptions: IBasicOption<string>[];
}

export interface DependentsSettingsStateInterface {
  showAddDependentsModal: boolean;
  dependentIdToDelete?: string;
  editedDependentId?: string;
  ssnEditable: boolean;
  dependentsSameAddress: boolean;
  expandedFormDependentIds: string[];
  feedbackerMessage?: string;
  feedbackerStatus?: FeedbackerSeverity;
  dependentsDifferentAddresses: {
    [dependentId: string]: DependentAddressInterface;
  };
}
const CustomAccordion = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(MuiCheckbox);

export type DependentsAllProps = ICommonRegistrationStepProps &
  DependentsSettingsPropsFromStateInterface;

class DependentsSettings extends React.Component<
  DependentsAllProps,
  DependentsSettingsStateInterface
> {
  constructor(props: DependentsAllProps) {
    super(props);

    this.state = {
      showAddDependentsModal: false,
      ssnEditable: false,
      dependentsSameAddress: false,
      expandedFormDependentIds: [],
      dependentsDifferentAddresses: {},
    };
  }

  componentDidMount() {
    this.props.actions.loadUserDependents();
    this.setDependentsSameAddress();
    this.setDependentsDifferentAddresses();
  }

  componentDidUpdate(prevProps: DependentsAllProps) {
    if (!isEqual(prevProps.dependents, this.props.dependents)) {
      this.setDependentsSameAddress();
      this.setDependentsDifferentAddresses();
    }
  }

  setDependentsDifferentAddresses = () => {
    const dependentsDifferentAddresses: {
      [dependentId: string]: DependentAddressInterface;
    } = {};

    this.props.dependents.forEach((dependent) => {
      if (dependent.dependentDifferentAddress) {
        dependentsDifferentAddresses[dependent.id] =
          fromDependentToDependentAddress(dependent);
      }
    });

    this.setState({
      dependentsDifferentAddresses,
      expandedFormDependentIds: Object.keys(dependentsDifferentAddresses),
    });
  };

  setDependentsSameAddress = () => {
    this.setState({
      dependentsSameAddress: !this.isSomeDependentHaveDifferentAddress(),
    });
  };

  isSomeDependentHaveDifferentAddress = () => {
    return this.props.dependents.some(
      (dependent) => dependent.dependentDifferentAddress
    );
  };

  onChangeDependentAddress = (dependentAddress: DependentAddressInterface) => {
    this.setState((prevState) => ({
      dependentsDifferentAddresses: {
        ...prevState.dependentsDifferentAddresses,
        [dependentAddress.id]: dependentAddress,
      },
    }));
  };

  onDeclineDependentDifferentAddress = (dependentId: string | number) => {
    const dependentsDifferentAddresses = {
      ...this.state.dependentsDifferentAddresses,
    };
    delete dependentsDifferentAddresses[dependentId];
    this.setState((prevState) => ({
      dependentsDifferentAddresses,
      expandedFormDependentIds: prevState.expandedFormDependentIds.filter(
        (id) => id !== dependentId.toString()
      ),
    }));
  };

  submitDependentAddresses = async () => {
    const addresses = this.props.dependents.map((dependent) => {
      return (
        this.state.dependentsDifferentAddresses[dependent.id] ||
        this.setUserAddressForDependent(dependent)
      );
    });
    await this.apiAction(async () => {
      await dependentsDomain.updateDependentsAddresses(addresses);
    }, 'Cannot update dependents addresses');
  };

  setUserAddressForDependent = (
    dependent: DependentInterface
  ): DependentAddressInterface => ({
    id: dependent.id,
    dependentDifferentAddress: false,
    address1: this.props.userAddress.address1,
    address2: this.props.userAddress.address2,
    state: this.props.userAddress.state,
    city: this.props.userAddress.city,
    zip: this.props.userAddress.zip,
  });

  clearFeedbacker = () => {
    this.setState({
      feedbackerMessage: undefined,
      feedbackerStatus: undefined,
    });
  };

  setFeedbackerMessage = (
    feedbackerMessage: string,
    feedbackerStatus: FeedbackerSeverity
  ) => {
    this.setState({ feedbackerMessage, feedbackerStatus }, () => {
      setTimeout(this.clearFeedbacker, 5000);
    });
  };

  async apiAction(action: () => Promise<void>, errorMessage: string) {
    const { setIsLoading, setError } = this.props;
    try {
      await setIsLoading(true);
      await action();
    } catch (e) {
      setError(errorMessage);
      console.error(e);
    } finally {
      await setIsLoading(false);
    }
  }

  deleteDependent = async () => {
    const { dependentIdToDelete } = this.state;
    if (dependentIdToDelete) {
      await this.apiAction(async () => {
        await dependentsDomain.delete(dependentIdToDelete);
        this.props.actions.deleteDependent(dependentIdToDelete);
      }, 'Error, when trying to delete dependent');
    }
    this.setState({ dependentIdToDelete: undefined });
  };

  showDeleteConfirmation = (id: string) => {
    this.setState({ dependentIdToDelete: id });
  };

  changeDependentsSameAddress = (dependentsSameAddress: boolean) => {
    if (dependentsSameAddress) {
      this.setState({
        ...this.state,
        dependentsSameAddress,
        expandedFormDependentIds: [],
        dependentsDifferentAddresses: {},
      });
    } else {
      this.setState({ ...this.state, dependentsSameAddress });
    }
  };

  createDependent = async (dependent: DependentInterfaceWithoutId) => {
    await this.apiAction(async () => {
      const { data } = await dependentsDomain.create(
        fromDependentToCreateDependent(dependent)
      );

      this.props.actions.createDependent(
        Object.assign(dependent, {
          id: data.id,
          individualId: data.individualId,
          ssn: ssnToMaskedSsn(data.ssn),
        })
      );
    }, 'Error, when trying to create dependent');
  };

  editDependent = async (dependent: DependentInterface) => {
    await this.apiAction(async () => {
      await dependentsDomain.update(
        dependent.id,
        fromDependentToUpdateDependent(dependent)
      );
      this.props.actions.editDependent({
        ...dependent,
        ssn: ssnToMaskedSsn(dependent.ssn),
      });
    }, 'Error, when trying to update dependent');
  };

  addDependent = async (
    dependent: DependentInterface | DependentInterfaceWithoutId
  ) => {
    this.setShowAddDependentsModal(false);
    if (!(dependent as DependentInterface).id) {
      await this.createDependent(dependent);
    } else {
      await this.editDependent(dependent as DependentInterface);
    }
  };

  onEditDependent = (id: string) => {
    this.setState({ editedDependentId: id }, () =>
      this.setShowAddDependentsModal(true)
    );
  };

  onAddDependentClick = () => {
    this.setState({ editedDependentId: undefined }, () =>
      this.setShowAddDependentsModal(true)
    );
  };

  handleModalClose = () => {
    this.setState({ showAddDependentsModal: false, ssnEditable: false });
  };

  toggleSsnEditableWithCb = (cb: (ssnEditable: boolean) => void) => {
    this.setState(
      (prevState) => ({ ssnEditable: !prevState.ssnEditable }),
      () => {
        cb(this.state.ssnEditable);
      }
    );
  };

  isSsnRequired = (): boolean => {
    const { requireSsn } = this.props;
    return Boolean(requireSsn && +requireSsn);
  };

  isAllDependentsValid = () => {
    const ssnRequired = this.isSsnRequired();
    return this.props.dependents.every((dependent) => {
      const { isValid } = validateDependentsFields(dependent, ssnRequired);
      return isValid;
    });
  };

  expandDependentAnotherHomeAddress = (dependentId: string) => {
    this.setState((prevState) => ({
      expandedFormDependentIds: [
        ...prevState.expandedFormDependentIds,
        dependentId,
      ],
    }));
  };

  unExpandDependentAnotherHomeAddress = (dependentId: string) => {
    this.setState((prevState) => {
      const newExpandedFormDependentIds =
        prevState.expandedFormDependentIds.filter((id) => id !== dependentId);
      delete prevState.dependentsDifferentAddresses[dependentId];
      return {
        expandedFormDependentIds: newExpandedFormDependentIds,
        dependentsDifferentAddresses: {
          ...prevState.dependentsDifferentAddresses,
        },
        dependentsSameAddress: !newExpandedFormDependentIds.length,
      };
    });
  };

  handleExpandDependentForm = (dependentId: string) => {
    return (event: any, expanded: boolean) => {
      if (expanded) {
        return this.expandDependentAnotherHomeAddress(dependentId);
      }
      this.unExpandDependentAnotherHomeAddress(dependentId);
    };
  };

  onDependentsAddressChangeFail = () => {
    this.setFeedbackerMessage('Server error', 'error');
  };

  setShowAddDependentsModal = (showAddDependentsModal: boolean) =>
    this.setState({ showAddDependentsModal, ssnEditable: false });

  getDescription = () => {
    return DEPENDENTS_SETTINGS_ALERT(this.props.userFirstName);
  };

  onNextClick = async () => {
    const { dependents, moveForward, actions } = this.props;

    if (dependents.length) {
      await this.submitDependentAddresses();
    }
    await moveForward();
    actions.editUserData({
      registrationStep: ExternalRegistrationStep.Dependents.toString(),
    });
  };

  renderStepperButtons = () => {
    return (
      <StepperButtons
        handleNext={this.onNextClick}
        handleBack={this.props.moveBack}
        disabledNext={!this.isAllDependentsValid()}
      />
    );
  };

  renderErrorMessage = () => {
    const ssnRequired = this.isSsnRequired();
    const wrongDependents = this.props.dependents.filter((dependent) => {
      const { isValid } = validateDependentsFields(dependent, ssnRequired);
      return !isValid;
    });

    if (!wrongDependents.length) return null;
    const wrongDependentsNames = wrongDependents.map(
      (dependent) => `${dependent.firstName} ${dependent.lastName}`
    );

    const singularOrPlural =
      wrongDependentsNames.length > 1 ? 'dependents' : 'dependent';

    return (
      <SimpleAlert
        message={`The information about your ${singularOrPlural} ${wrongDependentsNames.join(
          ', '
        )} is not complete.
        Please fill in all required fields in order to continue.`}
        type="error"
        className={styles.marginBottom}
      />
    );
  };

  renderDifferentAddressesExpandedForms = () => {
    if (this.state.dependentsSameAddress) return null;

    return (
      <Box margin="1rem 0">
        <SimpleAlert
          type="info"
          title="Select dependents that have different home address"
          className={styles.marginBottom}
        />
        <Box className={styles.addressBox}>
          {this.props.dependents.map((dependent) => {
            const isExpanded = !!this.state.expandedFormDependentIds.find(
              (id) => id === dependent.id
            );
            return (
              <CustomAccordion
                elevation={0}
                key={dependent.id}
                expanded={isExpanded}
                onChange={this.handleExpandDependentForm(dependent.id)}
              >
                <AccordionSummary
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                  className={styles.accordionSummaryContent}
                >
                  <Box display="flex" alignItems="center">
                    <CustomCheckbox
                      icon={
                        <SvgIcon
                          component={offIcon}
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                        />
                      }
                      checkedIcon={
                        <SvgIcon
                          component={onIcon}
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                        />
                      }
                      checked={
                        !!this.state.dependentsDifferentAddresses[
                          dependent.id
                        ] || isExpanded
                      }
                    />
                    <Typography
                      className={styles.labelCheckbox}
                    >{`${dependent.firstName} ${dependent.lastName}`}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails className={styles.details}>
                  <DependentAddressForm
                    className={styles.dependentAddressForm}
                    dependentAddress={
                      this.state.dependentsDifferentAddresses[dependent.id] ||
                      fromDependentToDependentAddress(dependent)
                    }
                    onChangeDependentAddress={this.onChangeDependentAddress}
                    onDecline={this.onDeclineDependentDifferentAddress}
                    stateOptions={this.props.stateOptions}
                  />
                </AccordionDetails>
              </CustomAccordion>
            );
          })}
        </Box>
      </Box>
    );
  };

  renderDifferentAddressCheckBox = () => {
    return (
      <Box className={styles.box}>
        <CheckBox
          checked={this.state.dependentsSameAddress}
          onChange={this.changeDependentsSameAddress}
          name="changeDependentsDifferentAddress"
          label="All my dependents reside at my home address"
          className={styles.sameAddressesCheckbox}
        />
        {this.renderDifferentAddressesExpandedForms()}
      </Box>
    );
  };

  render() {
    const { editedDependentId, showAddDependentsModal, ssnEditable } =
      this.state;
    const { dependents, dependentLoading } = this.props;

    return (
      <>
        <ContentTableBox title="Dependents" index={3} count={8} isBox={true}>
          <SimpleAlert
            message={this.getDescription()}
            type="info"
            className={styles.marginTop}
          />
          <SubmitButton
            label="Add Dependent"
            icon={<Add />}
            iconPosition="left"
            onClick={this.onAddDependentClick}
            className={`${styles.marginBottom} ${styles.btnAdd}`}
          />
          {dependentLoading ? (
            <DependentsSkeleton />
          ) : (
            <DependentsList
              dependents={dependents}
              onEditItem={this.onEditDependent}
              onDeleteItem={this.showDeleteConfirmation}
            />
          )}
          {this.renderDifferentAddressCheckBox()}
          {this.renderErrorMessage()}
        </ContentTableBox>
        {this.renderStepperButtons()}
        <AddDependentsModal
          isOpen={showAddDependentsModal}
          handleClose={this.handleModalClose}
          ssnEditable={ssnEditable}
          toggleSsnEditableWithCb={this.toggleSsnEditableWithCb}
          userLastName={this.props.userLastName}
          onSubmit={this.addDependent}
          dependent={dependents.find((d) => d.id === editedDependentId)}
        />
        <ConfirmationModalWithCancel
          isOpen={!!this.state.dependentIdToDelete}
          handleClose={() => this.setState({ dependentIdToDelete: undefined })}
          onSubmit={this.deleteDependent}
        >
          <Typography variant="h6">
            Are you sure you want to delete a dependent?
          </Typography>
        </ConfirmationModalWithCancel>
        <Feedbacker
          open={!!this.state.feedbackerMessage && !!this.state.feedbackerStatus}
          severity={this.state.feedbackerStatus}
          feedbackMessage={this.state.feedbackerMessage || ''}
          clearFeedback={this.clearFeedbacker}
        />
      </>
    );
  }
}

const mapStateToProps = (store: AppStoreInterface) => ({
  userFirstName: store.userRegistration.profile.firstName,
  userLastName: store.userRegistration.profile.lastName,
  userAddress: fromUserProfileToUserAddress(store.userRegistration.profile),
  dependents: store.userRegistration.dependents,
  dependentLoading: store.userRegistration.loading,
  requireSsn: store.user.requireSsn,
  stateOptions: store.options.state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      createDependent: typeof RegistrationActions.createDependent;
      editDependent: typeof RegistrationActions.editDependent;
      deleteDependent: typeof RegistrationActions.deleteDependent;
      loadUserDependents: typeof loadUserDependents.trigger;
      editUserData: typeof UserActions.editUserData;
    }
  >(
    {
      createDependent: RegistrationActions.createDependent,
      editDependent: RegistrationActions.editDependent,
      deleteDependent: RegistrationActions.deleteDependent,
      loadUserDependents: loadUserDependents.trigger,
      editUserData: UserActions.editUserData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DependentsSettings);
