import React from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { ICustomFields } from 'types/plans';

import styles from './styles.module.scss';

interface IProps {
  customFields: ICustomFields[];
}

const CustomFields: React.FC<IProps> = ({ customFields }) => {
  return (
    <Box>
      <TableContainer className={styles.planTableContainer} component={Box}>
        <Table className={styles.planTable}>
          <TableBody>
            {customFields.map((field, index) => (
              <TableRow key={index}>
                <TableCell scope="row">{field.fieldLabel}:</TableCell>
                <TableCell scope="row">{field.fieldValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomFields;
