/* eslint-disable */
import { initOptionsStore, AppOptionsInterface } from 'store/options';
import {
  genderOptions,
  maritalStatusOptions,
  smokerOptions,
  securityQuestionOptions,
  relationshipTypeOptions,
  lastNameReasonOptions,
  employmentTypeOptions,
  employmentStatusOptions,
  officeLocationOptions,
  accessGroupOptions,
  qualifyingEventOptions,
  logEventTypeOptions,
  planTypeOptions,
  workEligibilityTypeOptions,
  w4FilingStatusOptions,
  payrollMethodOptions,
  payrollAmountTypeOptions,
  payrollAccountTypeOptions,
  eeoMilitaryStatusOptions,
  eeoJobCategoryOptions,
  eeoRaceOptionsOptions,
  beneficiaryRelationshipTypeOptions,
  allOptions,
} from 'redux/sagas/options/routines';

const options = (
  state = initOptionsStore(),
  action: any
): AppOptionsInterface => {
  switch (action.type) {
    case allOptions.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case genderOptions.SUCCESS:
      return {
        ...state,
        gender: action.payload,
      };
    case smokerOptions.SUCCESS:
      return {
        ...state,
        smoker: action.payload,
      };
    case maritalStatusOptions.SUCCESS:
      return {
        ...state,
        maritalStatus: action.payload,
      };

    case securityQuestionOptions.SUCCESS:
      return {
        ...state,
        securityQuestions: action.payload,
      };

    case relationshipTypeOptions.SUCCESS:
      return {
        ...state,
        relationshipType: action.payload,
      };

    case lastNameReasonOptions.SUCCESS:
      return {
        ...state,
        lastNameReason: action.payload,
      };

    case employmentTypeOptions.SUCCESS:
      return {
        ...state,
        employmentType: action.payload,
      };

    case employmentStatusOptions.SUCCESS:
      return {
        ...state,
        employmentStatus: action.payload,
      };

    case officeLocationOptions.SUCCESS:
      return {
        ...state,
        officeLocation: action.payload,
      };

    case accessGroupOptions.SUCCESS:
      return {
        ...state,
        accessGroup: action.payload,
      };

    case qualifyingEventOptions.SUCCESS:
      return {
        ...state,
        qualifyingEvent: action.payload,
      };

    case logEventTypeOptions.SUCCESS:
      return {
        ...state,
        logEventType: action.payload,
      };

    case planTypeOptions.SUCCESS:
      return {
        ...state,
        planType: action.payload,
      };

    case workEligibilityTypeOptions.SUCCESS:
      return {
        ...state,
        workEligibilityType: action.payload,
      };

    case w4FilingStatusOptions.SUCCESS:
      return {
        ...state,
        w4FilingStatus: action.payload,
      };

    case payrollMethodOptions.SUCCESS:
      return {
        ...state,
        payrollMethod: action.payload,
      };

    case payrollAmountTypeOptions.SUCCESS:
      return {
        ...state,
        payrollAmountType: action.payload,
      };

    case payrollAccountTypeOptions.SUCCESS:
      return {
        ...state,
        payrollAccountType: action.payload,
      };

    case eeoRaceOptionsOptions.SUCCESS:
      return {
        ...state,
        eeoRace: action.payload,
      };

    case eeoMilitaryStatusOptions.SUCCESS:
      return {
        ...state,
        eeoMilitaryStatus: action.payload,
      };

    case eeoJobCategoryOptions.SUCCESS:
      return {
        ...state,
        eeoJobCategory: action.payload,
      };

    case beneficiaryRelationshipTypeOptions.SUCCESS:
      return {
        ...state,
        beneficiaryRelationshipType: action.payload,
      };

    default:
      return state;
  }
};

export default options;
