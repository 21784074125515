import React from 'react';
import setClassNames from 'classnames';
import { Box, Paper } from '@material-ui/core';
import styles from './styles.module.scss';

export interface ContentBoxInterface {
  title?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  className?: string;
  withBorders?: boolean;
  description?: string;
}

const ContentBox: React.FC<ContentBoxInterface> = (props) => {
  const {
    title,
    footer,
    className,
    children,
    withBorders,
    description,
  } = props;

  const classNames = setClassNames(styles.contentBox, {
    [className || '']: Boolean(className),
    contentBoxWithBorders: withBorders,
  });

  return (
    <Paper elevation={3} className={classNames}>
      {title && <Box className={styles.contentBoxTitle}>{title}</Box>}
      <Box className={styles.contentBoxMain}>
        {description && (
          <h4 className={styles.contentBoxDesc}>{description}</h4>
        )}
        {children}
      </Box>
      {footer && <Box className={styles.contentBoxFooter}>{footer}</Box>}
    </Paper>
  );
};

export default ContentBox;
