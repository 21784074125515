import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ComparePlansTable from 'components/ComparePlansTable';

import ModalWindowComponent, {
  ModalWindowPropsInterface,
} from 'components/Modal/ModalWindowComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';

interface IComparePlansModal
  extends Pick<ModalWindowPropsInterface, 'isOpen' | 'handleClose'> {
  planTypeId: string;
  myPlanId?: string | number;
  onChange?: (planId: string) => void;
}

const useStyles = makeStyles({
  comparePlanContainer: {
    padding: '1rem 0',
  },
  btn: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    padding: '10px 15px',
  },
});

const ComparePlansModal: React.FC<IComparePlansModal> = (props) => {
  const { planTypeId, isOpen, handleClose, myPlanId, onChange } = props;
  const onChangePlan = (id: string) => {
    if (onChange && handleClose) {
      onChange(id);
      handleClose();
    }
  };
  const classes = useStyles();
  const renderActions = () => {
    return (
      <SubmitButton
        className={classes.btn}
        onClick={handleClose}
        label="Close"
        variant="contained"
      />
    );
  };
  return (
    <ModalWindowComponent
      dividers
      title="Compare Plans"
      isOpen={isOpen}
      handleClose={handleClose}
      actions={renderActions()}
      maxWidth="xl"
    >
      <Box className={classes.comparePlanContainer}>
        <ComparePlansTable
          planTypeId={planTypeId}
          planId={myPlanId}
          onChange={onChange && handleClose ? onChangePlan : undefined}
        />
      </Box>
    </ModalWindowComponent>
  );
};

export default ComparePlansModal;
