import React, { useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';

import { SvgIcon, FormLabel } from '@material-ui/core';
import { ReactComponent as visibilityIcon } from 'assets/visibility.svg';
import { ReactComponent as visibilityOffIcon } from 'assets/visibilityOff.svg';
import { TextField, TextFieldProps } from 'formik-material-ui';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const CustomTextField = withStyles({
  root: {
    marginTop: '10px',
    '& .MuiFormHelperText-root.Mui-error': {
      border: 'none',
      borderRadius: '5px',
      backgroundColor: '#ffff',
    },
    '& .Mui-error': {
      border: '1px solid #E51212',
      borderRadius: '5px',
      backgroundColor: '#FFE3E3',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4979C0',
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      '& fieldset': {
        borderColor: '#E1E2E7',
      },
      '&:hover fieldset': {
        borderColor: '#4979C0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #4979C0',
      },
    },
  },
})(TextField);

const FormikPasswordInput: React.FC<TextFieldProps> = (props) => {
  const [secured, setSecured] = useState(true);
  const classes = useStyles();

  const icon = secured ? (
    <SvgIcon
      component={visibilityIcon}
      width="22"
      height="20"
      viewBox="0 0 22 20"
    />
  ) : (
    <SvgIcon
      component={visibilityOffIcon}
      width="22"
      height="15"
      viewBox="0 0 22 15"
    />
  );
  const iconOnClick = () => setSecured(!secured);
  const type = secured ? 'password' : 'text';
  const { label, ...other } = props;
  return (
    <>
      {label && (
        <FormLabel className={classes.label} component="legend">
          {label}
        </FormLabel>
      )}
      <CustomTextField
        variant="outlined"
        type={type}
        placeholder="Enter Password"
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={iconOnClick}
              className={classes.inputIcon}
            >
              {icon}
            </InputAdornment>
          ),
        }}
        className={`${classes.root} ${secured && classes.input}`}
        {...other}
      />
    </>
  );
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: 0,
  },
  inputIcon: {
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fill: '#4979C0',
    },
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#252733',
  },
  input: {
    '& ::placeholder': {
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#9FA2B4',
      opacity: 0.8,
    },
    '& input': {
      color: '#E1E2E7',
    },
  },
});

export default FormikPasswordInput;
