import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Skeleton from 'components/Skeleton';

import styles from './styles.module.scss';

const loadingItems = Array(3).fill(true);

const ProfileSkeleton: React.FC = () => {
  return (
    <>
      <Typography className={styles.userInitials} variant="h6">
        <Skeleton width="7em" />
      </Typography>
      {loadingItems.map((_, i) => (
        <Box mb="1em" key={i}>
          <Skeleton variant="rect" height="17.8em" />
        </Box>
      ))}
    </>
  );
};

export default ProfileSkeleton;
