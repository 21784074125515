import { IFormQuestion, IFormQuestionPayload } from 'types/additionalInfo';
import api from '../adapter';

export class AdditionalInfoDomain {
  getFormQuestions() {
    return api.makeRequest<IFormQuestion[]>('/forms/questions', 'GET');
  }

  updateFormQuestions(payload: IFormQuestionPayload[]) {
    return api.makeRequest<void>('/forms/questions', 'PUT', payload);
  }
}

export default new AdditionalInfoDomain();
