import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

import AuditLogUserType from 'enums/auditLogUserType';
import Skeleton from 'components/Skeleton';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import ModalWindow from 'components/Modal/ModalWindowComponent';
import { IAuditLogDetails } from 'types/auditLogs';
import { useQuery } from 'react-query';
import userDomain from 'api/domains/user';
import LogDetailsFactory from './LogDetailsFactory';

import styles from './styles.module.scss';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  logId: string;
  userType: AuditLogUserType;
}

const getLogDetails = async (userType: AuditLogUserType, id: string) => {
  const { data } = await userDomain.getLogDetails(userType, id);
  return data;
};
const LogDetailsModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  logId,
  userType,
}) => {
  const { data: logDetails, status } = useQuery(
    [userType, logId],
    getLogDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
  const modalActions = (
    <SubmitButton
      label="Close"
      variant="contained"
      className={styles.button}
      onClick={handleClose}
    />
  );

  const showCaption = Boolean(
    logDetails && logDetails.createdBy && logDetails.createdOn
  );

  return (
    <>
      <ModalWindow
        title="Log Details"
        isOpen={isOpen}
        handleClose={handleClose}
        actions={modalActions}
        withoutPadding
        dividers
      >
        <Box>
          {status === 'loading' ? (
            <Skeleton variant="rect" height="10em" />
          ) : (
            <>
              <LogDetailsFactory logDetails={logDetails as IAuditLogDetails} />
              {showCaption ? (
                <Box className={styles.boxText}>
                  <Typography className={styles.subtext}>
                    {` Performed by ${logDetails?.createdBy} on ${logDetails?.createdOn}`}
                  </Typography>
                </Box>
              ) : null}
            </>
          )}
        </Box>
      </ModalWindow>
    </>
  );
};

export default LogDetailsModal;
