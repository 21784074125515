import ExternalRegistrationStep from 'enums/externalRegistrationStep';

export const stepMapping: ExternalRegistrationStep[] = [
  ExternalRegistrationStep.Account,
  ExternalRegistrationStep.Profile,
  ExternalRegistrationStep.Dependents,
  ExternalRegistrationStep.Enrollments,
  ExternalRegistrationStep.Beneficiaries,
  ExternalRegistrationStep.AdditionalInfo,
  ExternalRegistrationStep.Signature,
];
