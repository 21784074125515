import React from 'react';
import { connect } from 'react-redux';
import { Box, Typography, Divider } from '@material-ui/core';

import PersonalInfo from 'components/PersonalInfo';
import { EMPTY_VALUE } from 'constants/placeholders';
import Gender from 'enums/gender';
import BeneficiaryType from 'enums/beneficiaryType';
import { AppStoreInterface } from 'store/app';
import { ExternalBeneficiaryInterface } from 'types/beneficiaries';
import IBasicOption from 'models/IBasicOption';
import styles from './styles.module.scss';

const getBeneficiaryType = (
  beneficiary: ExternalBeneficiaryInterface
): string => {
  switch (+beneficiary.beneficiaryType) {
    case BeneficiaryType.Primary:
      return 'Primary';
    case BeneficiaryType.Contingent:
      return 'Contingent';
    case BeneficiaryType.Tertiary:
      return 'Tertiary';
    default:
      return EMPTY_VALUE;
  }
};

export interface IBeneficiariesReadOnlyItemProps {
  beneficiary: ExternalBeneficiaryInterface;
  relationshipTypeOptions: IBasicOption<number>[];
  handleOpenModal: () => void;
  handleHidePersonalData: () => void;
  handleShowPersonalData: (password: string) => Promise<void>;
  isPasswordPrompt?: boolean;
  hideSsn: boolean;
}

// TODO: Try to create more general component for Beneficiary and Dependent
const BeneficiaryCardItem: React.FC<IBeneficiariesReadOnlyItemProps> = (
  props
) => {
  const {
    beneficiary,
    relationshipTypeOptions,
    handleOpenModal,
    handleHidePersonalData,
    isPasswordPrompt,
    handleShowPersonalData,
    hideSsn,
  } = props;

  const getRelationType = () => {
    const relation = relationshipTypeOptions.find(
      (option) => option.value === +beneficiary.relationshipTypeId
    );
    return relation?.label || '';
  };
  const unlockSsn = () =>
    isPasswordPrompt ? handleOpenModal() : handleShowPersonalData('');

  const renderSsn = (ssn?: string) => {
    if (!ssn) {
      return (
        <Typography className={styles.infoAnswer} variant="body2">
          {EMPTY_VALUE}
        </Typography>
      );
    }
    return (
      <PersonalInfo
        isLocked={hideSsn}
        onLockIconClick={unlockSsn}
        personalInfo={ssn}
        handleHidePersonalData={handleHidePersonalData}
      />
    );
  };
  const getBottomBorder = () => {
    if (beneficiary.relationshipTypeId === '2') {
      return Math.floor(Math.random() * 2) + 1 === Gender.Male
        ? styles.borderMale
        : styles.borderFemale;
    }
    return styles.borderDefault;
  };

  return (
    <Box className={`${styles.card} ${getBottomBorder()}`}>
      <Box className={styles.cardTitle}>
        <Box>
          <Typography className={styles.beneficiaryName} variant="subtitle2">
            {beneficiary.firstName} {beneficiary.lastName}
          </Typography>
          <Typography className={styles.beneficiaryRelation} variant="caption">
            {getRelationType()}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className={styles.infoContainer}>
        <Box className={styles.infoRow}>
          <Typography className={styles.infoName} variant="body2">
            Date of birth:
          </Typography>
          <Typography className={styles.infoAnswer} variant="body2">
            {beneficiary.birthDate}
          </Typography>
        </Box>
        <Box className={styles.infoRow}>
          <Typography className={styles.infoName} variant="body2">
            Beneficiary Type:
          </Typography>
          <Typography className={styles.infoAnswer} variant="body2">
            {getBeneficiaryType(beneficiary)}
          </Typography>
        </Box>
        <Box className={styles.infoRow}>
          <Typography className={styles.infoName} variant="body2">
            Social security number:
          </Typography>
          {renderSsn(beneficiary.ssn)}
        </Box>
        <Box className={styles.infoRow}>
          <Typography className={styles.infoName} variant="body2">
            Share %:
          </Typography>
          <Typography className={styles.infoAnswer} variant="body2">
            {beneficiary.share}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  relationshipTypeOptions: store.options.relationshipType,
});

export default connect(mapStateToProps)(BeneficiaryCardItem);
