import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { withStyles, SvgIcon, Button } from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';
import { Formik, Form, Field, FormikProps, FormikErrors } from 'formik';
import * as yup from 'yup';

import { ReactComponent as openIcon } from 'assets/sidebar/open.svg';
import { ReactComponent as closeIcon } from 'assets/sidebar/close.svg';
import * as inputFormats from 'constants/inputFormats';
import userDomain from 'api/domains/user';
import { ZIP_REG_EXP } from 'constants/regExps';
import FillFieldsList, {
  FieldsInterface,
} from 'components/FillFieldsList/FillFieldsListComponent';
import Input from 'components/Forms/Input/InputComponent';
import Feedbacker from 'components/Feedbacker';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import ModalWindow from 'components/Modal/ModalWindowComponent';
import FormikInput from 'components/Forms/Formik/Input';
import FormikDatePicker from 'components/Forms/Formik/Datepicker';
import IBasicOption from 'models/IBasicOption';
import { IAddEmployeePayload } from 'types/employee';
import * as validationHelper from 'helpers/validation';
import * as feedbackMessages from 'constants/feedbackMessages';
import AuthMethod from 'enums/authMethod';
import { getErrorMessage } from './helpers';

import styles from './styles.module.scss';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleAddEmployee: (payload: IAddEmployeePayload) => Promise<void>;
  employmentType: IBasicOption<number>[];
  employmentStatus: IBasicOption<number>[];
  officeLocation: IBasicOption<number>[];
  accessGroup: IBasicOption<number>[];
  gender: IBasicOption<number>[];
  maritalStatus: IBasicOption<number>[];
  authenticationMethod: AuthMethod | null;
  enableOnboarding: boolean | null;
  workHoursRequired: boolean;
}
const CustomFormControlLabel = withStyles({
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
})(FormControlLabel);

const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);
const AddEmployeeModal: React.FC<IProps> = (props) => {
  const {
    isOpen,
    employmentType,
    employmentStatus,
    officeLocation,
    accessGroup,
    gender,
    maritalStatus,
    handleClose,
    handleAddEmployee,
    authenticationMethod,
    enableOnboarding,
    workHoursRequired,
  } = props;

  const [feedback, setFeedback] = useState<string>();
  const [prevEmail, setPrevEmail] = useState<string>();
  const [prevEmailError, setPrevEmailError] = useState<string>();
  const [showNotRequired, setShowNotRequired] = useState<boolean>(false);
  const [ssnEditable, setSsnEditable] = useState<boolean>(false);
  // eslint-disable-next-line
  const [initialSsn, setInitialSsn] = useState<string>('');
  const initialValues: IAddEmployeePayload = {
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    email: '',
    groupId: '',
    employmentId: '',
    officeId: '',
    employmentType: '',
    title: '',
    income: '',
    workHours: '',
    hireDate: null,
    birthDate: null,
    sendEmail: true,
    enableOnboarding: Boolean(enableOnboarding),
    gender: '',
    maritalStatusId: 1,
    ssn: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    officePhone: '',
    homePhone: '',
    cellPhone: '',
  };

  const handleSubmit = async (values: IAddEmployeePayload) => {
    try {
      await handleAddEmployee(values);
      setFeedback(feedbackMessages.EMPLOYEE_CREATION_SUCCESS);
      handleClose();
    } catch (err) {
      setFeedback(feedbackMessages.EMPLOYEE_CREATION_ERROR);
      handleClose();
    }
  };

  const validateEmail = async (email?: string): Promise<string | undefined> => {
    const isEmailAuthentication = authenticationMethod === AuthMethod.Email;
    if (!email && isEmailAuthentication) {
      return 'Field is required';
    }

    const isEmail = await validationHelper.email.isValid(email);
    if (email && !isEmail) {
      return 'Please enter a valid email';
    }

    const isUnique = await validationHelper.checkEmailUniqueness(email);
    if (!isUnique && isEmailAuthentication) {
      return 'Email already in use';
    }
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Field is required'),
    lastName: yup.string().required('Field is required'),
    groupId: yup.string().required('Field is required'),
    employmentId: yup.string().required('Field is required'),
    title: yup.string(),
    hireDate: yup.date().nullable().required('Field is required'),
    birthDate: yup.date().nullable(),
    income: yup
      .number()
      .transform((cv) => (isNaN(cv) ? undefined : cv))
      .positive('Cannot be less than 0')
      .nullable(),
    workHours: workHoursRequired
      ? yup
          .number()
          .transform((cv) => (isNaN(cv) ? undefined : cv))
          .positive('Cannot be less than 0')
          .required('Field is required')
      : yup
          .number()
          .transform((cv) => (isNaN(cv) ? undefined : cv))
          .positive('Cannot be less than 0'),
    sendEmail: yup.boolean(),
    enableOnboarding: yup.boolean(),
  });

  const validate = async (
    values: IAddEmployeePayload
  ): Promise<FormikErrors<IAddEmployeePayload>> => {
    let emailError: string | undefined;

    if (!prevEmail || values.email !== prevEmail) {
      emailError = await validateEmail(values.email);
      setPrevEmail(values.email);
      setPrevEmailError(emailError);
    } else {
      emailError = prevEmailError;
    }

    return new Promise((resolve, reject) => {
      validationSchema
        .validate(values, { abortEarly: false })
        .then(() => resolve({}))
        .catch((error: yup.ValidationError) => {
          const formikErrors = error.inner.reduce<
            FormikErrors<IAddEmployeePayload>
          >((errors, currentError) => {
            return Object.assign(errors, {
              [currentError.path]: currentError.errors,
            });
          }, {});
          formikErrors.email = emailError;
          resolve(formikErrors);
        });
    });
  };

  const toggleSsnEditable = (formik: FormikProps<IAddEmployeePayload>) => {
    const { setFieldValue } = formik;
    setSsnEditable(!ssnEditable);
    setFieldValue('ssn', !ssnEditable ? '' : initialSsn);
  };

  const handleZipCodeChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<IAddEmployeePayload>
  ) => {
    const { handleChange, setFieldValue } = formik;
    handleChange(e);

    const zip = e.target.value;
    const zipValid = zip.match(ZIP_REG_EXP);

    if (zipValid) {
      try {
        const { data } = await userDomain.zipCodeLookup(zip);
        setFieldValue('city', data.city || '');
        setFieldValue('state', data.state || '');
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getNotRequiredeFields = (
    formik: FormikProps<IAddEmployeePayload>
  ): FieldsInterface[] => {
    const { values, handleBlur, handleChange } = formik;
    return [
      {
        key: 'gender',
        selectComponentData: {
          options: gender,
          value: values.gender,
          name: 'gender',
          onChange: handleChange,
          label: 'Gender',
          onBlur: handleBlur,
          error: !!getErrorMessage('gender', formik),
          helperText: getErrorMessage('gender', formik),
        },
      },
      {
        key: 'maritalStatusId',
        selectComponentData: {
          options: maritalStatus,
          value: values.maritalStatusId,
          onChange: handleChange,
          label: 'Marital status',
          onBlur: handleBlur,
          name: 'maritalStatusId',
          error: !!getErrorMessage('maritalStatusId', formik),
          helperText: getErrorMessage('maritalStatusId', formik),
        },
      },
      {
        key: 'ssn',
        ssnInputComponentData: {
          onChange: handleChange,
          value: values.ssn ?? '',
          name: 'ssn',
          label: 'Social Security Number',
          onBlur: handleBlur,
          error: getErrorMessage('ssn', formik),
          editable: ssnEditable,
          toggleEditable: () => toggleSsnEditable(formik),
          initialValue: initialSsn,
        },
      },
      {
        key: 'address1',
        inputComponentData: {
          onChange: handleChange,
          value: values.address1,
          name: 'address1',
          label: 'Home address',
          onBlur: handleBlur,
          error: getErrorMessage('address1', formik),
        },
      },
      {
        key: 'address2',
        inputComponentData: {
          onChange: handleChange,
          value: values.address2,
          name: 'address2',
          label: 'Apt Number/Unit/Etc.',
          onBlur: handleBlur,
          error: getErrorMessage('address2', formik),
        },
      },
      {
        key: 'zip',
        inputComponentData: {
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleZipCodeChange(e, formik),
          value: values.zip,
          name: 'zip',
          label: 'Zip code',
          onBlur: handleBlur,
          error: getErrorMessage('zip', formik),
          isFormatting: true,
          format: inputFormats.zip,
        },
      },
      {
        key: 'city',
        inputComponentData: {
          onChange: handleChange,
          value: values.city,
          name: 'city',
          label: 'City',
          onBlur: handleBlur,
          error: getErrorMessage('city', formik),
        },
      },
      {
        key: 'state',
        inputComponentData: {
          onChange: handleChange,
          value: values.state,
          name: 'state',
          label: 'State',
          onBlur: handleBlur,
          error: getErrorMessage('state', formik),
        },
      },
      {
        key: 'officePhone',
        inputComponentData: {
          onChange: handleChange,
          value: values.officePhone,
          name: 'officePhone',
          label: 'Office phone',
          onBlur: handleBlur,
          error: getErrorMessage('officePhone', formik),
          isFormatting: true,
          format: inputFormats.phone,
        },
      },
      {
        key: 'homePhone',
        inputComponentData: {
          onChange: handleChange,
          value: values.homePhone,
          name: 'homePhone',
          label: 'Home phone',
          onBlur: handleBlur,
          error: getErrorMessage('homePhone', formik),
          isFormatting: true,
          format: inputFormats.phone,
        },
      },
      {
        key: 'cellPhone',
        inputComponentData: {
          onChange: handleChange,
          value: values.cellPhone,
          name: 'cellPhone',
          label: 'Cell phone',
          onBlur: handleBlur,
          error: getErrorMessage('cellPhone', formik),
          isFormatting: true,
          format: inputFormats.phone,
        },
      },
    ];
  };
  return (
    <>
      <ModalWindow
        isOpen={isOpen}
        handleClose={handleClose}
        dividers
        title="Add New Employee"
      >
        <Box className={styles.modalForm}>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur
            onSubmit={handleSubmit}
            validate={validate}
          >
            {(formik: FormikProps<IAddEmployeePayload>) => (
              <Form>
                <Box className={styles.addEmployeeModalSection}>
                  <Box className={styles.header}>
                    <Typography className={styles.subtitle}>
                      Personal Information
                    </Typography>
                    <Divider />
                  </Box>
                  <Field
                    component={FormikInput}
                    label="First Name"
                    name="firstName"
                    fullWidth
                  />
                  <Field
                    component={FormikInput}
                    label="Last Name"
                    name="lastName"
                    fullWidth
                  />
                  <Field
                    component={FormikInput}
                    label="Email"
                    name="email"
                    fullWidth
                  />
                </Box>

                <Box className={styles.addEmployeeModalSection}>
                  <Box className={styles.header}>
                    <Typography className={styles.subtitle}>
                      User Access
                    </Typography>
                    <Divider />
                  </Box>
                  <Field
                    component={FormikInput}
                    name="groupId"
                    label="Access Group"
                    variant="outlined"
                    select
                    fullWidth
                  >
                    <MenuItem
                      value=""
                      disabled
                      className={styles.optionPlaceholder}
                    >
                      Select
                    </MenuItem>
                    {accessGroup.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        className={styles.option}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Input
                    placeholder=""
                    value={
                      authenticationMethod === AuthMethod.Username
                        ? ''
                        : formik.values.email
                    }
                    label="Username"
                    helperText={
                      authenticationMethod === AuthMethod.Username
                        ? 'Username will be assigned after you create the employee'
                        : ''
                    }
                    onChange={() => {}}
                    readOnly
                  />
                  <Input
                    value=""
                    placeholder=""
                    label="Password"
                    helperText={
                      authenticationMethod === AuthMethod.Username
                        ? 'Password will be assigned after you create the employee'
                        : 'User will create password during registration'
                    }
                    readOnly
                    onChange={() => {}}
                  />
                </Box>

                <Box className={styles.addEmployeeModalSection}>
                  <Box className={styles.header}>
                    <Typography className={styles.subtitle}>
                      Employment Information
                    </Typography>
                    <Divider />
                  </Box>
                  <Field
                    component={FormikInput}
                    name="employmentId"
                    label="Employment"
                    variant="outlined"
                    select
                    fullWidth
                  >
                    <MenuItem
                      value=""
                      disabled
                      className={styles.optionPlaceholder}
                    >
                      Select
                    </MenuItem>
                    {employmentStatus.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        className={styles.option}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <FormikDatePicker
                    value={formik.values.hireDate}
                    label="Hire date"
                    name="hireDate"
                    variant="inline"
                    convertToDate
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.hireDate
                        ? !!getErrorMessage<IAddEmployeePayload>(
                            'hireDate',
                            formik
                          )
                        : false
                    }
                    helperText={
                      formik.touched.hireDate
                        ? getErrorMessage<IAddEmployeePayload>(
                            'hireDate',
                            formik
                          )
                        : ''
                    }
                  />
                  <Field
                    component={FormikInput}
                    name="title"
                    label="Job Title"
                    variant="outlined"
                    fullWidth
                  />
                  <Field
                    component={FormikInput}
                    name="officeId"
                    label="Office Location"
                    variant="outlined"
                    select
                    fullWidth
                  >
                    <MenuItem
                      value=""
                      disabled
                      className={styles.optionPlaceholder}
                    >
                      Select
                    </MenuItem>
                    {officeLocation.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        className={styles.option}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={FormikInput}
                    name="employmentType"
                    label="Employment Type"
                    variant="outlined"
                    select
                    fullWidth
                  >
                    <MenuItem
                      value=""
                      disabled
                      className={styles.optionPlaceholder}
                    >
                      Select
                    </MenuItem>
                    {employmentType.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        className={styles.option}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={FormikInput}
                    name="income"
                    label={
                      +formik.values.employmentType === 2
                        ? 'Wage/hour'
                        : 'Salary'
                    }
                    variant="outlined"
                    type="number"
                    fullWidth
                  />
                  <Field
                    component={FormikInput}
                    name="workHours"
                    label="Work hours"
                    variant="outlined"
                    type="number"
                    fullWidth
                  />
                  <Box className={styles.checkBox}>
                    <CustomFormControlLabel
                      control={
                        <CustomCheckbox
                          checked={formik.values.sendEmail}
                          onChange={formik.handleChange}
                          icon={
                            <SvgIcon
                              component={offIcon}
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                            />
                          }
                          checkedIcon={
                            <SvgIcon
                              component={onIcon}
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                            />
                          }
                          name="sendEmail"
                        />
                      }
                      label="Send Welcome Email"
                    />
                    {Boolean(enableOnboarding) && (
                      <CustomFormControlLabel
                        control={
                          <CustomCheckbox
                            checked={formik.values.enableOnboarding}
                            onChange={formik.handleChange}
                            icon={
                              <SvgIcon
                                component={offIcon}
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                              />
                            }
                            checkedIcon={
                              <SvgIcon
                                component={onIcon}
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                              />
                            }
                            name="enableOnboarding"
                          />
                        }
                        label="Enable onboarding"
                      />
                    )}
                  </Box>
                </Box>
                <Box className={styles.addEmployeeModalSection}>
                  <Box className={styles.header}>
                    <Typography className={styles.subtitleNotRequired}>
                      Additional Information (optional)
                      <Button
                        onClick={() => setShowNotRequired(!showNotRequired)}
                      >
                        <Typography
                          className={styles.terminatedEmployees}
                          variant="subtitle1"
                        >
                          {showNotRequired ? (
                            <SvgIcon
                              className={styles.helpIcons}
                              component={closeIcon}
                              width="15"
                              height="8"
                              viewBox="0 0 11 7"
                            />
                          ) : (
                            <SvgIcon
                              className={styles.helpIcons}
                              component={openIcon}
                              width="15"
                              height="8"
                              viewBox="0 0 11 7"
                            />
                          )}
                        </Typography>
                      </Button>
                    </Typography>
                    <Divider />
                  </Box>

                  {showNotRequired && (
                    <>
                      <FormikDatePicker
                        value={formik.values.birthDate}
                        label="Birth date"
                        variant="dialog"
                        name="birthDate"
                        convertToDate
                      />
                      <FillFieldsList fields={getNotRequiredeFields(formik)} />
                    </>
                  )}
                </Box>
                <SubmitButton
                  disabled={formik.isSubmitting}
                  className={styles.submitBtn}
                  label="Submit"
                />
              </Form>
            )}
          </Formik>
        </Box>
      </ModalWindow>
      <Feedbacker
        open={!!feedback}
        autoHideDuration={5000}
        severity={
          feedback === feedbackMessages.EMPLOYEE_CREATION_ERROR
            ? 'error'
            : 'success'
        }
        feedbackMessage={feedback || ''}
        clearFeedback={() => setFeedback(undefined)}
      />
    </>
  );
};

export default AddEmployeeModal;
