import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useQuery } from 'react-query';

import ModalWindow from 'components/Modal/ModalWindowComponent';
import Typography from '@material-ui/core/Typography';
import userDomain from 'api/domains/user';

import styles from './styles.module.scss';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

const getPrivacyNotice = async () => {
  const { data } = await userDomain.getPrivacyNoticeData();
  return data;
};

const PrivacyNotice: React.FC<IProps> = ({ isOpen, handleClose }) => {
  const { data: privacyNotice } = useQuery('privacyNotice', getPrivacyNotice, {
    refetchOnWindowFocus: false,
  });

  const { revisionDate, mblUrl } =
    privacyNotice || {};

  const modalActions = (
    <Button
      variant="contained"
      className={styles.submitBtn}
      onClick={handleClose}
    >
      Close
    </Button>
  );

  return (
    <ModalWindow
      isOpen={isOpen}
      className={styles.privacyNoticeModal}
      handleClose={handleClose}
      actions={modalActions}
      dividers
      title="Privacy Notice"
    >
      <Box className={styles.privacyNoteContainer}>
        <Box mb="1em">
          <Typography variant="h6">Revised: {revisionDate}</Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            THIS PRIVACY POLICY CONTAINS IMPORTANT INFORMATION PERTAINING TO THE
            PRIVACY POLICY AND PRACTICES OF AGENCYSMART LLC ("AgencySmart", "we"
            or "us"). THIS PRIVACY POLICY APPLIES ONLY TO INFORMATION COLLECTED
            THROUGH OUR WEBSITE (
            <a target="_blank" href={mblUrl} rel="noreferrer">
              {mblUrl}
            </a>
            ) ("WEBSITE") OR THROUGH USE OF THE MYBENEFITLINK SERVICES AVAILABLE
            AT OUR WEBSITE (THE "SERVICE"). THIS PRIVACY POLICY DOES NOT APPLY
            TO ANY OTHER THIRD PARTY WEBSITES THAT MAY BE ACCESSIBLE OR THAT MAY
            BE LINKED FROM THE WEBSITE OR THE SERVICES
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            Please be sure to read this Privacy Policy before using the Website,
            the Service or submitting information to us through this Website or
            the Service.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            This Privacy Policy contains important information on how and why we
            collect, store, use, and share
            <b>
              &nbsp;your personal information as well as the personal
              information of third-parties you may provide to us while using the
              Website or Service
            </b>
            . As used in this Privacy Policy, "personal information" does not
            include deidentified personal information.
            <b>
              &nbsp;DO NOT PROVIDE US WITH PERSONAL INFORMATION OF THIRD-PARTIES
              UNLESS YOU HAVE OBTAINED ALL NECESSARY CONSENTS FROM THEM TO THE
              TERMS OF THIS PRIVACY POLICY BEFORE DOING SO.
            </b>
            &nbsp;AS USED IN THIS PRIVACY POLICY, THE WORDS "YOU" AND "YOUR"
            REFER NOT ONLY TO YOUR PERSONAL INFORMATION, BUT ALSO REFER TO
            THIRD-PARTY PERSONAL INFORMATION YOU PROVIDE THROUGH THE WEBSITE OR
            SERVICE.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">Personal Information We Collect.</Typography>
          <Typography variant="body1">
            We may collect and use the following types of personal information:
            IP address or referring URL, the type of operating system and
            browser used, the time and date of access, which pages were visited,
            and other similar information. When you access the Website or the
            Service by or through a mobile device, we may collect certain
            information automatically, including, but not limited to, the type
            of mobile device you use, your mobile device unique ID, the IP
            address of your mobile device, your mobile operating system, the
            type of mobile Internet browser you use, unique device identifiers
            and other diagnostic data. We may also collect such other
            information you voluntarily provide to us when you contact us. You
            are responsible for providing us with accurate and complete
            information, and for contacting us if correction of the information
            is required.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            This personal information is required to respond to your inquiries
            and to provide products and/or services to you. If you do not
            provide personal information we ask for, it may delay or prevent us
            from responding to your inquiries or providing products and/or
            services to you.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">
            How Consumer Personal Information is Collected.
          </Typography>
          <Typography variant="body1">
            We obtain personal information from users of our Website and/or
            Service when it is input directly by the user.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">
            Use of cookies and other tracking systems.
          </Typography>
          <Typography variant="body1">
            Cookies, log files, and pixel-tags (Web beacons) are technologies
            used by the Website and Service for various purposes. Your browser
            allows us to place some information (session-based IDs and/or
            persistent cookies) on your computer's hard drive to identify
            information about the browser you are using to tailor web page
            display. We may also use these cookies to connect the information
            collected to the collected personal information you may voluntarily
            provide, improve the quality of its Website, and/or Service,
            identify user preferences, improve search results and track user
            browsing trends. Your web browser can be set to allow you to control
            whether you will accept cookies, reject cookies, or to notify you
            each time a cookie is sent to you. Please note that cookies are not
            required to access and use our Website or Service, however, if your
            browser is set to reject cookies some Website or Service
            functionality may be lost. The Help section of your browser will
            tell you how to prevent your browser from accepting cookies or to
            delete cookies that are already on your computer (persistent
            cookies).
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            We gather certain information automatically and store it in log
            files. This information may include Internet protocol (IP)
            addresses, browser type, internet service provider (ISP),
            referring/exit pages, operating system, date/time stamp, and/or
            clickstream data. While not all of the information that we collect
            from visitors is personally identifiable, it may be associated with
            personal information that visitors provide to us through our Website
            or the Service.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">Right to Opt-Out.</Typography>
          <Typography variant="body1">
            You have the right to opt-out of receiving promotional
            communications from us at any time by updating your email
            preferences by using the "Unsubscribe" link found in the emails. You
            may also update, modify or correct the information or request that
            such information be removed from our databases by contacting us (see{' '}
            <b>How to Contact Us</b> Us below).
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            Other than the right to opt-out of as described above, the only way
            to opt-out of the terms of this Privacy Policy is to not use the
            Website or the Service and not to request products and/or services
            from AgencySmart.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">
            Why We Use Consumer Personal Information.
          </Typography>
          <Typography variant="body1">
            We use and/or sell and share consumer personal information for the
            following reasons:
          </Typography>
          <ul>
            <li>
              Helping to ensure security and integrity to the extent the use of
              the consumer's personal information is reasonably necessary and
              proportionate for these purposes;
            </li>
            <li>
              Debugging to identify and repair errors that impair existing
              intended functionality;
            </li>
            <li>
              Performing services on behalf of the business, including
              maintaining or servicing accounts, providing customer service,
              processing or fulfilling orders and transactions, verifying
              customer information, processing payments, providing financing,
              providing analytic services, providing storage, or providing
              similar services on behalf of the business or service provider;
            </li>
            <li>
              Undertaking internal research for technological development and
              demonstration; and
            </li>
            <li>
              Undertaking activities to verify or maintain the quality or safety
              of a service or device that is owned, manufactured, manufactured
              for, or controlled by the business, and to improve, upgrade, or
              enhance the service or device that is owned, manufactured,
              manufactured for, or controlled by the business.
            </li>
          </ul>
        </Box>

        <Box mb="1em">
          <Typography variant="h6">
            Who We Share Collected Personal Information With.
          </Typography>
          <Typography variant="body1">
            We may share collected personal information with:
          </Typography>
          <ul>
            <li>Our affiliates;</li>
            <li>
              Service providers we use to help deliver our products and/or
              services to you, such as payment service providers, warehouses and
              delivery companies;
            </li>
            <li>
              Other third parties we use to help us run our business, marketing
              agencies or website hosts; and
            </li>
            <li>
              Third parties approved by you, including third-party payment
              providers.
            </li>
          </ul>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            Additionally, all of a subscriber's end users will have access to
            all personal information input into the subscriber's account.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            Additionally, all of a subscriber's end users will have access to
            all personal information input into the subscriber's account.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            We may sell, license or otherwise disclose and exchange deidentified
            collected personal information to and with third parties.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            We also impose contractual obligations on service providers relating
            to ensure they can only use collected personal information to
            provide services to us and to you. We may also share collected
            personal information with external auditors.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            We may disclose and exchange collected personal information with law
            enforcement agencies and regulatory bodies to comply with our legal
            and regulatory obligations.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            We may also need to share collected personal information with other
            parties, such as potential buyers of some or all of our business or
            during a re-structuring.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">Sale of Personal Information.</Typography>
          <Typography variant="body1">
            Except as set forth in this privacy policy, we do not sell or
            disclose collected personal information to third parties. We may
            disclose such collected personally identifiable information when we
            believe in good faith that it is required to be disclosed to protect
            our rights, protect your safety or the safety of others, investigate
            fraud, by law or by an appropriate law enforcement or governmental
            authority.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            As noted above (see{' '}
            <b>Who We Share Your Personal Information With</b> above), we may
            sell, license or otherwise disclose and exchange deidentified
            collected personal information to and with third parties.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            We also may from time to time engage third parties to track and
            analyze non-personally identifiable usage and volume statistical
            information from visitors to our Website and users of our Service to
            help us administer our Website and Service and improve its quality.
            Except as otherwise set forth herein, all data collected by such
            third parties on our behalf is used only to provide us with
            information on Website and/or Service usage and is not shared with
            any other third parties.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">Links to Other Websites.</Typography>
          <Typography variant="body1">
            Our Website and Service includes links to other websites whose
            privacy practices may differ from those of AgencySmart. If you
            submit personal information to any of those websites, your
            information is governed by their privacy policies. We encourage you
            to carefully read the privacy policy of any website you visit.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">
            How We Use the Information We Collect.
          </Typography>
          <Typography variant="body1">
            We use the information we collect in order to improve the content
            and usability of the Website and the Service.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            We also use such information to respond to your inquiries and to
            provide you with products or services you request.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            We may also periodically send promotional emails about our products
            and services to visitors who provide their email information. We use
            an agent to assist with our marketing campaigns. The agent is bound
            by a contractual agreement which covers security and limits the use
            of your personal information to the intended AgencySmart
            communications. You may elect to be removed from any future
            communications by confirming your preferences via a link at the
            bottom of the communication or by contacting us (see{' '}
            <b>How to Contact Us</b> below).
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">Information Security.</Typography>
          <Typography variant="body1">
            We take various security measures to protect information received
            online. Except as otherwise provided in this Privacy Policy, access
            to information stored on our servers is restricted to AgencySmart
            employees or third parties who need to know such information to
            perform a specific job on AgencySmart's behalf.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            Although we make a genuine effort to ensure the security of
            activities conducted on this Website and the Service, no
            transmission over the Internet, or method of electronic storage, is
            completely secure. Accordingly, we cannot and do not guarantee that
            any activities conducted on this Website or the Service will be
            absolutely secure.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">Social Media Widgets.</Typography>
          <Typography variant="body1">
            Our Website and Service may include social media widgets, such as
            the share this button or interactive mini-programs that run on our
            Website and Service. These features may collect your IP address,
            which page you are visiting on our Website or Service, and may set a
            cookie to enable the feature to function properly. Social media
            features and widgets are either hosted by a third party or hosted
            directly on our Website or Service. Your interactions with these
            features are governed by the privacy policy of the company providing
            it.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">Children Under the Age of 18.</Typography>
          <Typography variant="body1">
            The Website and Service is not intended for use by children under 18
            years of age. No one under age 18 may provide any information to or
            on the Website or through the Service. We do not knowingly collect
            information from children under 18. If you are under 18, do not
            provide any information on or through the Website or Service. If we
            learn we have collected or received personal information from a
            child under 18 without verification of parental consent, we will
            delete that information. If you believe we might have any
            information from or about a child under 18, please contact us (see{' '}
            <b>How to Contact Us</b> below).
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">How to Exercise Your Rights.</Typography>
          <Typography variant="body1">
            If you would like to exercise any of your rights as described in
            this Privacy Policy or if you have any questions about this Privacy
            Policy or the information we hold about you, you may contact us by
            toll-free phone number, email or write to use (see{' '}
            <b>How to Contact Us</b> below).
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">Changes to This Privacy Policy.</Typography>
          <Typography variant="body1">
            This Privacy Policy was published on {revisionDate} and last updated
            on {revisionDate}. We may change this Privacy Policy from time to
            time &mdash; when we do, we will inform you via our Website, the
            Service or other means of contact, such as email.
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">How to Contact Us.</Typography>
          <Typography variant="body1">
            Please contact us by post, email or telephone if you have any
            questions about this privacy policy or the information we hold about
            you. You can find contact information for AgencySmart at{' '}
            <a
              target="_blank"
              href="https://www.agencysmart.com/contact/"
              rel="noreferrer"
            >
              agencysmart.com/contact/
            </a>
            .
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography variant="h6">Do You Need Extra Help?</Typography>
          <Typography variant="body1">
            If you would like this notice in another format (for example: audio,
            large print, braille) please contact us (see{' '}
            <b>How to Contact Us</b> above).
          </Typography>
        </Box>
      </Box>
    </ModalWindow>
  );
};

export default PrivacyNotice;
