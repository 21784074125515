import React, { useRef, useState, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';

import Signature from 'components/Signature';
import fileDomain from 'api/domains/file';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import { IMidyearChangeData } from 'types/enrollments';

interface IProps {
  setStep: (data: IMidyearChangeData) => void;
  setEventId: (id: string | null) => void;
}

const StepSeven: React.FC<IProps> = ({ setStep, setEventId }) => {
  const [signatureStored, setSignatureStored] = useState(false);
  const sigCanvas = useRef<SignaturePad>(null);

  useEffect(() => {
    const handleResize = () => {
      setSignatureStored(false);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const onSaveSignature = async (canvas: HTMLCanvasElement) => {
    try {
      //   setIsLoading(true);
      const base64Signature = canvas.toDataURL();
      await fileDomain.signatureMidChanges({ signature: base64Signature });

      //   moveForward();
    } catch (e) {
      console.error(e);
      //     setError('Server error!');
    } finally {
      //   setIsLoading(false);
    }
  };

  const onSave = () => {
    if (sigCanvas.current) {
      const canvas = sigCanvas.current.getTrimmedCanvas();
      onSaveSignature(canvas);
      setEventId(null);
      setStep({ step: 1 });
    }
    return;
  };

  const check = () => {
    if (sigCanvas.current && signatureStored) {
      const canvas = sigCanvas.current?.getTrimmedCanvas();
      return canvas.width === 1 && canvas.height === 1;
    }
    return true;
  };

  return (
    <>
      <Signature
        sigCanvas={sigCanvas}
        setSignatureStored={setSignatureStored}
      />
      <SubmitButton
        label="Finish"
        variant="contained"
        onClick={onSave}
        disabled={check()}
      />
    </>
  );
};

export default StepSeven;
