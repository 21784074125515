import {
  BeneficiaryInterface,
  TableBeneficiaryData,
} from 'types/beneficiaries';

export const fromBeneficiaryToTableBeneficiary = (
  beneficiary: BeneficiaryInterface
): TableBeneficiaryData => ({
  firstName: beneficiary.firstName,
  lastName: beneficiary.lastName,
  beneficiaryType: beneficiary.beneficiaryType,
  share: beneficiary.share,
  id: beneficiary.id,
});
