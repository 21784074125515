export const toFixed = (num: number, precision: number): string => {
  return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(
    precision
  );
};

export const getSplitedNumericString = (
  num: number
): [string, string | undefined] => {
  const [int, dec] = num.toString().split('.');
  return [int, dec];
};

export const convertStringToNumber = (numString: string): number | null => {
  const num = +numString;
  return !Number.isNaN(num) && Number.isFinite(num) ? num : null;
};
