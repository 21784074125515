import React from 'react';
import { Typography, Box, Button, List, ListItem } from '@material-ui/core';
import fileDomain from 'api/domains/file';
import { IEmployeeFile, IShortFile } from 'types/files';

import styles from './styles.module.scss';

interface IProps {
  files?: IShortFile[] | IEmployeeFile[] | null;
  setLoading: (value: React.SetStateAction<boolean>) => void;
}

const ButtonFilesList: React.FC<IProps> = ({ files, setLoading }) => {
  const handleOpenFileInNewTab = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await fileDomain.downloadFile(id);
      const fileURL = URL.createObjectURL(data);
      window.open(fileURL);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={styles.list}>
      {files && files.length ? (
        <List>
          {(files as IShortFile[]).map((file, i) => (
            <ListItem key={i} className={styles.listItem}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleOpenFileInNewTab(file.id)}
              >
                {file.title}
              </Button>
            </ListItem>
          ))}
        </List>
      ) : (
        <Box className={styles.noData}>
          <Typography variant="body1" className={styles.placeholder}>
            No files are available at this time
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ButtonFilesList;
