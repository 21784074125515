import React, { useMemo } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

type OverridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends OverridesNameToClassKey {}
}
const Theme: React.FC = ({ children }) => {
  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: '#4979C0',
            dark: '#3f6bac',
          },
          secondary: {
            main: '#fff',
          },
          text: {
            primary: '#252733',
          },
        },
        typography: {
          fontFamily: '"Mulish", sans-serif',
        },
        overrides: {
          MuiPopover: {
            paper: {
              borderRadius: '10px',
              '& .MuiPickersBasePicker-pickerView': {
                height: 'fit-content',
                minHeight: '10px',
                width: 'fit-content',
                minWidth: '10px',
                margin: '10px',
              },
            },
          },
          MuiPickersCalendar: {
            progressContainer: {
              backgroundColor: '#A8E0FF',
            },
            transitionContainer: {
              minHeight: '180px',
            },
          },
          MuiPickersDatePickerRoot: {
            borderRadius: '4px',
            backgroundColor: '#A8E0FF',
          },

          MuiPickersCalendarHeader: {
            switchHeader: {
              padding: '5px 0',
              height: 'fit-content',
              margin: '15px 5px',
              background: '#A8E0FF',
              borderRadius: '5px',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '12px',
              color: '#2C2C2C',
            },
            iconButton: {
              width: '15px',
              height: '15px',
              backgroundColor: '#A8E0FF',
              color: '4979C0',
            },
            daysHeader: {
              paddingTop: '10px',
            },
            dayLabel: {
              width: 30,
              height: 15,
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '13px',
              lineHeight: '16px',
              color: '#2C2C2C',
            },
          },
          MuiPickersDay: {
            day: {
              width: 30,
              height: 30,
              color: '#9FA2B4',
              fontFamily: '"Mulish", sans-serif !important',
              fontStyle: 'normal !important',
              fontWeight: 'normal !important',
              fontSize: '12px !important',
            },
            daySelected: {
              backgroundColor: '#3DB9FE',
              color: '#fff',
              fontFamily: '"Mulish", sans-serif !important',
              '&:hover': {
                backgroundColor: '#2fa4e4',
                color: '#fff',
              },
            },
            current: {
              color: '#3DB9FE',
              fontFamily: '"Mulish", sans-serif !important',
            },
          },
          MuiPickersModal: {
            left: '50%',
            backgroundColor: '#A8E0FF',
            dialogAction: {
              width: 0,
              color: lightBlue['400'],
            },
          },
          MuiTextField: {
            root: {
              minHeight: '4.9em',
            },
          },
          MuiFormControl: {
            root: {
              minHeight: '4.9em',
            },
          },
        },
      }),
    []
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
