import React, { ChangeEvent, FocusEvent } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Box from '@material-ui/core/Box';

import { createStyles, FormLabel, makeStyles, Theme } from '@material-ui/core';
import FormCustomOnboardingFieldType from 'enums/formCustomOnboardingFieldType';
import DatePicker from 'components/Forms/Datepicker/DatepickerComponent';
import { MapField } from './types';
import TextField from './fields/TextField';
import TextAreaField from './fields/MultipleText';
import CheckboxField from './fields/CheckboxField';
import SsnField from './fields/SsnField';
import AmountField from './fields/AmountField';
import PhoneField from './fields/PhoneField';
import EmailField from './fields/EmailField';
import ZipField from './fields/ZipField';

interface IProps {
  field: MapField;
  mapKey: string;
  error: boolean;
  handleChange: (key: string, value: string | boolean) => void;
  handleErrorCheck: (key: string, value: string | boolean) => void;
  helperText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 0,
    },
    input: {
      marginTop: '10px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#252733',
      marginBottom: '10px',
    },
  })
);
const FieldFactory: React.FC<IProps> = (props) => {
  const {
    field,
    mapKey,
    handleChange,
    handleErrorCheck,
    error,
    helperText,
  } = props;

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handleChange(mapKey, value);
    handleErrorCheck(mapKey, value);
  };

  const handleTextFieldBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    handleErrorCheck(mapKey, value);
  };

  const handleCheckboxChange = (_: any, checked: boolean) => {
    handleChange(mapKey, checked);
    handleErrorCheck(mapKey, checked);
  };

  const handleDatePick = (_: MaterialUiPickersDate, value?: string | null) => {
    if (!value) {
      handleChange(mapKey, '');
      handleErrorCheck(mapKey, '');
      return;
    }

    handleChange(mapKey, value);
    handleErrorCheck(mapKey, value);
  };

  const getTextValue = (): string => {
    if (typeof field.value === 'boolean') {
      return '';
    }
    return field.value || '';
  };

  const getCheckBoxValue = () => {
    return !!field.value;
  };

  const getDateValue = () => {
    if (typeof field.value === 'string') {
      return field.value || null;
    }
    return null;
  };

  const defaultTextFieldProps = {
    value: getTextValue(),
    onChange: handleTextFieldChange,
    onBlur: handleTextFieldBlur,
    label: field.fieldLabel,
    error: error,
    helperText: helperText,
  };

  const inputClasses = useStyles();
  switch (field.fieldType) {
    case FormCustomOnboardingFieldType.Text:
      return <TextField {...defaultTextFieldProps} />;
    case FormCustomOnboardingFieldType.Textarea:
      return <TextAreaField {...defaultTextFieldProps} />;
    case FormCustomOnboardingFieldType.CheckBox:
      return (
        <CheckboxField
          checked={getCheckBoxValue()}
          onChange={handleCheckboxChange}
          label={field.fieldLabel}
        />
      );
    case FormCustomOnboardingFieldType.Date:
      return (
        <Box mb="1em">
          <FormLabel className={inputClasses.label} component="legend">
            {field.fieldLabel}
          </FormLabel>
          <DatePicker
            onChange={handleDatePick}
            value={getDateValue()}
            error={error}
            helperText={helperText}
          />
        </Box>
      );
    case FormCustomOnboardingFieldType.Amount:
      return <AmountField {...defaultTextFieldProps} />;
    case FormCustomOnboardingFieldType.Phone:
      return <PhoneField {...defaultTextFieldProps} />;
    case FormCustomOnboardingFieldType.Ssn:
      return <SsnField {...defaultTextFieldProps} />;
    case FormCustomOnboardingFieldType.Email:
      return <EmailField {...defaultTextFieldProps} />;
    case FormCustomOnboardingFieldType.Zip:
      return <ZipField {...defaultTextFieldProps} />;
    case FormCustomOnboardingFieldType.State:
      return <TextField {...defaultTextFieldProps} />;
    default:
      return null;
  }
};

export default FieldFactory;
