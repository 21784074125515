import {
  IFsaContributionAmount,
  IFsaContributionAmountNumbered,
} from 'types/enrollments';

const convertTypeHelper = (value?: string): number | undefined =>
  (typeof value === 'string' && value.length) ||
  !Number.isNaN(value) ||
  typeof value === 'number' ||
  !Number.isNaN(value)
    ? Number(value)
    : undefined;

export const fromFSAContributionToNumberedFSAContribution = (
  data: IFsaContributionAmount
): IFsaContributionAmountNumbered => ({
  med: convertTypeHelper(data.med),
  dep: convertTypeHelper(data.dep),
  tra: convertTypeHelper(data.tra),
  park: convertTypeHelper(data.park),
});
