import React, { useState, useEffect, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
import Feedbacker from 'components/Feedbacker';
import BeneficiariesReadOnlyList from 'components/BeneficiariesReadOnlyList';
import SectionTitle from 'components/SectionTitle';
import PasswordPrompt from 'components/PasswordPrompt';
import OpenEnrollmentsInfo from 'components/OpenEnrollmentsInfo';
import RestartRegistrationModal from 'components/Modal/RestartRegistrationModal';

import ExternalRegistrationStep from 'enums/externalRegistrationStep';
import beneficiariesDomains from 'api/domains/beneficiaries';
import userDomain from 'api/domains/user';
import { loadCurrentUser } from 'redux/sagas/user/routines';
import { AppStoreInterface } from 'store/app';
import {
  ExternalBeneficiaryInterface,
  IBeneficiaryPersonal,
} from 'types/beneficiaries';
import DependentsSkeleton from '../Dependents/DependentsSkeleton';

import styles from './styles.module.scss';

interface IProps {
  testUser: string | null;
  impersonatingUserId: string | null;
  newHire: boolean | null;
  openEnrollmentEndDate: string | null;
  eligibilityDate: string | null;
  actions: {
    loadCurrentUser: typeof loadCurrentUser.trigger;
  };
}

const Beneficiaries: React.FC<IProps> = (props) => {
  const {
    testUser,
    impersonatingUserId,
    newHire,
    openEnrollmentEndDate,
    eligibilityDate,
    actions,
  } = props;

  const [restartRegistrationModal, setRestartRegistrationModal] = useState(
    false
  );
  const [passwordPromptOpen, setPasswordPromptOpen] = useState(false);

  const [personalData, setPersonalData] = useState<IBeneficiaryPersonal[]>([]);
  const [beneficiaries, setBeneficiaries] = useState<
    ExternalBeneficiaryInterface[]
  >([]);
  const [apiError, setApiError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isPasswordPrompt = !(
    parseInt(testUser as string) || !!impersonatingUserId
  );

  const handleShowPersonalData = async (password: string) => {
    try {
      const { data } = await beneficiariesDomains.getPersonal(password);
      setPersonalData(data);
    } catch (err) {
      throw err;
    }
  };

  const handleEditBeneficiariesClick = async () => {
    try {
      const step = (ExternalRegistrationStep.Beneficiaries - 1).toString();
      await userDomain.saveRegistrationStep(step);
      actions.loadCurrentUser();
    } catch (e) {
      console.error(e);
    }
  };

  const handleHidePersonalData = () => {
    setPersonalData([]);
  };

  const clearError = () => setApiError(undefined);

  const setTemporaryApiError = useCallback(
    (error: string) => {
      setApiError(error);
      setTimeout(clearError, 5000);
    },
    [setApiError]
  );

  useEffect(() => {
    setIsLoading(true);
    beneficiariesDomains
      .getList()
      .then(({ data }) => setBeneficiaries(data))
      .catch(() =>
        setTemporaryApiError('Error, when trying to fetch beneficiaries')
      )
      .finally(() => setIsLoading(false));
  }, [setTemporaryApiError]);

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Beneficiaries</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <SectionTitle title="My Beneficiaries" />
        <Grid container className={styles.mainGrid}>
          <Grid sm={12} md={9} item className={styles.firstGrid}>
            {isLoading ? (
              <DependentsSkeleton />
            ) : (
              <BeneficiariesReadOnlyList
                beneficiaries={beneficiaries}
                personalData={personalData}
                handleOpenModal={() => setPasswordPromptOpen(true)}
                handleHidePersonalData={handleHidePersonalData}
                handleShowPersonalData={handleShowPersonalData}
                isPasswordPrompt={isPasswordPrompt}
              />
            )}
          </Grid>
          <Grid sm={12} md={3} item className={styles.secondGrid}>
            <OpenEnrollmentsInfo
              eligibilityDate={eligibilityDate}
              openEnrollmentEndDate={openEnrollmentEndDate}
              newHire={newHire}
              handleOpenConfirmModal={() => setRestartRegistrationModal(true)}
              btnName="Edit Beneficiaries"
            />
          </Grid>
        </Grid>
        <Feedbacker
          open={!!apiError}
          feedbackMessage={apiError || ''}
          clearFeedback={clearError}
          severity="error"
        />
        <PasswordPrompt
          isOpen={passwordPromptOpen}
          handleClose={() => setPasswordPromptOpen(false)}
          onSubmit={handleShowPersonalData}
        />
        <RestartRegistrationModal
          open={restartRegistrationModal}
          handleClose={() => setRestartRegistrationModal(false)}
          handleSubmit={handleEditBeneficiariesClick}
        />
      </Box>
    </>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  testUser: store.user.testUser,
  impersonatingUserId: store.user.impersonatingUserId,
  newHire: store.user.newHire,
  openEnrollmentEndDate: store.user.openEnrollmentEndDate,
  eligibilityDate: store.user.eligibilityDate,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      loadCurrentUser: typeof loadCurrentUser.trigger;
    }
  >(
    {
      loadCurrentUser: loadCurrentUser.trigger,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Beneficiaries);
