import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import ILoginCredentials from 'models/ILoginCredentials';
import { Actions as UserActions } from 'redux/reducers/user/actions';
import { AppStoreInterface } from 'store/app';
import * as userRoutines from 'redux/sagas/user/routines';
import BoxComponent from 'components/LoginBox/BoxComponent';
import LoginForm from './LoginForm';

interface IProps {
  login: (payload: ILoginCredentials) => void;
  logining: boolean;
  error: string | null;
  clearFeedback: typeof UserActions.clearFeedback;
}

const LoginContainer: React.FC<IProps> = (props) => {
  return (
    <BoxComponent>
      <Helmet>
        <title>MyBenefitLink | Login</title>
      </Helmet>
      <LoginForm {...props} />
    </BoxComponent>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  logining: store.user.logining,
  error: store.user.error,
});

const mapDispatchToProps = {
  login: userRoutines.login,
  clearFeedback: UserActions.clearFeedback,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
