import { createAction, ActionsUnion } from 'helpers/createAction';
import { PartialUserAccount, PartialUserProfile } from 'types/user';
import { DependentInterface } from 'types/dependents';
import * as actionTypes from './actionTypes';

export const Actions = {
  addAccountData: (payload: PartialUserAccount) =>
    createAction(actionTypes.ADD_ACCOUNT_DATA, payload),

  addProfileData: (payload: PartialUserProfile) =>
    createAction(actionTypes.ADD_PROFILE_DATA, payload),

  createDependent: (payload: DependentInterface) =>
    createAction(actionTypes.ADD_DEPENDENT, payload),

  editDependent: (payload: DependentInterface) =>
    createAction(actionTypes.EDIT_DEPENDENT, payload),

  deleteDependent: (id: string) =>
    createAction(actionTypes.DELETE_DEPENDENT, { id }),
};

export type ActionTypes = ActionsUnion<typeof Actions>;
