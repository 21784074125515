import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import IBasicOption from 'models/IBasicOption';
import { DependentInterface, IDependentPersonal } from 'types/dependents';
import DependentReadOnlyItem from './DependentReadOnlyItem';

import styles from './styles.module.scss';

interface IProps {
  dependents: DependentInterface[] | null;
  gender: IBasicOption<number>[];
  personalData: IDependentPersonal[];
  handleHidePersonalData: () => void;
  handleOpenModal: () => void;
  handleShowPersonalData: (password: string) => Promise<void>;
  isPasswordPrompt: boolean;
}

const DependentReadOnlyList: React.FC<IProps> = (props) => {
  const {
    dependents,
    gender,
    personalData,
    handleHidePersonalData,
    handleOpenModal,
    handleShowPersonalData,
    isPasswordPrompt,
  } = props;

  let dependentsWithPersonalData: DependentInterface[] | undefined;

  if (dependents) {
    dependentsWithPersonalData = dependents.map((dependent) => {
      const matchedPersonalData = personalData.find(
        (data) => data.id === dependent.id
      );
      return matchedPersonalData
        ? { ...dependent, ...matchedPersonalData }
        : dependent;
    });
  }

  return (
    <>
      {dependentsWithPersonalData && dependentsWithPersonalData.length > 0 ? (
        <Grid container spacing={3} className={styles.cardsGridContainer}>
          {dependentsWithPersonalData.map((dependent) => (
            <Grid key={dependent.id} item xs={12} sm={6} lg={4}>
              <DependentReadOnlyItem
                handleHidePersonalData={handleHidePersonalData}
                handleOpenModal={handleOpenModal}
                dependent={dependent}
                gender={gender}
                handleShowPersonalData={handleShowPersonalData}
                isPasswordPrompt={isPasswordPrompt}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box className={styles.noDependents}>
          <Typography variant="body1" className={styles.placeholder}>
            You don't have any dependents
          </Typography>
        </Box>
      )}
    </>
  );
};

export default DependentReadOnlyList;
