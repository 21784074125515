import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/ResetPassword';
import Registration from 'containers/Registration';
import Login from 'containers/Login';
import Profile from 'containers/Profile';
import EmployeesCensus from 'containers/EmployeesCensus';
import Contact from 'containers/Contact';
import Dependents from 'containers/Dependents';
import Dashboard from 'containers/Dashboard';
import AuditLogs from 'containers/AuditLogs';
import ChangePassword from 'containers/ChangePassword';
import ActionCenter from 'containers/ActionCenter';
import AdminAuditLogs from 'containers/AdminAuditLogs';
import Reports from 'containers/Reports';
import Documents from 'containers/Documents';
import StatusDashboard from 'containers/StatusDashboard';
import Onboarding from 'containers/Onboarding';
import EmployerForms from 'containers/EmployerForms';
import Beneficiaries from 'containers/Beneficiaries';
import Transactions from 'containers/TransactionsReport';
import EmailCommunications from 'containers/EmailCommunications';
import ChangeSequrityQuestion from 'containers/ChangeSequrityQuestion';
import ChangeBenefits from 'containers/ChangeBenefits';

import RoleType from 'enums/roleType';
import ExternalRegistrationStep from 'enums/externalRegistrationStep';

import {
  FORGOT_PASSWORD_PATH,
  REGISTRATION_PATH,
  DEFAULT_PATH,
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
  PROFILE_PATH,
  CONTACT_PATH,
  DEPENDENTS_PATH,
  DOCUMENTS_PATH,
  AUDIT_LOGS_PATH,
  ONBOARDING_PATH,
  EMPLOYEES_CENSUS,
  CHANGE_PASSWORD_PATH,
  ACTION_CENTER_PATH,
  ADMIN_AUDIT_LOGS_PATH,
  REPORTS_PATH,
  STATUS_DASHBOARD_PATH,
  EMPLOYER_FORMS,
  BENEFICIARIES_PATH,
  TRANSACTIONS_PATH,
  EMAIL_PATH,
  CHANGE_SECURITY_QUESTION_PATH,
  CHANGE_BENEFITS,
} from 'constants/routes';
import ExternalOnboardingStep from 'enums/externalOnboardingStep';
import RoleTypeRoute from './RoleTypeRoute';
import NoMatch from './NoMatchRoute';

const authenticatedAppRoutes = [
  PROFILE_PATH,
  CONTACT_PATH,
  DEPENDENTS_PATH,
  DOCUMENTS_PATH,
  AUDIT_LOGS_PATH,
  EMPLOYEES_CENSUS,
  CHANGE_PASSWORD_PATH,
  ACTION_CENTER_PATH,
  ADMIN_AUDIT_LOGS_PATH,
  REPORTS_PATH,
  STATUS_DASHBOARD_PATH,
  EMPLOYER_FORMS,
  BENEFICIARIES_PATH,
  TRANSACTIONS_PATH,
];

const notAuthenticatedAppRoutes = [
  LOGIN_PATH,
  REGISTRATION_PATH,
  ONBOARDING_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
];

interface IProps {
  isAuthenticated?: boolean;
  registrationStep: number | null;
  onboardingStep: number | null;
  passwordReset?: boolean;
  securityQuestionReset?: boolean;
}

const RootRouter: React.FC<IProps> = ({
  isAuthenticated,
  registrationStep,
  onboardingStep,
  passwordReset,
  securityQuestionReset,
}) => {
  const authenticatedSwitch = (
    <Switch>
      <RoleTypeRoute
        exact
        path={STATUS_DASHBOARD_PATH}
        roleTypes={[RoleType.Admin]}
        component={StatusDashboard}
      />
      <RoleTypeRoute
        exact
        path={EMPLOYEES_CENSUS}
        roleTypes={[RoleType.Admin]}
        component={EmployeesCensus}
      />
      <RoleTypeRoute
        exact
        path={ACTION_CENTER_PATH}
        roleTypes={[RoleType.Admin]}
        component={ActionCenter}
      />
      <RoleTypeRoute
        exact
        path={REPORTS_PATH}
        roleTypes={[RoleType.Admin]}
        component={Reports}
      />
      <RoleTypeRoute
        path={ADMIN_AUDIT_LOGS_PATH}
        roleTypes={[RoleType.Admin]}
        component={AdminAuditLogs}
      />
      <RoleTypeRoute
        path={EMPLOYER_FORMS}
        roleTypes={[RoleType.Admin]}
        component={EmployerForms}
      />
      <RoleTypeRoute
        path={TRANSACTIONS_PATH}
        roleTypes={[RoleType.Admin]}
        component={Transactions}
      />
      <RoleTypeRoute
        path={EMAIL_PATH}
        roleTypes={[RoleType.Admin]}
        component={EmailCommunications}
      />
      <Route exact path={PROFILE_PATH}>
        <Profile />
      </Route>
      <Route exact path={CONTACT_PATH}>
        <Contact />
      </Route>
      <Route exact path={DEPENDENTS_PATH}>
        <Dependents />
      </Route>
      <Route exact path={BENEFICIARIES_PATH}>
        <Beneficiaries />
      </Route>
      <Route exact path={AUDIT_LOGS_PATH}>
        <AuditLogs />
      </Route>
      <Route exact path={CHANGE_PASSWORD_PATH}>
        <ChangePassword />
      </Route>
      <Route exact path={DEFAULT_PATH}>
        <Dashboard />
      </Route>
      <Route path={DOCUMENTS_PATH}>
        <Documents />
      </Route>
      <Route exact path={CHANGE_BENEFITS}>
        <ChangeBenefits />
      </Route>
      <Route path="*">
        <NoMatch routes={notAuthenticatedAppRoutes} />
      </Route>
    </Switch>
  );

  const notAuthenticatedSwitch = (
    <Switch>
      <Route exact path={LOGIN_PATH}>
        <Login />
      </Route>
      <Route exact path={FORGOT_PASSWORD_PATH}>
        <ForgotPassword />
      </Route>
      <Route exact path={RESET_PASSWORD_PATH}>
        <ResetPassword />
      </Route>
      <Route exact path={REGISTRATION_PATH}>
        <Registration />
      </Route>
      <Route exact path={DEFAULT_PATH}>
        <Login />
      </Route>
      <Route path="*">
        <NoMatch routes={authenticatedAppRoutes} />
      </Route>
    </Switch>
  );

  /**
   * If user should change password
   */
  if (passwordReset) {
    return (
      <Switch>
        <Route path={CHANGE_PASSWORD_PATH}>
          <ChangePassword passwordReset={passwordReset} />
        </Route>
        <Route path="*">
          <Redirect to={CHANGE_PASSWORD_PATH} />
        </Route>
      </Switch>
    );
  }

  /**
   * If user should change password
   */
  if (securityQuestionReset) {
    return (
      <Switch>
        <Route path={CHANGE_SECURITY_QUESTION_PATH}>
          <ChangeSequrityQuestion />
        </Route>
        <Route path="*">
          <Redirect to={CHANGE_SECURITY_QUESTION_PATH} />
        </Route>
      </Switch>
    );
  }

  const registrationStepIndex =
    typeof registrationStep === 'number' ? registrationStep : undefined;
  const onboardingStepIndex =
    typeof onboardingStep === 'number' ? onboardingStep : undefined;

  /*
    if onboarding is not finished
  */
  if (
    typeof onboardingStep === 'number' &&
    onboardingStep !== ExternalOnboardingStep.OnboardingIsFinished &&
    isAuthenticated
  ) {
    return (
      <Switch>
        <Route path={ONBOARDING_PATH}>
          <Onboarding
            onboardingStepIndex={onboardingStepIndex}
            registrationStepIndex={registrationStepIndex}
          />
        </Route>
        <Route path="*">
          <Redirect to={ONBOARDING_PATH} />
        </Route>
      </Switch>
    );
  }

  /*
    if registration is not finished
  */
  if (
    typeof registrationStep === 'number' &&
    registrationStep !== ExternalRegistrationStep.RegistrationIsFinished &&
    isAuthenticated
  ) {
    return (
      <Switch>
        <Route path={REGISTRATION_PATH}>
          <Registration registrationStepIndex={registrationStepIndex} />
        </Route>
        <Route path="*">
          <Redirect to={REGISTRATION_PATH} />
        </Route>
      </Switch>
    );
  }

  return isAuthenticated ? authenticatedSwitch : notAuthenticatedSwitch;
};

export default RootRouter;
