import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';

import SectionTitle from 'components/SectionTitle';
import Backdrop from 'components/Backdrop';
import contactsDomain from 'api/domains/contacts';
import { IContact } from 'types/contacts';
import ContactType from 'enums/contactType';
import ContactInsuranceAgent from './ContactInsuranceAgent';
import ContactHr from './ContactHr';

import styles from './styles.module.scss';

const Contact: React.FC = () => {
  const [contacts, setContacts] = React.useState<IContact[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const hrs: IContact[] = React.useMemo(
    () => contacts.filter((contact) => contact.contactType === ContactType.Hr),
    [contacts]
  );

  const agents: IContact[] = React.useMemo(
    () =>
      contacts.filter((contact) => contact.contactType === ContactType.Agent),
    [contacts]
  );

  React.useEffect(() => {
    setIsLoading(true);
    contactsDomain
      .get()
      .then(({ data }) => setContacts(data))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, []);

  const noContactsMessage = (
    <Box className={styles.noContactsContainer}>
      <Typography variant="h4" className={styles.noContactsMessage}>
        List of contacts is empty for now
      </Typography>
      <Typography className={styles.noContactsMessage}>
        Try to come back here later
      </Typography>
    </Box>
  );

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Contact</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <SectionTitle title="Contact" />

        <Box className={styles.sectionContainer}>
          {Boolean(hrs.length) && (
            <ContactHr hrs={hrs} setIsLoading={setIsLoading} />
          )}
          {Boolean(agents.length) && (
            <ContactInsuranceAgent
              agents={agents}
              setIsLoading={setIsLoading}
            />
          )}
          {!isLoading && !hrs.length && !agents.length && noContactsMessage}
        </Box>
      </Box>
      <Backdrop open={isLoading} />
    </>
  );
};

export default Contact;
