import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import Currency from 'components/Currency';
import { IPlanSummary } from 'types/plans';
import PlanTypeNumeric from 'enums/planTypeNumeric';
import { WILL_BE_ELIGIBLE } from 'constants/messages';
import { DEFAULT_DATE_FORMAT } from 'constants/app';
import moment from 'moment';
import styles from './styles.module.scss';

interface IProps {
  plans?: IPlanSummary[];
  onPlanClick: (planId: string) => void | Promise<void>;
}

const ElectedPlansTable: React.FC<IProps> = ({ plans, onPlanClick }) => {
  const totalContributionPayPeriod =
    plans &&
    plans.reduce(
      (curr, { contributionAmount }) => curr + contributionAmount,
      0
    );

  const getCovered = (dependents: string[]) => {
    const dependentsFirstNames = dependents.map((dependent) =>
      dependent.replace(/\s(.*)/, '')
    );
    return ['You', ...dependentsFirstNames].join(', ');
  };

  const isEligibilityDateInPast = (eligibilityDate: string) => {
    return moment(eligibilityDate, DEFAULT_DATE_FORMAT).toDate() < new Date();
  };
  const hsaDependents = plans?.find(
    (plan) => +plan.planTypeId === PlanTypeNumeric.Medical
  )?.dependents;

  return (
    <>
      <Toolbar className={styles.electedTableToolbar}>
        <Typography className={styles.tableHeader} variant="body2">
          Plans You Elected
        </Typography>
      </Toolbar>
      <Box className={styles.tableBox}>
        <TableContainer component={Box}>
          <Table className={styles.electedTable}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Box className={styles.headerCell}>Plan Name</Box>
                </TableCell>
                <TableCell align="left">
                  <Box className={styles.headerCell}>Covered</Box>
                </TableCell>
                <TableCell align="left">
                  <Box className={styles.headerCell}>Effective Until</Box>
                </TableCell>
                <TableCell align="left">
                  <Box className={styles.headerCell}>Policy Number</Box>
                </TableCell>
                <TableCell align="left">
                  <Box className={styles.headerCell}>Member ID</Box>
                </TableCell>
                <TableCell align="left">
                  <Box className={styles.headerCell}>
                    Contribution per pay period
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans && plans.length ? (
                plans.map((plan, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      <Box className={styles.planBox}>
                        <Button
                          disableTouchRipple
                          onClick={() => onPlanClick(plan.planId)}
                          className={styles.planDetailsBtn}
                          variant="text"
                        >
                          <PlanIcons planTypeId={Number(plan.planTypeId)} />
                          <Box className={styles.nameBox}>
                            {plan.planName}
                            {plan?.eligibilityDate &&
                              !isEligibilityDateInPast(
                                plan?.eligibilityDate
                              ) && (
                                <Box>
                                  <Typography
                                    className={styles.subInfo}
                                    variant="body2"
                                  >
                                    {WILL_BE_ELIGIBLE(plan?.eligibilityDate)}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        </Button>
                        <Button
                          className={styles.infoBtn}
                          variant="outlined"
                          color="primary"
                          onClick={() => onPlanClick(plan.planId)}
                        >
                          Info
                        </Button>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      {+plan.planTypeId === PlanTypeNumeric.HSA
                        ? hsaDependents
                          ? getCovered(hsaDependents)
                          : 'You'
                        : plan.dependents
                        ? getCovered(plan.dependents)
                        : 'You'}
                    </TableCell>
                    <TableCell align="left">{plan.effectiveToDate}</TableCell>
                    <TableCell align="left">{plan.policyNumber}</TableCell>
                    <TableCell align="left">{plan.memberNumber}</TableCell>
                    <TableCell align="left">
                      <Currency fixed={2}>{plan.contributionAmount}</Currency>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography
                      variant="h6"
                      align="center"
                      className={styles.placeholder}
                    >
                      You are not enrolled in any plans
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5} align="right" />
                <TableCell align="left">
                  <Currency fixed={2}>{totalContributionPayPeriod}</Currency>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ElectedPlansTable;
