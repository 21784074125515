import React from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { SvgIcon, Box, Typography } from '@material-ui/core';
import { ReactComponent as infoIcon } from 'assets/info.svg';

import styles from './styles.module.scss';

interface IProps {
  eligibilityDate: string | null;
  openEnrollmentEndDate: string | null;
  newHire: boolean | null;
  btnName: string;
  fullWidth?: boolean;
  handleOpenConfirmModal: () => void;
}

const OpenEnrollmentsInfo: React.FC<IProps> = ({
  eligibilityDate,
  openEnrollmentEndDate,
  newHire,
  btnName,
  fullWidth = false,
  handleOpenConfirmModal,
}) => {
  /*const alertClassName = setClassNames({
    [styles.infoAlert]: !fullWidth,
    [styles.infoAlertFullWidth]: fullWidth,
  });*/

  const isStillOEE = moment(openEnrollmentEndDate, 'MM-DD-YYYY').isAfter();
  const infoVisible = Boolean(newHire) || isStillOEE;

  const showEligibilityDate = Boolean(newHire);

  return (
    <Box className={styles.container}>
      {infoVisible ? (
        <Box className={styles.alert}>
          <Box className={styles.mainContainer}>
            <SvgIcon
              component={infoIcon}
              className={styles.icon}
              width="19"
              height="20"
              viewBox="0 0 19 20"
            />
            <Box className={styles.textContainer}>
              {showEligibilityDate ? (
                <Typography variant="body1">{`You have until ${eligibilityDate} to make changes.`}</Typography>
              ) : (
                <Typography variant="body1">{`You have until ${openEnrollmentEndDate} to make changes.`}</Typography>
              )}
            </Box>
          </Box>
          <Box mt="1em">
            <Button
              className={styles.editProfileBtn}
              variant="contained"
              color="primary"
              onClick={handleOpenConfirmModal}
            >
              {btnName}
            </Button>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default OpenEnrollmentsInfo;
