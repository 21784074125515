import { createAction, ActionsUnion } from 'helpers/createAction';
import IBasicOption from 'models/IBasicOption';
import {
  genderOptions,
  maritalStatusOptions,
  smokerOptions,
  securityQuestionOptions,
} from 'redux/sagas/options/routines';
import * as actionTypes from './actionTypes';

export const Actions = {
  addGenderOptions: (payload: IBasicOption<number>[]) =>
    createAction(genderOptions.SUCCESS, payload),

  addSmokerOptions: (payload: IBasicOption<number>[]) =>
    createAction(smokerOptions.SUCCESS, payload),

  addMaritalStatusOptions: (payload: IBasicOption<number>[]) =>
    createAction(maritalStatusOptions.SUCCESS, payload),

  addSecurityQuestionOptions: (payload: IBasicOption<number>[]) =>
    createAction(securityQuestionOptions.SUCCESS, payload),

  fetchAllOptionsForAuthenticatedUser: () =>
    createAction(actionTypes.FETCH_ALL_AUTH_OPTIONS),

  fetchAllOptionsForNonAuthenticatedUser: () =>
    createAction(actionTypes.FETCH_ALL_NON_AUTH_OPTIONS),
};

export type ActionTypes = ActionsUnion<typeof Actions>;
