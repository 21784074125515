import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Actions as RegistrationActions } from 'redux/reducers/userRegistration/actions';
import { useFormik } from 'formik';
import { Box, Typography } from '@material-ui/core';
import * as yup from 'yup';

import Skeleton from 'components/Skeleton';
import ConfirmationModal from 'components/Modal/ConfirmationModalComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import SimpleStepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import ControlledProgressBar from 'components/ProgressBar/ControlledProgressBarComponent';
import FillFieldsList, {
  FieldsInterface,
} from 'components/FillFieldsList/FillFieldsListComponent';

import ApiError from 'enums/apiError';
import AuthMethod from 'enums/authMethod';
import ICommonRegistrationStepProps from 'models/ICommonRegistrationStepProps';
import IBasicOption from 'models/IBasicOption';
import IShortEmployeeData from 'models/IShortEmployeeData';

import { REGISTRATION_CODE_KEY } from 'constants/queryParams';
import { LOGIN_PATH } from 'constants/routes';
import { ACCESS_TOKEN } from 'constants/storeKeys';
import {
  ALREADY_REGISTERED,
  REGISTRATION_TOKEN_NOT_VALID,
} from 'constants/feedbackMessages';

import storage from 'helpers/storage';
import useQuery from 'hooks/useQuery';
import userDomain from 'api/domains/user';
import ICreateAccount from 'models/ICreateAccount';
import { AppStoreInterface } from 'store/app';
import { loadUserShortData } from 'redux/sagas/userRegistration/routines';

import history from 'helpers/history';
import { passwordStrength } from 'helpers/passwordStrength';
import * as validationHelper from 'helpers/validation';
import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';

import styles from './styles.module.scss';

interface IFormikValues {
  email: string;
  password: string;
  reEnteredPassword: string;
  securityQuestion: IBasicOption<number> | string;
  securityQuestionAnswer: string;
}

export interface AccountSettingsPropsInterface
  extends ICommonRegistrationStepProps {
  label: string;
  index: number;
  count: number;
  userShortData: Partial<IShortEmployeeData>;
  securityQuestions: IBasicOption<number>[];
  registrationTokenError: ApiError | null;
  loading: boolean;
  isAuthenticated: boolean;
  authenticationMethod: AuthMethod | null;
  actions: {
    loadUserShortData: typeof loadUserShortData.trigger;
    addProfileData: typeof RegistrationActions.addProfileData;
  };
}

const MAX_PASSWORD_STRENGTH = 100;

const validationSchema = yup.object().shape({
  email: validationHelper.email,
  password: validationHelper.password,
  reEnteredPassword: validationHelper.reEnteredPassword,
  securityQuestion: yup.string().required('Field is required'),
  securityQuestionAnswer: yup.string().required('Field is required'),
});

const initialValues: IFormikValues = {
  email: '',
  password: '',
  reEnteredPassword: '',
  securityQuestion: '',
  securityQuestionAnswer: '',
};

const AccountSettings: React.FC<AccountSettingsPropsInterface> = (props) => {
  const queryParams = useQuery();
  const token = queryParams.get(REGISTRATION_CODE_KEY);

  const {
    actions,
    moveForward,
    setIsLoading,
    userShortData,
    securityQuestions,
    registrationTokenError,
    isAuthenticated,
    authenticationMethod,
    loading,
    index,
    count,
    label,
  } = props;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const {
    setFieldValue,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
  } = formik;

  useEffect(() => {
    if (token) {
      actions.loadUserShortData({ token });
    }
  }, [token, actions]);

  useEffect(() => {
    if (isAuthenticated && authenticationMethod === AuthMethod.Username) {
      actions.loadUserShortData();
    }
  }, [actions, authenticationMethod, isAuthenticated]);

  useEffect(() => {
    if (userShortData.email) {
      setFieldValue('email', userShortData.email.trim());
    }
  }, [userShortData.email, setFieldValue]);

  const isAuthByEmail = userShortData.authenticationMethod === AuthMethod.Email;

  async function handleSubmit(values: IFormikValues) {
    const {
      password,
      securityQuestionAnswer,
      securityQuestion,
      email,
    } = values;

    const commonPayloadValues = {
      token,
      password,
      securityQuestionId: securityQuestion.toString(),
      securityAnswer: securityQuestionAnswer,
    };
    let payload: ICreateAccount;

    if (isAuthByEmail) {
      payload = { ...commonPayloadValues };
    } else {
      payload = { ...commonPayloadValues, email };
    }

    try {
      await setIsLoading(true);
      const { data } = await userDomain.register(payload);
      storage.set(ACCESS_TOKEN, data.jwt);
      /**
       * Prefetch options for next steps
       */
      await moveForward();
      history.replace({
        search: '',
      });
    } catch (error) {
      console.error(error);
    } finally {
      await setIsLoading(false);
    }
  }

  const getEmployeeName = () => {
    const { firstName, lastName } = userShortData;

    if (!firstName) {
      return '';
    }
    return `${firstName} ${lastName || ''}`;
  };

  const handleLoginRedirect = () => {
    storage.remove(ACCESS_TOKEN);
    history.push(LOGIN_PATH);
    history.go(0);
  };

  const getErrorMessage = (key: keyof IFormikValues): string | undefined => {
    const error = errors[key];
    const isTouched = touched[key];
    return isTouched && error ? (error as string) : '';
  };

  const getFields = (): FieldsInterface[] => {
    return [
      {
        inputComponentData: {
          value: isAuthByEmail
            ? userShortData.email || ''
            : userShortData.username || '',
          name: 'username',
          label: 'Username',
          onChange: () => {},
          readOnly: true,
        },
        key: 'username',
      },
      {
        inputComponentData: {
          value: values.email,
          name: 'email',
          label: 'Email',
          onChange: handleChange,
          onBlur: handleBlur,
          helperText: getErrorMessage('email'),
          error: getErrorMessage('email'),
          hidden: isAuthByEmail,
        },
        key: 'email',
      },
      {
        inputComponentData: {
          value: values.password,
          name: 'password',
          label: 'Password',
          isPassword: true,
          onChange: handleChange,
          onBlur: handleBlur,
          secured: true,
          helperText: getErrorMessage('password'),
          error: getErrorMessage('password'),
        },
        key: 'password',
      },
      {
        component: passwordStrengthBar,
        key: 'passwordStrength',
      },
      {
        inputComponentData: {
          value: values.reEnteredPassword,
          name: 'reEnteredPassword',
          label: 'Reenter password',
          isPassword: true,
          onChange: handleChange,
          onBlur: handleBlur,
          secured: true,
          helperText: getErrorMessage('reEnteredPassword'),
          error: getErrorMessage('reEnteredPassword'),
        },
        key: 'reEnteredPassword',
      },
      {
        selectComponentData: {
          name: 'securityQuestion',
          options: securityQuestions,
          label: 'Security question',
          onChange: handleChange,
          onBlur: handleBlur,
          value: values.securityQuestion,
          error: !!getErrorMessage('securityQuestion'),
          helperText: getErrorMessage('securityQuestion'),
        },
        key: 'securityQuestion',
      },
      {
        inputComponentData: {
          value: values.securityQuestionAnswer,
          name: 'securityQuestionAnswer',
          label: 'Your answer',
          onChange: handleChange,
          onBlur: handleBlur,
          readOnly: !values.securityQuestion,
          error: getErrorMessage('securityQuestionAnswer'),
          helperText: getErrorMessage('securityQuestionAnswer'),
        },
        key: 'securityQuestionAnswer',
      },
    ];
  };

  const tokenNotValidModal = (
    <ConfirmationModal
      isOpen={registrationTokenError === ApiError.Forbidden}
      onSubmit={handleLoginRedirect}
    >
      <SimpleAlert type="error">
        <Typography component="h5">{REGISTRATION_TOKEN_NOT_VALID}</Typography>
      </SimpleAlert>
    </ConfirmationModal>
  );

  const userAlreadyRegisteredModal = (
    <ConfirmationModal
      isOpen={registrationTokenError === ApiError.Conflict}
      onSubmit={handleLoginRedirect}
    >
      <SimpleAlert type="info">
        <Typography component="h5">{ALREADY_REGISTERED}</Typography>
      </SimpleAlert>
    </ConfirmationModal>
  );

  const passwordStrengthBar = (
    <ControlledProgressBar
      className="password-strength"
      max={MAX_PASSWORD_STRENGTH}
      value={passwordStrength(values.password)}
    />
  );

  if (loading) {
    return <Skeleton variant="rect" height="3em" />;
  }

  return (
    <>
      <ContentTableBox title={label} index={index} count={count} isBox>
        <Box className={styles.main}>
          <FillFieldsList title={getEmployeeName()} fields={getFields()} />
        </Box>
      </ContentTableBox>
      <SimpleStepperButtons
        handleNext={formik.handleSubmit}
        disableBack
        hideBackBtn
      />
      {tokenNotValidModal}
      {userAlreadyRegisteredModal}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      addProfileData: typeof RegistrationActions.addProfileData;
      loadUserShortData: typeof loadUserShortData.trigger;
    }
  >(
    {
      addProfileData: RegistrationActions.addProfileData,
      loadUserShortData: loadUserShortData.trigger,
    },
    dispatch
  ),
});

const mapStateToProps = (store: AppStoreInterface) => ({
  securityQuestions: store.options.securityQuestions,
  registrationTokenError: store.userRegistration.registrationTokenError,
  loading: store.userRegistration.loading,
  authenticationMethod: store.user.authenticationMethod,
  isAuthenticated: store.user.isAuthenticated,
  userShortData: {
    firstName: store.userRegistration.profile.firstName,
    lastName: store.userRegistration.profile.lastName,
    email: store.userRegistration.profile.email,
    authenticationMethod: store.userRegistration.profile.authenticationMethod,
    username: store.userRegistration.profile.username,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
