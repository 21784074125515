enum PlanType {
  GroupMedical = 'Group Medical',
  GroupDental = 'Group Dental',
  GroupLife = 'Group Life',
  GroupVision = 'Group Vision',
  VoluntaryLife = 'Voluntary Life',
  LTD = 'LTD',
  FSA = 'FSA',
  STD = 'STD',
  HSA = 'HSA',
  HRA = 'HRA',
  VoluntaryDental = 'Voluntary Dental',
  WorksiteBenefitsDental = 'Worksite Benefits Dental',
  VoluntaryVision = 'Voluntary Vision',
  WorksiteBenefitsVision = 'Worksite Benefits Vision',
}

export default PlanType;
