import React, { useState, useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';
import { RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';

import SectionTitle from 'components/SectionTitle';
import Skeleton from 'components/Skeleton';
import IBasicOption from 'models/IBasicOption';
import CensusTabValue from 'enums/censusTabValue';
import userDomain from 'api/domains/user';
import dependentsDomain from 'api/domains/dependents';
import beneficiariesDomain from 'api/domains/beneficiaries';
import plansDomain from 'api/domains/plans';
import fileDomain from 'api/domains/file';
import enrollmentsDomain from 'api/domains/enrollments';
import storage from 'helpers/storage';
import { IEmployeeFile } from 'types/files';
import {
  IEmployeeBasicData,
  IEmployee,
  IEmployeeTerminationPayload,
  IAddEmployeePayload,
  IEditEmployeePayload,
} from 'types/employee';
import { IPlanSummary } from 'types/plans';
import { DependentInterface } from 'types/dependents';
import { AppStoreInterface } from 'store/app';
import { DEFAULT_PATH, BASE_NAME } from 'constants/routes';
import { fromEditEmployeeToEditEmployeeEndpointFormat } from 'mappers/employee/fromEditEmployeeToEditEmployeeEndpointFormat';
import { fromAddEmployeeToAddEmployeeEndpointFormat } from 'mappers/employee/fromAddEmployeeToAddEmployeeEndpointFormat';
import { fromTerminateEmployeeToTerminateEmployeeEndpointFormat } from 'mappers/employee/fromTerminateEmployeeToTerminateEmployeeEndpointFormat';
import {
  LOCATION_BEFORE_IMPERSONATION,
  IMPERSONATE_TOKEN,
  EMPLOYEE_ID,
} from 'constants/storeKeys';
import AuthMethod from 'enums/authMethod';
import { Task } from 'types/enrollments';
import { useQuery } from 'react-query';
import ReactSignatureCanvas from 'react-signature-canvas';
import * as feedbackMessages from 'constants/feedbackMessages';
import Feedbacker from 'components/Feedbacker';
import { ExternalBeneficiaryInterface } from 'types/beneficiaries';
import SignatureModal from '../../components/Signature/SignatureModal';
import styles from './styles.module.scss';
import TerminateEmployeeModal from './TerminateEmployeeModal';
import EditEmployeeModal from './EditEmployeeModal';
import AddEmployeeModal from './AddEmployeeModal';
import EmployeePanel from './EmployeePanel';
import EmployeesListPanel from './EmployeesListPanel';


interface IProps extends RouteProps {
  maritalStatus: IBasicOption<number>[];
  gender: IBasicOption<number>[];
  employmentType: IBasicOption<number>[];
  employmentStatus: IBasicOption<number>[];
  officeLocation: IBasicOption<number>[];
  accessGroup: IBasicOption<number>[];
  qualifyingEvent: IBasicOption<number>[];
  testUser: string | null;
  impersonatingUserId: string | null;
  currentUserId: string | null;
  authenticationMethod: AuthMethod | null;
  enableOnboarding: boolean | null;
  workHoursRequired: boolean;
}

const getEmployees = async () => {
  const { data } = await userDomain.getEmployees();
  return data;
};

const EmployeeCensus: React.FC<IProps> = (props) => {
  const {
    maritalStatus,
    gender,
    employmentType,
    employmentStatus,
    officeLocation,
    accessGroup,
    qualifyingEvent,
    location,
    testUser,
    impersonatingUserId,
    currentUserId,
    authenticationMethod,
    enableOnboarding,
    workHoursRequired,
  } = props;
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    storage.get(EMPLOYEE_ID)
  );

  const [feedback, setFeedback] = useState<string>();
  const sigCanvas = useRef<ReactSignatureCanvas>(null);
  const [filteredEmployees, setFilteredEmployees] = useState<
    IEmployeeBasicData[] | null
  >(null);
  const [employee, setEmployee] = useState<IEmployee | null>(null);
  const [plansSummary, setPlansSummary] = useState<IPlanSummary[] | null>(null);
  const [files, setFiles] = useState<IEmployeeFile[] | null>(null);
  const [dependents, setDependents] = useState<DependentInterface[] | null>(
    null
  );
  const [beneficiaries, setBeneficiaries] = useState<
    ExternalBeneficiaryInterface[] | null
  >(null);
  const [enrollmentsIssues, setEnrollmentsIssues] = useState<Task[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState<number>(CensusTabValue.Personal);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [terminateModalOpen, setTerminateModalOpen] = useState(false);
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const [signatureStored, setSignatureStored] = useState(false);
  const [signing, setSigning] = useState(false);
  const [employees, setEmployees] = useState<IEmployeeBasicData[] | null>(null);
  const { data, status } = useQuery('employees', getEmployees, {
    refetchOnWindowFocus: false,
  });
  const [terminationBody, setTerminationBody] = useState<
    IEmployeeTerminationPayload
  >();
  useEffect(() => {
    storage.remove(EMPLOYEE_ID);
  }, []);
  useEffect(() => {
    setEmployees(data!);
  }, [data]);
  const fetchEmployee = async (id: string) => {
    try {
      const { data } = await userDomain.getEmployeeById(id);
      setEmployee(data);
    } catch (err) {
      throw err;
    }
  };

  const fetchDependents = async (id: string) => {
    try {
      const { data } = await dependentsDomain.getListByEmployeeId(id);
      setDependents(data);
    } catch (err) {
      throw err;
    }
  };

  const fetchBeneficiaries = async (id: string) => {
    try {
      const { data } = await beneficiariesDomain.getListByEmployeeId(id);
      setBeneficiaries(data);
    } catch (err) {
      throw err;
    }
  };

  const fetchPlansSummary = async (id: string) => {
    try {
      const { data } = await plansDomain.getPlansSummaryByEmployeeId(id);
      setPlansSummary(data);
    } catch (err) {
      throw err;
    }
  };

  const fetchFiles = async (id: string) => {
    try {
      const { data } = await fileDomain.getFilesByEmployeeId(id);
      setFiles(data);
    } catch (err) {
      throw err;
    }
  };

  const fetchEnrollmentsIssues = async (id: string) => {
    try {
      const { data } = await enrollmentsDomain.getEnrollmentsIssuesByEmployeeId(
        id
      );
      setEnrollmentsIssues(data);
    } catch (err) {
      throw err;
    }
  };

  const addNewFile = (newFile: IEmployeeFile) => {
    setFiles((files) => [newFile, ...files!]);
  };

  useEffect(() => {
    if (selectedEmployeeId) {
      setError(null);
      (async () => {
        setLoading(true);
        try {
          await fetchEnrollmentsIssues(selectedEmployeeId);
          await fetchEmployee(selectedEmployeeId);
          if (tabValue === CensusTabValue.Dependents)
            await fetchDependents(selectedEmployeeId);
          if (tabValue === CensusTabValue.Beneficiaries)
            await fetchBeneficiaries(selectedEmployeeId);
          else if (tabValue === CensusTabValue.Enrollments)
            await fetchPlansSummary(selectedEmployeeId);
          else if (tabValue === CensusTabValue.Files)
            await fetchFiles(selectedEmployeeId);
        } catch (err) {
          setError('No employee found!');
          setEmployee(null);
          setDependents(null);
          setPlansSummary(null);
          setFiles(null);
          setSelectedEmployeeId(null);
          setEnrollmentsIssues(undefined);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [selectedEmployeeId, tabValue]);

  useEffect(() => {
    if (employees) {
      setFilteredEmployees(employees);
    }
  }, [employees]);

  const handleAddEmployee = async (payload: IAddEmployeePayload) => {
    try {
      const { data } = await userDomain.addEmployee(
        fromAddEmployeeToAddEmployeeEndpointFormat(payload)
      );
      const newEmployee: IEmployeeBasicData = {
        id: data.id,
        individualId: data.individualId,
        firstName: data.firstName,
        lastName: data.lastName,
        terminated: false,
        cobra: false,
      };
      setEmployees((prevEmployees) => {
        if (prevEmployees) {
          const newEmployees = [...prevEmployees, newEmployee].sort((a, b) => {
            const aFullName = `${a.lastName.toLowerCase()}, ${a.firstName.toLowerCase()}`;
            const bFullName = `${b.lastName.toLowerCase()}, ${b.firstName.toLowerCase()}`;
            return aFullName.localeCompare(bFullName);
          });
          return newEmployees;
        }
        return prevEmployees;
      });
    } catch (err) {
      throw err;
    }
  };

  const handleEditEmployee = async (payload: IEditEmployeePayload) => {
    try {
      const castedSelectedEmployeeId = selectedEmployeeId as string;
      await userDomain.editEmployee(
        castedSelectedEmployeeId,
        fromEditEmployeeToEditEmployeeEndpointFormat(payload)
      ); // Response is empty
      setEmployees((prevEmployees) => {
        if (prevEmployees) {
          return prevEmployees.map((employee) =>
            employee.id === castedSelectedEmployeeId
              ? {
                  ...employee,
                  firstName: payload.firstName,
                  lastName: payload.lastName,
                }
              : employee
          );
        }
        return prevEmployees;
      });
      setEmployee((prevEmployee) => {
        if (prevEmployee) {
          return {
            ...prevEmployee,
            ...fromEditEmployeeToEditEmployeeEndpointFormat(payload),
          };
        }
        return prevEmployee;
      });
    } catch (err) {
      throw err;
    }
  };

  const handleTerminate = (body: IEmployeeTerminationPayload) => {
    setTerminationBody(body);
    setSignatureModalOpen(true);
  };

  const handleTerminateEmployee = async (canvas: HTMLCanvasElement) => {
    const base64Signature = canvas.toDataURL();
    try {
      setSigning(true);
      const castedEmployeeId = selectedEmployeeId!.toString();
      await userDomain.terminateEmployeeById(
        fromTerminateEmployeeToTerminateEmployeeEndpointFormat({
          ...terminationBody,
          signature: base64Signature,
        } as IEmployeeTerminationPayload),
        castedEmployeeId
      );
      setEmployees((prevEmployees) => {
        if (prevEmployees) {
          const terminatedId = prevEmployees.findIndex(
            (employee) => employee.id === selectedEmployeeId
          );
          const terminatedEmployee = prevEmployees.find(
            (employee) => employee.id === selectedEmployeeId
          );
          const newEmployees = [...prevEmployees];
          newEmployees[terminatedId] = {
            ...(terminatedEmployee as IEmployeeBasicData),
            terminated: true,
          };
          return newEmployees;
        }
        return prevEmployees;
      });

      setFeedback(feedbackMessages.EMPLOYEE_TERMINATION_SUCCESS);
    } catch (err) {
      setFeedback(feedbackMessages.EMPLOYEE_TERMINATION_ERROR);
      throw err;
    } finally {
      handleCloseSignatureModal();
      setSigning(false);
    }
  };

  const handleLogInAsEmployee = async () => {
    if (selectedEmployeeId) {
      try {
        const { data } = await userDomain.impersonateEmployee(
          selectedEmployeeId
        );
        storage.set(IMPERSONATE_TOKEN, data.jwt);
        storage.set(LOCATION_BEFORE_IMPERSONATION, location!.pathname);
        window.location.assign(`${BASE_NAME}${DEFAULT_PATH}`);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleListItemClick = (id: string) => {
    setSelectedEmployeeId(id);
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue !== tabValue) setLoading(true);
    setTabValue(newValue);
  };

  const handleSearchEmployee = (value: string) => {
    if (employees && filteredEmployees) {
      value = value.toLowerCase().trim();
      const newEmployees = employees.filter(({ firstName, lastName }) => {
        firstName = firstName.toLowerCase();
        lastName = lastName.toLowerCase();
        const fullName = `${firstName} ${lastName}`;
        const fullNameWithComa = `${firstName}, ${lastName}`;
        const fullNameReverse = `${lastName} ${firstName}`;
        const fullNameReverseWithComa = `${lastName}, ${firstName}`;
        return (
          fullName.includes(value) ||
          fullNameReverse.includes(value) ||
          fullNameWithComa.includes(value) ||
          fullNameReverseWithComa.includes(value)
        );
      });
      setFilteredEmployees(newEmployees);
    }
  };

  const checkEmployeeIsActive = () => {
    if (employees && selectedEmployeeId) {
      const activeEmployees = employees.filter(({ terminated }) => !terminated);
      return activeEmployees.some(
        (employee) => +employee.id === +selectedEmployeeId
      );
    }
    return false;
  };

  const getSelectedEmployeeInitials = () => {
    if (employee) {
      return `${employee.firstName} ${employee.lastName}`;
    }
    return '';
  };

  const isCurrentUser = selectedEmployeeId
    ? currentUserId === selectedEmployeeId
    : false;

  const logInAsEmployeeBtnVisible = !(
    parseInt(testUser as string) ||
    // !!impersonatingUserId ||
    isCurrentUser
  );

  const handleCloseSignatureModal = () => {
    setSignatureModalOpen(false);
    setSignatureStored(false);
  };
  const handleSign = async () => {
    if (sigCanvas.current) {
      const canvas = sigCanvas.current.getTrimmedCanvas();
      await handleTerminateEmployee(canvas);
    }
  };
  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Census</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <SectionTitle title="Employee Census" />
        <Grid className={styles.pageGrid} container>
          <Grid xs={12} md={4} lg={4} item className={styles.firstBox}>
            {status === 'loading' ? (
              <Skeleton variant="rect" height="79vh" />
            ) : (
              <EmployeesListPanel
                employees={filteredEmployees}
                selectedEmployeeId={selectedEmployeeId}
                handleListItemClick={handleListItemClick}
                handleSearchEmployee={handleSearchEmployee}
                openAddEmployeeModal={() => setAddModalOpen(true)}
              />
            )}
          </Grid>
          <Grid xs={12} md={8} lg={8} className={styles.secondBox}>
            <EmployeePanel
              handleLogInAsEmployee={handleLogInAsEmployee}
              setTerminateModalOpen={setTerminateModalOpen}
              checkEmployeeIsActive={checkEmployeeIsActive}
              isCurrentUser={isCurrentUser}
              logInAsEmployeeBtnVisible={logInAsEmployeeBtnVisible}
              maritalStatus={maritalStatus}
              gender={gender}
              employmentStatus={employmentStatus}
              accessGroup={accessGroup}
              loading={loading}
              error={error}
              tabValue={tabValue}
              employee={employee}
              dependents={dependents}
              enrollments={plansSummary}
              files={files}
              selectedEmployeeId={selectedEmployeeId}
              enrollmentsIssues={enrollmentsIssues}
              handleTabChange={handleTabChange}
              addNewFile={addNewFile}
              openEditEmployeeModal={() => setEditModalOpen(true)}
              testUser={testUser}
              impersonatingUserId={impersonatingUserId}
              beneficiaries={beneficiaries}
            />
          </Grid>
        </Grid>
        <AddEmployeeModal
          isOpen={addModalOpen}
          handleClose={() => setAddModalOpen(false)}
          employmentType={employmentType}
          employmentStatus={employmentStatus}
          officeLocation={officeLocation}
          accessGroup={accessGroup}
          gender={gender}
          maritalStatus={maritalStatus}
          handleAddEmployee={handleAddEmployee}
          authenticationMethod={authenticationMethod}
          enableOnboarding={enableOnboarding}
          workHoursRequired={workHoursRequired}
        />
        {employee && (
          <EditEmployeeModal
            isOpen={editModalOpen}
            employee={employee}
            handleClose={() => setEditModalOpen(false)}
            employmentType={employmentType}
            employmentStatus={employmentStatus}
            officeLocation={officeLocation}
            accessGroup={accessGroup}
            gender={gender}
            maritalStatus={maritalStatus}
            handleEditEmployee={handleEditEmployee}
            authenticationMethod={authenticationMethod}
          />
        )}
        <TerminateEmployeeModal
          isOpen={terminateModalOpen}
          handleClose={() => setTerminateModalOpen(false)}
          employeeInitials={getSelectedEmployeeInitials()}
          qualifyingEvent={qualifyingEvent}
          handleTerminateEmployee={handleTerminate}
        />
        <SignatureModal
          title="Signature required to terminate"
          open={signatureModalOpen}
          signing={signing}
          handleClose={handleCloseSignatureModal}
          handleSign={handleSign}
          sigCanvas={sigCanvas}
          setSignatureStored={setSignatureStored}
          signatureStored={signatureStored}
        />
        <Feedbacker
          open={!!feedback}
          autoHideDuration={5000}
          severity={
            feedback === feedbackMessages.EMPLOYEE_TERMINATION_ERROR
              ? 'error'
              : 'success'
          }
          feedbackMessage={feedback || ''}
          clearFeedback={() => setFeedback(undefined)}
        />
      </Box>
    </>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  maritalStatus: store.options.maritalStatus,
  gender: store.options.gender,
  employmentType: store.options.employmentType,
  employmentStatus: store.options.employmentStatus,
  officeLocation: store.options.officeLocation,
  accessGroup: store.options.accessGroup,
  qualifyingEvent: store.options.qualifyingEvent,
  location: store.router.location,
  testUser: store.user.testUser,
  impersonatingUserId: store.user.impersonatingUserId,
  currentUserId: store.user.id,
  authenticationMethod: store.user.authenticationMethod,
  enableOnboarding: store.user.enableOnboarding,
  workHoursRequired: store.user.workHoursRequired,
});

export default connect(mapStateToProps)(EmployeeCensus);
