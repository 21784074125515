import React from 'react';
import { Alert, Color, AlertTitle } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import setClassNames from 'classnames';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as infoIcon } from 'assets/info.svg';
import { ReactComponent as errorIcon } from 'assets/error.svg';
import styles from './styles.module.scss';

const AlertWithFullWidth = withStyles({
  message: { width: '100%' },
})(Alert);

export interface SimpleAlertPropsInterface {
  message?: string;
  title?: string;
  type: Color;
  className?: string;
  light?: boolean;
}

const SimpleAlert: React.FC<SimpleAlertPropsInterface> = ({
  children,
  className,
  message,
  type,
  title,
  light,
}) => {
  const classNames = setClassNames({
    [className || '']: Boolean(className),
  });
  const getAlertStyle = () => {
    switch (type as string) {
      case 'info':
        return light ? styles.light : styles.info;
      case 'error':
        return styles.error;
      default:
        return '';
    }
  };

  return (
    <AlertWithFullWidth
      severity={type}
      iconMapping={{
        info: (
          <SvgIcon
            component={infoIcon}
            fontSize="inherit"
            width="19"
            height="20"
            viewBox="0 0 19 20"
          />
        ),
        error: (
          <SvgIcon
            component={errorIcon}
            fontSize="inherit"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          />
        ),
      }}
      className={`${getAlertStyle()} ${classNames} ${styles.alert} `}
    >
      {title && <AlertTitle className={styles.title}>{title}</AlertTitle>}
      {message || children}
    </AlertWithFullWidth>
  );
};

export default SimpleAlert;
