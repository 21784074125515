import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';

import SectionTitle from 'components/SectionTitle';
import { ACCESS_TOKEN } from 'constants/storeKeys';
import { ORIGIN_URL_WITH_PREFIX } from 'constants/app';
import storage from 'helpers/storage';

import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import styles from './styles.module.scss';

const EmployerForms: React.FC = () => {
  const link = `${ORIGIN_URL_WITH_PREFIX}/mbl_wizard_er.php?jwt=${storage.get(
    ACCESS_TOKEN
  )}`;

  return (
    <Box className={styles.employerFormsContainer}>
      <Helmet>
        <title>MyBenefitLink | Employer Form</title>
      </Helmet>
      <SectionTitle title="Employer Forms" withoutBottomPadding />
      <Box className={styles.mainContainer}>
        <ContentContainer title="Forms">
          <iframe
            className={styles.employerFormsFrame}
            title="Employer Form"
            src={link}
          />
        </ContentContainer>
      </Box>
    </Box>
  );
};

export default EmployerForms;
