import React, { useState, ChangeEvent } from 'react';
import Box from '@material-ui/core/Box';
import { useDropzone } from 'react-dropzone';

import fileDomain from 'api/domains/file';
import Feedbacker from 'components/Feedbacker';
import Backdrop from 'components/Backdrop';
import AddFilesModal from 'components/Modal/AddFilesModal';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import AdminFilesList from 'components/FilesList/AdminFileList';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import { IEmployeeFile } from 'types/files';

import styles from './styles.module.scss';

interface IProps {
  files: IEmployeeFile[] | null;
  selectedEmployeeId: string | null;
  addNewFile: (newFile: IEmployeeFile) => void;
}

const Files: React.FC<IProps> = ({ files, addNewFile, selectedEmployeeId }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isFileLoading, setFileLoading] = useState(false);
  const [isFileSubmitting, setFileSubmitting] = useState(false);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setSelectedFiles(acceptedFiles);
    },
  });

  const saveSelectedFiles = async () => {
    const file = selectedFiles[0]; // For now we are sending only one file. In future it might be multiple
    let formData = new FormData();
    formData.append('filename', file);
    formData.append('title', file.name);
    try {
      setFileSubmitting(true);
      const { data } = await fileDomain.uploadFileByEmployeeId(
        selectedEmployeeId as string,
        formData
      );
      addNewFile(data);
    } catch (err) {
      setError('Upload file error');
    } finally {
      setFileSubmitting(false);
      setSelectedFiles([]);
      setModalOpen(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedFiles([]);
  };

  const changeSelectedFileName = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const value = e.target.value;
    setSelectedFiles((files) =>
      files.map((file, i) =>
        i === index ? new File([file], value, { type: file.type }) : file
      )
    );
  };

  return (
    <Box mt="1em">
      <Backdrop open={isFileLoading} />
      <ContentContainer
        title="Files"
        button={
          <SubmitButton
            onClick={() => setModalOpen(true)}
            variant="contained"
            color="secondary"
            label="Add file"
            className={styles.add}
          />
        }
      >
        <AdminFilesList files={files} setLoading={setFileLoading} />
      </ContentContainer>
      <AddFilesModal
        changeFileName={changeSelectedFileName}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        saveSelectedFiles={saveSelectedFiles}
        handleCloseModal={handleCloseModal}
        isDragActive={isDragActive}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        isOpen={modalOpen}
        isFileSubmitting={isFileSubmitting}
        selectedFiles={selectedFiles}
      />
      <Feedbacker
        open={!!error}
        autoHideDuration={5000}
        displayInTheMiddle={false}
        clearFeedback={() => setError(null)}
        severity="error"
        feedbackMessage={error as string}
      />
    </Box>
  );
};

export default Files;
