import OptionsType from 'enums/optionsType';
import ISystemOption from 'models/ISystemOption';
import { IOptions } from 'types/options';
import api from '../adapter';

export class OptionsDomain {
  getOptions(type: OptionsType) {
    return api.makeRequest<ISystemOption[]>(
      '/options',
      'GET',
      undefined,
      undefined,
      { type }
    );
  }

  getSecurityQuestion() {
    return api.makeRequest<ISystemOption[]>(
      '/options/security-question',
      'GET',
      undefined,
      undefined
    );
  }

  getAllOptions() {
    return api.makeRequest<IOptions>('/options', 'GET');
  }
}

export default new OptionsDomain();
