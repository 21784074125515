import React from 'react';
import setClassNames from 'classnames';
import { TextField, InputAdornment, FormLabel } from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import styles from './styles.module.scss';

export interface InputPropsInterface {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void | undefined;
  onFocus?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void | undefined;
  label?: string;
  name?: string;
  className?: string;
  secured?: boolean;
  readOnly?: boolean;
  icon?: any;
  iconOnClick?: () => void | Promise<void>;
  isNumberInput?: boolean;
  error?: string;
  helperText?: string;
  min?: number;
  max?: number;
  autoFocus?: boolean;
  placeholder?: string;
  ssn?: boolean;
  typeInput?: string;
  inversePositionIcon?: boolean;
}

const CustomTextField = withStyles({
  root: {
    '& ::placeholder': {
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#9FA2B4',
      opacity: 0.8,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      border: 'none',
      borderRadius: '5px',
      backgroundColor: 'unset',
    },
    '& .Mui-error': {
      border: '1px solid #E51212',
      borderRadius: '5px',
      backgroundColor: '#FFE3E3',
    },
    '& .Mui-disabled': {
      background: '#F7F8FC',
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E1E2E7',
    },
    '& .MuiFormHelperText-root.Mui-disabled': {
      border: 'none',
      backgroundColor: '#ffff',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#9FA2B4',
      marginLeft: '0px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4979C0',
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      '& fieldset': {
        borderColor: '#E1E2E7',
      },
      '&:hover fieldset': {
        borderColor: '#4979C0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #4979C0',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 0,
    },
    inputIcon: {
      cursor: 'pointer',
      color: '#4979C0',
    },
    margin: {
      margin: theme.spacing(1),
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#252733',
      margin: '10px 0',
    },
    cssOutlinedInputInfo: {
      '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
        borderColor: '#90B9F5',
      },
    },
    cssOutlinedInputWarning: {
      '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
        borderColor: '#E51212',
      },
    },
    notchedOutline: {},
    cssFocused: {},
    error: {},
    disabled: {},
  })
);

const Input: React.FC<InputPropsInterface> = (props: InputPropsInterface) => {
  const {
    value,
    className,
    onChange,
    onBlur,
    name,
    label,
    secured,
    readOnly,
    icon,
    iconOnClick,
    isNumberInput,
    error,
    helperText,
    autoFocus,
    placeholder,
    onFocus,
    ssn,
    typeInput,
    inversePositionIcon,
  } = props;

  const inputClasses = useStyles();

  const classes: string = setClassNames(inputClasses.root, {
    [className || '']: Boolean(className),
  });

  const inputType = secured ? 'password' : isNumberInput ? 'number' : 'text';
  const inputHelperText = error || helperText;
  const getStyle = (): string | undefined => {
    if (!typeInput) return '';
    switch (typeInput) {
      case 'info':
        return inputClasses.cssOutlinedInputInfo;
      case 'warning':
        return inputClasses.cssOutlinedInputInfo;
      default:
        return '';
    }
  };
  return (
    <>
      {!ssn && label && (
        <FormLabel className={inputClasses.label} component="legend">
          {label}
        </FormLabel>
      )}
      <CustomTextField
        error={Boolean(error)}
        helperText={inputHelperText}
        disabled={readOnly}
        type={inputType}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        className={`${classes} ${styles.input} `}
        autoFocus={autoFocus}
        placeholder={placeholder ?? `Enter ${label ?? ''}`}
        variant="outlined"
        InputProps={{
          classes: {
            root: getStyle(),
            focused: inputClasses.cssFocused,
            notchedOutline: inputClasses.notchedOutline,
          },
          startAdornment: inversePositionIcon && icon && (
            <InputAdornment
              position="start"
              onClick={iconOnClick}
              className={inputClasses.inputIcon}
            >
              {icon}
            </InputAdornment>
          ),
          endAdornment: !inversePositionIcon && icon && (
            <InputAdornment
              position="end"
              onClick={iconOnClick}
              className={inputClasses.inputIcon}
            >
              {icon}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default Input;
