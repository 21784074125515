import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { IMidyearChangeData } from 'types/enrollments';
import ConfirmationModalWithCancel from 'components/Modal/ConfirmationModalWithCancel';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as userRoutines from 'redux/sagas/user/routines';
import { Helmet } from 'react-helmet';
import { connect, useSelector } from 'react-redux';
import SectionTitle from 'components/SectionTitle';
import RestartRegistrationModal from 'components/Modal/RestartRegistrationModal';
import ExternalRegistrationStep from 'enums/externalRegistrationStep';
import userDomain from 'api/domains/user';
import { AppStoreInterface } from 'store/app';
import { Button } from '@material-ui/core';
import enrollmentsDomain from 'api/domains/enrollments';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import styles from './styles.module.scss';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepThree from './stepThree';
import StepFour from './stepFour';
import StepSix from './StepSix';
import StepSeven from './stepSeven';

interface IProps {
  loadCurrentUser: () => void;
}
const getSettings = async () => {
  const { data } = await userDomain.loadCurrentUser();
  return data.midyearChangesData;
};

const ChangeBenefits: React.FC<IProps> = ({ loadCurrentUser }) => {
  const [eventType, setEventType] = useState<string | null>(null);
  const [step, setStep] = useState<number>(1);
  const [effectiveDate, setEffectiveDate] = useState<string | null>('');
  const [reasonId, setReasonId] = useState<string | null | undefined>('');
  const [restartRegistrationModal, setRestartRegistrationModal] = useState(
    false
  );
  const [dependentId, setDependentId] = useState<null | string | number>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data: midyearChangesData } = useQuery('getSettings', getSettings, {
    refetchOnWindowFocus: false,
  });

  const {
    newHire,
    openEnrollmentEndDate,
    eligibilityDate,
    maritalStatusId,
    medicalEnrolled,
  } = useSelector((state: AppStoreInterface) => state.user);

  useEffect(() => {
    if (midyearChangesData && midyearChangesData.step !== 1 && step === 1) {
      setIsOpen(true);
    }
  }, [midyearChangesData, step]);

  useEffect(() => {
    if (
      step === 3 &&
      !(
        eventType === '1' ||
        eventType === '2' ||
        eventType === '3' ||
        eventType === '5' ||
        eventType === '6'
      )
    ) {
      setStep(step + 1);
    }

    if (
      step === 4 &&
      !(
        eventType === '2' ||
        eventType === '3' ||
        eventType === '4' ||
        eventType === '7' ||
        (eventType === '5' && !medicalEnrolled) ||
        (eventType === '6' && !medicalEnrolled)
      )
    ) {
      setStep(step + 1);
    }
    if (step === 5) setStep(step + 1);
  }, [eventType, medicalEnrolled, step]);

  const restartMidChanges = () => {
    if (midyearChangesData) {
      const {
        dependentId,
        reasonId,
        eventType,
        effectiveDate,
        step,
      } = midyearChangesData;
      setDependentId(dependentId ?? null);
      setReasonId(reasonId);
      setEventType(eventType ?? null);
      setEffectiveDate(effectiveDate ?? null);
      setStep(step);
      setIsOpen(false);
    }
  };
  const handleCardActionClick = async () => {
    try {
      const step = (ExternalRegistrationStep.Enrollments - 1).toString();
      await userDomain.saveRegistrationStep(step);
      loadCurrentUser();
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (data: IMidyearChangeData) => {
    setStep(data.step);
    await enrollmentsDomain.updateMidyearData({
      step: data.step,
      eventType: data.eventType ?? eventType,
      dependentId: data.dependentId ?? dependentId,
      effectiveDate: data.effectiveDate ?? effectiveDate,
      reasonId: data.reasonId ?? reasonId,
    });
    if (data.step === 1) {
      getSettings();
    }
  };

  const isStillOEE =
    openEnrollmentEndDate && !moment().isAfter(openEnrollmentEndDate, 'day');
  const modifyEnrollmentsVisible = newHire || isStillOEE;
  const diffDays = (date: string) => {
    var now = moment(new Date());
    var end = moment(new Date(date));
    var duration = moment.duration(end.diff(now));
    return Math.ceil(duration.asDays());
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            eventType={eventType}
            setEventType={setEventType}
            setStep={updateData}
            maritalStatusId={maritalStatusId}
          />
        );
      case 2:
        return (
          <StepTwo
            eventType={eventType}
            setStep={updateData}
            setEffectiveDate={setEffectiveDate}
            setReasonId={setReasonId}
          />
        );
      case 3:
        return (
          <StepThree
            effectiveDate={effectiveDate as string}
            eventType={eventType as string}
            setStep={updateData}
            setDependentId={setDependentId}
          />
        );
      case 4:
        return <StepFour setStep={updateData} />;
      case 6:
        return (
          <StepSix
            setIsLoading={() => {}}
            setError={() => {}}
            eventType={eventType}
            dependentId={dependentId}
            reasonId={reasonId}
            eventDate={effectiveDate}
            setStep={updateData}
          />
        );
      case 7:
        return <StepSeven setStep={updateData} setEventId={setEventType} />;
    }
  };

  const restartStep = () => (
    <ConfirmationModalWithCancel
      isOpen={isOpen}
      handleClose={() => setIsOpen(false)}
      onSubmit={restartMidChanges}
      cancelLabel="No"
    >
      <Typography variant="h6">
        Would you like to continue previous changes?
      </Typography>
    </ConfirmationModalWithCancel>
  );
  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Change Benefits</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <SectionTitle title="Change Benefits" />
        {modifyEnrollmentsVisible ? (
          <Box className={styles.mainBox}>
            <Grid sm={12} md={4} item>
              <Typography className={styles.tableTitle} variant="subtitle2" />
              <Box className={styles.modifyEnrollmentContainer}>
                <Card className={styles.modifyEnrollmentCard}>
                  <Box className={styles.header}>
                    You can still make changes to your benefits
                  </Box>
                  <CardContent className={styles.card}>
                    <Typography variant="body1">
                      {newHire ? (
                        <>
                          <Typography className={styles.mainText}>
                            {+diffDays(eligibilityDate as string) < 1
                              ? 'Today is the last day.'
                              : `You have ${diffDays(
                                  eligibilityDate as string
                                )} ${
                                  +diffDays(eligibilityDate as string) <= 1
                                    ? 'day'
                                    : 'days'
                                } left.`}
                          </Typography>
                          <Typography className={styles.secondText}>
                            Please make any changes to your benefits by{' '}
                            {eligibilityDate}.
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography className={styles.mainText}>
                            {+diffDays(openEnrollmentEndDate as string) < 1
                              ? 'Today is the last day.'
                              : `You have ${diffDays(
                                  openEnrollmentEndDate as string
                                )} ${
                                  +diffDays(openEnrollmentEndDate as string) <=
                                  1
                                    ? 'day'
                                    : 'days'
                                } left.`}
                          </Typography>
                          <Typography variant="body1">
                            Open Enrollment Ends {openEnrollmentEndDate}
                          </Typography>
                        </>
                      )}
                    </Typography>
                    <Button
                      className={styles.btnEnrollment}
                      variant="contained"
                      color="primary"
                      onClick={() => setRestartRegistrationModal(true)}
                    >
                      Modify Selections
                    </Button>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Box>
        ) : (
          <Box className={styles.main}>
            <ContentContainer
              title="Change My Benefits"
              isBox
              withoutMargin
              padding
            >
              {restartStep()}
              {renderStep()}
            </ContentContainer>
          </Box>
        )}
        <RestartRegistrationModal
          open={restartRegistrationModal}
          handleClose={() => setRestartRegistrationModal(false)}
          handleSubmit={handleCardActionClick}
        />
      </Box>
    </>
  );
};

const mapDispatchToProps = {
  loadCurrentUser: userRoutines.loadCurrentUser,
};

export default connect(null, mapDispatchToProps)(ChangeBenefits);
