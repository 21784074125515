import IBasicOption from 'models/IBasicOption';
import { EMPTY_VALUE } from 'constants/placeholders';

const getOptionLabelByValue = (
  options: IBasicOption<number>[],
  value?: string | number
) => {
  if (value) {
    const label = options.find((option) => +option.value === +value)?.label;
    return label || EMPTY_VALUE;
  }
  return EMPTY_VALUE;
};

export default getOptionLabelByValue;
