import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';

import {
  ExternalBeneficiaryInterface,
  IBeneficiaryPersonal,
} from 'types/beneficiaries';
import BeneficiaryCardItem from './BeneficiaryCardItem';
import styles from './styles.module.scss';

export interface IBeneficiariesReadOnlyListProps {
  beneficiaries: ExternalBeneficiaryInterface[] | null;
  personalData: IBeneficiaryPersonal[];
  handleHidePersonalData: () => void;
  handleOpenModal: () => void;
  handleShowPersonalData: (password: string) => Promise<void>;
  isPasswordPrompt: boolean;
  hideSsn: boolean;
}

const providePersonalInfo = (
  beneficiaries: ExternalBeneficiaryInterface[],
  personalData: IBeneficiaryPersonal[]
): ExternalBeneficiaryInterface[] => {
  return beneficiaries.map((beneficiary) => {
    const matchedPersonalData = personalData.find(
      (beneficiaryPersonalData) => beneficiaryPersonalData.id === beneficiary.id
    );
    return matchedPersonalData
      ? { ...beneficiary, ...matchedPersonalData }
      : beneficiary;
  });
};

const BeneficiaryCardList: React.FC<IBeneficiariesReadOnlyListProps> = (
  props
) => {
  const { beneficiaries, personalData, ...itemProps } = props;

  return (
    <Box className={styles.dependentsBox}>
      {beneficiaries && beneficiaries.length ? (
        <Grid container spacing={3} className={styles.cardsGridContainer}>
          {providePersonalInfo(beneficiaries, personalData).map(
            (beneficiary) => (
              <Grid key={beneficiary.id} item xs={12} sm={6} lg={6}>
                <BeneficiaryCardItem beneficiary={beneficiary} {...itemProps} />
              </Grid>
            )
          )}
        </Grid>
      ) : (
        <Box className={styles.noBeneficiaries}>
          <Typography variant="h5" className={styles.placeholder}>
            You don't have any beneficiaries
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BeneficiaryCardList;
