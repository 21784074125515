import {
  Plan,
  IFullPlan,
  IPlanContribution,
  IPlanSummary,
  IMaxContributionAmountHSA,
  IMaxContributionAmountVoluntaryLife,
  ICustomFields,
  ICoverageAmounts,
} from 'types/plans';
import ExternalPlanType from 'enums/externalPlanType';
import api from '../adapter';

export class PlansDomain {
  getList() {
    return api.makeRequest<IFullPlan[]>('/plans', 'GET');
  }

  getByType(planType: ExternalPlanType | string) {
    return api.makeRequest<Plan[]>(`/plans?type=${planType}`, 'GET');
  }

  getOne<T = Plan>(id: string | number, employeeId?: string | number | null) {
    return api.makeRequest<T>(
      `/plans${employeeId ? `-hr/${id}/${employeeId}` : `/${id}`}`,
      'GET'
    );
  }

  predictContributionAmount(
    id: string,
    dependents?: string[],
    status?: null | number
  ) {
    return api.makeRequest<IPlanContribution>(
      `/plans/${id}/contributions`,
      'GET',
      null,
      undefined,
      {
        dependents: dependents?.length ? dependents.join(',') : undefined,
        enrollment_status: status,
      }
    );
  }

  predictContributionAmountVolLife(
    id: string,
    employee?: number | string | null,
    spouse?: number | string | null,
    child?: number | string | null
  ) {
    return api.makeRequest<IPlanContribution>(
      `/plans/${id}/contributions?employee=${employee}&spouse=${spouse}&child=${child}`,
      'GET'
    );
  }

  getPlansSummary() {
    return api.makeRequest<IPlanSummary[]>('/summary', 'GET');
  }

  getCoverages(id: string, employeeId?: string | number | null) {
    return api.makeRequest<ICoverageAmounts>(
      `/plans/${id}/coverages${employeeId ? `-hr/${employeeId}` : ''}`,
      'GET'
    );
  }

  getPlansSummaryByEmployeeId(id: string) {
    return api.makeRequest<IPlanSummary[]>(`/employees/${id}/summary`, 'GET');
  }

  getMaxContributionAmountHSA(id: string) {
    return api.makeRequest<IMaxContributionAmountHSA>(
      `/plans/${id}/limits`,
      'GET'
    );
  }

  getMaxContributionAmountVoluntaryLife(id: string) {
    return api.makeRequest<IMaxContributionAmountVoluntaryLife>(
      `/plans/${id}/limits`,
      'GET'
    );
  }

  getMyPlans() {
    return api.makeRequest<IFullPlan[]>('/plans?includeCurrent=1', 'GET');
  }

  getCustomFields(id: string, employeeId?: string | number | null) {
    return api.makeRequest<ICustomFields[]>(
      `/plans/${id}/custom${employeeId ? `-hr/${employeeId}` : ''}`,
      'GET'
    );
  }

  getCoverageAmounts(id: string) {
    return api.makeRequest<ICoverageAmounts>(`/plans/${id}/coverage`, 'GET');
  }
}

export default new PlansDomain();
