import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { RESTART_REGISTRATION_WARNING } from 'constants/messages';
import ConfirmationModalWithCancel from './ConfirmationModalWithCancel';

import styles from './styles.module.scss';

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => Promise<void>;
}

const RestartRegistrationModal: React.FC<IProps> = ({
  open,
  handleClose,
  handleSubmit,
}) => {
  return (
    <ConfirmationModalWithCancel
      isOpen={open}
      handleClose={handleClose}
      onSubmit={handleSubmit}
      isConfirm
    >
      <Box width="15em" textAlign="center">
        <Typography variant="h6" className={styles.textModal}>
          {RESTART_REGISTRATION_WARNING}
        </Typography>
      </Box>
    </ConfirmationModalWithCancel>
  );
};

export default RestartRegistrationModal;
