import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';

import styles from './styles.module.scss';

export interface ISectionTitleProps {
  title?: string;
  button?: React.ReactNode;
  withoutBottomPadding?: boolean;
}

const SectionTitle: React.FC<ISectionTitleProps> = ({
  title,
  button,
  withoutBottomPadding,
}) => {
  return (
    <Box
      className={
        withoutBottomPadding ? styles.withoutPadding : styles.sectionTitle
      }
    >
      {title && (
        <Typography className={styles.sectionText} variant="h6">
          {title}
        </Typography>
      )}
      {button}
      <Divider />
    </Box>
  );
};

export default SectionTitle;
