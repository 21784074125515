import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Typography, useMediaQuery, Tooltip, SvgIcon } from '@material-ui/core';
import Box, { BoxProps } from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { TableCellProps } from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { useQuery } from 'react-query';
import { ReactComponent as waivedIcon } from 'assets/waived.svg';
import { ReactComponent as notAvailableIcon } from 'assets/notAvailable.svg';
import { ReactComponent as enrolledIcon } from 'assets/enrolled.svg';

import plansDomain from 'api/domains/plans';
import getCurrency from 'helpers/getCurrency';
import { IFullPlan } from 'types/plans';
import { IEnrollment } from 'types/enrollments';
import { DependentInterface } from 'types/dependents';

import {
  ENROLLMENT_PERIOD_PLAN_WARNING,
  WILL_BE_ELIGIBLE,
  DECLINED_MEDICAL,
  NOT_HIGH_DEDUCTIBLE_PLAN,
  NOT_HRA_ELIGIBLE_PLAN,
  NOT_ELIGIBLE,
} from 'constants/messages';
import EnrollmentStatus from 'enums/enrollmentStatus';
import { DEFAULT_DATE_FORMAT } from 'constants/app';
import { AppStoreInterface } from 'store/app';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import PlanType from 'enums/planType';
import { isDomesticPartner, isDomesticPartnerCanBeEnrolled } from './logic';
import ComparePlansModal from './ComparePlansModal';
import {
  useStyles,
  CustomCell,
  CustomTable,
  ChipWithMargin,
  ActionCell as ActionCustomCell,
} from './styling';

export interface EnrollmentsGroupProps {
  groupName: string;
  plans: IFullPlan[];
  onInfoClick?: (planId: string) => void | Promise<void>;
  enrollment?: IEnrollment;
  dependents: DependentInterface[];
  disableEnroll?: boolean;
  disableEnrollReason?: string;
  openEnrollmentId?: string | null;
  onSelect: (id?: string) => void | Promise<void>;
  userGender?: number | null;
}

interface GroupNameProps {
  groupName: string;
  status?: EnrollmentStatus;
  comparable: boolean;
  showGroupName: boolean;
  onCompare?: () => void | Promise<void>;
  isMyPlan: boolean;
}

interface PlanNameProps {
  isGroupPlan?: boolean;
  userGender?: number | null;
  plan: IFullPlan;
  enrollment?: IEnrollment;
  openEnrollmentId?: string | null;
  dependents: DependentInterface[];
  onInfoClick?: (planId: string) => void | Promise<void>;
}

interface ActionButtonProps {
  plans: IFullPlan[];
  enrollment?: IEnrollment;
  onClick?: () => void | Promise<void>;
}

interface PlanWarningProps {
  enrollment?: IEnrollment;
  plan: IFullPlan;
}

interface EnrolledIconProps {
  enrollment?: IEnrollment;
}

interface ActionCellProps {
  cellProps: TableCellProps;
  boxProps: BoxProps;
}

const isEligibilityDateInPast = (eligibilityDate: string) => {
  return moment(eligibilityDate, DEFAULT_DATE_FORMAT).toDate() < new Date();
};

const isNonContributory = (plans: IFullPlan[]): boolean => {
  return plans.every((plan) => plan.contributionType === '2');
};

const EmployerPaid: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.actionButtonContainer}>
      <Box className={classes.enrolledIconContainer}>
        <Tooltip title="Enrolled">
          <SvgIcon component={enrolledIcon} className={classes.enrolledIcon} />
        </Tooltip>
        <Typography
          className={`${classes.enrolledIconLabel} ${classes.enrolled}`}
        >
          Enrolled
        </Typography>
      </Box>
      <Typography className={`${classes.employerPaid} ${classes.enrolled}`}>
        Employer Paid
      </Typography>
    </Box>
  );
};

const NotAvailable: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.nonActionContainer}>
      <SvgIcon component={notAvailableIcon} className={classes.enrolledIcon} />
      <Typography className={classes.notAvailableLabel}>
        Not Available
      </Typography>
    </Box>
  );
};

const EnrolledIcon: React.FC<EnrolledIconProps> = ({ enrollment }) => {
  const classes = useStyles();

  const isPlanChosen = Boolean(
    enrollment && +enrollment.status > +EnrollmentStatus.NotSelected
  );

  const isPlanDeclined =
    Boolean(enrollment && +enrollment.status < +EnrollmentStatus.NotSelected) &&
    !enrollment?.isNewOE;

  if (isPlanChosen) {
    return (
      <Box className={classes.enrolledIconContainer}>
        <Tooltip title="Enrolled">
          <SvgIcon component={enrolledIcon} className={classes.enrolledIcon} />
        </Tooltip>
        <Typography className={classes.enrolledIconLabel}>Enrolled</Typography>
      </Box>
    );
  }
  if (isPlanDeclined) {
    const status =
      enrollment?.status === EnrollmentStatus.Waived ? 'Waived' : 'Declined';
    return (
      <Box className={classes.enrolledIconContainer}>
        <Tooltip title={status}>
          <SvgIcon component={waivedIcon} className={classes.enrolledIcon} />
        </Tooltip>
        <Typography className={classes.enrolledIconLabel}>{status}</Typography>
      </Box>
    );
  }
  return <Box className={classes.enrolledIconContainer} />;
};

const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const { onClick, enrollment, plans } = props;
  const classes = useStyles();
  const isNotEnrolled =
    !enrollment?.status ||
    enrollment?.status === EnrollmentStatus.NotSelected ||
    enrollment.isNewOE;

  const notSelectable = !enrollment?.selectable;
  const notAvailable = !!enrollment && Number(enrollment.restricted) > 0;
  if (notAvailable) {
    return <NotAvailable />;
  }
  if (notSelectable) {
    return (
      <>
        <EmployerPaid />
        {plans.length !== 1 && plans[0].planType !== PlanType.HRA && (
          <Box className={classes.actionButtonContainer}>
            <Box className={classes.enrolledIconContainer} />
            <Button
              className={`${classes.btnAction} ${
                isNotEnrolled ? '' : classes.modifyBtn
              }`}
              variant="contained"
              color={isNotEnrolled ? 'primary' : 'default'}
              onClick={onClick}
            >
              {isNotEnrolled ? 'Select' : 'Modify'}
            </Button>
          </Box>
        )}
      </>
    );
  }

  if (enrollment) {
    return (
      <Box className={classes.actionButtonContainer}>
        <EnrolledIcon enrollment={enrollment} />
        <Button
          className={`${classes.btnAction} ${
            isNotEnrolled ? '' : classes.modifyBtn
          }`}
          variant="contained"
          color={isNotEnrolled ? 'primary' : 'default'}
          onClick={onClick}
        >
          {isNotEnrolled ? 'Select' : 'Modify'}
        </Button>
      </Box>
    );
  }

  return null;
};

const ActionCell: React.FC<ActionCellProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <ActionCustomCell className={classes.enrolledCell}>
      <Box>{children}</Box>
    </ActionCustomCell>
  );
};

const GroupName: React.FC<GroupNameProps> = ({
  groupName,
  status,
  comparable,
  onCompare,
  showGroupName,
  isMyPlan,
}) => {
  const classes = useStyles();
  const groupNameStatus = status || EnrollmentStatus.NotSelected;
  const colorClass =
    +groupNameStatus > +EnrollmentStatus.NotSelected
      ? classes.enrolled
      : +groupNameStatus < +EnrollmentStatus.NotSelected
      ? classes.enrolled
      : classes.notSelected;

  return (
    <>
      {showGroupName && (
        <Box className={classes.groupTitleContainer}>
          <Typography className={`${classes.groupTitle} ${colorClass}`}>
            {groupName}
          </Typography>
        </Box>
      )}
      {comparable && (
        <Typography className={classes.groupDesc}>
          <span onClick={onCompare} className={classes.groupDescClickable}>
            {isMyPlan
              ? 'Compare your current plan with the new plans'
              : 'Compare plans'}
          </span>
        </Typography>
      )}
    </>
  );
};

const PlanWarning: React.FC<PlanWarningProps> = ({ enrollment, plan }) => {
  const classes = useStyles();
  if (!enrollment) {
    return null;
  }
  if (!enrollment.restricted) {
    return enrollment?.eligibilityDate &&
      !isEligibilityDateInPast(enrollment.eligibilityDate) ? (
      <Box>
        <Typography className={classes.subInfo} variant="body2">
          {WILL_BE_ELIGIBLE(enrollment.eligibilityDate)}
        </Typography>
      </Box>
    ) : null;
  }

  let warning: string | null = null;
  switch (enrollment.restricted) {
    case 2:
      warning = DECLINED_MEDICAL;
      break;
    case 3:
      warning = NOT_HRA_ELIGIBLE_PLAN;
      break;
    case 4:
      warning = ENROLLMENT_PERIOD_PLAN_WARNING;
      break;
    case 5:
      warning = NOT_HIGH_DEDUCTIBLE_PLAN;
      break;
    case 6:
      warning = NOT_ELIGIBLE;
      break;
    default:
      break;
  }

  return warning !== null ? (
    <Box>
      <Typography className={classes.subInfo} variant="body2">
        {warning}
      </Typography>
    </Box>
  ) : null;
};

const PlanName: React.FC<PlanNameProps> = (props) => {
  const {
    plan,
    enrollment,
    dependents,
    onInfoClick,
    userGender,
    children,
    isGroupPlan,
  } = props;
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:980px)');
  const sm = useMediaQuery('(min-width:600px)');
  const isPlanNotChosen = Boolean(
    isGroupPlan &&
      enrollment &&
      enrollment.planId !== plan.id &&
      enrollment.planId
  );
  const isPlanEnrolled = Boolean(enrollment && enrollment.planId === plan.id);
  const isDependentsEnrolled = Boolean(
    enrollment && enrollment.dependents && enrollment.dependents.length
  );
  const isNotAvailable = !!enrollment && Number(enrollment.restricted) > 0;
  return !sm ? (
    <>
      <TableRow>
        <CustomCell className={isGroupPlan ? classes.planInfo : classes.plans}>
          <Box className={classes.planCellSm}>
            <Box className={classes.planWithIcon}>
              <PlanIcons planTypeId={Number(plan.planTypeId)} />
              <Box className={classes.planContainer}>
                <Box className={classes.planBox}>
                  <Typography
                    onClick={() => onInfoClick && onInfoClick(plan.id)}
                    className={
                      isNotAvailable || isPlanNotChosen
                        ? classes.notAvailable
                        : classes.enrolled
                    }
                  >
                    {plan.title}
                  </Typography>
                </Box>
                {!isPlanNotChosen && (
                  <PlanWarning enrollment={enrollment} plan={plan} />
                )}
                {isDependentsEnrolled && isPlanEnrolled ? (
                  <Box className={classes.chipBlock}>
                    {enrollment?.dependents.length !== 0 && (
                      <Box className={classes.dependentsLabel}>Dependents:</Box>
                    )}
                    {enrollment?.dependents.map((id) => {
                      const dependent = dependents.find((d) => d.id === id);
                      return (
                        dependent &&
                        !(
                          isDomesticPartner(dependent) &&
                          !isDomesticPartnerCanBeEnrolled(
                            dependent,
                            plan,
                            userGender
                          )
                        ) && (
                          <ChipWithMargin
                            key={dependent.id}
                            label={dependent.firstName}
                            variant="outlined"
                          />
                        )
                      );
                    })}
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Button
              className={
                !isPlanNotChosen ? classes.infoBtnSm : classes.infoBtnSmDisable
              }
              variant="outlined"
              color="primary"
              disabled={isPlanNotChosen}
              onClick={() => onInfoClick && onInfoClick(plan.id)}
            >
              Info
            </Button>
          </Box>
        </CustomCell>
      </TableRow>
      {!isGroupPlan && <TableRow>{children}</TableRow>}
      {!isGroupPlan && isPlanEnrolled && (
        <TableRow>
          <CustomCell className={classes.contributionCell}>
            <Box className={classes.contributionAmount}>
              {`${
                getCurrency(enrollment?.contributionAmount, 2) || '$0.00'
              } per paycheck`}
            </Box>
          </CustomCell>
        </TableRow>
      )}
    </>
  ) : (
    <TableRow>
      <CustomCell className={isGroupPlan ? classes.planInfo : classes.plans}>
        <Box className={classes.planCell}>
          <Box className={classes.planWithIcon}>
            <PlanIcons planTypeId={Number(plan.planTypeId)} />
            <Box className={classes.planContainer}>
              <Box className={classes.planBox}>
                <Typography
                  onClick={() => onInfoClick && onInfoClick(plan.id)}
                  className={
                    isNotAvailable || isPlanNotChosen
                      ? classes.notAvailable
                      : classes.enrolled
                  }
                >
                  {plan.title}
                </Typography>
              </Box>
              {!isPlanNotChosen && (
                <PlanWarning enrollment={enrollment} plan={plan} />
              )}
              {isDependentsEnrolled && isPlanEnrolled ? (
                <Box className={classes.chipBlock}>
                  {enrollment?.dependents.length !== 0 && (
                    <Box className={classes.dependentsLabel}>Dependents:</Box>
                  )}
                  {enrollment?.dependents.map((id) => {
                    const dependent = dependents.find((d) => d.id === id);
                    return (
                      dependent &&
                      !(
                        isDomesticPartner(dependent) &&
                        !isDomesticPartnerCanBeEnrolled(
                          dependent,
                          plan,
                          userGender
                        )
                      ) && (
                        <ChipWithMargin
                          key={dependent.id}
                          label={dependent.firstName}
                          variant="outlined"
                        />
                      )
                    );
                  })}
                </Box>
              ) : null}
            </Box>
          </Box>
          <Button
            className={
              !isPlanNotChosen ? classes.infoBtn : classes.infoBtnDisable
            }
            variant="outlined"
            color="primary"
            disabled={isPlanNotChosen}
            onClick={() => onInfoClick && onInfoClick(plan.id)}
          >
            Info
          </Button>
        </Box>
      </CustomCell>
      {matches &&
        (isGroupPlan ? (
          <ActionCustomCell className={classes.enrolledCell} />
        ) : (
          children
        ))}
      <CustomCell className={classes.contributionCell}>
        {!isGroupPlan && isPlanEnrolled && (
          <Box className={classes.contributionAmount}>
            {`${
              getCurrency(enrollment?.contributionAmount, 2) || '$0.00'
            } per paycheck`}
          </Box>
        )}
      </CustomCell>
    </TableRow>
  );
};

const getMyPlans = async () => {
  const { data } = await plansDomain.getMyPlans();
  return data;
};
const EnrollmentsGroup: React.FC<EnrollmentsGroupProps> = (props) => {
  const {
    groupName,
    plans,
    enrollment,
    onSelect,
    dependents,
    disableEnroll,
    disableEnrollReason,
    onInfoClick,
    openEnrollmentId,
    userGender,
  } = props;

  const classes = useStyles();
  const matches = useMediaQuery('(min-width:980px)');
  const [showCompareModal, setShowCompareModal] = React.useState(false);
  const isGroupNonContributed = isNonContributory(plans);
  const { data: myPlans } = useQuery('myPlans', getMyPlans, {
    refetchOnWindowFocus: false,
  });

  const sm = useMediaQuery('(min-width:600px)');
  const onCompare = () => {
    setShowCompareModal(true);
  };

  const myPlan =
    (Number(enrollment?.status) > 0 || !enrollment?.status) &&
    myPlans?.find(
      (plan) =>
        +plan.planTypeId === +plans[0].planTypeId &&
        !plans.find((p) => p.id === plan.id)
    );

  const actionCellContent = () => (
    <>
      {disableEnroll ? (
        disableEnrollReason || 'Enroll is disable'
      ) : (
        <ActionButton
          plans={plans}
          enrollment={enrollment}
          onClick={onSelect}
        />
      )}
    </>
  );

  if (plans.length === 1 && !myPlan) {
    return (
      <Box className={classes.planGroup}>
        <TableContainer className={classes.tableContainer} component={Box}>
          <CustomTable style={{ borderCollapse: 'unset' }}>
            <TableBody>
              {matches ? (
                <PlanName
                  enrollment={enrollment}
                  dependents={dependents}
                  plan={plans[0]}
                  onInfoClick={onInfoClick}
                  openEnrollmentId={openEnrollmentId}
                  userGender={userGender}
                >
                  <ActionCell
                    cellProps={{
                      rowSpan: plans.length + 1,
                      className: classes.actions,
                    }}
                    boxProps={{ className: classes.actionContainer }}
                  >
                    {actionCellContent()}
                  </ActionCell>
                </PlanName>
              ) : (
                <>
                  <PlanName
                    userGender={userGender}
                    enrollment={enrollment}
                    dependents={dependents}
                    plan={plans[0]}
                    onInfoClick={onInfoClick}
                    openEnrollmentId={openEnrollmentId}
                  />
                  <TableRow>
                    <ActionCell
                      cellProps={{ colSpan: 2 }}
                      boxProps={{ className: classes.actionContainer }}
                    >
                      {actionCellContent()}
                    </ActionCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>
      </Box>
    );
  }
  const isGroupEnrolled = () =>
    Boolean(
      enrollment && plans.filter((p) => enrollment.planId === p.id).length !== 0
    );
  return (
    <Box className={classes.planGroup}>
      <TableContainer className={classes.tableContainer} component={Box}>
        <CustomTable style={{ borderCollapse: 'unset' }}>
          <TableBody>
            <TableRow>
              <CustomCell className={classes.plans}>
                <GroupName
                  groupName={groupName}
                  showGroupName={plans.length > 1}
                  status={
                    enrollment?.status ||
                    (isGroupNonContributed
                      ? EnrollmentStatus.Enrolled
                      : undefined)
                  }
                  isMyPlan={Boolean(myPlan)}
                  comparable={plans.length > 1 || Boolean(myPlan)}
                  onCompare={
                    plans.length > 1 || Boolean(myPlan) ? onCompare : undefined
                  }
                />
              </CustomCell>
              {matches && (
                <ActionCell
                  cellProps={{
                    rowSpan: 1,
                    className: classes.actions,
                  }}
                  boxProps={{ className: classes.actionContainer }}
                >
                  {actionCellContent()}
                </ActionCell>
              )}
              {sm && (
                <CustomCell className={classes.contributionCell}>
                  {isGroupEnrolled() && (
                    <Box className={classes.contributionAmount}>
                      {`${
                        getCurrency(enrollment?.contributionAmount, 2) ||
                        '$0.00'
                      } per paycheck`}
                    </Box>
                  )}
                </CustomCell>
              )}
            </TableRow>
            {plans.map((plan) => (
              <PlanName
                isGroupPlan
                userGender={userGender}
                key={plan.id}
                enrollment={enrollment}
                dependents={dependents}
                plan={plan}
                onInfoClick={onInfoClick}
                openEnrollmentId={openEnrollmentId}
              />
            ))}
            {!sm && (
              <TableRow>
                <CustomCell className={classes.contributionCell}>
                  {isGroupEnrolled() && (
                    <Box className={classes.contributionAmount}>
                      {`${
                        getCurrency(enrollment?.contributionAmount, 2) ||
                        '$0.00'
                      } per paycheck`}
                    </Box>
                  )}
                </CustomCell>
              </TableRow>
            )}
            {!matches && (
              <TableRow>
                <ActionCell
                  cellProps={{ colSpan: 2 }}
                  boxProps={{ className: classes.actionContainer }}
                >
                  {actionCellContent()}
                </ActionCell>
              </TableRow>
            )}
          </TableBody>
        </CustomTable>
      </TableContainer>
      {plans.length > 1 || myPlan ? (
        <ComparePlansModal
          myPlanId={myPlan ? myPlan?.id : undefined}
          isOpen={showCompareModal}
          handleClose={() => setShowCompareModal(false)}
          planTypeId={plans[0].planTypeId}
          onChange={(id: string) => onSelect(id)}
        />
      ) : null}
    </Box>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  dependents: store.userRegistration.dependents,
  openEnrollmentId: store.user.openEnrollmentId,
  userGender: store.userRegistration.profile.gender,
});

export default connect(mapStateToProps)(EnrollmentsGroup);
