import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  createStyles,
  withStyles,
  SvgIcon,
  FormLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';

interface Props {
  handleAcknowledgmentCheckbox: (_: any, checked: boolean) => void;
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUserNameBlur: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  userAcknowledgment: boolean;
  userAcknowledgmentError: boolean;
  userName: string;
  userNameError: string;
}
const CustomFormControlLabel = withStyles({
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
})(FormControlLabel);

const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);

const CustomTextField = withStyles({
  root: {
    '& ::placeholder': {
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#9FA2B4',
      opacity: 0.8,
    },
    '& .Mui-error': {
      '& .MuiOutlinedInput-input': {
        border: '1px solid #E51212',
        borderRadius: '5px',
        backgroundColor: '#FFE3E3',
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4979C0',
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      '& fieldset': {
        borderColor: '#E1E2E7',
      },
      '&:hover fieldset': {
        borderColor: '#4979C0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #4979C0',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 0,
    },
    input: {
      marginTop: '10px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    control: {
      marginTop: '20px',
    },
    inputIcon: {
      cursor: 'pointer',
      color: '#4979C0',
    },
    margin: {
      margin: theme.spacing(1),
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#252733',
      marginTop: '20px',
    },
  })
);

const UserAcknowledgment: React.FC<Props> = ({
  handleAcknowledgmentCheckbox,
  handleNameChange,
  handleUserNameBlur,
  userAcknowledgment,
  userAcknowledgmentError,
  userName,
  userNameError,
}) => {
  const inputClasses = useStyles();
  return (
    <>
      <FormControl
        required
        error={userAcknowledgmentError}
        className={inputClasses.control}
      >
        <CustomFormControlLabel
          control={
            <CustomCheckbox
              icon={
                <SvgIcon
                  component={offIcon}
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                />
              }
              checkedIcon={
                <SvgIcon
                  component={onIcon}
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                />
              }
              checked={userAcknowledgment}
              onChange={handleAcknowledgmentCheckbox}
              color="primary"
            />
          }
          label="I acknowledge that I reviewed the information above."
        />
        {userAcknowledgmentError && (
          <FormHelperText>Acknowledgment required</FormHelperText>
        )}
        <FormLabel className={inputClasses.label} component="legend">
          Employee Name
        </FormLabel>
        <CustomTextField
          variant="outlined"
          onBlur={handleUserNameBlur}
          helperText={userNameError}
          error={!!userNameError}
          placeholder="Enter Employee Name"
          value={userName}
          className={inputClasses.input}
          onChange={handleNameChange}
        />
      </FormControl>
    </>
  );
};

export default UserAcknowledgment;
