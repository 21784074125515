import React from 'react';
import setClassNames from 'classnames';
import { Close } from '@material-ui/icons';
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Divider,
} from '@material-ui/core';

import styles from './styles.module.scss';

export interface ModalWindowPropsInterface {
  isOpen: boolean;
  handleClose?: () => void | Promise<void>;
  className?: string;
  title?: string | React.ReactNode;
  actions?: React.ReactNode;
  fullWidth?: boolean;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  dividers?: boolean;
  isConfirm?: boolean;
  withoutPadding?: boolean;
  withoutTitle?: boolean;
  round?: boolean;
}

export interface DialogTitleProps {
  children: React.ReactNode;
  onClose?: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={styles.modalHeader}>
      <Typography variant="h6" className={styles.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <Close className={styles.closeIcon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const ModalWindow: React.FC<ModalWindowPropsInterface> = (props) => {
  const {
    isOpen,
    className,
    handleClose,
    children,
    title,
    actions,
    fullWidth,
    maxWidth,
    isConfirm,
    withoutPadding,
    withoutTitle,
    round,
  } = props;

  const classNames = setClassNames('modal-container', {
    [className || '']: Boolean(className),
  });

  const handleEscKeyPress = (keyboardEvent: KeyboardEvent) => {
    if (keyboardEvent.key === 'Escape' && handleClose) {
      handleClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleEscKeyPress);
    return () => document.removeEventListener('keydown', handleEscKeyPress);
  });

  return (
    <Dialog
      className={`${classNames} ${(isConfirm || round) && styles.confirmModal}`}
      open={isOpen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      scroll="body"
    >
      {title && (
        <DialogTitle onClose={handleClose}>
          {!withoutTitle && title}
        </DialogTitle>
      )}
      {title && !withoutTitle && <Divider className={styles.divider} />}
      <DialogContent
        className={
          isConfirm
            ? styles.confirmContent
            : withoutPadding
            ? styles.withoutPadding
            : styles.content
        }
      >
        {children}
      </DialogContent>
      {actions && (
        <DialogActions
          className={isConfirm ? styles.confirmButton : styles.action}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalWindow;
