import React from 'react';
import { Box, Link } from '@material-ui/core';

import { jobCategoryHelpLink } from 'constants/onboarding';
import Selector from 'components/Forms/Selector/SelectorComponent';
import IBasicOption from 'models/IBasicOption';
import { IEEOPayload } from 'types/onboarding';

import styles from './styles.module.scss';

export interface IEEOFormProps {
  eeoJobCategoryOptions: IBasicOption<number>[];
  eeoMilitaryStatusOptions: IBasicOption<number>[];
  eeoRaceOptionsOptions: IBasicOption<number>[];
  genderOptions: IBasicOption<number>[];
  onChange: (payload: IEEOPayload) => void | Promise<void>;
  eeo: IEEOPayload;
}

const placeholders: Record<keyof IEEOPayload, string> = {
  eeoGender: 'Gender',
  eeoRace: 'Race / Ethnicity',
  eeoJobCategory: 'Job category',
  eeoMilitaryStatus: 'Military status',
};

const findOption = (options: IBasicOption<number>[], value: number | null) => {
  return options.find((option) => option.value === value);
};

const EEOForm: React.FC<IEEOFormProps> = (props) => {
  const {
    eeo,
    onChange,
    genderOptions,
    eeoJobCategoryOptions,
    eeoMilitaryStatusOptions,
    eeoRaceOptionsOptions,
  } = props;

  const onSelect = (name: keyof IEEOPayload) => {
    return (option?: IBasicOption<number>) => {
      onChange({
        ...eeo,
        [name]: option ? option.value : null,
      });
    };
  };

  const optionsMapping: Record<keyof IEEOPayload, IBasicOption<number>[]> = {
    eeoGender: genderOptions,
    eeoJobCategory: eeoJobCategoryOptions,
    eeoMilitaryStatus: eeoMilitaryStatusOptions,
    eeoRace: eeoRaceOptionsOptions,
  };

  const getSelectorValue = (
    name: keyof IEEOPayload
  ): IBasicOption<number> | undefined => {
    return findOption(optionsMapping[name], eeo[name]);
  };

  const getComponents = () => {
    const components: React.ReactNode[] = [];

    Object.keys(eeo).forEach((name) => {
      components.push(
        <Selector
          name={name}
          placeholder={placeholders[name as keyof IEEOPayload]}
          onChange={onSelect(name as keyof IEEOPayload)}
          options={optionsMapping[name as keyof IEEOPayload]}
          value={getSelectorValue(name as keyof IEEOPayload)}
          className={styles.eeoSelector}
        />
      );

      /**
       * Add help link below the job category selector
       */
      if (name === 'eeoJobCategory') {
        components.push(
          <Link
            underline="always"
            className={styles.helpLink}
            onClick={() => window.open(jobCategoryHelpLink)}
          >
            Help me choose the right job category
          </Link>
        );
      }
    });

    return components;
  };

  return <Box className={styles.eeoForm}>{getComponents()}</Box>;
};

export default EEOForm;
