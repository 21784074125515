import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Checkbox from '../Checkbox/CheckboxComponent';

export interface CheckboxFromGroupInterface<T = any> {
  label: string;
  value: T;
  name: string;
  disabled?: boolean;
}

export interface CheckboxGroupPropsinterface<T = any> {
  checkboxes: CheckboxFromGroupInterface<T>[];
  selected: T[]; // array of selected options
  onChange: (selected: T[]) => void | Promise<void>;
  selectAllCheckbox?: boolean;
  selectAllCheckboxLabel?: string;
  disableAll?: boolean;
}

const useStyles = makeStyles({
  checkboxGroup: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
});

const CheckboxGroup: React.FC<CheckboxGroupPropsinterface> = (props) => {
  const {
    checkboxes,
    selected,
    onChange,
    selectAllCheckbox,
    selectAllCheckboxLabel,
    disableAll,
  } = props;

  const classes = useStyles();
  const filteredCheckboxes = checkboxes.filter(
    (checkbox) => !checkbox.disabled
  );

  const onCheck = (value: any) => {
    return (isChecked: boolean) => {
      let newSelected = [...selected];
      if (isChecked) {
        newSelected.push(value);
      } else {
        newSelected = newSelected.filter((v) => v !== value);
      }
      onChange(newSelected);
    };
  };

  const selectAll = (isChecked: boolean) => {
    if (isChecked) {
      onChange(filteredCheckboxes.map((checkbox) => checkbox.value));
    } else {
      onChange([]);
    }
  };

  return (
    <Box className={classes.checkboxGroup}>
      {selectAllCheckbox && (
        <>
          <Checkbox
            name="Select all"
            onChange={selectAll}
            label={selectAllCheckboxLabel || 'Select all'}
            checked={
              !!selected.length && selected.length === filteredCheckboxes.length
            }
            disabled={disableAll}
          />
        </>
      )}
      {checkboxes.map((checkbox) => {
        return (
          <Checkbox
            key={checkbox.value}
            name={checkbox.name}
            label={checkbox.label}
            onChange={onCheck(checkbox.value)}
            checked={!!selected.find((value) => value === checkbox.value)}
            disabled={checkbox.disabled || disableAll}
          />
        );
      })}
    </Box>
  );
};

export default CheckboxGroup;
