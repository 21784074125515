import React from 'react';
import { Box, Typography } from '@material-ui/core';

import openEnrollmentDomain from 'api/domains/openEnrollment';
import { useQuery } from 'react-query';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import styles from './styles.module.scss';

interface IAsIsRenevalBlock {
  planId: string;
}

const checkOpenEnrollment = async (_: string, id: string) => {
  const { data } = await openEnrollmentDomain.check(id);
  return data;
};

const AsIsRenevalBlock: React.FC<IAsIsRenevalBlock> = ({ planId }) => {
  const { data, refetch } = useQuery(['data', planId], checkOpenEnrollment, {
    refetchOnWindowFocus: false,
  });

  const handleFix = async () => {
    try {
      await openEnrollmentDomain.fix(planId);
      refetch();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box>
      {data ? (
        <Box className={styles.alertWarning}>
          <Typography className={styles.textAlert}>
            Some employees are still enrolled in the old replaced plan.
          </Typography>
          <Box className={styles.fixBox}>
            <SubmitButton
              className={styles.fixBtn}
              onClick={handleFix}
              color="primary"
              label="Fix"
            />
          </Box>
        </Box>
      ) : (
        <Box className={styles.alertInfo}>
          <Typography className={styles.textAlert}>
            All eligible employees are already enrolled in this plan.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AsIsRenevalBlock;
