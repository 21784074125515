import React from 'react';
import Grid from '@material-ui/core/Grid';

import Skeleton from 'components/Skeleton';

import styles from './styles.module.scss';

const loadingItems = Array(2).fill(true);

const DependentsSkeleton: React.FC = () => {
  return (
    <Grid container spacing={3} className={styles.cardsGridContainer}>
      {loadingItems.map((_, i) => (
        <Grid item xs={12} sm={6} md={4} key={i}>
          <Skeleton variant="rect" height="15.8em" />
        </Grid>
      ))}
    </Grid>
  );
};

export default DependentsSkeleton;
