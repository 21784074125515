import React from 'react';
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  Typography,
  StepContent,
  StepperProps,
  StepProps,
  makeStyles,
  StepConnector,
  withStyles,
  Box,
} from '@material-ui/core';
import { StepIconProps } from '@material-ui/core/StepIcon';
import CheckIcon from '@material-ui/icons/Check';
import styles from '../styles.module.scss';

export interface SimpleStepDataInterface {
  label: string;
  content: string | React.ReactNode;
  optional?: boolean;
  disabled?: boolean;
}

type StepperPropsPickedInterface = Pick<
  StepperProps,
  'orientation' | 'className' | 'nonLinear'
>;

export interface SimpleStepperPropsInterface
  extends StepperPropsPickedInterface {
  steps: SimpleStepDataInterface[];
  activeStep: number;
  orientation: 'horizontal' | 'vertical';
  onStepClick?: (index: number) => void | Promise<void>;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  stepper: {
    background: 'transparent',
    padding: '24px 0px  30px 0px !important',
  },
  mainLabel: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: '#3BA6DD',
  },
  secondCompletedLabel: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#252733',
  },
  secondActiveLabel: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#252733',
  },
  secondNextLabel: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#9FA2B4',
  },
  buttonStep: {
    cursor: 'pointer',
  },
  labelBoxHorizontal: {
    position: 'relative',
    padding: 0,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    left: '45%',
  },
  labelBoxVertical: {
    padding: 0,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
  },
  step: {
    left: '-4%',
    right: '8%',
    width: 'max-content !important',
  },
  verticalContent: {
    borderColor: '#E1E2E7',
    borderLeftWidth: 2,
    borderRadius: 1,
  },
});

const HorizontalConnector = withStyles({
  alternativeLabel: {
    top: '8px',
    left: 'calc(-50% + 8px)',
    right: 'calc(50% + 8px)',
  },
  active: {
    '&*secondLabel': {
      color: '#fff',
    },
    '& $line': {
      borderColor: '#3DB9FE',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3DB9FE',
    },
  },
  line: {
    borderColor: '#E1E2E7',
    borderTopWidth: '1.5px',
    borderRadius: '1.5px',
  },
})(StepConnector);

const VericalConnector = withStyles({
  active: {
    '& $line': {
      borderColor: '#3DB9FE',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3DB9FE',
    },
  },
  line: {
    borderColor: '#E1E2E7',
    borderLeftWidth: '1.5px',
    borderRadius: '1.5px',
  },
})(StepConnector);

const useStepIconStyles = makeStyles({
  root: {
    padding: 0,
    height: '16px',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
  },
  completedIcon: {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    zIndex: 10,
    background: '#4979C0',
    color: '#ffff',
    padding: '3px',
  },
  activeIcon: {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    zIndex: 10,
    border: '3px solid #4979C0',
    background: '#F7F8FC',
  },
  nextIcon: {
    borderRadius: '50%',
    width: '22px',
    height: '22px',
    zIndex: 10,
    background: '#E1E2E7',
  },
});

function StepIcon(props: StepIconProps) {
  const classes = useStepIconStyles();
  const { active, completed } = props;

  return (
    <div className={classes.root}>
      {completed ? (
        <CheckIcon className={classes.completedIcon} />
      ) : active ? (
        <Box className={classes.activeIcon} />
      ) : (
        <Box className={classes.nextIcon} />
      )}
    </div>
  );
}

const StepperSimple: React.FC<SimpleStepperPropsInterface> = (props) => {
  const { steps, orientation, activeStep, onStepClick } = props;
  const classes = useStyles();
  const getHorizontalStepContent = () => {
    return (
      <>
        <div className={styles.stepperContentContainer}>
          {steps[activeStep] ? steps[activeStep].content : null}
        </div>
      </>
    );
  };

  const getVerticaltStepContent = () => {
    return (
      <StepContent className={classes.verticalContent}>
        {steps[activeStep] ? steps[activeStep].content : null}
      </StepContent>
    );
  };

  return (
    <div className={classes.root}>
      <MuiStepper
        className={classes.stepper}
        alternativeLabel={orientation === 'horizontal'}
        activeStep={activeStep}
        orientation={orientation}
        connector={
          orientation === 'horizontal' ? (
            <HorizontalConnector />
          ) : (
            <VericalConnector />
          )
        }
      >
        {steps.map((step, index) => {
          const stepProps: StepProps = {
            completed: index < activeStep,
            disabled: step.disabled,
          };
          const labelProps: { optional?: React.ReactNode } = {};
          if (step.optional) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          return (
            <Step key={step.label} {...stepProps} className={classes.step}>
              {onStepClick ? (
                <StepLabel
                  className={classes.buttonStep}
                  StepIconComponent={StepIcon}
                  onClick={() => onStepClick(index)}
                >
                  <Box
                    className={
                      orientation === 'horizontal'
                        ? classes.labelBoxHorizontal
                        : classes.labelBoxVertical
                    }
                  >
                    <Typography className={classes.mainLabel}>
                      STEP 0{index + 1}
                    </Typography>
                    <Typography
                      className={
                        activeStep === index
                          ? classes.secondActiveLabel
                          : stepProps.completed
                          ? classes.secondCompletedLabel
                          : classes.secondNextLabel
                      }
                    >
                      {step.label}
                    </Typography>
                  </Box>
                </StepLabel>
              ) : (
                <StepLabel StepIconComponent={StepIcon}>
                  <Box
                    className={
                      orientation === 'horizontal'
                        ? classes.labelBoxHorizontal
                        : classes.labelBoxVertical
                    }
                  >
                    <Typography className={classes.mainLabel}>
                      STEP 0{index + 1}
                    </Typography>
                    <Typography
                      className={
                        activeStep === index
                          ? classes.secondActiveLabel
                          : stepProps.completed
                          ? classes.secondCompletedLabel
                          : classes.secondNextLabel
                      }
                    >
                      {step.label}
                    </Typography>
                  </Box>
                </StepLabel>
              )}
              {orientation === 'vertical' && getVerticaltStepContent()}
            </Step>
          );
        })}
      </MuiStepper>
      {(!orientation || orientation === 'horizontal') &&
        getHorizontalStepContent()}
    </div>
  );
};

export default StepperSimple;
