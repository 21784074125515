import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useLocation, Redirect } from 'react-router-dom';

import { DEFAULT_PATH } from 'constants/routes';

interface IProps {
  routes: string[];
}

const NoMatch: React.FC<IProps> = ({ routes }) => {
  const location = useLocation();
  const inRoutes = routes.includes(location.pathname);

  if (inRoutes) {
    return <Redirect to={DEFAULT_PATH} />;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Typography variant="h5">
        No match for <code>{location.pathname}</code>
      </Typography>
    </Box>
  );
};

export default NoMatch;
