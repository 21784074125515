import React from 'react';
import { Grid } from '@material-ui/core';

import { DependentInterface } from 'types/dependents';
import DependentItem from './DependentItem';

import styles from './styles.module.scss';

export interface DependentListPropsInterface {
  dependents: DependentInterface[];
  onDeleteItem?: (id: string) => void;
  onEditItem?: (id: string) => void | Promise<void>;
}

const DependentList: React.FC<DependentListPropsInterface> = (props) => {
  const { dependents, onEditItem, onDeleteItem } = props;

  return (
    <Grid
      container
      wrap="wrap"
      spacing={2}
      className={styles.cardsGridContainer}
    >
      {dependents.map((dependent, index) => (
        <Grid
          sm={6}
          md={4}
          item
          key={index}
          className={styles.dependentGridItem}
        >
          <DependentItem
            dependent={dependent}
            onDeleteClick={
              onDeleteItem ? () => onDeleteItem(dependent.id || '') : undefined
            }
            onEditClick={
              onEditItem ? () => onEditItem(dependent.id || '') : undefined
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DependentList;
