import React, { useCallback, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, Form, FormikProps, FormikErrors } from 'formik';
import {
  IOpenEnrollmentPayload,
  IPeriodInterface,
  IPlanPayload,
} from 'types/statusDashboard';
import * as yup from 'yup';
import BlockWithHelpBuble from 'components/Popper/BlockWithHelpBuble';
import ModalWindowComponent from 'components/Modal/ModalWindowComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import { SvgIcon, Typography, withStyles } from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import FormikDatePicker from 'components/Forms/Formik/Datepicker';
import openEnrollmentDomain from 'api/domains/openEnrollment';

import Feedbacker from 'components/Feedbacker';
import styles from './styles.module.scss';
import PlansTable from './PlansTable';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  plans: any;
  periods: any;
  handleSubmitModal: (values: IOpenEnrollmentPayload) => void;
}

const CustomFormControlLabel = withStyles({
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
})(FormControlLabel);

const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);

const OpenEnrollmentModal: React.FC<IProps> = (props) => {
  const { isOpen, handleClose, plans, periods, handleSubmitModal } = props;

  const [period, setPeriod] = useState<IPeriodInterface | null>(null);
  const [chosenPlans, setChosenPlans] = useState<IPlanPayload[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (
      periods?.closedDate &&
      new Date().getTime() - new Date(periods?.closedDate).getTime() <
        30 * 86400 * 1000
    ) {
      setPeriod(null);
    } else {
      setPeriod(periods);
    }
  }, [periods]);

  useEffect(() => {
    if (period) {
      const plansTemp: IPlanPayload[] = [];
      for (const [id, row] of Object.entries(
        period?.plans as {
          [key: string]: { planId: string; asIsRenewal: boolean };
        }
      )) {
        const temp = plans?.find((planTemp: any) => id === planTemp.id);
        plansTemp.push({
          id: +id,
          effectiveDate: temp?.effectiveDate,
          asIsRenewal: Boolean(row.asIsRenewal),
        });
      }
      setChosenPlans(plansTemp);
    } else {
      setChosenPlans([]);
    }
  }, [plans, period]);

  const clearFeedbackError = () => setIsDisabled(false);

  const setTemporaryApiError = useCallback(
    (disable: boolean) => {
      setIsDisabled(disable);
      setTimeout(clearFeedbackError, 5000);
    },
    [setIsDisabled]
  );

  const getErrorMessage = (
    key: keyof IOpenEnrollmentPayload,
    formikProps: FormikProps<IOpenEnrollmentPayload>
  ): string | undefined => {
    const { errors, touched } = formikProps;
    const error = errors[key];
    const isTouched = touched[key];
    return isTouched && error ? (error as string) : '';
  };

  let recentPeriod: IPeriodInterface | null = null;

  if (
    periods?.closedDate &&
    new Date().getTime() - new Date(periods?.closedDate).getTime() <
      30 * 86400 * 10000
  ) {
    recentPeriod = periods;
  }

  const initialValues: IOpenEnrollmentPayload = {
    endDate: recentPeriod ? null : periods?.endDate,
    plans: [],
    close: false,
    reopen: false,
    openEnrollmentId: periods?.id,
    asIsRenewal: [],
    sendEmail: false,
    activeOpenEnrollment: null,
  };

  const activeOpenEnrollment =
    period?.endDate &&
    period?.employeeEnrollments &&
    period?.employeeEnrollments !== 'none';

  const isSelected = (id: string) => {
    return Boolean(chosenPlans.find((plan) => plan.id === id));
  };

  const isSelectedAsIsRenewal = (id: string) => {
    return Boolean(
      chosenPlans.find((plan) => plan.id === id && plan.asIsRenewal)
    );
  };

  const isAllSelected = () => {
    return chosenPlans.length === plans?.length;
  };

  const onSelectAllClick = () => {
    if (isAllSelected()) {
      setChosenPlans([]);
    } else {
      const temp: IPlanPayload[] = [];
      plans?.forEach((plan: any) => {
        temp.push({
          id: plan?.id,
          effectiveDate: plan?.effectiveDate,
          asIsRenewal: false,
        });
      });
      setChosenPlans(temp);
    }
  };

  const onSelectClick = (id: string, IsRenewal?: boolean) => {
    if (isSelected(id)) {
      const temp: IPlanPayload[] = chosenPlans.filter((plan) => plan.id !== id);
      if (IsRenewal) {
        const tempPlan = chosenPlans.find((plan) => plan.id === id);
        if (tempPlan) {
          setChosenPlans([
            ...temp,
            { ...tempPlan, asIsRenewal: !tempPlan?.asIsRenewal },
          ]);
        }
      } else {
        setChosenPlans(temp);
      }
    } else {
      const plan = plans?.find((plan: any) => plan.id === id);
      setChosenPlans([
        ...chosenPlans,
        {
          id,
          effectiveDate: plan.effectiveDate,
          asIsRenewal: false,
        },
      ]);
    }
  };

  const reopenOpenEnrollment = async () => {
    try {
      handleSubmitModal({
        openEnrollmentId: recentPeriod?.id,
        reopen: true,
        plans: chosenPlans,
      });
      handleClose();
    } catch (err) {
      handleClose();
    }
  };

  const closeOpenEnrollment = async () => {
    try {
      handleSubmitModal({
        openEnrollmentId: periods?.id,
        close: true,
        plans: chosenPlans,
      });
      handleClose();
    } catch (err) {
      handleClose();
    }
  };

  const handleSubmit = async (values: IOpenEnrollmentPayload) => {
    try {
      let isDisable = false;
      const renewalPlans = chosenPlans.filter((plan) => plan.asIsRenewal);
      for (const plan of renewalPlans) {
        const result = await checkEnrollment(plan.id as string);
        isDisable = isDisable || result;
      }
      if (isDisable) {
        setTemporaryApiError(isDisable);
        return;
      } else {
        handleSubmitModal({ ...values, plans: chosenPlans });
        handleClose();
      }
    } catch (err) {
      handleClose();
    }
  };

  const checkEnrollment = async (planId: string): Promise<boolean> => {
    try {
      const { data } = await openEnrollmentDomain.check(planId);
      return data > 0;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const validationSchema = yup.object().shape({
    endDate: yup.date().required('Field is required').nullable(),
  });

  const validate = async (
    values: IOpenEnrollmentPayload
  ): Promise<FormikErrors<IOpenEnrollmentPayload>> => {
    return new Promise((resolve, reject) => {
      validationSchema
        .validate(values, { abortEarly: false })
        .then(() => resolve({}))
        .catch((error: yup.ValidationError) => {
          const formikErrors = error.inner.reduce<
            FormikErrors<IOpenEnrollmentPayload>
          >((errors, currentError) => {
            return Object.assign(errors, {
              [currentError.path]: currentError.errors,
            });
          }, {});
          resolve(formikErrors);
        });
    });
  };
  return (
    <>
      <ModalWindowComponent
        dividers
        title="Open Enrollment"
        isOpen={isOpen}
        handleClose={handleClose}
        maxWidth="md"
        className={styles.modal}
      >
        <Box className={styles.terminateModal}>
          <Box className={styles.alertBox}>
            <SimpleAlert type="info">
              {!recentPeriod && periods?.endDate ? (
                <>
                  <Typography className={styles.infoText}>
                    Open enrollment is set to end at the end of the day on{' '}
                    {periods?.endDate}. At that time employees will no longer be
                    able to make changes to their benefit selections unless they
                    experience a life event.
                  </Typography>
                  <Typography className={styles.infoMainText}>
                    You need to make sure that all employees complete their
                    benefit enrollment prior to that time.
                  </Typography>
                </>
              ) : (
                <Typography className={styles.infoText}>
                  After you start open enrollment period, employees will be
                  asked to make their benefit selections and will be able to
                  make changes to their enrollments until the end of the open
                  enrollment period.
                </Typography>
              )}
            </SimpleAlert>
          </Box>
          {recentPeriod && (
            <Box className={styles.reopenBox}>
              <SubmitButton
                className={styles.reopenBtn}
                onClick={reopenOpenEnrollment}
                color="primary"
                label={`Reopen ${recentPeriod?.effectiveDate} Open Enrollment`}
              />
            </Box>
          )}
          <Box>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnChange={false}
              validateOnBlur
              validationSchema={validationSchema}
              validate={validate}
            >
              {(formik: FormikProps<IOpenEnrollmentPayload>) => (
                <Form className={styles.deleteEmployeeModalForm}>
                  <Box className={styles.endDateBox}>
                    <Box className={styles.endBox}>
                      <Box className={styles.endDateWrapper}>
                        <FormikDatePicker
                          value={formik.values.endDate}
                          label="Last day of the open enrollment period:"
                          variant="dialog"
                          name="endDate"
                          convertToDate
                          error={!!getErrorMessage('endDate', formik)}
                          helperText={getErrorMessage('endDate', formik)}
                        />
                      </Box>
                      <Box className={styles.BubbleWrapper}>
                        <BlockWithHelpBuble
                          iconClass={styles.iconStyles}
                          moreInfo={
                            'This date will be shown to the employees as the cutoff date \nto make their benefit selections.'
                          }
                        />
                      </Box>
                    </Box>
                    {period?.endDate && (
                      <SubmitButton
                        color="primary"
                        label="Close OE now"
                        onClick={closeOpenEnrollment}
                      />
                    )}
                  </Box>
                  <Typography>
                    Plans included in the Open Enrollment:
                  </Typography>
                  <PlansTable
                    plans={plans}
                    isSelected={isSelected}
                    isSelectedAsIsRenewal={isSelectedAsIsRenewal}
                    isAllSelected={isAllSelected}
                    onSelectClick={onSelectClick}
                    onSelectAllClick={onSelectAllClick}
                    activeOpenEnrollment={Boolean(activeOpenEnrollment)}
                  />
                  {!activeOpenEnrollment && (
                    <Box className={styles.emailCheckbox}>
                      <CustomFormControlLabel
                        control={
                          <CustomCheckbox
                            checked={formik.values.sendEmail}
                            onChange={formik.handleChange}
                            icon={
                              <SvgIcon
                                component={offIcon}
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                              />
                            }
                            checkedIcon={
                              <SvgIcon
                                component={onIcon}
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                              />
                            }
                            color="primary"
                            name="sendEmail"
                          />
                        }
                        label="Send email invite to all employees"
                      />
                    </Box>
                  )}
                  <Box className={styles.saveBox}>
                    <SubmitButton
                      disabled={formik.isSubmitting}
                      className={styles.saveBtn}
                      color="primary"
                      label="Save"
                    />
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </ModalWindowComponent>
      <Box className={styles.feedbackerBox}>
        <Feedbacker
          severity="error"
          feedbackMessage="Some employees are still enrolled in the old replaced plan."
          open={isDisabled}
          clearFeedback={clearFeedbackError}
        />
      </Box>
    </>
  );
};

export default OpenEnrollmentModal;
