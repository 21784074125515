import moment from 'moment';
import { UserProfileInterface, UpdateUserProfilePayload } from 'types/user';

export const fromProfileInterfaceToUpdateUserProfilePayload = (
  profile: UserProfileInterface
): UpdateUserProfilePayload => {
  return {
    ...profile,
    maritalStatusId: profile.maritalStatusId?.toString() || '1',
    gender: profile.gender?.toString() || '',
    smoker: profile.smoker?.toString() || '',
    birthDate: moment(profile.birthDate).format('MM/DD/YYYY'),
    ssn: profile.ssn.toString(),
  };
};
