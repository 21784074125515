import React from 'react';
import { Moment } from 'moment';
import DatePicker, {
  IDatePickerProps,
} from 'components/Forms/Datepicker/DatepickerComponent';
import { FormLabel } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import styles from './styles.module.scss';

export interface IFormikDatePickerProps
  extends Omit<IDatePickerProps, 'onChange'> {
  name: string;
  convertToDate?: boolean;
}

const FormikDatePicker: React.FC<IFormikDatePickerProps> = (props) => {
  const { convertToDate, label, ...otherProps } = props;
  const { setFieldValue, setFieldTouched, setFieldError } = useFormikContext();
  const [field] = useField(otherProps.name);

  const currentError = otherProps.helperText || undefined;

  const onChange = (date: Moment | null) => {
    setFieldTouched(field.name, true);
    setFieldValue(field.name, convertToDate ? date?.toDate() : date, true);
  };

  const onBlur = () => {
    setFieldTouched(field.name, true);
  };

  const onError = (error: React.ReactNode) => {
    if (typeof currentError !== 'undefined') {
      if (error && error !== currentError) {
        setFieldError(field.name, error as string);
      }
    }
  };

  return (
    <>
      <FormLabel component="legend" className={styles.label}>
        {label}
      </FormLabel>
      <DatePicker
        className={styles.datepicker}
        {...otherProps}
        onChange={onChange}
        onBlur={onBlur}
        onError={onError}
      />
    </>
  );
};

export default FormikDatePicker;
