import React from 'react';
import Box from '@material-ui/core/Box';

import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import IBasicOption from 'models/IBasicOption';
import getOptionLabelByValue from 'helpers/getOptionLabelByValue';
import { IEmployee } from 'types/employee';
import { IProfileCard } from 'types/profileCard';
import { EMPTY_VALUE } from 'constants/placeholders';

import { IDependentPersonal } from 'types/dependents';
import styles from './styles.module.scss';
import EmployeeCardItem from './EmployeeCardItem';

interface IProps {
  employee: IEmployee | null;
  maritalStatus: IBasicOption<number>[];
  gender: IBasicOption<number>[];
  employmentStatus: IBasicOption<number>[];
  accessGroup: IBasicOption<number>[];
  openEditEmployeeModal: () => void;
  handleHidePersonalData: () => void;
  handleOpenModal: () => void;
  handleShowPersonalData: (password: string) => Promise<void>;
  isPasswordPrompt: boolean;
  personalData: IDependentPersonal[];
  hideSsn: boolean;
}

const EmployeeCardList: React.FC<IProps> = ({
  employee,
  maritalStatus,
  gender,
  employmentStatus,
  accessGroup,
  openEditEmployeeModal,
  handleHidePersonalData,
  handleOpenModal,
  handleShowPersonalData,
  isPasswordPrompt,
  personalData,
  hideSsn,
}) => {
  const {
    maritalStatusId,
    birthDate,
    officePhone,
    workHours,
    homePhone,
    firstName,
    hireDate,
    lastName,
    address1,
    address2,
    income,
    gender: genderId,
    groupId,
    state,
    title,
    email,
    city,
    ssn,
    zip,
    employmentId,
    terminationDate,
    employmentType,
    link,
  } = employee || {};

  const cityStateZip = `${city || EMPTY_VALUE}, ${state || EMPTY_VALUE}, ${
    zip || EMPTY_VALUE
  }`;

  const cards: IProfileCard[] = [
    {
      title: 'Account Information',
      rows: [
        {
          name: 'Access Group:',
          value: getOptionLabelByValue(accessGroup, groupId),
        },
        {
          name: 'Username:',
          value: email || EMPTY_VALUE,
        },
      ],
    },
    {
      title: 'Personal Information',
      rows: [
        {
          name: 'Full name:',
          value: `${firstName} ${lastName}`,
        },
        {
          name: 'Date Of Birth:',
          value: birthDate,
        },
        {
          name: 'Gender:',
          value: getOptionLabelByValue(gender, genderId),
        },
        {
          name: 'Marital Status:',
          value: getOptionLabelByValue(maritalStatus, maritalStatusId),
        },
        {
          name: 'Social Security Number:',
          value: ssn || EMPTY_VALUE,
        },
      ],
    },
    {
      title: 'Contact Information',
      rows: [
        {
          name: 'Home Address:',
          value: `${address1 || ''} ${address2 || ''}`,
        },
        {
          name: 'City, ST, Zip:',
          value: cityStateZip,
        },
        {
          name: 'Home Telephone:',
          value: homePhone || EMPTY_VALUE,
        },
        {
          name: 'Work Telephone:',
          value: officePhone || EMPTY_VALUE,
        },
        {
          name: 'Email Address:',
          value: email || EMPTY_VALUE,
        },
      ],
    },
    {
      title: 'Employment Information',
      rows: [
        {
          name: 'Employment:',
          value: getOptionLabelByValue(employmentStatus, employmentId),
        },
        {
          name: 'Job Title:',
          value: title || EMPTY_VALUE,
        },
        {
          name: 'Date Of Hire:',
          value: hireDate || EMPTY_VALUE,
        },
        {
          name: 'Work Hours Per Week:',
          value: workHours || EMPTY_VALUE,
        },
        {
          name:
            employmentType && +employmentType === 2 ? 'Wage/hour:' : 'Salary:',
          value: income || EMPTY_VALUE,
        },
      ],
    },
  ];

  if (terminationDate) {
    cards[3].rows?.push({
      name: 'Termination Date:',
      value: terminationDate,
    });
  }

  if (link) {
    cards[0].rows?.push({
      name: 'link',
      value: link,
    });
  }

  return (
    <Box>
      {cards.map((card, i) => (
        <EmployeeCardItem
          key={i}
          card={card}
          handleHidePersonalData={handleHidePersonalData}
          handleOpenModal={handleOpenModal}
          handleShowPersonalData={handleShowPersonalData}
          isPasswordPrompt={isPasswordPrompt}
          hideSsn={hideSsn}
          button={
            i === 0 && (
              <SubmitButton
                onClick={openEditEmployeeModal}
                variant="contained"
                color="secondary"
                label="Edit"
                className={styles.editBtn}
              />
            )
          }
        />
      ))}
    </Box>
  );
};

export default EmployeeCardList;
