import React, { useState, FocusEvent, useEffect } from 'react';
import {
  Typography,
  Box,
  FormHelperText,
  Link,
  FormLabel,
} from '@material-ui/core';
import { Moment } from 'moment';
import { useSelector } from 'react-redux';

import StepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import DatePicker from 'components/Forms/Datepicker/DatepickerComponent';
import Input from 'components/Forms/Input/InputComponent';
import BlockWithHelpBuble from 'components/Popper/BlockWithHelpBuble';
import ConfirmationModal from 'components/Modal/ConfirmationModalComponent';
import WorkEligibilityType from 'enums/onboarding';
import { AppStoreInterface } from 'store/app';
import { i9Link } from 'constants/onboarding';
import onboardingDomain from 'api/domains/onboarding';
import { EmploymentEligibilityI, I9DocksI } from 'types/onboarding';
import IOnboardingStepProps from 'models/IOnboardingStepProps';
import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import EmploymentEligibilityDocuments from '../EmploymentEligibilityDocuments';
import CheckboxForm from './CheckboxForm';
import { EligibilityInputState, CheckboxData } from './types';
import { isBothRequiredFieldsAreFilled, isAllInputsEmpty } from './helpers';
import {
  NOT_US_CITIZEN,
  RESIDENT,
  LEGAL_ALIEN,
  USCIS_NUMBER,
  ALIEN_REGISTRATION_NUMBER,
  ADDMISION_NUMBER,
  LEGAL_ALIEN_ADDITIONAL,
  EXPIRATION_DATE,
} from './moreInfoConstants';

import styles from './styles.module.scss';

const EmploymentEligibilityVerification: React.FC<IOnboardingStepProps> = ({
  index,
  count,
  setIsLoading,
  moveForward,
  moveBack,
}) => {
  const [checkBoxState, setCheckboxState] = useState<CheckboxData[]>([]);
  const [checkBoxesError, setCheckboxesError] = useState(false);
  const [expirationDate, setExpirationDate] = useState<null | string>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputsState, setInputsState] = useState<EligibilityInputState>({
    uscisNumber: '',
    i94Number: '',
    passportNumber: '',
    issuanceCountry: '',
  });
  const [fieldError, setFieldError] = useState('');
  const [dateError, setDateError] = useState('');
  const [pairFieldError, setPairFieldError] = useState('');
  const [i9Docs, setI9Docs] = useState<I9DocksI[]>([]);

  const { workEligibilityType } = useSelector(
    (state: AppStoreInterface) => state.options
  );

  const formInputsState = (data: any) => {
    return {
      uscisNumber: data.uscisNumber || '',
      i94Number: data.i94Number || '',
      passportNumber: data.passportNumber || '',
      issuanceCountry: data.issuanceCountry || '',
    };
  };

  useEffect(() => {
    if (workEligibilityType.length > 0) {
      (async () => {
        try {
          await setIsLoading(true);
          const { data } = await onboardingDomain.getEmploymentEligibility();

          const {
            expirationDate: incomeExpirationDate,
            workEligibilityType: eligibilityType,
            ...rest
          } = data;

          setInputsState(formInputsState(rest));
          setExpirationDate(incomeExpirationDate);

          setCheckboxState([
            {
              label: workEligibilityType[0].label,
              value: workEligibilityType[0].value,
              name: WorkEligibilityType.USCitizen,
              checked: workEligibilityType[0].value === Number(eligibilityType),
            },
            {
              label: workEligibilityType[1].label,
              value: workEligibilityType[1].value,
              name: WorkEligibilityType.NotUSCitizen,
              checked: workEligibilityType[1].value === Number(eligibilityType),
              moreInfo: NOT_US_CITIZEN,
            },
            {
              label: workEligibilityType[2].label,
              value: workEligibilityType[2].value,
              name: WorkEligibilityType.Resident,
              checked: workEligibilityType[2].value === Number(eligibilityType),
              moreInfo: RESIDENT,
            },
            {
              label: workEligibilityType[3].label,
              value: workEligibilityType[3].value,
              name: WorkEligibilityType.AlienAuthorizedToWork,
              checked: workEligibilityType[3].value === Number(eligibilityType),
              moreInfo: LEGAL_ALIEN,
            },
          ]);
        } catch (err) {
          console.error(err);
        } finally {
          await setIsLoading(false);
        }
      })();
    } else {
      (async () => setIsLoading(true))();
    }
  }, [workEligibilityType, setIsLoading]);

  const getActiveCheckboxValue = () => {
    return checkBoxState.find(({ checked }) => checked)?.value;
  };

  const handleInformationModalSubmit = async () => {
    try {
      await setIsModalOpen(false);
      await moveForward();
    } catch (error) {
      console.error(error);
    } finally {
      await setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    const checkedOption = checkBoxState.find(({ checked }) => checked);
    if (!checkedOption) {
      setCheckboxesError(true);
      return;
    }
    const isFormValid = getFormValidStatus(checkedOption.name);
    if (!isFormValid) {
      return;
    }
    if (!!fieldError && !!dateError) {
      return;
    }
    const eligibilityPayloads = {
      ...inputsState,
      expirationDate: expirationDate,
      workEligibilityType: getActiveCheckboxValue(),
    } as EmploymentEligibilityI;

    try {
      await setIsLoading(true);
      await onboardingDomain.saveEmploymentEligibility(eligibilityPayloads);
      const { data } = await onboardingDomain.getI9Docks();
      setI9Docs(data);
      await setIsModalOpen(true);
    } catch (error) {
      console.error(error);
    } finally {
      await setIsLoading(false);
    }
  };

  const renderStepperButtons = () => {
    return (
      <StepperButtons
        handleNext={() => {
          handleSubmit();
        }}
        handleBack={moveBack}
        hideBackBtn={index === 3}
      />
    );
  };

  const handleCheckboxChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const newState = checkBoxState.map((checkbox) => {
      const { name, checked } = target;
      if (
        name === WorkEligibilityType.USCitizen ||
        name === WorkEligibilityType.NotUSCitizen
      ) {
        setDateError('');
        setFieldError('');
      }
      if (name === WorkEligibilityType.Resident) {
        setDateError('');
      }
      if (name === checkbox.name) {
        checkbox.checked = checked;
        return checkbox;
      }
      checkbox.checked = false;
      return checkbox;
    });
    setCheckboxState(newState);
    const isOneChecked = checkBoxState.some(({ checked }) => checked);
    setCheckboxesError(!isOneChecked);
  };

  const handleDatePick = (date: Moment | null) => {
    if (!date) {
      setExpirationDate(null);
      setDateError('Expire Date Is Required');
      return;
    }
    if (!date.isValid()) {
      setDateError('Date Format Is Not Valid');
    } else {
      setDateError('');
    }
    setExpirationDate(date.format('l'));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newState = {
      ...inputsState,
      [name]: value,
    };
    setInputsState(newState);
  };

  const handleInputBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target;

    if (isAllInputsEmpty(inputsState) && !value) {
      setFieldError('Field Is Required');
    } else {
      setFieldError('');
    }

    if (name === 'passportNumber' || name === 'issuanceCountry') {
      if (!isBothRequiredFieldsAreFilled(inputsState)) {
        setPairFieldError('Both Fields Are Required');
      } else {
        setPairFieldError('');
      }
    } else {
      setPairFieldError('');
    }
  };

  const renderAdditionalQuestions = () => {
    const checked = checkBoxState.find((checkbox) => checkbox.checked);
    if (!checked) {
      return null;
    }
    switch (checked.name) {
      case WorkEligibilityType.Resident: {
        return (
          <Box className={styles.subBox}>
            <FormLabel component="legend" className={styles.label}>
              Alien Registration Number / USCIS Number
            </FormLabel>
            <BlockWithHelpBuble
              iconClass={styles.helpIconAlign}
              moreInfo={USCIS_NUMBER}
            >
              <Input
                name="uscisNumber"
                placeholder="Enter Alien Registration Number / USCIS Number"
                onChange={handleInputChange}
                onBlur={(
                  e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  if (!e.target.value) {
                    setFieldError('Field Is Required');
                  } else {
                    setFieldError('');
                  }
                }}
                value={inputsState.uscisNumber}
                error={fieldError}
                helperText={fieldError}
              />
            </BlockWithHelpBuble>
          </Box>
        );
      }
      case WorkEligibilityType.AlienAuthorizedToWork: {
        return (
          <Box className={styles.subBox}>
            <FormLabel
              component="legend"
              className={`${styles.label} ${styles.margin}`}
            >
              Expire Date
            </FormLabel>
            <BlockWithHelpBuble
              iconClass={styles.helpIconAlign}
              moreInfo={EXPIRATION_DATE}
            >
              <DatePicker
                name="expirationDate"
                onChange={handleDatePick}
                value={expirationDate}
                error={!!dateError}
                helperText={dateError}
              />
            </BlockWithHelpBuble>
            <FormHelperText
              className={styles.helperText}
              error={!!fieldError || !!pairFieldError}
            >
              Only one of the last three questions is required.
            </FormHelperText>
            <Box className={styles.inputsContainer}>
              <FormLabel component="legend" className={styles.label}>
                Alien Registration Number / USCIS Number
              </FormLabel>
              <BlockWithHelpBuble
                iconClass={styles.helpIconAlign}
                moreInfo={ALIEN_REGISTRATION_NUMBER}
              >
                <Input
                  name="uscisNumber"
                  placeholder="Enter Alien Registration Number / USCIS Number"
                  onChange={handleInputChange}
                  value={inputsState.uscisNumber}
                  onBlur={handleInputBlur}
                  error={fieldError}
                  helperText={fieldError}
                />
              </BlockWithHelpBuble>
              <Typography className={styles.orSign}>Or</Typography>
              <FormLabel className={styles.label}>
                Form I-94 Admission Number
              </FormLabel>
              <BlockWithHelpBuble
                iconClass={styles.helpIconAlign}
                moreInfo={ADDMISION_NUMBER}
              >
                <Input
                  name="i94Number"
                  placeholder="Enter Form I-94 Admission Number"
                  onChange={handleInputChange}
                  value={inputsState.i94Number}
                  onBlur={handleInputBlur}
                  error={fieldError}
                  helperText={fieldError}
                />
              </BlockWithHelpBuble>
              <Typography className={styles.orSign}>Or</Typography>
              <form className={styles.inlineInputs}>
                <Box className={styles.inlineBoxText}>
                  <FormLabel
                    component="legend"
                    className={`${styles.label} ${styles.inline}`}
                  >
                    Foreign Passport Number
                  </FormLabel>
                  <FormLabel
                    component="legend"
                    className={`${styles.label} ${styles.rightInput} ${styles.inline}`}
                  >
                    Country of Issuance
                  </FormLabel>
                </Box>
                <BlockWithHelpBuble
                  iconClass={styles.helpIconAlign}
                  moreInfo={LEGAL_ALIEN_ADDITIONAL}
                >
                  <Box className={styles.inlineBox}>
                    <Input
                      className={styles.inline}
                      name="passportNumber"
                      placeholder="Enter Foreign Passport Number"
                      onChange={handleInputChange}
                      value={inputsState.passportNumber}
                      onBlur={handleInputBlur}
                      error={fieldError || pairFieldError}
                      helperText={fieldError || pairFieldError}
                    />
                    <Input
                      className={`${styles.rightInput} ${styles.inline}`}
                      placeholder="Enter Country of Issuance"
                      name="issuanceCountry"
                      onChange={handleInputChange}
                      value={inputsState.issuanceCountry}
                      onBlur={handleInputBlur}
                      error={fieldError || pairFieldError}
                      helperText={fieldError || pairFieldError}
                    />
                  </Box>
                </BlockWithHelpBuble>
              </form>
            </Box>
          </Box>
        );
      }
      default:
        return null;
    }
  };

  const getFormValidStatus = (checkedOptionName: WorkEligibilityType) => {
    if (checkedOptionName === WorkEligibilityType.Resident) {
      if (!inputsState.uscisNumber) {
        setFieldError('Field Is Required');
        return false;
      }
    }

    if (checkedOptionName === WorkEligibilityType.AlienAuthorizedToWork) {
      if (inputsState.passportNumber || inputsState.issuanceCountry) {
        if (!isBothRequiredFieldsAreFilled(inputsState)) {
          setPairFieldError('Both Fields Are Required');
          return false;
        }
      } else {
        setPairFieldError('');
      }

      if (isAllInputsEmpty(inputsState)) {
        setFieldError('Field Is Required');
        return false;
      }

      if (!expirationDate) {
        setDateError('Expire Date Is Required');
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <ContentTableBox
        title="Employment Eligibility"
        index={index}
        count={count}
        isBox
      >
        <Box className={styles.main}>
          <Box className={styles.title}>
            <Link
              underline="always"
              className={styles.link}
              onClick={() => window.open(i9Link)}
            >
              View Form I-9
            </Link>
          </Box>
          <Typography variant="subtitle1" className={styles.helpText}>
            I am aware that federal law provides for imprisonment and/or fines
            for false statements or use of false documents in connection with
            completion of this form.
          </Typography>
          <Typography variant="subtitle1" className={styles.helpText}>
            I attest, under penalty of perjury, that I am (check one of the
            following):
          </Typography>
          <CheckboxForm
            error={checkBoxesError}
            checkboxes={checkBoxState}
            handleChange={handleCheckboxChange}
          />
          {renderAdditionalQuestions()}
        </Box>
      </ContentTableBox>
      {renderStepperButtons()}
      <ConfirmationModal
        className={styles.modalConfim}
        maxWidth="xl"
        isOpen={isModalOpen}
        onSubmit={handleInformationModalSubmit}
        title="Employment Eligibility Documents"
        confirmButtonProps={{ variant: 'contained' }}
      >
        <EmploymentEligibilityDocuments i9Docs={i9Docs} />
      </ConfirmationModal>
    </>
  );
};

export default EmploymentEligibilityVerification;
