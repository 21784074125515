import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import StepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import Skeleton from 'components/Skeleton';
import ICommonRegistrationStepProps from 'models/ICommonRegistrationStepProps';
import additionalInfoDomain from 'api/domains/additionalInfo';
import FormQuestionsFilterType from 'enums/formQuestionsFilterType';
import { AppStoreInterface } from 'store/app';
import {
  IModifiedFormQuestion,
  IFormQuestionError,
  IFormQuestionPayload,
  IFormQuestionsFilter,
} from 'types/additionalInfo';
import {
  ADDITIONAL_INFO_NOT_REQUIRED,
  ADDITIONAL_INFO_TITLE,
} from 'constants/messages';
import {
  fromFormQuestionsToQuestionsMap,
  fromFormQuestionsToQuestionsMapErrors,
} from './fromFormQuestionsToQuestionsMap';
import FieldFactory from './FieldFactory';

import styles from './styles.module.scss';

export interface IProps extends ICommonRegistrationStepProps {
  userFirstName?: string;
}

const AdditionalInfoSettings: React.FC<IProps> = (props) => {
  const { userFirstName, moveForward, setIsLoading, moveBack } = props;

  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<
    Map<string, IModifiedFormQuestion[]>
  >(new Map());

  const [questionErrors, setQuestionErrors] = useState<
    Map<string, IFormQuestionError[]>
  >(new Map());

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await additionalInfoDomain.getFormQuestions();
        setQuestions(fromFormQuestionsToQuestionsMap(data));
        setQuestionErrors(fromFormQuestionsToQuestionsMapErrors(data));
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleQuestionChange = (
    key: string,
    idx: number,
    value: string | boolean
  ) => {
    setQuestions((questions) => {
      const values = questions.get(key) ?? [];
      const newValues = [...values];

      if (values && values[idx].dependent) {
        newValues[idx].dependent!.info = value;
      } else {
        newValues[idx].info = value;
      }
      return new Map(questions.set(key, [...newValues]));
    });
  };

  const handleErrorCheck = (
    key: string,
    idx: number,
    value: string | boolean
  ) => {
    setQuestionErrors((errors) => {
      const values = errors.get(key) ?? [];
      const newValues = [...values];

      if (values && values[idx].required) {
        if (!value) {
          newValues[idx].error = 'Field is required';
        } else {
          newValues[idx].error = undefined;
        }
        return new Map(errors.set(key, [...newValues]));
      }
      return errors;
    });
  };

  const getFieldError = (key: string, idx: number) => {
    const errorByKey = questionErrors.get(key);
    return errorByKey ? errorByKey[idx].error : '';
  };

  const checkIfAnyQuestionsErrors = () => {
    let isError = false;
    questionErrors.forEach((value) => {
      value.forEach((question) => {
        if (question.error) {
          isError = true;
        }
      });
    });
    return isError;
  };

  const validateOnSubmit = () => {
    const setError = (key: string, idx: number) => {
      setQuestionErrors((errors) => {
        const values = errors.get(key) ?? [];
        const newValues = [...values];
        newValues[idx].error = 'Field is required';
        return new Map(errors.set(key, [...newValues]));
      });
    };

    questions.forEach((value, key) => {
      value.forEach((question, idx) => {
        if (question.required) {
          if (question.dependent) {
            if (!question.dependent.info) {
              setError(key, idx);
            }
          } else {
            if (!question.info) {
              setError(key, idx);
            }
          }
        }
      });
    });
  };

  const handleSubmit = async () => {
    validateOnSubmit();
    if (checkIfAnyQuestionsErrors()) return;

    const dataToSend: IFormQuestionPayload[] = [];

    questions.forEach((value) => {
      value.forEach((question) => {
        const { dependent } = question;
        const dependentId = dependent ? dependent.id : null;
        const info = dependent ? dependent.info : question.info;
        dataToSend.push({ fieldId: question.id, dependentId, info });
      });
    });

    try {
      setIsLoading(true);
      await additionalInfoDomain.updateFormQuestions(dataToSend);
      await moveForward();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const isFilteredQuestionVisible = (
    filter: IFormQuestionsFilter,
    questions: IModifiedFormQuestion[]
  ) => {
    const filteredQuestion = questions.find(
      (question) => +question.id === +filter.fieldId
    );
    if (filteredQuestion) {
      const info = filteredQuestion.info ?? filteredQuestion.dependent?.info;
      if (filteredQuestion) {
        switch (filter.operator) {
          case FormQuestionsFilterType.Equals:
            return info === filter.fieldValue;
          case FormQuestionsFilterType.DoesNotEqual:
            return info !== filter.fieldValue;
        }
      }
    }
    return false;
  };

  const renderQuestions = (questions: Map<string, IModifiedFormQuestion[]>) => {
    let jsx: JSX.Element[] = [];
    const lastMapKey = Array.from(questions.keys()).pop();
    for (let [key, value] of questions) {
      jsx.push(
        <Box key={key} className={styles.questionSection}>
          <Typography
            className={
              key === 'Employee:'
                ? styles.questionSectionName
                : styles.questionTitle
            }
            variant="h6"
          >
            {key}
          </Typography>
          {value.length ? (
            <>
              {value.map((question, idx) => (
                <Box key={`${key}${idx}`}>
                  {!question.filter ||
                  isFilteredQuestionVisible(question.filter, value) ? (
                    <FieldFactory
                      question={question}
                      mapKey={key}
                      index={idx}
                      handleQuestionChange={handleQuestionChange}
                      handleErrorCheck={handleErrorCheck}
                      error={!!getFieldError(key, idx)}
                      helperText={getFieldError(key, idx)}
                    />
                  ) : null}
                </Box>
              ))}
            </>
          ) : (
            <Typography variant="subtitle1">
              No additional information required
            </Typography>
          )}
          {lastMapKey !== key && <Divider className={styles.sectionDivider} />}
        </Box>
      );
    }
    return jsx;
  };

  const additionalInfoTitle = ADDITIONAL_INFO_TITLE(userFirstName);
  const notRequiredTitle = ADDITIONAL_INFO_NOT_REQUIRED(userFirstName);

  const isQuestionsEmpty = questions.size === 0;

  if (loading) {
    return <Skeleton variant="rect" height="10em" />;
  }
  return (
    <>
      <ContentTableBox title="Additional Information" index={6} count={8} isBox>
        <SimpleAlert
          className={styles.alertTop}
          type="info"
          message={isQuestionsEmpty ? notRequiredTitle : additionalInfoTitle}
        />
        <Box>{!isQuestionsEmpty && renderQuestions(questions)}</Box>
      </ContentTableBox>
      <StepperButtons
        handleNext={isQuestionsEmpty ? moveForward : handleSubmit}
        handleBack={moveBack}
      />
    </>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  userFirstName: store.userRegistration.profile.firstName,
});

export default connect(mapStateToProps)(AdditionalInfoSettings);
