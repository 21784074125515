import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import EnrollmentsIssues from 'components/EnrollmentsIssues';
import Currency from 'components/Currency';
import { IPlanSummary } from 'types/plans';

import { Task } from 'types/enrollments';
import EnrollmentStatus from 'enums/enrollmentStatus';
import { Button } from '@material-ui/core';
import PlanSummaryModal from 'components/PlanSummaryModal';
import DeclinedPlansTable from './DeclinedPlansTable';
import styles from './styles.module.scss';

interface IProps {
  plans: IPlanSummary[] | null;
  enrollmentsIssues?: Task[];
  employeeId?: string;
}

const Enrollments: React.FC<IProps> = ({
  plans,
  enrollmentsIssues,
  employeeId,
}) => {
  const [chosenPlanId, setChosenPlanId] = useState<string>();

  const onPlanClick = (planId: string) => {
    setChosenPlanId(planId);
  };

  const totalContributionPayPeriod =
    plans &&
    plans.reduce(
      (curr, { contributionAmount }) => curr + contributionAmount,
      0
    );

  const getCovered = (dependents: string[]) => {
    const dependentsFirstNames = dependents.map((dependent) =>
      dependent.replace(/\s(.*)/, '')
    );
    return ['Employee', ...dependentsFirstNames].join(', ');
  };

  const electedPlans =
    plans &&
    plans.filter((plan) => +plan.status > +EnrollmentStatus.NotSelected);
  const declinedPlans =
    plans &&
    plans.filter((plan) => +plan.status < +EnrollmentStatus.NotSelected);

  return (
    <>
      <Box className={styles.enrollments}>
        {
          <Box className={styles.enrollmentsIssues}>
            <EnrollmentsIssues issues={enrollmentsIssues} />
          </Box>
        }
        {electedPlans && electedPlans.length ? (
          <ContentContainer title="Enrollments" withoutMargin>
            <Box className={styles.wrapper}>
              <TableContainer component={Box}>
                <Table className={styles.electedTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Plan Name</TableCell>
                      <TableCell>Covered</TableCell>
                      <TableCell>Effective From</TableCell>
                      <TableCell>Effective Until</TableCell>
                      <TableCell>Policy Number</TableCell>
                      <TableCell>Member ID</TableCell>
                      <TableCell>Contribution</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {electedPlans.map((plan, i) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          <Box className={styles.planBox}>
                            <Button
                              disableTouchRipple
                              onClick={() => onPlanClick(plan.planId)}
                              className={styles.planDetailsBtn}
                              variant="text"
                            >
                              <PlanIcons planTypeId={Number(plan.planTypeId)} />
                              <Box className={styles.nameBox}>
                                {plan.planName}
                              </Box>
                            </Button>
                            <Button
                              className={styles.infoBtn}
                              variant="outlined"
                              color="primary"
                              onClick={() => onPlanClick(plan.planId)}
                            >
                              Info
                            </Button>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {plan.dependents
                            ? getCovered(plan.dependents)
                            : 'Employee'}
                        </TableCell>
                        <TableCell>{plan?.eligibilityDate}</TableCell>
                        <TableCell>{plan.effectiveToDate}</TableCell>
                        <TableCell>{plan.policyNumber}</TableCell>
                        <TableCell>{plan.memberNumber}</TableCell>
                        <TableCell>
                          <Currency fixed={2}>
                            {plan.contributionAmount}
                          </Currency>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={6} align="right">
                        <Typography className={styles.total}>
                          Total contribution per pay period:
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Currency fixed={2}>
                          {totalContributionPayPeriod}
                        </Currency>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </ContentContainer>
        ) : (
          <Box mt="1em" textAlign="center">
            <Typography variant="body1" className={styles.placeholder}>
              This employee is not enrolled in any plans
            </Typography>
          </Box>
        )}
        {!!chosenPlanId && (
          <PlanSummaryModal
            isOpen={!!chosenPlanId}
            planId={chosenPlanId}
            employeeId={employeeId}
            handleClose={() => setChosenPlanId(undefined)}
          />
        )}
      </Box>
      {declinedPlans && declinedPlans.length ? (
        <Box mb="3em">
          <DeclinedPlansTable plans={declinedPlans} />
        </Box>
      ) : (
        <Box mt="1em" textAlign="center">
          <Typography variant="body1" className={styles.placeholder}>
            This employee didn't decline any plans
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Enrollments;
