import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useQuery, useMutation, queryCache } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from 'components/Skeleton';
import SectionTitle from 'components/SectionTitle';
import FilesList from 'components/FilesList';
import Backdrop from 'components/Backdrop';
import FileType from 'enums/fileType';
import IBasicOption from 'models/IBasicOption';
import fileDomain from 'api/domains/file';
import PersonalFiles from './PersonalFiles';

import styles from './styles.module.scss';

const getEmployeeFiles = async () => {
  const { data } = await fileDomain.getFiles();
  return data;
};

const uploadPersonalFile = async (formData: FormData) => {
  const { data } = await fileDomain.uploadFile(formData);
  return data;
};
const useStyles = makeStyles({
  tab: {
    '& .MuiTabs-flexContainer': {
      overflow: 'auto',
    },
  },
  indicator: {
    display: 'none',
  },
  flexContainer: {
    overflow: 'auto',
  },
  selected: {
    borderRadius: '4px',
    background: '#d2dcec',
  },
  label: {
    color: '#9FA2B4',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
  },
  labelSelected: {
    color: '#4979C0',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
  },
});
const Documents: React.FC = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [isFileLoading, setFileLoading] = useState(false);

  const { data: files, isLoading: filesLoading } = useQuery(
    'employeeFiles',
    getEmployeeFiles,
    {
      refetchOnWindowFocus: false,
    }
  );

  const [
    uploadFile,
    { error: uploadError, reset: resetUploadError },
  ] = useMutation(uploadPersonalFile, {
    onSuccess: () => queryCache.refetchQueries('employeeFiles'),
  });

  const getFilesByType = (type: FileType) =>
    files && files.filter((file) => file.fileType === type);

  const tabs: IBasicOption<React.ReactNode>[] = [
    {
      label: 'Carrier Forms',
      value: (
        <FilesList
          files={getFilesByType(FileType.Carrier)}
          setLoading={setFileLoading}
        />
      ),
    },
    {
      label: 'Company Files',
      value: (
        <FilesList
          files={getFilesByType(FileType.Client)}
          setLoading={setFileLoading}
        />
      ),
    },
    {
      label: 'Personal Documents',
      value: (
        <PersonalFiles
          uploadFile={uploadFile}
          uploadError={uploadError ? 'Upload file error' : null}
          resetUploadError={resetUploadError}
          files={getFilesByType(FileType.Personal)}
          setLoading={setFileLoading}
        />
      ),
    },
  ];

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Documents</title>
      </Helmet>
      <Box className={styles.documentsContainer}>
        <Backdrop open={isFileLoading} />
        <SectionTitle title="My Documents" />
        {filesLoading ? (
          <Skeleton variant="rect" height="3em" />
        ) : (
          <Box className={styles.pageContainer}>
            <Tabs
              className={classes.tab}
              classes={{
                indicator: classes.indicator,
              }}
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {tabs.map((tab, index) => (
                <Tab
                  classes={{
                    selected: classes.selected,
                  }}
                  key={index}
                  label={
                    <Typography
                      className={
                        index === tabValue
                          ? classes.labelSelected
                          : classes.label
                      }
                    >
                      {tab.label}
                    </Typography>
                  }
                  value={index}
                />
              ))}
            </Tabs>
            <Box mt="1em">{tabs[tabValue].value}</Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Documents;
