import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import { IContact } from 'types/contacts';
import ContactType from 'enums/contactType';
import ContactSubmitForm from './ContactSubmitForm';

import styles from './styles.module.scss';

interface IContactInsuranceAgentProps {
  agents: IContact[];
  setIsLoading?: (isLoading: boolean) => void;
}

const ContactInsuranceAgent: React.FC<IContactInsuranceAgentProps> = ({
  agents,
  setIsLoading,
}) => {
  return (
    <Box className={styles.boxWrapper}>
      <ContentContainer
        title="Contact Insurance Agent"
        isBox
        withoutMargin
        padding
      >
        <Typography variant="subtitle2" className={styles.infoText}>
          Send message to agent via MyBenefitLink
        </Typography>
        <ContactSubmitForm
          contacts={agents}
          contactType={ContactType.Agent}
          setIsLoading={setIsLoading}
        />
      </ContentContainer>
    </Box>
  );
};

export default ContactInsuranceAgent;
