import React from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import parse from 'html-react-parser';
import { IAuditLogDetails } from 'types/auditLogs';
import AuditLogActionType from 'enums/auditLogActionType';
import { EMPTY_VALUE } from 'constants/placeholders';

import styles from './styles.module.scss';

interface IProps {
  logDetails: IAuditLogDetails;
}

const LogComparison: React.FC<IProps> = ({ logDetails }) => {
  const actionTypeIsNotCreate =
    logDetails.actionType !== AuditLogActionType.Create;
  const actionTypeIsNotDelete =
    logDetails.actionType !== AuditLogActionType.Delete;
  return (
    <Box>
      {logDetails.data ? (
        <TableContainer component={Box}>
          <Table className={styles.comparisonTable}>
            <TableHead>
              <TableRow>
                <TableCell className={styles.paddingLeft} />
                <TableCell>Field</TableCell>
                {actionTypeIsNotCreate && <TableCell>Old Value</TableCell>}
                {actionTypeIsNotDelete && <TableCell>New Value</TableCell>}
                <TableCell className={styles.paddingRight} />
              </TableRow>
            </TableHead>
            <TableBody>
              {logDetails.data.map((log, i) => (
                <TableRow
                  className={log.changed ? styles.changedRow : ''}
                  key={i}
                >
                  <TableCell className={styles.paddingLeft} />
                  <TableCell scope="row">{log.title}:</TableCell>
                  {actionTypeIsNotCreate && (
                    <TableCell scope="row">
                      {log.oldValue ? parse(log.oldValue!) : EMPTY_VALUE}
                    </TableCell>
                  )}
                  {actionTypeIsNotDelete && (
                    <TableCell scope="row">
                      {log.newValue ? parse(log.newValue!) : EMPTY_VALUE}
                    </TableCell>
                  )}
                  <TableCell className={styles.paddingRight} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box className={styles.placeholder}>
          There are no additional details available for this log entry
        </Box>
      )}
    </Box>
  );
};

export default LogComparison;
