import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  withStyles,
  SvgIcon,
} from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';

export interface CheckBoxPropsInterface {
  label?: string;
  name: string;
  onChange: (isChecked: boolean) => void | Promise<void>;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
}
const CustomFormControlLabel = withStyles({
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
})(FormControlLabel);

const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);
const CheckBox: React.FC<CheckBoxPropsInterface> = (props) => {
  const { label, name, onChange, checked, disabled, className } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.checked);

  return (
    <CustomFormControlLabel
      className={className}
      control={
        <CustomCheckbox
          icon={
            <SvgIcon
              component={offIcon}
              width="16"
              height="17"
              viewBox="0 0 16 17"
            />
          }
          checkedIcon={
            <SvgIcon
              component={onIcon}
              width="16"
              height="17"
              viewBox="0 0 16 17"
            />
          }
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
          name={name}
        />
      }
      label={label}
    />
  );
};

export default CheckBox;
