import React, { useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import parseHtmlToReact from 'html-react-parser';
import { bindActionCreators, Dispatch } from 'redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import StepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import FileUploader from 'components/FileUploader';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';

import { AppStoreInterface } from 'store/app';
import onboardingDomain from 'api/domains/onboarding';
import * as validationHelper from 'helpers/validation';
import * as onboardingRoutines from 'redux/sagas/onboarding/routines';
import UserAcknowledgment from './UserAcknowledgment';
import FieldFactory from './FieldFactory';
import File from './File';
import { fieldsToFieldMap, fieldsToErrorFieldMap } from './helpers';
import { MapField, MapFieldsError } from './types';

import styles from './styles.module.scss';

export type FieldPayload = {
  id: string;
  value: string | boolean;
};

interface CustomDocumentsI {
  index: number;
  count: number;
  userFirstName: string | null;
  moveForward: () => void | Promise<void>;
  moveBack?: () => void | Promise<void>;
  setIsLoading: (isLoading: boolean) => void | Promise<void>;
  actions: {
    fetchCustomData: (payload?: {
      moveForward?: () => void | Promise<void>;
    }) => void;
  };
}

const CustomDocuments: React.FC<CustomDocumentsI> = ({
  index,
  count,
  userFirstName,
  setIsLoading,
  moveForward,
  actions: { fetchCustomData },
}) => {
  const { onboarding } = useSelector((state: AppStoreInterface) => state);
  const {
    title,
    customFields,
    files,
    requireFileView,
    requireFileUpload,
    requireAcknowledgment,
    requireSignature,
    requireApproval,
    comment,
    id: onboardingId,
  } = onboarding.custom;

  const [userAcknowledgment, setUserAcknowledgment] = useState(false);
  const [userAcknowledgmentError, setUserAcknowledgmentError] = useState(false);

  const [userName, setUserName] = useState('');
  const [userNameError, setUserNameError] = useState('');

  const [isFileOpened, setIsFileOpened] = useState(false);
  const [fileViewError, setFileViewError] = useState(false);

  const [uploadedFileName, setUploadedFileName] = useState<string>();
  const [fileId, setFileId] = useState<null | string>(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploadedErrorMessage, setIsUploadedErrorMessage] = useState('');

  const [fieldsMap, setFieldsMap] = useState<Map<string, MapField>>(new Map());
  const [fieldsErrorMap, setFieldsErrorMap] = useState<
    Map<string, MapFieldsError>
  >(new Map());

  const [watchedFiles, setWatchedFiles] = React.useState<Map<string, boolean>>(
    new Map()
  );
  const [unwatchedFilesErros, setUnwatchedFilesErros] = React.useState<
    Map<string, boolean>
  >(new Map());

  const isSomeFilesUnwatched = (): boolean => {
    return Array.from(unwatchedFilesErros.values()).some(Boolean);
  };

  const onOpenFile = (fileId: string) => {
    return (isOpen: boolean) => {
      setIsFileOpened(isOpen);
      if (isOpen) {
        const newWatchedFiles = new Map<string, boolean>(watchedFiles);
        const newUnwatchedFilesErros = new Map<string, boolean>(
          unwatchedFilesErros
        );

        newWatchedFiles.set(fileId, true);
        newUnwatchedFilesErros.set(fileId, false);

        setWatchedFiles(newWatchedFiles);
        setUnwatchedFilesErros(newUnwatchedFilesErros);
      }
    };
  };

  useEffect(() => {
    const newWatchedFiles = new Map<string, boolean>();
    files.forEach((file) => {
      newWatchedFiles.set(file.id, false);
    });
  }, [files]);

  useEffect(() => {
    fetchCustomData();
  }, [fetchCustomData]);

  useEffect(() => {
    if (customFields && customFields.length > 0) {
      setFieldsMap(fieldsToFieldMap(customFields));
      setFieldsErrorMap(fieldsToErrorFieldMap(customFields));
    }
  }, [customFields]);

  const validateOnSubmit = () => {
    fieldsMap.forEach((field, key) => {
      handleErrorCheck(key, field.value);
    });
  };

  const checkIfAnyFieldErrors = () => {
    let isError = Array.from(unwatchedFilesErros.values()).some(Boolean);
    fieldsErrorMap.forEach((value) => {
      if (value.error) {
        isError = true;
      }
    });
    return isError;
  };

  const handleUnwatchedFilesErrors = () => {
    if (!requireFileView) return;
    const newUnwatchedFilesErros = new Map<string, boolean>();
    files.forEach((file) => {
      if (!watchedFiles.get(file.id)) {
        newUnwatchedFilesErros.set(file.id, true);
      }
    });
    setUnwatchedFilesErros(newUnwatchedFilesErros);
  };

  async function handleSubmit() {
    validateOnSubmit();
    handleUnwatchedFilesErrors();

    if (checkIfAnyFieldErrors()) return;

    if (!onboardingId) {
      await moveForward();
    }
    if (requireFileView && !isFileOpened) {
      setFileViewError(true);
      return;
    }
    if (requireFileUpload && !isUploaded) {
      setIsUploadedErrorMessage('File must be uploaded');
      return;
    }

    if (
      isRequireAcknowledgment() &&
      (!userAcknowledgment || !userName.trim())
    ) {
      !userName
        ? setUserNameError('Name is required')
        : setUserAcknowledgmentError(true);
      return;
    }
    const data = [] as FieldPayload[];
    fieldsMap.forEach((field) => {
      data.push({ id: field.id, value: `${field.value}` });
    });
    const fieldPayloads = {
      id: onboardingId,
      customFields: data,
      fullName: userName,
      fileId,
    };
    try {
      await setIsLoading(true);
      await onboardingDomain.saveCustomFieldsData(fieldPayloads);

      fetchCustomData({ moveForward });
      setUserAcknowledgment(false);
      setUserName('');
      setIsUploaded(false);
    } catch (error) {
      console.error(error);
    } finally {
      await setIsLoading(false);
    }
  }

  const renderStepperButtons = () => {
    return <StepperButtons handleNext={handleSubmit} disableBack hideBackBtn />;
  };

  const renderComment = (comment: string) => {
    return parseHtmlToReact(`<div>${comment}</div>`);
  };

  const handleFieldValueChange = (key: string, value: string | boolean) => {
    const field = fieldsMap.get(key);
    if (field) {
      setFieldsMap(new Map(fieldsMap.set(key, { ...field, value })));
    }
  };

  const schema = {
    email: validationHelper.email,
    ssn: validationHelper.ssn,
    phone: validationHelper.phone,
    zip: validationHelper.zip,
  };

  const handleErrorCheck = (key: string, value: string | boolean) => {
    const fieldError = fieldsErrorMap.get(key);
    const field = fieldsMap.get(key);
    if (!fieldError || !field) {
      return fieldsErrorMap;
    }

    const { fieldType } = field;
    if (!value && fieldType !== 'checkbox') {
      fieldError.error = 'Field is required';
    } else if (fieldType === 'email' && !schema.email.isValidSync(value)) {
      fieldError.error = 'Invalid Email Format';
    } else if (fieldType === 'ssn' && !schema.ssn.isValidSync(value)) {
      fieldError.error = 'Invalid SSN Format';
    } else if (fieldType === 'phone' && !schema.phone.isValidSync(value)) {
      fieldError.error = 'Invalid Phone Format';
    } else if (fieldType === 'zip' && !schema.zip.isValidSync(value)) {
      fieldError.error = 'Invalid ZIP code Format';
    } else {
      fieldError.error = undefined;
    }

    return setFieldsErrorMap(new Map(fieldsErrorMap.set(key, fieldError)));
  };

  const getFieldError = (key: string) => {
    const errorByKey = fieldsErrorMap.get(key);
    return errorByKey ? errorByKey.error : '';
  };

  const renderCustomFields = (fieldsMap: Map<string, MapField>) => {
    const fieldsComponents = [] as any;
    fieldsMap.forEach((field, key) => {
      fieldsComponents.push(
        <FieldFactory
          key={key}
          field={field}
          mapKey={key}
          handleChange={handleFieldValueChange}
          handleErrorCheck={handleErrorCheck}
          error={!!getFieldError(key)}
          helperText={getFieldError(key)}
        />
      );
    });
    return (
      <Box className={styles.customFieldsContainer}>{fieldsComponents}</Box>
    );
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUserName(value);
    if (!userName.trim()) {
      setUserNameError('Name is required');
    } else {
      setUserNameError('');
    }
  };

  const handleUserNameBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    if (!value) {
      setUserNameError('Name is required');
    } else {
      setUserNameError('');
    }
  };

  const handleAcknowledgmentCheckbox = (_: any, checked: boolean) => {
    setUserAcknowledgment(checked);
    setUserAcknowledgmentError(false);
  };

  const isRequireAcknowledgment = () =>
    requireAcknowledgment || requireApproval || requireSignature;

  return (
    <Box>
      <ContentTableBox title="Documents" index={index} count={count} isBox>
        <Box className={styles.main}>
          <Typography variant="h5" className={styles.title}>
            {title}
          </Typography>
          <Box className={styles.comment}>
            {comment ? renderComment(comment) : null}
          </Box>
          {files.map((file) => (
            <Box key={file.id} className={styles.fileBox}>
              <File
                file={file}
                error={fileViewError}
                setError={setFileViewError}
                setIsFileOpened={onOpenFile(file.id)}
              />
              {watchedFiles.get(file.id) && requireFileView && (
                <CheckCircleIcon
                  style={{ color: '#78B7A3' }}
                  className={styles.alreadyWatchedMessage}
                />
              )}
            </Box>
          ))}
          {isSomeFilesUnwatched() && requireFileView && (
            <SimpleAlert
              message="Please open and read all attached documents"
              type="error"
              className={styles.requiredViewFileWarning}
            />
          )}
          {requireFileUpload && (
            <>
              {uploadedFileName && (
                <Typography color="textSecondary" variant="caption">
                  {uploadedFileName}
                </Typography>
              )}
              <FileUploader
                errorMessage={isUploadedErrorMessage}
                setIsUploaded={setIsUploaded}
                setFileId={setFileId}
                setFileName={setUploadedFileName}
                setIsUploadedError={setIsUploadedErrorMessage}
              />
            </>
          )}
          {customFields.length > 0 && renderCustomFields(fieldsMap)}
          {(requireAcknowledgment || requireSignature) && (
            <UserAcknowledgment
              userName={userName}
              userNameError={userNameError}
              userAcknowledgment={userAcknowledgment}
              userAcknowledgmentError={userAcknowledgmentError}
              handleAcknowledgmentCheckbox={handleAcknowledgmentCheckbox}
              handleNameChange={handleNameChange}
              handleUserNameBlur={handleUserNameBlur}
            />
          )}
          {!onboardingId && (
            <SimpleAlert type="info">
              <Typography component="h5">
                {`${
                  userFirstName ? `${userFirstName}, at` : 'At'
                } this time you don't have to complete any documents. You can proceed to the next step.`}
              </Typography>
            </SimpleAlert>
          )}
        </Box>
      </ContentTableBox>
      {renderStepperButtons()}
    </Box>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  userFirstName: store.user.firstName,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      fetchCustomData: typeof onboardingRoutines.fetchCustomData;
    }
  >(
    {
      fetchCustomData: onboardingRoutines.fetchCustomData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDocuments);
