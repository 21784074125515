enum ExternalOnboardingStep {
  OnboardingIsFinished = 0,
  Pending = 1,
  Profile = 2,
  Documents = 3,
  Eligibility = 4,
  Tax = 5,
  Payroll = 6,
  Eeo = 7,
  Signature = 8,
}

export default ExternalOnboardingStep;
