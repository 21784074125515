import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';

import FillFieldsList, {
  FieldsInterface,
} from 'components/FillFieldsList/FillFieldsListComponent';
import { useFormik } from 'formik';
import IBasicOption from 'models/IBasicOption';
import { AppStoreInterface } from 'store/app';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import { DEFAULT_PATH } from 'constants/routes';
import history from 'helpers/history';
import userDomain from 'api/domains/user';
import { connect } from 'react-redux';
import SectionTitle from 'components/SectionTitle';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import Feedbacker from 'components/Feedbacker';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import * as userRoutines from 'redux/sagas/user/routines';
import * as yup from 'yup';

import { CHANGE_SQ_ALERT } from 'constants/feedbackMessages';
import styles from './styles.module.scss';

interface IChangeSecurityQuestionProps {
  loadCurrentUser: () => void;
  securityQuestions: IBasicOption<number>[];
}
interface IFormikValues {
  securityQuestion: IBasicOption<number> | string;
  securityQuestionAnswer: string;
}
const validationSchema = yup.object().shape({
  securityQuestion: yup.string().required('Field is required'),
  securityQuestionAnswer: yup.string().required('Field is required'),
});

const initialValues: IFormikValues = {
  securityQuestion: '',
  securityQuestionAnswer: '',
};
const ChangeSecurityQuestion: React.FC<IChangeSecurityQuestionProps> = ({
  securityQuestions,
  loadCurrentUser,
}) => {
  const [feedbackerMessage, setFeedbackerMessage] = React.useState<
    string | undefined
  >();
  // eslint-disable-next-line
  const [passwordChanged, setPasswordChanged] = React.useState<boolean>(false);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const setMessageWithDelay = (message: string) => {
    setFeedbackerMessage(message);
    setTimeout(() => setFeedbackerMessage(undefined), 5000);
  };

  const onError = () => {
    setMessageWithDelay('You provided wrong data');
  };

  async function handleSubmit(values: IFormikValues) {
    const { securityQuestionAnswer, securityQuestion } = values;

    const payload = {
      securityQuestionId: securityQuestion.toString(),
      securityAnswer: securityQuestionAnswer,
    };

    try {
      await userDomain.updateSecurityQuestion(payload);
      loadCurrentUser();
      history.push(DEFAULT_PATH);
    } catch (error) {
      onError();
      console.error(error);
    }
  }

  const getErrorMessage = (key: keyof IFormikValues): string | undefined => {
    const error = errors[key];
    const isTouched = touched[key];
    return isTouched && error ? (error as string) : '';
  };
  const { handleChange, handleBlur, values, errors, touched } = formik;

  const getFields = (): FieldsInterface[] => {
    return [
      {
        selectComponentData: {
          name: 'securityQuestion',
          options: securityQuestions,
          label: 'Security question',
          onChange: handleChange,
          onBlur: handleBlur,
          value: values.securityQuestion,
          error: !!getErrorMessage('securityQuestion'),
          helperText: getErrorMessage('securityQuestion'),
        },
        key: 'securityQuestion',
      },
      {
        inputComponentData: {
          value: values.securityQuestionAnswer,
          name: 'securityQuestionAnswer',
          label: 'Your answer',
          onChange: handleChange,
          onBlur: handleBlur,
          readOnly: !values.securityQuestion,
          error: getErrorMessage('securityQuestionAnswer'),
          helperText: getErrorMessage('securityQuestionAnswer'),
        },
        key: 'securityQuestionAnswer',
      },
    ];
  };

  return (
    <Box className={styles.pageContainer}>
      <Helmet>
        <title>MyBenefitLink | Change Password</title>
      </Helmet>
      <SectionTitle title="Add Security Question" />
      <Box className={styles.main}>
        <ContentContainer
          title="Adding Security Question"
          isBox
          withoutMargin
          padding
        >
          <Box className={styles.wrapper}>
            <SimpleAlert className={styles.alertMessage} type="warning">
              {CHANGE_SQ_ALERT}
            </SimpleAlert>
            <FillFieldsList fields={getFields()} />
            <SubmitButton
              className={styles.btn}
              disabled={formik.isSubmitting}
              onClick={formik.handleSubmit}
              label="Save"
            />
          </Box>
        </ContentContainer>
        <Feedbacker
          open={!!feedbackerMessage}
          clearFeedback={() => setFeedbackerMessage(undefined)}
          severity={passwordChanged ? 'success' : 'error'}
          feedbackMessage={feedbackerMessage as string}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  securityQuestions: store.options.securityQuestions,
});

const mapDispatchToProps = {
  loadCurrentUser: userRoutines.loadCurrentUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeSecurityQuestion);
