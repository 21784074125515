import { TableCell, Table, Chip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const border = '1px solid #DFE0EB;';
const sm = window.innerWidth < 600;

export const useStyles = makeStyles({
  tableContainer: { width: '100%', overflowX: 'inherit' },
  plans: {
    width: '55%',
  },
  planGroup: {},
  planInfo: {
    display: 'flex',
  },
  planCell: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  planCellSm: {
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  planContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  planBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  planWithIcon: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoBtn: {
    border: '1px solid #4979C0',
    color: '#4979C0',
    margin: '0 20px',
  },
  infoBtnSm: {
    border: '1px solid #4979C0',
    color: '#4979C0',
    margin: '10px 0px',
  },
  infoBtnDisable: {
    border: '1px solid #ffff !important',
    color: '#ffff !important',
    margin: '0 20px',
  },
  infoBtnSmDisable: {
    border: '1px solid #ffff !important',
    color: '#ffff !important',
    margin: '10px 0px',
  },
  groupTitleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  enrolledCell: {
    width: '25%',
    verticalAlign: 'sub',
  },
  contributionCell: {
    width: '20%',
    verticalAlign: 'sub',
  },
  contributionAmount: {
    width: 'fit-content',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#000000',
  },
  contributionAmountCommon: {
    width: 'fit-content',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#000000',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#252733',
  },
  groupTitle: {
    marginLeft: '60px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
  },
  groupDesc: { fontSize: '0.8rem' },
  groupDescClickable: {
    marginLeft: '60px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    textDecoration: 'underline',
    color: '#3DB9FE',
    textDecorationColor: '#3DB9FE',
    cursor: 'pointer',
  },
  actions: { width: '25%' },
  actionContainer: {
    width: '100%',
    display: 'flex',
  },
  plan: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  enrolled: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#252733',
    cursor: 'pointer',
  },
  notAvailable: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
    cursor: 'pointer',
  },
  subInfo: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
  },
  notSelected: { color: '#9FA2B4' },
  enrolledStatusCell: { width: '70px', textAlign: 'center' },
  enrolledCellHeader: { fontSize: '1.3rem' },
  nonActionContainer: { display: 'flex' },
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
  },
  btnAction: {
    color: '#ffff',
    background: '#4979C0',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    borderRadius: '4px',
    margin: '5px 0px 5px 10px',
  },
  enrolledIcon: {
    color: 'transparent',
    overflow: 'initial',
    marginRight: '2px',
    width: '20px',
    height: '20px',
  },
  enrolledPlanIcon: {
    color: 'transparent',
    overflow: 'initial',
    marginLeft: '5px',
    width: '20px',
    height: '20px',
  },
  enrolledIconLabel: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#252733',
    marginRight: '10px',
  },
  notAvailableLabel: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
  },
  enrolledIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
  },
  fsaContributionAmount: {
    fontSize: '1rem',
    listStyleType: 'none',
    padding: '0',
  },
  employerPaid: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  totalAmountLabel: {
    width: '80%',
  },
  titleAmount: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#000000',
    marginRight: '20px',
  },
  chipBlock: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',
  },
  modifyBtn: {
    background: '#90B9F5 !important',
  },
  dependentsLabel: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
    display: 'inline',
    margin: '8px 5px 0 0',
  },
});

export const CustomCell = withStyles({
  root: {
    padding: sm ? '5px 0' : '15px 0',
    borderTop: 'none',
    borderBottom: 'none',
  },
})(TableCell);

export const CustomTable = withStyles({
  root: {
    borderBottom: border,
    borderCollapse: 'unset',
  },
})(Table);
export const CustomTotalTable = withStyles({
  root: { border: 'none', borderCollapse: 'unset' },
})(Table);

export const ActionCell = withStyles({
  root: {
    padding: sm ? '20px 0' : '5px 0',
    borderBottom: 'none',
  },
})(TableCell);

export const ChipWithMargin = withStyles({
  root: {
    margin: '8px 5px  0 0',
    borderRadius: '17px',
    border: 'none',
    background: '#EEF3FD',
    fontFamily: '"Inter", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#4979C0',
  },
})(Chip);
