import React from 'react';

import { orange, red } from '@material-ui/core/colors';

import Popper from 'components/Popper/PopperComponent';
import ContentBox from 'components/ContentBox/ContentBoxComponent';
import {
  CheckCircle as CompletedIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';

import styles from './styles.module.scss';

interface IProps {
  errors: string[];
  alerts: string[];
}

const DependentStatus: React.FC<IProps> = ({ errors, alerts }) => {
  const getPopover = (messages: string[]): React.ReactNode => {
    return (
      <ContentBox className={styles.itemPopperMessage}>
        {messages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </ContentBox>
    );
  };

  if (errors.length) {
    return (
      <Popper popoverMessage={getPopover(errors)} placement="right-start">
        <ErrorIcon style={{ color: red[500] }} />
      </Popper>
    );
  }
  if (alerts.length) {
    return (
      <Popper popoverMessage={getPopover(alerts)} placement="right-start">
        <WarningIcon style={{ color: orange[500] }} />
      </Popper>
    );
  }
  return <CompletedIcon style={{ color: '#78B7A3' }} />;
};

export default DependentStatus;
