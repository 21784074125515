/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps, FormikBag } from 'formik';
import { Typography } from '@material-ui/core';
import { isEqual, isEmpty } from 'lodash';
import * as yup from 'yup';

import FillFieldsList, {
  FieldsInterface,
} from 'components/FillFieldsList/FillFieldsListComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import StepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import PrivacyNotice from 'components/Modal/PrivacyNotice';
import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import ICommonRegistrationStepProps from 'models/ICommonRegistrationStepProps';
import IBasicOption from 'models/IBasicOption';
import ExternalRegistrationStep from 'enums/externalRegistrationStep';

import userDomain from 'api/domains/user';
import ssnToMaskedSsn from 'helpers/ssnToMaskedSnn';
import { fromProfileInterfaceToUpdateUserProfilePayload } from 'mappers/employee/fromProfileInterfaceToUpdateUserProfilePayload';
import { Actions as UserActions } from 'redux/reducers/user/actions';
import { AppStoreInterface } from 'store/app';
import { loadUserProfile } from 'redux/sagas/userRegistration/routines';

import { PartialUserProfile, UserProfileInterface } from 'types/user';
import { ZIP_REG_EXP } from 'constants/regExps';
import { PROFILE_SETTINGS_ALERT } from 'constants/messages';
import * as validationHelper from 'helpers/validation';
import * as inputFormats from 'constants/inputFormats';
import getInitialValues from './getInitialValues';

import styles from './styles.module.scss';

interface IFormikValues
  extends Omit<
    UserProfileInterface,
    'email' | 'cellPhoneIndex' | 'homePhoneIndex' | 'officePhoneIndex'
  > {}

export interface ProfileSettingsPropsInterface
  extends ICommonRegistrationStepProps {}

type ProfileSettingsActions = {
  loadUserProfile: typeof loadUserProfile.trigger;
  addProfileData: typeof loadUserProfile.success;
  editUserData: typeof UserActions.editUserData;
};

export interface ProfileSettingsPropsFromStateInterface {
  actions: ProfileSettingsActions;
  genderOptions: IBasicOption<number>[];
  smokerOptions: IBasicOption<number>[];
  maritalStatusOptions: IBasicOption<number>[];
  stateOptions: IBasicOption<string>[];
  userProfile?: PartialUserProfile;
}

type AllProps = ProfileSettingsPropsFromStateInterface &
  ProfileSettingsPropsInterface;

export interface ProfileSettingsStateInterface {
  ssnEditable: boolean;
  privacyModalOpen: boolean;
  initialSsn?: string;
}

type WithFormikProps = AllProps & FormikProps<IFormikValues>;

class ProfileSettings extends React.Component<
  WithFormikProps,
  ProfileSettingsStateInterface
> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    privacyModalOpen: false,
    ssnEditable: false,
    initialSsn: undefined,
  };

  async componentDidMount() {
    const { userProfile, actions } = this.props;
    const userProfileIsEmpty = isEmpty(userProfile);

    actions.loadUserProfile();
    if (!userProfileIsEmpty) {
      this.setInitialValues(userProfile);
      this.setState({ initialSsn: userProfile?.ssn });
    }
  }

  componentDidUpdate(prevProps: WithFormikProps) {
    const { userProfile } = this.props;

    if (!isEqual(userProfile, prevProps.userProfile)) {
      this.setInitialValues(userProfile);
    }
  }

  setInitialValues = (userProfile?: PartialUserProfile) => {
    const {
      genderOptions,
      smokerOptions,
      maritalStatusOptions,
      stateOptions,
      setValues,
    } = this.props;

    const initialValues = getInitialValues(
      genderOptions,
      smokerOptions,
      maritalStatusOptions,
      stateOptions,
      userProfile
    );

    setValues(initialValues);
    this.setState({ initialSsn: userProfile?.ssn });
  };

  toggleSsnEditable = () => {
    const { setFieldValue } = this.props;
    const { initialSsn } = this.state;
    this.setState(
      (prevState) => ({ ssnEditable: !prevState.ssnEditable }),
      () => {
        const { ssnEditable } = this.state;
        setFieldValue('ssn', ssnEditable ? '' : initialSsn);
      }
    );
  };

  getErrorMessage = (key: keyof IFormikValues): string | undefined => {
    const { errors, touched } = this.props;
    const error = errors[key];
    const isTouched = touched[key];
    return isTouched && error ? (error as string) : '';
  };

  handleZipCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { handleChange, setFieldValue } = this.props;
    handleChange(e);

    const zip = e.target.value;
    const zipValid = zip.match(ZIP_REG_EXP);

    if (zipValid) {
      try {
        const { data } = await userDomain.zipCodeLookup(zip);
        setFieldValue('city', data.city || '');
        setFieldValue('state', data.state || '');
      } catch (err) {
        console.error(err);
      }
    }
  };

  getProfileFields = (): FieldsInterface[] => {
    const {
      values,
      genderOptions,
      smokerOptions,
      maritalStatusOptions,
      stateOptions,
      handleBlur,
      handleChange,
    } = this.props;

    const { ssnEditable, initialSsn } = this.state;
    return [
      {
        key: 'firstName',
        inputComponentData: {
          onChange: handleChange,
          value: values.firstName,
          name: 'firstName',
          label: 'First name',
          onBlur: handleBlur,
          error: this.getErrorMessage('firstName'),
        },
      },
      {
        key: 'middleName',
        inputComponentData: {
          onChange: handleChange,
          value: values.middleName,
          label: 'Middle name',
          name: 'middleName',
          onBlur: handleBlur,
          error: this.getErrorMessage('middleName'),
        },
      },
      {
        key: 'lastName',
        inputComponentData: {
          onChange: handleChange,
          value: values.lastName,
          name: 'lastName',
          label: 'Last name',
          onBlur: handleBlur,
          error: this.getErrorMessage('lastName'),
        },
      },
      {
        key: 'maidenName',
        inputComponentData: {
          onChange: handleChange,
          value: values.maidenName,
          name: 'maidenName',
          label: 'Other names used',
          onBlur: handleBlur,
          error: this.getErrorMessage('maidenName'),
        },
      },
      {
        key: 'birthDate',
        formikDatePickerComponentData: {
          value: values.birthDate,
          label: 'Date of birth',
          variant: 'inline',
          name: 'birthDate',
          error: !!this.getErrorMessage('birthDate'),
          helperText: this.getErrorMessage('birthDate'),
          convertToDate: true,
          onBlur: handleBlur,
        },
      },
      {
        key: 'gender',
        selectComponentData: {
          options: genderOptions,
          value: values.gender,
          name: 'gender',
          onChange: handleChange,
          label: 'Gender',
          onBlur: handleBlur,
          error: !!this.getErrorMessage('gender'),
          helperText: this.getErrorMessage('gender'),
        },
      },
      {
        key: 'smoker',
        selectComponentData: {
          options: smokerOptions,
          value: values.smoker,
          name: 'smoker',
          onChange: handleChange,
          label: 'Smoker',
          onBlur: handleBlur,
          error: !!this.getErrorMessage('smoker'),
          helperText: this.getErrorMessage('smoker'),
        },
      },
      {
        key: 'maritalStatusId',
        selectComponentData: {
          options: maritalStatusOptions,
          value: values.maritalStatusId,
          onChange: handleChange,
          label: 'Marital status',
          onBlur: handleBlur,
          name: 'maritalStatusId',
          error: !!this.getErrorMessage('maritalStatusId'),
          helperText: this.getErrorMessage('maritalStatusId'),
        },
      },
      {
        key: 'ssn',
        ssnInputComponentData: {
          onChange: handleChange,
          value: values.ssn,
          name: 'ssn',
          label: 'Social Security Number',
          onBlur: handleBlur,
          error: this.getErrorMessage('ssn'),
          editable: ssnEditable,
          toggleEditable: this.toggleSsnEditable,
          initialValue: initialSsn,
        },
      },
      {
        key: 'address1',
        inputComponentData: {
          onChange: handleChange,
          value: values.address1,
          name: 'address1',
          label: 'Home address',
          onBlur: handleBlur,
          error: this.getErrorMessage('address1'),
        },
      },
      {
        key: 'address2',
        inputComponentData: {
          onChange: handleChange,
          value: values.address2,
          name: 'address2',
          label: 'Apt Number/Unit/Etc.',
          onBlur: handleBlur,
          error: this.getErrorMessage('address2'),
        },
      },
      {
        key: 'zip',
        inputComponentData: {
          onChange: this.handleZipCodeChange,
          value: values.zip,
          name: 'zip',
          label: 'Zip code',
          onBlur: handleBlur,
          error: this.getErrorMessage('zip'),
          isFormatting: true,
          format: inputFormats.zip,
        },
      },
      {
        key: 'city',
        inputComponentData: {
          onChange: handleChange,
          value: values.city,
          name: 'city',
          label: 'City',
          onBlur: handleBlur,
          error: this.getErrorMessage('city'),
        },
      },
      {
        key: 'state',
        selectComponentData: {
          options: stateOptions,
          onChange: handleChange,
          value: values.state,
          name: 'state',
          label: 'State',
          onBlur: handleBlur,
          error: !!this.getErrorMessage('state'),
          helperText: this.getErrorMessage('state'),
        },
      },
      {
        key: 'officePhone',
        inputComponentData: {
          onChange: handleChange,
          value: values.officePhone,
          name: 'officePhone',
          label: 'Office phone',
          onBlur: handleBlur,
          error: this.getErrorMessage('officePhone'),
          isFormatting: true,
          format: inputFormats.phone,
        },
      },
      {
        key: 'homePhone',
        inputComponentData: {
          onChange: handleChange,
          value: values.homePhone,
          name: 'homePhone',
          label: 'Home phone',
          onBlur: handleBlur,
          error: this.getErrorMessage('homePhone'),
          isFormatting: true,
          format: inputFormats.phone,
        },
      },
      {
        key: 'cellPhone',
        inputComponentData: {
          onChange: handleChange,
          value: values.cellPhone,
          name: 'cellPhone',
          label: 'Cell phone',
          onBlur: handleBlur,
          error: this.getErrorMessage('cellPhone'),
          isFormatting: true,
          format: inputFormats.phone,
        },
      },
    ];
  };

  renderAlert = () => {
    return (
      <SimpleAlert
        className={styles.privacyAlert}
        type="info"
        title="Why do we collect this information?"
      >
        <Typography className={styles.alert}>
          {PROFILE_SETTINGS_ALERT}{' '}
          <Link
            className={styles.privacyLink}
            component="span"
            underline="always"
            onClick={() => this.setState({ privacyModalOpen: true })}
          >
            privacy notice
          </Link>
        </Typography>
      </SimpleAlert>
    );
  };

  renderStepperButtons = () => {
    return (
      <StepperButtons
        handleNext={() => {
          this.props.handleSubmit();
          this.props.setFieldTouched('birthDate', true);
        }}
        hideBackBtn
        disableBack
      />
    );
  };

  render() {
    return (
      <>
        <ContentTableBox
          title="Employee Profile"
          index={this.props.index}
          count={this.props.count}
          isBox
        >
          <Box className={styles.fields}>
            <FillFieldsList fields={this.getProfileFields()} />
          </Box>
        </ContentTableBox>

        {this.renderAlert()}
        {this.renderStepperButtons()}
        <PrivacyNotice
          isOpen={this.state.privacyModalOpen}
          handleClose={() => this.setState({ privacyModalOpen: false })}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<{}, ProfileSettingsActions>(
    {
      addProfileData: loadUserProfile.success,
      loadUserProfile: loadUserProfile.trigger,
      editUserData: UserActions.editUserData,
    },
    dispatch
  ),
});

const mapStateToProps = (store: AppStoreInterface) => ({
  genderOptions: store.options.gender,
  smokerOptions: store.options.smoker,
  maritalStatusOptions: store.options.maritalStatus,
  stateOptions: store.options.state,
  userProfile: store.userRegistration.profile,
});

const mapPropsToValues = (props: AllProps): IFormikValues => {
  const {
    userProfile,
    genderOptions,
    smokerOptions,
    maritalStatusOptions,
    stateOptions,
  } = props;

  const initialValues = getInitialValues(
    genderOptions,
    smokerOptions,
    maritalStatusOptions,
    stateOptions,
    userProfile
  );
  return initialValues;
};

const handleSubmit = async (
  values: IFormikValues,
  bag: FormikBag<AllProps, IFormikValues>
) => {
  const { firstName, lastName, middleName } = values;
  const {
    setIsLoading,
    setError,
    moveForward,
    actions,
    userProfile,
  } = bag.props;

  const {
    cellPhoneIndex,
    homePhoneIndex,
    officePhoneIndex,
  } = userProfile as UserProfileInterface;

  try {
    await setIsLoading(true);
    await userDomain.updateEmployee(
      fromProfileInterfaceToUpdateUserProfilePayload({
        ...values,
        cellPhoneIndex,
        homePhoneIndex,
        officePhoneIndex,
      })
    );
    await moveForward();
    actions.addProfileData({
      ...values,
      ssn: ssnToMaskedSsn(values.ssn),
      cellPhoneIndex,
      homePhoneIndex,
      officePhoneIndex,
    });
    actions.editUserData({
      firstName,
      lastName,
      middleName,
      registrationStep: ExternalRegistrationStep.Profile.toString(),
    });
  } catch (error) {
    console.error(error);
    setError('Error, when trying to create profile');
  } finally {
    setIsLoading(false);
  }
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Field is required'),
  middleName: yup.string().optional(),
  lastName: yup.string().required('Field is required'),
  otherNames: yup.string().optional(),
  birthDate: validationHelper.date(),
  gender: yup.string().required('Field is required'),
  smoker: yup.string().required('Field is required'),
  maritalStatusId: yup.string().required('Field is required'),
  ssn: validationHelper.ssn.required('Field is required'),
  address1: yup.string().required('Field is required'),
  address2: yup.string().optional(),
  zip: validationHelper.zip.required('Field is required'),
  city: yup.string().required('Field is required'),
  state: yup.string().required('Field is required'),
  officePhone: validationHelper.phone.optional(),
  homePhone: validationHelper.phone.optional(),
  cellPhone: validationHelper.phone.optional(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik<AllProps, IFormikValues>({
    enableReinitialize: true,
    mapPropsToValues,
    validationSchema,
    handleSubmit,
  })(ProfileSettings)
);
