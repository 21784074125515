import React from 'react';
import { Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import SectionTitle from 'components/SectionTitle';

import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import { ORIGIN_URL_WITH_PREFIX } from 'constants/app';
import { ACCESS_TOKEN } from 'constants/storeKeys';
import storage from 'helpers/storage';
import { IReport } from 'types/reports';

import styles from './styles.module.scss';

export interface IEditReportProps {
  report?: IReport;
  onBack?: () => void | Promise<void>;
  onUpdate: (reportId: number | string) => void | Promise<void>;
}

const EditReport: React.FC<IEditReportProps> = ({
  report,
  onBack,
  onUpdate,
}) => {
  const frameListener = React.useCallback(
    async (event: MessageEvent) => {
      if (event.data.shouldUpdate) await onUpdate(event.data.reportId);
    },
    [onUpdate]
  );

  React.useEffect(() => {
    window.addEventListener('message', frameListener);
    return () => window.removeEventListener('message', frameListener);
  }, [frameListener]);

  if (!report) return null;

  const duplicate = report.editable === '0' ? '1' : '0';
  return (
    <Box className={styles.editReportContainer}>
      <SectionTitle
        button={
          onBack && (
            <SubmitButton
              className={styles.backBtn}
              label="Back"
              variant="text"
              onClick={onBack}
              icon={<ArrowBackIcon />}
              iconPosition="left"
            />
          )
        }
        withoutBottomPadding
      />
      <Box className={styles.mainContainer}>
        <ContentContainer title={report.title}>
          <iframe
            title={report.title}
            className={styles.editReportFrame}
            src={
              `${ORIGIN_URL_WITH_PREFIX}/client_census_report_edit.php` +
              `?jwt=${storage.get(ACCESS_TOKEN)}&report_id=${
                report.id
              }&duplicate=${duplicate}`
            }
          />
        </ContentContainer>
      </Box>
    </Box>
  );
};

export default EditReport;
