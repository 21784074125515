/* eslint-disable import/no-duplicates */
import React from 'react';
import setClassNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { FormLabel, withStyles } from '@material-ui/core';
import IBasicOption from 'models/IBasicOption';
import * as inputFormats from 'constants/inputFormats';
import MuiCheckbox from '@material-ui/core/Checkbox';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';
import Input, { InputPropsInterface } from '../Forms/Input/InputComponent';
import PasswordInput from '../Forms/Input/InputPasswordComponent';
import Selector, {
  SelectorPropsInterface,
} from '../Forms/Selector/SelectorComponent';
import DatePicker, {
  IDatePickerProps,
} from '../Forms/Datepicker/DatepickerComponent';
import RadioGroup, {
  RadioButtonsGroupPropsInterface,
} from '../Forms/RadioGroup/RadioGroupComponent';
import Select, { SelectPropsInterface } from '../Forms/Select';
import FormikDatePicker, {
  IFormikDatePickerProps,
} from '../Forms/Formik/Datepicker';

import styles from './styles.module.scss';

export interface FieldsInterface {
  /**
   * You can provide label and data for rendering component or you can provide any ReactNode instead
   */
  inputComponentData?: InputPropsInterface & {
    isPassword?: boolean;
    isFormatting?: boolean;
    format?: string;
    hidden?: boolean;
  };
  ssnInputComponentData?: InputPropsInterface & {
    editable: boolean;
    initialValue?: string;
    toggleEditable: () => void;
  };
  selectorComponentData?: SelectorPropsInterface;
  selectComponentData?: SelectPropsInterface & {
    options: IBasicOption<any>[];
  };
  datePickerComponentData?: IDatePickerProps;
  formikDatePickerComponentData?: IFormikDatePickerProps;
  radioGroupComponentData?: RadioButtonsGroupPropsInterface;
  checkboxComponentData?: CheckboxProps & { label?: string };
  component?: React.ReactNode | string;
  key: string | number;
}

export interface FillFieldsListPropsInterface {
  fields: FieldsInterface[];
  title?: string;
  className?: string;
}

const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(MuiCheckbox);

const getFieldComponent = (field: FieldsInterface) => {
  if (field.inputComponentData) {
    if (field.inputComponentData.hidden) {
      return null;
    }

    const inputClassNames = setClassNames(
      field.inputComponentData.className,
      styles.fieldInput
    );
    return field.inputComponentData.isPassword ? (
      <PasswordInput
        {...field.inputComponentData}
        className={inputClassNames}
      />
    ) : field.inputComponentData.isFormatting ? (
      <NumberFormat
        customInput={Input}
        {...field.inputComponentData}
        className={inputClassNames}
      />
    ) : (
      <Input {...field.inputComponentData} className={inputClassNames} />
    );
  }

  if (field.ssnInputComponentData) {
    const {
      toggleEditable,
      editable,
      initialValue,
      className,
      ...rest
    } = field.ssnInputComponentData;

    const inputClassNames = setClassNames(className, styles.fieldInput);

    return editable || !initialValue ? (
      <>
        <Box className={styles.toggleSsnBtnContainer}>
          <FormLabel component="legend" className={styles.label}>
            {rest.label}
          </FormLabel>
          {editable && (
            <Button
              onClick={toggleEditable}
              disableRipple
              variant="text"
              className={styles.editBtn}
            >
              Decline
            </Button>
          )}
        </Box>
        <NumberFormat
          ssn
          customInput={Input}
          {...rest}
          format={inputFormats.ssn}
          autoFocus={editable}
          className={inputClassNames}
        />
      </>
    ) : (
      <>
        <Box className={styles.toggleSsnBtnContainer}>
          <FormLabel component="legend" className={styles.label}>
            {rest.label}
          </FormLabel>
          <Button
            onClick={toggleEditable}
            disableRipple
            variant="text"
            className={styles.editBtn}
          >
            Edit
          </Button>
        </Box>
        <Input ssn {...rest} readOnly className={inputClassNames} />
      </>
    );
  }

  if (field.selectorComponentData) {
    const selectorClassNames = setClassNames(
      field.selectorComponentData.className,
      styles.fieldSelector
    );
    return (
      <Selector
        {...field.selectorComponentData}
        className={selectorClassNames}
      />
    );
  }

  if (field.selectComponentData) {
    const { options, ...rest } = field.selectComponentData;
    return (
      <Select {...rest}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={styles.option}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  }

  if (field.formikDatePickerComponentData) {
    return <FormikDatePicker {...field.formikDatePickerComponentData} />;
  }

  if (field.datePickerComponentData) {
    const selectorClassNames = setClassNames(
      field.datePickerComponentData.className,
      styles.fieldSelector
    );
    return (
      <DatePicker
        {...field.datePickerComponentData}
        className={selectorClassNames}
      />
    );
  }

  if (field.radioGroupComponentData) {
    const groupClassNames = setClassNames(
      field.radioGroupComponentData.className,
      styles.fieldRadioGroup
    );
    return (
      <RadioGroup
        {...field.radioGroupComponentData}
        className={groupClassNames}
      />
    );
  }

  if (field.checkboxComponentData) {
    const { label, ...rest } = field.checkboxComponentData;
    return (
      <FormControlLabel
        control={
          <CustomCheckbox
            icon={
              <SvgIcon
                component={offIcon}
                width="16"
                height="17"
                viewBox="0 0 16 17"
              />
            }
            checkedIcon={
              <SvgIcon
                component={onIcon}
                width="16"
                height="17"
                viewBox="0 0 16 17"
              />
            }
            {...rest}
          />
        }
        label={label}
      />
    );
  }

  return field.component;
};

const renderFiled = (field: FieldsInterface) => {
  if (!field || typeof field !== 'object') {
    return null;
  }

  return (
    <div className={styles.field} key={field.key}>
      {getFieldComponent(field)}
    </div>
  );
};

const renderFields = (fields: FieldsInterface[]): React.ReactNode => {
  if (!Array.isArray(fields) || !fields.length) {
    return null;
  }

  return fields.map(renderFiled);
};

const FillFieldsList: React.FC<FillFieldsListPropsInterface> = (props) => {
  const { title, fields, className } = props;

  const classNames = setClassNames(styles.fillFieldsList, {
    [className || '']: Boolean(className),
  });
  return (
    <div className={classNames}>
      {title && <div className={styles.fillFieldsTitle}>{title}</div>}
      <div className={styles.fillFieldsCntrl}>{renderFields(fields)}</div>
    </div>
  );
};

export default FillFieldsList;
