import React from 'react';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';

export interface PopperPropsInterface {
  popoverMessage: string | React.ReactNode;
  placement: PopperPlacementType;
}

const SimplePopper: React.FC<PopperPropsInterface> = ({
  children,
  popoverMessage,
  placement,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="popover-wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={placement}
      >
        {popoverMessage}
      </Popper>
    </div>
  );
};

export default SimplePopper;
