import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';

import Feedbacker from 'components/Feedbacker';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import ModalWindow from 'components/Modal/ModalWindowComponent';
import FormikInput from 'components/Forms/Formik/Input';
import PasswordInput from 'components/Forms/Formik/PasswordInput';
import FormikDatePicker from 'components/Forms/Formik/Datepicker';
import IBasicOption from 'models/IBasicOption';
import { IEditEmployeePayload, IEmployee } from 'types/employee';
import * as validationHelper from 'helpers/validation';
import * as feedbackMessages from 'constants/feedbackMessages';
import userDomain from 'api/domains/user';
import AuthMethod from 'enums/authMethod';
import { ZIP_REG_EXP } from 'constants/regExps';

import * as inputFormats from 'constants/inputFormats';
import { ReactComponent as openIcon } from 'assets/sidebar/open.svg';
import { ReactComponent as closeIcon } from 'assets/sidebar/close.svg';
import { Button, SvgIcon } from '@material-ui/core';
import FillFieldsList, {
  FieldsInterface,
} from 'components/FillFieldsList/FillFieldsListComponent';
import styles from './styles.module.scss';
import { getErrorMessage } from './helpers';

interface IProps {
  isOpen: boolean;
  employee: IEmployee;
  handleClose: () => void;
  handleEditEmployee: (payload: IEditEmployeePayload) => Promise<void>;
  employmentType: IBasicOption<number>[];
  employmentStatus: IBasicOption<number>[];
  officeLocation: IBasicOption<number>[];
  accessGroup: IBasicOption<number>[];
  gender: IBasicOption<number>[];
  maritalStatus: IBasicOption<number>[];
  authenticationMethod: AuthMethod | null;
}

const EditEmployeeModal: React.FC<IProps> = (props) => {
  const {
    isOpen,
    employmentType,
    employmentStatus,
    officeLocation,
    accessGroup,
    employee,
    authenticationMethod,
    handleClose,
    handleEditEmployee,
    gender,
    maritalStatus,
  } = props;

  const [feedback, setFeedback] = useState<string>();
  const [showNotRequired, setShowNotRequired] = useState<boolean>(false);
  const [ssnEditable, setSsnEditable] = useState<boolean>(false);
  // eslint-disable-next-line
  const [initialSsn, setInitialSsn] = useState<string>(employee.ssn ?? '');
  const checkSelectValue = (value: string | number) => {
    if (value !== 0 && value !== '0' && value !== '') {
      return value;
    } else return '';
  };

  const initialValues: IEditEmployeePayload = {
    username: employee.username || '',
    password: employee.password || '',
    firstName: employee.firstName || '',
    lastName: employee.lastName || '',
    email: employee.email || '',
    groupId: checkSelectValue(employee.groupId),
    employmentId: checkSelectValue(employee.employmentId),
    officeId: checkSelectValue(employee.officeId),
    employmentType: checkSelectValue(employee.employmentType),
    title: employee.title || '',
    income: employee.income || '',
    workHours: employee.workHours || '',
    hireDate:
      typeof employee.hireDate === 'string'
        ? new Date(employee.hireDate)
        : null,
    birthDate:
      typeof employee.birthDate === 'string'
        ? new Date(employee.birthDate)
        : null,
    gender:
      (employee && gender.find((g) => +g.value === +employee.gender))?.value ||
      '',
    maritalStatusId:
      (
        employee &&
        maritalStatus.find(
          (status) => +status.value === +employee.maritalStatusId
        )
      )?.value || 1,
    ssn: employee?.ssn || '',
    address1: employee?.address1 || '',
    address2: employee?.address2 || '',
    zip: employee?.zip || '',
    city: employee?.city || '',
    state: employee?.state || '',
    officePhone: employee?.officePhone || '',
    homePhone: employee?.homePhone || '',
    cellPhone: employee.cellPhone || '',
  };
  const checkUsernameUniqueness = async (value?: string) => {
    if (!value) {
      return true;
    }
    try {
      const { data } = await userDomain.validateUsername(value, employee.id);
      return data.success;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(5, 'Password should contain at least 5 characters')
      .max(20, 'Password should contain maximum of 20 characters')
      .nullable(),
    firstName: yup.string().required('Field is required'),
    lastName: yup.string().required('Field is required'),
    email:
      authenticationMethod === AuthMethod.Email
        ? validationHelper.email
            .required('Field is required')
            .test(
              'Unique Email',
              'Email already in use',
              checkUsernameUniqueness
            )
        : validationHelper.email,
    username:
      authenticationMethod === AuthMethod.Username
        ? yup
            .string()
            .test(
              'Unique Username',
              'Username already in use',
              checkUsernameUniqueness
            )
            .nullable()
        : yup.string().nullable(),
    groupId: yup.string().nullable(),
    employmentId: yup.string().required('Field is required'),
    title: yup.string(),
    hireDate: yup.date().required('Field is required').nullable(),
    income: yup
      .number()
      .transform((cv) => (isNaN(cv) ? undefined : cv))
      .positive('Cannot be less than 0')
      .nullable(),
    workHours: yup.number().min(0, 'Cannot be less than 0'),
    gender: yup.string(),
    smoker: yup.string(),
    maritalStatusId: yup.string(),
    ssn: validationHelper.ssn,
    address1: yup.string(),
    address2: yup.string().optional(),
    zip: validationHelper.zip,
    city: yup.string(),
    state: yup.string(),
    officePhone: validationHelper.phone.optional(),
    homePhone: validationHelper.phone.optional(),
    cellPhone: validationHelper.phone.optional(),
  });

  const handleSubmit = async (values: IEditEmployeePayload) => {
    try {
      await handleEditEmployee(values);
      setFeedback(feedbackMessages.EMPLOYEE_UPDATING_SUCCESS);
      handleClose();
    } catch (err) {
      setFeedback(feedbackMessages.EMPLOYEE_UPDATING_ERROR);
      handleClose();
    }
  };

  const toggleSsnEditable = (formik: FormikProps<IEditEmployeePayload>) => {
    const { setFieldValue } = formik;
    setSsnEditable(!ssnEditable);
    setFieldValue('ssn', !ssnEditable ? '' : initialSsn);
  };

  const handleZipCodeChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<IEditEmployeePayload>
  ) => {
    const { handleChange, setFieldValue } = formik;
    handleChange(e);

    const zip = e.target.value;
    const zipValid = zip.match(ZIP_REG_EXP);

    if (zipValid) {
      try {
        const { data } = await userDomain.zipCodeLookup(zip);
        setFieldValue('city', data.city || '');
        setFieldValue('state', data.state || '');
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getNotRequiredeFields = (
    formik: FormikProps<IEditEmployeePayload>
  ): FieldsInterface[] => {
    const { values, handleBlur, handleChange } = formik;
    return [
      {
        key: 'gender',
        selectComponentData: {
          options: gender,
          value: values.gender,
          name: 'gender',
          onChange: handleChange,
          label: 'Gender',
          onBlur: handleBlur,
          error: !!getErrorMessage('gender', formik),
          helperText: getErrorMessage('gender', formik),
        },
      },
      {
        key: 'maritalStatusId',
        selectComponentData: {
          options: maritalStatus,
          value: values.maritalStatusId,
          onChange: handleChange,
          label: 'Marital status',
          onBlur: handleBlur,
          name: 'maritalStatusId',
          error: !!getErrorMessage('maritalStatusId', formik),
          helperText: getErrorMessage('maritalStatusId', formik),
        },
      },
      {
        key: 'ssn',
        ssnInputComponentData: {
          onChange: handleChange,
          value: values.ssn ?? '',
          name: 'ssn',
          label: 'Social Security Number',
          onBlur: handleBlur,
          error: getErrorMessage('ssn', formik),
          editable: ssnEditable,
          toggleEditable: () => toggleSsnEditable(formik),
          initialValue: initialSsn,
        },
      },
      {
        key: 'address1',
        inputComponentData: {
          onChange: handleChange,
          value: values.address1,
          name: 'address1',
          label: 'Home address',
          onBlur: handleBlur,
          error: getErrorMessage('address1', formik),
        },
      },
      {
        key: 'address2',
        inputComponentData: {
          onChange: handleChange,
          value: values.address2,
          name: 'address2',
          label: 'Apt Number/Unit/Etc.',
          onBlur: handleBlur,
          error: getErrorMessage('address2', formik),
        },
      },
      {
        key: 'zip',
        inputComponentData: {
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleZipCodeChange(e, formik),
          value: values.zip,
          name: 'zip',
          label: 'Zip code',
          onBlur: handleBlur,
          error: getErrorMessage('zip', formik),
          isFormatting: true,
          format: inputFormats.zip,
        },
      },
      {
        key: 'city',
        inputComponentData: {
          onChange: handleChange,
          value: values.city,
          name: 'city',
          label: 'City',
          onBlur: handleBlur,
          error: getErrorMessage('city', formik),
        },
      },
      {
        key: 'state',
        inputComponentData: {
          onChange: handleChange,
          value: values.state,
          name: 'state',
          label: 'State',
          onBlur: handleBlur,
          error: getErrorMessage('state', formik),
        },
      },
      {
        key: 'officePhone',
        inputComponentData: {
          onChange: handleChange,
          value: values.officePhone,
          name: 'officePhone',
          label: 'Office phone',
          onBlur: handleBlur,
          error: getErrorMessage('officePhone', formik),
          isFormatting: true,
          format: inputFormats.phone,
        },
      },
      {
        key: 'homePhone',
        inputComponentData: {
          onChange: handleChange,
          value: values.homePhone,
          name: 'homePhone',
          label: 'Home phone',
          onBlur: handleBlur,
          error: getErrorMessage('homePhone', formik),
          isFormatting: true,
          format: inputFormats.phone,
        },
      },
      {
        key: 'cellPhone',
        inputComponentData: {
          onChange: handleChange,
          value: values.cellPhone,
          name: 'cellPhone',
          label: 'Cell phone',
          onBlur: handleBlur,
          error: getErrorMessage('cellPhone', formik),
          isFormatting: true,
          format: inputFormats.phone,
        },
      },
    ];
  };

  return (
    <>
      <ModalWindow
        isOpen={isOpen}
        handleClose={handleClose}
        dividers
        title="Edit Employee"
      >
        <Box className={styles.modalForm}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {(formik: FormikProps<IEditEmployeePayload>) => (
              <Form>
                <Box className={styles.addEmployeeModalSection}>
                  <Box className={styles.header}>
                    <Typography className={styles.subtitle}>
                      Personal Information
                    </Typography>
                    <Divider />
                  </Box>
                  <Field
                    component={FormikInput}
                    label="First Name"
                    name="firstName"
                    fullWidth
                  />
                  <Field
                    component={FormikInput}
                    label="Last Name"
                    name="lastName"
                    fullWidth
                  />
                  <Field
                    component={FormikInput}
                    label="Email"
                    name="email"
                    fullWidth
                  />
                </Box>

                <Box className={styles.addEmployeeModalSection}>
                  <Box className={styles.header}>
                    <Typography className={styles.subtitle}>
                      User Access
                    </Typography>
                    <Divider />
                  </Box>
                  <Field
                    component={FormikInput}
                    name="groupId"
                    label="Access Group"
                    variant="outlined"
                    select
                    fullWidth
                  >
                    <MenuItem
                      value=""
                      disabled
                      className={styles.optionPlaceholder}
                    >
                      Select
                    </MenuItem>
                    {accessGroup.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        className={styles.option}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={FormikInput}
                    name="username"
                    label="Username"
                    variant="outlined"
                    disabled={authenticationMethod === 'email'}
                    fullWidth
                  />
                  <Field
                    component={PasswordInput}
                    name="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                  />
                </Box>

                <Box className={styles.addEmployeeModalSection}>
                  <Box className={styles.header}>
                    <Typography className={styles.subtitle}>
                      Employment Information
                    </Typography>
                    <Divider />
                  </Box>
                  <Field
                    component={FormikInput}
                    name="employmentId"
                    label="Employment"
                    variant="outlined"
                    select
                    fullWidth
                  >
                    <MenuItem
                      value=""
                      disabled
                      className={styles.optionPlaceholder}
                    >
                      Select
                    </MenuItem>
                    {employmentStatus.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        className={styles.option}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <FormikDatePicker
                    value={formik.values.hireDate}
                    label="Hire date"
                    variant="dialog"
                    name="hireDate"
                    convertToDate
                    error={
                      formik.touched.hireDate
                        ? !!getErrorMessage<IEditEmployeePayload>(
                            'hireDate',
                            formik
                          )
                        : false
                    }
                    helperText={
                      formik.touched.hireDate
                        ? getErrorMessage<IEditEmployeePayload>(
                            'hireDate',
                            formik
                          )
                        : ''
                    }
                  />
                  <Field
                    component={FormikInput}
                    name="title"
                    label="Job Title"
                    variant="outlined"
                    fullWidth
                  />
                  <Field
                    component={FormikInput}
                    name="officeId"
                    label="Office Location"
                    variant="outlined"
                    select
                    fullWidth
                  >
                    <MenuItem
                      value=""
                      disabled
                      className={styles.optionPlaceholder}
                    >
                      Select
                    </MenuItem>
                    {officeLocation.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        className={styles.option}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={FormikInput}
                    name="employmentType"
                    label="Employment Type"
                    variant="outlined"
                    select
                    fullWidth
                  >
                    <MenuItem
                      value=""
                      disabled
                      className={styles.optionPlaceholder}
                    >
                      Select
                    </MenuItem>
                    {employmentType.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        className={styles.option}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={FormikInput}
                    name="income"
                    label={
                      +formik.values.employmentType === 2
                        ? 'Wage/hour'
                        : 'Salary'
                    }
                    variant="outlined"
                    type="number"
                    fullWidth
                  />
                  <Field
                    component={FormikInput}
                    name="workHours"
                    label="Work hours"
                    variant="outlined"
                    type="number"
                    fullWidth
                  />
                </Box>
                <Box className={styles.addEmployeeModalSection}>
                  <Box className={styles.header}>
                    <Typography className={styles.subtitleNotRequired}>
                      Additional Information (optional)
                      <Button
                        onClick={() => setShowNotRequired(!showNotRequired)}
                      >
                        <Typography
                          className={styles.terminatedEmployees}
                          variant="subtitle1"
                        >
                          {showNotRequired ? (
                            <SvgIcon
                              className={styles.helpIcons}
                              component={closeIcon}
                              width="15"
                              height="8"
                              viewBox="0 0 11 7"
                            />
                          ) : (
                            <SvgIcon
                              className={styles.helpIcons}
                              component={openIcon}
                              width="15"
                              height="8"
                              viewBox="0 0 11 7"
                            />
                          )}
                        </Typography>
                      </Button>
                    </Typography>
                    <Divider />
                  </Box>
                  {showNotRequired && (
                    <>
                      <FormikDatePicker
                        value={formik.values.birthDate}
                        label="Birth date"
                        variant="dialog"
                        name="birthDate"
                        convertToDate
                      />
                      <FillFieldsList fields={getNotRequiredeFields(formik)} />
                    </>
                  )}
                </Box>

                <SubmitButton
                  disabled={formik.isSubmitting}
                  className={styles.submitBtn}
                  label="Submit"
                />
              </Form>
            )}
          </Formik>
        </Box>
      </ModalWindow>
      <Feedbacker
        open={!!feedback}
        autoHideDuration={5000}
        displayInTheMiddle={false}
        severity={
          feedback === feedbackMessages.EMPLOYEE_UPDATING_ERROR
            ? 'error'
            : 'success'
        }
        feedbackMessage={feedback || ''}
        clearFeedback={() => setFeedback(undefined)}
      />
    </>
  );
};

export default EditEmployeeModal;
