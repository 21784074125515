import { FieldPayload } from 'containers/Onboarding/steps/CustomDocuments';
import {
  EmploymentEligibilityI,
  I9DocksI,
  DomainTaxWithholdingI,
  PayrollI,
  PartialPayrollI,
  BankDataI,
  OnboardingFile,
  IEEOPayload,
  IExternalEEOPayload,
} from 'types/onboarding';
import api from '../adapter';

export class OnboardingDomain {
  getOnboarding() {
    return api.makeRequest('/onboarding/custom', 'GET');
  }

  saveCustomFieldsData(body: {
    customFields: FieldPayload[];
    fullName: string;
    fileId: string | null;
  }) {
    return api.makeRequest('/onboarding/custom', 'PUT', body);
  }

  getEmploymentEligibility() {
    return api.makeRequest<EmploymentEligibilityI>('/onboarding/i9', 'GET');
  }

  saveEmploymentEligibility(body: EmploymentEligibilityI) {
    return api.makeRequest('/onboarding/i9', 'PUT', body);
  }

  getI9Docks() {
    return api.makeRequest<I9DocksI[]>('/onboarding/i9-docs', 'GET');
  }

  getTaxWithholding() {
    return api.makeRequest<DomainTaxWithholdingI>('/onboarding/w4', 'GET');
  }

  saveTaxWithholding(body: DomainTaxWithholdingI) {
    return api.makeRequest('/onboarding/w4', 'PUT', body);
  }

  saveOnboardingStep(step: string) {
    return api.makeRequest('/onboarding', 'PUT', { step });
  }

  getPayroll() {
    return api.makeRequest<PayrollI[]>('/payroll', 'GET');
  }

  updatePayrollById(id: string, body: PayrollI) {
    return api.makeRequest(`/payroll/${id}`, 'PUT', body);
  }

  saveAllPayrolls(body: PartialPayrollI[]) {
    return api.makeRequest('/payroll', 'PUT', body);
  }

  createPayroll(body: PartialPayrollI) {
    return api.makeRequest<PayrollI[] | PayrollI>('/payroll/', 'POST', body);
  }

  deletePayrollById(id: string) {
    return api.makeRequest(`/payroll/${id}`, 'DELETE');
  }

  fetchBankNameByNumber(number: string) {
    return api.makeRequest<BankDataI>(`/bank-lookup/${number}`, 'GET');
  }

  signature(body: { signature: string }) {
    return api.makeRequest<BankDataI>('/onboarding/signature', 'POST', body);
  }

  getGeneratedFiles() {
    return api.makeRequest<OnboardingFile[]>('/onboarding/files', 'GET');
  }

  getEEO() {
    return api.makeRequest<IExternalEEOPayload>('/onboarding/eeo', 'GET');
  }

  updateEEO(payload: IEEOPayload) {
    return api.makeRequest<void>('/onboarding/eeo', 'PUT', payload);
  }
}

export default new OnboardingDomain();
