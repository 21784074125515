import React from 'react';
import { Typography } from '@material-ui/core';

import { IShortActionPlanInfo, IAction } from 'types/actions';
import EnrollmentStatus from 'enums/enrollmentStatus';

import styles from './styles.module.scss';

export const getActionStatus = (
  action: IShortActionPlanInfo | IAction
): React.ReactNode => {
  if (+action.statusId > +EnrollmentStatus.NotSelected) {
    return (
      <Typography className={styles.statusText}>{action.status}</Typography>
    );
  }
  if (+action.statusId < +EnrollmentStatus.NotSelected) {
    return (
      <Typography className={styles.statusText}>{action.status}</Typography>
    );
  }
  return null;
};
