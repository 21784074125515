import React, { useMemo, CSSProperties, ChangeEvent } from 'react';
import { Typography, Box } from '@material-ui/core';
import { DropzoneRootProps } from 'react-dropzone';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import ModalWindow from 'components/Modal/ModalWindowComponent';
import Input from 'components/Forms/Input/InputComponent';

import styles from './styles.module.scss';

interface IProps {
  changeFileName: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
  getInputProps: (props?: DropzoneRootProps) => DropzoneRootProps;
  saveSelectedFiles: () => void;
  handleCloseModal: () => void;
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  isOpen: boolean;
  isFileSubmitting: boolean;
  selectedFiles: File[];
}

const AddFilesModal: React.FC<IProps> = (props) => {
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    isOpen,
    isFileSubmitting,
    selectedFiles,
    saveSelectedFiles,
    handleCloseModal,
    getRootProps,
    getInputProps,
    changeFileName,
  } = props;

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const isSomeFileHasEmptyName = selectedFiles.some((file) => !file.name);
  const saveBtnDisabled =
    selectedFiles.length === 0 || isSomeFileHasEmptyName || isFileSubmitting;

  const modalActions = (
    <Box className={styles.btnBox}>
      <SubmitButton
        variant="text"
        color="primary"
        label="Cancel"
        className={styles.cancelBtn}
        onClick={handleCloseModal}
      />
      <SubmitButton
        variant="contained"
        color="primary"
        label="Save"
        disabled={saveBtnDisabled}
        className={styles.submitBtn}
        onClick={saveSelectedFiles}
      />
    </Box>
  );

  return (
    <ModalWindow
      isOpen={isOpen}
      dividers
      handleClose={handleCloseModal}
      title="Add new file"
    >
      <Box>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <Typography className={styles.placeholderFile}>
            Drag 'n' drop a file here, or click to select a file
          </Typography>
        </div>
        <aside>
          {selectedFiles.map((file, i) => (
            <Box mt="1em" key={i}>
              <Input
                value={file.name}
                onChange={(e) => changeFileName(e, i)}
                label="Title"
              />
            </Box>
          ))}
        </aside>
        {modalActions}
      </Box>
    </ModalWindow>
  );
};

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '80px 50px',
  marginTop: '30px',
  marginBottom: '20px',
  color: '#bdbdbd',
  outline: 'none',
  border: '1px dashed #E1E2E7',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default AddFilesModal;
