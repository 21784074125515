import { PartialUserProfile, UserAddress } from 'types/user';

export const fromUserProfileToUserAddress = (
  profile: PartialUserProfile
): UserAddress => ({
  address1: profile.address1,
  address2: profile.address2,
  city: profile.city,
  zip: profile.zip,
  state: profile.state,
});
