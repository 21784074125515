import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { withFormik, FormikBag, FormikProps } from 'formik';
import * as yup from 'yup';

import FillFields, {
  FieldsInterface,
} from 'components/FillFieldsList/FillFieldsListComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';

import { AppStoreInterface } from 'store/app';
import {
  DependentInterface,
  DependentInterfaceWithoutId,
} from 'types/dependents';
import IBasicOption from 'models/IBasicOption';
import RelationshipType from 'enums/relationshipType';
import * as validationHelper from 'helpers/validation';
import { Box } from '@material-ui/core';
import getInitialValues from './getInitialValues';
import styles from './styles.module.scss';

interface IFormikValues extends DependentInterfaceWithoutId {
  userLastName?: string;
  requireSsn?: boolean | null;
}

export interface AddDependentsModalPropsInterface {
  ssnEditable: boolean;
  relationshipTypeId?: string | number;
  dependent?: DependentInterface;
  userLastName?: string;
  requireSsn: string | null;
  onSubmit: (
    dependent: DependentInterface | DependentInterfaceWithoutId
  ) => void | Promise<void>;
  toggleSsnEditableWithCb: (cb: (ssnEditable: boolean) => void) => void;
  reason: string;
  setEditedRelationId: (id: string) => void;
  effectiveDate: string;
}

export interface AppDependentsModalPropsFromState {
  relationshipOptions: IBasicOption<number>[];
  genderOptions: IBasicOption<number>[];
  smokerOptions: IBasicOption<number>[];
  childHaveAnotherLastNameReasons: IBasicOption<number>[];
  hideDocumentWarnings: boolean;
}

export type AllProps = AppDependentsModalPropsFromState &
  AddDependentsModalPropsInterface;

interface AddDependentsModalStateInterface {
  initialSsn?: string;
  showDifferentLastNamesAlert?: boolean;
}

type WithFormikProps = AllProps & FormikProps<IFormikValues>;

const lessThanOneYear = (birthDate: Date | null) => {
  const momentBirthDate = moment(birthDate);
  const diff = -1 * momentBirthDate.diff(new Date(), 'y');
  return momentBirthDate.isValid() && diff < 1;
};

class AddDependentsModal extends React.Component<
  WithFormikProps,
  AddDependentsModalStateInterface
> {
  state: AddDependentsModalStateInterface = { initialSsn: undefined };

  componentDidUpdate(prevProps: WithFormikProps) {
    if (!isEqual(prevProps.dependent, this.props.dependent)) {
      const {
        setValues,
        resetForm,
        dependent,
        userLastName,
        relationshipOptions,
        childHaveAnotherLastNameReasons,
        genderOptions,
        smokerOptions,
        requireSsn,
        reason,
        effectiveDate,
      } = this.props;

      const initialValues = getInitialValues(
        genderOptions,
        smokerOptions,
        relationshipOptions,
        childHaveAnotherLastNameReasons,
        requireSsn,
        reason,
        userLastName,
        dependent,
        effectiveDate
      );

      resetForm();
      setValues(initialValues, false);
      this.setState({ initialSsn: dependent?.ssn });
    }
  }

  toggleSsnEditable = () => {
    const { setFieldValue, toggleSsnEditableWithCb } = this.props;
    const { initialSsn } = this.state;

    toggleSsnEditableWithCb((ssnEditable) => {
      setFieldValue('ssn', ssnEditable ? '' : initialSsn);
    });
  };

  getErrorMessage = (key: keyof IFormikValues): string | undefined => {
    const { errors, touched } = this.props;
    const error = errors[key];
    const isTouched = touched[key];
    return isTouched && error ? (error as string) : '';
  };

  getFields = (): FieldsInterface[] => {
    const {
      relationshipOptions,
      genderOptions,
      smokerOptions,
      values,
      handleBlur,
      handleChange,
      ssnEditable,
      reason,
      setEditedRelationId,
    } = this.props;

    const onChangeRelationId = (e: React.ChangeEvent<any>) => {
      handleChange(e);
      setEditedRelationId(e.target.value);
    };
    const fields: FieldsInterface[] = [
      {
        key: 'relationshipType',
        selectComponentData: {
          label: 'Relation',
          name: 'relationshipTypeId',
          options:
            reason !== '1'
              ? relationshipOptions.filter((option) => option.label !== 'Child')
              : relationshipOptions,
          value: values.relationshipTypeId,
          onChange: onChangeRelationId,
          onBlur: handleBlur,
          error: !!this.getErrorMessage('relationshipTypeId'),
          helperText: this.getErrorMessage('relationshipTypeId'),
          disabled: reason === '1',
        },
      },
      {
        key: 'firstName',
        inputComponentData: {
          onChange: handleChange,
          value: values.firstName,
          name: 'firstName',
          label: 'First name',
          onBlur: handleBlur,
          error: this.getErrorMessage('firstName'),
        },
      },
      {
        key: 'middleName',
        inputComponentData: {
          onChange: handleChange,
          value: values.middleName as string,
          label: 'Middle name',
          name: 'middleName',
          onBlur: handleBlur,
          error: this.getErrorMessage('middleName'),
        },
      },
      {
        key: 'lastName',
        inputComponentData: {
          onChange: handleChange,
          value: values.lastName,
          name: 'lastName',
          label: 'Last name',
          onBlur: (e: React.FocusEvent<any>) => {
            this.setState({ showDifferentLastNamesAlert: true });
            handleBlur(e);
          },
          onFocus: (e: React.FocusEvent<any>) => {
            this.setState({ showDifferentLastNamesAlert: false });
          },
          error: this.getErrorMessage('lastName'),
        },
      },
      {
        key: 'birthDate',
        formikDatePickerComponentData: {
          value: values.birthDate,
          label: 'Date of birth',
          variant: 'inline',
          name: 'birthDate',
          error: !!this.getErrorMessage('birthDate'),
          helperText: this.getErrorMessage('birthDate'),
          convertToDate: true,
          onBlur: handleBlur,
        },
      },
      {
        key: 'gender',
        selectComponentData: {
          options: genderOptions,
          value: values.gender,
          name: 'gender',
          onChange: handleChange,
          label: 'Gender',
          onBlur: handleBlur,
          error: !!this.getErrorMessage('gender'),
          helperText: this.getErrorMessage('gender'),
        },
      },
      {
        key: 'smoker',
        selectComponentData: {
          options: smokerOptions,
          value: values.smoker,
          name: 'smoker',
          onChange: handleChange,
          label: 'Smoker',
          onBlur: handleBlur,
          error: !!this.getErrorMessage('smoker'),
          helperText: this.getErrorMessage('smoker'),
          hidden: reason === '1',
        },
      },
      {
        key: 'ssn',
        ssnInputComponentData: {
          onChange: handleChange,
          value: values.ssn as string,
          name: 'ssn',
          label: 'Social Security Number',
          onBlur: handleBlur,
          error: this.getErrorMessage('ssn'),
          editable: ssnEditable,
          toggleEditable: this.toggleSsnEditable,
          initialValue: this.state.initialSsn,
        },
      },
      {
        key: 'email',
        inputComponentData: {
          label: 'Email Address',
          name: 'email',
          value: values.email as string,
          onChange: handleChange,
          onBlur: handleBlur,
          error: this.getErrorMessage('email'),
          hidden: reason === '1',
        },
      },
    ];

    if (values.relationshipTypeId === RelationshipType.Child) {
      fields.push({
        key: 'disabled',
        checkboxComponentData: {
          value: values.disabled,
          checked: values.disabled,
          name: 'disabled',
          onChange: handleChange,
          label: 'Totaly Disabled',
          onBlur: handleBlur,
        },
      });
    }
    return fields;
  };

  renderReasonSelect = () => {
    const {
      childHaveAnotherLastNameReasons,
      values,
      handleChange,
      handleBlur,
    } = this.props;

    const select: FieldsInterface = {
      key: 'lastNameReasonId',
      selectComponentData: {
        options: childHaveAnotherLastNameReasons,
        value: values.lastNameReasonId,
        name: 'lastNameReasonId',
        onChange: handleChange,
        label: 'Reason',
        onBlur: handleBlur,
        error: !!this.getErrorMessage('lastNameReasonId'),
        helperText: this.getErrorMessage('lastNameReasonId'),
      },
    };

    return <FillFields fields={[select]} />;
  };

  renderActions = () => {
    return (
      <SubmitButton
        className={styles.submitBtn}
        label="Next"
        variant="contained"
        onClick={() => {
          this.props.handleSubmit();
          this.props.setFieldTouched('birthDate', true);
        }}
      />
    );
  };

  renderDifferentLastNamesAlert = () => {
    const { userLastName, values, hideDocumentWarnings } = this.props;

    const isSpouse = values.relationshipTypeId === RelationshipType.Spouse;
    const isChild = values.relationshipTypeId === RelationshipType.Child;
    const isDifferentLastName =
      !!values.lastName && values.lastName !== userLastName;

    if (isSpouse && isDifferentLastName && !hideDocumentWarnings) {
      return (
        <SimpleAlert
          title="Your spouse has a different last name which may require you to upload a Marriage Certificate!"
          type="warning"
        />
      );
    }
    if (isChild && isDifferentLastName) {
      return (
        <SimpleAlert
          title="Please explain why your child's last name is different from your:"
          type="warning"
        >
          {this.renderReasonSelect()}
        </SimpleAlert>
      );
    }

    return null;
  };

  render() {
    const { dependent } = this.props;

    return (
      <Box>
        <FillFields fields={this.getFields()} />
        {dependent
          ? this.renderDifferentLastNamesAlert()
          : this.state.showDifferentLastNamesAlert &&
            this.renderDifferentLastNamesAlert()}
        {this.renderActions()}
      </Box>
    );
  }
}

const mapStateToProps = (store: AppStoreInterface) => ({
  relationshipOptions: store.options.relationshipType,
  genderOptions: store.options.gender,
  smokerOptions: store.options.smoker,
  childHaveAnotherLastNameReasons: store.options.lastNameReason,
  requireSsn: store.user.requireSsn,
  hideDocumentWarnings: store.user.hideDocumentWarnings,
});

const mapPropsToValues = (props: AllProps): IFormikValues => {
  const {
    userLastName,
    genderOptions,
    smokerOptions,
    relationshipOptions,
    childHaveAnotherLastNameReasons,
    dependent,
    requireSsn,
    reason,
    effectiveDate,
  } = props;

  const initialValues = getInitialValues(
    genderOptions,
    smokerOptions,
    relationshipOptions,
    childHaveAnotherLastNameReasons,
    requireSsn,
    reason,
    userLastName,
    dependent,
    effectiveDate
  );
  return initialValues;
};

const handleSubmit = async (
  values: IFormikValues,
  bag: FormikBag<AllProps, IFormikValues>
) => {
  const { onSubmit, dependent: externalDependent } = bag.props;
  const { userLastName, requireSsn, ...dependent } = values;

  if (onSubmit && typeof onSubmit === 'function') {
    onSubmit(
      !externalDependent
        ? dependent
        : Object.assign(dependent, {
            id: externalDependent.id,
            individualId: externalDependent.individualId,
          })
    );
  }
  bag.resetForm();
};

const validationSchema = yup.object().shape({
  email: validationHelper.email,
  userLastName: yup.string(),
  requireSsn: yup.boolean(),
  firstName: yup.string().required('Field is required'),
  middleName: yup.string().optional(),
  lastName: yup.string().required('Field is required'),
  birthDate: validationHelper.date(),
  gender: yup.string().required('Field is required'),
  smoker: yup.string().required('Field is required'),
  relationshipTypeId: yup.string().required('Field is required'),
  ssn: yup
    .string()
    .when(
      ['birthDate', 'relationshipTypeId', 'requireSsn'],
      (
        birthDate: Date | null,
        relationshipTypeId: string,
        requireSsn: boolean
      ) => {
        if (requireSsn) {
          const childSsn =
            lessThanOneYear(birthDate) &&
            +relationshipTypeId === RelationshipType.Child;
          return childSsn
            ? validationHelper.childSSN.required(
                "If you don't have Social Security Number yet, please enter 000-00-0000"
              )
            : validationHelper.ssn.required('Field is required');
        } else {
          return validationHelper.ssn;
        }
      }
    ),
  lastNameReasonId: yup
    .string()
    .when(['userLastName', 'lastName', 'relationshipTypeId'], {
      is: (userLastName, lastName, relationshipTypeId) =>
        +relationshipTypeId === RelationshipType.Child &&
        lastName !== userLastName,
      then: yup.string().required('Field is required'),
      otherwise: yup.string().optional(),
    }),
  disabled: yup.boolean(),
});

export default connect(mapStateToProps)(
  withFormik<AllProps, IFormikValues>({
    enableReinitialize: true,
    mapPropsToValues,
    validationSchema,
    handleSubmit,
  })(AddDependentsModal)
);
