import React from 'react';
import Typography from '@material-ui/core/Typography';

import ModalWindow from 'components/Modal/ModalWindowComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';

interface IProps {
  isOpen: boolean;
  handleClose?: () => void | Promise<void>;
}

const Over26Modal: React.FC<IProps> = ({ isOpen, handleClose }) => {
  const actions = (
    <SubmitButton onClick={handleClose} label="Ok" variant="contained" />
  );
  return (
    <ModalWindow isOpen={isOpen} handleClose={handleClose} actions={actions}>
      <Typography variant="h6">
        You may enroll the child if he or she:
      </Typography>
      <ul>
        <li>
          is a resident of New Jersey, or is a full-time student at an
          accredited public or private institution of higher education
        </li>
        <li>
          has evidence of creditable coverage, or receipt of benefits under a
          group or individual health plan, or receipt of benefits as a Medicare
          recipient
        </li>
        <li>
          is not covered under another group or individual health plan and is
          not entitled to Medicare
        </li>
        <li>does not have any children</li>
        <li>does not have a spouse, civil union partner or domestic partner</li>
      </ul>
    </ModalWindow>
  );
};

export default Over26Modal;
