import { PartialPayrollI, PayrollI } from 'types/onboarding';
import { PayrollErrors, AmountType } from './types';

export const convertToPartialPayroll = (
  payroll: PayrollI,
  isRemaining: boolean
): PartialPayrollI => {
  return {
    ...payroll,
    method: Number(payroll.method),
    amountType: isRemaining
      ? AmountType.RemainingAmount
      : Number(payroll.amountType),
    accountType: Number(payroll.accountType),
  };
};

export const convertToRemainingAmount = (
  payroll: PartialPayrollI
): PartialPayrollI => ({
  ...payroll,
  amount: '0',
  amountType: AmountType.Dollars,
});

export const defaultPayrollData = {
  id: null,
  amount: '',
  routingNumber: '',
  accountNumber: '',
  method: 1,
  accountType: '',
  amountType: 1,
  sortOrder: '1',
};

/**
 * Here we checking if payment have remaining amount type
 */
export const isRemainingAmount = (
  payroll: PayrollI,
  isLast: boolean
): boolean => {
  return (
    isLast &&
    +payroll.amountType === AmountType.Dollars &&
    !Boolean(+payroll.amount)
  );
};

/**
 * We using this method for converting pyments from API
 * to payment types, that used by our app
 */
export function formPaymentState(payments: PayrollI[]) {
  let newMap = new Map<string, PartialPayrollI>();
  if (payments.length < 0) {
    newMap.set('default', {
      ...defaultPayrollData,
    });
  } else {
    payments.forEach((payment) => {
      const isLast =
        payments.length > 1 && +payment.sortOrder === payments.length;
      const isRemainingAmountPayment = isRemainingAmount(payment, isLast);
      newMap.set(
        payment.id,
        convertToPartialPayroll(payment, isRemainingAmountPayment)
      );
    });
  }
  console.info(newMap);
  return newMap;
}

export const isPercentage = (paymentsMap: Map<string, PartialPayrollI>) => {
  return Array.from(paymentsMap.values()).every(
    (payment) => payment.amountType === AmountType.Percentage
  );
};

export const getCurrentAmountType = (
  paymentsMap: Map<string, PartialPayrollI>
): number => {
  let paymentMethod = {} as PartialPayrollI;
  let count = 0;
  paymentsMap.forEach((value) => {
    if (count === 0) {
      paymentMethod = value;
    }
  });
  return paymentMethod.amountType | AmountType.Percentage;
};

export const getDepositFieldsError = (payment: PartialPayrollI) => {
  const errors = {} as PayrollErrors;
  for (const [key, value] of Object.entries(payment)) {
    const errorKey = key as keyof PayrollErrors;
    if (!value && value !== 0) {
      errors[errorKey] = 'Field is required';
    } else {
      errors[errorKey] = '';
    }
  }
  return errors;
};

export const getCardOrCheckPayrollFieldsError = (payment: PartialPayrollI) => {
  const errors = {} as PayrollErrors;
  for (const [key, value] of Object.entries(payment)) {
    const errorKey = key as keyof PayrollErrors;
    if (!value && value !== 0 && errorKey === 'amount') {
      errors[errorKey] = 'Field is required';
    } else {
      errors[errorKey] = '';
    }
  }
  return errors;
};
