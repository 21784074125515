import { IUpdateDependent, DependentInterface } from 'types/dependents';
import { fromDependentToCreateDependent } from './fromDependentToCreateDependent';

export const fromDependentToUpdateDependent = (
  dependent: DependentInterface
): IUpdateDependent => {
  return {
    ...fromDependentToCreateDependent(dependent),
    individualId: dependent.individualId,
  };
};
