import React from 'react';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

import useWindowDimensions from 'hooks/useWindowDimensions';
import SimpleStepper, {
  SimpleStepperPropsInterface,
} from './SimpleStepperComponent';

export interface AdaptiveStepperPropsInterface
  extends Omit<SimpleStepperPropsInterface, 'orientation'> {
  breakpoint: number;
}

const AdaptiveSimpleStepper: React.FC<AdaptiveStepperPropsInterface> = ({
  breakpoint,
  ...stepperProps
}) => {
  const { width } = useWindowDimensions();

  return (
    <SimpleStepper
      orientation={width > breakpoint ? 'horizontal' : 'vertical'}
      {...stepperProps}
    />
  );
};

export default AdaptiveSimpleStepper;
