import React from 'react';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import Box from '@material-ui/core/Box';
import ModalWindow, { ModalWindowPropsInterface } from './ModalWindowComponent';

import styles from './styles.module.scss';

export interface IConfirmationModalProps
  extends Omit<ModalWindowPropsInterface, 'actions'> {
  onSubmit: () => void | Promise<void>;
  cancelLabel?: string;
}

const ConfirmationModalWithCancel: React.FC<IConfirmationModalProps> = (
  props
) => {
  const { onSubmit, cancelLabel, ...modalWindowProps } = props;

  const actions = (
    <Box width="100%" textAlign="left">
      <SubmitButton
        className={styles.submitBtn}
        onClick={onSubmit}
        label="Yes"
        variant="contained"
      />
      <SubmitButton
        className={styles.cancelBtn}
        onClick={modalWindowProps.handleClose}
        label={cancelLabel ?? 'Cancel'}
        variant="text"
        color="primary"
      />
    </Box>
  );

  return <ModalWindow actions={actions} {...modalWindowProps} isConfirm />;
};

export default ConfirmationModalWithCancel;
