import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PersonalInfo from 'components/PersonalInfo';
import DependentStatus from 'components/DependentStatus';
import IBasicOption from 'models/IBasicOption';
import Gender from 'enums/gender';
import femaleIcon from 'assets/female.png';
import maleIcon from 'assets/male.png';
import { Divider } from '@material-ui/core';
import getOptionLabelByValue from 'helpers/getOptionLabelByValue';
import { validateDependentsFields } from 'helpers/validateDependents';
import { DependentInterface } from 'types/dependents';
import { MASKED_SSN_REG_EXP } from 'constants/regExps';
import { EMPTY_VALUE } from 'constants/placeholders';
import { getDateWithAgeString } from 'helpers/dateHelpers';

import styles from './styles.module.scss';

interface IProps {
  dependent: DependentInterface;
  gender: IBasicOption<number>[];
  handleHidePersonalData: () => void;
  handleOpenModal: () => void;
  handleShowPersonalData: (password: string) => Promise<void>;
  isPasswordPrompt: boolean;
}

// TODO: Try to create more general component for Beneficiary and Dependent
const DependentReadOnlyItem: React.FC<IProps> = (props) => {
  const {
    dependent,
    gender,
    handleHidePersonalData,
    handleOpenModal,
    handleShowPersonalData,
    isPasswordPrompt,
  } = props;

  const { errors, alerts } = validateDependentsFields(dependent);

  const unlockSsn = () =>
    isPasswordPrompt ? handleOpenModal() : handleShowPersonalData('');

  const renderGenderImage = () => {
    const castedGender = +(dependent.gender as string);

    return (
      <Box>
        <img
          className={styles.genderIcon}
          src={castedGender === Gender.Male ? maleIcon : femaleIcon}
          alt={castedGender === Gender.Male ? 'Male' : 'Female'}
        />
      </Box>
    );
  };

  const getBottomBorder = () => {
    if (dependent.relationshipType === 'Child') {
      if (!dependent.gender) return null;
      return +dependent.gender === Gender.Male
        ? styles.borderMale
        : styles.borderFemale;
    }
    return styles.borderDefault;
  };

  const renderSsn = (ssn?: string) => {
    if (!ssn) {
      return (
        <Typography className={styles.infoAnswer} variant="body2">
          {EMPTY_VALUE}
        </Typography>
      );
    }
    return (
      <PersonalInfo
        isLocked={MASKED_SSN_REG_EXP.test(ssn)}
        onLockIconClick={unlockSsn}
        personalInfo={ssn}
        handleHidePersonalData={handleHidePersonalData}
      />
    );
  };

  return (
    <Box className={`${styles.card} ${getBottomBorder()}`}>
      <Box className={styles.cardTitle}>
        <Box>
          <Typography className={styles.dependentName} variant="subtitle2">
            {dependent.firstName} {dependent.lastName}
          </Typography>
          <Typography className={styles.dependentRelation} variant="caption">
            {dependent.relationshipType}
          </Typography>
        </Box>
        {renderGenderImage()}
      </Box>
      <Divider />
      <Box className={styles.infoContainer}>
        <Box>
          <Box className={styles.infoRow}>
            <Typography className={styles.infoName} variant="body2">
              Date of birth:
            </Typography>
            <Typography className={styles.infoAnswer} variant="body2">
              {typeof dependent.birthDate === 'string'
                ? getDateWithAgeString(dependent.birthDate)
                : dependent.birthDate}
            </Typography>
          </Box>
          <Box className={styles.infoRow}>
            <Typography className={styles.infoName} variant="body2">
              Social security number:
            </Typography>
            {renderSsn(dependent.ssn)}
          </Box>
          <Box className={styles.infoRow}>
            <Typography className={styles.infoName} variant="body2">
              Gender:
            </Typography>
            <Typography className={styles.infoAnswer} variant="body2">
              {getOptionLabelByValue(gender, dependent.gender as string)}
            </Typography>
          </Box>
        </Box>
        <Box alignSelf="flex-end">
          <DependentStatus errors={errors} alerts={alerts} />
        </Box>
      </Box>
    </Box>
  );
};

export default DependentReadOnlyItem;
