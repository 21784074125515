import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import { IPlanSummary } from 'types/plans';
import styles from './styles.module.scss';

interface IProps {
  plans?: IPlanSummary[];
}

const DeclinedPlansTable: React.FC<IProps> = ({ plans }) => {
  return (
    <>
      <Toolbar className={styles.declinedTableToolbar}>
        <Typography className={styles.tableHeader} variant="body2">
          Plans You Declined
        </Typography>
      </Toolbar>
      <Box className={styles.tableBox}>
        <TableContainer component={Box}>
          <Table className={styles.declinedTable}>
            <TableBody>
              {plans && plans.length ? (
                plans.map((plan, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      <Box className={styles.declinedBlock}>
                        <PlanIcons planTypeId={Number(plan.planTypeId)} />
                        {plan.planName}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell component="th">
                    <Typography
                      variant="h6"
                      align="center"
                      className={styles.placeholder}
                    >
                      You have not declined any plans
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default DeclinedPlansTable;
