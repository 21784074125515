import React from 'react';
import Typography from '@material-ui/core/Typography';
import FileLink from 'components/FileLink';
import { Task } from 'types/enrollments';
import { ReactComponent as warningIcon } from 'assets/warning.svg';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@material-ui/core';
import styles from './styles.module.scss';

interface IProps {
  issues?: Task[];
}

const EnrollmentsIssues: React.FC<IProps> = ({ issues }) => {
  return (
    <>
      {issues && issues.length ? (
        <>
          <Typography className={styles.issuesTitle} variant="subtitle2">
            Enrollment Issues
          </Typography>
          <List className={styles.enrollmentsIssues}>
            {issues.map((issue, i) => (
              <ListItem className={styles.item}>
                <ListItemIcon className={styles.icon}>
                  <SvgIcon component={warningIcon} />
                </ListItemIcon>
                <ListItemText className={styles.text}>
                  {issue.fileId ? (
                    <FileLink
                      fileId="6934"
                      linkLabel={issue.label}
                      isIssue={true}
                    />
                  ) : (
                    issue.label
                  )}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      ) : null}
    </>
  );
};

export default EnrollmentsIssues;
