import moment from 'moment';

import { DependentInterface } from 'types/dependents';
import { IFullPlan } from 'types/plans';
import IBasicOption from 'models/IBasicOption';

import ExternalPlanType from 'enums/externalPlanType';
import DomesticPartnerPlanStatus from 'enums/domesticPartnerPlanStatus';
import RelationshipType from 'enums/relationshipType';

export const getDependentAge = (dependent: DependentInterface): number => {
  return moment().diff(dependent.birthDate, 'years');
};

export const getDependentLabel = (
  dependent: DependentInterface,
  relationshipTypes: IBasicOption<number>[],
  message?: string
): string => {
  const relationType = relationshipTypes.find(
    (type) => dependent.relationshipTypeId === type.value
  );
  const age = getDependentAge(dependent);
  return `${dependent.firstName} ${dependent.lastName}, ${
    relationType?.label || ''
  } (${age})${message ? ` (${message})` : ''}`;
};

export const enrollDependents = (
  planTypeId: string | number,
  tierStructure?: string | number | null,
  miniCensus?: string | number,
  ageBandedRateType?: string | number
) => {
  return (
    planTypeId === ExternalPlanType.Dental ||
    planTypeId === ExternalPlanType.Medical ||
    planTypeId === ExternalPlanType.Vision ||
    checkTierStatus(planTypeId, tierStructure) ||
    checkVoluntary(miniCensus, tierStructure, ageBandedRateType)
  );
};

export const checkVoluntary = (
  miniCensus?: string | number,
  tierStructure?: string | number | null,
  ageBandedRateType?: string | number
): boolean => {
  return Boolean(
    miniCensus &&
      +miniCensus === 1 &&
      ((tierStructure && +tierStructure !== 5) ||
        (ageBandedRateType && +ageBandedRateType === 0))
  );
};

export const isTierNeeded = (planTypeId: string | number) =>
  planTypeId === ExternalPlanType.Dental ||
  planTypeId === ExternalPlanType.Medical ||
  planTypeId === ExternalPlanType.Vision;

export const checkTierStatus = (
  planTypeId: string | number,
  tierStructure?: string | number | null
): boolean => {
  if (isTierNeeded(planTypeId) || !tierStructure) return false;
  switch (Number(tierStructure)) {
    case 4:
    case 3:
    case 2:
      return true;
    default:
      return false;
  }
};

export const isDomesticPartner = (dependent: DependentInterface): boolean => {
  return (
    dependent.relationshipTypeId?.toString() ===
    RelationshipType.DomesticPartner.toString()
  );
};

export const isChild = (dependent: DependentInterface): boolean => {
  return (
    dependent.relationshipTypeId?.toString() ===
    RelationshipType.Child.toString()
  );
};

export const isChildConditionallyEnrolled = (
  planTypeId: string,
  dependent: DependentInterface
): boolean => {
  const dependentAge = getDependentAge(dependent);
  return (
    planTypeId === ExternalPlanType.Medical &&
    dependentAge >= 26 &&
    dependentAge < 31
  );
};

export const isChildCanBeEnrolled = (
  planTypeId: string,
  dependent: DependentInterface,
  tierStructure?: string | number | null,
  dependentMaxAge?: string | number | null,
  miniCensus?: string | number,
  ageBandedRateType?: string | number
): boolean => {
  const dependentAge = getDependentAge(dependent);

  if (dependentMaxAge) return dependentAge < +dependentMaxAge;
  //if (!enrollDependents(planTypeId, tierStructure, miniCensus, ageBandedRateType)) return false;
  if (checkTierStatus(planTypeId, tierStructure)) return true;
  if (
    (planTypeId === ExternalPlanType.Dental ||
      planTypeId === ExternalPlanType.Vision) &&
    dependentAge >= 26
  )
    return false;
  if (planTypeId === ExternalPlanType.Medical && dependentAge >= 31)
    return false;
  if (isChildConditionallyEnrolled(planTypeId, dependent)) return true;
  return true;
};

export const isDomesticPartnerCanBeEnrolled = (
  domesticPartner: DependentInterface,
  plan?: IFullPlan,
  userGender?: string | number | null
): boolean => {
  const isSameSexPartner =
    domesticPartner.gender?.toString() === userGender?.toString();
  if (!plan || !userGender) return true;
  switch (plan.domesticPartner.toString()) {
    case DomesticPartnerPlanStatus.CannotBeEnrolled:
      return false;
    case DomesticPartnerPlanStatus.OnlySameSex:
      return isSameSexPartner;
    case DomesticPartnerPlanStatus.OnlyOppositeSexPartners:
      return !isSameSexPartner;
    default:
      return true;
  }
};
