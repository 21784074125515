import { CustomField } from 'types/onboarding';
import { MapField, MapFieldsError } from './types';

export const fieldsToFieldMap = (fields: CustomField[]) => {
  const fieldsMap = new Map<string, MapField>();

  fields.forEach((field) => {
    fieldsMap.set(field.id, { ...field, value: '' });
  });

  return fieldsMap;
};

export const fieldsToErrorFieldMap = (fields: CustomField[]) => {
  const errorsFieldMap = new Map<string, MapFieldsError>();

  fields.forEach(({ id }) => {
    errorsFieldMap.set(id, { id, error: undefined });
  });

  return errorsFieldMap;
};
