const mimeToExtension = (mime: string) => {
  const map: { [key: string]: string } = {
    'text/csv': 'csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  };

  return map[mime] || '';
};

export default mimeToExtension;
