import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import userDomain from 'api/domains/user';
import CheckActivity from 'containers/CheckActivity';
import { UserStoreInterface } from 'store/user';
import { AppStoreInterface } from 'store/app';
import { Actions as OptionActions } from 'redux/reducers/options/actions';
import ExternalRegistrationStep from 'enums/externalRegistrationStep';
import ExternalOnboardingStep from 'enums/externalOnboardingStep';
import * as userRoutines from 'redux/sagas/user/routines';
import { allOptions as loadAllOptions } from 'redux/sagas/options/routines';
import { beforeMatch } from 'helpers/beforeMatch';
import Sidebar from './components/Sidebar';
import Spinner from './components/Spinner';
import Footer from './components/Footer';
import Header from './components/Header';
import UpdatePrivacyNotice from './components/Modal/UpdatePrivacyNotice';
import RootRouter from './router/RootRouter';
import './app.scss';

type UserStorePickedProps = Pick<
  UserStoreInterface,
  | 'isAuthenticated'
  | 'loading'
  | 'firstName'
  | 'lastName'
  | 'clientName'
  | 'clientLogo'
  | 'agencyName'
  | 'agencyLogo'
  | 'registrationStep'
  | 'onboardingStep'
  | 'roleType'
  | 'passwordReset'
  | 'colorConfiguration'
  | 'securityQuestionReset'
  | 'mblPrivacyPolicyAgreement'
  | 'testUser'
  | 'enableMidyearChanges'
  | 'impersonatingUserId'
>;

interface IProps extends UserStorePickedProps {
  pathname: string;
  loadCurrentUser: () => void;
  loadNonAuthOptions: typeof OptionActions.fetchAllOptionsForNonAuthenticatedUser;
  loadAllOptions: typeof loadAllOptions.trigger;
  logout: () => void;
}

const App: React.FC<IProps> = ({
  isAuthenticated,
  loading,
  firstName,
  lastName,
  clientName,
  clientLogo,
  agencyName,
  agencyLogo,
  pathname,
  registrationStep,
  onboardingStep,
  roleType,
  passwordReset,
  loadCurrentUser,
  loadNonAuthOptions,
  logout,
  loadAllOptions,
  colorConfiguration,
  securityQuestionReset,
  mblPrivacyPolicyAgreement,
  testUser,
  enableMidyearChanges,
  impersonatingUserId,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();

  const [modalOpen, setModalOpen] = useState(
    !Boolean(mblPrivacyPolicyAgreement) && !Boolean(impersonatingUserId)
  );
  useEffect(() => {
    beforeMatch(location);
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      loadAllOptions();
    }
  }, [loadAllOptions, isAuthenticated]);

  useEffect(() => {
    loadNonAuthOptions();
    loadCurrentUser();
  }, [loadCurrentUser, loadNonAuthOptions]);

  const registrationCompleted = () => {
    if (registrationStep === null) {
      return false;
    }
    return (
      +registrationStep === ExternalRegistrationStep.RegistrationIsFinished
    );
  };

  const isRegistrationProccess = () => {
    if (registrationStep === null) return false;

    return (
      typeof +registrationStep === 'number' &&
      +registrationStep !== ExternalRegistrationStep.RegistrationIsFinished &&
      isAuthenticated
    );
  };

  const isOnboardingProcess = () => {
    if (onboardingStep === null) return false;

    return (
      typeof +onboardingStep === 'number' &&
      +onboardingStep !== ExternalOnboardingStep.OnboardingIsFinished &&
      isAuthenticated
    );
  };
  const checkPath = () => {
    switch (pathname) {
      case '/login':
      case '/forgot-password':
      case '/reset-password':
        return true;
      default:
        return false;
    }
  };
  const showSideBar = (): Boolean => {
    return Boolean(
      isAuthenticated &&
        registrationCompleted() &&
        !passwordReset &&
        !isOnboardingProcess()
    );
  };

  const accept = async () => {
    try {
      await userDomain.updatePrivacyPolicy();
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return (
      <Box className="full-page-spinner">
        <Spinner />
      </Box>
    );
  }
  return (
    <div className="app">
      {!((pathname === '/' || checkPath()) && !isAuthenticated) && (
        <Header
          isAuthenticated={isAuthenticated && registrationCompleted()}
          firstName={firstName}
          lastName={lastName}
          clientName={clientName}
          clientLogo={clientLogo}
          toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
          logout={logout}
          isOnboardingProccess={isOnboardingProcess()}
          isRegistrationProccess={
            isRegistrationProccess() && !isOnboardingProcess()
          }
          colorConfiguration={colorConfiguration}
        />
      )}
      <div className="main">
        {showSideBar() && (
          <Sidebar
            open={sidebarOpen}
            setOpen={setSidebarOpen}
            pathname={pathname}
            roleType={roleType}
            testUser={Boolean(testUser)}
            enableMidyearChanges={Boolean(enableMidyearChanges)}
          />
        )}
        <CheckActivity loadCurrentUser={loadCurrentUser}>
          <RootRouter
            isAuthenticated={isAuthenticated}
            onboardingStep={onboardingStep !== null ? +onboardingStep : null}
            registrationStep={
              registrationStep !== null ? +registrationStep : null
            }
            passwordReset={passwordReset}
            securityQuestionReset={securityQuestionReset}
          />
        </CheckActivity>
      </div>
      {!((pathname === '/' || checkPath()) && !isAuthenticated) && (
        <Footer
          colorConfiguration={colorConfiguration}
          isAuthenticated={isAuthenticated}
          agencyName={agencyName}
          agencyLogo={agencyLogo}
        />
      )}
      {isAuthenticated &&
        !mblPrivacyPolicyAgreement &&
        !impersonatingUserId && (
          <UpdatePrivacyNotice
            isOpen={modalOpen}
            handleClose={() => setModalOpen(false)}
            logout={logout}
            accept={accept}
          />
        )}
    </div>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  isAuthenticated: store.user.isAuthenticated,
  loading: store.user.loading,
  firstName: store.user.firstName,
  lastName: store.user.lastName,
  clientName: store.user.clientName,
  clientLogo: store.user.clientLogo,
  agencyName: store.user.agencyName,
  agencyLogo: store.user.agencyLogo,
  pathname: store.router.location.pathname,
  registrationStep: store.user.registrationStep,
  onboardingStep: store.user.onboardingStep,
  roleType: store.user.roleType,
  passwordReset: store.user.passwordReset,
  securityQuestionReset: store.user.securityQuestionReset,
  colorConfiguration: store.user.colorConfiguration,
  mblPrivacyPolicyAgreement: store.user.mblPrivacyPolicyAgreement,
  testUser: store.user.testUser,
  enableMidyearChanges: store.user.enableMidyearChanges,
  impersonatingUserId: store.user.impersonatingUserId,
});

const mapDispatchToProps = {
  loadCurrentUser: userRoutines.loadCurrentUser,
  loadNonAuthOptions: OptionActions.fetchAllOptionsForNonAuthenticatedUser,
  loadAllOptions: loadAllOptions.trigger,
  logout: userRoutines.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
