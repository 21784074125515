import React from 'react';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';

import { Box, Typography } from '@material-ui/core';
import * as validationHelper from 'helpers/validation';
import SubmitButton from '../Forms/SubmitButton/SubmitButtonComponent';
import ModalWindow, {
  ModalWindowPropsInterface,
} from '../Modal/ModalWindowComponent';
import FormikPasswordInput from '../Forms/Formik/PasswordInput';

import styles from './styles.module.scss';

interface IFormValues {
  password: string;
}

const validationSchema = yup.object().shape({
  password: validationHelper.password,
});

interface IProps
  extends Pick<ModalWindowPropsInterface, 'isOpen' | 'handleClose'> {
  onSubmit: (password: string) => void | Promise<void>;
}

const PasswordPrompt: React.FC<IProps> = ({
  isOpen,
  handleClose,
  onSubmit,
}) => {
  const initialValues: IFormValues = {
    password: '',
  };

  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    try {
      await onSubmit(values.password);
      if (handleClose) handleClose();
    } catch (err) {
      formikHelpers.setFieldError(
        'password',
        (err as any).response.data.developerMessage as string
      );
    }
  };
  return (
    <ModalWindow
      isOpen={isOpen}
      handleClose={handleClose}
      title="Password"
      withoutTitle
      withoutPadding
      round
    >
      <Box className={styles.main}>
        <Typography className={styles.title}>
          Please enter your password
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<IFormValues>) => (
            <Form className={styles.form}>
              <Field
                component={FormikPasswordInput}
                label="Password"
                name="password"
                fullWidth
              />
              <SubmitButton
                disabled={formik.isSubmitting}
                label="Submit"
                className={styles.submitBtn}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </ModalWindow>
  );
};

export default PasswordPrompt;
