import React from 'react';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import styles from './styles.module.scss';

const Disclaimer: React.FC = () => {
  return (
    <SimpleAlert type="info" className={styles.info}>
      This benefit summary provides selected highlights of the employee benefits
      program being offered. It is not a legal document and shall not be
      construed as a guarantee of benefits. All benefit plans are governed by
      master policies, contracts and plan documents. Any discrepancies between
      any information provided through this summary and the actual terms of such
      policies, contracts and plan documents shall be governed by the terms of
      such policies, contracts and plan documents. Your employer reserves the
      right to amend, suspend or terminate any benefit plan, in whole or in
      part, at any time. The authority to make such changes rests with the Plan
      Administrator.
    </SimpleAlert>
  );
};

export default Disclaimer;
