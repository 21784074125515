import React from 'react';
import { Box, Typography, Toolbar } from '@material-ui/core';
import styles from './styles.module.scss';

export interface ContentBoxInterface {
  title?: string | React.ReactNode;
  button?: React.ReactNode;
  isBox?: boolean;
  withoutMargin?: boolean;
  padding?: boolean;
  hide?: boolean;
  onClick?: () => void;
}

const ContentContainer: React.FC<ContentBoxInterface> = (props) => {
  const {
    title,
    children,
    isBox,
    withoutMargin,
    padding,
    hide,
    button,
    onClick,
  } = props;

  return (
    <>
      <Toolbar
        onClick={onClick}
        className={`${styles.tableToolbar} ${
          withoutMargin && styles.withoutMargin
        }`}
      >
        <Typography className={styles.tableHeader}>{title}</Typography>
        {button}
      </Toolbar>
      {!hide && (
        <Box
          className={`${isBox ? styles.box : styles.tableBox} ${
            padding && styles.paddingBox
          }`}
        >
          {children}
        </Box>
      )}
    </>
  );
};

export default ContentContainer;
