import React from 'react';
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckIcon from '@material-ui/icons/Check';
import MaximizeIcon from '@material-ui/icons/Maximize';
import StopIcon from '@material-ui/icons/Stop';

import { I9DocksI } from 'types/onboarding';
import { firstDocumentsList, secondDocumentsList } from './tableData';
import { planeNestedList } from './helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
    nestedSecond: {
      paddingLeft: theme.spacing(8),
    },
    nestedThird: {
      paddingLeft: theme.spacing(12),
    },
    nestedFourth: {
      paddingLeft: theme.spacing(16),
    },
  })
);

interface Props {
  i9Docs: I9DocksI[];
}

export type MappedDock = {
  parent: JSX.Element;
  children: MappedDock[];
};

const EmploymentEligibilityDocuments: React.FC<Props> = ({ i9Docs }) => {
  const classes = useStyles();

  const getListIconByNestedLvl = (lvl: number) => {
    switch (lvl) {
      case 1:
        return <FiberManualRecordIcon fontSize="inherit" />;
      case 2:
        return <StopIcon fontSize="inherit" />;
      case 3:
        return <MaximizeIcon fontSize="inherit" />;
      case 4:
        return <CheckIcon fontSize="inherit" />;
      default:
        return <ArrowRightIcon fontSize="inherit" />;
    }
  };

  const getClassNameByNestedLvl = (lvl: number) => {
    switch (lvl) {
      case 1:
        return classes.nested;
      case 2:
        return classes.nestedSecond;
      case 3:
        return classes.nestedThird;
      case 4:
        return classes.nestedFourth;
      default:
        return '-';
    }
  };

  const renderI9Docks = () => {
    if (i9Docs.length < 0) {
      return null;
    }

    const mapDock = (dock: I9DocksI, nestedLvl: number, index: number) => {
      let mappedChildren = [] as MappedDock[];
      if (dock.children.length > 0) {
        mappedChildren = dock.children.map((el, i) =>
          mapDock(el, nestedLvl + 1, i)
        );
      }
      return {
        parent: (
          <ListItem
            key={`listItem.${nestedLvl}.${index}`}
            className={getClassNameByNestedLvl(nestedLvl)}
          >
            <ListItemIcon>{getListIconByNestedLvl(nestedLvl)}</ListItemIcon>
            <ListItemText primary={dock.parent} />
          </ListItem>
        ),
        children: mappedChildren,
      };
    };

    const mappedList = i9Docs.map((dock, i) => mapDock(dock, 1, i));
    const listContent = planeNestedList(mappedList);

    return (
      <>
        <Typography variant="h6">Present either:</Typography>
        {listContent}
      </>
    );
  };

  return (
    <>
      <Typography variant="subtitle1">
        You must present to your employer documentation that establishes your
        identity and employment authorization. Choose which documents to present
        from the following Lists of Acceptable Documents. You must present this
        documentation no later than the third day after beginning employment,
        although you may present the required documentation before this date.
      </Typography>
      {renderI9Docks()}
      <Typography variant="h6">OR two of the following documents:</Typography>
      <Grid
        direction="row"
        justify="center"
        alignItems="flex-start"
        container
        spacing={1}
      >
        <Grid item xs={12} md={6}>
          <Typography align="center" variant="h6">
            One document from this list
          </Typography>
          <List disablePadding>
            {firstDocumentsList.map((text, i) => (
              <ListItem key={`firstList-${i}`}>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography align="center" variant="h6">
            One document from this list
          </Typography>
          <List disablePadding>
            {secondDocumentsList.map((text, i) => {
              if (typeof text !== 'string') {
                return text.map((nestedText, j) => (
                  <ListItem
                    key={`secondList-${i}.${j}`}
                    className={classes.nested}
                  >
                    <ListItemIcon>{j + 1}.</ListItemIcon>
                    <ListItemText primary={nestedText} />
                  </ListItem>
                ));
              }
              return (
                <ListItem key={`secondList-${i}`}>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default EmploymentEligibilityDocuments;
