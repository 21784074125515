import React from 'react';
import {
  TableContainer,
  TableBody,
  TableRow,
  Box,
  Typography,
} from '@material-ui/core';

import getCurrency from 'helpers/getCurrency';
import { IEnrollment } from 'types/enrollments';
import { useStyles, CustomTotalTable, CustomCell } from './styling';

interface ITotalContributionProps {
  enrollments: IEnrollment[];
}

const TotalContribution: React.FC<ITotalContributionProps> = ({
  enrollments,
}) => {
  const classes = useStyles();

  const totalContribution = enrollments.reduce<number>(
    (prevContribution, enrollment) => {
      return enrollment.contributionAmount
        ? prevContribution + +enrollment.contributionAmount
        : prevContribution;
    },
    0
  );

  return (
    <TableContainer className={classes.tableContainer} component={Box}>
      <CustomTotalTable style={{ borderCollapse: 'unset' }}>
        <TableBody>
          <TableRow>
            <CustomCell className={classes.totalAmountLabel} align="right">
              <Typography className={classes.titleAmount}>
                Total contribution
              </Typography>
            </CustomCell>
            <CustomCell>
              <Box className={classes.contributionAmountCommon}>
                {getCurrency(totalContribution, 2)} per paycheck
              </Box>
            </CustomCell>
          </TableRow>
        </TableBody>
      </CustomTotalTable>
    </TableContainer>
  );
};

export default TotalContribution;
