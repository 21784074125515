import { all, put, takeEvery, call } from 'redux-saga/effects';

import {
  FETCH_ALL_AUTH_OPTIONS,
  FETCH_ALL_NON_AUTH_OPTIONS,
} from 'redux/reducers/options/actionTypes';
import optionsDomain from 'api/domains/options';

import { fromOptionsToAppOptions } from 'mappers/common/fromOptionsToAppOptions';
import { fromSystemOptionToBasicOption } from 'mappers/common/fromSystemOptionToBasicOption';
import OptionType from 'enums/optionsType';
import {
  genderOptions,
  securityQuestionOptions,
  maritalStatusOptions,
  relationshipTypeOptions,
  lastNameReasonOptions,
  employmentStatusOptions,
  employmentTypeOptions,
  officeLocationOptions,
  accessGroupOptions,
  qualifyingEventOptions,
  logEventTypeOptions,
  planTypeOptions,
  workEligibilityTypeOptions,
  w4FilingStatusOptions,
  payrollAccountTypeOptions,
  payrollAmountTypeOptions,
  payrollMethodOptions,
  eeoJobCategoryOptions,
  eeoMilitaryStatusOptions,
  eeoRaceOptionsOptions,
  beneficiaryRelationshipTypeOptions,
  allOptions,
  smokerOptions,
} from './routines';

function* fetchGenderOptionsWorker() {
  try {
    const { data } = yield call(optionsDomain.getOptions, OptionType.Gender);
    yield put(genderOptions.success(data.map(fromSystemOptionToBasicOption)));
  } catch (e) {
    // TODO: Handle errors
    console.error(e);
  }
}

function* fetchSmokerOptionsWorker() {
  try {
    const { data } = yield call(optionsDomain.getOptions, OptionType.Smoker);
    yield put(smokerOptions.success(data.map(fromSystemOptionToBasicOption)));
  } catch (e) {
    // TODO: Handle errors
    console.error(e);
  }
}

function* fetchMaritalStatusOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.MaritalStatus
    );
    yield put(
      maritalStatusOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchSecurityQuestionsOptionsWorker() {
  try {
    const { data } = yield call(optionsDomain.getSecurityQuestion);
    yield put(
      securityQuestionOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchRelationshipTypeOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.RelationshipType
    );
    yield put(
      relationshipTypeOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchLastNameReasonOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.LastNameReason
    );
    yield put(
      lastNameReasonOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchEmploymentTypeOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.EmploymentType
    );
    yield put(
      employmentTypeOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchEmploymentStatusOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.EmploymentStatus
    );
    yield put(
      employmentStatusOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchOfficeLocationOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.OfficeLocation
    );
    yield put(
      officeLocationOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchAccessGroupOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.AccessGroup
    );
    yield put(
      accessGroupOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchQualifyingEventOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.QualifyingEvent
    );
    yield put(
      qualifyingEventOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchLogEventTypeOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.LogEventType
    );
    yield put(
      logEventTypeOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchPlanTypeOptionsWorker() {
  try {
    const { data } = yield call(optionsDomain.getOptions, OptionType.PlanType);
    yield put(planTypeOptions.success(data.map(fromSystemOptionToBasicOption)));
  } catch (e) {
    console.error(e);
  }
}

function* fetchWorkEligibilityTypeOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.WorkEligibilityType
    );
    yield put(
      workEligibilityTypeOptions.success(
        data.map(fromSystemOptionToBasicOption)
      )
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchPayrollMethodOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.PayrollMethod
    );
    yield put(
      payrollMethodOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchPayrollAmountTypeOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.PayrollAmountType
    );
    yield put(
      payrollAmountTypeOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchPayrollAccountTypeOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.PayrollAccountType
    );
    yield put(
      payrollAccountTypeOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchW4FilingStatusOptionsWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.W4FilingStatus
    );
    yield put(
      w4FilingStatusOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchEeoRaceOptionsWorker() {
  try {
    const { data } = yield call(optionsDomain.getOptions, OptionType.Race);
    yield put(
      eeoRaceOptionsOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchEeoJobCategoryWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.JobCategory
    );
    yield put(
      eeoJobCategoryOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchEeoMilitaryStatusWorker() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.MilitaryStatus
    );
    yield put(
      eeoMilitaryStatusOptions.success(data.map(fromSystemOptionToBasicOption))
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchBeneficiaryRelationshipTypeOptions() {
  try {
    const { data } = yield call(
      optionsDomain.getOptions,
      OptionType.BeneficiaryRelationshipType
    );
    yield put(
      beneficiaryRelationshipTypeOptions.success(
        data.map(fromSystemOptionToBasicOption)
      )
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchAllOptions() {
  try {
    const { data } = yield call(optionsDomain.getAllOptions);
    yield put(allOptions.success(fromOptionsToAppOptions(data)));
  } catch (error) {
    console.error(error);
  }
}

function* fetchAllOptionsForAuthenticatedUser() {
  try {
    yield all([
      put(genderOptions()),
      put(smokerOptions()),
      put(maritalStatusOptions()),
      put(relationshipTypeOptions()),
      put(lastNameReasonOptions()),
      put(employmentTypeOptions()),
      put(employmentStatusOptions()),
      put(officeLocationOptions()),
      put(accessGroupOptions()),
      put(qualifyingEventOptions()),
      put(logEventTypeOptions()),
      put(planTypeOptions()),
      put(workEligibilityTypeOptions()),
      put(w4FilingStatusOptions()),
      put(payrollMethodOptions()),
      put(payrollAmountTypeOptions()),
      put(payrollAccountTypeOptions()),
    ]);
  } catch (e) {
    console.error(e);
  }
}

function* fetchAllOptionsForNonAuthenticatedUser() {
  try {
    yield all([put(securityQuestionOptions())]);
  } catch (e) {
    console.error(e);
  }
}

function* watchOptions() {
  yield takeEvery(genderOptions.trigger, fetchGenderOptionsWorker);
  yield takeEvery(smokerOptions.trigger, fetchSmokerOptionsWorker);
  yield takeEvery(
    maritalStatusOptions.trigger,
    fetchMaritalStatusOptionsWorker
  );
  yield takeEvery(
    securityQuestionOptions.trigger,
    fetchSecurityQuestionsOptionsWorker
  );
  yield takeEvery(
    relationshipTypeOptions.trigger,
    fetchRelationshipTypeOptionsWorker
  );
  yield takeEvery(
    lastNameReasonOptions.trigger,
    fetchLastNameReasonOptionsWorker
  );
  yield takeEvery(
    employmentTypeOptions.trigger,
    fetchEmploymentTypeOptionsWorker
  );
  yield takeEvery(
    employmentStatusOptions.trigger,
    fetchEmploymentStatusOptionsWorker
  );
  yield takeEvery(
    officeLocationOptions.trigger,
    fetchOfficeLocationOptionsWorker
  );
  yield takeEvery(
    qualifyingEventOptions.trigger,
    fetchQualifyingEventOptionsWorker
  );
  yield takeEvery(accessGroupOptions.trigger, fetchAccessGroupOptionsWorker);
  yield takeEvery(logEventTypeOptions.trigger, fetchLogEventTypeOptionsWorker);
  yield takeEvery(planTypeOptions.trigger, fetchPlanTypeOptionsWorker);
  yield takeEvery(
    payrollMethodOptions.trigger,
    fetchPayrollMethodOptionsWorker
  );
  yield takeEvery(
    payrollAmountTypeOptions.trigger,
    fetchPayrollAmountTypeOptionsWorker
  );
  yield takeEvery(
    payrollAccountTypeOptions.trigger,
    fetchPayrollAccountTypeOptionsWorker
  );
  yield takeEvery(
    workEligibilityTypeOptions.trigger,
    fetchWorkEligibilityTypeOptionsWorker
  );
  yield takeEvery(
    w4FilingStatusOptions.trigger,
    fetchW4FilingStatusOptionsWorker
  );
  yield takeEvery(FETCH_ALL_AUTH_OPTIONS, fetchAllOptionsForAuthenticatedUser);
  yield takeEvery(
    FETCH_ALL_NON_AUTH_OPTIONS,
    fetchAllOptionsForNonAuthenticatedUser
  );

  yield takeEvery(eeoRaceOptionsOptions.TRIGGER, fetchEeoRaceOptionsWorker);
  yield takeEvery(eeoJobCategoryOptions.TRIGGER, fetchEeoJobCategoryWorker);
  yield takeEvery(
    eeoMilitaryStatusOptions.TRIGGER,
    fetchEeoMilitaryStatusWorker
  );

  yield takeEvery(
    beneficiaryRelationshipTypeOptions.TRIGGER,
    fetchBeneficiaryRelationshipTypeOptions
  );

  yield takeEvery(allOptions.TRIGGER, fetchAllOptions);
}

export default function* optionsSaga() {
  yield all([watchOptions()]);
}
