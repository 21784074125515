import { all } from 'redux-saga/effects';
import userSaga from './user/saga';
import optionsSaga from './options/saga';
import userRegistrationSaga from './userRegistration/saga';
import onboardingSaga from './onboarding/saga';

export default function* rootSaga() {
  yield all([
    userSaga(),
    optionsSaga(),
    userRegistrationSaga(),
    onboardingSaga(),
  ]);
}
