import React from 'react';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import styles from '../styles.module.scss';

interface IStepperButtonsProps {
  handleBack?: () => void | Promise<void>;
  disableBack?: boolean;
  handleSkip?: () => void | Promise<void>;
  handleNext: () => void | Promise<void>;
  disabledNext?: boolean;
  isLastStep?: boolean;
  finishBtnText?: string;
  hideBackBtn?: boolean;
}

const StepperButtons: React.FC<IStepperButtonsProps> = (props) => {
  const {
    handleBack,
    handleNext,
    handleSkip,
    finishBtnText = 'Finish',
    disableBack,
    disabledNext,
    isLastStep,
    hideBackBtn,
  } = props;

  return (
    <div className={styles.stepperControllContainer}>
      {!hideBackBtn && (
        <SubmitButton
          label="Back"
          variant="text"
          disabled={disableBack}
          onClick={handleBack}
          className={`${styles.stepperBtn} ${styles.backBtn}`}
        />
      )}
      {handleSkip && (
        <SubmitButton
          label="Skip"
          variant="contained"
          onClick={handleSkip}
          className={`${styles.stepperBtn} ${styles.nextBtn}`}
        />
      )}
      <SubmitButton
        label={isLastStep ? finishBtnText : 'Next'}
        variant="contained"
        onClick={handleNext}
        disabled={disabledNext}
        className={`${styles.stepperBtn} ${
          disabledNext ? styles.disabled : styles.nextBtn
        }`}
      />
    </div>
  );
};

export default StepperButtons;
