import { all, put, takeEvery, call } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import ApiError from 'enums/apiError';
import userDomain from 'api/domains/user';
import dependantDomain from 'api/domains/dependents';
import enrollmentsDomain from 'api/domains/enrollments';
import planDomain from 'api/domains/plans';
import beneficiariesDomain from 'api/domains/beneficiaries';

import { fromExternalBeneficiaryToBeneficiary } from 'mappers/beneficiary/fromExternalBeneficiaryToBeneficiary';
import { fromEmployeeToProfileInterface } from 'mappers/fromEmployeeToProfileInterface';
import {
  loadUserShortData,
  loadUserDependents,
  loadUserEnrollments,
  loadUserPlans,
  loadUserBeneficiaries,
  loadUserProfile,
} from './routines';

function* loadShortUserDataWorker(action: Action<{ token?: string }>) {
  try {
    yield put(loadUserShortData.request());
    const token = action?.payload?.token;
    const { data } = yield userDomain.getEmployeeShortData(token);
    yield put(loadUserShortData.success(data));
  } catch (e) {
    switch (e.response.status) {
      case ApiError.Conflict:
        yield put(loadUserShortData.failure(ApiError.Conflict));
        break;
      case ApiError.Forbidden:
        yield put(loadUserShortData.failure(ApiError.Forbidden));
        break;
    }
  } finally {
    yield put(loadUserShortData.fulfill());
  }
}

function* loadUserDependentsWorker() {
  try {
    yield put(loadUserDependents.request());
    const { data } = yield dependantDomain.getList();
    yield put(loadUserDependents.success(data));
  } catch (e) {
    yield put(loadUserShortData.failure());
    console.error(e);
  } finally {
    yield put(loadUserDependents.fulfill());
  }
}

function* loadUserEnrollmentsWorker() {
  try {
    yield put(loadUserEnrollments.request());
    const { data } = yield call(enrollmentsDomain.getList);
    yield put(loadUserEnrollments.success(data));
  } catch (e) {
    yield put(loadUserEnrollments.failure());
    console.error(e);
  }
}

function* loadUserPlansWorker() {
  try {
    yield put(loadUserPlans.request());
    const { data } = yield call(planDomain.getList);
    yield put(loadUserPlans.success(data));
  } catch (e) {
    yield put(loadUserPlans.failure());
    console.error(e);
  }
}

function* loadUserBeneficiariesWorker() {
  try {
    const { data } = yield call(beneficiariesDomain.getList);
    yield put(
      loadUserBeneficiaries.success(
        data.map(fromExternalBeneficiaryToBeneficiary)
      )
    );
  } catch (e) {
    yield put(loadUserBeneficiaries.failure());
    console.error(e);
  }
}

function* loadUserProfileWorker() {
  try {
    yield put(loadUserProfile.request());
    const { data } = yield userDomain.getEmployeeData();
    yield put(loadUserProfile.success(fromEmployeeToProfileInterface(data)));
  } catch (e) {
    yield put(loadUserProfile.failure());
    console.error(e);
  }
}

function* watchLoadShortUserData() {
  yield takeEvery(loadUserShortData.trigger, loadShortUserDataWorker);
}

function* watchLoadUserDependents() {
  yield takeEvery(loadUserDependents.trigger, loadUserDependentsWorker);
}

function* watchLoadUserEnrollments() {
  yield takeEvery(loadUserEnrollments.trigger, loadUserEnrollmentsWorker);
}

function* watchLoadUserPlans() {
  yield takeEvery(loadUserPlans.trigger, loadUserPlansWorker);
}

function* watchLoadUserBeneficiaries() {
  yield takeEvery(loadUserBeneficiaries.trigger, loadUserBeneficiariesWorker);
}

function* watchLoadUserProfile() {
  yield takeEvery(loadUserProfile.trigger, loadUserProfileWorker);
}

export default function* userRegistrationSaga() {
  yield all([
    watchLoadShortUserData(),
    watchLoadUserDependents(),
    watchLoadUserEnrollments(),
    watchLoadUserPlans(),
    watchLoadUserBeneficiaries(),
    watchLoadUserProfile(),
  ]);
}
