import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';

import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import FormikPasswordInput from 'components/Forms/Formik/PasswordInput';

import IChangePasswordFormProps from 'models/IChangePasswordFormProps';
import { DEFAULT_PATH } from 'constants/routes';
import { password as passwordValidation } from 'helpers/validation';
import history from 'helpers/history';
import userDomain from 'api/domains/user';
import { loadCurrentUser } from 'redux/sagas/user/routines';
import styles from './styles.module.scss';

interface IFormValues {
  newPassword: string;
  repeatNewPassword: string;
}

interface IChangeTemporaryPasswordProps extends IChangePasswordFormProps {
  actions: {
    updateCurrentUser: typeof loadCurrentUser.success;
  };
}

const validationSchema = yup.object().shape({
  newPassword: passwordValidation,
  repeatNewPassword: passwordValidation.oneOf(
    [yup.ref('newPassword')],
    'Password must match'
  ),
});

const ChangeTemporaryPasswordForm: React.FC<IChangeTemporaryPasswordProps> = ({
  onError,
  onSuccess,
  actions,
}) => {
  const initialValues: IFormValues = {
    newPassword: '',
    repeatNewPassword: '',
  };

  const handleSubmit = async (values: IFormValues) => {
    try {
      await userDomain.changeTemporaryPassword({
        password: values.newPassword,
      });
      if (onSuccess) onSuccess();
      actions.updateCurrentUser({ passwordReset: false });
      history.push(DEFAULT_PATH);
    } catch (e) {
      console.error(e);
      if (onError) onError();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik: FormikProps<IFormValues>) => (
        <Form className={styles.form}>
          <Field
            component={FormikPasswordInput}
            fullWidth
            name="newPassword"
            label="New Password"
          />
          <Field
            component={FormikPasswordInput}
            fullWidth
            name="repeatNewPassword"
            label="Repeat New Password"
          />
          <SubmitButton
            className={styles.btn}
            disabled={formik.isSubmitting}
            label="Save"
          />
        </Form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    { updateCurrentUser: typeof loadCurrentUser.success }
  >({ updateCurrentUser: loadCurrentUser.success }, dispatch),
});

export default connect(null, mapDispatchToProps)(ChangeTemporaryPasswordForm);
