import React from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';

import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import FormikPasswordInput from 'components/Forms/Formik/PasswordInput';

import IChangePasswordFormProps from 'models/IChangePasswordFormProps';
import { DEFAULT_PATH } from 'constants/routes';
import { password as passwordValidation } from 'helpers/validation';
import history from 'helpers/history';
import userDomain from 'api/domains/user';
import styles from './styles.module.scss';

interface IFormValues {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

const validationSchema = yup.object().shape({
  oldPassword: passwordValidation,
  newPassword: passwordValidation,
  repeatNewPassword: passwordValidation.oneOf(
    [yup.ref('newPassword')],
    'Password must match'
  ),
});

const ChangePasswordForm: React.FC<IChangePasswordFormProps> = ({
  onError,
  onSuccess,
}) => {
  const initialValues: IFormValues = {
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  };

  const handleSubmit = async (values: IFormValues) => {
    try {
      await userDomain.changePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
      if (onSuccess) onSuccess();
      history.push(DEFAULT_PATH);
    } catch (e) {
      console.error(e);
      if (onError) onError();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik: FormikProps<IFormValues>) => (
        <Form className={styles.form}>
          <Field
            component={FormikPasswordInput}
            fullWidth
            name="oldPassword"
            label="Old Password"
          />
          <Field
            component={FormikPasswordInput}
            fullWidth
            name="newPassword"
            label="New Password"
          />
          <Field
            component={FormikPasswordInput}
            fullWidth
            name="repeatNewPassword"
            label="Repeat New Password"
          />
          <SubmitButton
            className={styles.btn}
            disabled={formik.isSubmitting}
            label="Save"
          />
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
