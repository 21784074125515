import React from 'react';
import Box from '@material-ui/core/Box';
import {
  FormControlLabel,
  Checkbox,
  withStyles,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';
import styles from './styles.module.scss';

interface IProps {
  checked?: boolean;
  onChange: (_: any, checked: boolean) => void;
  userPrompt: string;
}
const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);
const CheckboxField: React.FC<IProps> = ({ checked, userPrompt, onChange }) => {
  return (
    <Box mb="1em">
      <FormControlLabel
        control={
          <CustomCheckbox
            icon={
              <SvgIcon
                component={offIcon}
                width="16"
                height="17"
                viewBox="0 0 16 17"
              />
            }
            checkedIcon={
              <SvgIcon
                component={onIcon}
                width="16"
                height="17"
                viewBox="0 0 16 17"
              />
            }
            checked={checked}
            onChange={onChange}
            color="primary"
          />
        }
        label={
          <Typography className={styles.labelCheckBox}>{userPrompt}</Typography>
        }
      />
    </Box>
  );
};

export default CheckboxField;
