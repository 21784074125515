import React from 'react';

import { Plan } from 'types/plans';
import FileLink, { IFileLinkProps } from './index';

export interface IPlanFileLinkProps extends Omit<IFileLinkProps, 'fileId'> {
  plan: Plan;
  noFileMessage?: string;
}

const PlanFileLink: React.FC<IPlanFileLinkProps> = ({
  plan,
  linkLabel,
  noFileMessage,
}) => {
  return (
    <>
      {plan.fileId ? (
        <FileLink fileId={plan.fileId} linkLabel={linkLabel} />
      ) : (
        noFileMessage || null
      )}
    </>
  );
};

export default PlanFileLink;
