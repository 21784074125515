import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/icons/EventNote';
import { Query } from 'material-table';
import { ReactComponent as logIcon } from 'assets/log.svg';
import AuditLogUserType from 'enums/auditLogUserType';
import auditDomain from 'api/domains/audit';
import Table, { TableColumn } from 'components/Table/TableComponent';
import { IAuditLog, IAuditTableData } from 'types/auditLogs';
import LogDetailsModal from './LogDetailsModal';

import styles from './styles.module.scss';

interface IProps {
  columns: TableColumn<IAuditLog>[];
  userType: AuditLogUserType;
}

const AuditLogsTable: React.FC<IProps> = (props) => {
  const { userType, columns } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [currentLogId, setCurrentLogId] = useState<string>();

  const getAudits = (query: Query<any>) => {
    return new Promise<IAuditTableData>(async (resolve, reject) => {
      try {
        const { data } = await auditDomain.getList(query, userType);
        resolve({
          data: data.result,
          page: +data.page - 1,
          totalCount: +data.totalCount,
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  const handleOpenModal = (id: string) => {
    setModalOpen(true);
    setCurrentLogId(id);
  };

  return (
    <Box className={styles.auditTableContainer}>
      <Table
        columns={columns}
        data={(query) => getAudits(query)}
        options={{
          filtering: true,
          search: false,
          actionsColumnIndex: -1,
          toolbar: false,
        }}
        actions={[
          {
            icon: () => (
              <SvgIcon
                component={logIcon}
                width="25"
                height="25"
                viewBox="0 0 25 25"
              />
            ),
            tooltip: 'Log Details',
            onClick: (_, data) => handleOpenModal(data.id),
          },
        ]}
      />
      {currentLogId && (
        <LogDetailsModal
          isOpen={modalOpen}
          handleClose={() => setModalOpen(false)}
          logId={currentLogId}
          userType={userType}
        />
      )}
    </Box>
  );
};

export default AuditLogsTable;
