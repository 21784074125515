import React, { useState, useCallback } from 'react';
import { Box, Grid, IconButton, Paper, Tab, Tabs } from '@material-ui/core';

import { Close } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import Input from 'components/Forms/Input/InputComponent';
import IBasicOption from 'models/IBasicOption';
import Selector from 'components/Forms/Selector/SelectorComponent';
import { AppStoreInterface } from 'store/app';
import onboardingDomain from 'api/domains/onboarding';
import { PayrollMethodValue, AmountType, PayrollErrors } from './types';

import styles from './styles.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt="1em">{children}</Box>}
    </div>
  );
}

interface Props {
  name: string;
  amount: string;
  method: number;
  index: number;
  routingNumber: string;
  accountNumber: string;
  accountType: number | string;
  amountType: number;
  error?: PayrollErrors;
  remainingAmount?: boolean;
  setAccountType: (value?: IBasicOption<any>) => void;
  setAmountType: (value?: IBasicOption<any>) => void;
  setRoutingNumber: (value: string) => void;
  setAccountNumber: (value: string) => void;
  handleAmountChange: (value: string) => void;
  deletePayrollMethod: (mapKey: string) => void;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const PayrollForm: React.FC<Props> = ({
  name,
  amount,
  method,
  routingNumber,
  accountNumber,
  accountType,
  amountType,
  index,
  error,
  handleAmountChange,
  setAccountNumber,
  setRoutingNumber,
  deletePayrollMethod,
  setAccountType,
  setAmountType,
  handleTabChange,
  remainingAmount,
}) => {
  const { payrollAmountType, payrollAccountType } = useSelector(
    (state: AppStoreInterface) => state.options
  );
  const { payrollMethods } = useSelector(
    (state: AppStoreInterface) => state.user
  );

  const [bankName, setBankName] = useState('');

  useCallback(() => {
    if (payrollAmountType.length > 0) {
      setAmountType(
        payrollAmountType.find(({ value }) => value === AmountType.Dollars)
      );
    }
  }, [payrollAmountType, setAmountType]);

  const fetchBankNameByNumber = () => {
    (async () => {
      try {
        const { data } = await onboardingDomain.fetchBankNameByNumber(
          routingNumber
        );
        if (data.bankName) {
          setBankName(data.bankName);
        } else {
          setBankName('');
        }
      } catch (err) {
        setBankName('');
        console.error(err);
      }
    })();
  };

  const payrollAmountTypeOptions = !remainingAmount
    ? payrollAmountType
    : [
        ...payrollAmountType,
        { value: AmountType.RemainingAmount, label: 'Remaining amount' },
      ];

  return (
    <>
      <Box>
        <Box className={styles.deleteButton}>
          {index !== 0 && (
            <IconButton onClick={() => deletePayrollMethod(name)}>
              <Close className={styles.closeIcon} />
            </IconButton>
          )}
        </Box>
        <Paper className={styles.tabsHeader}>
          <Tabs
            className={styles.tabs}
            value={method}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            classes={{
              indicator: styles.indicator,
            }}
          >
            {payrollMethods &&
              payrollMethods.length > 0 &&
              payrollMethods.map(({ value, label }, i) => (
                <Tab
                  key={`method.${i}`}
                  value={value}
                  label={label.replace('_', ' ')}
                  className={styles.tab}
                  classes={{
                    selected: styles.selected,
                  }}
                />
              ))}
          </Tabs>
        </Paper>
        {payrollMethods &&
          payrollMethods.length > 0 &&
          payrollMethods.map((payrollMethod, i) => (
            <TabPanel
              key={`tabPanel-${i}`}
              value={payrollMethod.value}
              index={Number(method)}
            >
              <Grid container spacing={3}>
                <Grid xs={6} item>
                  <NumberFormat
                    isNumericString
                    name={name}
                    customInput={Input}
                    thousandSeparator={amountType === AmountType.Dollars}
                    prefix={amountType === AmountType.Dollars ? '$' : ''}
                    suffix={amountType === AmountType.Percentage ? '%' : ''}
                    value={
                      amountType === AmountType.RemainingAmount ? '' : amount
                    }
                    fullWidth
                    variant="outlined"
                    label="Amount"
                    allowNegative={false}
                    onValueChange={({ value }) => handleAmountChange(value)}
                    error={error?.amount}
                    helperText={error?.amount}
                    readOnly={amountType === AmountType.RemainingAmount}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Selector
                    placeholder="Amount type"
                    value={payrollAmountTypeOptions.find(
                      (type) => type.value === amountType
                    )}
                    options={payrollAmountTypeOptions}
                    onChange={setAmountType}
                    withoutDefaultOption
                    disabled={remainingAmount}
                  />
                </Grid>
              </Grid>
              {payrollMethod.value === PayrollMethodValue.Deposit && (
                <>
                  <NumberFormat
                    className={styles.routingNumber}
                    customInput={Input}
                    name={name}
                    fullWidth
                    allowLeadingZeros
                    value={routingNumber}
                    variant="outlined"
                    label="Bank routing number"
                    onValueChange={({ value }) => setRoutingNumber(value)}
                    onBlur={fetchBankNameByNumber}
                    error={error?.routingNumber}
                    helperText={bankName}
                  />
                  <NumberFormat
                    customInput={Input}
                    name={name}
                    fullWidth
                    allowLeadingZeros
                    value={accountNumber}
                    variant="outlined"
                    label="Bank account number"
                    onValueChange={({ value }) => setAccountNumber(value)}
                    error={error?.accountNumber}
                    helperText={error?.accountNumber}
                  />
                  <Selector
                    name={name}
                    placeholder="Account type"
                    value={payrollAccountType.find(
                      (type) => type.value === accountType
                    )}
                    options={payrollAccountType}
                    onChange={setAccountType}
                    error={!!error?.accountType}
                    helperText={error?.accountType}
                  />
                </>
              )}
            </TabPanel>
          ))}
      </Box>
    </>
  );
};

export default PayrollForm;
