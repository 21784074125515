import RoleType from 'enums/roleType';
import { UserGeneralInfoInterface } from 'types/user';
import IColorConfiguration from 'models/IColorConfiguration';

export interface UserStoreInterface extends UserGeneralInfoInterface {
  isAuthenticated: boolean;
  loading: boolean;
  logining: boolean;
  roleType: RoleType;
  error: string | null;
  passwordAskModalVisible: boolean;
  colorConfiguration: IColorConfiguration;
}

export const initUserStore = (): UserStoreInterface => ({
  id: null,
  agencyLogo: null,
  agencyWebsite: null,
  clientLogo: null,
  clientName: null,
  clientWebsite: null,
  firstName: null,
  lastName: null,
  middleName: null,
  agencyName: null,
  openEnrollmentClosedDate: null,
  openEnrollmentEndDate: null,
  openEnrollmentId: null,
  payPeriods: null,
  isAuthenticated: false,
  payrollEnabled: null,
  loading: true,
  logining: false,
  error: null,
  roleType: RoleType.User,
  registrationStep: null,
  onboardingStep: null,
  registrationStepCode: null,
  cobra: null,
  impersonatingUserId: null,
  testUser: null,
  passwordReset: false,
  securityQuestionReset: false,
  passwordAskModalVisible: false,
  newHire: null,
  eligibilityDate: null,
  authenticationMethod: null,
  eoi: null,
  payrollMethods: null,
  eeoEnabled: null,
  enrollmentsEnabled: null,
  requireSsn: null,
  colorConfiguration: {},
  enableOnboarding: null,
  links: [],
  i9Enabled: null,
  w4Enabled: null,
  workHoursRequired: false,
  hideDocumentWarnings: false,
  maritalStatusId: null,
  medicalEnrolled: false,
  midyearChangesData: null,
  mblPrivacyPolicyAgreement: null,
  enableMidyearChanges: false,
});
