import React from 'react';
import { Typography, Box } from '@material-ui/core';

import StepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import ICommonRegistrationStepProps from 'models/ICommonRegistrationStepProps';

import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import styles from './styles.module.scss';

type SummaryStepProps = Pick<
  ICommonRegistrationStepProps,
  'moveBack' | 'moveForward'
>;

const SummaryStep: React.FC<SummaryStepProps> = (props) => {
  return (
    <>
      <ContentTableBox title="Summary" index={8} count={8} isBox>
        <Box className={styles.summaryContainerBlock}>
          <Typography
            className={styles.mainLabel}
            variant="h3"
            gutterBottom
            color="primary"
          >
            Congratulations!
          </Typography>
          <Typography
            className={styles.secondaryLabel}
            variant="h5"
            gutterBottom
          >
            You have successfully completed benefit enrollment in MyBenefitLink
          </Typography>
        </Box>
      </ContentTableBox>
      <StepperButtons
        handleNext={props.moveForward}
        handleBack={props.moveBack}
        isLastStep
        finishBtnText="Continue"
      />
    </>
  );
};

export default SummaryStep;
