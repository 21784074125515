import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import { IEmployeePersonal } from 'types/employee';
import { IProfileCard } from 'types/profileCard';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as lockIcon } from 'assets/lock.svg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import styles from './styles.module.scss';

interface IProps {
  personalData: IEmployeePersonal | null;
  handleHidePersonalData: () => void;
  handleOpenModal: () => void;
  handleShowPersonalData: (password: string) => Promise<void>;
  card: IProfileCard;
  isPasswordPrompt: boolean;
}

const ProfileCardItem: React.FC<IProps> = (props) => {
  const {
    handleHidePersonalData,
    handleOpenModal,
    handleShowPersonalData,
    isPasswordPrompt,
    personalData,
    card,
  } = props;

  return (
    <ContentContainer
      title={card.title}
      isBox
      withoutMargin={card.title === 'Personal Information'}
    >
      <TableContainer component={Box}>
        <Table className={styles.filesTable}>
          <TableBody>
            {card.rows.map((row, i) =>
              row.value ? (
                <TableRow key={i} className={styles.infoRow}>
                  <TableCell>
                    <Typography variant="body2" className={styles.name}>
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box className={styles.cell}>
                      <Typography variant="body2" className={styles.info}>
                        {row.value}
                      </Typography>
                      {row.isSecured && personalData ? (
                        <Button
                          className={styles.hideBtn}
                          onClick={handleHidePersonalData}
                          disableElevation
                          variant="text"
                        >
                          Hide
                        </Button>
                      ) : null}
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={i} className={styles.infoRow}>
                  <TableCell>
                    <Typography variant="body2" className={styles.name}>
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        isPasswordPrompt
                          ? handleOpenModal()
                          : handleShowPersonalData('')
                      }
                      className={styles.lockBtn}
                    >
                      <SvgIcon
                        component={lockIcon}
                        className={styles.icon}
                        width="19"
                        height="22"
                        viewBox="0 0 19 22"
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ContentContainer>
  );
};

export default ProfileCardItem;
