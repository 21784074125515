import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';

const Spinner: React.FC<CircularProgressProps> = (props) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <CircularProgress {...props} />
    </Box>
  );
};

export default Spinner;
