import React, { useRef, useState, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';

import Signature from 'components/Signature';
import StepperButtonsComponent from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import ICommonRegistrationStepProps from 'models/ICommonRegistrationStepProps';
import fileDomain from 'api/domains/file';
import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';

const AddSignature: React.FC<ICommonRegistrationStepProps> = (props) => {
  const { moveBack, moveForward, setError, setIsLoading } = props;

  const [signatureStored, setSignatureStored] = useState(false);
  const sigCanvas = useRef<SignaturePad>(null);

  useEffect(() => {
    const handleResize = () => {
      setSignatureStored(false);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const onSaveSignature = async (canvas: HTMLCanvasElement) => {
    try {
      setIsLoading(true);
      const base64Signature = canvas.toDataURL();
      await fileDomain.signature({ signature: base64Signature });
      moveForward();
    } catch (e) {
      console.error(e);
      setError('Server error!');
    } finally {
      setIsLoading(false);
    }
  };

  const onSave = () => {
    if (sigCanvas.current) {
      const canvas = sigCanvas.current.getTrimmedCanvas();
      onSaveSignature(canvas);
    }
    return;
  };

  const check = () => {
    if (sigCanvas.current && signatureStored) {
      const canvas = sigCanvas.current?.getTrimmedCanvas();
      return canvas.width === 1 && canvas.height === 1;
    }
    return true;
  };

  return (
    <>
      <ContentTableBox title="Signature" index={7} count={8} isBox>
        <Signature
          sigCanvas={sigCanvas}
          setSignatureStored={setSignatureStored}
        />
      </ContentTableBox>
      <StepperButtonsComponent
        handleNext={onSave}
        handleBack={moveBack}
        disabledNext={check()}
      />
    </>
  );
};

export default AddSignature;
