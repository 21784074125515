import React from 'react';
import {
  Select,
  FormLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  SvgIcon,
  InputBase,
} from '@material-ui/core';
import IBasicOption from 'models/IBasicOption';
import { ReactComponent as selectIcon } from 'assets/select.svg';
import styles from './styles.module.scss';

export interface SelectorPropsInterface<T = any> {
  options: IBasicOption<T>[];
  onChange?: (value?: IBasicOption<T>) => void | Promise<void>;
  value?: IBasicOption | null;
  className?: string;
  placeholder?: string;
  isLabeled?: boolean;
  disabled?: boolean;
  defaultValueLabel?: string;
  name?: string;
  helperText?: string;
  error?: boolean;
  withoutDefaultOption?: boolean;
  typeInput?: string;
}
const CustomInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    input: {
      padding: '15px 14px',
      borderRadius: '4px',
      position: 'relative',
      border: '1px solid #E1E2E7',
      '& .Mui-error': {
        '& fieldset': {
          border: '1px solid #E51212',
        },
      },
      '&:focus': {
        background: 'transparent',
        borderRadius: '4px',
        border: '1px solid #4979C0',
      },
      '&:hover': {
        background: 'transparent',
        borderRadius: '4px',
        border: '1px solid #4979C0',
      },
    },
  })
)(InputBase);

const CustomSelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'auto',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#3C4257',
    },
  })
)(Select);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    control: {
      marginTop: '10px',
      marginBottom: '10px',
      width: '100%',
    },
    errorInput: {
      '& .MuiSelect-outlined': {
        border: '1px solid #E51212',
        background: '#FFE3E3',
      },
    },
    icon: {
      position: 'absolute',
      right: '0',
      marginTop: '10x',
      marginRight: '25px',
      display: 'inline-block',
      width: '15px',
      height: '7px',
      cursor: 'pointer',
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#3C4257',
    },
    dropdownStyle: {
      maxHeight: '150px',
      '& .MuiList-padding': {
        padding: 0,
      },
      borderRadius: '4px',
      '& .Mui-selected': {
        borderRadius: '4px',
        background: '#A8E0FF',
      },
    },
    cssOutlinedInputInfo: {
      '& .MuiSelect-outlined': {
        borderColor: '#90B9F5',
      },
    },
    cssOutlinedInputWarning: {
      '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
        borderColor: '#E51212',
      },
    },
    notchedOutline: {},
    cssFocused: {},
    error: {},
    disabled: {},
  })
);

const Selector: React.FC<SelectorPropsInterface> = (props) => {
  const {
    options,
    name,
    onChange,
    placeholder,
    value,
    disabled,
    defaultValueLabel,
    error,
    helperText,
    withoutDefaultOption,
    typeInput,
  } = props;

  const defaultOption: IBasicOption = {
    label: defaultValueLabel || 'None',
    value: 'None',
  };

  const optionsWithDefault: IBasicOption[] = withoutDefaultOption
    ? [...options]
    : [defaultOption, ...options];
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (onChange && typeof onChange) {
      if (event.target.value === defaultOption.value) {
        return onChange();
      }

      const option = options.find(
        (option) => event.target.value === option.value
      );
      if (option) {
        return onChange(option);
      }
    }
  };
  const getStyle = (): string | undefined => {
    if (!typeInput) return '';
    switch (typeInput) {
      case 'info':
        return classes.cssOutlinedInputInfo;
      case 'warning':
        return classes.cssOutlinedInputWarning;
      default:
        return '';
    }
  };

  return (
    <>
      <FormLabel component="legend" className={classes.label}>
        {placeholder}
      </FormLabel>
      <FormControl variant="outlined" className={classes.control}>
        <CustomSelect
          IconComponent={() => (
            <SvgIcon
              className={classes.icon}
              component={selectIcon}
              width="11"
              height="6"
              viewBox="0 0 11 6"
            />
          )}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            classes: { paper: classes.dropdownStyle },
          }}
          displayEmpty
          input={
            <CustomInput
              className={`${error ? classes.errorInput : ''} ${getStyle()}`}
            />
          }
          inputProps={{
            classes: {
              root: getStyle(),
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
          name={name}
          value={value ? value.value : defaultOption.value}
          onChange={handleChange}
          disabled={disabled}
          error={error}
        >
          {optionsWithDefault.map((option) => (
            <MenuItem
              className={styles.option}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </CustomSelect>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default Selector;
