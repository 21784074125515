import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { FormikHelpers, Formik, Form, FormikProps, Field } from 'formik';
import * as yup from 'yup';

import FormikPasswordInput from 'components/Forms/Formik/PasswordInput';
import ModalWindow, {
  ModalWindowPropsInterface,
} from 'components/Modal/ModalWindowComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';

import Storage from 'helpers/storage';
import userDomain from 'api/domains/user';
import history from 'helpers/history';
import { Actions as UserActions } from 'redux/reducers/user/actions';
import { LOCATION_BEFORE_SESSION_EXPIRATION } from 'constants/storeKeys';

import styles from './styles.module.scss';

interface IProps
  extends Pick<ModalWindowPropsInterface, 'isOpen' | 'handleClose'> {
  logout: () => void;
  loadCurrentUser: () => void;
  setShowPasswordAskModal: typeof UserActions.setShowPasswordAskModal;
}

interface IFormikValues {
  password: string;
}

const validationSchema = yup.object().shape({
  password: yup.string().required('Field is required'),
});

const PasswordAskModal: React.FC<IProps> = (props) => {
  const {
    isOpen,
    handleClose,
    logout,
    loadCurrentUser,
    setShowPasswordAskModal,
  } = props;
  const initValues: IFormikValues = { password: '' };

  const handleSubmit = async (
    values: IFormikValues,
    helpers: FormikHelpers<IFormikValues>
  ) => {
    try {
      const {
        data: { success },
      } = await userDomain.verifyPassword(values.password);

      if (success) {
        const locationBeforeExpiration = Storage.get(
          LOCATION_BEFORE_SESSION_EXPIRATION
        );
        setShowPasswordAskModal(false);
        if (locationBeforeExpiration) {
          loadCurrentUser();
          history.push(locationBeforeExpiration);
          Storage.remove(LOCATION_BEFORE_SESSION_EXPIRATION);
        }
      } else {
        helpers.setFieldError('password', 'Incorrect password');
      }
    } catch (err) {
      console.error(err.response.data.developerMessage);
    }
  };

  return (
    <ModalWindow
      isOpen={isOpen}
      handleClose={handleClose}
      className={styles.passwordAskModal}
      withoutPadding
      round
    >
      <Box className={styles.main}>
        <Typography className={styles.title}>
          Your session has expired, please enter your password
        </Typography>
        <Formik
          initialValues={initValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formik: FormikProps<IFormikValues>) => (
            <Form>
              <Field
                component={FormikPasswordInput}
                label="Password"
                name="password"
                fullWidth
              />
              <Box display="flex" justifyContent="flex-start">
                <SubmitButton
                  disabled={formik.isSubmitting}
                  variant="contained"
                  label="Submit"
                  className={styles.submitBtn}
                />
                <SubmitButton
                  label="Logout"
                  variant="text"
                  color="primary"
                  type="button"
                  onClick={() => {
                    logout();
                    setShowPasswordAskModal(false);
                  }}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ModalWindow>
  );
};

export default PasswordAskModal;
