import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import { Box, Button, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import SectionTitle from 'components/SectionTitle';
import Feedbacker from 'components/Feedbacker';
import Selector from 'components/Forms/Selector/SelectorComponent';
import Backdrop from 'components/Backdrop';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';

import IBasicOption from 'models/IBasicOption';
import statusDashboardDomain from 'api/domains/statusDashboard';
import openEnrollmentDomain from 'api/domains/openEnrollment';
import fromOpenEnrollmentToBasicOption from 'mappers/enrollments/fromOpenEnrollmentToBasicOption';
import {
  IOpenEnrollmentPayload,
  IOpenEnrollmentsUserInfo,
} from 'types/statusDashboard';
import { IPlanGroup } from 'types/plans';
import { downloadFromBlob } from 'helpers/downloadFileFromBlob';
import mimeToExtension from 'helpers/mimeToExtension';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import OpenEnrollmentModal from './OpenEnrollmentModal';
import StatusDashboardTable from './StatusDashboardTable';

import styles from './styles.module.scss';

const DefaultMessage: React.FC<{ message: string }> = ({ message }) => (
  <Box className={styles.defaultMessageContainer}>
    <Typography variant="h5" className={styles.placeholder}>
      {message}
    </Typography>
  </Box>
);
const getOpenEnrollmentData = async () => {
  const { data } = await openEnrollmentDomain.get();
  return data;
};
const StatusDashboard: React.FC = () => {
  const [openEnrollments, setOpenEnrollments] = useState<
    IBasicOption<string>[]
  >([]);
  const [apiError, setApiError] = useState<string>();
  const [currentOpenEnrollment, setCurrentOpenEnrollment] = useState<
    IBasicOption<string>
  >();
  const [planTypes, setPlanTypes] = useState<IPlanGroup[]>([]);
  const [usersData, setUsersData] = useState<IOpenEnrollmentsUserInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [EnrollmentModal, setEnrollmentModal] = useState<boolean>(false);

  const clearFeedbackError = () => setApiError(undefined);

  const { data: openEnrollmentData, refetch } = useQuery(
    'openEnrollmentData',
    getOpenEnrollmentData,
    {
      refetchOnWindowFocus: false,
    }
  );
  const setTemporaryApiError = useCallback(
    (error: string) => {
      setApiError(error);
      setTimeout(clearFeedbackError, 5000);
    },
    [setApiError]
  );

  const fetchPlanTypes = useCallback(
    async (id: string) => {
      const { data } = await statusDashboardDomain.getPlans(id);
      setPlanTypes(data);
    },
    [setPlanTypes]
  );

  const fetchUserData = useCallback(
    async (id: string) => {
      const { data } = await statusDashboardDomain.getOne(id);
      setUsersData(data);
    },
    [setUsersData]
  );

  const handleSubmit = async (values: IOpenEnrollmentPayload) => {
    try {
      await openEnrollmentDomain.update(values);
      refetch();
    } catch (e) {
      console.error(e);
    }
  };
  const fetchCurrentOpenEnrollmentData = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true);
        await Promise.all([fetchPlanTypes(id), fetchUserData(id)]);
      } catch (e) {
        console.error(e);
        setApiError(
          'Cannot fetch information about this open enrollment period'
        );
      } finally {
        setIsLoading(false);
      }
    },
    [fetchPlanTypes, fetchUserData]
  );

  const onChangeOpenEnrollment = async (newValue?: IBasicOption<string>) => {
    setCurrentOpenEnrollment(newValue);
    if (!newValue) {
      setUsersData([]);
      setPlanTypes([]);
      return;
    }
    fetchCurrentOpenEnrollmentData(newValue.value);
  };
  const fetchOpenEnrollments = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await statusDashboardDomain.getList();
      const currentOpenEnrollment = data.find(
        (openEnrollment) => openEnrollment.current === '1'
      );
      const options = data.map(fromOpenEnrollmentToBasicOption);
      if (currentOpenEnrollment) {
        setCurrentOpenEnrollment(
          fromOpenEnrollmentToBasicOption(currentOpenEnrollment)
        );
        await fetchCurrentOpenEnrollmentData(currentOpenEnrollment.id);
      }
      setOpenEnrollments(options);
    } catch (e) {
      console.error(e);
      setTemporaryApiError('Cannot get open enrollments from server');
    } finally {
      setIsLoading(false);
    }
  }, [
    setOpenEnrollments,
    setTemporaryApiError,
    fetchCurrentOpenEnrollmentData,
  ]);

  const onDownloadClick = async () => {
    try {
      if (!currentOpenEnrollment) return;
      const { data } = await statusDashboardDomain.download(
        currentOpenEnrollment.value
      );
      const extension = mimeToExtension(data.type);
      downloadFromBlob(data, `${currentOpenEnrollment.label}.${extension}`);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchOpenEnrollments();
  }, [fetchOpenEnrollments]);

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Status Dashboard</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <SectionTitle title="Status Dashboard" />
        <Box className={styles.main}>
          <ContentContainer
            title="Enrollments"
            button={
              planTypes.length && usersData.length ? (
                <SubmitButton
                  label="Download"
                  color="secondary"
                  variant="contained"
                  className={styles.btnHeader}
                  onClick={onDownloadClick}
                />
              ) : null
            }
          >
            <Box className={styles.box}>
              {isLoading ? null : (
                <Box>
                  <Box className={styles.flexBox}>
                    <Box className={styles.selector}>
                      <Selector
                        options={openEnrollments}
                        value={currentOpenEnrollment}
                        isLabeled
                        placeholder="Open enrollment"
                        onChange={onChangeOpenEnrollment}
                      />
                    </Box>
                    {Boolean(openEnrollmentData?.settings?.allowHrOe) && (
                      <Box className={styles.container}>
                        <Box className={styles.alert}>
                          <Box className={styles.mainContainer}>
                            <Box className={styles.textContainer}>
                              <Typography
                                className={styles.titleOpenEnrollment}
                              >
                                Open Enrollment
                              </Typography>
                              {openEnrollmentData?.settings
                                ?.openEnrollmentEndDate &&
                              !openEnrollmentData?.settings
                                ?.openEnrollmentClosedDate ? (
                                <Typography>
                                  {' '}
                                  Ends on{' '}
                                  {
                                    openEnrollmentData?.settings
                                      ?.openEnrollmentEndDate
                                  }{' '}
                                </Typography>
                              ) : (
                                <Typography>Ready to Start </Typography>
                              )}
                            </Box>
                          </Box>
                          <Box>
                            <Button
                              className={styles.editEnrollmentBtn}
                              color="primary"
                              variant="contained"
                              onClick={() => setEnrollmentModal(true)}
                            >
                              {openEnrollmentData?.settings
                                ?.openEnrollmentEndDate &&
                              !openEnrollmentData?.settings
                                ?.openEnrollmentClosedDate
                                ? 'Change Date'
                                : 'Start'}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {planTypes.length && usersData.length ? (
                    <>
                      <StatusDashboardTable
                        planTypes={planTypes}
                        usersData={usersData}
                      />
                    </>
                  ) : (
                    <DefaultMessage
                      message={
                        currentOpenEnrollment
                          ? "Sorry, we don't information about this Open Enrollment Period"
                          : 'Please choose Open Enrollment period'
                      }
                    />
                  )}
                </Box>
              )}
            </Box>
          </ContentContainer>
          <Feedbacker
            severity="error"
            feedbackMessage={apiError || ''}
            open={!!apiError}
            clearFeedback={clearFeedbackError}
          />
          <OpenEnrollmentModal
            isOpen={EnrollmentModal}
            handleClose={() => setEnrollmentModal(false)}
            plans={openEnrollmentData?.plans}
            periods={openEnrollmentData?.periods}
            handleSubmitModal={handleSubmit}
          />
          <Backdrop open={isLoading} />
        </Box>
      </Box>
    </>
  );
};

export default StatusDashboard;
