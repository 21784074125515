import React from 'react';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as fileIcon } from 'assets/file.svg';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import Backdrop from 'components/Backdrop';
import { Plan } from 'types/plans';
import CustomFields from './CustomFIelds';
import Disclaimer from './Disclaimer';
import CoverageTable from './CoverageTable';

import styles from './styles.module.scss';

interface IProps {
  plan: Plan;
  handleOpenFile: () => Promise<void>;
  isFileLoading: boolean;
  employeeId?: string | number | null;
}

const OtherPlan: React.FC<IProps> = ({
  plan,
  handleOpenFile,
  isFileLoading,
  employeeId,
}) => {
  return (
    <>
      <Backdrop open={isFileLoading} />
      {plan.fileId && (
        <Box className={styles.fileLinkContainer}>
          <SvgIcon
            component={fileIcon}
            width="19"
            height="21"
            viewBox="0 0 19 21"
          />
          <Link className={styles.fileLink} onClick={handleOpenFile}>
            View Benefits Summary
          </Link>
        </Box>
      )}
      <Box className={styles.planNameContainer}>
        <Typography variant="h6" className={styles.title}>
          <PlanIcons planTypeId={Number(plan.planTypeId)} />
          {plan.title}
        </Typography>
        {plan.customFields && <CustomFields customFields={plan.customFields} />}
        <CoverageTable plan={plan} asTable employeeId={employeeId} />
        <Typography className={styles.mblComment} variant="subtitle1">
          {plan.mblComment}
        </Typography>
      </Box>
      <Disclaimer />
    </>
  );
};

export default OtherPlan;
