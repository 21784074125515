/* eslint-disable */
import ApiError from 'enums/apiError';
import {
  loadUserShortData,
  loadUserDependents,
  loadUserEnrollments,
  loadUserPlans,
  loadUserBeneficiaries,
  loadUserProfile,
} from 'redux/sagas/userRegistration/routines';
import {
  initUserRegistrationStore,
  UserRegistrationStoreInterface,
} from 'store/registration';
import * as actionTypes from './actionTypes';
import { ActionTypes } from './actions';
import { DependentInterface } from '../../../types/dependents';

const userRegistrationReducer = (
  state = initUserRegistrationStore(),
  action: ActionTypes
): UserRegistrationStoreInterface => {
  switch (action.type) {
    case actionTypes.ADD_ACCOUNT_DATA:
      return {
        ...state,
        account: Object.assign({ ...state.account }, action.payload),
      };

    case actionTypes.ADD_PROFILE_DATA:
      return {
        ...state,
        profile: Object.assign({ ...state.profile }, action.payload),
      };

    case actionTypes.ADD_DEPENDENT:
      return {
        ...state,
        dependents: [...state.dependents, action.payload],
      };

    case actionTypes.EDIT_DEPENDENT:
      return {
        ...state,
        dependents: state.dependents.map((dependent) => {
          if (action.payload.id === dependent.id) {
            return action.payload;
          }
          return { ...dependent };
        }),
      };

    case actionTypes.DELETE_DEPENDENT:
      return {
        ...state,
        dependents: [...state.dependents].filter(
          (dependent) => dependent.id !== action.payload.id
        ),
      };

    case loadUserShortData.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case loadUserShortData.SUCCESS:
      return {
        ...state,
        profile: Object.assign({ ...state.profile }, action.payload),
        registrationTokenError: null,
      };

    case loadUserShortData.FAILURE:
      return {
        ...state,
        registrationTokenError: action.payload as ApiError,
      };

    case loadUserShortData.FULFILL:
      return {
        ...state,
        loading: false,
      };

    case loadUserDependents.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case loadUserDependents.SUCCESS:
      return {
        ...state,
        dependents: action.payload as DependentInterface[],
      };

    case loadUserDependents.FULFILL:
      return {
        ...state,
        loading: false,
      };

    case loadUserEnrollments.SUCCESS:
      return {
        ...state,
        enrollmentsIsLoading: false,
        enrollments: action.payload as any,
      };

    case loadUserEnrollments.REQUEST:
      return {
        ...state,
        enrollmentsIsLoading: true,
      };

    case loadUserPlans.SUCCESS:
      return {
        ...state,
        plans: action.payload as any,
        plansIsLoading: false,
      };

    case loadUserEnrollments.FAILURE:
      return {
        ...state,
        enrollmentsIsLoading: false,
        enrollments: [],
      };

    case loadUserPlans.REQUEST:
      return {
        ...state,
        plansIsLoading: true,
      };

    case loadUserPlans.FAILURE:
      return {
        ...state,
        plans: [],
        plansIsLoading: false,
      };

    case loadUserBeneficiaries.SUCCESS:
      return {
        ...state,
        beneficiaries: action.payload as any,
        loading: false,
      };

    case loadUserBeneficiaries.FAILURE:
      return {
        ...state,
        beneficiaries: [],
        loading: false,
      };

    case loadUserProfile.REQUEST:
      return {
        ...state,
        profileIsLoading: true,
      };

    case loadUserProfile.SUCCESS:
      return {
        ...state,
        profile: action.payload as any,
        profileIsLoading: false,
      };

    case loadUserProfile.FAILURE:
      return {
        ...state,
        profileIsLoading: false,
      };

    default:
      return state;
  }
};

export default userRegistrationReducer;
