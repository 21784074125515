enum ExternalRegistrationStep {
  RegistrationIsFinished = 0,
  WelcomeEmail = 1,
  Account = 2,
  Profile = 5,
  Dependents = 6,
  Enrollments = 7,
  Beneficiaries = 8,
  AdditionalInfo = 9,
  Signature = 10,
}

export default ExternalRegistrationStep;
