export const firstDocumentsList = [
  "Driver's license or ID card issued by a State or outlying possession of the United States provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address",
  'ID card issued by federal, state or local government agencies or entities, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address',
  'School ID card with a photograph',
  "Voter's registration card",
  'U.S. Military card or draft record',
  "Military dependent's ID card",
  'U.S. Coast Guard Merchant Mariner Card',
  'Native American tribal document',
  "Driver's license issued by a Canadian government authority",
];

export const secondDocumentsList = [
  'A Social Security Account Number card, unless the card includes one of the following restrictions:',
  [
    'NOT VALID FOR EMPLOYMENT',
    'VALID FOR WORK ONLY WITH INS AUTHORIZATION',
    'VALID FOR WORK ONLY WITH DHS AUTHORIZATION',
  ],
  'Certification of Birth Abroad issued by the Department of State (Form FS-545)',
  'Certification of Report of Birth issued by the Department of State (Form DS-1350)',
  'Original or certified copy of birth certificate issued by a State, county, municipal authority, or territory of the United States bearing an official seal',
  'Native American tribal document',
  'U.S. Citizen ID Card (Form I-197)',
  'Identification Card for Use of Resident Citizen in the United States (Form I-179)',
  'Employment authorization document issued by the Department of Homeland Security',
];
