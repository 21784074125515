import React from 'react';
import { Typography, Button, IconButton, SvgIcon } from '@material-ui/core';
import { ReactComponent as lockIcon } from 'assets/lock.svg';

import styles from './styles.module.scss';

export interface IPersonalInfoProps {
  personalInfo: string;
  handleHidePersonalData: () => void;
  isLocked: boolean;
  onLockIconClick: () => void | Promise<void>;
}

const PersonalInfo: React.FC<IPersonalInfoProps> = (props) => {
  const {
    personalInfo,
    handleHidePersonalData,
    isLocked,
    onLockIconClick,
  } = props;

  const unlockedPersonalInfo = (
    <>
      <Typography className={styles.infoAnswer} variant="body2">
        {personalInfo}
      </Typography>
      <Button
        className={styles.hideBtn}
        onClick={handleHidePersonalData}
        disableElevation
        variant="text"
      >
        Hide
      </Button>
    </>
  );

  const lockedPersonalInfo = (
    <IconButton onClick={onLockIconClick} className={styles.lockBtn}>
      <SvgIcon
        component={lockIcon}
        className={styles.icon}
        width="19"
        height="22"
        viewBox="0 0 19 22"
      />
    </IconButton>
  );

  return <>{!isLocked ? unlockedPersonalInfo : lockedPersonalInfo}</>;
};

export default PersonalInfo;
