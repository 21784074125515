export const ENROLLMENT_PERIOD_PLAN_WARNING =
  'At this time you cannot enroll in this plan, because it will renew prior to your eligibility date. Please come back during open enrollment period.';

export const WILL_BE_ELIGIBLE = (eligibilityDate: string) =>
  `You will be eligible for this benefit on ${eligibilityDate}`;

export const MEDICAL_CONDITIONALLY_ENROLLED_WARNING =
  'Children 26 and over can be enrolled in this benefit only under certain conditions';

export const NOT_COVERED_DEPENDENTS_WARNING = `If you decline coverage for your dependents during your initial enrollment period,
  then you may not enroll them until you experience a qualifying life event or until the next open enrollment period.
  For more information, please see Human Resources.`;

export const DOMESTIC_PARTNER_NOT_ELIGIBLE =
  'Domestic partners are not eligible for this plan';

export const CHILD_AGE_LIMIT_EXCEEDED = 'Child age limit exceeded';

export const CHILD_DISABLED =
  'You may need to submit supporting documentation in order to enroll an overage, totally disabled dependent.';

export const DUPLICATED_HR_REPORT = `This is a stock report. You cannot modify it.
  Would you like to create a copy of this report, so that you can make changes to it?`;

export const NOT_ELIGIBLE = 'You are not eligible for this benefit';

export const DECLINED_MEDICAL =
  'You are not eligible for this benefit because you have declined medical coverage';
export const NOT_HRA_ELIGIBLE_PLAN =
  'You are not eligible for this benefit because the medical plan you enrolled in is not an HRA eligible plan';
export const NOT_HIGH_DEDUCTIBLE_PLAN =
  'You are not eligible for this benefit because the medical plan you enrolled in is not a qualified high-deductible plan';

export const PROFILE_SETTINGS_ALERT = `Your personal information will only be used for internal purposes, and
  will never be disclosed to any third parties. Your privacy is very
  important to us and we will keep information secure and confidential.
  To see how this information may be used, please read our`;

export const DEPENDENTS_SETTINGS_ALERT = (userName?: string) => `${
  userName ? `${userName}, please` : 'Please'
} enter the information
  about your spouse and children. Make sure that you list all
  dependents that will be covered under your insurance.`;

export const ADDITIONAL_INFO_TITLE = (userName?: string) =>
  `${
    userName ? `${userName}, we` : 'We'
  } need some additional information to prepare your forms`;

export const ADDITIONAL_INFO_NOT_REQUIRED = (userName?: string) => `${
  userName ? `${userName}, we` : 'We'
} don't
need any additional information for your enrollment at this time. Please proceed to the next step.`;

export const BENEFICIARY_DEPENDENT_EXPLANATION =
  'Select one of your dependents from the list, or enter beneficiary information below';

export const RESTART_REGISTRATION_WARNING = `If you make any changes to your information,
  you will need to complete benefit enrollment process again.
  Are you sure you want to continue?`;

export const MEDICAL_OUT_OF_NETWORK = `This plan does not provide out-of-network coverage,
  except in the case of a defined emergency.`;
