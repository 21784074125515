import React from 'react';
import { Typography } from '@material-ui/core';

import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import styles from './styles.module.scss';

const EEOTitle: React.FC = () => {
  return (
    <>
      <Typography className={styles.eeoTitle} variant="h5">
        Equal Employment Opportunity Survey
      </Typography>
      <SimpleAlert type="info">
        The Equal Employment Opportunity Commission requires collection of race,
        ethnicity and sex information on employees.{' '}
        <b>Completion of this survey is optional.</b>
      </SimpleAlert>
    </>
  );
};

export default EEOTitle;
