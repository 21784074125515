import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import { IContact } from 'types/contacts';
import ContactType from 'enums/contactType';
import ContactSubmitForm from './ContactSubmitForm';

import styles from './styles.module.scss';

interface IContactHrProps {
  hrs: IContact[];
  setIsLoading?: (isLoading: boolean) => void;
}

const ContactHr: React.FC<IContactHrProps> = ({ hrs, setIsLoading }) => {
  return (
    <Box className={styles.boxWrapper}>
      <ContentContainer title="Contact HR" isBox withoutMargin padding>
        <Typography variant="subtitle2" className={styles.infoText}>
          If you have any questions, or need assistance with your benefit
          coverage, you can contact your HR administrator by filling out this
          form.
        </Typography>
        <ContactSubmitForm
          contacts={hrs}
          contactType={ContactType.Hr}
          setIsLoading={setIsLoading}
        />
      </ContentContainer>
    </Box>
  );
};

export default ContactHr;
