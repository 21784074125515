import moment from 'moment';
import {
  ExternalBeneficiaryInterface,
  BeneficiaryInterface,
} from 'types/beneficiaries';

export const fromExternalBeneficiaryToBeneficiary = (
  externalBeneficiary: ExternalBeneficiaryInterface
): BeneficiaryInterface => {
  return {
    id: externalBeneficiary.id,
    firstName: externalBeneficiary.firstName,
    lastName: externalBeneficiary.lastName,
    ssn: externalBeneficiary.ssn,
    relationshipId: externalBeneficiary.relationshipId,
    relationshipTypeId: externalBeneficiary.relationshipTypeId,
    birthDate: moment(externalBeneficiary.birthDate, 'MM/DD/YYYY').toDate(),
    beneficiaryType: +externalBeneficiary.beneficiaryType,
    share: +externalBeneficiary.share,
  };
};
