/* eslint-disable import/no-duplicates */
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useTheme from '@material-ui/core/styles/useTheme';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/icons/ChevronLeft';
import { ReactComponent as closeIcon } from 'assets/sidebar/close.svg';
import { ReactComponent as openIcon } from 'assets/sidebar/open.svg';
import clsx from 'clsx';
import RoleType from 'enums/roleType';
import { Link } from 'react-router-dom';
import useSidebar, { IStrictLink, ILinkWithNested } from './useSidebar';
import useStyles from './styles';

interface IProps {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  pathname: string;
  roleType: RoleType;
  testUser: boolean;
  enableMidyearChanges: boolean;
}

const Sidebar: React.FC<IProps> = ({
  open,
  setOpen,
  pathname,
  roleType,
  testUser,
  enableMidyearChanges,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { topSidebarLinks, bottomSidebarLinks, currentTabIndex } = useSidebar(
    matchesSm,
    pathname,
    setOpen,
    roleType,
    testUser,
    enableMidyearChanges
  );

  const drawerVariant = matchesSm ? 'persistent' : 'permanent';

  const isDrawerOpenClass = open && !matchesSm;
  const isDrawerCloseClass = !open && !matchesSm;

  const drawerClassName = clsx({
    [classes.drawer]: !matchesSm,
    [classes.drawerOpen]: isDrawerOpenClass,
    [classes.drawerClose]: isDrawerCloseClass,
  });

  const drawerClasses = {
    paper: clsx(classes.drawerPaper, {
      [classes.drawerOpen]: isDrawerOpenClass,
      [classes.drawerClose]: isDrawerCloseClass,
    }),
  };

  const isNestedExpandIconVisible = (nested: Array<any> | null) =>
    nested && !matchesSm;

  const isListItemDisabled = (to: string | null) =>
    !to && matchesSm ? true : false;

  return (
    <Drawer
      variant={drawerVariant}
      open={open}
      className={drawerClassName}
      classes={drawerClasses}
    >
      <Box className={classes.toolbar} />
      <List className={classes.mainList}>
        {topSidebarLinks.map((link) => (
          <Box key={link.name}>
            <ListItem
              className={
                (link as IStrictLink).activeIndex === currentTabIndex
                  ? classes.selected
                  : classes.item
              }
              component={(link as IStrictLink).to ? Link : 'div'}
              to={(link as IStrictLink).to || undefined}
              disabled={isListItemDisabled((link as IStrictLink).to)}
              selected={(link as IStrictLink).activeIndex === currentTabIndex}
              onClick={link.handleClick}
              button
            >
              <ListItemIcon className={classes.drawerItemIcon}>
                {link.icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  classes={{
                    primary:
                      (link as IStrictLink).activeIndex === currentTabIndex
                        ? classes.textSelected
                        : classes.drawerItemText,
                  }}
                  primary={link.name}
                />
              )}
              {isNestedExpandIconVisible((link as ILinkWithNested).nested) && (
                <>
                  {(link as ILinkWithNested).nestedState ? (
                    <SvgIcon
                      className={classes.helpIcons}
                      component={closeIcon}
                      width="11"
                      height="7"
                      viewBox="0 0 11 7"
                    />
                  ) : (
                    <SvgIcon
                      className={classes.helpIcons}
                      component={openIcon}
                      width="11"
                      height="7"
                      viewBox="0 0 11 7"
                    />
                  )}
                </>
              )}
            </ListItem>
            {(link as ILinkWithNested).nested &&
              (link as ILinkWithNested).nested.map(
                (nestedLink: IStrictLink) => (
                  <Collapse
                    in={
                      matchesSm ? true : (link as ILinkWithNested).nestedState
                    }
                    timeout="auto"
                    unmountOnExit
                    key={nestedLink.name}
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        className={
                          nestedLink.activeIndex === currentTabIndex
                            ? classes.nestedSelect
                            : classes.nested
                        }
                        component={Link}
                        to={nestedLink.to}
                        onClick={nestedLink.handleClick}
                        selected={nestedLink.activeIndex === currentTabIndex}
                        button
                      >
                        <ListItemIcon
                          className={
                            open ? classes.drawerItemIcon : classes.openIcons
                          }
                        >
                          {nestedLink.icon}
                        </ListItemIcon>
                        {open && (
                          <ListItemText
                            classes={{
                              primary:
                                nestedLink.activeIndex === currentTabIndex
                                  ? classes.textSelected
                                  : classes.drawerItemText,
                            }}
                            primary={nestedLink.name}
                          />
                        )}
                      </ListItem>
                    </List>
                  </Collapse>
                )
              )}
          </Box>
        ))}
        {bottomSidebarLinks.map((link) => (
          <ListItem
            className={
              link.activeIndex === currentTabIndex
                ? classes.selected
                : classes.item
            }
            component={Link}
            to={link.to}
            selected={link.activeIndex === currentTabIndex}
            onClick={link.handleClick}
            button
            key={link.name}
          >
            <ListItemIcon className={classes.drawerItemIcon}>
              {link.icon}
            </ListItemIcon>
            {open && (
              <ListItemText
                classes={{
                  primary:
                    link.activeIndex === currentTabIndex
                      ? classes.textSelected
                      : classes.drawerItemText,
                }}
                primary={link.name}
              />
            )}
          </ListItem>
        ))}
      </List>
      <Box
        className={clsx(classes.collapseIconContainer, {
          [classes.collapseIconContainerOpen]: open,
          [classes.collapseIconContainerClose]: !open,
        })}
      >
        {!matchesSm && (
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={() => setOpen(!open)}
            classes={{
              label: classes.collapseIcon,
            }}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}
      </Box>
    </Drawer>
  );
};

export default Sidebar;
