import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import RoleType from 'enums/roleType';
import { AppStoreInterface } from 'store/app';
import { DEFAULT_PATH } from 'constants/routes';

interface IProps extends RouteProps {
  roleTypes: RoleType[];
  currentRoleType: RoleType;
  component?: React.ComponentType & any;
}

export const UserTypeRoute: React.FC<IProps> = ({
  roleTypes,
  currentRoleType,
  component: Component,
  ...props
}) => {
  const renderComponent = (routeProps: RouteProps) => (
    <Component {...routeProps} />
  );

  const route = Component ? (
    <Route {...props} render={renderComponent} />
  ) : (
    <Route {...props} />
  );

  return roleTypes.includes(currentRoleType) ? (
    route
  ) : (
    <Redirect to={DEFAULT_PATH} />
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  currentRoleType: store.user.roleType,
});

export default connect(mapStateToProps)(UserTypeRoute);
