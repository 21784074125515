import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import SectionTitle from 'components/SectionTitle';
import AuditLogUserType from 'enums/auditLogUserType';
import IBasicOption from 'models/IBasicOption';
import { TableColumn } from 'components/Table/TableComponent';
import { IAuditLog } from 'types/auditLogs';
import { AppStoreInterface } from 'store/app';
import { EMPTY_VALUE } from 'constants/placeholders';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import LookupFilter from 'components/Table/LookupComponent';
import SearchFilter from 'components/Table/SearchComponent';
import AuditLogsTable from './AuditLogsTable';

import styles from './styles.module.scss';

interface IProps {
  logEventType: IBasicOption<number>[];
  planType: IBasicOption<number>[];
}

const AuditLogs: React.FC<IProps> = ({ logEventType, planType }) => {
  const logEventTypeDictionary = _.chain(logEventType)
    .keyBy('value')
    .mapValues('label')
    .value();

  const planTypeDictionary = _.chain(planType)
    .keyBy('value')
    .mapValues('label')
    .value();

  const columns: TableColumn<IAuditLog>[] = [
    {
      title: 'Date & Time',
      render: (row) => row.createdOn,
      field: 'createdOn',
      filtering: false,
      hideFilterIcon: false,
      /**
       * sorting in reverse chronological order by default
       */
      defaultSort: 'desc',
    },
    {
      title: 'Event Type',
      field: 'eventType',
      render: (row) => row.eventType,
      lookup: logEventTypeDictionary,
      filterPlaceholder: 'Select',
      hideFilterIcon: false,
      filterComponent: (props) => (
        <LookupFilter
          columnDef={props.columnDef}
          onFilterChanged={props.onFilterChanged}
        />
      ),
    },
    {
      title: 'Plan Type',
      render: (row) => row.planType || EMPTY_VALUE,
      field: 'planTypeId',
      lookup: planTypeDictionary,
      filterPlaceholder: 'Select',
      hideFilterIcon: false,
      filterComponent: (props) => (
        <LookupFilter
          columnDef={props.columnDef}
          onFilterChanged={props.onFilterChanged}
        />
      ),
    },
    {
      title: 'Description',
      render: (row) => row.description,
      field: 'description',
      filtering: true,
      hideFilterIcon: false,
      filterComponent: (props) => (
        <SearchFilter
          columnDef={props.columnDef}
          onFilterChanged={props.onFilterChanged}
        />
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | My Audit Log</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <SectionTitle title="My Audit Log" />
        <Box className={styles.contentWrapper}>
          <ContentContainer title="Enrollments & Changes" isBox withoutMargin>
            <AuditLogsTable
              columns={columns}
              userType={AuditLogUserType.Employee}
            />
          </ContentContainer>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  logEventType: store.options.logEventType,
  planType: store.options.planType,
});

export default connect(mapStateToProps)(AuditLogs);
