import {
  IOpenEnrollmentPayload,
  IOpenEnrollmentsInfo,
} from 'types/statusDashboard';
import api from '../adapter';

export class OpenEnrollmentsDomain {
  get() {
    return api.makeRequest<IOpenEnrollmentsInfo>('/openEnrollment', 'GET');
  }

  check(planId: string) {
    return api.makeRequest<any>(`/openEnrollment/check/${planId}`, 'GET');
  }

  fix(planId: string) {
    return api.makeRequest<{ success: boolean }>(
      `/openEnrollment/fix/${planId}`,
      'PUT'
    );
  }

  update(payload: IOpenEnrollmentPayload) {
    return api.makeRequest<IOpenEnrollmentPayload>(
      '/openEnrollment',
      'POST',
      payload
    );
  }
}

export default new OpenEnrollmentsDomain();
