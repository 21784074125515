import React, { useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  SvgIcon,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import { IMessageInfo } from 'types/user';
import BlockWithHelpBuble from 'components/Popper/BlockWithHelpBuble';
import AsIsRenevalBlock from './AsIsRenevalBlock';
import styles from './styles.module.scss';

interface IEmailTableProps {
  messageData?: IMessageInfo;
  plans?: any[];
  isSelected: (id: string) => boolean;
  isSelectedAsIsRenewal: (id: string) => boolean;
  onSelectClick: (id: string, isRenewal?: boolean) => void;
  isAllSelected: () => boolean;
  onSelectAllClick: () => void;
  activeOpenEnrollment: boolean;
}
const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);
const PlansTable: React.FC<IEmailTableProps> = ({
  messageData,
  plans,
  isSelected,
  isSelectedAsIsRenewal,
  isAllSelected,
  onSelectClick,
  onSelectAllClick,
  activeOpenEnrollment,
}) => {
  useEffect(() => {
    if (messageData) {
    }
  }, [messageData]);

  return (
    <Box>
      <Box className={styles.mainContainer}>
        <ContentContainer>
          <Box className={styles.wrapper}>
            <TableContainer className={styles.wrapperTable} component={Box}>
              <Table className={styles.recipientsTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {!activeOpenEnrollment && (
                        <CustomCheckbox
                          disabled={activeOpenEnrollment}
                          checked={isAllSelected()}
                          onChange={onSelectAllClick}
                          icon={
                            <SvgIcon
                              component={offIcon}
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                            />
                          }
                          checkedIcon={
                            <SvgIcon
                              component={onIcon}
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                            />
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell> Plan</TableCell>
                    <TableCell> Effective Date</TableCell>
                    <TableCell>
                      <Box className={styles.asIsBox}>
                        As-Is
                        <Box className={styles.BubbleWrapper}>
                          <BlockWithHelpBuble
                            iconClass={styles.iconStyles}
                            moreInfo={`If you select this option, employees will be automatically enrolled 
                          in the new plan and will not have to make plan selections during open enrollment.`}
                          />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {plans?.map((plan) => (
                    <TableRow key={plan.id}>
                      <TableCell>
                        <CustomCheckbox
                          disabled={activeOpenEnrollment}
                          checked={isSelected(plan.id)}
                          onChange={() => onSelectClick(plan.id)}
                          icon={
                            <SvgIcon
                              component={offIcon}
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                            />
                          }
                          checkedIcon={
                            <SvgIcon
                              component={onIcon}
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                            />
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {plan.title}
                        {isSelectedAsIsRenewal(plan.id) && (
                          <Box>
                            <AsIsRenevalBlock planId={plan.id} />
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>{plan.effectiveDate}</TableCell>
                      <TableCell>
                        <CustomCheckbox
                          disabled={activeOpenEnrollment}
                          checked={isSelectedAsIsRenewal(plan.id)}
                          onChange={() => onSelectClick(plan.id, true)}
                          icon={
                            <SvgIcon
                              component={offIcon}
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                            />
                          }
                          checkedIcon={
                            <SvgIcon
                              component={onIcon}
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                            />
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </ContentContainer>
      </Box>
    </Box>
  );
};

export default PlansTable;
