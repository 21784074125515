import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';

import FillFields, {
  FieldsInterface,
} from 'components/FillFieldsList/FillFieldsListComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import * as inputFormats from 'constants/inputFormats';
import { ZIP_REG_EXP } from 'constants/regExps';
import {
  DependentAddressPayloadInterface,
  DependentAddressInterface,
} from 'types/dependents';
import userDomain from 'api/domains/user';

import styles from './styles.module.scss';
import IBasicOption from 'models/IBasicOption';

interface IDependentAddressFormProps {
  dependentAddress: DependentAddressInterface;
  className?: string;
  onChangeDependentAddress: (
    dependentAddress: DependentAddressInterface
  ) => void | Promise<void>;
  onDecline: (dependentId: string | number) => void | Promise<void>;
  stateOptions: IBasicOption<string>[];
}

const DependentAddressForm: React.FC<IDependentAddressFormProps> = (props) => {
  const {
    dependentAddress,
    className,
    onChangeDependentAddress,
    onDecline,
    stateOptions,
  } = props;

  const declineHandler = async () => {
    onDecline(dependentAddress.id);
  };

  const handleDependentAddressChange = (
    field: keyof DependentAddressPayloadInterface
  ) => {
    return (
      e: ChangeEvent<
        HTMLInputElement | { name?: string | undefined; value: unknown }
      >,
    ) => {
      onChangeDependentAddress({
        ...dependentAddress,
        [field]: e.target.value,
        dependentDifferentAddress: true,
      });
    };
  };

  const handleZipCodeChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const zip = e.target.value;
    const zipValid = zip.match(ZIP_REG_EXP);

    if (zipValid) {
      try {
        const { data } = await userDomain.zipCodeLookup(zip);
        onChangeDependentAddress({
          ...dependentAddress,
          zip,
          city: data.city || '',
          state: data.state || '',
          dependentDifferentAddress: true,
        });
      } catch (err) {
        console.error(err);
      }
      return;
    }
    onChangeDependentAddress({
      ...dependentAddress,
      zip,
      city: '',
      state: '',
      dependentDifferentAddress: true,
    });
  };

  const fields: FieldsInterface[] = [
    {
      key: 'address1',
      inputComponentData: {
        onChange: handleDependentAddressChange('address1'),
        value: dependentAddress.address1 as string,
        name: 'address1',
        label: 'Home address',
        typeInput: 'info',
      },
    },
    {
      key: 'address2',
      inputComponentData: {
        onChange: handleDependentAddressChange('address2'),
        value: dependentAddress.address2 as string,
        name: 'address2',
        label: 'Apt Number/Unit/Etc.',
        typeInput: 'info',
      },
    },
    {
      key: 'zip',
      inputComponentData: {
        onChange: handleZipCodeChange,
        value: dependentAddress.zip as string,
        name: 'zip',
        label: 'Zip code',
        isFormatting: true,
        format: inputFormats.zip,
        typeInput: 'info',
      },
    },
    {
      key: 'city',
      inputComponentData: {
        onChange: handleDependentAddressChange('city'),
        value: (dependentAddress.city as string) || '',
        name: 'city',
        label: 'City',
        typeInput: 'info',
      },
    },
    {
      key: 'state',
      selectComponentData: {
        options: stateOptions,
        onChange: handleDependentAddressChange('state'),
        value: (dependentAddress.state as string) || '',
        name: 'state',
        label: 'State',
        typeInput: 'info',
      },
    },
  ];

  return (
    <SimpleAlert
      className={classNames({ [className || '']: !!classNames })}
      title="Please enter dependent address"
      type="info"
    >
      <FillFields fields={fields} />
      <Box className={styles.addressFormSubmitContainer}>
        <SubmitButton label="Cancel" color="primary" onClick={declineHandler} />
      </Box>
    </SimpleAlert>
  );
};

export default DependentAddressForm;
