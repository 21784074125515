import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Divider,
  Popover,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FullScreenDialogProps } from 'components/Modal/FullScreenDialogComponent';
import FullScreenModal from 'components/Modal/FullScreenWindowComponent';
import RadioGroup, {
  BasicRadioButtonInterface,
} from 'components/Forms/RadioGroup/RadioGroupComponent';

import IBasicOption from 'models/IBasicOption';
import EnrollmentStatus from 'enums/enrollmentStatus';
import OptionType from 'enums/optionsType';
import ExternalPlanType from 'enums/externalPlanType';
import PlanTypeNumeric from 'enums/planTypeNumeric';
import optionsDomain from 'api/domains/options';
import { IFullPlan } from 'types/plans';
import { IEnrollment, IUpdateEnrollmentPayload } from 'types/enrollments';
import { fromSystemOptionToBasicOption } from 'mappers/common/fromSystemOptionToBasicOption';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import { useQuery } from 'react-query';
import plansDomain from 'api/domains/plans';
import moment from 'moment';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import MyPlan from './MyPlan';
import ComparePlansModal from './ComparePlansModal';
import DeclineEnrollmentForm from './DeclineEnrollmentForm';
import PlanFormFactory from './PlanFormFactory';
import RadioGroupPlanDetails from './RadioGroupPlanDetails';

export interface EnrollmentSettingsDialogProps extends FullScreenDialogProps {
  groupName: string;
  plans: IFullPlan[];
  enrollment?: IEnrollment;
  chosenPlanId?: string;
  updateEnrollment: (
    id: string,
    enrollment: IUpdateEnrollmentPayload
  ) => void | Promise<void>;
}

const plansToRadioButtonGroup = (
  plans: IFullPlan[]
): BasicRadioButtonInterface<string>[] => {
  return plans.map((plan) => ({
    label: plan.title,
    value: plan.id,
  }));
};

const isPlansCanBeDeclined = (plans: IFullPlan[]): boolean => {
  return plans.every((plan) => {
    if (Number(plan?.planTypeId) === PlanTypeNumeric.FSA)
      return (
        plan.contributionType !== '2' &&
        !plan.fsaDepErAmount &&
        !plan.fsaParkErAmount &&
        !plan.fsaMedErAmount &&
        !plan.fsaTraErAmount
      );
    else if (Number(plan?.planTypeId) === PlanTypeNumeric.HSA)
      return (
        plan.contributionType !== '2' &&
        !plan.priceF &&
        !plan.priceHw &&
        !plan.pricePc &&
        !plan.priceS
      );
    else return plan.contributionType !== '2';
  });
};

const getMyPlans = async () => {
  const { data } = await plansDomain.getMyPlans();
  return data;
};
const getCoverageAmounts = async (_key: string, id: string) => {
  const { data } = await plansDomain.getCoverageAmounts(id);
  return data;
};

const EnrollmentSettingsDialog: React.FC<EnrollmentSettingsDialogProps> = (
  props
) => {
  const {
    plans,
    groupName,
    enrollment,
    chosenPlanId,
    updateEnrollment,
    ...dialogProps
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();
  const [chosenPlan, setChosenPlan] = useState<string | undefined>(
    chosenPlanId &&
      (typeof chosenPlanId === 'number' || typeof chosenPlanId === 'string')
      ? (chosenPlanId as string)
      : undefined
  );
  const [waiverReasons, setWaiverReasons] = useState<IBasicOption<number>[]>(
    []
  );
  const [iWantToDecline, setIWantToDecline] = useState<boolean>(
    enrollment &&
      !(
        chosenPlanId &&
        (typeof chosenPlanId === 'number' || typeof chosenPlanId === 'string')
      )
      ? +enrollment.status < +EnrollmentStatus.NotSelected
      : false
  );
  const [tabValue, setTabValue] = useState<number | null>(
    chosenPlanId &&
      (typeof chosenPlanId === 'number' || typeof chosenPlanId === 'string')
      ? 0
      : null
  );
  const [showCompareModal, setShowCompareModal] = React.useState(false);
  const { data: myPlans } = useQuery('myPlans', getMyPlans, {
    refetchOnWindowFocus: false,
  });
  const [choosedDependents, setChoosedDependents] = useState<string[]>([]);
  const [contributionAmount, setContributionsAmount] = useState<{
    [key: string]: number;
  }>({});
  const currentPlanType = plans[0]?.planTypeId;
  const { data: coverageAmounts } = useQuery(
    ['coverageAmounts', currentPlanType],
    getCoverageAmounts,
    {
      refetchOnWindowFocus: false,
    }
  );
  const myPlan =
    (Number(enrollment?.status) > 0 || !enrollment?.status) &&
    myPlans?.find(
      (plan) =>
        +plan.planTypeId === +currentPlanType &&
        !plans.find((p) => +p.id === +plan.id)
    );
  const onChangePlan = (planId: string) => {
    setChosenPlan(planId);
  };

  const onCompare = () => {
    setShowCompareModal(true);
  };

  useEffect(() => {
    if (
      chosenPlanId &&
      (typeof chosenPlanId === 'number' || typeof chosenPlanId === 'string')
    ) {
      setTabValue(0);
    } else if (!enrollment?.selectable) {
      setTabValue(0);
    } else if (enrollment) {
      const status = enrollment.status;
      if (+status >= +EnrollmentStatus.Enrolled) {
        setTabValue(0);
      } else if (
        status === EnrollmentStatus.Declined ||
        status === EnrollmentStatus.Waived
      ) {
        setTabValue(1);
      }
    }
    // eslint-disable-next-line
  }, [dialogProps.open, enrollment]);

  const setWaivedOptions = async () => {
    try {
      const { data } = await optionsDomain.getOptions(OptionType.WaiverReason);
      setWaiverReasons(data.map(fromSystemOptionToBasicOption));
    } catch (e) {
      console.error(e);
    }
  };
  const currentPlan = useMemo(
    () => plans.find((plan) => plan.id === chosenPlan),
    [chosenPlan, plans]
  );

  useEffect(() => {
    if (
      chosenPlanId &&
      (typeof chosenPlanId === 'number' || typeof chosenPlanId === 'string')
    ) {
      setChosenPlan(chosenPlanId);
    } else {
      setChosenPlan(
        (+plans.length === 1 ? plans[0].id : undefined) || enrollment?.planId
      );
    }
    // eslint-disable-next-line
  }, [enrollment, plans]);

  useEffect(() => {
    const planIds = [];
    for (const plan of plans) {
      if (plan.id) planIds.push(plan.id);
    }
    getContributionAmount(planIds);
    // eslint-disable-next-line
  }, [choosedDependents, plans]);

  useEffect(() => {
    setWaivedOptions();
  }, [enrollment]);

  const getContributionAmount = async (planId: string[]) => {
    try {
      const tempContributionAmount: { [key: string]: number } = {};
      for (const plan of planId) {
        const { data } = await plansDomain.predictContributionAmount(
          plan,
          choosedDependents,
          null
        );
        tempContributionAmount[plan] = data.amount ?? 0;
      }
      setContributionsAmount(tempContributionAmount);
    } catch (e) {
      console.error(e);
    }
  };

  const planFormVisible = !iWantToDecline && !!chosenPlan;

  const radioGroupProps = {
    options: plansToRadioButtonGroup(plans),
    name: 'groupName',
    onChange: onChangePlan,
    value: chosenPlan,
    disableAll: iWantToDecline,
    contribution: contributionAmount,
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      setIWantToDecline(false);
    }
    setTabValue(newValue);
  };
  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };
  const popperOpen = Boolean(anchorEl);
  const id = popperOpen ? 'mouse-over-popove' : undefined;
  var dateMns = moment(plans[0].effectiveDate).subtract(1, 'day');
  var date = dateMns.format('L');

  const coverageAmountBox = () => {
    return coverageAmounts?.children ||
      coverageAmounts?.spouse ||
      coverageAmounts?.employee ? (
      <Box>
        <Typography className={classes.myCoverageLabel}>
          Current Coverage
        </Typography>
        {coverageAmounts?.employee && (
          <Box className={classes.myCoverageBox}>
            <Typography className={classes.myPlanLabels}>Employee: </Typography>
            <Typography className={classes.myPlanSubLabel}>
              ${coverageAmounts?.employee}{' '}
            </Typography>
          </Box>
        )}
        {coverageAmounts?.spouse && (
          <Box className={classes.myCoverageBox}>
            <Typography className={classes.myPlanLabels}>Spouse: </Typography>
            <Typography className={classes.myPlanSubLabel}>
              ${coverageAmounts?.spouse}{' '}
            </Typography>
          </Box>
        )}
        {coverageAmounts?.children && (
          <Box className={classes.myCoverageBox}>
            <Typography className={classes.myPlanLabels}>Children: </Typography>
            <Typography className={classes.myPlanSubLabel}>
              ${coverageAmounts?.children}{' '}
            </Typography>
          </Box>
        )}
      </Box>
    ) : (
      <></>
    );
  };

  return (
    <FullScreenModal {...dialogProps}>
      {currentPlanType && (
        <Box display="flex" className={classes.modal}>
          <Box className={classes.container}>
            <Typography className={classes.groupTitle}>
              <PlanIcons planTypeId={Number(currentPlanType)} />
              {groupName}
            </Typography>
            <Box>
              <Box
                className={`${tabValue === null && classes.tabContainerNull} ${
                  classes.tabContainer
                }`}
              >
                <Box className={classes.tabPaper}>
                  <Tabs
                    className={classes.tabs}
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    classes={{
                      indicator: classes.indicator,
                    }}
                  >
                    <Tab
                      classes={{
                        selected: classes.selected,
                      }}
                      className={classes.tab}
                      label="Enroll"
                    />
                    {!isPlansCanBeDeclined(plans) ? (
                      <Box
                        onMouseEnter={handlePopperOpen}
                        onMouseLeave={handlePopperClose}
                        aria-describedby={id}
                      >
                        <Tab
                          classes={{
                            selected: classes.selected,
                          }}
                          className={classes.tab}
                          disabled={
                            !enrollment?.selectable ||
                            !isPlansCanBeDeclined(plans)
                          }
                          label="Decline"
                        />
                        {!isPlansCanBeDeclined(plans) && (
                          <Popover
                            id={id}
                            open={popperOpen}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={handlePopperClose}
                            disableRestoreFocus
                          >
                            <SimpleAlert type="info" className={classes.alert}>
                              <Typography className={classes.textAlert}>
                                Your employer is making a contribution to this
                                plan so you cannot decline it. If you do not
                                wish to contribute please enter 0 and click
                                enroll.
                              </Typography>
                            </SimpleAlert>
                          </Popover>
                        )}
                      </Box>
                    ) : (
                      <Tab
                        classes={{
                          selected: classes.selected,
                        }}
                        className={classes.tab}
                        disabled={
                          !enrollment?.selectable ||
                          !isPlansCanBeDeclined(plans)
                        }
                        label="Decline"
                      />
                    )}
                  </Tabs>
                </Box>
              </Box>
              {tabValue !== null && (
                <Box className={tabValue === 1 ? classes.decline : classes.box}>
                  {tabValue === 0 ? (
                    <Box className={classes.enrollDependents}>
                      {myPlan && (
                        <Box className={classes.myPlanBlock}>
                          <Typography className={classes.subBlockTitle}>
                            MY PLAN (until {date})
                          </Typography>
                          <Typography className={classes.myPlanLabel}>
                            {myPlan?.title}
                            {+myPlan.planTypeId === PlanTypeNumeric.Medical && (
                              <MyPlan planId={myPlan.id} />
                            )}
                          </Typography>
                          {coverageAmountBox()}
                          <ComparePlansModal
                            onChange={onChangePlan}
                            isOpen={showCompareModal}
                            handleClose={() => setShowCompareModal(false)}
                            planTypeId={plans[0].planTypeId}
                            myPlanId={myPlan.id}
                          />
                        </Box>
                      )}
                      {plans.length !== 1 ||
                        (myPlan &&
                          (myPlan ? (
                            <Typography className={classes.subBlockTitle}>
                              {plans.length !== 1
                                ? `CHOOSE A NEW PLAN (from ${plans[0].effectiveDate})`
                                : `NEW PLAN (from ${plans[0].effectiveDate})`}
                            </Typography>
                          ) : (
                            <Typography className={classes.blockTitle}>
                              Choose one of the plans
                            </Typography>
                          )))}
                      <Box>
                        {currentPlanType === ExternalPlanType.Medical ? (
                          <RadioGroupPlanDetails {...radioGroupProps} />
                        ) : (
                          <RadioGroup {...radioGroupProps} />
                        )}
                      </Box>
                      {myPlan && (
                        <Typography className={classes.groupDesc}>
                          <span
                            onClick={onCompare}
                            className={classes.groupDescClickable}
                          >
                            Compare your current plan with the new plans
                          </span>
                        </Typography>
                      )}
                      {!myPlan && (
                        <Typography className={classes.groupDesc}>
                          {coverageAmountBox()}
                        </Typography>
                      )}
                      <Divider className={classes.div} />
                      {planFormVisible && (
                        <PlanFormFactory
                          chosenPlan={chosenPlan as string}
                          currentPlan={currentPlan}
                          enrollment={enrollment}
                          coverageAmount={myPlan ? coverageAmounts : undefined}
                          iWantToDecline={iWantToDecline}
                          currentPlanType={currentPlanType}
                          updateEnrollment={updateEnrollment}
                          setChoosedDependents={setChoosedDependents}
                        />
                      )}
                    </Box>
                  ) : tabValue === 1 ? (
                    <DeclineEnrollmentForm
                      groupName={groupName}
                      waiverReasons={waiverReasons}
                      onDecline={updateEnrollment}
                      currentPlanType={currentPlanType}
                      enrollment={enrollment}
                    />
                  ) : null}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </FullScreenModal>
  );
};

const useStyles = makeStyles({
  modal: {
    width: '100%',
    display: 'flex',
    alignSelf: 'start',
  },
  container: {
    width: '100%',
    padding: '20px 0',
    margin: 'auto',
    minHeight: 'calc(100vh - 226px)',
  },
  tabContainerNull: {
    background: '#ffff !important',
  },
  tabContainer: {
    padding: '0 24px',
    background: '#4979C0',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '2em',
    borderRadius: '8px 8px 0 0',
    minHeight: '2.5em',
    height: '60px',
    border: '1px solid #4979C0',
  },
  tabPaper: {
    color: '#F7F8FC',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  enrollDependents: {
    width: '100%',
  },
  tabs: {
    minHeight: '10px',
    height: 'fit-content',
    '&.MuiTab-textColorInherit': {
      opacity: '1 !important',
    },
  },
  alert: {
    width: '200px',
  },
  textAlert: {
    fontSize: '1rem',
    fontFamily: 'Mulish", sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
  },
  tab: {
    padding: '8px 28px',
    background: '#4979C0',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '4px',
    minWidth: '10px',
    width: 'fit-content',
    marginRight: '20px',
    minHeight: '10px',
    color: '#FFFFFF',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    opacity: '1 !important',
  },
  groupTitle: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '19px',
    lineHeight: '32px',
    color: '#252733',
  },
  subBlockTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.4px',
    color: '#4979C0',
    margin: '5px 0',
  },
  blockTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.4px',
    color: '#00000',
    margin: '5px 0',
  },
  indicator: {
    background: '#F7F8FC',
    height: '0px',
  },
  flexContainer: {
    overflow: 'auto',
  },
  selected: {
    padding: '8px 28px',
    background: '#FFFFFF',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: '#4979C0',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  box: {
    maxWidth: '100%',
    overflow: 'auto',
    background: '#FFFFFF',
    borderRadius: '0 0 8px 8px',
    border: '1px solid #E0E1EB',
    padding: '24px',
  },
  decline: {
    maxWidth: '100%',
    overflow: 'auto',
    background: '#FEF0F0',
    borderRadius: '0 0 8px 8px',
    border: '1px solid #E0E1EB',
    padding: '24px',
  },
  div: {
    margin: '25px 0',
    background: '#DFE0EB',
  },
  groupDesc: {
    fontSize: '0.8rem',
    margin: '10px 0',
  },
  groupDescClickable: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    textDecoration: 'underline',
    color: '#3DB9FE',
    textDecorationColor: '#3DB9FE',
    cursor: 'pointer',
  },
  myPlanLabel: {
    margin: '10px 0 10px 0',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  myCoverageLabel: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  myCoverageBox: {
    margin: '5px 0',
    display: 'flex',
  },
  myPlanLabels: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  myPlanSubLabel: {
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#000000',
    marginLeft: '5px',
  },
  myPlanBlock: {
    margin: '10px 0 50px 0',
  },
});

export default EnrollmentSettingsDialog;
