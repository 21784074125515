import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import { withFormik, FormikBag, FormikProps } from 'formik';
import * as yup from 'yup';
import moment from 'moment';

import ModalWindow, {
  ModalWindowPropsInterface,
} from 'components/Modal/ModalWindowComponent';
import FillFields, {
  FieldsInterface,
} from 'components/FillFieldsList/FillFieldsListComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import { BENEFICIARY_DEPENDENT_EXPLANATION } from 'constants/messages';

import IBasicOption from 'models/IBasicOption';
import {
  BeneficiaryInterface,
  BeneficiaryInterfaceWithoutId,
} from 'types/beneficiaries';
import BeneficiaryType from 'enums/beneficiaryType';
import { AppStoreInterface } from 'store/app';
import { DependentInterface } from 'types/dependents';
import { beneficiaryRelationshipTypeOptions as fetchBeneficiaryRelationshipTypeOptions } from 'redux/sagas/options/routines';
import * as validationHelper from 'helpers/validation';
import { DEFAULT_DATE_FORMAT } from 'constants/app';

interface IAddBeneficiaryModalPropsFromState {
  dependents?: DependentInterface[];
  beneficiaryRelationshipType: IBasicOption<number>[];
  beneficiaryTypeOptions: IBasicOption<BeneficiaryType>[];
}

interface IAddBeneficiaryModalActions {
  actions: {
    fetchBeneficiaryRelationshipTypeOptions: typeof fetchBeneficiaryRelationshipTypeOptions.trigger;
  };
}

type IFormikValues = Omit<BeneficiaryInterfaceWithoutId, 'share'> & {
  dependentForPrefillId?: string;
  share: string;
};

export interface IAddBeneficiaryModalProps {
  ssnEditable: boolean;
  toggleSsnEditableWithCb: (cb: (ssnEditable: boolean) => void) => void;
  onSubmit?: (beneficiary: BeneficiaryInterface) => void | Promise<void>;
  beneficiary?: BeneficiaryInterface;
  primaryMax?: number;
  contingentMax?: number;
  tertiaryMax?: number;
}

type AllAddBeneficiaryModalProps = ModalWindowPropsInterface &
  IAddBeneficiaryModalPropsFromState &
  IAddBeneficiaryModalProps &
  IAddBeneficiaryModalActions;

type WithFormikProps = AllAddBeneficiaryModalProps & FormikProps<IFormikValues>;

class AddBeneficiaryModal extends React.Component<
  WithFormikProps,
  { initialSsn?: string }
> {
  state = { initialSsn: undefined };

  componentDidMount() {
    if (!this.props.beneficiaryRelationshipType.length) {
      this.props.actions.fetchBeneficiaryRelationshipTypeOptions();
    }
  }

  componentDidUpdate(prevProps: WithFormikProps) {
    if (
      this.props.values.dependentForPrefillId &&
      prevProps.values.dependentForPrefillId !==
        this.props.values.dependentForPrefillId
    ) {
      const { values } = this.props;
      const dependent = this.props.dependents?.find(
        (d) => d.id === values.dependentForPrefillId
      );
      this.props.setValues({
        ...values,
        ssn: dependent?.ssn || '',
        relationshipTypeId: dependent?.relationshipTypeId || '',
        firstName: dependent?.firstName || '',
        lastName: dependent?.lastName || '',
        birthDate: dependent
          ? typeof dependent.birthDate === 'string'
            ? moment(dependent.birthDate, DEFAULT_DATE_FORMAT).toDate()
            : dependent.birthDate
          : null,
      });
      this.setState({ initialSsn: dependent?.ssn });
    }
    if (!isEqual(this.props.beneficiary, prevProps.beneficiary)) {
      this.props.setValues(mapPropsToValues(this.props), false);
      this.setState({ initialSsn: this.props.beneficiary?.ssn });
    }
  }

  toggleSsnEditable = () => {
    const { setFieldValue, toggleSsnEditableWithCb } = this.props;
    const { initialSsn } = this.state;

    toggleSsnEditableWithCb((ssnEditable) => {
      setFieldValue('ssn', ssnEditable ? '' : initialSsn);
    });
  };

  getMaxShared = (): number => {
    const { beneficiaryType } = this.props.values;
    const { contingentMax, primaryMax, tertiaryMax } = this.props;

    switch (beneficiaryType) {
      case BeneficiaryType.Primary:
        return typeof primaryMax === 'number' ? primaryMax : 100;
      case BeneficiaryType.Contingent:
        return typeof contingentMax === 'number' ? contingentMax : 100;
      case BeneficiaryType.Tertiary:
        return typeof tertiaryMax === 'number' ? tertiaryMax : 100;
      default:
        return 100;
    }
  };

  getErrorMessage = (key: keyof IFormikValues): string | undefined => {
    const { errors, touched } = this.props;
    const error = errors[key];
    const isTouched = touched[key];
    return isTouched && error ? (error as string) : '';
  };

  setShareValue = (maxShared: number, minShared: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      // set empty string
      if (!e.target.value) {
        this.props.setFieldValue(e.target.name, e.target.value);
        return;
      }
      // set number value
      const newShare = +e.target.value;
      if (Number.isNaN(newShare)) {
        return;
      }
      this.props.setFieldValue(
        e.target.name,
        newShare > maxShared
          ? maxShared
          : newShare < minShared
          ? minShared
          : newShare
      );
    };
  };

  getSharedError = (maxShared: number): string | undefined => {
    const { values } = this.props;

    if (typeof values.beneficiaryType !== 'number') {
      return 'Choose type of share';
    }
    if (maxShared <= 0) {
      return 'You already spent it';
    }
    if (!values.share || +values.share < 1 || +values.share > maxShared) {
      return `From 1 to ${maxShared}`;
    }
  };

  getFields = (): FieldsInterface[] => {
    const {
      beneficiaryRelationshipType,
      handleChange,
      handleBlur,
      values,
      beneficiaryTypeOptions,
      ssnEditable,
    } = this.props;
    const maxShared = this.getMaxShared();
    const disableShared =
      typeof values.beneficiaryType !== 'number' || maxShared <= 0;
    const sharedError = this.getSharedError(maxShared);

    return [
      {
        key: 'relationshipTypeId',
        selectComponentData: {
          options: beneficiaryRelationshipType,
          value: values.relationshipTypeId,
          onChange: handleChange,
          name: 'relationshipTypeId',
          label: 'Relation',
          onBlur: handleBlur,
          error: !!this.getErrorMessage('relationshipTypeId'),
          helperText: this.getErrorMessage('relationshipTypeId'),
        },
      },
      {
        key: 'firstName',
        inputComponentData: {
          name: 'firstName',
          label: 'First name',
          value: values.firstName,
          onChange: handleChange,
          onBlur: handleBlur,
          error: this.getErrorMessage('firstName'),
        },
      },
      {
        key: 'lastName',
        inputComponentData: {
          label: 'Last name',
          name: 'lastName',
          value: values.lastName,
          onChange: handleChange,
          onBlur: handleBlur,
          error: this.getErrorMessage('lastName'),
        },
      },
      {
        key: 'birthDate',
        formikDatePickerComponentData: {
          value: values.birthDate,
          label: 'Date of birth',
          variant: 'inline',
          name: 'birthDate',
          error: !!this.getErrorMessage('birthDate'),
          helperText: this.getErrorMessage('birthDate'),
          convertToDate: true,
        },
      },
      {
        key: 'ssn',
        ssnInputComponentData: {
          onChange: handleChange,
          value: values.ssn as string,
          name: 'ssn',
          label: 'Social Security Number',
          onBlur: handleBlur,
          error: this.getErrorMessage('ssn'),
          editable: ssnEditable,
          toggleEditable: this.toggleSsnEditable,
          initialValue: this.state.initialSsn,
        },
      },
      {
        key: 'beneficiaryType',
        selectComponentData: {
          options: beneficiaryTypeOptions,
          value: values.beneficiaryType,
          name: 'beneficiaryType',
          onChange: handleChange,
          label: 'Type',
          onBlur: handleBlur,
          error: !!this.getErrorMessage('beneficiaryType'),
          helperText: this.getErrorMessage('beneficiaryType'),
        },
      },
      {
        key: 'share',
        inputComponentData: {
          label: 'Share %',
          name: 'share',
          value: values.share.toString(),
          onChange: this.setShareValue(maxShared, 0),
          readOnly: disableShared,
          error: sharedError,
        },
      },
    ];
  };

  dependentsToOptions = (dependents: DependentInterface[]): IBasicOption[] => {
    return dependents.map((dependent) => {
      const dependentRelation = dependent.relationshipTypeId
        ? +dependent.relationshipTypeId
        : null;
      const relation = this.props.beneficiaryRelationshipType.find(
        (rel) => rel.value === dependentRelation
      );
      return {
        value: dependent.id,
        label: `${dependent.firstName} ${dependent.lastName} (${relation?.label})`,
      };
    });
  };

  setPrefillDependent = (option?: IBasicOption) => {
    if (option) {
      this.props.setFieldValue('dependentForPrefillId', option.value);
    }
  };

  renderPrefillOptions = () => {
    const { dependents, beneficiary, values } = this.props;
    if (!Array.isArray(dependents) || !dependents.length || beneficiary?.id) {
      return null;
    }

    const options = this.dependentsToOptions(dependents);

    const fields: FieldsInterface[] = [
      {
        key: 'prefill',
        selectorComponentData: {
          options,
          value: options.find(
            (option) => option.value === values.dependentForPrefillId
          ),
          name: 'dependentForPrefillId',
          onChange: this.setPrefillDependent,
          placeholder: 'Dependent',
          typeInput: 'info',
        },
      },
    ];

    return (
      <SimpleAlert title={BENEFICIARY_DEPENDENT_EXPLANATION} type="info">
        <FillFields fields={fields} />
      </SimpleAlert>
    );
  };

  renderActions = () => {
    return (
      <SubmitButton
        label="Submit"
        variant="contained"
        onClick={this.props.handleSubmit}
      />
    );
  };

  onClose = () => {
    this.props.handleClose && this.props.handleClose();
    this.props.resetForm();
  };

  render() {
    const { beneficiary } = this.props;
    const modalTitle = beneficiary ? 'Edit Beneficiary' : 'Add Beneficiary';

    return (
      <ModalWindow
        {...this.props}
        handleClose={this.onClose}
        title={modalTitle}
        actions={this.renderActions()}
        fullWidth
        dividers
      >
        {this.renderPrefillOptions()}
        <FillFields fields={this.getFields()} />
      </ModalWindow>
    );
  }
}

const mapStateToProps = (
  store: AppStoreInterface
): IAddBeneficiaryModalPropsFromState => ({
  dependents: store.userRegistration.dependents,
  beneficiaryRelationshipType: store.options.beneficiaryRelationshipType,
  beneficiaryTypeOptions: store.options.beneficiaryType,
});

const mapDispatchToProps = (
  dispatch: Dispatch
): IAddBeneficiaryModalActions => ({
  actions: bindActionCreators<{}, IAddBeneficiaryModalActions['actions']>(
    {
      fetchBeneficiaryRelationshipTypeOptions: fetchBeneficiaryRelationshipTypeOptions,
    },
    dispatch
  ),
});

const mapPropsToValues = (props: IAddBeneficiaryModalProps): IFormikValues => {
  const { beneficiary } = props;

  return {
    firstName: beneficiary?.firstName || '',
    lastName: beneficiary?.lastName || '',
    ssn: beneficiary?.ssn || '',
    relationshipId: beneficiary?.relationshipId || '',
    relationshipTypeId: beneficiary?.relationshipTypeId || '',
    birthDate: beneficiary?.birthDate || null,
    beneficiaryType: beneficiary?.beneficiaryType || '',
    share: beneficiary?.share.toString() || '',
  };
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Field is required'),
  lastName: yup.string().required('Field is required'),
  ssn: validationHelper.ssn,
  relationshipTypeId: yup.string().required('Field is required'),
  birthDate: validationHelper.date(),
  beneficiaryType: yup.string().required('Field is required'),
  share: yup
    .string()
    .matches(/^(100)|([1-9][0-9]?)$/)
    .required('Field is required'),
  relationshipId: yup.string().optional(),
  dependentForPrefillId: yup.string().optional(),
});

const handleSubmit = async (
  values: IFormikValues,
  bag: FormikBag<IAddBeneficiaryModalProps, IFormikValues>
) => {
  const { props, resetForm } = bag;

  if (typeof props.onSubmit === 'function') {
    const data: BeneficiaryInterfaceWithoutId = {
      ...values,
      share: +values.share,
    };
    await props.onSubmit(
      props.beneficiary
        ? Object.assign(data, {
            id: props.beneficiary.id,
            relationshipId: props.beneficiary.relationshipId,
          })
        : Object.assign(data, { relationshipId: values.dependentForPrefillId })
    );
    resetForm();
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik<AllAddBeneficiaryModalProps, IFormikValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
  })(AddBeneficiaryModal)
);
