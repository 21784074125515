import React, { useState, FocusEvent, ChangeEvent, useEffect } from 'react';
import {
  Box,
  ListItemText,
  List,
  Typography,
  ListItem,
  Link,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useSelector } from 'react-redux';

import { w4Link } from 'constants/onboarding';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import Input from 'components/Forms/Input/InputComponent';
import StepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import Selector from 'components/Forms/Selector/SelectorComponent';
import IBasicOption from 'models/IBasicOption';
import CheckBox from 'components/Forms/Checkbox/CheckboxComponent';
import onboardingDomain from 'api/domains/onboarding';
import { AppStoreInterface } from 'store/app';
import IOnboardingStepProps from 'models/IOnboardingStepProps';
import { getCurrentYear, convertProps, convertToDomain } from './helpers';

import styles from './styles.module.scss';

const TaxWithholding: React.FC<IOnboardingStepProps> = ({
  index,
  count,
  setIsLoading,
  moveForward,
  moveBack,
}) => {
  const [filingOptions, setFilingOptions] = useState<IBasicOption[]>([]);
  const [filingValue, setFilingValue] = useState<IBasicOption>();
  const [filingError, setFilingError] = useState('');

  const [allowancesNumber, setAllowancesNumber] = useState<string | null>(null);
  const [allowancesNumberError, setAllowancesNumberError] = useState('');

  const [amount, setAmount] = useState<string | null>(null);
  const [amountError, setAmountError] = useState('');

  const [exemptionClaimed, setExemptionClaimed] = useState(false);

  const { w4FilingStatus } = useSelector(
    (state: AppStoreInterface) => state.options
  );

  useEffect(() => {
    setFilingOptions(w4FilingStatus);
  }, [w4FilingStatus]);

  useEffect(() => {
    (async () => {
      try {
        await setIsLoading(true);
        const { data } = await onboardingDomain.getTaxWithholding();
        const {
          federalWithholdingAllowance,
          federalFilingStatus,
          federalAdditionalWithholding,
          federalExemption,
        } = convertProps(data);

        setAllowancesNumber(federalWithholdingAllowance);
        setFilingValue(
          w4FilingStatus.find(({ value }) => value === federalFilingStatus)
        );
        setAmount(federalAdditionalWithholding);
        setExemptionClaimed(federalExemption);
      } catch (err) {
        console.error(err);
      } finally {
        await setIsLoading(false);
      }
    })();
  }, [setIsLoading, w4FilingStatus]);

  const handleSubmit = async () => {
    if (!filingValue) {
      setFilingError('Filing Status Is Required');
      return;
    }
    if (!allowancesNumber) {
      setAllowancesNumberError('Allowances Number Is Required');
      return;
    }

    const dataToSave = convertToDomain({
      federalFilingStatus: filingValue.value,
      federalWithholdingAllowance: allowancesNumber,
      federalAdditionalWithholding: amount,
      federalExemption: exemptionClaimed,
    });

    try {
      await setIsLoading(true);
      await onboardingDomain.saveTaxWithholding(dataToSave);
      await moveForward();
    } catch (error) {
      console.error(error);
    } finally {
      await setIsLoading(false);
    }
  };

  const renderStepperButtons = () => {
    return (
      <StepperButtons
        handleNext={() => {
          handleSubmit();
        }}
        handleBack={moveBack}
        hideBackBtn={index === 3}
      />
    );
  };

  const handleAllowancesNumberBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value) {
      setAllowancesNumberError('Allowances Number Is Required');
    } else if (Number(value) < 0) {
      setAllowancesNumberError('Allowances Number Cannot Be Less Than 0');
    } else {
      setAllowancesNumberError('');
    }
  };

  const handleAmountBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.startsWith('-')) {
      setAmountError('Amount Cannot Be Less Than 0');
    } else {
      setAmountError('');
    }
  };

  const onClaimChecked = (checked: boolean) => {
    setAllowancesNumberError('');
    setExemptionClaimed(checked);
  };

  return (
    <>
      <ContentTableBox
        title="Tax Withholding"
        index={index}
        count={count}
        isBox
      >
        <Box className={styles.main}>
          <Box className={styles.title}>
            <Link
              underline="always"
              className={styles.link}
              onClick={() => window.open(w4Link)}
            >
              View Form W-4
            </Link>
          </Box>
          <Box className={styles.fields}>
            <Selector
              placeholder="Filing status"
              value={filingValue}
              options={filingOptions}
              onChange={(value) => {
                if (!value) {
                  setFilingError('Filing Status Is Required');
                } else {
                  setFilingError('');
                }
                setFilingValue(value);
              }}
              error={!!filingError}
              helperText={filingError}
            />
            {exemptionClaimed && (
              <Box mb="1em">
                <SimpleAlert type="info">
                  <Typography component="h5">
                    I certify that I meet both of the following conditions for
                    exemption:
                  </Typography>
                  <List disablePadding>
                    <ListItem>
                      <FiberManualRecordIcon
                        fontSize="inherit"
                        className={styles.listItemIcon}
                      />
                      <ListItemText
                        primary="Last year I had a right to a refund of all federal income tax
                  withheld because I had no tax liability."
                      />
                    </ListItem>
                    <ListItem>
                      <FiberManualRecordIcon
                        fontSize="inherit"
                        className={styles.listItemIcon}
                      />
                      <ListItemText
                        primary="This year I expect a refund of all federal income tax withheld
                    because I expect to have no tax liability."
                      />
                    </ListItem>
                  </List>
                </SimpleAlert>
              </Box>
            )}
            <Box className={styles.margin}>
              <NumberFormat
                customInput={Input}
                readOnly={exemptionClaimed}
                value={allowancesNumber || ''}
                fullWidth
                variant="outlined"
                label="Total number of allowances you are claiming"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setAllowancesNumber(e.target.value)
                }
                onBlur={handleAllowancesNumberBlur}
                error={allowancesNumberError}
                helperText={allowancesNumberError}
              />
            </Box>
            <NumberFormat
              customInput={Input}
              readOnly={exemptionClaimed}
              thousandSeparator
              prefix="$"
              value={amount || ''}
              fullWidth
              variant="outlined"
              label="Additional amount you want withheld from each paycheck"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setAmount(e.target.value)
              }
              onBlur={handleAmountBlur}
              error={amountError}
              helperText={amountError}
            />
            <CheckBox
              checked={exemptionClaimed}
              onChange={onClaimChecked}
              name="claimExemption"
              label={`I claim exemption from withholding for ${getCurrentYear()}`}
            />
          </Box>
        </Box>
      </ContentTableBox>
      {renderStepperButtons()}
    </>
  );
};

export default TaxWithholding;
