import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { isEqual } from 'lodash';
import {
  Box,
  SvgIcon,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  IconButton,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import Skeleton from 'components/Skeleton';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import StepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import { ReactComponent as deleteIcon } from 'assets/trash.svg';
import { ReactComponent as editIcon } from 'assets/pencil.svg';
import { loadUserBeneficiaries } from 'redux/sagas/userRegistration/routines';

import beneficiariesDomain from 'api/domains/beneficiaries';
import { AppStoreInterface } from 'store/app';
import { fromBeneficiaryToTableBeneficiary } from 'mappers/beneficiary/fromBeneficiaryToTableBeneficiary';
import {
  BeneficiaryInterface,
  TableBeneficiaryData,
  ExternalBeneficiaryPayload,
} from 'types/beneficiaries';
import BeneficiaryType from 'enums/beneficiaryType';
import ICommonRegistrationStepProps from 'models/ICommonRegistrationStepProps';
import ExternalPlanType from 'enums/externalPlanType';
import { IEnrollment } from 'types/enrollments';
import { EMPTY_VALUE } from 'constants/placeholders';

import { fromBeneficiaryToExternalBeneficiaryPayload } from 'mappers/beneficiary/fromBeneficiaryToExternalBeneficiaryPayload';
import { fromExternalBeneficiaryToBeneficiary } from 'mappers/beneficiary/fromExternalBeneficiaryToBeneficiary';
import AddBeneficiaryModal from './AddBeneficiaryModal';

import styles from './styles.module.scss';

export interface IBeneficiariesSettingsProps
  extends ICommonRegistrationStepProps {
  classes: { [key: string]: string };
  beneficiaries: BeneficiaryInterface[];
  enrollments: IEnrollment[];
  loading: boolean;
  actions: {
    loadUserBeneficiaries: typeof loadUserBeneficiaries.trigger;
    addUserBeneficiaries: typeof loadUserBeneficiaries.success;
  };
}

export interface IBeneficiariesSettingsPropsFromState {
  userFirstName?: string;
}

export interface IBeneficiariesSettingsState {
  showModal: boolean;
  editedBeneficiaryId?: string;
  ssnEditable: boolean;
  /**
   * This values used to show summary percents of beneficiaries' share
   */
  primarySummary: number;
  contingentSummary: number;
  tertiarySummary: number;
}

type IBeneficiariesSettingsAllProps = IBeneficiariesSettingsProps &
  IBeneficiariesSettingsPropsFromState;

interface IMaxSharedValues {
  primaryMax: number;
  contingentMax: number;
  tertiaryMax: number;
}

interface TableBeneficiaryDataWithSummary<T = TableBeneficiaryData> {
  isSummary?: boolean;
  data: Partial<T>;
}

class BeneficiariesSettings extends React.Component<
  IBeneficiariesSettingsAllProps,
  IBeneficiariesSettingsState
> {
  constructor(props: IBeneficiariesSettingsAllProps) {
    super(props);

    this.state = {
      showModal: false,
      ssnEditable: false,
      primarySummary: 0,
      contingentSummary: 0,
      tertiarySummary: 0,
    };
  }

  componentDidUpdate(prevProps: IBeneficiariesSettingsAllProps) {
    if (!isEqual(prevProps.beneficiaries, this.props.beneficiaries)) {
      this.reCalculateSummary();
    }
  }

  async componentDidMount() {
    await this.fetchBeneficiaries();
    this.reCalculateSummary();
  }

  setShowModal = (showModal: boolean) => this.setState({ showModal });

  apiAction = async (action: () => Promise<void>, errorMessage?: string) => {
    const { setError, setIsLoading } = this.props;
    try {
      setIsLoading(true);
      await action();
    } catch (e) {
      console.error(e);
      setError(errorMessage || 'Server error');
    } finally {
      setIsLoading(false);
    }
  };

  createBeneficiary = async (payload: ExternalBeneficiaryPayload) => {
    await this.apiAction(async () => {
      const { data } = await beneficiariesDomain.create(payload);
      this.props.actions.addUserBeneficiaries([
        ...this.props.beneficiaries.map((beneficiary) => ({ ...beneficiary })),
        fromExternalBeneficiaryToBeneficiary(data),
      ]);
    }, 'Cannot create beneficiary');
  };

  updateBeneficiary = async (
    id: string,
    payload: ExternalBeneficiaryPayload
  ) => {
    await this.apiAction(async () => {
      await beneficiariesDomain.update(id, payload);
      this.props.actions.addUserBeneficiaries(
        this.props.beneficiaries.map((beneficiary) =>
          beneficiary.id === id
            ? fromExternalBeneficiaryToBeneficiary(
                Object.assign(payload, { id })
              )
            : { ...beneficiary }
        )
      );
      this.setState({ editedBeneficiaryId: undefined });
    }, 'Cannot update beneficiary');
  };

  deleteBeneficiary = async (id: string) => {
    await this.apiAction(async () => {
      await beneficiariesDomain.delete(id);
      this.props.actions.addUserBeneficiaries(
        this.props.beneficiaries.filter(
          (prevBeneficiary) => prevBeneficiary.id !== id
        )
      );
    }, 'Cannot delete beneficiary');
  };

  onSubmitBeneficiary = async (newBeneficiary: BeneficiaryInterface) => {
    this.setShowModal(false);
    if (!newBeneficiary.id) {
      await this.createBeneficiary(
        fromBeneficiaryToExternalBeneficiaryPayload(newBeneficiary)
      );
    } else {
      await this.updateBeneficiary(
        newBeneficiary.id,
        fromBeneficiaryToExternalBeneficiaryPayload(newBeneficiary)
      );
    }
  };

  fetchBeneficiaries = async () => {
    await this.apiAction(async () => {
      await this.props.actions.loadUserBeneficiaries();
    });
  };

  onEditBeneficiaryClick = (editedBeneficiaryId: string) => {
    this.setState({ editedBeneficiaryId, showModal: true });
  };

  onAddBeneficiaryClick = () => {
    this.setState(
      { editedBeneficiaryId: undefined },
      this.setShowModal.bind(this, true)
    );
  };

  handleModalClose = () => {
    this.setState({ showModal: false, ssnEditable: false });
  };

  toggleSsnEditableWithCb = (cb: (ssnEditable: boolean) => void) => {
    this.setState(
      (prevState) => ({ ssnEditable: !prevState.ssnEditable }),
      () => {
        cb(this.state.ssnEditable);
      }
    );
  };

  reCalculateSummary = () => {
    const { beneficiaries } = this.props;

    let primarySummary = 0;
    let contingentSummary = 0;
    let tertiarySummary = 0;

    beneficiaries.forEach((beneficiary) => {
      if (beneficiary.beneficiaryType === BeneficiaryType.Primary) {
        primarySummary += beneficiary.share;
      } else if (beneficiary.beneficiaryType === BeneficiaryType.Contingent) {
        contingentSummary += beneficiary.share;
      } else {
        tertiarySummary += beneficiary.share;
      }
    });

    this.setState({ primarySummary, contingentSummary, tertiarySummary });
  };

  getMaxSharedValues = (
    editedBeneficiary?: BeneficiaryInterface
  ): IMaxSharedValues => {
    const { primarySummary, contingentSummary, tertiarySummary } = this.state;

    const editedCoef = editedBeneficiary ? editedBeneficiary.share : 0;
    return {
      primaryMax:
        100 -
        primarySummary +
        (editedBeneficiary?.beneficiaryType === BeneficiaryType.Primary
          ? editedCoef
          : 0),
      contingentMax:
        100 -
        contingentSummary +
        (editedBeneficiary?.beneficiaryType === BeneficiaryType.Contingent
          ? editedCoef
          : 0),
      tertiaryMax:
        100 -
        tertiarySummary +
        (editedBeneficiary?.beneficiaryType === BeneficiaryType.Tertiary
          ? editedCoef
          : 0),
    };
  };

  renderDescription() {
    const { userFirstName } = this.props;
    const message = `${
      userFirstName ? `${userFirstName}, please` : 'Please'
    } enter
      the information about your life insurance beneficiaries`;

    return (
      <SimpleAlert
        className={styles.addBeneficiariesStepItem}
        message={message}
        type="info"
      />
    );
  }

  renderNotRequiredInfo() {
    const { userFirstName } = this.props;
    const message = `${
      userFirstName ? `${userFirstName}, at` : 'At'
    } this time we don't need to collect information about your beneficiaries. You can proceed to the next step.`;

    return (
      <SimpleAlert
        className={styles.addBeneficiariesStepItem}
        message={message}
        type="info"
      />
    );
  }

  isSummaryChecker = (
    row: TableBeneficiaryDataWithSummary,
    type: BeneficiaryType,
    defaultValue: number
  ) => {
    return row.isSummary
      ? `${defaultValue}%`
      : row.data.beneficiaryType === type
      ? `${row.data.share}%`
      : EMPTY_VALUE;
  };

  renderTable() {
    const { primarySummary, contingentSummary, tertiarySummary } = this.state;
    const data: TableBeneficiaryDataWithSummary[] = this.props.beneficiaries.map(
      (beneficiary) => ({
        data: fromBeneficiaryToTableBeneficiary(beneficiary),
      })
    );

    /**
     * Add final summary row, if we have data
     */
    if (data.length) {
      data.push({ isSummary: true, data: {} });
    }
    return (
      <TableContainer component={Box}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>Beneficiary</TableCell>
              <TableCell>Primary</TableCell>
              <TableCell>Contingent</TableCell>
              <TableCell>Tertiary</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.data.id}>
                <TableCell
                  className={row.isSummary ? styles.footer : styles.custom}
                  component="th"
                  scope="row"
                >
                  {row.isSummary
                    ? 'Total share'
                    : `${row.data.firstName} ${row.data.lastName}`}
                </TableCell>
                <TableCell
                  className={row.isSummary ? styles.footer : styles.custom}
                  component="th"
                  scope="row"
                >
                  {this.isSummaryChecker(
                    row,
                    BeneficiaryType.Primary,
                    primarySummary
                  )}
                </TableCell>
                <TableCell
                  className={row.isSummary ? styles.footer : styles.custom}
                  component="th"
                  scope="row"
                >
                  {this.isSummaryChecker(
                    row,
                    BeneficiaryType.Contingent,
                    contingentSummary
                  )}
                </TableCell>
                <TableCell
                  className={row.isSummary ? styles.footer : styles.custom}
                  component="th"
                  scope="row"
                >
                  {this.isSummaryChecker(
                    row,
                    BeneficiaryType.Tertiary,
                    tertiarySummary
                  )}
                </TableCell>
                <TableCell
                  className={row.isSummary ? styles.footer : styles.custom}
                  component="th"
                  scope="row"
                >
                  {!row.isSummary && (
                    <>
                      <IconButton
                        onClick={() =>
                          this.deleteBeneficiary(row.data?.id as string)
                        }
                        component="span"
                      >
                        <SvgIcon
                          component={deleteIcon}
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                        />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          this.onEditBeneficiaryClick(row.data?.id as string)
                        }
                        component="span"
                      >
                        <SvgIcon
                          component={editIcon}
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                        />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  isValid = (): [boolean, string | undefined] => {
    const { beneficiaries } = this.props;
    const { primarySummary, contingentSummary, tertiarySummary } = this.state;

    let contingentBeneficiariesCount = 0;
    let tertiaryBeneficiariesCount = 0;

    for (const beneficiary of beneficiaries) {
      if (beneficiary.beneficiaryType === BeneficiaryType.Contingent) {
        contingentBeneficiariesCount++;
      }
      if (beneficiary.beneficiaryType === BeneficiaryType.Tertiary) {
        tertiaryBeneficiariesCount++;
      }
    }

    if (contingentBeneficiariesCount && contingentSummary !== 100) {
      return [false, 'Total contingent share must equal 100%'];
    }
    if (tertiaryBeneficiariesCount && tertiarySummary !== 100) {
      return [false, 'Total tertiary share must equal 100%'];
    }
    if (primarySummary !== 100) {
      return [false, 'Total primary share must equal 100%'];
    }
    return [true, undefined];
  };

  renderStepperButtons = (isEnrolledInLivePlan: boolean) => {
    const { moveForward, moveBack } = this.props;
    const [isValid] = this.isValid();

    return (
      <StepperButtons
        handleNext={moveForward}
        handleBack={moveBack}
        disabledNext={isEnrolledInLivePlan ? !isValid : false}
      />
    );
  };

  checkLifePlanEnrollingStatus = (enrollments: IEnrollment[]) => {
    const lifePlan = enrollments.filter(
      (enrollment) => enrollment.planTypeId === ExternalPlanType.Live
    );
    const voluntaryPlan = enrollments.filter(
      (enrollment) => enrollment.planTypeId === ExternalPlanType.VoluntaryLife
    );
    let checkVoluntary = false;
    let checkLife = false;
    if (voluntaryPlan.length !== 0) {
      checkVoluntary =
        voluntaryPlan.filter((plan) => +plan.status > 0).length !== 0;
    }
    if (lifePlan.length !== 0) {
      checkLife = lifePlan.filter((plan) => +plan.status > 0).length !== 0;
    }

    return checkLife || checkVoluntary;
  };

  render() {
    const { classes, beneficiaries, enrollments, loading } = this.props;
    const { editedBeneficiaryId, showModal, ssnEditable } = this.state;

    const isEnrolledInLivePlan = this.checkLifePlanEnrollingStatus(enrollments);
    const editedBeneficiary = editedBeneficiaryId
      ? beneficiaries.find((b) => b.id === editedBeneficiaryId)
      : undefined;
    const { primaryMax, contingentMax, tertiaryMax } = this.getMaxSharedValues(
      editedBeneficiary
    );
    const [isValid, errorMessage] = this.isValid();

    const showError =
      !isValid && !!beneficiaries.length && isEnrolledInLivePlan;

    if (loading) {
      return <Skeleton variant="rect" height="4.7em" />;
    }

    return (
      <>
        <ContentTableBox title="Beneficiaries" index={5} count={8} isBox>
          {isEnrolledInLivePlan ? (
            <>
              {this.renderDescription()}
              <Box className={styles.addBeneficiariesStepItem}>
                <SubmitButton
                  className={styles.btnAdd}
                  label="Add Beneficiary"
                  icon={<Add />}
                  iconPosition="left"
                  onClick={this.onAddBeneficiaryClick}
                />
              </Box>
              {this.renderTable()}
              {showError && (
                <SimpleAlert
                  className={classes.errorMessage}
                  type="error"
                  message={errorMessage}
                />
              )}
              <AddBeneficiaryModal
                className={styles.addBeneficiariesStepItem}
                isOpen={showModal}
                handleClose={this.handleModalClose}
                onSubmit={this.onSubmitBeneficiary}
                ssnEditable={ssnEditable}
                toggleSsnEditableWithCb={this.toggleSsnEditableWithCb}
                beneficiary={editedBeneficiary}
                primaryMax={primaryMax}
                contingentMax={contingentMax}
                tertiaryMax={tertiaryMax}
              />
            </>
          ) : (
            this.renderNotRequiredInfo()
          )}
        </ContentTableBox>
        {this.renderStepperButtons(isEnrolledInLivePlan)}
      </>
    );
  }
}

const mapStateToProps = (store: AppStoreInterface) => ({
  userFirstName: store.userRegistration.profile.firstName,
  beneficiaries: store.userRegistration.beneficiaries,
  enrollments: store.userRegistration.enrollments,
  loading: store.userRegistration.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      loadUserBeneficiaries: typeof loadUserBeneficiaries.trigger;
      addUserBeneficiaries: typeof loadUserBeneficiaries.success;
    }
  >(
    {
      loadUserBeneficiaries: loadUserBeneficiaries.trigger,
      addUserBeneficiaries: loadUserBeneficiaries.success,
    },
    dispatch
  ),
});
export default withStyles({
  errorMessage: { marginTop: '1rem' },
})(connect(mapStateToProps, mapDispatchToProps)(BeneficiariesSettings));
