import { IOpenEnrollment } from 'types/statusDashboard';
import { ITransactionsReport } from 'types/transactions';
import api from '../adapter';

class TransactionsReportDomain {
  getList() {
    return api.makeRequest<IOpenEnrollment[]>('/transactions', 'GET');
  }

  getOne(id: string | number) {
    return api.makeRequest<ITransactionsReport>(`/transactions/${id}`, 'GET');
  }

  download(id: string | number) {
    return api.makeRequest<Blob>(
      `/transactions/${id}/download`,
      'GET',
      null,
      undefined,
      null,
      {
        responseType: 'blob',
      }
    );
  }
}

export default new TransactionsReportDomain();
