/**
 * This enum contain the most important plan type's ids, that used in application
 */
enum ExternalPlanType {
  Medical = '1',
  Dental = '2',
  Live = '3',
  Vision = '8',
  HRA = '19',
  HSA = '20',
  FSA = '12',
  VoluntaryLife = '33',
  VoluntaryDental = '32',
  WorksiteBenefitsDental = '42',
  VoluntaryVision = '38',
  WorksiteBenefitsVision = '48',
}

export default ExternalPlanType;
