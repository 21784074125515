import React from 'react';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as fileIcon } from 'assets/file.svg';
import Backdrop from 'components/Backdrop';
import { IMedicalPlan } from 'types/plans';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import Disclaimer from './Disclaimer';
import CoverageTable from './CoverageTable';

import styles from './styles.module.scss';

interface IProps {
  plan: IMedicalPlan;
  isFileLoading: boolean;
  handleOpenFile: () => Promise<void>;
  employeeId?: string | number | null;
}

const GroupMedicalPlan: React.FC<IProps> = ({
  plan,
  handleOpenFile,
  isFileLoading,
  employeeId,
}) => {
  return (
    <>
      <Backdrop open={isFileLoading} />
      {plan.fileId && (
        <Box className={styles.fileLinkContainer}>
          <SvgIcon
            component={fileIcon}
            width="19"
            height="21"
            viewBox="0 0 19 21"
          />
          <Link className={styles.fileLink} onClick={handleOpenFile}>
            View Summary of Benefits and Coverage (SBC)
          </Link>
        </Box>
      )}
      <Box className={styles.planNameContainer}>
        <Typography variant="h6" className={styles.title}>
          <PlanIcons planTypeId={Number(plan.planTypeId)} />
          {plan.title}
        </Typography>
        <Typography className={styles.mblComment} variant="subtitle1">
          {plan.mblComment}
        </Typography>
      </Box>

      <Box>
        <TableContainer className={styles.planTableContainer} component={Box}>
          <Table className={styles.planTable}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>In Network</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell scope="row">Deductible (single / family):</TableCell>
                <TableCell scope="row">{plan.inDeductible}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell scope="row">Coinsurance:</TableCell>
                <TableCell scope="row">{plan.inCoinsurance}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell scope="row">
                  Maximum out of pocket (single / family):
                </TableCell>
                <TableCell scope="row">{plan.inMoop}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell scope="row">
                  Primary Care Physician (PCP) copay:
                </TableCell>
                <TableCell scope="row">{plan.inPcpCopay}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell scope="row">Specialist copay:</TableCell>
                <TableCell scope="row">{plan.inSpecialistCopay}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell scope="row">Inpatient hospital copay:</TableCell>
                <TableCell scope="row">{plan.inHospitalCopay}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell scope="row">Emergency room copay:</TableCell>
                <TableCell scope="row">{plan.inEmergency}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell scope="row">
                  Diagnostic testing - Laboratory:
                </TableCell>
                <TableCell scope="row">{plan.inLaboratory}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell scope="row">Diagnostic testing - X-ray:</TableCell>
                <TableCell scope="row">{plan.inXray}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell scope="row">Imaging:</TableCell>
                <TableCell scope="row">{plan.inImaging}</TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box>
        <TableContainer className={styles.planTableContainer} component={Box}>
          <Table className={styles.planTable}>
            <TableHead>
              <TableRow>
                {plan.outDeductible && <TableCell />}
                <TableCell colSpan={plan.outDeductible ? 1 : 2}>
                  Out of Network
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {plan.outDeductible ? (
                <>
                  <TableRow>
                    <TableCell scope="row">
                      Deductible (single / family):
                    </TableCell>
                    <TableCell scope="row">{plan.outDeductible}</TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Coinsurance:</TableCell>
                    <TableCell scope="row">{plan.outCoinsurance}</TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">
                      Maximum out of pocket (single / family):
                    </TableCell>
                    <TableCell scope="row">{plan.outMoop}</TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">UCR level:</TableCell>
                    <TableCell scope="row">{plan.outUcrLevel}</TableCell>
                    <TableCell />
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={2} scope="row">
                    This plan does not provide out-of-network coverage, except
                    in the case of a defined emergency.
                  </TableCell>
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <TableContainer className={styles.planTableContainer} component={Box}>
        <Table className={styles.planTable}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Pharmacy Benefits</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell className={styles.subHeader}>Retail</TableCell>
              <TableCell className={styles.subHeader}>
                {plan.rxMailOrderGeneric ? 'Mail order' : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Generic:</TableCell>
              <TableCell scope="row">{plan.rxRetailGeneric}</TableCell>
              <TableCell scope="row">{plan.rxMailOrderGeneric}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Preferred:</TableCell>
              <TableCell scope="row">{plan.rxRetailPreferred}</TableCell>
              <TableCell scope="row">{plan.rxMailOrderPreferred}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Non-preferred:</TableCell>
              <TableCell scope="row">{plan.rxRetailNonpreferred}</TableCell>
              <TableCell scope="row">{plan.rxMailOrderNonpreferred}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Deductible:</TableCell>
              <TableCell scope="row">{plan.rxRetailDeductible}</TableCell>
              <TableCell scope="row">{plan.rxMailOrderDeductible}</TableCell>
            </TableRow>
            <CoverageTable plan={plan} employeeId={employeeId} />
          </TableBody>
        </Table>
      </TableContainer>
      <Disclaimer />
    </>
  );
};

export default GroupMedicalPlan;
