import React from 'react';
import Box from '@material-ui/core/Box';

import Skeleton from 'components/Skeleton';

const PlanSkeleton: React.FC = () => {
  return (
    <>
      <Skeleton variant="text" width="20.25em" height="1.5em" />
      <Box margin="0.6em 0">
        <Skeleton variant="text" width="16.625em" height="2em" />
      </Box>
      <Skeleton variant="rect" height="38em" />
    </>
  );
};

export default PlanSkeleton;
