import { Location } from 'history';

import storage from 'helpers/storage';
import { REGISTRATION_CODE_KEY } from 'constants/queryParams';
import { REGISTRATION_PATH } from 'constants/routes';
import getUrlSearchParam from 'helpers/urlSearchParams';

export const beforeMatch = (location: Location) => {
  const token = getUrlSearchParam(location.search, REGISTRATION_CODE_KEY);

  /**
   * If token is exist, then we clear local storage,
   * cause we want to start registration proccess
   */
  const isRegistrationRoute =
    location.pathname === REGISTRATION_PATH ||
    location.pathname === `${REGISTRATION_PATH}/`;

  if (token && isRegistrationRoute) {
    storage.clear();
  }
};
