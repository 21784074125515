export enum PayrollMethodValue {
  Deposit = 1,
  Card = 2,
  Check = 3,
}
export enum AmountType {
  Percentage = 1,
  Dollars = 2,
  // TODO: Think about moving remaining amount logic on the back-end
  /**
   * Remaining Amount doesn't exist for Back-end logic,
   * but we need it for client
   */
  RemainingAmount = 3,
}

export type PayrollErrors = {
  method?: string;
  amount?: string;
  amountType?: string;
  routingNumber?: string;
  accountNumber?: string;
  accountType?: string;
};
