import React from 'react';

import ExternalPlanType from 'enums/externalPlanType';
import { IEnrollment, IUpdateEnrollmentPayload } from 'types/enrollments';
import { ICoverageAmounts, IFullPlan } from 'types/plans';
import HSAPlanForm from './HSAPlanForm';
import FSAPlanForm from './FSAPlanForm';
import DefaultPlanForm from './DefaultPlanForm';
import VoluntaryLifeForm from './VoluntaryLifeForm';

interface IProps {
  chosenPlan: string;
  currentPlanType: string;
  enrollment?: IEnrollment;
  currentPlan?: IFullPlan;
  iWantToDecline?: boolean;
  coverageAmount?: ICoverageAmounts | null;
  updateEnrollment: (
    id: string,
    enrollment: IUpdateEnrollmentPayload
  ) => void | Promise<void>;
  setChoosedDependents: (dependents: string[]) => void;
}

const PlanFormFactory: React.FC<IProps> = (props) => {
  const {
    chosenPlan,
    currentPlanType,
    enrollment,
    currentPlan,
    iWantToDecline,
    updateEnrollment,
    coverageAmount,
    setChoosedDependents,
  } = props;
  switch (currentPlanType) {
    case ExternalPlanType.FSA:
      return (
        <FSAPlanForm
          planId={chosenPlan as string}
          enrollment={enrollment}
          updateEnrollment={updateEnrollment}
        />
      );
    case ExternalPlanType.HSA:
      return (
        <HSAPlanForm
          planId={chosenPlan as string}
          enrollment={enrollment}
          updateEnrollment={updateEnrollment}
        />
      );
    default:
      return currentPlan?.ageBandedRateType === '2' &&
        currentPlan?.tierStructure === '5' ? (
        <VoluntaryLifeForm
          planTypeId={currentPlanType}
          planId={chosenPlan as string}
          enrollment={enrollment}
          updateEnrollment={updateEnrollment}
          coverageAmount={coverageAmount}
        />
      ) : (
        <DefaultPlanForm
          currentPlan={currentPlan}
          enrollment={enrollment}
          updateEnrollment={updateEnrollment}
          iWantToDecline={iWantToDecline}
          currentPlanType={currentPlanType}
          setChoosedDependents={setChoosedDependents}
        />
      );
  }
};

export default PlanFormFactory;
