import { IReport, IReportData } from 'types/reports';
import OrderDirection from 'enums/orderDirection';
import api from '../adapter';

class ReportsDomain {
  getList() {
    return api.makeRequest<IReport[]>('/reports', 'GET');
  }

  getOne(
    id: string | number,
    orderBy?: string,
    orderDirection?: OrderDirection
  ) {
    let params = '';
    if (orderBy && orderDirection) {
      params = `?orderBy=${orderBy}&orderDirection=${orderDirection}`;
    }
    return api.makeRequest<IReportData>(`/reports/${id}${params}`, 'GET');
  }

  deleteReport(id: string | number) {
    return api.makeRequest<IReport>(`/reports/${id}`, 'DELETE');
  }

  downloadExcel(
    id: string | number,
    orderBy?: string,
    orderDirection?: OrderDirection
  ) {
    return api.makeRequest<Blob>(
      `/reports/${id}/download${
        orderBy && orderDirection
          ? `?orderBy=${orderBy}&orderDirection=${orderDirection}`
          : ''
      }`,
      'GET',
      null,
      undefined,
      null,
      { responseType: 'blob' }
    );
  }
}

export default new ReportsDomain();
