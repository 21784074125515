import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as visibilityIcon } from 'assets/visibility.svg';
import { ReactComponent as visibilityOffIcon } from 'assets/visibilityOff.svg';
import Input, { InputPropsInterface } from './InputComponent';

type PasswordInputPropsInterface = Omit<
  InputPropsInterface,
  'secured' | 'icon' | 'iconOnClick'
>;

const PasswordInput: React.FC<PasswordInputPropsInterface> = (props) => {
  const [secured, setSecured] = React.useState(true);

  const icon = secured ? (
    <SvgIcon
      component={visibilityIcon}
      width="22"
      height="20"
      viewBox="0 0 22 20"
    />
  ) : (
    <SvgIcon
      component={visibilityOffIcon}
      width="22"
      height="15"
      viewBox="0 0 22 15"
    />
  );
  const iconOnClick = () => setSecured(!secured);

  return (
    <Input {...props} secured={secured} icon={icon} iconOnClick={iconOnClick} />
  );
};

export default PasswordInput;
