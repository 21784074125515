import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import fileDomain from 'api/domains/file';
import { IEmployeeFile, IShortFile } from 'types/files';

import styles from './styles.module.scss';

interface IProps {
  files?: IShortFile[] | IEmployeeFile[] | null;
  setLoading: (value: React.SetStateAction<boolean>) => void;
}

const AdminFilesList: React.FC<IProps> = ({ files, setLoading }) => {
  const handleOpenFileInNewTab = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await fileDomain.downloadFile(id);
      const fileURL = URL.createObjectURL(data);
      window.open(fileURL);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  /*
  const renderCategory = (files: IEmployeeFile[], file: IEmployeeFile) => {
    if (!file.categoryName) return null;
    let shouldRender = false;
    if (files[0].categoryName === 'Other') shouldRender = true;
    if (!category.current) {
      category.current = file.categoryName;
      shouldRender = true;
    } else {
      if (category.current !== file.categoryName) {
        category.current = file.categoryName;
        shouldRender = true;
      }
    }
    return shouldRender ? (
      <>
        <TableRow component="th" scope="row">
          {file.categoryName}
        </TableRow>
        <Divider />
      </>
    ) : null;
  };
  */

  return (
    <>
      {files && files.length ? (
        <TableContainer component={Box}>
          <Table className={styles.filesAdminTable}>
            <TableBody>
              {(files as IShortFile[]).map((file, i) => (
                <>
                  <TableRow
                    onClick={() => handleOpenFileInNewTab(file.id)}
                    key={i}
                  >
                    <TableCell component="th" scope="row">
                      {file.title}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box className={styles.noData}>
          <Typography variant="body1" className={styles.placeholder}>
            No files are available at this time
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AdminFilesList;
