import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';

import dependentDomain from 'api/domains/dependents';
import { ReactComponent as warningIcon } from 'assets/warning.svg';
import IBasicOption from 'models/IBasicOption';
import PasswordPrompt from 'components/PasswordPrompt';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import CensusTabValue from 'enums/censusTabValue';
import { IEmployee } from 'types/employee';
import { DependentInterface, IDependentPersonal } from 'types/dependents';
import { IPlanSummary } from 'types/plans';
import { IEmployeeFile } from 'types/files';
import { makeStyles } from '@material-ui/core';

import { Task } from 'types/enrollments';
import { ExternalBeneficiaryInterface } from 'types/beneficiaries';
import Files from './Files';
import Enrollments from './Enrollments';
import EmployeePanelSkeleton from './EmployeePanelSkeleton';
import DependentCardsList from './DependentCardsList';
import BeneficiaryCardList from './BeneficiaryCardsList';
import EmployeeCardsList from './EmployeeCardsList';

import styles from './styles.module.scss';

interface IProps {
  loading: boolean;
  error: string | null;
  maritalStatus: IBasicOption<number>[];
  gender: IBasicOption<number>[];
  employmentStatus: IBasicOption<number>[];
  accessGroup: IBasicOption<number>[];
  tabValue: number;
  employee: IEmployee | null;
  dependents: DependentInterface[] | null;
  enrollments: IPlanSummary[] | null;
  files: IEmployeeFile[] | null;
  selectedEmployeeId: string | null;
  enrollmentsIssues?: Task[];
  handleTabChange: (_: React.ChangeEvent<{}>, newValue: number) => void;
  addNewFile: (newFile: IEmployeeFile) => void;
  openEditEmployeeModal: () => void;
  handleLogInAsEmployee: () => Promise<void>;
  setTerminateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  logInAsEmployeeBtnVisible: boolean;
  isCurrentUser: boolean;
  checkEmployeeIsActive: () => boolean;
  testUser: string | null;
  impersonatingUserId: string | null;
  beneficiaries: ExternalBeneficiaryInterface[] | null;
}

const useStyles = makeStyles({
  tab: {
    marginTop: '1em',
    height: '50px',
    '& .MuiTabs-flexContainer': {
      overflow: 'auto',
    },
    '& .MuiTypography-body1': {
      color: '#9FA2B4',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      display: 'flex',
    },
  },
  indicator: {
    display: 'none',
  },
  flexContainer: {
    overflow: 'auto',
  },
  selected: {
    borderRadius: '4px',
    background: '#d2dcec',
    '& .MuiTypography-body1': {
      color: '#4979C0',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  icon: {
    marginRight: '3px',
  },
});

const EmployeePanel: React.FC<IProps> = (props) => {
  const {
    maritalStatus,
    gender,
    loading,
    error,
    tabValue,
    employee,
    dependents,
    selectedEmployeeId,
    enrollmentsIssues,
    employmentStatus,
    accessGroup,
    enrollments,
    files,
    handleTabChange,
    openEditEmployeeModal,
    addNewFile,
    handleLogInAsEmployee,
    setTerminateModalOpen,
    logInAsEmployeeBtnVisible,
    isCurrentUser,
    checkEmployeeIsActive,
    testUser,
    impersonatingUserId,
    beneficiaries,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [passwordPromptOpen, setPasswordPromptOpen] = useState(false);
  const [personalData, setPersonalData] = useState<IDependentPersonal[]>([]);
  const [hideSSn, setHideSsn] = useState<boolean>(true);

  const handleShowPersonalData = async (password: string) => {
    try {
      const { data } = await dependentDomain.getPersonalDataList(password);
      setPersonalData(data);
      setHideSsn(false);
    } catch (err) {
      throw err;
    }
  };

  const handleHidePersonalData = () => {
    setPersonalData([]);
    setHideSsn(true);
  };

  const isPasswordPrompt = !(
    parseInt(testUser as string) || !!impersonatingUserId
  );

  const classes = useStyles();
  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const popperOpen = Boolean(anchorEl);
  const id = popperOpen ? 'enrollments-issues-popper' : undefined;
  const popperVisible = !!enrollmentsIssues && !!enrollmentsIssues.length;

  const renderPanelContent = () => {
    switch (tabValue) {
      case CensusTabValue.Personal:
        return (
          <EmployeeCardsList
            employee={employee}
            maritalStatus={maritalStatus}
            gender={gender}
            employmentStatus={employmentStatus}
            accessGroup={accessGroup}
            openEditEmployeeModal={openEditEmployeeModal}
            handleHidePersonalData={handleHidePersonalData}
            handleOpenModal={() => setPasswordPromptOpen(true)}
            handleShowPersonalData={handleShowPersonalData}
            isPasswordPrompt={isPasswordPrompt}
            personalData={personalData}
            hideSsn={hideSSn}
          />
        );
      case CensusTabValue.Dependents:
        return (
          <DependentCardsList
            dependents={dependents}
            gender={gender}
            handleHidePersonalData={handleHidePersonalData}
            handleOpenModal={() => setPasswordPromptOpen(true)}
            handleShowPersonalData={handleShowPersonalData}
            isPasswordPrompt={isPasswordPrompt}
            personalData={personalData}
            hideSsn={hideSSn}
          />
        );
      case CensusTabValue.Beneficiaries:
        return (
          <BeneficiaryCardList
            beneficiaries={beneficiaries}
            personalData={[]}
            handleOpenModal={() => setPasswordPromptOpen(true)}
            handleHidePersonalData={handleHidePersonalData}
            handleShowPersonalData={handleShowPersonalData}
            isPasswordPrompt={isPasswordPrompt}
            hideSsn={hideSSn}
          />
        );
      case CensusTabValue.Enrollments:
        return (
          <Enrollments
            employeeId={employee?.id}
            plans={enrollments}
            enrollmentsIssues={enrollmentsIssues}
          />
        );
      case CensusTabValue.Files:
        return (
          <Files
            selectedEmployeeId={selectedEmployeeId}
            files={files}
            addNewFile={addNewFile}
          />
        );
    }
  };

  if (error) {
    return (
      <Box>
        <Typography variant="h6" color="secondary">
          {error}
        </Typography>
      </Box>
    );
  }

  if (selectedEmployeeId) {
    return (
      <>
        <Box className={styles.menu}>
          <Tabs
            className={classes.tab}
            classes={{
              indicator: classes.indicator,
            }}
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            <Tab
              label={<Typography>Personal</Typography>}
              classes={{
                selected: classes.selected,
              }}
            />
            <Tab
              label={<Typography>Dependents</Typography>}
              classes={{
                selected: classes.selected,
              }}
            />
            <Tab
              label={<Typography>Beneficiaries</Typography>}
              classes={{
                selected: classes.selected,
              }}
            />
            <Tab
              aria-describedby={id}
              onMouseEnter={handlePopperOpen}
              onMouseLeave={handlePopperClose}
              label={
                <Typography>
                  {popperVisible && (
                    <SvgIcon component={warningIcon} className={classes.icon} />
                  )}
                  Enrollments
                </Typography>
              }
              classes={{
                selected: classes.selected,
              }}
            />
            <Tab
              label={<Typography>Files</Typography>}
              classes={{
                selected: classes.selected,
              }}
            />
          </Tabs>
          {checkEmployeeIsActive() && (
            <Box className={styles.menuBtn}>
              {logInAsEmployeeBtnVisible && (
                <Button
                  className={styles.loginAsBtn}
                  variant="contained"
                  color="primary"
                  onClick={handleLogInAsEmployee}
                >
                  Log in as employee
                </Button>
              )}
              {!isCurrentUser && (
                <Button
                  className={styles.terminateBtn}
                  variant="outlined"
                  color="primary"
                  onClick={() => setTerminateModalOpen(true)}
                >
                  Terminate
                </Button>
              )}
            </Box>
          )}
        </Box>
        {loading ? (
          <EmployeePanelSkeleton />
        ) : (
          <Box>{renderPanelContent()}</Box>
        )}
        {popperVisible && tabValue === CensusTabValue.Enrollments && (
          <Popper
            id={id}
            open={popperOpen}
            placement="bottom"
            anchorEl={anchorEl}
            modifiers={{
              offset: {
                enabled: true,
                offset: '0, 10',
              },
            }}
          >
            <SimpleAlert type="warning" className={styles.alert}>
              <Typography className={styles.textAlert}>
                This employee has some enrollments issues
              </Typography>
            </SimpleAlert>
          </Popper>
        )}
        <PasswordPrompt
          isOpen={passwordPromptOpen}
          handleClose={() => setPasswordPromptOpen(false)}
          onSubmit={handleShowPersonalData}
        />
      </>
    );
  }

  return null;
};

export default EmployeePanel;
