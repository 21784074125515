import React, { useState, useEffect, useCallback } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { connect } from 'react-redux';
import { Box, Typography, Divider, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckboxGroup from 'components/Forms/CheckboxGroup/CheckboxGroupComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';

import { BasicRadioButtonInterface } from 'components/Forms/RadioGroup/RadioGroupComponent';

import { AppStoreInterface } from 'store/app';
import { IFullPlan } from 'types/plans';
import { IEnrollment, IUpdateEnrollmentPayload } from 'types/enrollments';
import { DependentInterface } from 'types/dependents';
import IBasicOption from 'models/IBasicOption';

import getCurrency from 'helpers/getCurrency';
import {
  DOMESTIC_PARTNER_NOT_ELIGIBLE,
  CHILD_AGE_LIMIT_EXCEEDED,
} from 'constants/messages';
import plansDomain from 'api/domains/plans';
import {
  isChild,
  isChildCanBeEnrolled,
  isDomesticPartner,
  isDomesticPartnerCanBeEnrolled,
  enrollDependents,
  getDependentLabel,
  isTierNeeded,
} from './logic';
import Over26Modal from './Over26Modal';

export interface IDefaultPlanForm {
  userGender?: number | null;
  currentPlan?: IFullPlan;
  enrollment?: IEnrollment;
  dependents: DependentInterface[];
  relationshipTypes: IBasicOption<number>[];
  updateEnrollment: (
    id: string,
    enrollment: IUpdateEnrollmentPayload
  ) => void | Promise<void>;
  iWantToDecline?: boolean;
  currentPlanType: string;
  eventType: string | null;
  dependentId: string | null | number;
}

interface IDefaultEnrollmentFormBuffer {
  dependentIds: string[];
  contributionsAmount: string;
  status?: string;
}

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
  },
  groupTitle: { fontSize: '1.3rem' },
  subBlock: { margin: '1rem 0' },
  subBlockTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.4px',
    color: '#000000',
    marginBottom: '10px',
  },
  subBlockContent: {},
  submitBtnContainer: {
    marginTop: '2rem',
  },
  subBlockContr: {
    display: 'flex',
    flexDirection: 'row',
    margin: '30px 0',
  },
  subBlockContribution: {
    marginRight: '20px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.4px',
    color: '#9FA2B4',
  },
  contribution: {
    marginTOp: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#00000',
  },
  div: {
    margin: '25px 0',
  },
  addRightMargin: { marginRight: '1rem' },
  contributionLoader: { maxWidth: '200px', marginTop: '1rem' },
});

const DefaultPlanForm: React.FC<IDefaultPlanForm> = (props) => {
  const {
    currentPlan,
    enrollment,
    dependents,
    relationshipTypes,
    updateEnrollment,
    userGender,
    iWantToDecline,
    currentPlanType,
    eventType,
    dependentId,
  } = props;
  const [dependentIds, setDependentIds] = useState<string[] | null>(null);
  const [removeDependentIds, setRemoveDependentIds] = useState<string[] | null>(
    null
  );
  const [status, setStatus] = useState<string | undefined>(
    enrollment?.tier ?? undefined
  );
  const [contributionsAmount, setContributionsAmount] = useState<string>(
    '0.00'
  );
  const [loadNewContributionAmount, setLoadNewContributionAmount] = useState(
    false
  );

  const [over26Modal, setOver26ModalOpen] = useState(false);
  const [buffer, setBuffer] = useState<
    IDefaultEnrollmentFormBuffer | undefined
  >();
  // eslint-disable-next-line
  const [choosedDependentId, setChoosedDependentId] = useState<
    string | number | null | undefined
  >();
  const classes = useStyles();
  useEffect(() => {
    if (eventType === '4') {
      setChoosedDependentId(dependentId);
    }
    // eslint-disable-next-line
  }, [dependentId]);

  useEffect(() => {
    setRemoveDependentIds(dependentIds);
    // eslint-disable-next-line
  }, [dependentIds]);
  /*
  const getChoosedDependent = () => {
    const value = dependents.find(
      (dependent) => choosedDependentId && +dependent.id === +choosedDependentId
    );
    return value
      ? {
          value: value.id,
          label: getDependentLabel(value, relationshipTypes, undefined),
        }
      : null;
  };
  const setChoosedDependent = (value?: IBasicOption<any>) => {
    setChoosedDependentId(value?.value);
  };
  */
  /* const filteredDependents = dependents.filter((dependent) =>
    isChild(dependent)
      ? isChildCanBeEnrolled(
          currentPlanType,
          dependent,
          currentPlan?.tierStructure,
          currentPlan?.dependentMaxAge
        )
      : isDomesticPartner(dependent)
      ? isDomesticPartnerCanBeEnrolled(dependent, currentPlan, userGender)
      : true
  );
*/
  /*const isAllDependentsCovered = () => {
    return dependentIds?.length === filteredDependents.length;
  };*/

  const isCheckboxDisabled = useCallback(
    (dependent: DependentInterface): boolean => {
      const partnerCanBeEnrolled = isDomesticPartnerCanBeEnrolled(
        dependent,
        currentPlan,
        userGender
      );

      const childCanBeEnrolled =
        isChildCanBeEnrolled(
          currentPlanType,
          dependent,
          currentPlan?.tierStructure,
          currentPlan?.dependentMaxAge
        ) || Number(dependent?.disabled) === 1;

      return (
        (isDomesticPartner(dependent) && !partnerCanBeEnrolled) ||
        (isChild(dependent) && !childCanBeEnrolled)
      );
    },
    [currentPlan, userGender, currentPlanType]
  );

  const isCanBeChecked = useCallback(
    (dependent: DependentInterface) => {
      return !isCheckboxDisabled(dependent);
    },
    [isCheckboxDisabled]
  );

  const onChangeDependentIds = (ids: string[]) => {
    const newIds: string[] = [];
    for (const id of ids) {
      const foundedDependent = dependents.find(
        (dependent) => dependent.id === id
      );
      if (foundedDependent && isCanBeChecked(foundedDependent))
        newIds.push(foundedDependent.id);
    }
    setDependentIds(newIds);
  };

  const onRemoveDependentIds = (ids: string[]) => {
    const newIds: string[] = [];
    for (const id of ids) {
      const foundedDependent = dependents.find(
        (dependent) => dependent.id === id
      );
      if (foundedDependent && isCanBeChecked(foundedDependent))
        newIds.push(foundedDependent.id);
    }
    setRemoveDependentIds(newIds);
  };

  const getContributionAmount = async (
    planId: string,
    dependents?: string[],
    status?: number
  ) => {
    try {
      setLoadNewContributionAmount(true);
      const { data } = await plansDomain.predictContributionAmount(
        planId,
        dependents,
        tierStatus().length === 0 ? null : status
      );
      setContributionsAmount(data.amount ? data.amount.toString() : '0.00');
    } catch (e) {
      console.error(e);
    } finally {
      setLoadNewContributionAmount(false);
    }
  };

  const onSubmit = async () => {
    let dependentFinalIds: string[] = [];
    if (
      eventType === '5' ||
      eventType === '6' ||
      eventType === '7' ||
      eventType === '8'
    ) {
      dependentFinalIds =
        dependentIds?.filter((d) => !removeDependentIds?.includes(d)) ?? [];
    } else {
      dependentFinalIds = dependentIds ?? [];
    }
    if (currentPlanType && currentPlan) {
      await updateEnrollment(currentPlanType, {
        dependents: enrollDependents(
          currentPlanType,
          currentPlan?.tierStructure
        )
          ? dependentFinalIds ?? []
          : [],
        planId: currentPlan.id,
        contributionAmount: contributionsAmount,
        tier: tierStatus().length === 0 ? undefined : status,
      });
    }
  };

  const setDataFromBuffer = useCallback(() => {
    if (buffer) {
      setDependentIds(buffer.dependentIds);
      setContributionsAmount(buffer.contributionsAmount);
      setStatus(buffer?.status);
      setBuffer(undefined);
    }
  }, [buffer]);

  const bufferizeData = useCallback(() => {
    setBuffer({
      dependentIds: dependentIds ?? [],
      contributionsAmount,
      status,
    });
    setDependentIds([]);
    setContributionsAmount('0.00');
    setStatus(undefined);
  }, [
    setBuffer,
    setDependentIds,
    setContributionsAmount,
    setStatus,
    contributionsAmount,
    dependentIds,
    status,
  ]);

  const checkIWantToDecline = useCallback(() => {
    if (iWantToDecline) bufferizeData();
    else setDataFromBuffer();
  }, [iWantToDecline, bufferizeData, setDataFromBuffer]);

  useEffect(() => {
    if (currentPlan && dependentIds) {
      getContributionAmount(currentPlan.id, dependentIds);
    }
    // eslint-disable-next-line
  }, [currentPlan, dependentIds]);

  useEffect(() => {
    if (currentPlan && status && tierStatus()?.length !== 0) {
      getContributionAmount(currentPlan.id, undefined, +status);
    }
    // eslint-disable-next-line
  }, [currentPlan, status]);

  useEffect(() => {
    setContributionsAmount(
      typeof enrollment?.contributionAmount === 'string'
        ? enrollment?.contributionAmount
        : '0.00'
    );
  }, [enrollment]);

  useDeepCompareEffect(() => {
    const ids = enrollment ? enrollment.dependents : [];
    const newIds: string[] = [];
    for (const id of ids) {
      const foundedDependent = dependents.find(
        (dependent) => dependent.id === id
      );
      if (foundedDependent && isCanBeChecked(foundedDependent))
        newIds.push(foundedDependent.id);
    }
    setDependentIds(newIds);
  }, [enrollment, dependents]);

  useEffect(() => {
    const tier = enrollment ? enrollment?.tier : undefined;
    setStatus(tier);
  }, [enrollment]);

  useEffect(() => {
    checkIWantToDecline();
  }, [checkIWantToDecline]);

  const isAllDependentsDisabled =
    iWantToDecline ||
    dependents.every((dependent) => isCheckboxDisabled(dependent));

  /*const renderAlert = () => {
    const isSomeConditionalyEnrolled = dependents
      .filter(isChild)
      .filter((dependent) => dependentIds?.includes(dependent.id))
      .some((dependent) =>
        isChildConditionallyEnrolled(currentPlanType, dependent)
      );

    if (isAllDependentsDisabled) {
      return null;
    }

    if (isSomeConditionalyEnrolled) {
      return (
        <SimpleAlert
          className={styles.alertModal}
          title={MEDICAL_CONDITIONALLY_ENROLLED_WARNING}
          type="warning"
        >
          <Link
            onClick={() => setOver26ModalOpen(true)}
            className={styles.link}
          >
            View conditions for enrollment
          </Link>
        </SimpleAlert>
      );
    }

    if (!isAllDependentsCovered()) {
      return (
        <SimpleAlert
          className={styles.alertModal}
          title={NOT_COVERED_DEPENDENTS_WARNING}
          type="warning"
        />
      );
    }

    return null;
  };

  const renderAlertDisabled = () => {
    const isChildEnrolledAsDisabled = dependents
      .filter(isChild)
      .filter((dependent) => dependentIds?.includes(dependent.id))
      .some(
        (dependent) =>
          !isChildCanBeEnrolled(
            currentPlanType,
            dependent,
            currentPlan?.tierStructure,
            currentPlan?.dependentMaxAge
          ) && +dependent.disabled === 1
      );

    if (isChildEnrolledAsDisabled) {
      return (
        <SimpleAlert
          title={CHILD_DISABLED}
          className={styles.alertModal}
          type="warning"
        />
      );
    }
  };*/
  const tierStatus = (): BasicRadioButtonInterface[] => {
    if (isTierNeeded(Number(currentPlan?.planTypeId))) return [];

    switch (Number(currentPlan?.tierStructure)) {
      case 4:
        return [
          {
            label: 'Single',
            value: '1',
          },
          {
            label: 'Husband/Wife',
            value: '2',
          },
          {
            label: 'Parent/Child',
            value: '3',
          },
          {
            label: 'Family',
            value: '4',
          },
        ];
      case 3:
        return [
          {
            label: 'Employee only',
            value: '1',
          },
          {
            label: 'Employee +1',
            value: '2',
          },
          {
            label: 'Employee +2',
            value: '4',
          },
        ];
      case 2:
        return [
          {
            label: 'Single',
            value: '1',
          },
          {
            label: 'Family',
            value: '4',
          },
        ];
      default:
        return [];
    }
  };
  const checkedDependent = (dependent: DependentInterface) => {
    if (
      (eventType === '5' || eventType === '6') &&
      dependent.relationshipType === 'Spouse'
    )
      return true;
    else return !dependentIds?.includes(dependent.id);
  };
  const selectedDependent = (dependent: DependentInterface) => {
    if (
      (eventType === '5' || eventType === '6') &&
      dependent.relationshipType === 'Spouse'
    )
      return true;
    else return dependentIds?.includes(dependent.id);
  };

  const renderDependents = () => {
    switch (eventType) {
      case '1':
        return (
          <Box className={classes.subBlockContent}>
            {enrollment && +enrollment.status > 0 && (
              <Typography className={classes.subBlockTitle}>
                Enrolled dependents:
              </Typography>
            )}
            {enrollment && +enrollment.status > 0 ? (
              <CheckboxGroup
                onChange={onChangeDependentIds}
                checkboxes={dependents
                  .filter(
                    (dependent) => dependentId && +dependent.id === +dependentId
                  )
                  .map((dependent) => {
                    const disabled = isCheckboxDisabled(dependent);
                    const disabledMsg = isChild(dependent)
                      ? CHILD_AGE_LIMIT_EXCEEDED
                      : DOMESTIC_PARTNER_NOT_ELIGIBLE;

                    return {
                      value: dependent.id,
                      name: dependent.id,
                      disabled,
                      label: `Enroll ${getDependentLabel(
                        dependent,
                        relationshipTypes,
                        disabled ? disabledMsg : undefined
                      )}`,
                    };
                  })}
                selected={dependentIds ?? []}
              />
            ) : (
              <CheckboxGroup
                onChange={onChangeDependentIds}
                checkboxes={dependents
                  .filter(
                    (dependent) =>
                      !(
                        dependent.relationshipType === 'Child' &&
                        dependentId &&
                        +dependent.id !== +dependentId
                      )
                  )
                  .map((dependent) => {
                    const disabled = isCheckboxDisabled(dependent);
                    const disabledMsg = isChild(dependent)
                      ? CHILD_AGE_LIMIT_EXCEEDED
                      : DOMESTIC_PARTNER_NOT_ELIGIBLE;

                    return {
                      value: dependent.id,
                      name: dependent.id,
                      disabled,
                      label: `${getDependentLabel(
                        dependent,
                        relationshipTypes,
                        disabled ? disabledMsg : undefined
                      )}`,
                    };
                  })}
                selected={dependentIds ?? []}
              />
            )}
          </Box>
        );
      case '2':
      case '3':
        return (
          <Box className={classes.subBlockContent}>
            <Typography className={classes.subBlockTitle}>
              Enrolled dependents:
            </Typography>
            <CheckboxGroup
              onChange={onChangeDependentIds}
              checkboxes={dependents.map((dependent) => {
                const disabled = isCheckboxDisabled(dependent);
                const disabledMsg = isChild(dependent)
                  ? CHILD_AGE_LIMIT_EXCEEDED
                  : DOMESTIC_PARTNER_NOT_ELIGIBLE;

                return {
                  value: dependent.id,
                  name: dependent.id,
                  disabled: Boolean(
                    (enrollment?.dependents.includes(dependent.id) &&
                      dependent.relationshipType !== 'Spouse') ||
                      disabled
                  ),
                  label: getDependentLabel(
                    dependent,
                    relationshipTypes,
                    disabled ? disabledMsg : undefined
                  ),
                };
              })}
              selected={dependentIds ?? []}
              disableAll={isAllDependentsDisabled}
            />
          </Box>
        );
      case '4':
        return (
          <Box className={classes.subBlockContent}>
            {dependents.filter(
              (dependent) => dependent.relationshipType === 'Child'
            ).length > 1 && (
              <Typography className={classes.subBlockTitle}>
                Enrolled dependents:
              </Typography>
            )}
            <CheckboxGroup
              onChange={onChangeDependentIds}
              checkboxes={dependents
                .filter((dependent) => dependent.relationshipType === 'Child')
                .map((dependent) => {
                  const disabled = isCheckboxDisabled(dependent);
                  const disabledMsg = isChild(dependent)
                    ? CHILD_AGE_LIMIT_EXCEEDED
                    : DOMESTIC_PARTNER_NOT_ELIGIBLE;

                  return {
                    value: dependent.id,
                    name: dependent.id,
                    disabled,
                    label: `${
                      dependents.filter((d) => d.relationshipType === 'Child')
                        .length === 1
                        ? 'Enroll '
                        : ''
                    }${getDependentLabel(
                      dependent,
                      relationshipTypes,
                      disabled ? disabledMsg : undefined
                    )}`,
                  };
                })}
              selected={dependentIds ?? []}
              disableAll={isAllDependentsDisabled}
            />
          </Box>
        );
      case '5':
      case '6':
      case '8':
        return enrollment && +enrollment.status > 0 ? (
          <Box className={classes.subBlockContent}>
            <Typography className={classes.subBlockTitle}>
              Remove dependents:
            </Typography>
            <CheckboxGroup
              onChange={onRemoveDependentIds}
              checkboxes={dependents.map((dependent) => {
                const disabled = checkedDependent(dependent);
                return {
                  value: dependent.id,
                  name: dependent.id,
                  disabled,
                  selected: selectedDependent(dependent),
                  label: getDependentLabel(
                    dependent,
                    relationshipTypes,
                    undefined
                  ),
                };
              })}
              selected={removeDependentIds ?? []}
              disableAll={isAllDependentsDisabled}
            />
          </Box>
        ) : (
          <Box className={classes.subBlockContent}>
            <Typography className={classes.subBlockTitle}>
              Enrolled dependents:
            </Typography>
            <CheckboxGroup
              onChange={onChangeDependentIds}
              checkboxes={dependents
                .filter((dependent) => dependent.relationshipType === 'Child')
                .map((dependent) => {
                  const disabled = isCheckboxDisabled(dependent);
                  const disabledMsg = isChild(dependent)
                    ? CHILD_AGE_LIMIT_EXCEEDED
                    : DOMESTIC_PARTNER_NOT_ELIGIBLE;

                  return {
                    value: dependent.id,
                    name: dependent.id,
                    disabled,
                    label: `${getDependentLabel(
                      dependent,
                      relationshipTypes,
                      disabled ? disabledMsg : undefined
                    )}`,
                  };
                })}
              selected={dependentIds ?? []}
            />
          </Box>
        );
      case '7':
        return (
          <Box className={classes.subBlockContent}>
            {dependents.filter(
              (dependent) => dependent.relationshipType === 'Child'
            ).length > 1 && (
              <Typography className={classes.subBlockTitle}>
                Remove dependents:
              </Typography>
            )}
            <CheckboxGroup
              onChange={onRemoveDependentIds}
              checkboxes={dependents
                .filter((d) => d.relationshipType === 'Child')
                .map((dependent) => {
                  const disabled = checkedDependent(dependent);
                  return {
                    value: dependent.id,
                    name: dependent.id,
                    disabled,
                    selected: selectedDependent(dependent),
                    label: `${
                      dependents.filter((d) => d.relationshipType === 'Child')
                        .length === 1
                        ? 'Remove '
                        : ''
                    }
                      ${getDependentLabel(
                        dependent,
                        relationshipTypes,
                        undefined
                      )}`,
                  };
                })}
              selected={removeDependentIds ?? []}
              disableAll={isAllDependentsDisabled}
            />
          </Box>
        );
    }
  };

  return (
    <>
      {enrollDependents(
        currentPlanType,
        currentPlan?.tierStructure,
        currentPlan?.miniCensus,
        currentPlan?.ageBandedRateType as string
      ) && (
        <>
          <Box className={classes.subBlock}>
            <Typography className={classes.subBlockTitle}>
              Choose Dependent(s) Coverage
            </Typography>
            {dependents && dependents.length ? (
              renderDependents()
            ) : (
              <Box className={classes.subBlockContent}>
                <Typography>You have no dependents</Typography>
              </Box>
            )}
          </Box>
          <Divider className={classes.div} />
        </>
      )}
      <Box className={classes.subBlockContr}>
        <Typography className={classes.subBlockContribution}>
          Your Contribution
        </Typography>
        <Box className={classes.subBlockContent}>
          {loadNewContributionAmount ? (
            <LinearProgress className={classes.contributionLoader} />
          ) : (
            <Typography className={classes.contribution}>{`${getCurrency(
              contributionsAmount,
              2
            )} per pay period`}</Typography>
          )}
        </Box>
      </Box>
      <Box className={classes.submitBtnContainer}>
        {!iWantToDecline && (
          <SubmitButton
            label={!currentPlan ? 'Please choose plan' : 'Enroll'}
            className={classes.addRightMargin}
            onClick={onSubmit}
            disabled={!currentPlan}
          />
        )}
      </Box>
      <Over26Modal
        isOpen={over26Modal}
        handleClose={() => setOver26ModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state: AppStoreInterface) => ({
  dependents: state.userRegistration.dependents,
  relationshipTypes: state.options.relationshipType,
  userGender: state.userRegistration.profile.gender,
});

export default connect(mapStateToProps)(DefaultPlanForm);
