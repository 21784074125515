import moment from 'moment';
import {
  ExternalBeneficiaryPayload,
  BeneficiaryInterface,
  BeneficiaryInterfaceWithoutId,
} from 'types/beneficiaries';

export const fromBeneficiaryToExternalBeneficiaryPayload = (
  beneficiary: BeneficiaryInterface | BeneficiaryInterfaceWithoutId
): ExternalBeneficiaryPayload => {
  return {
    relationshipId: beneficiary.relationshipId?.toString() || null,
    relationshipTypeId: beneficiary.relationshipTypeId?.toString() || '',
    beneficiaryType: beneficiary.beneficiaryType?.toString() || '',
    share: beneficiary.share.toString(),
    birthDate: moment(beneficiary.birthDate).format('MM/DD/YYYY'),
    ssn: beneficiary.ssn || '',
    firstName: beneficiary.firstName,
    lastName: beneficiary.lastName,
  };
};
