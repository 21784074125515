import moment from 'moment';
import { UserProfileInterface } from 'types/user';
import { IEmployee } from 'types/employee';

export const fromEmployeeToProfileInterface = (
  employee: IEmployee
): UserProfileInterface => {
  return {
    firstName: employee.firstName,
    lastName: employee.lastName,
    middleName: employee.middleName || '',
    maidenName: employee.maidenName || '',
    email: employee.email,
    birthDate: employee.birthDate
      ? moment(employee.birthDate, 'MM/DD/YYYY').toDate()
      : null,
    gender: +employee.gender,
    smoker: +employee.smoker,
    maritalStatusId: +employee.maritalStatusId,
    ssn: employee.ssn || '',
    address1: employee.address1,
    address2: employee.address2,
    zip: employee.zip,
    city: employee.city,
    state: employee.state,
    officePhone: employee.officePhone,
    homePhone: employee.homePhone,
    cellPhone: employee.cellPhone,
    cellPhoneIndex: employee.cellPhoneIndex,
    homePhoneIndex: employee.homePhoneIndex,
    officePhoneIndex: employee.officePhoneIndex,
  };
};
