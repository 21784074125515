import React from 'react';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import { useQuery } from 'react-query';
import plansDomain from 'api/domains/plans';
import { Plan } from 'types/plans';
import PlanType from 'enums/planType';
import Currency from '../Currency';

import styles from './styles.module.scss';

interface IProps {
  plan: Plan;
  asTable?: boolean;
  employeeId?: string | number | null;
  guaranteeIssue?: number;
}

const getPlansCoverages = async (
  _key: string,
  id: string,
  employeeId: string | number | null
) => {
  const { data } = await plansDomain.getCoverages(id, employeeId);
  return data;
};

const CoverageTable: React.FC<IProps> = ({
  plan,
  asTable,
  employeeId,
  guaranteeIssue,
}) => {
  // eslint-disable-next-line
  const { isLoading, data: plansCoverages } = useQuery(
    ['plansCoverages', plan.id, employeeId],
    getPlansCoverages,
    {
      refetchOnWindowFocus: false,
    }
  );

  const condition =
    plan.planType === PlanType.STD ||
    plan.planType === PlanType.LTD ||
    plan.planType === PlanType.VoluntaryLife ||
    plan.planType === PlanType.GroupLife ||
    (plan?.tierStructure &&
      +plan.tierStructure === 5 &&
      plan.ageBandedRateType &&
      +plan.ageBandedRateType > 0);

  const coverage = () => (
    <>
      {Boolean(plansCoverages?.employee && plansCoverages?.employee !== 0) && (
        <TableRow>
          <TableCell scope="row">Employee coverage:</TableCell>
          <TableCell scope="row">
            <Currency>{plansCoverages?.employee}</Currency>
            {plansCoverages?.benefitPeriod &&
              (plansCoverages?.benefitPeriod === 'year'
                ? ' '
                : ` per ${plansCoverages.benefitPeriod}`)}
            {guaranteeIssue &&
            guaranteeIssue < (plansCoverages?.employee ?? 0) ? (
              <>
                {' after your Evidence of Insurability (EOI) is approved'}
                <br />
                <Currency>{guaranteeIssue}</Currency>
                {' without approved EOI'}
              </>
            ) : (
              ''
            )}
          </TableCell>
        </TableRow>
      )}
      {Boolean(plansCoverages?.spouse && plansCoverages?.spouse !== 0) && (
        <TableRow>
          <TableCell scope="row">Spouse coverage:</TableCell>
          <TableCell scope="row">
            <Currency>{plansCoverages?.spouse}</Currency>
            {plansCoverages?.benefitPeriod &&
              (plansCoverages?.benefitPeriod === 'year'
                ? ' '
                : ` per ${plansCoverages.benefitPeriod}`)}
          </TableCell>
        </TableRow>
      )}
      {Boolean(plansCoverages?.children && plansCoverages?.children !== 0) && (
        <TableRow>
          <TableCell scope="row">Children coverage:</TableCell>
          <TableCell scope="row">
            <Currency>{plansCoverages?.children}</Currency>
            {plansCoverages?.benefitPeriod &&
              (plansCoverages?.benefitPeriod === 'year'
                ? ' '
                : ` per ${plansCoverages.benefitPeriod}`)}
          </TableCell>
        </TableRow>
      )}
    </>
  );

  return condition ? (
    asTable ? (
      <>
        <TableContainer className={styles.planTableContainer} component={Box}>
          <Table className={styles.planTable}>
            <TableBody>{coverage()}</TableBody>
          </Table>
        </TableContainer>
      </>
    ) : (
      coverage()
    )
  ) : (
    <></>
  );
};

export default CoverageTable;
