import {
  DependentInterface,
  PartialDependentInterface,
} from 'types/dependents';

export interface KeyToLabelDependentMapperInterface {
  key: keyof DependentInterface;
  label: string;
}

export interface DependentValidationInterface {
  errors: string[];
  alerts: string[];
  isValid: boolean;
}

export const requiredDependentFields: KeyToLabelDependentMapperInterface[] = [
  { key: 'firstName', label: 'First name' },
  { key: 'lastName', label: 'Last name' },
  { key: 'relationshipTypeId', label: 'Relation' },
  { key: 'gender', label: 'Gender' },
  { key: 'smoker', label: 'Smoker' },
  { key: 'birthDate', label: 'Date of birth' },
];

export const importantDependentFields: KeyToLabelDependentMapperInterface[] = [
  { key: 'email', label: 'Email' },
];

export const ssnDependentField: KeyToLabelDependentMapperInterface = {
  key: 'ssn',
  label: 'SSN',
};

export const validateDependentsFields = (
  dependent: PartialDependentInterface,
  ssnRequired = false
): DependentValidationInterface => {
  const errors: string[] = [];
  const alerts: string[] = [];

  const requiredFields = ssnRequired
    ? [...requiredDependentFields, ssnDependentField]
    : [...requiredDependentFields];
  const importantFields = ssnRequired
    ? [...importantDependentFields]
    : [...importantDependentFields, ssnDependentField];

  requiredFields.forEach((field) => {
    if (
      !dependent[field.key] &&
      !(field.key === 'smoker' && dependent[field.key] !== '0')
    ) {
      errors.push(`${field.label} is missing!`);
    }
  });

  importantFields.forEach((field) => {
    if (!dependent[field.key]) {
      alerts.push(`${field.label} is missing!`);
    }
  });

  return { errors, alerts, isValid: !errors.length };
};
