import { EMPTY_VALUE } from 'constants/placeholders';

const getCurrency = (
  amount?: number | string | null,
  fixed?: number,
  unit = '$'
) => {
  if (typeof amount !== 'number' && typeof amount !== 'string')
    return EMPTY_VALUE;
  let result = '';
  if (unit) result += unit;

  if (fixed) {
    result += Number(amount).toFixed(fixed);
  } else {
    result += parseFloat(amount.toString()).toLocaleString();
  }
  return result;
};

export default getCurrency;
