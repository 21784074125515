import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { bindActionCreators, Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';
import OpenEnrollmentsInfo from 'components/OpenEnrollmentsInfo';
import SectionTitle from 'components/SectionTitle';
import DependentReadOnlyList from 'components/DependentReadOnlyList';
import PasswordPrompt from 'components/PasswordPrompt';
import RestartRegistrationModal from 'components/Modal/RestartRegistrationModal';
import IBasicOption from 'models/IBasicOption';
import ExternalRegistrationStep from 'enums/externalRegistrationStep';
import dependentDomain from 'api/domains/dependents';
import userDomain from 'api/domains/user';
import { useQuery } from 'react-query';
import { loadCurrentUser } from 'redux/sagas/user/routines';
import { IDependentPersonal } from 'types/dependents';
import { AppStoreInterface } from 'store/app';
import DependentsSkeleton from './DependentsSkeleton';

import styles from './styles.module.scss';

interface IProps {
  gender: IBasicOption<number>[];
  testUser: string | null;
  impersonatingUserId: string | null;
  newHire: boolean | null;
  openEnrollmentEndDate: string | null;
  eligibilityDate: string | null;
  actions: {
    loadCurrentUser: typeof loadCurrentUser.trigger;
  };
}

const getDependets = async () => {
  const { data } = await userDomain.getDependets();
  return data;
};
const Dependents: React.FC<IProps> = ({
  gender,
  testUser,
  impersonatingUserId,
  newHire,
  openEnrollmentEndDate,
  eligibilityDate,
  actions,
}) => {
  const [passwordPromptOpen, setPasswordPromptOpen] = useState(false);
  const [restartRegistrationModal, setRestartRegistrationModal] = useState(
    false
  );

  const [personalData, setPersonalData] = useState<IDependentPersonal[]>([]);
  const { data: dependents, status } = useQuery('dependents', getDependets, {
    refetchOnWindowFocus: false,
  });

  const handleShowPersonalData = async (password: string) => {
    try {
      const { data } = await dependentDomain.getPersonalDataList(password);
      setPersonalData(data);
    } catch (err) {
      throw err;
    }
  };

  const handleHidePersonalData = () => {
    setPersonalData([]);
  };

  const handleEditDependentsClick = async () => {
    try {
      const step = (ExternalRegistrationStep.Dependents - 1).toString();
      await userDomain.saveRegistrationStep(step);
      actions.loadCurrentUser();
    } catch (e) {
      console.error(e);
    }
  };

  const isPasswordPrompt = !(
    parseInt(testUser as string) || !!impersonatingUserId
  );

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Dependents</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <SectionTitle title="My Dependents" />
        <Grid container className={styles.mainGrid}>
          <Grid sm={12} md={9} item className={styles.dependentsGrid}>
            {status === 'loading' ? (
              <DependentsSkeleton />
            ) : (
              <DependentReadOnlyList
                handleHidePersonalData={handleHidePersonalData}
                handleOpenModal={() => setPasswordPromptOpen(true)}
                personalData={personalData}
                dependents={dependents!}
                gender={gender}
                handleShowPersonalData={handleShowPersonalData}
                isPasswordPrompt={isPasswordPrompt}
              />
            )}
          </Grid>
          <Grid sm={12} md={3} item className={styles.secondGrid}>
            <OpenEnrollmentsInfo
              eligibilityDate={eligibilityDate}
              openEnrollmentEndDate={openEnrollmentEndDate}
              newHire={newHire}
              handleOpenConfirmModal={() => setRestartRegistrationModal(true)}
              btnName="Edit Dependents"
            />
          </Grid>
        </Grid>
        <PasswordPrompt
          isOpen={passwordPromptOpen}
          handleClose={() => setPasswordPromptOpen(false)}
          onSubmit={handleShowPersonalData}
        />
        <RestartRegistrationModal
          open={restartRegistrationModal}
          handleClose={() => setRestartRegistrationModal(false)}
          handleSubmit={handleEditDependentsClick}
        />
      </Box>
    </>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  gender: store.options.gender,
  testUser: store.user.testUser,
  impersonatingUserId: store.user.impersonatingUserId,
  newHire: store.user.newHire,
  openEnrollmentEndDate: store.user.openEnrollmentEndDate,
  eligibilityDate: store.user.eligibilityDate,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      loadCurrentUser: typeof loadCurrentUser.trigger;
    }
  >(
    {
      loadCurrentUser: loadCurrentUser.trigger,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dependents);
