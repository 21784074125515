import React, { useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  SvgIcon,
  Tooltip,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';
import { ReactComponent as enrolledIcon } from 'assets/enrolled.svg';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import { IMessageInfo } from 'types/user';
import styles from './styles.module.scss';

interface IEmailTableProps {
  messageData?: IMessageInfo;
  isSelected: (id: string) => boolean;
  onSelectClick: (id: string) => void;
  isAllSelected: () => boolean;
  onSelectAllClick: () => void;
}
const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);
const EmailTable: React.FC<IEmailTableProps> = ({
  messageData,
  isSelected,
  isAllSelected,
  onSelectClick,
  onSelectAllClick,
}) => {
  useEffect(() => {
    if (messageData) {
    }
  }, [messageData]);

  if (!messageData) return null;
  const { employees, openEnrollmentPeriod } = messageData;

  return (
    <Box>
      <Box className={styles.mainContainer}>
        <ContentContainer>
          <Box className={styles.wrapper}>
            <TableContainer className={styles.wrapperTable} component={Box}>
              <Table className={styles.recipientsTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <CustomCheckbox
                        checked={isAllSelected()}
                        onChange={onSelectAllClick}
                        icon={
                          <SvgIcon
                            component={offIcon}
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                          />
                        }
                        checkedIcon={
                          <SvgIcon
                            component={onIcon}
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                          />
                        }
                      />
                    </TableCell>
                    <TableCell> Employee</TableCell>
                    <TableCell> Email</TableCell>
                    <TableCell> Welcome Email Sent</TableCell>
                    <TableCell> Account Created</TableCell>
                    {openEnrollmentPeriod && (
                      <TableCell> OE Invite Sent</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employees.map((employee) => (
                    <TableRow key={employee.id}>
                      <TableCell>
                        <CustomCheckbox
                          checked={isSelected(employee.id)}
                          onChange={() => onSelectClick(employee.id)}
                          icon={
                            <SvgIcon
                              component={offIcon}
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                            />
                          }
                          checkedIcon={
                            <SvgIcon
                              component={onIcon}
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                            />
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {employee.firstName} {employee.lastName}
                      </TableCell>
                      <TableCell>{employee.email}</TableCell>
                      <TableCell>
                        {employee.progressTracking & 1 ? (
                          <Tooltip title={employee.welcomeEmailSentDate}>
                            <SvgIcon
                              component={enrolledIcon}
                              className={styles.enrolledIcon}
                            />
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>
                        {employee.progressTracking & 2 ? (
                          <Tooltip title={employee.registrationDate}>
                            <SvgIcon
                              component={enrolledIcon}
                              className={styles.enrolledIcon}
                            />
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      {openEnrollmentPeriod && (
                        <TableCell>
                          {openEnrollmentPeriod?.emails[employee.id] ? (
                            <Tooltip
                              title={openEnrollmentPeriod?.emails[employee.id]}
                            >
                              <SvgIcon
                                component={enrolledIcon}
                                className={styles.enrolledIcon}
                              />
                            </Tooltip>
                          ) : (
                            ''
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </ContentContainer>
      </Box>
    </Box>
  );
};

export default EmailTable;
