enum OptionType {
  SecurityQuestion = 'security_question',
  Gender = 'gender',
  Smoker = 'smoker',
  MaritalStatus = 'marital_status',
  Employment = 'employment',
  EmploymentType = 'employment_type',
  RelationshipType = 'relationship_type',
  LastNameReason = 'last_name_reason',
  WaiverReason = 'waiver_reason',
  AccessGroup = 'access_group',
  EmploymentStatus = 'employment_status',
  OfficeLocation = 'office',
  QualifyingEvent = 'qualifying_event',
  LogEventType = 'log_event_type',
  PlanType = 'plan_type',
  WorkEligibilityType = 'work_eligibility_type',
  W4FilingStatus = 'w4_filing_status',
  PayrollMethod = 'payroll_method',
  PayrollAmountType = 'payroll_amount_type',
  PayrollAccountType = 'payroll_account_type',
  Race = 'eeo_race',
  JobCategory = 'eeo_job_category',
  MilitaryStatus = 'eeo_military_status',
  BeneficiaryRelationshipType = 'beneficiary_relationship_type',
}

export default OptionType;
