import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators, Dispatch } from 'redux';
import { Add } from '@material-ui/icons';
import { Actions as RegistrationActions } from 'redux/reducers/userRegistration/actions';
import { Actions as UserActions } from 'redux/reducers/user/actions';
import { AppStoreInterface } from 'store/app';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import DependentsList from 'components/DependentList/DependentsList';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import Feedbacker, { FeedbackerSeverity } from 'components/Feedbacker';
import { loadUserDependents } from 'redux/sagas/userRegistration/routines';

import { IMidyearChangeData } from 'types/enrollments';
import { fromDependentToCreateDependent } from 'mappers/dependents/fromDependentToCreateDependent';
import { fromDependentToUpdateDependent } from 'mappers/dependents/fromDependentToUpdateDependent';
import { fromUserProfileToUserAddress } from 'mappers/employee/fromProfileInterfaceToAddress';

import dependentsDomain from 'api/domains/dependents';
import {
  DependentInterface,
  DependentInterfaceWithoutId,
  DependentAddressInterface,
} from 'types/dependents';
import { UserAddress } from 'types/user';
import ssnToMaskedSsn from 'helpers/ssnToMaskedSnn';
import AddDependentsModal from './AddDependentModal';
import DependentsSkeleton from './DependentsSkeleton';
import styles from './styles.module.scss';

interface DependentsSettingsPropsFromStateInterface {
  actions: {
    createDependent: typeof RegistrationActions.createDependent;
    editDependent: typeof RegistrationActions.editDependent;
    deleteDependent: typeof RegistrationActions.deleteDependent;
    loadUserDependents: typeof loadUserDependents.trigger;
    editUserData: typeof UserActions.editUserData;
  };
  userAddress: UserAddress;
  userFirstName?: string;
  userLastName?: string;
  dependents: DependentInterface[];
  dependentLoading: boolean;
  requireSsn: string | null;
  setStep: (data: IMidyearChangeData) => void;
}

export interface DependentsSettingsStateInterface {
  showAddDependentsModal: boolean;
  dependentIdToDelete?: string;
  editedDependentId?: string;
  ssnEditable: boolean;
  dependentsSameAddress: boolean;
  expandedFormDependentIds: string[];
  feedbackerMessage?: string;
  feedbackerStatus?: FeedbackerSeverity;
  dependentsDifferentAddresses: {
    [dependentId: string]: DependentAddressInterface;
  };
}

export type DependentsAllProps = DependentsSettingsPropsFromStateInterface;

const StepFour: React.FC<DependentsAllProps> = ({
  actions,
  requireSsn,
  dependents,
  userLastName,
  setStep,
}) => {
  const [ssnEditable, setSsnEditable] = useState(false);
  const [feedbackerMessage, setFeedbackerMessage] = useState<
    string | undefined
  >(undefined);
  // eslint-disable-next-line
  const [feedbackerStatus, setFeedbackerStatus] = useState(undefined);
  const [editedDependentId, setEditedDependentId] = useState<
    string | undefined
  >(undefined);
  const [showAddDependentsModal, setShowAddDependentsModal] = useState(false);
  const [dependentLoading, setDependentLoading] = useState(true);

  useEffect(() => {
    actions.loadUserDependents();
    setDependentLoading(false);
  }, [actions]);

  const clearFeedbacker = () => {
    setFeedbackerMessage(undefined);
  };

  const apiAction = async (
    action: () => Promise<void>,
    errorMessage: string
  ) => {
    try {
      await action();
    } catch (e) {
      console.error(e);
    }
  };

  const createDependent = async (dependent: DependentInterfaceWithoutId) => {
    await apiAction(async () => {
      const { data } = await dependentsDomain.create(
        fromDependentToCreateDependent(dependent)
      );

      actions.createDependent(
        Object.assign(dependent, {
          id: data.id,
          individualId: data.individualId,
          ssn: ssnToMaskedSsn(data.ssn),
        })
      );
    }, 'Error, when trying to create dependent');
  };

  const editDependent = async (dependent: DependentInterface) => {
    await apiAction(async () => {
      await dependentsDomain.update(
        dependent.id,
        fromDependentToUpdateDependent(dependent)
      );
      actions.editDependent({
        ...dependent,
        ssn: ssnToMaskedSsn(dependent.ssn),
      });
    }, 'Error, when trying to update dependent');
  };

  const addDependent = async (
    dependent: DependentInterface | DependentInterfaceWithoutId
  ) => {
    if (!(dependent as DependentInterface).id) {
      await createDependent(dependent);
    } else {
      await editDependent(dependent as DependentInterface);
    }
    handleModalClose();
  };

  const toggleSsnEditableWithCb = (cb: (ssnEditable: boolean) => void) => {
    setSsnEditable(!ssnEditable);
  };

  const onAddDependentClick = () => {
    setEditedDependentId(undefined);
    setShowAddDependentsModal(true);
  };

  const handleModalClose = () => {
    setShowAddDependentsModal(false);
    setSsnEditable(false);
  };

  const onNextClick = async () => {
    setStep({ step: 5 });
  };

  return (
    <>
      <SimpleAlert type="info">
        <Typography className={styles.stepText}>
          You may also enroll your children at this time if they are not already
          enrolled. Please make sure all children that you would like to add are
          listed here.
        </Typography>
      </SimpleAlert>
      <SubmitButton
        label="Add Child"
        icon={<Add />}
        iconPosition="left"
        onClick={onAddDependentClick}
        className={`${styles.marginBottom} ${styles.btnAdd}`}
      />
      {dependentLoading ? (
        <DependentsSkeleton />
      ) : (
        <DependentsList
          dependents={dependents.filter((d) => d.relationshipType === 'Child')}
        />
      )}
      <AddDependentsModal
        isOpen={showAddDependentsModal}
        handleClose={handleModalClose}
        ssnEditable={ssnEditable}
        toggleSsnEditableWithCb={toggleSsnEditableWithCb}
        userLastName={userLastName}
        onSubmit={addDependent}
        dependent={dependents.find((d) => d.id === editedDependentId)}
      />
      <SubmitButton
        className={styles.submitBtn}
        label="Next"
        variant="contained"
        onClick={() => {
          onNextClick();
        }}
      />
      <Feedbacker
        open={!!feedbackerMessage && !!feedbackerStatus}
        severity={feedbackerStatus}
        feedbackMessage={feedbackerMessage || ''}
        clearFeedback={clearFeedbacker}
      />
    </>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  userFirstName: store.userRegistration.profile.firstName,
  userLastName: store.userRegistration.profile.lastName,
  userAddress: fromUserProfileToUserAddress(store.userRegistration.profile),
  dependents: store.userRegistration.dependents,
  dependentLoading: store.userRegistration.loading,
  requireSsn: store.user.requireSsn,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      createDependent: typeof RegistrationActions.createDependent;
      editDependent: typeof RegistrationActions.editDependent;
      deleteDependent: typeof RegistrationActions.deleteDependent;
      loadUserDependents: typeof loadUserDependents.trigger;
      editUserData: typeof UserActions.editUserData;
    }
  >(
    {
      createDependent: RegistrationActions.createDependent,
      editDependent: RegistrationActions.editDependent,
      deleteDependent: RegistrationActions.deleteDependent,
      loadUserDependents: loadUserDependents.trigger,
      editUserData: UserActions.editUserData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepFour);
