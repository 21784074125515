import React from 'react';
import {
  TableContainer,
  TableBody,
  TableHead,
  Table,
  TableRow,
  TableCell,
  Box,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import BlockWithHelpBuble from 'components/Popper/BlockWithHelpBuble';
import Input from 'components/Forms/Input/InputComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import plansDomain from 'api/domains/plans';
import userDomain from 'api/domains/user';
import {
  IEnrollment,
  IFsaContributionAmount,
  IUpdateEnrollmentPayload,
  IFsaContributionAmountNumbered,
} from 'types/enrollments';

import { fromFSAContributionToNumberedFSAContribution } from 'mappers/enrollments/fromFSAContributionToNumberedFSAContribution';
import { fromNumberedFSAContributionToFSAContribution } from 'mappers/enrollments/fromNumberedFSAContributionToFSAContribution';

import { IFsaPlan } from 'types/plans';
import ExternalPlanType from 'enums/externalPlanType';
import convertCurrencyToNumber from 'helpers/convertCurrencyToNumber';
import { toFixed, getSplitedNumericString } from 'helpers/numberHelpers';
import styles from './styles.module.scss';

interface IFSAPlanFormProps {
  planId: string | number;
  enrollment?: IEnrollment;
  updateEnrollment: (
    id: string,
    enrollment: IUpdateEnrollmentPayload
  ) => void | Promise<void>;
}

const fsaContributionKeyToLabel: { [key: string]: string } = {
  med: 'Health',
  dep: 'Dependent Care',
  tra: 'Transit',
  park: 'Qualified Parking',
};

const convertContributionToFixedNumbers = (
  contribution: IFsaContributionAmountNumbered
) => {
  return Object.keys(contribution).reduce<IFsaContributionAmountNumbered>(
    (fsaContribution, key) => {
      const currentContribution =
        contribution[key as keyof IFsaContributionAmountNumbered];
      return {
        ...fsaContribution,
        [key as keyof IFsaContributionAmountNumbered]: currentContribution
          ? +toFixed(currentContribution, 2)
          : undefined,
      };
    },
    {} as IFsaContributionAmountNumbered
  );
};

const FSAPlanForm: React.FC<IFSAPlanFormProps> = (props) => {
  const { planId, enrollment, updateEnrollment } = props;

  const [payPeriod, setPayPeriod] = React.useState<number>(0);
  const [fsaPlan, setFsaPlan] = React.useState<IFsaPlan>();

  const [perPeriodAmount, setPerPeriodAmount] = React.useState<
    IFsaContributionAmountNumbered
  >({});
  const [fsaContributionAmounts, setFsaContributionAmount] = React.useState<
    IFsaContributionAmountNumbered
  >({});

  const [inputFocus, setInputFocus] = React.useState(false);
  const [isPasted, setIsPasted] = React.useState(false);

  const isFsaTypeAvailable = (
    fsaType: keyof IFsaContributionAmountNumbered
  ) => {
    if (fsaType === 'dep') return fsaPlan?.fsaDep;
    if (fsaType === 'med') return fsaPlan?.fsaMed;
    if (fsaType === 'tra') return fsaPlan?.fsaTra;
    if (fsaType === 'park') return fsaPlan?.fsaPark;
  };

  const getAnnualLimit = (
    fsaType: keyof IFsaContributionAmountNumbered
  ): number => {
    if (!fsaPlan) return 0;
    if (
      fsaType === 'med' &&
      isFsaTypeAvailable(fsaType) &&
      fsaPlan.fsaMedEeAmount
    ) {
      return (
        convertCurrencyToNumber(fsaPlan.fsaMedEeAmount) -
        convertCurrencyToNumber(
          fsaPlan.fsaMedErAmount,
          convertCurrencyToNumber(fsaPlan.fsaMedEeAmount)
        )
      );
    }
    if (
      fsaType === 'dep' &&
      isFsaTypeAvailable(fsaType) &&
      fsaPlan.fsaDepEeAmount
    ) {
      return (
        convertCurrencyToNumber(fsaPlan.fsaDepEeAmount) -
        convertCurrencyToNumber(
          fsaPlan.fsaDepErAmount,
          convertCurrencyToNumber(fsaPlan.fsaDepEeAmount)
        )
      );
    }
    if (
      fsaType === 'tra' &&
      isFsaTypeAvailable(fsaType) &&
      fsaPlan.fsaTraEeAmount
    ) {
      return (
        convertCurrencyToNumber(fsaPlan.fsaTraEeAmount) -
        convertCurrencyToNumber(
          fsaPlan.fsaTraErAmount,
          convertCurrencyToNumber(fsaPlan.fsaTraEeAmount)
        )
      );
    }
    if (
      fsaType === 'park' &&
      isFsaTypeAvailable(fsaType) &&
      fsaPlan.fsaParkEeAmount
    ) {
      return (
        convertCurrencyToNumber(fsaPlan.fsaParkEeAmount) -
        convertCurrencyToNumber(
          fsaPlan.fsaParkErAmount,
          convertCurrencyToNumber(fsaPlan.fsaParkEeAmount)
        )
      );
    }
    return 0;
  };

  const fetchFSAPlan = React.useCallback(async () => {
    try {
      const { data } = await plansDomain.getOne<IFsaPlan>(planId);
      setFsaPlan(data);
    } catch (e) {
      console.error(e);
    }
  }, [planId, setFsaPlan]);

  const fetchPayPeriod = React.useCallback(async () => {
    try {
      const { data } = await userDomain.loadCurrentUser();
      setPayPeriod(data.payPeriods ? +data.payPeriods : 0);
    } catch (e) {
      console.error(e);
    }
  }, [setPayPeriod]);

  const onSubmit = async () => {
    await updateEnrollment(ExternalPlanType.FSA, {
      planId,
      fsaContributionAmounts: fromNumberedFSAContributionToFSAContribution(
        fsaContributionAmounts
      ),
    });
  };

  React.useEffect(() => {
    fetchFSAPlan();
    fetchPayPeriod();
  }, [fetchFSAPlan, fetchPayPeriod]);

  React.useEffect(() => {
    if (fsaPlan) {
      const newFsaContributionAmount: IFsaContributionAmount = {};
      const fsaContributionAmount = enrollment?.fsaContributionAmounts;
      if (fsaPlan.fsaMed) {
        newFsaContributionAmount.med = fsaContributionAmount?.med;
      }
      if (fsaPlan.fsaDep) {
        newFsaContributionAmount.dep = fsaContributionAmount?.dep;
      }
      if (fsaPlan.fsaTra) {
        newFsaContributionAmount.tra = fsaContributionAmount?.tra;
      }
      if (fsaPlan.fsaPark) {
        newFsaContributionAmount.park = fsaContributionAmount?.park;
      }
      const newContribution = fromFSAContributionToNumberedFSAContribution(
        newFsaContributionAmount
      );
      setFsaContributionAmount(
        convertContributionToFixedNumbers(newContribution)
      );

      const newPerPeriodAmount: IFsaContributionAmountNumbered = {};
      Object.keys(newContribution).forEach((key) => {
        const annualValue =
          newContribution[key as keyof IFsaContributionAmountNumbered];
        newPerPeriodAmount[key as keyof IFsaContributionAmountNumbered] =
          annualValue && annualValue / payPeriod;
      });
      setPerPeriodAmount(convertContributionToFixedNumbers(newPerPeriodAmount));
    }
  }, [enrollment, fsaPlan, setFsaContributionAmount, payPeriod]);

  const onCtrlVDetection = React.useCallback(
    (e: any) => {
      if (e.ctrlKey && e.key === 'v' && inputFocus) {
        setIsPasted(true);
      }
    },
    [setIsPasted, inputFocus]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', onCtrlVDetection);
    return () => {
      window.addEventListener('keydown', onCtrlVDetection);
    };
  }, [onCtrlVDetection]);

  const getCorrectContributionAmount = (
    newValue?: number,
    oldValue?: number
  ): number | undefined => {
    if (!newValue) return;
    if (!oldValue) return +toFixed(newValue, 2);

    if (isPasted) return +toFixed(newValue, 2);

    const [newInt, newDec] = getSplitedNumericString(newValue);
    const [oldInt, oldDec] = getSplitedNumericString(oldValue);

    if (!newDec) return +toFixed(newValue, 2);
    if (!oldDec) return +toFixed(newValue, 2);

    if (newDec.length <= 2) return newValue;

    if (newInt === oldInt && oldDec.length === 2 && newDec.length === 3)
      return oldValue;
    return +toFixed(newValue, 2);
  };

  const onChangePerPeriodContribution = (
    key: keyof IFsaContributionAmountNumbered
  ) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
        ? convertCurrencyToNumber(e.target.value)
        : undefined;
      const newValue = getCorrectContributionAmount(
        value,
        perPeriodAmount[key]
      );

      setPerPeriodAmount((prevAmount) => ({
        ...prevAmount,
        [key]: newValue,
      }));

      setIsPasted(false);
      setFsaContributionAmount((prevAmount) => ({
        ...prevAmount,
        [key]: getCorrectContributionAmount(
          newValue && newValue * payPeriod,
          fsaContributionAmounts[key]
        ),
      }));
    };
  };

  const onChangeAnnualContribution = (key: keyof IFsaContributionAmount) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
        ? convertCurrencyToNumber(e.target.value)
        : undefined;
      const newValue = getCorrectContributionAmount(
        value,
        fsaContributionAmounts[key]
      );

      setFsaContributionAmount((prevAmount) => ({
        ...prevAmount,
        [key]: newValue,
      }));

      setIsPasted(false);
      setPerPeriodAmount((prevAmount) => ({
        ...prevAmount,
        [key]: getCorrectContributionAmount(
          newValue && newValue / payPeriod,
          perPeriodAmount[key]
        ),
      }));
    };
  };

  const isValid = () => {
    const keys: Array<keyof IFsaContributionAmount> = Object.keys(
      fsaContributionAmounts
    ) as Array<keyof IFsaContributionAmount>;
    for (const key of keys) {
      const maxValue = getAnnualLimit(key);
      if (maxValue === 0) {
        continue;
      }

      const currentValue = fsaContributionAmounts[key];
      const isCurrentValueValid =
        (typeof currentValue === 'number' &&
          currentValue <= maxValue &&
          currentValue >= 0) ||
        typeof currentValue === 'undefined';
      if (!isCurrentValueValid) {
        return false;
      }
    }
    return true;
  };
  const tooltip = (fsaType: string) => {
    let annual = 0;
    let contribute = 0;
    if (fsaPlan) {
      switch (fsaType) {
        case 'med': {
          annual = convertCurrencyToNumber(fsaPlan?.fsaMedEeAmount);
          contribute = convertCurrencyToNumber(fsaPlan?.fsaMedErAmount);
          break;
        }
        case 'dep': {
          annual = convertCurrencyToNumber(fsaPlan?.fsaDepEeAmount);
          contribute = convertCurrencyToNumber(fsaPlan?.fsaDepErAmount);
          break;
        }
        case 'tra': {
          annual = convertCurrencyToNumber(fsaPlan?.fsaTraEeAmount);
          contribute = convertCurrencyToNumber(fsaPlan?.fsaTraErAmount);
          break;
        }
        case 'park': {
          annual = convertCurrencyToNumber(fsaPlan?.fsaParkEeAmount);
          contribute = convertCurrencyToNumber(fsaPlan?.fsaParkErAmount);
          break;
        }
      }
    }

    return (
      (contribute || fsaType === 'dep') && (
        <BlockWithHelpBuble
          iconClass={styles.iconStyles}
          moreInfo={
            fsaType === 'dep'
              ? `${fsaPlan?.fsaDepEeLimit} ${
                  contribute
                    ? ` \nYour employer is contributing $${contribute}`
                    : ''
                }`
              : `The Total Annual Limit is $${annual}
              \nYour employer is contributing $${contribute}`
          }
        />
      )
    );
  };

  return (
    <Box className={styles.fsaFormContainer}>
      <SimpleAlert
        title="Please enter the amounts you  would like to contribute towards your FSA account"
        type="info"
        className={styles.infoMessage}
      />
      <TableContainer>
        <Table size="small" className={styles.fsaTable}>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Contribution per pay period</TableCell>
              <TableCell>Annual Contribution</TableCell>
              <TableCell>Annual Limit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(Object.keys(fsaContributionAmounts) as Array<
              keyof IFsaContributionAmountNumbered
            >)
              .filter(isFsaTypeAvailable)
              .map((contributionKey, i) => (
                <TableRow key={i}>
                  <TableCell>
                    {fsaContributionKeyToLabel[contributionKey]}
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      customInput={Input}
                      variant="outlined"
                      thousandSeparator
                      className={styles.inputCell}
                      value={perPeriodAmount[contributionKey]}
                      allowNegative={false}
                      allowEmptyFormatting
                      allowLeadingZeros={false}
                      onChange={onChangePerPeriodContribution(contributionKey)}
                      onFocus={() => setInputFocus(true)}
                      onBlur={() => setInputFocus(false)}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      customInput={Input}
                      variant="outlined"
                      thousandSeparator
                      className={styles.inputCell}
                      value={fsaContributionAmounts[contributionKey]}
                      onChange={onChangeAnnualContribution(contributionKey)}
                      allowNegative={false}
                      allowEmptyFormatting
                      allowLeadingZeros={false}
                      onFocus={() => setInputFocus(true)}
                      onBlur={() => setInputFocus(false)}
                    />
                  </TableCell>
                  <TableCell>
                    <div className={styles.annualBlock}>
                      <NumberFormat
                        prefix="$"
                        displayType="text"
                        thousandSeparator
                        value={getAnnualLimit(contributionKey)}
                      />
                      {tooltip(contributionKey)}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isValid() && (
        <SimpleAlert
          type="error"
          title="Your Annual Contribution should be less then Annual Limit"
        />
      )}
      <Box className={styles.fsaSubmitButtonContainer}>
        <SubmitButton disabled={!isValid()} label="Enroll" onClick={onSubmit} />
      </Box>
    </Box>
  );
};

export default FSAPlanForm;
