import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Box } from '@material-ui/core';

import LookupFilter from 'components/Table/LookupComponent';
import SearchFilter from 'components/Table/SearchComponent';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import SectionTitle from 'components/SectionTitle';
import AuditLogsTable from 'containers/AuditLogs/AuditLogsTable';
import AuditLogUserType from 'enums/auditLogUserType';
import IBasicOption from 'models/IBasicOption';
import { TableColumn } from 'components/Table/TableComponent';
import { IAuditLog } from 'types/auditLogs';
import { AppStoreInterface } from 'store/app';
import { EMPTY_VALUE } from 'constants/placeholders';

import styles from './styles.module.scss';

interface IProps {
  logEventType: IBasicOption<number>[];
  planType: IBasicOption<number>[];
}

const AdminAuditLogs: React.FC<IProps> = ({ logEventType, planType }) => {
  const logEventTypeDictionary = _.chain(logEventType)
    .keyBy('value')
    .mapValues('label')
    .value();

  const planTypeDictionary = _.chain(planType)
    .keyBy('value')
    .mapValues('label')
    .value();

  const columns: TableColumn<IAuditLog>[] = [
    {
      title: 'Date & Time',
      render: (row) => row.createdOn,
      field: 'createdOn',
      filtering: false,
      /**
       * sorting in reverse chronological order by default
       */
      defaultSort: 'desc',
    },
    {
      title: 'Employee Name',
      render: (row) => `${row.firstName} ${row.lastName}`,
      field: 'employeeName',
      filtering: true,
      filterComponent: (props) => (
        <SearchFilter
          columnDef={props.columnDef}
          onFilterChanged={props.onFilterChanged}
        />
      ),
    },
    {
      title: 'Event Type',
      field: 'eventType',
      render: (row) => row.eventType,
      lookup: logEventTypeDictionary,

      filterComponent: (props) => (
        <LookupFilter
          columnDef={props.columnDef}
          onFilterChanged={props.onFilterChanged}
        />
      ),
    },
    {
      title: 'Plan Type',
      render: (row) => row.planType || EMPTY_VALUE,
      field: 'planTypeId',
      lookup: planTypeDictionary,

      filterComponent: (props) => (
        <LookupFilter
          columnDef={props.columnDef}
          onFilterChanged={props.onFilterChanged}
        />
      ),
    },
    {
      title: 'Description',
      render: (row) => row.description,
      field: 'description',
      filtering: true,

      filterComponent: (props) => (
        <SearchFilter
          columnDef={props.columnDef}
          onFilterChanged={props.onFilterChanged}
        />
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Audit Logs</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <SectionTitle title="Audit Log" />
        <Box className={styles.contentWrapper}>
          <ContentContainer title="Enrollments & Changes" isBox withoutMargin>
            <AuditLogsTable columns={columns} userType={AuditLogUserType.HR} />
          </ContentContainer>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  logEventType: store.options.logEventType,
  planType: store.options.planType,
});

export default connect(mapStateToProps)(AdminAuditLogs);
