import React, { ChangeEvent, FocusEvent } from 'react';
import {
  TextField,
  Box,
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  FormLabel,
} from '@material-ui/core';

interface IProps {
  label: string;
  helperText?: string;
  error: boolean;
  value: string | number | true;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
}
const CustomTextField = withStyles({
  root: {
    '& ::placeholder': {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#9FA2B4',
      opacity: 0.8,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4979C0',
    },
    '& .MuiOutlinedInput-root': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      '& fieldset': {
        borderColor: '#E1E2E7',
      },
      '&:hover fieldset': {
        borderColor: '#4979C0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #4979C0',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 0,
    },
    input: {
      marginTop: '10px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#252733',
    },
  })
);
const MultipleTextField: React.FC<IProps> = ({
  label,
  value,
  error,
  onChange,
  onBlur,
  helperText,
}) => {
  const inputClasses = useStyles();
  return (
    <Box mb="1em">
      <FormLabel className={inputClasses.label} component="legend">
        {label}
      </FormLabel>
      <CustomTextField
        className={inputClasses.input}
        fullWidth
        value={value}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        variant="outlined"
        multiline
        placeholder="Please enter your text"
        rows={4}
        helperText={helperText}
      />
    </Box>
  );
};

export default MultipleTextField;
