import React, { useState, useEffect, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';

import SectionTitle from 'components/SectionTitle';
import Feedbacker from 'components/Feedbacker';
import Backdrop from 'components/Backdrop';
import Selector from 'components/Forms/Selector/SelectorComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';

import transactionsReportDomain from 'api/domains/transactionsReport';
import fromOpenEnrollmentToBasicOption from 'mappers/enrollments/fromOpenEnrollmentToBasicOption';
import mimeToExtension from 'helpers/mimeToExtension';
import IBasicOption from 'models/IBasicOption';
import { downloadFromBlob } from 'helpers/downloadFileFromBlob';
import { ITransactionsReport, ITransactionData } from 'types/transactions';

import TransactionTable from './TransactionTable';

import styles from './styles.module.scss';

const TransactionsReport: React.FC = () => {
  const [openEnrollments, setOpenEnrollments] = useState<
    IBasicOption<string>[]
  >([]);
  const [apiError, setApiError] = useState<string>();
  const [currentTransitionData, setCurrentTransitionData] = useState<
    IBasicOption<string>
  >();
  const [
    transactionsData,
    setTransactionsData,
  ] = useState<ITransactionsReport | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clearFeedbackError = () => setApiError(undefined);

  const setTemporaryApiError = useCallback(
    (error: string) => {
      setApiError(error);
      setTimeout(clearFeedbackError, 5000);
    },
    [setApiError]
  );

  const fetchUserData = useCallback(
    async (id: string) => {
      const { data } = await transactionsReportDomain.getOne(id);
      setTransactionsData(data);
    },
    [setTransactionsData]
  );

  const fetchCurrentTransactionsData = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true);
        await fetchUserData(id);
      } catch (e) {
        console.error(e);
        setApiError(
          'Cannot fetch information about this open enrollment period'
        );
      } finally {
        setIsLoading(false);
      }
    },
    [fetchUserData]
  );

  const onChangeOpenEnrollment = async (newValue?: IBasicOption<string>) => {
    setCurrentTransitionData(newValue);
    if (!newValue) {
      setTransactionsData(null);
      return;
    }
    fetchCurrentTransactionsData(newValue.value);
  };

  const fetchOpenEnrollments = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await transactionsReportDomain.getList();
      const currentTransitionData = data.find(
        (openEnrollment) => openEnrollment.current === '1'
      );
      const options = data.map(fromOpenEnrollmentToBasicOption);
      if (currentTransitionData) {
        setCurrentTransitionData(
          fromOpenEnrollmentToBasicOption(currentTransitionData)
        );
        await fetchCurrentTransactionsData(currentTransitionData.id);
      } else if (options.length !== 0) {
        setCurrentTransitionData(options[0]);
        fetchCurrentTransactionsData(options[0].value);
      }
      setOpenEnrollments(options);
    } catch (e) {
      console.error(e);
      setTemporaryApiError('Cannot get open enrollments from server');
    } finally {
      setIsLoading(false);
    }
  }, [setOpenEnrollments, setTemporaryApiError, fetchCurrentTransactionsData]);

  const onDownloadClick = async () => {
    try {
      if (!currentTransitionData) return;
      const { data, headers } = await transactionsReportDomain.download(
        currentTransitionData.value
      );
      const getFilename = (str: string) =>
        str ? str.slice(str.indexOf('"') + 1, str.lastIndexOf('.')) : '';

      const extension = mimeToExtension(data.type);
      downloadFromBlob(
        data,
        `${getFilename(headers['content-disposition']) || ''}${
          currentTransitionData.label
        }.${extension}`
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchOpenEnrollments();
  }, [fetchOpenEnrollments]);

  const getNewData = (
    data: Record<string, ITransactionData>
  ): ITransactionData[] => {
    const newData = [];
    for (let key in data) {
      newData.push(data[key]);
    }
    return newData;
  };

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Transactions Report</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <SectionTitle title="Transactions Report" />
        <Box className={styles.main}>
          {isLoading ? null : openEnrollments.length !== 0 ? (
            <Box>
              <Box className={styles.selectBox}>
                <Box className={styles.selector}>
                  <Selector
                    options={openEnrollments}
                    value={currentTransitionData}
                    isLabeled
                    placeholder="Open enrollment"
                    onChange={onChangeOpenEnrollment}
                    withoutDefaultOption
                  />
                </Box>
                {transactionsData?.data && (
                  <SubmitButton
                    label="Download"
                    className={styles.downloadBtn}
                    onClick={onDownloadClick}
                  />
                )}
              </Box>
              {transactionsData?.data ? (
                <TransactionTable
                  layoutData={transactionsData?.layout}
                  newData={getNewData(transactionsData?.data?.end)}
                  oldData={transactionsData?.data?.start}
                />
              ) : (
                <Box className={styles.defaultMessageContainer}>
                  <Typography variant="h5" className={styles.placeholder}>
                    Sorry, we don't have information about this Open Enrollment
                    Period
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box className={styles.defaultMessageContainer}>
              <Typography variant="h5" className={styles.placeholder}>
                Transaction report shows all changes that happened during open
                enrollment.
                <br />
                Please come back after the end of the open enrollment period.
              </Typography>
            </Box>
          )}
          <Feedbacker
            severity="error"
            feedbackMessage={apiError || ''}
            open={!!apiError}
            clearFeedback={clearFeedbackError}
          />
          <Backdrop open={isLoading} />
        </Box>
      </Box>
    </>
  );
};

export default TransactionsReport;
