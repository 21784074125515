import React from 'react';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import Link from '@material-ui/core/Link';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as fileIcon } from 'assets/file.svg';
import Backdrop from 'components/Backdrop';
import { ILdtPlan } from 'types/plans';
import Disclaimer from './Disclaimer';
import CoverageTable from './CoverageTable';

import styles from './styles.module.scss';

interface IProps {
  plan: ILdtPlan;
  isFileLoading: boolean;
  handleOpenFile: () => Promise<void>;
  employeeId?: string | number | null;
}

const GroupLifePlan: React.FC<IProps> = ({
  plan,
  handleOpenFile,
  isFileLoading,
  employeeId,
}) => {
  return (
    <>
      <Backdrop open={isFileLoading} />
      {plan.fileId && (
        <Box className={styles.fileLinkContainer}>
          <SvgIcon
            component={fileIcon}
            width="19"
            height="21"
            viewBox="0 0 19 21"
          />
          <Link className={styles.fileLink} onClick={handleOpenFile}>
            View Benefits Summary
          </Link>
        </Box>
      )}
      <Box className={styles.planNameContainer}>
        <Typography variant="h6" className={styles.title}>
          <PlanIcons planTypeId={Number(plan.planTypeId)} />
          {plan.title}
        </Typography>
        <Typography className={styles.mblComment} variant="subtitle1">
          {plan.mblComment}
        </Typography>
      </Box>
      <TableContainer className={styles.planTableContainer} component={Box}>
        <Table className={styles.planTable}>
          <TableBody>
            <TableRow>
              <TableCell scope="row">Elimination Period:</TableCell>
              <TableCell scope="row">{plan.eliminationPeriod}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Benefit Percentage:</TableCell>
              <TableCell scope="row">{plan.benefitPercentage}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Benefit Maximum:</TableCell>
              <TableCell scope="row">{plan.maxAmount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Benefit Period:</TableCell>
              <TableCell scope="row">{plan.benefitPeriod}</TableCell>
            </TableRow>
            {plan?.customFields?.map((field, index) => (
              <TableRow key={index}>
                <TableCell scope="row">{field.fieldLabel}:</TableCell>
                <TableCell scope="row">{field.fieldValue}</TableCell>
              </TableRow>
            ))}
            <CoverageTable plan={plan} employeeId={employeeId} />
          </TableBody>
        </Table>
      </TableContainer>
      <Disclaimer />
    </>
  );
};

export default GroupLifePlan;
