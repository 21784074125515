import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import SignaturePad from 'react-signature-canvas';

import ModalWindow from 'components/Modal/ModalWindowComponent';
import Signature from 'components/Signature';

import styles from './styles.module.scss';

interface IProps {
  title?: string;
  open: boolean;
  signing: boolean;
  signatureStored: boolean;
  handleClose: () => void;
  sigCanvas: React.RefObject<SignaturePad>;
  setSignatureStored: (value: React.SetStateAction<boolean>) => void;
  handleSign: () => void;
}

const SignatureModal: React.FC<IProps> = ({
  title,
  open,
  signing,
  sigCanvas,
  signatureStored,
  handleClose,
  setSignatureStored,
  handleSign,
}) => {
  useEffect(() => {
    const handleResize = () => {
      setSignatureStored(false);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const check = () => {
    if (sigCanvas.current && signatureStored) {
      const canvas = sigCanvas.current?.getTrimmedCanvas();
      return canvas.width === 1 && canvas.height === 1;
    }
    return true;
  };
  const modalActions = (
    <Box className={styles.actionsModal}>
      <Button
        className={styles.cancelBtn}
        variant="text"
        color="primary"
        onClick={handleClose}
      >
        Close
      </Button>
      <Button
        className={styles.submitbtn}
        disabled={check() || signing}
        variant="contained"
        color="primary"
        onClick={handleSign}
      >
        Sign
      </Button>
    </Box>
  );
  return (
    <>
      <ModalWindow
        handleClose={handleClose}
        title={title ?? 'Signature required to approve'}
        className={styles.signatureModal}
        isOpen={open}
      >
        <Signature
          // minStrokeWidth={0.5}
          // maxStrokeWidth={2.5}
          sigCanvas={sigCanvas}
          setSignatureStored={setSignatureStored}
        />
        {modalActions}
      </ModalWindow>
    </>
  );
};

export default SignatureModal;
