import { Action } from 'redux-actions';
import { all, put, call, takeEvery } from 'redux-saga/effects';

import onboardingDomain from 'api/domains/onboarding';
import { fetchCustomData } from './routines';

function* fetchCustomDataWorker(
  action: Action<{ moveForward?: () => void | Promise<void> }>
) {
  try {
    yield put(fetchCustomData.request());
    const { data } = yield call(onboardingDomain.getOnboarding);

    // Currently if data equal to empty array we should moveForward to the next step
    if (Array.isArray(data) && !data.length) {
      const moveForward = action?.payload?.moveForward;
      if (moveForward) {
        yield call(moveForward);
        return;
      }
    }

    if (!data) {
      yield put(fetchCustomData.fulfill());
    }
    yield put(fetchCustomData.success({ ...data }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(fetchCustomData.fulfill());
  }
}

function* watchFetchCustomData() {
  yield takeEvery(fetchCustomData.trigger, fetchCustomDataWorker);
}

export default function* userSaga() {
  yield all([watchFetchCustomData()]);
}
