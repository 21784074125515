import React, { useCallback, useEffect, useState } from 'react';
import { Backdrop, Box } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Actions } from 'redux/reducers/onboarding/actions';
import * as onboardingRoutines from 'redux/sagas/onboarding/routines';
import { AppStoreInterface } from 'store/app';

import { SimpleStepDataInterface } from 'components/Stepper/SimpleStepper/SimpleStepperComponent';
import AdaptiveSimpleStepper from 'components/Stepper/SimpleStepper/AdaptiveSimpleStepperComponent';
import Feedbacker from 'components/Feedbacker';

import ExternalOnboardingStep from 'enums/externalOnboardingStep';
import AuthMethod from 'enums/authMethod';
import ExternalRegistrationStep from 'enums/externalRegistrationStep';
import CustomDocuments from './steps/CustomDocuments';
import { stepMapping } from './stepMapping';
import EmploymentEligibilityVerification from './steps/EmploymentEligibilityVerification';
import TaxWithholding from './steps/TaxWithholding';
import Payroll from './steps/Payroll';
import AddSignature from './steps/AddSignature';
import SummaryStep from './steps/Summary';
import EEO from './steps/EEO';
import ProfileSettings from '../Registration/steps/ProfileSettings';
import AccountSettings from '../Registration/steps/AccountSettings';

import styles from './styles.module.scss';

interface OnboardingPropsInterface {
  onboardingStepIndex?: number;
  registrationStepIndex?: number;
  payrollEnabled: string | null;
  eeoEnabled: string | null;
  i9Enabled: string | null;
  w4Enabled: string | null;
  authenticationMethod: AuthMethod | null;
  actions: {
    setIsLoading: (isLoading: boolean) => void;
  };
}

const OnboardingContainer: React.FC<OnboardingPropsInterface> = ({
  onboardingStepIndex,
  registrationStepIndex,
  authenticationMethod,
  payrollEnabled,
  eeoEnabled,
  actions,
  i9Enabled,
  w4Enabled,
}) => {
  const { setIsLoading } = actions;
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [feedbackErrorMessage, setFeedbackError] = useState<string | undefined>(
    undefined
  );

  const { loading } = useSelector(
    (state: AppStoreInterface) => state.onboarding
  );

  const stepsFromProps = useCallback(() => {
    if (typeof onboardingStepIndex === 'number') {
      let steps = stepMapping;
      if (!isPayrollEnabled())
        steps = steps.filter((step) => step !== ExternalOnboardingStep.Payroll);
      if (!isEEOEnabled())
        steps = steps.filter((step) => step !== ExternalOnboardingStep.Eeo);
      if (!isI9Enabled())
        steps = steps.filter(
          (step) => step !== ExternalOnboardingStep.Eligibility
        );
      if (!isW4Enabled())
        steps = steps.filter((step) => step !== ExternalOnboardingStep.Tax);
      let lastCompletedStepIndex = null;
      let index = onboardingStepIndex;
      while (true) {
        // eslint-disable-next-line
        lastCompletedStepIndex = steps.findIndex((step) => +step === +index);
        if (lastCompletedStepIndex !== -1 || index === 1) break;
        index--;
      }

      setActiveStepIndex(lastCompletedStepIndex + 1);
    }
    // eslint-disable-next-line
  }, [onboardingStepIndex]);

  const isI9Enabled = () => {
    return i9Enabled && +i9Enabled === 1;
  };

  const isW4Enabled = () => {
    return w4Enabled && +w4Enabled === 1;
  };

  const isPayrollEnabled = () => {
    return payrollEnabled && +payrollEnabled === 1;
  };

  const isEEOEnabled = () => {
    return eeoEnabled && +eeoEnabled === 1;
  };

  const isFinishStep = () => {
    let finishIndex = 7;
    if (!isPayrollEnabled()) finishIndex--;
    if (!isEEOEnabled()) finishIndex--;
    if (!isI9Enabled()) finishIndex--;
    if (!isW4Enabled()) finishIndex--;
    return activeStepIndex === finishIndex;
  };

  const index = () => {
    let finishIndex = 9;
    if (!accountSetupVisible) finishIndex--;
    if (!isPayrollEnabled()) finishIndex--;
    if (!isEEOEnabled()) finishIndex--;
    if (!isI9Enabled()) finishIndex--;
    if (!isW4Enabled()) finishIndex--;
    return finishIndex;
  };

  useEffect(() => {
    stepsFromProps();
  }, [stepsFromProps]);

  const moveForward = async () => {
    setActiveStepIndex(activeStepIndex + 1);
  };

  const onFinish = async () => {
    window.location.reload();
  };

  const moveBack = async () => {
    if (activeStepIndex > 1) {
      setActiveStepIndex(activeStepIndex - 1);
    }
  };

  const handleStepClick = (index: number) => {
    if (index < activeStepIndex && index > 1) {
      setActiveStepIndex(index);
    }
  };

  const accountSetupVisible =
    authenticationMethod === AuthMethod.Username &&
    registrationStepIndex === ExternalRegistrationStep.WelcomeEmail;

  const optionalAccountSetup: SimpleStepDataInterface[] = accountSetupVisible
    ? [
        {
          label: 'Account Settings',
          disabled: isFinishStep(),
          content: (
            <AccountSettings
              label="Account Settings"
              index={activeStepIndex + 1}
              count={index()}
              setError={setFeedbackError}
              setIsLoading={setIsLoading}
              moveForward={moveForward}
            />
          ),
        },
      ]
    : [];

  const requiredStartSteps: SimpleStepDataInterface[] = [
    {
      label: 'Employee Profile',
      disabled: isFinishStep(),
      content: (
        <ProfileSettings
          index={activeStepIndex + 1}
          count={index()}
          setError={setFeedbackError}
          setIsLoading={setIsLoading}
          moveForward={moveForward}
        />
      ),
    },
    {
      label: 'Documents',
      disabled: isFinishStep(),
      content: (
        <CustomDocuments
          index={activeStepIndex + 1}
          count={index()}
          setIsLoading={setIsLoading}
          moveForward={moveForward}
        />
      ),
    },
  ];

  const requiredFinalSteps: SimpleStepDataInterface[] = [
    {
      label: 'Signature',
      disabled: isFinishStep(),
      content: (
        <AddSignature
          index={activeStepIndex + 1}
          count={index()}
          moveBack={moveBack}
          setIsLoading={setIsLoading}
          moveForward={moveForward}
        />
      ),
    },
    {
      label: 'Finish',
      content: (
        <SummaryStep
          index={activeStepIndex + 1}
          count={index()}
          moveBack={moveBack}
          setIsLoading={setIsLoading}
          moveForward={onFinish}
        />
      ),
    },
  ];

  const getConditionalOnboardingSteps = () => {
    const steps: SimpleStepDataInterface[] = [];

    if (isI9Enabled()) {
      steps.push({
        label: 'Employment Eligibility Verification',
        disabled: isFinishStep(),
        content: (
          <EmploymentEligibilityVerification
            index={activeStepIndex + 1}
            count={index()}
            moveBack={moveBack}
            setIsLoading={setIsLoading}
            moveForward={moveForward}
          />
        ),
      });
    }

    if (isW4Enabled()) {
      steps.push({
        label: 'Tax Withholding',
        disabled: isFinishStep(),
        content: (
          <TaxWithholding
            index={activeStepIndex + 1}
            count={index()}
            moveBack={moveBack}
            setIsLoading={setIsLoading}
            moveForward={moveForward}
          />
        ),
      });
    }
    if (isPayrollEnabled()) {
      steps.push({
        label: 'Payroll',
        disabled: isFinishStep(),
        content: (
          <Payroll
            index={activeStepIndex + 1}
            count={index()}
            moveBack={moveBack}
            setIsLoading={setIsLoading}
            moveForward={moveForward}
          />
        ),
      });
    }

    if (isEEOEnabled()) {
      steps.push({
        label: 'EEO',
        disabled: isFinishStep(),
        content: (
          <EEO
            index={activeStepIndex + 1}
            count={index()}
            moveForward={moveForward}
            moveBack={moveBack}
            setIsLoading={setIsLoading}
            setError={setFeedbackError}
          />
        ),
      });
    }

    return steps;
  };

  const onboardingSteps: SimpleStepDataInterface[] = [
    ...optionalAccountSetup,
    ...requiredStartSteps,
    ...getConditionalOnboardingSteps(),
    ...requiredFinalSteps,
  ];

  const stepperProps = {
    steps: onboardingSteps,
    activeStep: activeStepIndex,
    nonLinear: true,
    breakpoint: 1200,
    onStepClick: handleStepClick,
  };

  const renderFeedbacker = () => {
    return (
      <Feedbacker
        open={!!feedbackErrorMessage}
        clearFeedback={() => setFeedbackError(undefined)}
        feedbackMessage={feedbackErrorMessage as string}
        severity="error"
      />
    );
  };

  return (
    <Box className={styles.onboardingContainer}>
      <Helmet>
        <title>MyBenefitLink | Onboarding</title>
      </Helmet>
      <Backdrop className={styles.loading} open={loading} />
      <AdaptiveSimpleStepper {...stepperProps} />
      {renderFeedbacker()}
    </Box>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  payrollEnabled: store.user.payrollEnabled,
  eeoEnabled: store.user.eeoEnabled,
  w4Enabled: store.user.w4Enabled,
  i9Enabled: store.user.i9Enabled,
  authenticationMethod: store.user.authenticationMethod,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      setIsLoading: typeof Actions.setLoading;
      fetchCustomData: typeof onboardingRoutines.fetchCustomData;
    }
  >(
    {
      fetchCustomData: onboardingRoutines.fetchCustomData,
      setIsLoading: Actions.setLoading,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingContainer);
