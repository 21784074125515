import { IExternalEEOPayload, IEEOPayload } from 'types/onboarding';
import { convertStringToNumber } from 'helpers/numberHelpers';

const convertExternalEEOValueToNumber = (
  value: string | null
): number | null => {
  return value !== null ? convertStringToNumber(value) : null;
};

export const fromExternalEEOToEEO = (
  externalEEO: IExternalEEOPayload
): IEEOPayload => ({
  eeoGender: convertExternalEEOValueToNumber(externalEEO.eeoGender),
  eeoMilitaryStatus: convertExternalEEOValueToNumber(
    externalEEO.eeoMilitaryStatus
  ),
  eeoJobCategory: convertExternalEEOValueToNumber(externalEEO.eeoJobCategory),
  eeoRace: convertExternalEEOValueToNumber(externalEEO.eeoRace),
});
