import { isNumber } from 'lodash';

const convertCurrencyToNumber = (
  str: string | null,
  fullAmount?: number | null
): number => {
  if (isNumber(str)) return str;
  if (!str) return 0;
  let temp: string = str;
  let result = 0;
  if (temp[0] === '$') temp = temp.slice(1);
  if (temp[temp.length - 1] === '%' && fullAmount) {
    result = fullAmount * Number(temp.slice(0, -1)) * 100;
    return result;
  }
  temp = temp.split(',').join('');
  return +temp;
};

export default convertCurrencyToNumber;
