export const BASE_NAME = '/mbl';
// TODO: this should be changed
export const OLD_VERSION_BASE_NAME = '/mbl_lite';

export const DEFAULT_PATH = '/';
export const LOGIN_PATH = '/login';
export const REGISTRATION_PATH = '/registration';
export const ONBOARDING_PATH = '/onboarding';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password';
export const PROFILE_PATH = '/profile';
export const CONTACT_PATH = '/contact';
export const DEPENDENTS_PATH = '/dependents';
export const DOCUMENTS_PATH = '/documents';
export const AUDIT_LOGS_PATH = '/audit';
export const EMPLOYEES_CENSUS = '/employees-census';
export const CHANGE_PASSWORD_PATH = '/change-password';
export const CHANGE_SECURITY_QUESTION_PATH = '/change-security-question';
export const ACTION_CENTER_PATH = '/action-center';
export const ADMIN_AUDIT_LOGS_PATH = '/audit-admin';
export const REPORTS_PATH = '/reports';
export const MESSAGE_BOARD_PATH = '/message-board';
export const STATUS_DASHBOARD_PATH = '/status-dashboard';
export const EMPLOYER_FORMS = '/employer-forms';
export const BENEFICIARIES_PATH = '/beneficiaries';
export const TRANSACTIONS_PATH = '/transactions';
export const EMAIL_PATH = '/message-board';
export const CHANGE_BENEFITS = '/change-benefits';
