import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import { Helmet } from 'react-helmet';
import { NavLink, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { Typography } from '@material-ui/core';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import FormikInput from 'components/Forms/Formik/Input';
import Feedbacker from 'components/Feedbacker';
import { LOGIN_PATH } from 'constants/routes';
import userDomain from 'api/domains/user';
import * as validationHelper from 'helpers/validation';
import * as feedbackMessages from 'constants/feedbackMessages';
import BoxComponent from 'components/LoginBox/BoxComponent';
import styles from './styles.module.scss';

interface IFormValues {
  email: string;
}

const validationSchema = yup.object().shape({
  email: validationHelper.email.required('Field is required'),
});

const ForgotPassword: React.FC = () => {
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const initialValues: IFormValues = {
    email: '',
  };
  const history = useHistory();
  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    try {
      await userDomain.forgotPassword(values);
      setFeedbackMessage(feedbackMessages.LINK_SENT);
      setSuccess(true);
    } catch (err) {
      const errCode = err.response.data.messageCode;
      const message =
        errCode === 40304
          ? feedbackMessages.FOLLOW_EMAIL_LINK
          : feedbackMessages.EMAIL_NOT_EXIST;
      formikHelpers.setFieldError('email', message);
    }
  };

  const handleBack = () => {
    history.push('/');
  };
  const formVisible = { display: !success ? 'block' : 'none' };

  return (
    <BoxComponent>
      <Helmet>
        <title>MyBenefitLink | Forgot Password</title>
      </Helmet>
      {success ? (
        <>
          <Typography className={styles.infoConfirm}>
            Please check your email for further instructions on resetting your
            password
          </Typography>
          <SubmitButton
            onClick={handleBack}
            label="Back to login"
            className={styles.btn}
          />
        </>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<IFormValues>) => (
            <Form style={formVisible} className={styles.form}>
              <Typography className={styles.title}>
                Reset Your Password
              </Typography>
              <Field
                component={FormikInput}
                fullWidth
                name="email"
                type="text"
                label="Email"
                className={styles.formItem}
              />
              <SubmitButton
                disabled={formik.isSubmitting}
                label="Reset password"
                className={styles.btn}
              />
              <Box>
                <NavLink to={LOGIN_PATH} className={styles.link}>
                  Back to login
                </NavLink>
              </Box>
            </Form>
          )}
        </Formik>
      )}
      <Feedbacker
        open={!!feedbackMessage}
        clearFeedback={() => setFeedbackMessage(null)}
        severity="success"
        feedbackMessage={feedbackMessage as string}
        displayInTheMiddle={false}
      />
    </BoxComponent>
  );
};

export default ForgotPassword;
