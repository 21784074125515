import React from 'react';
import setClassNames from 'classnames';
import styles from './styles.module.scss';

export interface ProgressBarPropsInterface {
  max: number;
  value: number;
  className?: string;
  fixedHeight?: boolean;
}

const calculateProgressInPercent = (max: number, value: number) => {
  return (value / max) * 100;
};

const ProgressBar: React.FC<ProgressBarPropsInterface> = ({
  max,
  value,
  children,
  className,
  fixedHeight,
}) => {
  const progress = calculateProgressInPercent(max, value);

  const classNames = setClassNames(styles.progressBar, {
    [className || '']: Boolean(className),
  });

  const progressClasses = setClassNames(styles.progress, {
    [styles.low]: progress < 25,
    [styles.medium]: progress >= 25 && progress < 50,
    [styles.normal]: progress >= 50 && progress < 75,
    [styles.good]: progress >= 75 && progress < 100,
    [styles.awesome]: progress >= 100,
  });

  const progressBarStyles = { width: `${progress || 1}%` };

  return (
    <div className={`${classNames} ${fixedHeight && styles.fixed}`}>
      <div
        style={progressBarStyles}
        className={`${progressClasses} ${fixedHeight && styles.fixed}`}
      >
        {children}
      </div>
    </div>
  );
};

export default ProgressBar;
