import { MappedDock } from '.';

export const planeNestedList = (nestedList: MappedDock[]): JSX.Element[] => {
  return nestedList.reduce((acc, dock) => {
    if (dock.children.length < 0) {
      return [...acc, dock.parent];
    }
    return [...acc, dock.parent, ...planeNestedList(dock.children)];
  }, [] as JSX.Element[]);
};
