import {
  IOpenEnrollment,
  IOpenEnrollmentsUserInfo,
} from 'types/statusDashboard';
import { IPlanGroup } from 'types/plans';
import api from '../adapter';

class StatusDashboardDomain {
  getList() {
    return api.makeRequest<IOpenEnrollment[]>('/dashboards', 'GET');
  }

  getOne(id: string | number) {
    return api.makeRequest<IOpenEnrollmentsUserInfo[]>(
      `/dashboards/${id}`,
      'GET'
    );
  }

  getPlans(id: string | number) {
    return api.makeRequest<IPlanGroup[]>(`/dashboards/${id}/plans`, 'GET');
  }

  download(id: string | number) {
    return api.makeRequest<Blob>(
      `/dashboards/${id}/download`,
      'GET',
      null,
      undefined,
      null,
      { responseType: 'blob' }
    );
  }
}

export default new StatusDashboardDomain();
