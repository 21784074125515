import {
  DependentInterface,
  DependentAddressInterface,
} from 'types/dependents';

export const fromDependentToDependentAddress = (
  dependent: DependentInterface
): DependentAddressInterface => ({
  id: dependent.id,
  address1: dependent.address1,
  address2: dependent.address2,
  city: dependent.city,
  state: dependent.state,
  zip: dependent.zip,
  country: dependent.country,
  dependentDifferentAddress: dependent.dependentDifferentAddress,
});
