import {
  IFormQuestion,
  IModifiedFormQuestion,
  IFormQuestionError,
} from 'types/additionalInfo';

function modifyQuestions(
  acc: IModifiedFormQuestion[],
  question: IFormQuestion
) {
  const { dependents, ...restQuestion } = question;

  if (dependents && dependents.length) {
    const newQuestions = dependents.map((dependent) => ({
      ...restQuestion,
      dependent,
    }));
    return [...acc, ...newQuestions];
  } else {
    return [...acc, { ...restQuestion, dependent: null }];
  }
}

function sortByDependentId(a: IModifiedFormQuestion, b: IModifiedFormQuestion) {
  const first = a.dependent ? +a.dependent.id : 0;
  const second = b.dependent ? +b.dependent.id : 0;

  return first - second;
}

function sortByIndex(
  a: IModifiedFormQuestion | IFormQuestionError,
  b: IModifiedFormQuestion | IFormQuestionError
) {
  return a.index - b.index;
}

export function fromFormQuestionsToQuestionsMap(questions: IFormQuestion[]) {
  let questionsMap = new Map<string, IModifiedFormQuestion[]>();

  questions
    .reduce(modifyQuestions, [])
    .sort(sortByDependentId)
    .forEach((mappedQuestion) => {
      const { dependent } = mappedQuestion;

      let key = 'Employee:';
      if (dependent) {
        key = `${dependent.firstName} ${dependent.lastName}:`;
      }
      if (questionsMap && questionsMap.get(key)) {
        questionsMap.set(
          key,
          [
            ...(questionsMap.get(key) as IModifiedFormQuestion[]),
            mappedQuestion,
          ].sort(sortByIndex)
        );
      } else {
        questionsMap.set(key, [mappedQuestion].sort(sortByIndex));
      }
    });

  return questionsMap;
}

export function fromFormQuestionsToQuestionsMapErrors(
  questions: IFormQuestion[]
) {
  let questionsMapErrors = new Map<string, IFormQuestionError[]>();

  questions
    .reduce(modifyQuestions, [])
    .sort(sortByDependentId)
    .forEach((mappedQuestion) => {
      const { dependent, index, required } = mappedQuestion;

      let key = 'Employee:';
      if (dependent) {
        key = `${dependent.firstName} ${dependent.lastName}:`;
      }
      if (questionsMapErrors.get(key)) {
        questionsMapErrors.set(
          key,
          [
            ...(questionsMapErrors.get(key) as IFormQuestionError[]),
            { required, index, error: undefined },
          ].sort(sortByIndex)
        );
      } else {
        questionsMapErrors.set(
          key,
          [{ required, index, error: undefined }].sort(sortByIndex)
        );
      }
    });
  return questionsMapErrors;
}
