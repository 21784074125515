import React from 'react';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import Typography from '@material-ui/core/Typography';
import PlanFileLink from 'components/FileLink/PlanFileLink';
import { IDentalPlan } from 'types/plans';
import { Box } from '@material-ui/core';
import PlanIcons from 'components/PlanIcons/PlanIcons';

import styles from './styles.module.scss';

interface IDentalPlanCompareTableProps {
  plans: IDentalPlan[];
  planId?: string | number;
  onChange?: (planId: string) => void;
}

const COL_SPAN = 3;

const DentalPlanCompareTable: React.FC<IDentalPlanCompareTableProps> = ({
  plans,
  planId,
  onChange,
}) => {
  const mapPropsToRow = (title: string, keys: Array<keyof IDentalPlan>) => {
    return (
      <TableRow>
        <TableCell className={styles.dentalTitleCell}>{title}</TableCell>
        {plans.map((plan) => (
          <>
            {keys.map((key) => (
              <TableCell
                className={plan.id === planId ? `${styles.myPlanCell}` : ``}
              >
                {key === 'deductibleFamilyIn' &&
                (plan.deductibleFamilyIn || plan.deductibleSingleIn)
                  ? `${plan.deductibleSingleIn} / ${plan.deductibleFamilyIn} `
                  : key === 'deductibleFamilyOut' &&
                    (plan.deductibleFamilyOut || plan.deductibleSingleOut)
                  ? `${plan.deductibleSingleOut} / ${plan.deductibleFamilyOut}`
                  : plan[key]}
              </TableCell>
            ))}
            {new Array(COL_SPAN - keys.length).fill(0).map((_) => (
              <TableCell
                className={plan.id === planId ? `${styles.myPlanCell}` : ''}
              />
            ))}
          </>
        ))}
      </TableRow>
    );
  };

  const checkMblComment = () => {
    return plans.filter((el) => el.mblComment !== null).length !== 0;
  };

  const renderButtonSelect = () => {
    return onChange ? (
      <TableRow>
        <TableCell className={styles.dentalTitleCell} scope="row" />
        {plans.map((plan) => (
          <TableCell
            colSpan={COL_SPAN}
            className={plan.id === planId ? `${styles.myPlanCell}` : ''}
          >
            {plan.id !== planId && onChange && (
              <Box className={styles.wrapper}>
                <SubmitButton
                  label="Select"
                  variant="contained"
                  onClick={() => onChange(plan.id)}
                  className={styles.btn}
                />
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    ) : (
      <></>
    );
  };

  return (
    <TableContainer className={styles.planTableContainer} component={Box}>
      <Table className={styles.planTable}>
        <TableHead>
          <TableRow>
            <TableCell />
            {plans.map((plan) => (
              <TableCell
                colSpan={COL_SPAN}
                className={plan.id === planId ? `${styles.myPlanCell}` : ''}
              >
                <Typography variant="h6" className={styles.myPlan}>
                  {plan.id === planId ? 'My plan' : ''}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell />
            {plans.map((plan) => (
              <TableCell
                colSpan={COL_SPAN}
                className={plan.id === planId ? `${styles.myPlanCell}` : ''}
              >
                <Typography variant="h6" className={styles.title}>
                  <PlanIcons planTypeId={Number(plan.planTypeId)} />
                  {plan.title}
                </Typography>
                {plan.id !== planId && onChange && (
                  <Box className={styles.wrapper}>
                    <SubmitButton
                      label="Select"
                      variant="contained"
                      onClick={() => onChange(plan.id)}
                      className={styles.btn}
                    />
                  </Box>
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell />
            {plans.map((plan) => (
              <TableCell
                colSpan={COL_SPAN}
                className={
                  plan.id === planId
                    ? `${styles.myPlanCell} ${styles.fileBox}`
                    : `${styles.fileBox}`
                }
              >
                <PlanFileLink
                  plan={plan}
                  linkLabel="View Detailed Benefits Summary"
                  noFileMessage="No files"
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            {plans.map((plan) => (
              <>
                <TableCell
                  className={`${
                    plan.id === planId ? `${styles.myPlanCell}` : ''
                  } ${styles.subHeader}`}
                >
                  <Box className={styles.titleBox}>In Network</Box>
                </TableCell>
                <TableCell
                  className={`${
                    plan.id === planId ? `${styles.myPlanCell}` : ''
                  } ${styles.subHeader}`}
                >
                  <Box className={styles.titleBox}>Out Of Network</Box>
                </TableCell>
                <TableCell
                  className={`${
                    plan.id === planId ? `${styles.myPlanCell}` : ''
                  } ${styles.subHeader}`}
                >
                  <Box className={styles.titleBox}>Waiting Period</Box>
                </TableCell>
              </>
            ))}
          </TableRow>
          {mapPropsToRow('Deductible (single / family):', [
            'deductibleFamilyIn',
            'deductibleFamilyOut',
          ])}
          {mapPropsToRow('Annual maximum:', ['annualMaxIn', 'annualMaxOut'])}
          {mapPropsToRow('Preventive/Diag.:', [
            'diagnosticIn',
            'diagnosticOut',
          ])}
          {mapPropsToRow('Basic Services:', [
            'basicIn',
            'basicOut',
            'basicWait',
          ])}
          {mapPropsToRow('Major Services:', [
            'majorIn',
            'majorOut',
            'majorWait',
          ])}
          {mapPropsToRow('Periodontics:', [
            'periodonticsIn',
            'periodonticsOut',
            'periodonticsWait',
          ])}
          {mapPropsToRow('Endodontics:', [
            'endodonticsIn',
            'endodonticsOut',
            'endodonticsWait',
          ])}
          {mapPropsToRow('Orthodontics:', ['orthoIn', 'orthoOut', 'orthoWait'])}
          {mapPropsToRow('Orthodontic Max:', [
            'orthoMaxIn',
            'orthoMaxOut',
            'orthoMaxType',
          ])}
          {renderButtonSelect()}
          {checkMblComment() && (
            <TableRow>
              <TableCell>Plan Highlights</TableCell>
              {plans.map((plan) => (
                <TableCell
                  className={`${
                    plan.id === planId ? `${styles.myPlanCell}` : ''
                  } ${styles.tableCell}`}
                  colSpan={COL_SPAN}
                >
                  {plan.mblComment}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DentalPlanCompareTable;
