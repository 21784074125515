import React from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import { Typography } from '@material-ui/core';
import { Plan } from 'types/plans';
import styles from './styles.module.scss';

interface IProps {
  plans: Plan[];
  planId?: string | number;
  onChange?: (planId: string) => void;
}

const OtherPlanCompareTable: React.FC<IProps> = ({
  plans,
  planId,
  onChange,
}) => {
  const mapPropToCell = (key: keyof Plan, title: string) => {
    return (
      <TableRow>
        <TableCell className={styles.othertableCell}>{title}</TableCell>
        {plans.map((plan) => (
          <TableCell
            className={`${plan.id === planId ? `${styles.myPlanCell}` : ''} ${
              styles.othertableCell
            }`}
            scope="row"
          >
            {plan[key]}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const mapPropToTitle = (key: keyof Plan, title: string) => {
    return (
      <TableRow>
        <TableCell className={styles.medicalTitleCell}>{title}</TableCell>
        {plans.map((plan) => (
          <TableCell
            className={`${plan.id === planId ? `${styles.myPlanCell}` : ''} ${
              styles.lifeRowCell
            }`}
          >
            <Typography variant="h6" className={styles.title}>
              <PlanIcons planTypeId={Number(plan.planTypeId)} />
              {plan[key]}
            </Typography>
            {plan.id !== planId && onChange && (
              <Box className={styles.wrapper}>
                <SubmitButton
                  label="Select"
                  variant="contained"
                  onClick={() => onChange(plan.id)}
                  className={styles.btn}
                />
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const mapMyPlan = () => {
    return (
      <TableRow>
        <TableCell />
        {plans.map((plan) => (
          <TableCell
            className={plan.id === planId ? `${styles.myPlanCell}` : ''}
          >
            <Typography variant="h6" className={styles.myPlan}>
              {plan.id === planId ? 'My plan' : ''}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const renderButtonSelect = () => {
    return onChange ? (
      <TableRow>
        <TableCell />
        {plans.map((plan) => (
          <TableCell
            className={plan.id === planId ? `${styles.myPlanCell}` : ''}
          >
            {plan.id !== planId && onChange && (
              <Box className={styles.wrapper}>
                <SubmitButton
                  label="Select"
                  variant="contained"
                  onClick={() => onChange(plan.id)}
                  className={styles.btn}
                />
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    ) : (
      <></>
    );
  };

  const checkMblComment = () => {
    return plans.filter((el) => el.mblComment !== null).length !== 0;
  };
  return (
    <>
      <Box>
        <TableContainer className={styles.planTableContainer} component={Box}>
          <Table className={styles.planTable}>
            <TableHead>
              {planId && mapMyPlan()}
              {mapPropToTitle('title', 'Plan name')}
            </TableHead>
            <TableBody>
              {renderButtonSelect()}
              {checkMblComment() &&
                mapPropToCell('mblComment', 'Plan Highlights:')}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default OtherPlanCompareTable;
