import {
  ExternalBeneficiaryInterface,
  ExternalBeneficiaryPayload,
  IBeneficiaryPersonal,
} from 'types/beneficiaries';
import api from '../adapter';

export class BeneficiariesDomain {
  getList() {
    return api.makeRequest<ExternalBeneficiaryInterface[]>(
      '/beneficiaries',
      'GET'
    );
  }

  getOne(id: string | number) {
    return api.makeRequest<ExternalBeneficiaryInterface>(
      `/beneficiaries/${id}`,
      'GET'
    );
  }

  getPersonal(password: string) {
    return api.makeRequest<IBeneficiaryPersonal[]>(
      '/beneficiaries/personal',
      'POST',
      { password }
    );
  }

  create(payload: ExternalBeneficiaryPayload) {
    return api.makeRequest<ExternalBeneficiaryInterface>(
      '/beneficiaries',
      'POST',
      payload
    );
  }

  update(id: string | number, payload: ExternalBeneficiaryPayload) {
    return api.makeRequest<void>(`/beneficiaries/${id}`, 'PUT', payload);
  }

  delete(id: string | number) {
    return api.makeRequest<any>(`/beneficiaries/${id}`, 'DELETE');
  }

  getListByEmployeeId(id: string) {
    return api.makeRequest<ExternalBeneficiaryInterface[]>(
      `/employees/${id}/beneficiaries`,
      'GET'
    );
  }
}

export default new BeneficiariesDomain();
