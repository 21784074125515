import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from 'constants/app';
import IBasicOption from 'models/IBasicOption';
import { PartialUserProfile } from 'types/user';

const getInitialValues = (
  genderOptions: IBasicOption<any>[],
  smokerOptions: IBasicOption<any>[],
  maritalStatusOptions: IBasicOption<number>[],
  stateOptions: IBasicOption<any>[],
  userProfile?: PartialUserProfile
) => {
  const smokerRes =
    userProfile &&
    smokerOptions.find(
      (option) => String(option.value) === String(userProfile.smoker)
    )?.value;
  return {
    firstName: userProfile?.firstName || '',
    middleName: userProfile?.middleName || '',
    lastName: userProfile?.lastName || '',
    maidenName: userProfile?.maidenName || '',
    birthDate:
      userProfile && userProfile.birthDate
        ? typeof userProfile.birthDate === 'string'
          ? moment(userProfile.birthDate, DEFAULT_DATE_FORMAT).toDate()
          : userProfile.birthDate
        : null,
    gender:
      (
        userProfile &&
        genderOptions.find((gender) => gender.value === userProfile.gender)
      )?.value || '',
    smoker: smokerRes === 0 || smokerRes ? smokerRes : '',
    maritalStatusId:
      (
        userProfile &&
        maritalStatusOptions.find(
          (status) => status.value === userProfile.maritalStatusId
        )
      )?.value || 1,
    ssn: userProfile?.ssn || '',
    address1: userProfile?.address1 || '',
    address2: userProfile?.address2 || '',
    zip: userProfile?.zip || '',
    city: userProfile?.city || '',
    state:    (
      userProfile &&
      stateOptions.find(
        (state) => state.value === userProfile.state,
      )
    )?.value || '',
    officePhone: userProfile?.officePhone || '',
    homePhone: userProfile?.homePhone || '',
    cellPhone: userProfile?.cellPhone || '',
  };
};

export default getInitialValues;
