import React from 'react';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';

import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import Input from 'components/Forms/Input/InputComponent';
import { withStyles, SvgIcon, Typography } from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';
import CobraType from 'enums/cobraType';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import ModalWindow from 'components/Modal/ModalWindowComponent';
import FormikInput from 'components/Forms/Formik/Input';
import FormikDatePicker from 'components/Forms/Formik/Datepicker';
import IBasicOption from 'models/IBasicOption';
import { IEmployeeTerminationPayload } from 'types/employee';

import styles from './styles.module.scss';

const getValidationSchema = (cobra: CobraType | null) =>
  yup.object().shape({
    totalDisability: yup.boolean(),
    terminationDate: yup.date().required('Field is required').nullable(),
    disabilityStartDate: yup.date().when('totalDisability', {
      is: true,
      then: yup.date().required('Field is required').nullable(),
      otherwise: yup.date().optional().nullable(),
    }),
    cobraElection: yup.boolean(),
    qualifyingEventId:
      cobra === CobraType.Federal
        ? yup.string().required('Field is required')
        : yup.string().optional(),
    notes: yup.string(),
  });

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  employeeInitials: string;
  qualifyingEvent: IBasicOption<number>[];
  handleTerminateEmployee: (body: IEmployeeTerminationPayload) => void;
}

const CustomFormControlLabel = withStyles({
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
})(FormControlLabel);

const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);

const TerminateEmployeeModal: React.FC<IProps> = (props) => {
  const {
    isOpen,
    handleClose,
    employeeInitials,
    qualifyingEvent,
    handleTerminateEmployee,
  } = props;

  const initialValues: IEmployeeTerminationPayload = {
    terminationDate: null,
    totalDisability: false,
    disabilityStartDate: null,
    qualifyingEventId: '',
    cobraElection: false,
    notes: '',
  };

  const handleSubmit = async (values: IEmployeeTerminationPayload) => {
    try {
      handleTerminateEmployee(values);
      handleClose();
    } catch (err) {
      handleClose();
    }
  };

  const getErrorMessage = (
    key: keyof IEmployeeTerminationPayload,
    formikProps: FormikProps<IEmployeeTerminationPayload>
  ): string | undefined => {
    const { errors, touched } = formikProps;

    const error = errors[key];
    const isTouched = touched[key];
    return isTouched && error ? (error as string) : '';
  };

  const cobraElectionLabel = (
    <>
      <Typography className={styles.checkBoxTitle}>
        Do you want the employee and/or dependents terminated from the insurance
        plans during the COBRA election period?
      </Typography>
      <SimpleAlert type="info">
        <Box className={styles.infoBlockCheckbox}>
          Check the box to have the employee and/or dependents terminated from
          the insurance programs. Once the employee and/or dependents elects and
          pays for coverage in the allotted time frame the coverage will be
          reinstated.
          <br />
          Do not check this box to keep the employee and/or dependents on the
          insurance programs during the COBRA election period. In this case, the
          insurance carrier is not required to provide premium credit when
          claims are incurred during the election period and COBRA is not
          elected and/or paid by the employee and/or dependents.
        </Box>
      </SimpleAlert>
    </>
  );

  return (
    <>
      <ModalWindow
        isOpen={isOpen}
        handleClose={handleClose}
        dividers
        title="Terminate Employee"
      >
        <Box className={styles.terminateModal}>
          <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema(CobraType.Federal)} // To Do. Get cobra value from the API
            onSubmit={handleSubmit}
          >
            {(formik: FormikProps<IEmployeeTerminationPayload>) => (
              <Form className={styles.deleteEmployeeModalForm}>
                <Input
                  value={employeeInitials}
                  label="Employee Name"
                  onChange={() => {}}
                  readOnly
                />
                <FormikDatePicker
                  value={formik.values.terminationDate}
                  label="Termination Date"
                  variant="dialog"
                  name="terminationDate"
                  convertToDate
                  error={!!getErrorMessage('terminationDate', formik)}
                  helperText={getErrorMessage('terminationDate', formik)}
                />
                <Field
                  component={FormikInput}
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                  name="notes"
                  label="Notes"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  className={styles.comment}
                />
                {'Federal' === CobraType.Federal && (
                  <>
                    <Field
                      component={FormikInput}
                      name="qualifyingEventId"
                      label="Qualifying Event"
                      variant="outlined"
                      select
                      fullWidth
                    >
                      <MenuItem
                        value=""
                        disabled
                        className={styles.optionPlaceholder}
                      >
                        Select
                      </MenuItem>
                      {qualifyingEvent.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          className={styles.option}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                    <Box className={styles.cobraElectionCheckbox}>
                      <CustomFormControlLabel
                        control={
                          <CustomCheckbox
                            checked={formik.values.cobraElection}
                            onChange={formik.handleChange}
                            icon={
                              <SvgIcon
                                component={offIcon}
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                              />
                            }
                            checkedIcon={
                              <SvgIcon
                                component={onIcon}
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                              />
                            }
                            color="primary"
                            name="cobraElection"
                          />
                        }
                        label={cobraElectionLabel}
                      />
                    </Box>
                  </>
                )}
                <Box className={styles.totalDisabilityCheckbox}>
                  <CustomFormControlLabel
                    control={
                      <CustomCheckbox
                        icon={
                          <SvgIcon
                            component={offIcon}
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                          />
                        }
                        checkedIcon={
                          <SvgIcon
                            component={onIcon}
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                          />
                        }
                        checked={formik.values.totalDisability}
                        onChange={formik.handleChange}
                        color="primary"
                        name="totalDisability"
                      />
                    }
                    label={
                      <>
                        <Typography className={styles.checkBoxTitle}>
                          Is the loss of coverage due to total disability?
                        </Typography>
                        <Box>
                          {formik.values.totalDisability && (
                            <FormikDatePicker
                              value={formik.values.disabilityStartDate}
                              label="Disability Start Date"
                              name="disabilityStartDate"
                              convertToDate
                              error={
                                !!getErrorMessage('disabilityStartDate', formik)
                              }
                              helperText={getErrorMessage(
                                'disabilityStartDate',
                                formik
                              )}
                            />
                          )}
                        </Box>
                      </>
                    }
                  />
                </Box>
                <SubmitButton
                  className={styles.terminateSubmitBtn}
                  disabled={formik.isSubmitting}
                  color="primary"
                  label="Terminate"
                />
              </Form>
            )}
          </Formik>
        </Box>
      </ModalWindow>
    </>
  );
};

export default TerminateEmployeeModal;
