import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { AppStoreInterface } from 'store/app';
import { Actions as UserActions } from 'redux/reducers/user/actions';
import userDomain from 'api/domains/user';
import * as userRoutines from 'redux/sagas/user/routines';
import PasswordAskModal from './PasswordAskModal';

const POLLING_INTERVAL = 30000;
interface IProps {
  children: React.ReactNode;
  passwordAskModalVisible: boolean;
  isAuthenticated: boolean;
  impersonatingUserId: string | null;
  logout: () => void;
  loadCurrentUser: () => void;
  setShowPasswordAskModal: typeof UserActions.setShowPasswordAskModal;
}

const CheckActivity: React.FC<IProps> = (props) => {
  const {
    children,
    passwordAskModalVisible,
    isAuthenticated,
    impersonatingUserId,
    logout,
    loadCurrentUser,
    setShowPasswordAskModal,
  } = props;
  const [polling, setPolling] = useState<NodeJS.Timeout>();

  const timerFunction = async () => {
    try {
      await userDomain.checkActivity();
    } catch (e) {
      setShowPasswordAskModal(true);
      console.error(e);
    }
  };

  useEffect(() => {
    if (!polling) {
      const performPolling =
        isAuthenticated && !passwordAskModalVisible && !impersonatingUserId;
      if (performPolling) {
        setPolling(setInterval(timerFunction, POLLING_INTERVAL));
      }
    } else {
      if (!isAuthenticated || passwordAskModalVisible) {
        clearInterval(polling);
        setPolling(undefined);
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated, passwordAskModalVisible, polling, impersonatingUserId]);

  return (
    <>
      {children}
      {passwordAskModalVisible && (
        <PasswordAskModal
          isOpen={passwordAskModalVisible}
          loadCurrentUser={loadCurrentUser}
          setShowPasswordAskModal={setShowPasswordAskModal}
          logout={logout}
        />
      )}
    </>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  passwordAskModalVisible: store.user.passwordAskModalVisible,
  isAuthenticated: store.user.isAuthenticated,
  impersonatingUserId: store.user.impersonatingUserId,
});

const mapDispatchToProps = {
  logout: userRoutines.logout,
  setShowPasswordAskModal: UserActions.setShowPasswordAskModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckActivity);
