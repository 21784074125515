import React from 'react';
import SubmitButton, {
  SubmitButtonOwnProps,
} from 'components/Forms/SubmitButton/SubmitButtonComponent';
import ModalWindow, { ModalWindowPropsInterface } from './ModalWindowComponent';

export interface IConfirmationModalProps
  extends Omit<ModalWindowPropsInterface, 'actions'> {
  onSubmit: () => void | Promise<void>;
  confirmButtonProps?: Partial<Omit<SubmitButtonOwnProps, 'onClick'>>;
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = (props) => {
  const { onSubmit, confirmButtonProps, ...modalWindowProps } = props;

  const actions = (
    <SubmitButton
      onClick={onSubmit}
      label="Ok"
      variant="text"
      {...confirmButtonProps}
    />
  );

  return <ModalWindow actions={actions} {...modalWindowProps} />;
};

export default ConfirmationModal;
