import {
  IFsaContributionAmount,
  IFsaContributionAmountNumbered,
} from 'types/enrollments';
import { toFixed } from 'helpers/numberHelpers';

const contributionToFixed = (value?: number | null): string | undefined => {
  return value ? toFixed(value, 2) : undefined;
};

export const fromNumberedFSAContributionToFSAContribution = (
  data: IFsaContributionAmountNumbered
): IFsaContributionAmount => ({
  med: contributionToFixed(data.med),
  dep: contributionToFixed(data.dep),
  tra: contributionToFixed(data.tra),
  park: contributionToFixed(data.park),
});
