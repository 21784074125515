import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import { Typography, Box } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import PlanSummaryModal from 'components/PlanSummaryModal';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import enrollmentsDomain from 'api/domains/enrollments';
import {
  loadUserEnrollments,
  loadUserPlans,
} from 'redux/sagas/userRegistration/routines';
import { Actions as UserActions } from 'redux/reducers/user/actions';
import { AppStoreInterface } from 'store/app';
import ExternalRegistrationStep from 'enums/externalRegistrationStep';
import EnrollmentStatus from 'enums/enrollmentStatus';

import {
  IEnrollment,
  IMidyearChangeData,
  IUpdateEnrollmentPayload,
} from 'types/enrollments';
import { IFullPlan } from 'types/plans';
import PlanTypeNumeric from 'enums/planTypeNumeric';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import styles from './styles.module.scss';
import { CustomCell, CustomTable } from './styling';
import EnrollmentSettingsDialog from './EnrollmentSettingsDialog';
import EnrollmentsGroup from './EnrollmentsGroup';
import TotalContribution from './TotalContribution';

export interface IEnrollmentSettingsProps {
  plans: IFullPlan[];
  enrollments: IEnrollment[];
  enrollmentsIsLoading: boolean;
  plansIsLoading: boolean;
  enrollmentsEnabled: boolean | null;
  setStep: (data: IMidyearChangeData) => void;
  setError: (error: string) => void | Promise<void>;
  setIsLoading: (isLoading: boolean) => void | Promise<void>;
  actions: {
    loadUserEnrollments: typeof loadUserEnrollments.trigger;
    loadUserPlans: typeof loadUserPlans.trigger;
    editUserData: typeof UserActions.editUserData;
  };
  eventType?: string | null;
  dependentId: string | number | null;
  eventDate: string | null;
  reasonId: string | null | undefined;
}

export interface IEnrollmentSettingsState {
  showSettingsDialog: boolean;
  chosenGroupName: string;
  chosenPlans: IFullPlan[];
  chosenPlanId?: string | null;
  chosenEnrollment?: IEnrollment;
  planIdForInfo?: string;
  isAllPlansEnrolled?: boolean;
  isNextBtnClicked: boolean;
}

type GroupedPlans = {
  [key: string]: { plans: IFullPlan[]; groupName: string };
};

class StepSix extends React.Component<
  IEnrollmentSettingsProps,
  IEnrollmentSettingsState
> {
  constructor(props: IEnrollmentSettingsProps) {
    super(props);

    this.state = {
      showSettingsDialog: false,
      chosenGroupName: '',
      chosenPlans: [],
      isNextBtnClicked: false,
      chosenPlanId: '',
    };
  }

  async componentDidMount() {
    await this.fetchPlansAndEnrollments();
    this.props.setIsLoading(
      this.props.enrollmentsIsLoading || this.props.plansIsLoading
    );
    this.checkIsAllPlansEnrolled();
  }

  componentDidUpdate(prevProps: IEnrollmentSettingsProps) {
    if (
      this.props.enrollmentsIsLoading !== prevProps.enrollmentsIsLoading ||
      this.props.plansIsLoading !== prevProps.plansIsLoading
    ) {
      this.props.setIsLoading(
        this.props.enrollmentsIsLoading || this.props.plansIsLoading
      );
    }
    if (
      !isEqual(this.props.enrollments, prevProps.enrollments) ||
      !isEqual(this.props.plans, prevProps.plans)
    ) {
      this.checkIsAllPlansEnrolled();
    }
  }

  checkIsAllPlansEnrolled = () => {
    const groupedPlans = this.groupPlans();
    const planTypeIds = Object.keys(groupedPlans);
    for (const planTypeId of planTypeIds) {
      const isNonContributory = groupedPlans[planTypeId].plans.every(
        (plan) => plan.contributionType === '2'
      );

      const enrollment = this.props.enrollments.find(
        (enrollment) => enrollment.planTypeId === planTypeId
      );

      const notAvailable =
        enrollment?.restricted && Number(enrollment.restricted) > 0;
      const isSelectable = enrollment?.selectable;
      const isEnrolledOrDeclined =
        enrollment &&
        enrollment.status &&
        enrollment.status !== EnrollmentStatus.NotSelected;
      if (
        (!isNonContributory &&
          !isEnrolledOrDeclined &&
          !notAvailable &&
          isSelectable) ||
        (isSelectable && enrollment?.isNewOE)
      ) {
        this.setState({ isAllPlansEnrolled: false });
        return;
      }
    }
    this.setState({ isAllPlansEnrolled: true });
  };

  setShowSettingsDialog = (showSettingsDialog: boolean) =>
    this.setState({ showSettingsDialog });

  async apiAction(action: () => Promise<void>, errorMessage?: string) {
    const { setError, setIsLoading } = this.props;
    try {
      setIsLoading(true);
      await action();
    } catch (e) {
      console.error(e);
      setError(errorMessage || 'Server error');
    } finally {
      setIsLoading(false);
    }
  }

  addEnrollment = async (
    id: string,
    payload: IUpdateEnrollmentPayload
  ): Promise<IEnrollment> => {
    await enrollmentsDomain.update(id, payload);
    const { data } = await enrollmentsDomain.getOne(id);
    return data;
  };

  updateEnrollment = async (
    planTypeId: string,
    payload: IUpdateEnrollmentPayload
  ) => {
    const { loadUserEnrollments } = this.props.actions;
    const { eventDate, reasonId, eventType } = this.props;
    await this.apiAction(async () => {
      this.setShowSettingsDialog(false);
      await this.addEnrollment(planTypeId, {
        ...payload,
        eventDate,
        reasonId,
        eventType,
      });
      loadUserEnrollments();
    }, 'Cannot create enrollment');
  };

  fetchPlansAndEnrollments = async () => {
    const { actions } = this.props;
    await this.apiAction(async () => {
      await actions.loadUserEnrollments();
      await actions.loadUserPlans();
    }, "Cannot fetch user's enrollments");
  };

  haveNoPlansToEnroll = (): boolean => {
    const { plans, enrollmentsEnabled } = this.props;
    return !plans || !plans.length || !enrollmentsEnabled;
  };

  groupPlans = (): GroupedPlans => {
    const { plans, enrollments, eventType } = this.props;
    const planTypes: GroupedPlans = {};

    for (const enrollment of enrollments) {
      if (!planTypes[enrollment.planTypeId]) {
        const plansOfThisType = plans.filter(
          (plan) => plan.planTypeId === enrollment.planTypeId
        );
        const { planTypeId } = enrollment;
        if (
          +planTypeId === PlanTypeNumeric.Medical ||
          +planTypeId === PlanTypeNumeric.Dental ||
          +planTypeId === PlanTypeNumeric.Vision
        ) {
          if (
            plansOfThisType.length &&
            !(
              (eventType === '7' || eventType === '8' || eventType === '9') &&
              +enrollment.status < 0
            )
          ) {
            planTypes[enrollment.planTypeId] = {
              plans: plansOfThisType,
              groupName: plansOfThisType[0].planType,
            };
          }
        }
      }
    }

    return planTypes;
  };

  onInfoClick = (planId: string) => {
    this.setState({ planIdForInfo: planId });
  };

  onSummaryClose = () => {
    this.setState({ planIdForInfo: undefined });
  };

  onNextClick = () => {
    if (!this.state.isNextBtnClicked) {
      this.setState({ isNextBtnClicked: true });
    }
    if (!this.state.isAllPlansEnrolled) return;
    const { actions } = this.props;
    actions.editUserData({
      registrationStep: ExternalRegistrationStep.Enrollments.toString(),
    });
  };

  renderStepperButtons() {
    const buttons = <StepperButtons handleNext={this.onNextClick} />;
    if (this.haveNoPlansToEnroll()) {
      return <Box className={styles.centerAlignBlock}>{buttons}</Box>;
    }
    return buttons;
  }

  updateAndShowSettingsDialog = (
    groupName: string,
    plans: IFullPlan[],
    enrollment?: IEnrollment,
    chosenPlanId?: string | null
  ) => {
    this.setState(
      {
        chosenGroupName: groupName,
        chosenPlans: plans,
        chosenEnrollment: enrollment,
        chosenPlanId: chosenPlanId ?? undefined,
      },
      () => this.setShowSettingsDialog(true)
    );
  };

  renderList() {
    const { enrollments } = this.props;
    const groupedPlans = this.groupPlans();
    return (
      <Box className={styles.mainBox}>
        <TableContainer component={Box} className={styles.headerTable}>
          <CustomTable>
            <TableHead>
              <TableRow className={styles.headerRow}>
                <CustomCell align="left" className={styles.firstColumn}>
                  <Box className={styles.headerTitle}>Plan Name</Box>
                </CustomCell>
                <CustomCell align="left" className={styles.secondColumn}>
                  <Box className={styles.headerTitle}>Enrollment</Box>
                </CustomCell>
                <CustomCell align="left" className={styles.thirdColumn}>
                  <Box className={styles.headerTitle}>Contribution</Box>
                </CustomCell>
              </TableRow>
            </TableHead>
          </CustomTable>
        </TableContainer>
        {Object.keys(groupedPlans).map((planTypeId) => {
          const enrollment = enrollments.find(
            (enrollment) => enrollment.planTypeId === planTypeId
          );
          const { plans } = groupedPlans[planTypeId];
          return (
            <EnrollmentsGroup
              key={planTypeId}
              groupName={groupedPlans[planTypeId].groupName}
              enrollment={enrollment}
              plans={plans}
              onInfoClick={this.onInfoClick}
              onSelect={(id?: string) =>
                this.updateAndShowSettingsDialog(
                  groupedPlans[planTypeId].groupName,
                  plans,
                  enrollment,
                  id
                )
              }
            />
          );
        })}
        <TotalContribution enrollments={enrollments} />
      </Box>
    );
  }

  haveNoPlansMessage = () => {
    return (
      <Box className={styles.centerAlignBlock}>
        <Typography variant="h4">
          You are not eligible for any benefits
        </Typography>
        <Typography className={styles.subtitle} variant="subtitle1">
          Press "Next" button to continue registration process
        </Typography>
      </Box>
    );
  };

  makeSelectionForAllPlans = () => {
    const { isAllPlansEnrolled, isNextBtnClicked } = this.state;
    const { enrollmentsEnabled } = this.props;
    const showAlert =
      enrollmentsEnabled &&
      isNextBtnClicked &&
      isAllPlansEnrolled !== undefined &&
      !isAllPlansEnrolled;

    return showAlert ? (
      <Box mt="1em">
        <SimpleAlert type="error">
          Please make a selection for all plans
        </SimpleAlert>
      </Box>
    ) : null;
  };

  onNext = () => {
    this.props.setStep({ step: 7 });
  };

  render() {
    const { enrollmentsIsLoading, plansIsLoading, dependentId } = this.props;

    const {
      chosenGroupName,
      chosenPlans,
      chosenEnrollment,
      showSettingsDialog,
      chosenPlanId,
    } = this.state;

    return (
      <>
        {((enrollmentsIsLoading || plansIsLoading) &&
          this.haveNoPlansToEnroll()) ||
        showSettingsDialog ? null : (
          <>
            {this.haveNoPlansToEnroll()
              ? this.haveNoPlansMessage()
              : this.renderList()}
            {this.makeSelectionForAllPlans()}
          </>
        )}
        {showSettingsDialog && (
          <EnrollmentSettingsDialog
            eventType={this.props.eventType as string}
            open={showSettingsDialog}
            handleClose={() => this.setShowSettingsDialog(false)}
            title="Benefits Enrollment"
            groupName={chosenGroupName}
            plans={chosenPlans}
            enrollment={chosenEnrollment}
            updateEnrollment={this.updateEnrollment}
            chosenPlanId={chosenPlanId ?? undefined}
            dependentId={dependentId}
          />
        )}
        <PlanSummaryModal
          isOpen={!!this.state.planIdForInfo}
          handleClose={this.onSummaryClose}
          planId={this.state.planIdForInfo}
        />
        <SubmitButton label="Next" variant="contained" onClick={this.onNext} />
      </>
    );
  }
}

const mapStateToProps = (store: AppStoreInterface) => ({
  plans: store.userRegistration.plans,
  enrollments: store.userRegistration.enrollments,
  enrollmentsIsLoading: store.userRegistration.enrollmentsIsLoading,
  plansIsLoading: store.userRegistration.plansIsLoading,
  enrollmentsEnabled: store.user.enrollmentsEnabled,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      loadUserEnrollments: typeof loadUserEnrollments.trigger;
      loadUserPlans: typeof loadUserPlans.trigger;
      editUserData: typeof UserActions.editUserData;
    }
  >(
    {
      loadUserEnrollments: loadUserEnrollments.trigger,
      loadUserPlans: loadUserPlans.trigger,
      editUserData: UserActions.editUserData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepSix);
