import Axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios';

import Storage from 'helpers/storage';
import CaseStyle from 'enums/caseStyle';
import caseConverter from 'helpers/caseConverter';
import parseJwt from 'helpers/parseJwt';
import history from 'helpers/history';
import store from 'redux/store';
import { logout, login } from 'redux/sagas/user/routines';
import { LOGIN_PATH } from 'constants/routes';
import { SESSION_EXPIRED } from 'constants/feedbackMessages';
import { Actions as UserActions } from 'redux/reducers/user/actions';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  IMPERSONATE_TOKEN,
  LOCATION_BEFORE_SESSION_EXPIRATION,
} from 'constants/storeKeys';

export interface IRequestHeaders {
  [key: string]: string;
}

interface IApi {
  adapter: AxiosInstance;
  makeRequest: <T>(
    url: string,
    type: any,
    payload?: any,
    headers?: IRequestHeaders,
    queryParams?: any
  ) => AxiosPromise<T>;
}

class Api implements IApi {
  adapter: AxiosInstance;

  constructor() {
    this.adapter = Axios.create({
      baseURL: process.env.MBL_BASE_URL || `${window.location.origin}/mbl/api`
    });
    this.adapter.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.config.responseType !== 'blob') {
          response.data = caseConverter(response.data, CaseStyle.Camel);
        }
        return response;
      },
      async (error: AxiosError) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.messageCode === 40102 &&
          Storage.get(ACCESS_TOKEN)
        ) {
          Storage.remove(ACCESS_TOKEN);
          Storage.remove(REFRESH_TOKEN);
          store.dispatch({ type: logout.SUCCESS });
          /**
           * Redirect user to login page and show info message
           */
          history.push(LOGIN_PATH);
          store.dispatch(login.failure({ error: SESSION_EXPIRED }));
        }
        if (error.response && error.response.status === 408) {
          store.dispatch(UserActions.setShowPasswordAskModal(true));
          if (error.response.config.url === '/me') {
            Storage.set(
              LOCATION_BEFORE_SESSION_EXPIRATION,
              history.location.pathname
            );
          }
        }
        return Promise.reject(error);
      }
    );
    this.adapter.interceptors.request.use(
      (request: AxiosRequestConfig) => {
        return request;
      },
      async (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  }

  public makeRequest<T>(
    url: string,
    type: any,
    payload?: any,
    headers: IRequestHeaders = {},
    queryParams: any = {},
    config: Pick<AxiosRequestConfig, 'responseType'> = {}
  ): AxiosPromise<T> {
    let token: string | null;
    const accessToken = Storage.get(ACCESS_TOKEN);
    const impersonationToken = Storage.get(IMPERSONATE_TOKEN);
    if (impersonationToken) {
      const parsedToken = parseJwt(impersonationToken);
      const isTokenExpired = Date.now() >= parsedToken.exp * 1000;
      token = isTokenExpired ? accessToken : impersonationToken;
    } else {
      token = accessToken;
    }

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    switch (type) {
      case 'GET':
        return this.adapter.get<T>(url, {
          headers,
          params: queryParams,
          ...config,
        });
      case 'POST':
        return this.adapter.post<T>(url, payload, {
          headers,
          params: queryParams,
        });
      case 'PUT':
        return this.adapter.put<T>(url, payload, {
          headers,
          params: queryParams,
        });
      case 'PATCH':
        return this.adapter.patch<T>(url, payload, {
          headers,
          params: queryParams,
        });
      case 'DELETE':
        return this.adapter.delete(url, {
          headers,
          params: queryParams,
        });
      default:
        return this.adapter.get<T>(url, { headers, params: queryParams });
    }
  }
}

export default new Api();
