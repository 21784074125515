import { createRoutine } from 'redux-saga-routines';

export const genderOptions = createRoutine('GENDER_OPTIONS');
export const smokerOptions = createRoutine('SMOKER_OPTIONS');
export const securityQuestionOptions = createRoutine(
  'SECURITY_QUESTION_OPTIONS'
);
export const maritalStatusOptions = createRoutine('MARITAL_STATUS_OPTIONS');
export const relationshipTypeOptions = createRoutine(
  'RELATIONSHIP_TYPE_OPTIONS'
);
export const lastNameReasonOptions = createRoutine('LAST_NAME_REASON_OPTIONS');
export const accessGroupOptions = createRoutine('ACCESS_GROUP_OPTIONS');
export const employmentStatusOptions = createRoutine(
  'EMPLOYMENT_STATUS_OPTIONS'
);
export const officeLocationOptions = createRoutine('OFFICE_LOCATION_OPTIONS');
export const employmentTypeOptions = createRoutine('EMPLOYMENT_TYPE_OPTIONS');
export const qualifyingEventOptions = createRoutine('QUALIFYING_EVENT_OPTIONS');
export const logEventTypeOptions = createRoutine('LOG_EVENT_TYPE_OPTIONS');
export const planTypeOptions = createRoutine('PLAN_TYPE');
export const workEligibilityTypeOptions = createRoutine(
  'WORK_ELIGIBILITY_TYPE'
);
export const w4FilingStatusOptions = createRoutine('W4_FILING_STATUS');
export const payrollMethodOptions = createRoutine('PAYROLL_METHOD');
export const payrollAmountTypeOptions = createRoutine('PAYROLL_AMOUNT_TYPE');
export const payrollAccountTypeOptions = createRoutine('PAYROLL_ACCOUNT_TYPE');
export const eeoRaceOptionsOptions = createRoutine('EEO_RACE');
export const eeoJobCategoryOptions = createRoutine('EEO_JOB_CATEGORY');
export const eeoMilitaryStatusOptions = createRoutine('EEO_MILITARY_STATUS');
export const beneficiaryRelationshipTypeOptions = createRoutine(
  'BENEFICIARY_RELATIONSHIP_TYPE'
);

export const allOptions = createRoutine('ALL_OPTIONS');
