import React, { useState } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { ReactComponent as logIcon } from 'assets/log.svg';

import SectionTitle from 'components/SectionTitle';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import { IAction } from 'types/actions';
import { EMPTY_VALUE } from 'constants/placeholders';
import { getActionStatus } from './helpers';
import ActionFormsModal from './ActionFormsModal';

import styles from './styles.module.scss';

export interface IEmployeeActionsTableProps {
  employeeFullName?: string;
  actions: IAction[];
  onBack: () => void | Promise<void>;
  onOpenSignatureModal: (
    planId: string | null,
    onboardingId: string | null
  ) => void;
}

const EmployeeActionsTable: React.FC<IEmployeeActionsTableProps> = (props) => {
  const { employeeFullName, actions, onBack, onOpenSignatureModal } = props;

  const [actionToShowFiles, setActionToShowFiles] = useState<IAction>();

  const setActionToShowFilesById = (actionId: string) => {
    setActionToShowFiles(actions.find((action) => action.id === actionId));
  };

  return (
    <Box>
      <SectionTitle
        button={
          <SubmitButton
            className={styles.backBtn}
            label="Back"
            variant="text"
            onClick={onBack}
            iconPosition="left"
            icon={<ArrowBackIcon />}
          />
        }
      />
      <ContentContainer title={employeeFullName}>
        <Box className={styles.actionBox}>
          <TableContainer>
            <Table size="small" className={styles.employeeTable}>
              <TableHead>
                <TableRow>
                  <TableCell className={styles.action} />
                  <TableCell className={styles.status}>Status</TableCell>
                  <TableCell className={styles.description}>
                    Description
                  </TableCell>
                  <TableCell align="right" className={styles.forms}>
                    Forms
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actions.length > 0 ? (
                  <>
                    {actions.map((action) => (
                      <TableRow key={action.id}>
                        <TableCell>
                          <SubmitButton
                            label="Approve"
                            onClick={() =>
                              onOpenSignatureModal(
                                action.planTypeId,
                                action.onboardingId
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>{getActionStatus(action)}</TableCell>
                        <TableCell>{action.planName}</TableCell>
                        <TableCell align="right">
                          {action.files && action.files.length ? (
                            <IconButton
                              onClick={() =>
                                setActionToShowFilesById(action.id)
                              }
                              className={styles.btnFile}
                            >
                              <SvgIcon
                                component={logIcon}
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                              />
                            </IconButton>
                          ) : (
                            EMPTY_VALUE
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={4}
                      className={styles.placeholder}
                    >
                      No changes left for the selected employee
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ContentContainer>
      <ActionFormsModal
        title="Action's forms"
        action={actionToShowFiles}
        isOpen={!!actionToShowFiles}
        handleClose={() => setActionToShowFiles(undefined)}
      />
    </Box>
  );
};

export default EmployeeActionsTable;
