import React from 'react';
import setClassNames from 'classnames';
import { Button, ButtonProps } from '@material-ui/core';

export interface SubmitButtonOwnProps {
  label: string;
  onClick?: () => void | Promise<void>;
  disabled?: boolean;
  className?: string;
  icon?: any;
  iconPosition?: 'left' | 'right';
  colorTheme?: 'blue' | 'green';
  variant?: 'text' | 'outlined' | 'contained';
  type?: 'submit' | 'reset' | 'button';
}

export type SubmitButtonAllProps = SubmitButtonOwnProps &
  Pick<ButtonProps, 'color'>;

const SubmitButton: React.FC<SubmitButtonAllProps> = (props) => {
  const {
    label,
    disabled,
    onClick,
    className,
    children,
    icon,
    iconPosition,
    colorTheme,
    variant,
    color,
    type,
  } = props;

  const classNames = setClassNames('submit-button button', {
    [className || '']: Boolean(className),
    'submit-button-disabled': disabled,
    'submit-button-green': colorTheme === 'green',
    'submit-button-blue': colorTheme === 'blue' || !colorTheme,
  });

  const handleClick = () => {
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <Button
      color={color || 'primary'}
      type={type || 'submit'}
      disabled={disabled}
      className={classNames}
      onClick={handleClick}
      variant={variant || 'contained'}
      startIcon={icon && iconPosition === 'left' && icon}
      endIcon={icon && (iconPosition === 'right' || !iconPosition) && icon}
    >
      {children || <span className="button-label">{label}</span>}
    </Button>
  );
};
export default SubmitButton;
