import React from 'react';
import { Box } from '@material-ui/core';

import Backdrop from 'components/Backdrop';
import Feedbacker from 'components/Feedbacker';
import { PLANS_GROUP_FETCH_ERROR } from 'constants/feedbackMessages';

import { useQuery } from 'react-query';
import plansDomain from 'api/domains/plans';
import {
  Plan,
  IMedicalPlan,
  IVisionPlan,
  IDentalPlan,
  ILivePlan,
} from 'types/plans';
import ExternalPlanType from 'enums/externalPlanType';
import DisclaimerHOC from './DisclaimerHOC';
import LifePlanCompareTable from './LifePlanCompareTable';
import DentalPlanCompareTable from './DentalPlanCompareTable';
import VisionPlansCompareTable from './VisionPlanCompareTable';
import MedicalPlanCompareTable from './MedicalPlanCompareTable';
import OtherPlanCompareTable from './OtherPlanCompareTable';

import styles from './styles.module.scss';

export interface IComparePlansTableProps {
  planTypeId: ExternalPlanType | string;
  planId?: string | number;
  onChange?: (planId: string) => void;
}
const getCurrentPlan = async (_: string, id?: string) => {
  if (id) {
    const { data } = await plansDomain.getOne(id);
    return data;
  }
  return null;
};
const ComparePlansTable: React.FC<IComparePlansTableProps> = (props) => {
  const { planTypeId, planId, onChange } = props;

  const [plans, setPlans] = React.useState<Plan[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const { data: myPlan } = useQuery(
    ['currentPlanDetails', planId],
    getCurrentPlan,
    {
      enabled: planId,
      refetchOnWindowFocus: false,
    }
  );
  const clearError = () => setError(undefined);

  const setTempError = React.useCallback(() => {
    setError(PLANS_GROUP_FETCH_ERROR);
    setTimeout(clearError, 5000);
  }, []);
  /* eslint-disable */
  React.useEffect(() => {
    setIsLoading(true);
    plansDomain
      .getByType(planTypeId)
      .then(({ data }) =>
        myPlan ? setPlans([myPlan, ...data]) : setPlans(data)
      )
      .catch(() => setTempError())
      .finally(() => setIsLoading(false));
  }, [planTypeId, setTempError]);

  React.useEffect(() => {
    if (myPlan) setPlans([myPlan, ...plans]);
  }, [myPlan]);
  /* eslint-enable */
  const getCompareTable = () => {
    switch (planTypeId) {
      case ExternalPlanType.Medical:
        return (
          <MedicalPlanCompareTable
            plans={plans as IMedicalPlan[]}
            planId={planId}
            onChange={onChange}
          />
        );
      case ExternalPlanType.Vision:
      case ExternalPlanType.VoluntaryVision:
      case ExternalPlanType.WorksiteBenefitsVision:
        return (
          <VisionPlansCompareTable
            plans={plans as IVisionPlan[]}
            planId={planId}
            onChange={onChange}
          />
        );
      case ExternalPlanType.Dental:
      case ExternalPlanType.VoluntaryDental:
      case ExternalPlanType.WorksiteBenefitsDental:
        return (
          <DentalPlanCompareTable
            plans={plans as IDentalPlan[]}
            planId={planId}
            onChange={onChange}
          />
        );
      case ExternalPlanType.Live:
        return (
          <LifePlanCompareTable
            plans={plans as ILivePlan[]}
            planId={planId}
            onChange={onChange}
          />
        );
      default:
        return (
          <OtherPlanCompareTable
            plans={plans}
            planId={planId}
            onChange={onChange}
          />
        );
    }
  };

  return (
    <Box className={styles.main}>
      <DisclaimerHOC>{getCompareTable()}</DisclaimerHOC>
      <Backdrop open={isLoading} />
      <Feedbacker
        open={!!error}
        feedbackMessage={error || ''}
        clearFeedback={clearError}
        severity="error"
      />
    </Box>
  );
};

export default ComparePlansTable;
