import React from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  SvgIcon,
} from '@material-ui/core';

import { ReactComponent as addIcon } from 'assets/transactions/add.svg';
import { ReactComponent as deleteIcon } from 'assets/transactions/delete.svg';
import { ReactComponent as editIcon } from 'assets/transactions/edit.svg';

import ContentBox from 'components/ContentBox/ContentBoxComponent';
import PopoverComponent from 'components/Popper/PopoverComponent';
import { toCamel } from 'helpers/caseConverter';
import {
  ITransactionData,
  ITransactionLayout,
  ITransactionDependents,
} from 'types/transactions';

import styles from './styles.module.scss';

interface ITransactionTableProps {
  layoutData: ITransactionLayout[];
  newData: ITransactionData[];
  oldData: Record<string, ITransactionData>;
}

const TransactionTable: React.FC<ITransactionTableProps> = ({
  layoutData,
  newData,
  oldData,
}) => {
  layoutData = layoutData.map((l) => ({ ...l, field: toCamel(l.field) }));

  const getDependentsData = (
    data: Record<string, ITransactionDependents>
  ): ITransactionDependents[] => {
    const newData = [];
    for (let key in data) {
      newData.push(data[key]);
    }
    return newData;
  };
  const getData = (
    employee: ITransactionData,
    dependent: ITransactionDependents,
    repeat: boolean,
    field: string
  ): string =>
    !dependent[field as keyof ITransactionDependents] && repeat
      ? (employee[field as keyof ITransactionData] as string)
      : (dependent[field as keyof ITransactionDependents] as string);

  const checkDataDependent = (
    employee: ITransactionData,
    dependent: ITransactionDependents,
    repeat: boolean,
    field: string
  ) => {
    if (field === 'actionType' && dependent.actionType !== 0) return true;
    if (dependent.actionType !== 0) return false;
    if (!dependent[field as keyof ITransactionDependents] && repeat) {
      return (
        oldData[employee.censusId][field as keyof ITransactionData] !==
        employee[field as keyof ITransactionData]
      );
    } else {
      if (oldData[employee.censusId]?.dependents[dependent.individualId]) {
        return (
          oldData[employee.censusId]?.dependents[dependent.individualId][
            field as keyof ITransactionDependents
          ] !== dependent[field as keyof ITransactionDependents]
        );
      }
      return false;
    }
  };
  const checkDataEmployee = (employee: ITransactionData, field: string) => {
    if (field === 'actionType' && employee.actionType !== 0) return true;
    if (employee.actionType !== 0) return false;
    if (oldData[employee.censusId]) {
      return (
        oldData[employee.censusId][field as keyof ITransactionData] !==
        employee[field as keyof ITransactionData]
      );
    }
    return false;
  };

  const getOldDataDependent = (
    employee: ITransactionData,
    dependent: ITransactionDependents,
    repeat: boolean,
    field: string
  ) => {
    if (!dependent[field as keyof ITransactionDependents] && repeat) {
      return oldData[employee.censusId][field as keyof ITransactionData];
    } else {
      if (oldData[employee.censusId]?.dependents[dependent.individualId]) {
        return oldData[employee.censusId]?.dependents[dependent.individualId][
          field as keyof ITransactionDependents
        ];
      }
      return false;
    }
  };
  const getOldDataEmployee = (employee: ITransactionData, field: string) => {
    if (oldData[employee.censusId]) {
      return oldData[employee.censusId][field as keyof ITransactionData];
    }
  };

  const messageForDependent = (
    employee: ITransactionData,
    dependent: ITransactionDependents,
    repeat: boolean,
    field: string,
    custom: string[]
  ) => {
    const oldData = custom
      ? custom[Number(getOldDataDependent(employee, dependent, repeat, field))]
      : getOldDataDependent(employee, dependent, repeat, field);
    const newData = custom
      ? custom[Number(getData(employee, dependent, repeat, field))]
      : getData(employee, dependent, repeat, field);
    return (
      <ContentBox className={styles.popover}>
        <p>Old value: {oldData}</p>
        <p>New value: {newData}</p>
      </ContentBox>
    );
  };

  const messageForEmployee = (
    employee: ITransactionData,
    field: string,
    custom: string[]
  ) => {
    const oldData = custom
      ? custom[Number(getOldDataEmployee(employee, field))]
      : getOldDataEmployee(employee, field);
    const newData = custom
      ? custom[employee[field as keyof ITransactionData] as number]
      : employee[field as keyof ITransactionData];
    return (
      <Box className={styles.popover}>
        <p>Old value: {oldData}</p>
        <p>New value: {newData}</p>
      </Box>
    );
  };
  const getValue = (
    data: string | number,
    field: string,
    custom: string[]
  ): string | React.ReactNode => {
    if (field === 'actionType') {
      switch (custom[Number(data)]) {
        case 'Add':
          return (
            <Box className={styles.boxIcon}>
              <SvgIcon
                component={addIcon}
                className={styles.addIcon}
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              />
            </Box>
          );
        case 'Remove':
          return (
            <Box className={styles.boxIcon}>
              <SvgIcon
                component={deleteIcon}
                className={styles.deleteIcon}
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
              />
            </Box>
          );
        default:
          return (
            <Box className={styles.boxIcon}>
              <SvgIcon
                component={editIcon}
                className={styles.editIcon}
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
              />
            </Box>
          );
      }
    }
    return custom ? custom[Number(data)] : (data as string);
  };

  return (
    <Box className={styles.mainContainer}>
      <Box className={styles.dashboardTableContainer}>
        <TableContainer className={styles.wrapperTable} component={Box}>
          <Table className={styles.transactionTable} size="small">
            <TableHead>
              <TableRow>
                {layoutData.map((v) => {
                  return <TableCell>{v.title}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            {newData.map((data, i) => (
              <TableBody>
                <TableRow
                  className={
                    i % 2 === 0 ? styles.firstColor : styles.secondColor
                  }
                >
                  {layoutData.map((v) => {
                    return checkDataEmployee(data, v.field) &&
                      v.field !== 'actionType' ? (
                      <TableCell
                        className={
                          checkDataEmployee(data, v.field)
                            ? styles.editCell
                            : styles.cell
                        }
                      >
                        <PopoverComponent
                          popoverMessage={messageForEmployee(
                            data,
                            v.field,
                            v.custom
                          )}
                        >
                          {getValue(
                            data[v.field as keyof ITransactionData] as string,
                            v.field,
                            v.custom
                          )}
                        </PopoverComponent>
                      </TableCell>
                    ) : (
                      <TableCell
                        className={
                          checkDataEmployee(data, v.field)
                            ? styles.editCell
                            : styles.cell
                        }
                      >
                        {getValue(
                          data[v.field as keyof ITransactionData] as string,
                          v.field,
                          v.custom
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {getDependentsData(data.dependents).map((d) => (
                  <TableRow
                    className={
                      i % 2 === 0 ? styles.firstColor : styles.secondColor
                    }
                  >
                    {layoutData.map((v) => {
                      return checkDataDependent(data, d, v.repeat, v.field) &&
                        v.field !== 'actionType' ? (
                        <TableCell
                          className={
                            checkDataDependent(data, d, v.repeat, v.field)
                              ? styles.editCell
                              : styles.cell
                          }
                        >
                          <PopoverComponent
                            popoverMessage={messageForDependent(
                              data,
                              d,
                              v.repeat,
                              v.field,
                              v.custom
                            )}
                          >
                            {getValue(
                              getData(data, d, v.repeat, v.field),
                              v.field,
                              v.custom
                            )}
                          </PopoverComponent>
                        </TableCell>
                      ) : (
                        <TableCell
                          className={
                            checkDataDependent(data, d, v.repeat, v.field)
                              ? styles.editCell
                              : styles.cell
                          }
                        >
                          {getValue(
                            getData(data, d, v.repeat, v.field),
                            v.field,
                            v.custom
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TransactionTable;
