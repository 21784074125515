/* eslint-disable */
import {
  initOnboardingStore,
  OnboardingStoreInterface,
} from 'store/onboarding';
import { fetchCustomData } from 'redux/sagas/onboarding/routines';
import { SET_LOADING } from './actionTypes';

const onboarding = (
  state = initOnboardingStore(),
  action: any
): OnboardingStoreInterface => {
  switch (action.type) {
    case fetchCustomData.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case fetchCustomData.SUCCESS:
      return {
        ...state,
        custom: Object.assign({ ...state.custom }, action.payload),
      };
    case fetchCustomData.FULFILL:
      return {
        ...state,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default onboarding;
