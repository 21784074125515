import moment from 'moment';
import {
  DependentInterfaceWithoutId,
  ICreateDependent,
  DependentInterface,
} from 'types/dependents';

export const fromDependentToCreateDependent = (
  dependent: DependentInterfaceWithoutId | DependentInterface
): ICreateDependent => {
  return {
    relationshipTypeId: dependent.relationshipTypeId?.toString() || '1',
    firstName: dependent.firstName,
    lastName: dependent.lastName,
    middleName: dependent.middleName,
    birthDate: moment(dependent.birthDate).format('MM/DD/YYYY'),
    gender: dependent.gender?.toString() || '',
    smoker: dependent.smoker?.toString() || '',
    disabled: dependent.disabled ? Number(dependent.disabled)?.toString() : '0',
    ssn: dependent.ssn,
    email: dependent.email,
    address1: dependent.address1,
    address2: dependent.address2,
    city: dependent.city,
    state: dependent.state,
    zip: dependent.zip,
    lastNameReasonId: dependent.lastNameReasonId?.toString() || undefined,
    dependentDifferentAddress: dependent.dependentDifferentAddress,
  };
};
