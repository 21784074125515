import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import SignaturePad from 'react-signature-canvas';

import PlanTypeNumeric from 'enums/planTypeNumeric';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import Feedbacker from 'components/Feedbacker';
import Skeleton from 'components/Skeleton';
import SectionTitle from 'components/SectionTitle';
import Backdrop from 'components/Backdrop';
import Selector from 'components/Forms/Selector/SelectorComponent';
import IBasicOption from 'models/IBasicOption';
import { IAction, IActionPayload } from 'types/actions';
import actionCenterDomain from 'api/domains/actionCenter';
import ActionTable from './ActionTable';
import SignatureModal from '../../components/Signature/SignatureModal';
import EmployeeActionsTable from './EmployeeActionsTable';

import styles from './styles.module.scss';

type ActionToOptionMapper = (action: IAction) => [string | null, string | null];

function actionsToFilterOptions(
  actions: IAction[],
  mapper: ActionToOptionMapper
): IBasicOption<string>[] {
  const optionsMap: Map<string, string> = new Map();
  actions.forEach((action) => {
    const [value, label] = mapper(action);
    if (value && label && !optionsMap.get(value)) {
      optionsMap.set(value, label);
    }
  });

  const options: IBasicOption<string>[] = [];
  optionsMap.forEach((label, value) => {
    options.push({ value, label });
  });
  return options;
}

function actionsToEmployeeFilterOptions(
  actions: IAction[]
): IBasicOption<string>[] {
  return actionsToFilterOptions(actions, (action) => [
    action.employeeId,
    `${action.firstName} ${action.lastName}`,
  ]);
}

function actionsToPlanTypeFilterOptions(
  actions: IAction[]
): IBasicOption<string>[] {
  return actionsToFilterOptions(actions, (action) => [
    action.planTypeId,
    action.planType,
  ]);
}

function filterActions(
  actions: IAction[],
  employeeId?: string,
  planTypeId?: string
) {
  if (!employeeId && !planTypeId)
    return actions.filter(
      (action) => Number(action.planTypeId) !== PlanTypeNumeric.ADD
    );
  const filteredByEmployee = employeeId
    ? actions.filter(
        (action) =>
          action.employeeId === employeeId &&
          Number(action.planTypeId) !== PlanTypeNumeric.ADD
      )
    : actions;
  return planTypeId
    ? filteredByEmployee.filter((action) =>
        +planTypeId === PlanTypeNumeric.ADD
          ? Number(action.planTypeId) === PlanTypeNumeric.Live
          : action.planTypeId === planTypeId
      )
    : filteredByEmployee;
}

const ActionCenter: React.FC = () => {
  const [actions, setActions] = useState<IAction[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [employeeFilterOptions, setEmployeeFilterOptions] = useState<
    IBasicOption<string>[]
  >([]);
  const [planTypeFilterOptions, setPlanTypeFilterOptions] = useState<
    IBasicOption<string>[]
  >([]);

  const [employeeFilter, setEmployeeFilter] = useState<IBasicOption<
    string
  > | null>(null);
  const [planTypeFilter, setPlanTypeFilter] = useState<IBasicOption<
    string
  > | null>(null);
  const [chosenEmployeeId, setChosenEmployeeId] = useState<string>();
  const [chosenPlanTypeId, setChosenPlanTypeId] = useState<string | null>(null);
  const [chosenOnboardingId, setChosenOnboardingId] = useState<string | null>(
    null
  );
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const [signatureStored, setSignatureStored] = useState(false);
  const [signing, setSigning] = useState(false);
  const sigCanvas = useRef<SignaturePad>(null);

  const fetchActions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await actionCenterDomain.getList();
      setActions(data);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  }, [setActions]);

  useEffect(() => {
    fetchActions();
  }, [fetchActions]);

  useEffect(() => {
    setEmployeeFilterOptions(actionsToEmployeeFilterOptions(actions));
    setPlanTypeFilterOptions(actionsToPlanTypeFilterOptions(actions));
  }, [actions]);

  const onEmployeeFilterChange = (newEmployeeFilter?: IBasicOption<string>) => {
    setEmployeeFilter(newEmployeeFilter || null);
  };

  const onPlanTypeFilterChange = (newPlanTypeFilter?: IBasicOption<string>) => {
    setPlanTypeFilter(newPlanTypeFilter || null);
  };

  const onClearClickHandler = () => {
    setEmployeeFilter(null);
    setPlanTypeFilter(null);
  };

  const handleOpenSignatureModal = (
    planId: string | null,
    onboardingId: string | null
  ) => {
    setSignatureModalOpen(true);
    setChosenPlanTypeId(planId);
    setChosenOnboardingId(onboardingId);
  };

  const handleCloseSignatureModal = () => {
    setSignatureModalOpen(false);
    setSignatureStored(false);
    setChosenPlanTypeId(null);
  };

  const handleApprove = async (canvas: HTMLCanvasElement) => {
    const base64Signature = canvas.toDataURL();
    try {
      setSigning(true);
      const isChosenEmployeesPlan = !!chosenEmployeeId && !!chosenPlanTypeId;
      const payload: Partial<IActionPayload> = {
        employeeId: isChosenEmployeesPlan
          ? chosenEmployeeId
          : chosenOnboardingId
          ? chosenEmployeeId
          : employeeFilter?.value,
        planTypeId: isChosenEmployeesPlan
          ? chosenPlanTypeId
          : planTypeFilter?.value,
        onboardingId: chosenOnboardingId,
        signature: base64Signature,
      };

      await actionCenterDomain.approve(payload);
      if (payload.planTypeId == PlanTypeNumeric.Live) {
        await actionCenterDomain.approve({
          ...payload,
          planTypeId: PlanTypeNumeric.ADD,
        });
      }
      await fetchActions();
    } catch (e) {
      setError('Error approving plan(s)');
      console.error(e);
    } finally {
      onClearClickHandler();
      handleCloseSignatureModal();
      setSigning(false);
    }
  };

  const handleSign = async () => {
    if (sigCanvas.current) {
      const canvas = sigCanvas.current.getTrimmedCanvas();
      await handleApprove(canvas);
    }
  };

  const chosenEmployeeAction = useMemo(
    () => actions.find((action) => action.employeeId === chosenEmployeeId),
    [chosenEmployeeId, actions]
  );

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Action Center</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        <Backdrop open={signing}>
          <Typography variant="h4">
            We are signing all necessary documents. Please wait. This might take
            a while.
          </Typography>
        </Backdrop>
        {!chosenEmployeeId && <SectionTitle title="Action Center" />}
        <Box className={styles.mainContainer}>
          {loading ? (
            <Skeleton variant="rect" height="3em" />
          ) : (
            <>
              {chosenEmployeeId ? (
                <EmployeeActionsTable
                  actions={filterActions(actions, chosenEmployeeId)}
                  employeeFullName={
                    chosenEmployeeAction &&
                    `${chosenEmployeeAction.firstName} ${chosenEmployeeAction.lastName}`
                  }
                  onOpenSignatureModal={(
                    planId: string | null,
                    onboardingId: string | null
                  ) => handleOpenSignatureModal(planId, onboardingId)}
                  onBack={() => setChosenEmployeeId(undefined)}
                />
              ) : (
                <Box>
                  <Box>
                    <Box className={styles.filterPanel}>
                      <Box className={styles.filterBox}>
                        <Selector
                          options={employeeFilterOptions}
                          value={employeeFilter}
                          onChange={onEmployeeFilterChange}
                          placeholder="Employee(s) Select"
                          className={styles.filterSelector}
                          defaultValueLabel="All"
                        />
                      </Box>
                      <Box className={styles.filterBox}>
                        <Selector
                          options={planTypeFilterOptions}
                          value={planTypeFilter}
                          onChange={onPlanTypeFilterChange}
                          placeholder="Plan Type Select"
                          className={styles.filterSelector}
                          defaultValueLabel="All"
                        />
                      </Box>
                      <Box className={styles.filterButtons}>
                        <Button
                          className={styles.filterClearBtn}
                          variant="outlined"
                          color="primary"
                          onClick={onClearClickHandler}
                        >
                          Clear filters
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles.mainPanel}>
                    <ContentContainer
                      title="Enrollments & Changes"
                      button={
                        <Button
                          className={styles.btnHeader}
                          color="secondary"
                          variant="contained"
                          onClick={() => setSignatureModalOpen(true)}
                        >
                          Approve
                        </Button>
                      }
                    >
                      <Box className={styles.actionBox}>
                        <ActionTable
                          actions={filterActions(
                            actions,
                            employeeFilter?.value,
                            planTypeFilter?.value
                          )}
                          onChooseEmployee={(id: string) =>
                            setChosenEmployeeId(id)
                          }
                        />
                      </Box>
                    </ContentContainer>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
        <SignatureModal
          open={signatureModalOpen}
          signing={signing}
          handleClose={handleCloseSignatureModal}
          handleSign={handleSign}
          sigCanvas={sigCanvas}
          setSignatureStored={setSignatureStored}
          signatureStored={signatureStored}
        />
        <Feedbacker
          open={!!error}
          autoHideDuration={5000}
          displayInTheMiddle
          clearFeedback={() => setError(null)}
          severity="error"
          feedbackMessage={error as string}
        />
      </Box>
    </>
  );
};

export default ActionCenter;
