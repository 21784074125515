import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import { withStyles, SvgIcon } from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/radioOn.svg';
import { ReactComponent as offIcon } from 'assets/radioOff.svg';
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import * as yup from 'yup';
import Feedbacker, { FeedbackerSeverity } from 'components/Feedbacker';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import { IContact, ISubmitContactForm } from 'types/contacts';
import ContactType from 'enums/contactType';
import contactsDomain from 'api/domains/contacts';

import styles from './styles.module.scss';

interface IContactSubmitFormProps {
  contacts: IContact[];
  contactType: ContactType;
  setIsLoading?: (isLoading: boolean) => void;
}

type IFormValues = Pick<ISubmitContactForm, 'contactId' | 'message'>;

const validationSchema = yup.object().shape({
  contactId: yup.string().required('Field is required'),
  message: yup.string().required('Field is required'),
});
const CustomRadio = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Radio);

const CustomField = withStyles({
  root: {
    '& ::placeholder': {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#9FA2B4',
      opacity: 0.8,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4979C0',
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      '& fieldset': {
        borderColor: '#E1E2E7',
      },
      '&:hover fieldset': {
        borderColor: '#4979C0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #4979C0',
      },
    },
  },
})(Field);
const ContactSubmitForm: React.FC<IContactSubmitFormProps> = ({
  contacts,
  contactType,
  setIsLoading,
}) => {
  const initialValues: IFormValues = {
    contactId: '',
    message: '',
  };

  const [feedback, setFeedback] = React.useState<string>();
  const [severity, setSeverity] = React.useState<FeedbackerSeverity>();

  const clearFeedback = () => {
    setFeedback(undefined);
    setSeverity(undefined);
  };

  const setFeedbackWithDeley = (
    message: string,
    messageSeverity: FeedbackerSeverity
  ) => {
    setFeedback(message);
    setSeverity(messageSeverity);
    setTimeout(clearFeedback, 5000);
  };

  const setSuccessFeedback = () => {
    setFeedbackWithDeley('Your message has been sent!', 'success');
  };

  const setErrorFeedback = (error: Error | string) => {
    console.error(error);
    setFeedbackWithDeley('Error, when trying to send your message', 'error');
  };

  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    if (setIsLoading) setIsLoading(true);
    const submitFormData: ISubmitContactForm = {
      ...values,
      contactType,
    };

    const onSuccess = () => {
      formikHelpers.resetForm();
      setSuccessFeedback();
    };

    contactsDomain
      .submitForm(submitFormData)
      .then(onSuccess)
      .catch(setErrorFeedback)
      .finally(() => setIsLoading && setIsLoading(false));
  };

  return (
    <Box className={styles.contentWrapper}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik: FormikProps<IFormValues>) => (
          <Form className={styles.form}>
            <Field component={RadioGroup} name="contactId">
              <Box className={styles.radioGroup}>
                {contacts.map((contact) => (
                  <Box key={contact.contactId}>
                    <FormControlLabel
                      value={contact.contactId}
                      control={
                        <CustomRadio
                          icon={
                            <SvgIcon
                              className={styles.icon}
                              component={offIcon}
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                            />
                          }
                          checkedIcon={
                            <SvgIcon
                              className={styles.icon}
                              component={onIcon}
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                            />
                          }
                          disabled={formik.isSubmitting}
                        />
                      }
                      label={
                        <Typography
                          className={styles.label}
                        >{`${contact.firstName} ${contact.lastName}`}</Typography>
                      }
                      disabled={formik.isSubmitting}
                    />
                    <Box className={styles.radioInfo}>
                      <Box className={styles.infoRow}>
                        <Typography variant="caption" className={styles.name}>
                          Phone:{' '}
                        </Typography>
                        <Typography className={styles.phone}>
                          {' '}
                          {contact.phone}
                        </Typography>
                      </Box>
                      <Box className={styles.infoRow}>
                        <Typography variant="caption" className={styles.name}>
                          Email:
                        </Typography>
                        <Typography className={styles.email}>
                          {' '}
                          {contact.email}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              <FormHelperText className={styles.radioError}>
                {formik.errors.contactId}
              </FormHelperText>
            </Field>
            <CustomField
              component={TextField}
              multiline
              rows={4}
              variant="outlined"
              name="message"
              type="text"
              placeholder="Please enter your question or comments"
              className={styles.comment}
            />
            <SubmitButton
              className={styles.submitBtn}
              disabled={formik.isSubmitting}
              label="Send"
            />
          </Form>
        )}
      </Formik>
      <Feedbacker
        open={!!feedback && !!severity}
        severity={severity}
        feedbackMessage={feedback || ''}
        clearFeedback={clearFeedback}
      />
    </Box>
  );
};

export default ContactSubmitForm;
