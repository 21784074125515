import { FormikProps } from 'formik';

export function getErrorMessage<T>(
  key: keyof T,
  formik: FormikProps<T>
): string | undefined {
  const { errors } = formik;
  const error = errors[key];
  //  const isTouched = touched[key];
  return error ? (error as string) : '';
}
