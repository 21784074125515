import React from 'react';

interface IProps {
  unit?: '$' | '€';
  fixed?: number;
}

const Currency: React.FC<IProps> = ({ children, unit = '$', fixed }) => {
  if (
    (typeof children !== 'number' && typeof children !== 'string') ||
    children === ''
  )
    return null;
  let result = '';
  if (unit) result += unit;

  if (fixed) {
    result += Number(children).toFixed(fixed);
  } else {
    result += parseFloat(children.toString()).toLocaleString();
  }
  return <>{result}</>;
};

export default Currency;
