import React from 'react';
import { Box, Link, SvgIcon } from '@material-ui/core';
import { ReactComponent as fileIcon } from 'assets/file.svg';

import Backdrop from 'components/Backdrop';
import Feedbacker from 'components/Feedbacker';
import { DOWNLOAD_ERROR } from 'constants/feedbackMessages';
import fileDomain from 'api/domains/file';
import styles from './styles.module.scss';

export interface IFileLinkProps {
  fileId: string;
  linkLabel: string;
  isIssue?: boolean;
}

const FileLink: React.FC<IFileLinkProps> = (props) => {
  const { fileId, linkLabel, isIssue = false } = props;

  const [isFileLoading, setFileLoading] = React.useState(false);
  const [downloadError, setDownloadError] = React.useState<string>();

  const clearError = () => setDownloadError(undefined);

  const setTempDownloadError = () => {
    setDownloadError(DOWNLOAD_ERROR);
    setTimeout(clearError, 5000);
  };

  const handleOpenFile = () => {
    setFileLoading(true);
    fileDomain
      .downloadFile(fileId)
      .then(({ data }) => {
        window.open(URL.createObjectURL(data));
      })
      .catch(() => setTempDownloadError())
      .finally(() => setFileLoading(false));
  };

  return (
    <Box className={styles.fileLinkContainer}>
      <Backdrop open={isFileLoading} />
      <Feedbacker
        severity="error"
        open={!!downloadError}
        feedbackMessage={downloadError || ''}
        clearFeedback={clearError}
      />
      {isIssue ? (
        <Link className={styles.fileLinkIssue} onClick={handleOpenFile}>
          {linkLabel}
        </Link>
      ) : (
        <>
          <SvgIcon
            component={fileIcon}
            width="19"
            height="21"
            viewBox="0 0 19 21"
          />
          <Link className={styles.fileLink} onClick={handleOpenFile}>
            {linkLabel}
          </Link>
        </>
      )}
    </Box>
  );
};

export default FileLink;
