import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme, createStyles } from '@material-ui/core/styles';

const drawerWidth = 255;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      position: 'relative',
    },
    drawerPaper: {
      backgroundColor: '#ffff',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(8) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      minHeight: '64px',
      Height: '64px',
    },
    mainList: {
      paddingTop: '0',
      overflowX: 'hidden',
      overflowY: 'auto',
      paddingBottom: '64px',
      paddignRight: '10px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    collapseIconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    collapseIconContainerOpen: {
      position: 'absolute',
      bottom: 'calc(1% + 60px)',
      right: '0',
    },
    collapseIconContainerClose: {
      position: 'absolute',
      bottom: 'calc(1% + 60px)',
      right: '0',
    },
    collapseIcon: {
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
      },
    },
    item: {
      padding: ' 10px 20px 10px 20px',
      '&:hover': {
        background: '#dce4f1',
      },
    },
    helpIcons: {
      width: '12px',
      height: '7px',
      marginRight: '5px',
    },
    selected: {
      '&.Mui-selected': {
        background: '#dce4f1',
        padding: ' 10px 20px 10px 16px',
        borderLeft: 'solid 4px #4979C0',
        fontWeight: 700,
        '&:hover, &:focus': {
          background: '#dce4f1',
        },
      },
    },
    nested: {
      padding: '10px 20px 10px 55px',
      '&:hover, &:focus': {
        background: '#dce4f1',
      },
    },
    nestedSelect: {
      '&.Mui-selected': {
        padding: '10px 20px 10px 51px',
        background: '#dce4f1',
        borderLeft: 'solid 4px #4979C0',
        fontWeight: 700,
        '&:hover': {
          background: '#dce4f1',
        },
      },
    },
    drawerItemText: {
      lineHeight: '20px',
      fontWeight: 400,
      fontSize: '16px',
      letterSpacing: '0.2px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '.9rem',
      },
    },
    textSelected: {
      lineHeight: '20px',
      fontWeight: 700,
      fontSize: '16px',
      letterSpacing: '0.2px',
      color: '#252733',
      [theme.breakpoints.down('sm')]: {
        fontSize: '.9rem',
      },
    },
    drawerItemIcon: {
      color: '#4979C0',
      display: 'flex',
      alignSelf: 'flex-end',
      minWidth: '24px',
      width: '25px',
      height: '25px',
      marginRight: '10px',
    },
    openIcons: {
      color: '#4979C0',
      display: 'flex',
      alignSelf: 'flex-end',
      minWidth: '24px',
      width: '25px',
      height: '25px',
      marginLeft: '-20px',
    },
  })
);

export default useStyles;
