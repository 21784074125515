import {
  IEnrollment,
  IMidyearChangeData,
  IUpdateEnrollmentPayload,
  Task,
} from 'types/enrollments';
import api from '../adapter';

export class EnrollmentsDomain {
  getList() {
    return api.makeRequest<IEnrollment[]>('/enrollments', 'GET');
  }

  getOne(id: string | number) {
    return api.makeRequest<IEnrollment>(`/enrollments/${id}`, 'GET');
  }

  update(id: string | number, payload: IUpdateEnrollmentPayload) {
    return api.makeRequest<IEnrollment>(`/enrollments/${id}`, 'PUT', payload);
  }

  getEnrollmentsIssues() {
    return api.makeRequest<Task[]>('tasks', 'GET');
  }

  getEnrollmentsIssuesByEmployeeId(id: string) {
    return api.makeRequest<Task[]>(`employees/${id}/tasks`, 'GET');
  }

  updateMidyearData(payload: IMidyearChangeData) {
    return api.makeRequest<IMidyearChangeData>(
      `/midyear-changes`,
      'PUT',
      payload
    );
  }
}

export default new EnrollmentsDomain();
