import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as dentalIcon } from 'assets/planIcons/dental.svg';
import { ReactComponent as lifeIcon } from 'assets/planIcons/life.svg';
import { ReactComponent as ltdIcon } from 'assets/planIcons/ltd.svg';
import { ReactComponent as fsaIcon } from 'assets/planIcons/fsa.svg';
import { ReactComponent as medicalIcon } from 'assets/planIcons/medical.svg';
import { ReactComponent as defaultIcon } from 'assets/planIcons/default.svg';
import { ReactComponent as hraIcon } from 'assets/planIcons/hra.svg';
import { ReactComponent as hsaIcon } from 'assets/planIcons/hsa.svg';
import { ReactComponent as stdIcon } from 'assets/planIcons/std.svg';
import { ReactComponent as visionIcon } from 'assets/planIcons/vision.svg';
import { ReactComponent as voluntaryLifeIcon } from 'assets/planIcons/voluntaryLife.svg';
import { ReactComponent as voluntaryVisionIcon } from 'assets/planIcons/voluntaryVision.svg';
import { ReactComponent as voluntaryDentalIcon } from 'assets/planIcons/voluntaryDental.svg';
import { ReactComponent as voluntaryLTDIcon } from 'assets/planIcons/voluntaryLTD.svg';
import { ReactComponent as voluntarySTDIcon } from 'assets/planIcons/voluntarySTD.svg';
import { ReactComponent as pensionIcon } from 'assets/planIcons/pension.svg';
import { ReactComponent as s125Icon } from 'assets/planIcons/125.svg';
import { ReactComponent as eapIcon } from 'assets/planIcons/eap.svg';
import { ReactComponent as ltcIcon } from 'assets/planIcons/ltc.svg';

import PlanTypeNumeric from 'enums/planTypeNumeric';
import styles from './styles.module.scss';

interface IProps {
  planTypeId: number;
}
const PlanIcons: React.FC<IProps> = (props) => {
  switch (props.planTypeId) {
    case PlanTypeNumeric.Pediatric:
    case PlanTypeNumeric.Dental:
      return (
        <SvgIcon
          component={dentalIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.Medical:
      return (
        <SvgIcon
          component={medicalIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.Live:
      return (
        <SvgIcon
          component={lifeIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.Vision:
      return (
        <SvgIcon
          component={visionIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.VoluntaryLife:
      return (
        <SvgIcon
          component={voluntaryLifeIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.VoluntaryLTD:
      return (
        <SvgIcon
          component={voluntaryLTDIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.VoluntarySTD:
      return (
        <SvgIcon
          component={voluntarySTDIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.VoluntaryDental:
      return (
        <SvgIcon
          component={voluntaryDentalIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.VoluntaryVision:
      return (
        <SvgIcon
          component={voluntaryVisionIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.FSA:
      return (
        <SvgIcon
          component={fsaIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.HRA:
      return (
        <SvgIcon
          component={hraIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.HSA:
      return (
        <SvgIcon
          component={hsaIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.LTD:
      return (
        <SvgIcon
          component={ltdIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.STD:
      return (
        <SvgIcon
          component={stdIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.Pension:
      return (
        <SvgIcon
          component={pensionIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.EAP:
      return (
        <SvgIcon
          component={eapIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.LTC:
      return (
        <SvgIcon
          component={ltcIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    case PlanTypeNumeric.S125:
      return (
        <SvgIcon
          component={s125Icon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
    default:
      return (
        <SvgIcon
          component={defaultIcon}
          className={styles.planIcon}
          width="43"
          height="43"
          viewBox="0 0 43 43"
        />
      );
  }
};

export default PlanIcons;
