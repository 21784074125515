import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import {
  FormControlLabel,
  Checkbox,
  withStyles,
  SvgIcon,
} from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/checkOn.svg';
import { ReactComponent as offIcon } from 'assets/checkOff.svg';

interface IProps {
  checked?: boolean;
  onChange: (_: any, checked: boolean) => void;
  label: string;
  error?: boolean;
  helperText?: string;
}
const CustomFormControlLabel = withStyles({
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
})(FormControlLabel);

const CustomCheckbox = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);

const CheckboxField: React.FC<IProps> = ({
  checked,
  label,
  onChange,
  helperText,
  error,
}) => {
  return (
    <FormControl required error={error} component="fieldset">
      <CustomFormControlLabel
        control={
          <CustomCheckbox
            icon={
              <SvgIcon
                component={offIcon}
                width="16"
                height="17"
                viewBox="0 0 16 17"
              />
            }
            checkedIcon={
              <SvgIcon
                component={onIcon}
                width="16"
                height="17"
                viewBox="0 0 16 17"
              />
            }
            checked={checked}
            onChange={onChange}
            color="primary"
          />
        }
        label={label}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxField;
