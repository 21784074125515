import React, { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';

import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import Input from 'components/Forms/Input/InputComponent';
import { IEmployeeBasicData } from 'types/employee';
import { ReactComponent as openIcon } from 'assets/openWhite.svg';
import { ReactComponent as closeIcon } from 'assets/closeWhite.svg';
import { ReactComponent as lookIcon } from 'assets/look.svg';
import { ReactComponent as plusIcon } from 'assets/plus.svg';
import styles from './styles.module.scss';

interface IProps {
  employees: IEmployeeBasicData[] | null;
  handleListItemClick: (id: string) => void;
  selectedEmployeeId: string | null;
  openAddEmployeeModal: () => void;
  handleSearchEmployee: (value: string) => void;
}

const EmployeesListPanel: React.FC<IProps> = (props) => {
  const [activeOpen, setActiveOpen] = useState(true);
  const [cobraOpen, setCobraOpen] = useState(false);
  const [terminatedOpen, setTerminatedOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const matches = useMediaQuery('(min-width:960px)');
  const {
    selectedEmployeeId,
    employees,
    handleListItemClick,
    openAddEmployeeModal,
    handleSearchEmployee,
  } = props;

  const toggleTerminated = () => {
    setTerminatedOpen(!terminatedOpen);
  };
  const toggleActive = () => {
    setActiveOpen(!activeOpen);
  };

  const toggleCobra = () => {
    setCobraOpen(!cobraOpen);
  };

  let activeEmployees: IEmployeeBasicData[] = [];
  let terminatedEmployees: IEmployeeBasicData[] = [];
  let cobraEmployees: IEmployeeBasicData[] = [];

  if (employees) {
    activeEmployees = employees.filter(
      ({ terminated, cobra }) => !terminated && !cobra
    );
    terminatedEmployees = employees.filter(
      ({ terminated, cobra }) => terminated && !cobra
    );
    cobraEmployees = employees.filter(({ cobra }) => cobra);
  }
  const selectEmployee = (
    id: string,
    active: boolean,
    cobra: boolean = false
  ) => {
    handleListItemClick(id);
    if (!matches) {
      if (cobra) {
        setCobraOpen(false);
      } else if (active) {
        setActiveOpen(false);
      } else {
        setTerminatedOpen(false);
      }
    }
  };
  const checkHide = (active: boolean, cobra: boolean = false): boolean => {
    if (selectedEmployeeId) {
      if (cobra) {
        return (
          cobraEmployees.find(
            (employee) => employee.id === selectedEmployeeId
          ) !== undefined
        );
      } else if (active) {
        return (
          activeEmployees.find(
            (employee) => employee.id === selectedEmployeeId
          ) !== undefined
        );
      } else {
        return (
          terminatedEmployees.find(
            (employee) => employee.id === selectedEmployeeId
          ) !== undefined
        );
      }
    }
    return false;
  };
  const renderEmployees = (
    employee: IEmployeeBasicData,
    last: boolean,
    active: boolean,
    cobra: boolean = false
  ) => (
    <List key={employee.id} component="nav">
      <ListItem
        className={
          selectedEmployeeId === employee.id ? styles.selectedItem : ''
        }
        button
        selected={selectedEmployeeId === employee.id}
        onClick={() => selectEmployee(employee.id, active, cobra)}
      >
        <ListItemText primary={`${employee.lastName}, ${employee.firstName}`} />
      </ListItem>
      {!last && <Divider />}
    </List>
  );

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    handleSearchEmployee(value);
  };

  return (
    <Box className={styles.employeesCard}>
      <Box className={styles.navigateBlock}>
        <Input
          className={styles.input}
          inversePositionIcon
          value={searchValue}
          name="search"
          onChange={handleSearchValueChange}
          placeholder="Search"
          icon={
            <SvgIcon
              component={lookIcon}
              width="17"
              height="17"
              viewBox="0 0 17 17"
              className={styles.icon}
            />
          }
        />
        <Button
          className={styles.submitBtn}
          onClick={openAddEmployeeModal}
          variant="contained"
          color="primary"
        >
          <SvgIcon
            component={plusIcon}
            width="7"
            height="6"
            viewBox="0 0 7 6"
            className={styles.iconBtn}
          />
          Add New Employee
        </Button>
      </Box>
      <Box className={styles.employeesBox}>
        <ContentContainer
          title="Active Employees"
          withoutMargin
          hide={!(activeOpen || checkHide(true))}
          onClick={toggleActive}
          button={
            <Typography
              className={styles.terminatedEmployees}
              onClick={toggleActive}
              variant="subtitle1"
            >
              {activeOpen ? (
                <SvgIcon
                  className={styles.helpIcons}
                  component={closeIcon}
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                />
              ) : (
                <SvgIcon
                  className={styles.helpIcons}
                  component={openIcon}
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                />
              )}
            </Typography>
          }
        >
          <Box className={styles.employeeListContainer}>
            {activeOpen
              ? activeEmployees.map((employee, index) =>
                  renderEmployees(
                    employee,
                    activeEmployees.length === index + 1,
                    true
                  )
                )
              : activeEmployees
                  .filter((employee) => employee.id === selectedEmployeeId)
                  .map((employee, index) =>
                    renderEmployees(
                      employee,
                      activeEmployees.length === index + 1,
                      true
                    )
                  )}
          </Box>
        </ContentContainer>
        {cobraEmployees && cobraEmployees.length != 0 && (
          <ContentContainer
            title="COBRA/Continuation"
            hide={!(cobraOpen || checkHide(false, true))}
            onClick={toggleCobra}
            button={
              <Typography
                className={styles.terminatedEmployees}
                onClick={toggleCobra}
                variant="subtitle1"
              >
                {cobraOpen ? (
                  <SvgIcon
                    className={styles.helpIcons}
                    component={closeIcon}
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                  />
                ) : (
                  <SvgIcon
                    className={styles.helpIcons}
                    component={openIcon}
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                  />
                )}
              </Typography>
            }
          >
            <Box className={styles.employeeListContainer}>
              {cobraOpen
                ? cobraEmployees.map((employee, index) =>
                    renderEmployees(
                      employee,
                      terminatedEmployees.length === index + 1,
                      false,
                      true
                    )
                  )
                : cobraEmployees
                    .filter((employee) => employee.id === selectedEmployeeId)
                    .map((employee, index) =>
                      renderEmployees(
                        employee,
                        terminatedEmployees.length === index + 1,
                        false,
                        true
                      )
                    )}
            </Box>
          </ContentContainer>
        )}
        <ContentContainer
          title="Terminated Employees"
          hide={!(terminatedOpen || checkHide(false))}
          onClick={toggleTerminated}
          button={
            <Typography
              className={styles.terminatedEmployees}
              onClick={toggleTerminated}
              variant="subtitle1"
            >
              {terminatedOpen ? (
                <SvgIcon
                  className={styles.helpIcons}
                  component={closeIcon}
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                />
              ) : (
                <SvgIcon
                  className={styles.helpIcons}
                  component={openIcon}
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                />
              )}
            </Typography>
          }
        >
          <Box className={styles.employeeListContainer}>
            {terminatedOpen
              ? terminatedEmployees.map((employee, index) =>
                  renderEmployees(
                    employee,
                    terminatedEmployees.length === index + 1,
                    false
                  )
                )
              : terminatedEmployees
                  .filter((employee) => employee.id === selectedEmployeeId)
                  .map((employee, index) =>
                    renderEmployees(
                      employee,
                      terminatedEmployees.length === index + 1,
                      false
                    )
                  )}
          </Box>
        </ContentContainer>
      </Box>
    </Box>
  );
};

export default EmployeesListPanel;
