import React, { useState, useEffect } from 'react';
import SvgIcon from '@material-ui/icons/ChevronLeft';
import { ReactComponent as dashboardIcon } from 'assets/sidebar/dashboard.svg';
import { ReactComponent as benefitsIcon } from 'assets/sidebar/benefits.svg';
import { ReactComponent as dependentsIcon } from 'assets/sidebar/dependents.svg';
import { ReactComponent as documentsIcon } from 'assets/sidebar/documents.svg';
import { ReactComponent as beneficiariesIcon } from 'assets/sidebar/beneficiaries.svg';
import { ReactComponent as accountIcon } from 'assets/sidebar/account.svg';
import { ReactComponent as contactIcon } from 'assets/sidebar/contact.svg';
import { ReactComponent as profileIcon } from 'assets/sidebar/profile.svg';
import { ReactComponent as myAuditIcon } from 'assets/sidebar/myAudit.svg';
import { ReactComponent as administratorIcon } from 'assets/sidebar/administrator.svg';
import { ReactComponent as censusIcon } from 'assets/sidebar/census.svg';
import { ReactComponent as centerIcon } from 'assets/sidebar/center.svg';
import { ReactComponent as formsIcon } from 'assets/sidebar/forms.svg';
import { ReactComponent as reportsIcon } from 'assets/sidebar/reports.svg';
import { ReactComponent as statusIcon } from 'assets/sidebar/status.svg';
import { ReactComponent as transactionIcon } from 'assets/sidebar/transaction.svg';
import { ReactComponent as auditIcon } from 'assets/sidebar/audit.svg';
import { ReactComponent as messagesIcon } from 'assets/sidebar/messages.svg';
import { ReactComponent as changeBenefitsIcon } from 'assets/sidebar/change_benefits.svg';
import RoleType from 'enums/roleType';
import * as routes from 'constants/routes';

export interface ILink {
  handleClick: () => void | Promise<void>;
  icon: React.ReactNode;
  name: string;
}

export interface IStrictLink extends ILink {
  activeIndex: number;
  to: string;
}

export interface ILinkWithNested extends ILink {
  nestedState?: boolean;
  nestedStateHandler?: React.Dispatch<React.SetStateAction<boolean>>;
  nested: IStrictLink[];
}

export type Link = IStrictLink | ILinkWithNested;

function useSidebar(
  matchesSm: boolean,
  pathname: string,
  setSidebarOpen: (value: React.SetStateAction<boolean>) => void,
  roleType: RoleType,
  testUser: boolean,
  enableMidyearChanges: boolean
) {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [myBenefitOpen, setMyBenefitOpen] = useState(false);
  const [myAccountOpen, setMyAccountOpen] = useState(false);
  const [administratorOpen, setAdministratorOpen] = useState(false);

  const handleSidebarLinkClick = (activeIndex: number) => {
    setCurrentTabIndex(activeIndex);
    setSidebarOpen(false);
  };

  const handleNestedSidebarLinkClick = (
    stateHandler: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    stateHandler((prevState) => !prevState);
  };

  const linkCallback = (activeIndex: number) =>
    matchesSm
      ? handleSidebarLinkClick(activeIndex)
      : setCurrentTabIndex(activeIndex);

  const topLinks: Link[] = [
    {
      name: 'Dashboard',
      icon: <SvgIcon component={dashboardIcon} />,
      to: routes.DEFAULT_PATH,
      activeIndex: 0,
      handleClick: () => linkCallback(0),
    },
    {
      name: 'My Benefits',
      icon: <SvgIcon component={benefitsIcon} />,
      handleClick: () => handleNestedSidebarLinkClick(setMyBenefitOpen),
      nestedState: myBenefitOpen,
      nestedStateHandler: setMyBenefitOpen,
      nested: [
        {
          name: 'My Dependents',
          icon: <SvgIcon component={dependentsIcon} />,
          to: routes.DEPENDENTS_PATH,
          activeIndex: 1,
          handleClick: () => linkCallback(1),
        },
        {
          name: 'My Documents',
          icon: <SvgIcon component={documentsIcon} />,
          to: routes.DOCUMENTS_PATH,
          activeIndex: 2,
          handleClick: () => linkCallback(2),
        },
        {
          name: 'My Beneficiaries',
          icon: <SvgIcon component={beneficiariesIcon} />,
          to: routes.BENEFICIARIES_PATH,
          activeIndex: 3,
          handleClick: () => linkCallback(3),
        },
      ],
    },
    {
      name: 'My Account',
      icon: <SvgIcon component={accountIcon} />,
      nestedState: myAccountOpen,
      nestedStateHandler: setMyAccountOpen,
      handleClick: () => handleNestedSidebarLinkClick(setMyAccountOpen),
      nested: [
        {
          name: 'My Profile',
          icon: <SvgIcon component={profileIcon} />,
          to: routes.PROFILE_PATH,
          activeIndex: 5,
          handleClick: () => linkCallback(5),
        },
        {
          name: 'My Audit Log',
          icon: <SvgIcon component={myAuditIcon} />,
          to: routes.AUDIT_LOGS_PATH,
          activeIndex: 6,
          handleClick: () => linkCallback(6),
        },
      ],
    },
  ];

  if (enableMidyearChanges)
    (topLinks[1] as ILinkWithNested).nested.push({
      name: 'Change Benefits',
      icon: <SvgIcon component={changeBenefitsIcon} />,
      to: routes.CHANGE_BENEFITS,
      activeIndex: 4,
      handleClick: () => linkCallback(4),
    });

  const adminLinks: ILinkWithNested[] = [
    {
      name: 'Administrator',
      icon: <SvgIcon component={administratorIcon} />,
      nestedState: administratorOpen,
      nestedStateHandler: setAdministratorOpen,
      handleClick: () => handleNestedSidebarLinkClick(setAdministratorOpen),
      nested: [
        {
          name: 'Employee Census',
          icon: <SvgIcon component={censusIcon} />,
          to: routes.EMPLOYEES_CENSUS,
          activeIndex: 7,
          handleClick: () => linkCallback(7),
        },
        {
          name: 'Action Center',
          icon: <SvgIcon component={centerIcon} />,
          to: routes.ACTION_CENTER_PATH,
          activeIndex: 8,
          handleClick: () => linkCallback(8),
        },
        {
          name: 'Audit Log',
          icon: <SvgIcon component={auditIcon} />,
          to: routes.ADMIN_AUDIT_LOGS_PATH,
          activeIndex: 9,
          handleClick: () => linkCallback(9),
        },
        {
          name: 'HR Reports',
          icon: <SvgIcon component={reportsIcon} />,
          to: routes.REPORTS_PATH,
          activeIndex: 10,
          handleClick: () => linkCallback(10),
        },
        // In current version not needed
        // {
        //   name: 'Message Board',
        //   icon: <MessageIcon />,
        //   to: routes.MESSAGE_BOARD_PATH,
        //   activeIndex: 9,
        //   handleClick: () => linkCallback(9),
        // },
        {
          name: 'Status Dashboard',
          icon: <SvgIcon component={statusIcon} />,
          to: routes.STATUS_DASHBOARD_PATH,
          activeIndex: 11,
          handleClick: () => linkCallback(11),
        },
        {
          name: 'Employer Forms',
          icon: <SvgIcon component={formsIcon} />,
          to: routes.EMPLOYER_FORMS,
          activeIndex: 12,
          handleClick: () => linkCallback(12),
        },
        {
          name: 'Transactions',
          icon: <SvgIcon component={transactionIcon} />,
          to: routes.TRANSACTIONS_PATH,
          activeIndex: 13,
          handleClick: () => linkCallback(13),
        },
        {
          name: 'Communications',
          icon: <SvgIcon component={messagesIcon} />,
          to: routes.EMAIL_PATH,
          activeIndex: 14,
          handleClick: () => linkCallback(14),
        },
      ],
    },
  ];

  const bottomSidebarLinks: IStrictLink[] = [
    {
      name: 'Contact',
      icon: <SvgIcon component={contactIcon} />,
      to: routes.CONTACT_PATH,
      activeIndex: 15,
      handleClick: () => linkCallback(15),
    },
  ];

  const topSidebarLinks =
    roleType === RoleType.Admin && !testUser
      ? [...topLinks, ...adminLinks]
      : topLinks;

  useEffect(() => {
    [...topSidebarLinks, ...bottomSidebarLinks].forEach((link) => {
      const baseName = routes.BASE_NAME;
      const currentPath = window.location.pathname;

      const linkAsStrictLink = link as IStrictLink;
      if (linkAsStrictLink.to) {
        const routeMatch = currentPath === `${baseName}${linkAsStrictLink.to}`;
        if (routeMatch) {
          if (linkAsStrictLink.activeIndex) {
            setCurrentTabIndex(linkAsStrictLink.activeIndex);
          }
        }
      }

      const linkAsLinkWithNested = link as ILinkWithNested;
      if (linkAsLinkWithNested.nested) {
        linkAsLinkWithNested.nested.forEach((nestedLink) => {
          const nestedRouteMatch = nestedLink.to
            ? currentPath === `${baseName}${nestedLink.to}`
            : null;
          if (
            nestedRouteMatch &&
            linkAsLinkWithNested.nestedStateHandler &&
            typeof nestedLink.activeIndex === 'number'
          ) {
            linkAsLinkWithNested.nestedStateHandler(true);
            setCurrentTabIndex(nestedLink.activeIndex);
          }
        });
      }
    });
    // eslint-disable-next-line
  }, [pathname]);

  return {
    topSidebarLinks,
    bottomSidebarLinks,
    currentTabIndex,
  };
}

export default useSidebar;
