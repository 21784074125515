export const LINK_SENT = 'Reset link has been sent to your email!';
export const EMAIL_NOT_EXIST = 'Provided email does not exist';
export const INVALID_CREDENTIALS = 'Invalid credentials!';
export const INVALID_TOKEN = 'Token is not valid!';
export const PASSWORD_CHANGED = 'Password changed successfully!';
export const WRONG_ANSWER = 'Wrong answer!';
export const ALREADY_REGISTERED =
  "You've already registered. Please enter your username and password on the login page";
export const REGISTRATION_TOKEN_NOT_VALID =
  'Registration token isn’t valid. Please try the link from the email one more time or contact support';
export const EMPLOYEE_TERMINATION_SUCCESS = 'Employee terminated successfully!';
export const EMPLOYEE_TERMINATION_ERROR = 'Employee termination error!';
export const EMPLOYEE_CREATION_SUCCESS = 'Employee created successfully!';
export const EMPLOYEE_CREATION_ERROR = 'Employee creation error!';
export const EMPLOYEE_UPDATING_SUCCESS = 'Employee updated successfully!';
export const EMPLOYEE_UPDATING_ERROR = 'Employee updating error!';

export const CHANGE_PASSWORD_ALERT =
  'Your password was changed by the system administrator. Please create a new password.';

export const CHANGE_SQ_ALERT =
  "You don't have have security question/answer. Please add security question/answer.";
export const SESSION_EXPIRED = 'Your session has expired. Please log in again.';
export const DOWNLOAD_ERROR = 'Error, when trying to download file';
export const PLANS_GROUP_FETCH_ERROR =
  'Error, when trying to fetch plans group info';
export const FOLLOW_EMAIL_LINK =
  'Please follow link in your welcome email to create your account';
