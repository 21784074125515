import moment from 'moment';
import {
  IAddEmployeePayload,
  IAddEmployeeEndpointFormat,
} from 'types/employee';

export const fromAddEmployeeToAddEmployeeEndpointFormat = (
  payload: IAddEmployeePayload
): IAddEmployeeEndpointFormat => {
  return {
    ...payload,
    birthDate: payload.birthDate
      ? moment(payload.birthDate).format('MM/DD/YYYY')
      : null,
    hireDate: moment(payload.hireDate).format('MM/DD/YYYY'),
  };
};
