import React from 'react';
import { Box, Typography, Toolbar } from '@material-ui/core';
import styles from './styles.module.scss';

export interface ContentBoxInterface {
  title?: string | React.ReactNode;
  index?: number;
  count?: number;
  isBox?: boolean;
}

const ContentTableBox: React.FC<ContentBoxInterface> = (props) => {
  const { title, children, isBox } = props;

  return (
    <>
      <Toolbar className={styles.electedTableToolbar}>
        <Typography className={styles.tableHeader}>{title}</Typography>
        <Typography
          className={styles.step}
        >{`STEP 0${props.index} / ${props.count}`}</Typography>
      </Toolbar>
      <Box className={isBox ? styles.box : styles.tableBox}>{children}</Box>
    </>
  );
};

export default ContentTableBox;
