import React from 'react';
import { Box } from '@material-ui/core';

import ModalWindow, {
  ModalWindowPropsInterface,
} from 'components/Modal/ModalWindowComponent';
import Backdrop from 'components/Backdrop';
import { IAction } from 'types/actions';

import ButtonFilesList from 'components/FilesList/ButtonFileList';
import styles from './styles.module.scss';

export interface IModalActionFilesModalProps extends ModalWindowPropsInterface {
  action?: IAction;
}

const ActionFormsModal: React.FC<IModalActionFilesModalProps> = (props) => {
  const { action, ...modalProps } = props;
  const [loading, setLoading] = React.useState(false);

  const filteredFiles = action?.files.filter((file) => file.id && file.title);

  return (
    <ModalWindow {...modalProps}>
      <Box className={styles.actionFormsModalContainer}>
        <ButtonFilesList setLoading={setLoading} files={filteredFiles} />
      </Box>
      <Backdrop open={loading} />
    </ModalWindow>
  );
};

export default ActionFormsModal;
