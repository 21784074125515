import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { SimpleStepDataInterface } from 'components/Stepper/SimpleStepper/SimpleStepperComponent';
import AdaptiveSimpleStepper from 'components/Stepper/SimpleStepper/AdaptiveSimpleStepperComponent';
import ContentStepperBox from 'components/ContentStepperBox/ContentStepperBoxComponent';
import Backdrop from 'components/Backdrop';
import Feedbacker from 'components/Feedbacker';

import { loadCurrentUser } from 'redux/sagas/user/routines';
import {
  loadUserDependents,
  loadUserEnrollments,
  loadUserPlans,
  loadUserBeneficiaries,
  loadUserProfile,
} from 'redux/sagas/userRegistration/routines';
import userDomain from 'api/domains/user';
import ExternalRegistrationStep from 'enums/externalRegistrationStep';
import AuthMethod from 'enums/authMethod';
import { AppStoreInterface } from 'store/app';
import AccountSettings from './steps/AccountSettings';
import DependentsSettings from './steps/DependentsSettings';
import ProfileSettings from './steps/ProfileSettings';
import EnrollmentsSettings from './steps/EnrollmentSettings';
import BeneficiariesSettings from './steps/BeneficiariesSettings';
import AdditionalInfoSettings from './steps/AdditionalInfoSettings';
import AddSignature from './steps/AddSignature';
import Summary from './steps/Summary';

import { stepMapping } from './stepMapping';

import styles from './styles.module.scss';

interface RegistrationContainerStateInterface {
  isLoading: boolean;
  feedbackErrorMessage?: string;
  activeStepIndex: number;
}

type RegistrationActionsInterface = {
  saveCurrentUserData: typeof loadCurrentUser.success;
  loadCurrentUser: typeof loadCurrentUser.trigger;
  loadUserDependents: typeof loadUserDependents.trigger;
  loadUserEnrollments: typeof loadUserEnrollments.trigger;
  loadUserPlans: typeof loadUserPlans.trigger;
  loadUserBeneficiaries: typeof loadUserBeneficiaries.trigger;
  loadUserProfile: typeof loadUserProfile.trigger;
};

interface RegistrationContainerPropsInterface {
  registrationStepIndex?: number;
  isAuthenticated: boolean;
  authenticationMethod: AuthMethod | null;
  actions: RegistrationActionsInterface;
}

class RegistrationContainer extends React.Component<
  RegistrationContainerPropsInterface,
  RegistrationContainerStateInterface
> {
  constructor(props: RegistrationContainerPropsInterface) {
    super(props);
    this.state = {
      isLoading: false,
      activeStepIndex: 0,
    };
  }

  componentDidMount() {
    this.stepsFromProps();
    this.prefetchData();
  }

  async componentDidUpdate(prevProps: RegistrationContainerPropsInterface) {
    if (typeof prevProps.registrationStepIndex !== 'number') {
      this.stepsFromProps();
    }
  }

  /**
   * When we got some registration index in props,
   * we need to fetch data from previous steps
   */
  prefetchData() {
    const { registrationStepIndex } = this.props;
    if (!registrationStepIndex) {
      return;
    }

    const loadEnrollmentsAndPlans = () => {
      this.props.actions.loadUserEnrollments();
      this.props.actions.loadUserPlans();
    };

    const stepToActions = new Map<ExternalRegistrationStep, () => any>([
      [ExternalRegistrationStep.Profile, this.props.actions.loadUserProfile],
      [
        ExternalRegistrationStep.Dependents,
        this.props.actions.loadUserDependents,
      ],
      [ExternalRegistrationStep.Enrollments, loadEnrollmentsAndPlans],
      [
        ExternalRegistrationStep.Beneficiaries,
        this.props.actions.loadUserBeneficiaries,
      ],
    ]);

    const index = stepMapping.findIndex(
      (step) => step === registrationStepIndex
    );
    if (!index) {
      return;
    }

    stepMapping.slice(0, index + 1).forEach((step) => {
      const action = stepToActions.get(step);
      if (action) {
        action();
      }
    });
  }

  stepsFromProps = () => {
    if (typeof this.props.registrationStepIndex === 'number') {
      const index = stepMapping.findIndex(
        (step) => step === this.props.registrationStepIndex
      );
      this.setState({ activeStepIndex: index + 1 });
    }
  };

  setIsLoadAsync = (isLoading: boolean): Promise<void> => {
    return new Promise((resolve) => {
      this.setState({ isLoading }, resolve);
    });
  };

  setFeedbackError = (feedbackErrorMessage?: string, delay?: number) => {
    if (delay) {
      setTimeout(() => this.setState({ feedbackErrorMessage }), delay);
    } else {
      this.setState({ feedbackErrorMessage });
    }
  };

  moveForward = async () => {
    const { activeStepIndex } = this.state;
    const newRegistrationStep = activeStepIndex + 1;
    await this.saveRegistrationStep(newRegistrationStep);
    this.setState({ activeStepIndex: newRegistrationStep });
  };

  // TODO: figure out with this method
  moveForwardAccountSetup = () => {
    const { actions } = this.props;
    // if (isAuthenticated && authenticationMethod === AuthMethod.Username) {
    actions.loadCurrentUser();
    // } else {
    // actions.saveCurrentUserData();
    // }
  };

  moveBack = () => {
    if (this.state.activeStepIndex > 0) {
      this.setState(
        (prevState) => ({
          activeStepIndex: prevState.activeStepIndex - 1,
        }),
        () => this.setFeedbackError(undefined)
      );
    }
  };

  saveRegistrationStep = async (newStep: number) => {
    try {
      await this.setIsLoadAsync(true);
      const externalStep = stepMapping[newStep - 1];
      await userDomain.saveRegistrationStep(externalStep.toString());
    } catch (e) {
      console.error(e);
    } finally {
      await this.setIsLoadAsync(false);
    }
  };

  onFinish = () => {
    this.props.actions.saveCurrentUserData({
      registrationStep: ExternalRegistrationStep.RegistrationIsFinished,
    });
  };

  handleStepClick = (index: number) => {
    if (index < this.state.activeStepIndex && index !== 0) {
      this.setState({ activeStepIndex: index });
    }
  };

  getRegistrationSteps(): SimpleStepDataInterface[] {
    return [
      {
        label: 'Account Setup',
        content: (
          <AccountSettings
            label="Account Setup"
            index={1}
            count={8}
            moveForward={this.moveForwardAccountSetup}
            setIsLoading={this.setIsLoadAsync}
            setError={this.setFeedbackError}
          />
        ),
      },
      {
        label: 'Employee Profile',
        content: (
          <ProfileSettings
            index={2}
            count={8}
            moveForward={this.moveForward}
            setIsLoading={this.setIsLoadAsync}
            setError={this.setFeedbackError}
          />
        ),
      },
      {
        label: 'Dependents',
        content: (
          <DependentsSettings
            moveForward={this.moveForward}
            setIsLoading={this.setIsLoadAsync}
            setError={this.setFeedbackError}
            moveBack={this.moveBack}
          />
        ),
      },
      {
        label: 'Benefits Enrollment',
        content: (
          <EnrollmentsSettings
            moveForward={this.moveForward}
            moveBack={this.moveBack}
            setIsLoading={this.setIsLoadAsync}
            setError={this.setFeedbackError}
          />
        ),
      },
      {
        label: 'Beneficiaries',
        content: (
          <BeneficiariesSettings
            moveForward={this.moveForward}
            setIsLoading={this.setIsLoadAsync}
            setError={this.setFeedbackError}
            moveBack={this.moveBack}
          />
        ),
      },
      {
        label: 'Additional Information',
        content: (
          <AdditionalInfoSettings
            moveForward={this.moveForward}
            moveBack={this.moveBack}
            setIsLoading={this.setIsLoadAsync}
            setError={this.setFeedbackError}
          />
        ),
      },
      {
        label: 'Signature',
        content: (
          <AddSignature
            moveForward={this.moveForward}
            moveBack={this.moveBack}
            setIsLoading={this.setIsLoadAsync}
            setError={this.setFeedbackError}
          />
        ),
      },
      {
        label: 'Summary',
        content: (
          <Summary moveForward={this.onFinish} moveBack={this.moveBack} />
        ),
      },
    ];
  }

  renderBackdrop = () => {
    const { isLoading } = this.state;
    return <Backdrop open={isLoading} />;
  };

  renderFeedbacker = () => {
    const { feedbackErrorMessage } = this.state;

    return (
      <Feedbacker
        open={!!feedbackErrorMessage}
        clearFeedback={() => this.setFeedbackError(undefined)}
        feedbackMessage={feedbackErrorMessage as string}
        severity="error"
      />
    );
  };

  render() {
    const { activeStepIndex } = this.state;

    return (
      <ContentStepperBox className={styles.registrationContainer}>
        <Helmet>
          <title>{`MyBenefitLink | ${
            activeStepIndex !== 0 ? 'Benefit Enrollment' : 'Registration'
          }`}</title>
        </Helmet>
        <AdaptiveSimpleStepper
          nonLinear
          onStepClick={this.handleStepClick}
          steps={this.getRegistrationSteps()}
          activeStep={this.state.activeStepIndex}
          breakpoint={1200}
        />
        {this.renderBackdrop()}
        {this.renderFeedbacker()}
      </ContentStepperBox>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<{}, RegistrationActionsInterface>(
    {
      saveCurrentUserData: loadCurrentUser.success,
      loadCurrentUser: loadCurrentUser.trigger,
      loadUserDependents: loadUserDependents.trigger,
      loadUserEnrollments: loadUserEnrollments.trigger,
      loadUserPlans: loadUserPlans.trigger,
      loadUserBeneficiaries: loadUserBeneficiaries.trigger,
      loadUserProfile: loadUserProfile.trigger,
    },
    dispatch
  ),
});

const mapStateToProps = (store: AppStoreInterface) => ({
  isAuthenticated: store.user.isAuthenticated,
  authenticationMethod: store.user.authenticationMethod,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationContainer);
