import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from 'react-query';
import { Form, Formik, FormikErrors, FormikProps, FormikTouched } from 'formik';
import * as yup from 'yup';

import Select from 'components/Forms/Select';
import Currency from 'components/Currency';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import plansDomain from 'api/domains/plans';
import getCurrency from 'helpers/getCurrency';
import { IEnrollment, IUpdateEnrollmentPayload } from 'types/enrollments';
import {
  IContributionAmountVoluntaryLife,
  ICoverageAmounts,
} from 'types/plans';

import styles from './styles.module.scss';

interface IFormValues {
  employeeAmount: string | number;
  spouseAmount: string | number;
  childrenAmount: string | number;
}

interface IPredictAmountPayload {
  planId: string;
  employeeAmount: string | number | null;
  spouseAmount: string | number | null;
  childrenAmount: string | number | null;
}

interface IProps {
  planId: string;
  planTypeId: string;
  enrollment?: IEnrollment;
  coverageAmount?: ICoverageAmounts | null;
  updateEnrollment: (
    id: string,
    enrollment: IUpdateEnrollmentPayload
  ) => void | Promise<void>;
}

const getMaxContributionAmount = async (_key: string, planId: string) => {
  const { data } = await plansDomain.getMaxContributionAmountVoluntaryLife(
    planId
  );
  return data;
};

const predictContributionAmountVolLife = async ({
  planId,
  employeeAmount,
  spouseAmount,
  childrenAmount,
}: IPredictAmountPayload) => {
  const { data } = await plansDomain.predictContributionAmountVolLife(
    planId,
    employeeAmount,
    spouseAmount,
    childrenAmount
  );
  return data;
};

const VoluntaryLifeForm: React.FC<IProps> = (props) => {
  const {
    planId,
    enrollment,
    updateEnrollment,
    coverageAmount,
    planTypeId,
  } = props;
  const classes = useStyles();

  const {
    isLoading: maxContributionLoading,
    data: maxContributionAmount,
  } = useQuery(
    ['VoluntaryLifePlanContributionLimit', planId],
    getMaxContributionAmount,
    { refetchOnWindowFocus: false }
  );

  const [
    predictContributionAmount,
    { data: contributionAmount, isLoading: predictContributionLoading },
  ] = useMutation(predictContributionAmountVolLife);

  const {
    employeeAmounts,
    spouseAmounts,
    childrenAmounts,
    spouseAmountPercent,
    childAmountPercent,
    employeeGiAmount,
    spouseGiAmount,
  } = maxContributionAmount || {};

  const { coverageAmounts } = enrollment || {};

  useEffect(() => {
    if (coverageAmounts || coverageAmount) {
      const amount = coverageAmounts ?? coverageAmount;
      if (amount) {
        const { employee, spouse, children } = amount;
        predictContributionAmount({
          planId,
          employeeAmount: employee,
          spouseAmount: spouse,
          childrenAmount: children,
        });
      }
    }
  }, [coverageAmount, coverageAmounts, planId, predictContributionAmount]);

  const initialValues: IFormValues = {
    employeeAmount:
      coverageAmounts?.employee || Number(coverageAmount?.employee) || '',
    spouseAmount:
      coverageAmounts?.spouse || Number(coverageAmount?.spouse) || '',
    childrenAmount:
      coverageAmounts?.children || Number(coverageAmount?.children) || '',
  };

  const validationSchema = (spousePercent?: string, childPercent?: string) =>
    yup.object<IFormValues>().shape({
      employeeAmount: yup.number().required('Field is required'),
      spouseAmount: yup
        .number()
        .when('employeeAmount', getMaxAmount(spousePercent, spouseAmounts)),
      childrenAmount: yup
        .number()
        .when('employeeAmount', getMaxAmount(childPercent, childrenAmounts)),
    });

  const getMaxAmount = (
    percent?: string,
    values?: IContributionAmountVoluntaryLife[]
  ) => (employeeAmount: string | number, schema: yup.NumberSchema) => {
    if (employeeAmount && percent && values) {
      const maxOfEmployeeAmount = +employeeAmount * (+percent / 100);
      let maxValue = maxOfEmployeeAmount;
      for (const { reduced } of values) {
        if (+reduced <= maxOfEmployeeAmount) maxValue = +reduced;
      }

      return schema.max(
        maxValue,
        `The maximum amount is ${getCurrency(maxValue)}`
      );
    }
    return schema;
  };

  const handleSubmit = async (values: IFormValues) => {
    if (contributionAmount) {
      const { employeeAmount, childrenAmount, spouseAmount } = values;

      await updateEnrollment(planTypeId, {
        planId,
        contributionAmount: contributionAmount.amount,
        coverageAmounts: {
          employee: employeeAmount,
          spouse: spouseAmount,
          children: childrenAmount,
        },
      });
    }
  };

  const getErrorMessage = (
    key: keyof IFormValues,
    errors: FormikErrors<IFormValues>,
    touched: FormikTouched<IFormValues>
  ) => {
    const error = errors[key];
    const isTouched = touched[key];
    return isTouched && error ? (error as string) : '';
  };

  const handleSelectChange = async (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    formikHandler: (e: React.ChangeEvent<any>) => void,
    values: IFormValues
  ) => {
    formikHandler(e);
    try {
      const {
        target: { name, value },
      } = e;
      let { employeeAmount, spouseAmount, childrenAmount } = values;

      if (name === 'employeeAmount') employeeAmount = value as string;
      if (name === 'spouseAmount') spouseAmount = value as string;
      if (name === 'childrenAmount') childrenAmount = value as string;

      await predictContributionAmount({
        planId,
        employeeAmount,
        spouseAmount,
        childrenAmount,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const isSelectVisible = (
    employeeAmount: string | number,
    amountOptions?: IContributionAmountVoluntaryLife[]
  ) => {
    if (amountOptions) {
      return amountOptions.length > 0;
    }
    return false;
  };

  const renderMenuItem = (
    item: IContributionAmountVoluntaryLife,
    current?: number | null | string
  ) => (
    <MenuItem key={item.original} value={item.original}>
      {current
        ? +item.reduced === +current
          ? 'Keep at '
          : +item.reduced > +current
          ? 'Increase to '
          : 'Decrease to '
        : ''}
      <Currency>{item.reduced}</Currency>
    </MenuItem>
  );

  const renderSelectInfo = (amount: string | number, giAmount?: string) => {
    if (giAmount && +amount > +giAmount) {
      return (
        <Typography className={styles.selectInfo} variant="body2">
          Evidence of Insurability Form is required for this level of coverage
        </Typography>
      );
    }
    return null;
  };

  if (maxContributionLoading) return null;
  return (
    <Box className={styles.fsaFormContainer}>
      {(maxContributionAmount?.employeeAmounts.length !== 0 ||
        maxContributionAmount?.spouseAmounts.length !== 0 ||
        maxContributionAmount?.childrenAmounts.length !== 0) && (
        <SimpleAlert
          title={
            coverageAmount
              ? `Your existing coverage levels have been pre-selected for you in the dropdowns. 
               If you wish to continue at those levels click Enroll.  
               If you wish to increase or decrease your coverage levels, select the TOTAL COVERAGE AMOUNT OF COVERAGE FOR THE NEW PERIOD 
               in the dropdowns prior to selecting Enroll. 
               Example IF YOU CURRENTLY HAVE $100,000 in coverage and you would like $150,000 total coverage, select $150,000 in the dropdown.`
              : 'Select the amount of insurance you would like to apply for in the dropdown window.'
          }
          type="info"
          className={styles.infoMessage}
        />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(
          spouseAmountPercent,
          childAmountPercent
        )}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
        }: FormikProps<IFormValues>) => (
          <Form className={styles.voluntaryLifeForm}>
            {renderSelectInfo(values.employeeAmount, employeeGiAmount)}
            {isSelectVisible(
              values.employeeAmount,
              maxContributionAmount?.employeeAmounts
            ) && (
              <Select
                name="employeeAmount"
                label="Employee"
                placeholder="Choose employee coverage amount"
                value={values.employeeAmount}
                onChange={(e) => handleSelectChange(e, handleChange, values)}
                onBlur={handleBlur}
                error={!!getErrorMessage('employeeAmount', errors, touched)}
                helperText={getErrorMessage('employeeAmount', errors, touched)}
              >
                {employeeAmounts?.map((i) =>
                  renderMenuItem(i, coverageAmounts?.employee)
                )}
              </Select>
            )}
            {renderSelectInfo(values.spouseAmount, spouseGiAmount)}
            {isSelectVisible(
              values.spouseAmount,
              maxContributionAmount?.spouseAmounts
            ) && (
              <Select
                name="spouseAmount"
                label="Spouse"
                placeholder="Choose spouse coverage amount"
                value={values.spouseAmount}
                onChange={(e) => handleSelectChange(e, handleChange, values)}
                onBlur={handleBlur}
                error={!!getErrorMessage('spouseAmount', errors, touched)}
                helperText={getErrorMessage('spouseAmount', errors, touched)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {spouseAmounts?.map((i) =>
                  renderMenuItem(i, coverageAmounts?.spouse)
                )}
              </Select>
            )}
            {isSelectVisible(
              values.childrenAmount,
              maxContributionAmount?.childrenAmounts
            ) && (
              <Select
                name="childrenAmount"
                label="Children"
                placeholder="Choose children coverage amount"
                value={values.childrenAmount}
                onChange={(e) => handleSelectChange(e, handleChange, values)}
                onBlur={handleBlur}
                error={!!getErrorMessage('childrenAmount', errors, touched)}
                helperText={getErrorMessage('childrenAmount', errors, touched)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {childrenAmounts?.map((i) =>
                  renderMenuItem(i, coverageAmounts?.children)
                )}
              </Select>
            )}
            <Divider />
            <Box className={classes.subBlockContr}>
              <Typography className={classes.subBlockContribution}>
                Your Contribution
              </Typography>
              <Box className={classes.subBlockContent}>
                {predictContributionLoading ? (
                  <LinearProgress className={classes.contributionLoader} />
                ) : (
                  <Typography className={classes.contribution}>{`${
                    contributionAmount
                      ? getCurrency(contributionAmount.amount, 2)
                      : '$0.00'
                  } per pay period`}</Typography>
                )}
              </Box>
            </Box>
            <SubmitButton disabled={isSubmitting} label="Enroll" />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const useStyles = makeStyles({
  subBlock: { padding: '1rem' },
  subBlockTitle: { color: '#009688', fontSize: '1.3rem' },
  subBlockContent: { paddingLeft: '1rem' },
  contributionLoader: { maxWidth: '200px', marginTop: '1rem' },
  subBlockContr: {
    display: 'flex',
    flexDirection: 'row',
    margin: '30px 0',
  },
  subBlockContribution: {
    marginRight: '20px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.4px',
    color: '#9FA2B4',
  },
  contribution: {
    marginTOp: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#00000',
  },
});

export default VoluntaryLifeForm;
