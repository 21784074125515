import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';

import SectionTitle from 'components/SectionTitle';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import Feedbacker from 'components/Feedbacker';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';

import { CHANGE_PASSWORD_ALERT } from 'constants/feedbackMessages';
import ChangePasswordForm from './ChangePasswordForm';
import ChangeTemporaryPasswordForm from './ChangeTemporaryPasswordForm';

import styles from './styles.module.scss';

interface IChangePasswordProps {
  passwordReset?: boolean;
}

const ChangePassword: React.FC<IChangePasswordProps> = ({ passwordReset }) => {
  const [feedbackerMessage, setFeedbackerMessage] = React.useState<
    string | undefined
  >();
  const [passwordChanged, setPasswordChanged] = React.useState<boolean>(false);

  const setMessageWithDelay = (message: string) => {
    setFeedbackerMessage(message);
    setTimeout(() => setFeedbackerMessage(undefined), 5000);
  };

  const onError = () => {
    setMessageWithDelay('You provided wrong data');
  };

  const onSuccess = () => {
    setPasswordChanged(true);
    setMessageWithDelay('Your password has been successfully changed');
  };

  return (
    <Box className={styles.pageContainer}>
      <Helmet>
        <title>MyBenefitLink | Change Password</title>
      </Helmet>
      <SectionTitle title="Change Password" />
      <Box className={styles.main}>
        <ContentContainer
          title="Creating new password"
          isBox
          withoutMargin
          padding
        >
          <Box className={styles.wrapper}>
            {passwordReset && (
              <SimpleAlert className={styles.alertMessage} type="warning">
                {CHANGE_PASSWORD_ALERT}
              </SimpleAlert>
            )}

            {passwordReset ? (
              <ChangeTemporaryPasswordForm
                onError={onError}
                onSuccess={onSuccess}
              />
            ) : (
              <ChangePasswordForm onError={onError} onSuccess={onSuccess} />
            )}
          </Box>
        </ContentContainer>
        <Feedbacker
          open={!!feedbackerMessage}
          clearFeedback={() => setFeedbackerMessage(undefined)}
          severity={passwordChanged ? 'success' : 'error'}
          feedbackMessage={feedbackerMessage as string}
        />
      </Box>
    </Box>
  );
};

export default ChangePassword;
