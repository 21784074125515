import ExternalOnboardingStep from 'enums/externalOnboardingStep';

export const stepMapping: ExternalOnboardingStep[] = [
  ExternalOnboardingStep.Profile,
  ExternalOnboardingStep.Documents,
  ExternalOnboardingStep.Eligibility,
  ExternalOnboardingStep.Tax,
  ExternalOnboardingStep.Payroll,
  ExternalOnboardingStep.Eeo,
  ExternalOnboardingStep.Signature,
];
