import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Actions as RegistrationActions } from 'redux/reducers/userRegistration/actions';
import { Actions as UserActions } from 'redux/reducers/user/actions';
import { AppStoreInterface } from 'store/app';
import Feedbacker, { FeedbackerSeverity } from 'components/Feedbacker';
import { loadUserDependents } from 'redux/sagas/userRegistration/routines';

import { fromDependentToCreateDependent } from 'mappers/dependents/fromDependentToCreateDependent';
import { fromDependentToUpdateDependent } from 'mappers/dependents/fromDependentToUpdateDependent';
import { fromUserProfileToUserAddress } from 'mappers/employee/fromProfileInterfaceToAddress';

import dependentsDomain from 'api/domains/dependents';
import {
  DependentInterface,
  DependentInterfaceWithoutId,
  DependentAddressInterface,
} from 'types/dependents';
import { UserAddress } from 'types/user';
import ssnToMaskedSsn from 'helpers/ssnToMaskedSnn';
import { Box, Typography } from '@material-ui/core';
import { IMidyearChangeData } from 'types/enrollments';
import AddDependent from './AddDependent';
import styles from './styles.module.scss';

interface DependentsSettingsPropsFromStateInterface {
  actions: {
    createDependent: typeof RegistrationActions.createDependent;
    editDependent: typeof RegistrationActions.editDependent;
    deleteDependent: typeof RegistrationActions.deleteDependent;
    loadUserDependents: typeof loadUserDependents.trigger;
    editUserData: typeof UserActions.editUserData;
  };
  userAddress: UserAddress;
  userFirstName?: string | null;
  userLastName?: string | null;
  dependents: DependentInterface[];
  dependentLoading: boolean;
  requireSsn: string | null;
  eventType: string;
  setStep: (data: IMidyearChangeData) => void;
  setDependentId: (id: string) => void;
  effectiveDate: string;
}

export interface DependentsSettingsStateInterface {
  showAddDependentsModal: boolean;
  dependentIdToDelete?: string;
  editedDependentId?: string;
  ssnEditable: boolean;
  dependentsSameAddress: boolean;
  expandedFormDependentIds: string[];
  feedbackerMessage?: string;
  feedbackerStatus?: FeedbackerSeverity;
  dependentsDifferentAddresses: {
    [dependentId: string]: DependentAddressInterface;
  };
}

export type DependentsAllProps = DependentsSettingsPropsFromStateInterface;

const StepThree: React.FC<DependentsAllProps> = ({
  actions,
  requireSsn,
  dependents,
  userLastName,
  eventType,
  setStep,
  setDependentId,
  effectiveDate,
}) => {
  const [ssnEditable, setSsnEditable] = useState(false);
  // eslint-disable-next-line
  const [expandedFormDependentIds, setExpendedFormDependentsIds] = useState(
    undefined
  );
  const [feedbackerMessage, setFeedbackerMessage] = useState(undefined);
  // eslint-disable-next-line
  const [feedbackerStatus, setFeedbackerStatus] = useState(undefined);
  const [editedRelationTypeId, setEditedRelationTypeId] = useState<
    string | undefined
  >(undefined);
  const [dependentChangedId, setDependentChangedId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    actions.loadUserDependents();
  }, [actions]);

  useEffect(() => {
    if (
      eventType === '2' ||
      eventType === '3' ||
      eventType === '5' ||
      eventType === '6'
    ) {
      const typeId =
        dependents.filter((d) => d.relationshipTypeId !== '2').length !== 0
          ? (dependents.filter((d) => d.relationshipTypeId !== '2')[0]
              ?.relationshipTypeId as string)
          : undefined;
      setEditedRelationTypeId(typeId);
    }
  }, [dependents, eventType]);

  const clearFeedbacker = () => {
    setExpendedFormDependentsIds(undefined);
    setFeedbackerMessage(undefined);
  };

  /*
  const updateFeedbackerMessage = (
    feedbackerMessage: string,
    feedbackerStatus: FeedbackerSeverity
  ) => {
    setFeedbackerMessage(feedbackerMessage,() => {
      setTimeout(this.clearFeedbacker, 5000);
    })
    this.setState({ feedbackerMessage, feedbackerStatus }, () => {
      setTimeout(this.clearFeedbacker, 5000);
    });
  };
  */

  const apiAction = async (
    action: () => Promise<void>,
    errorMessage: string
  ) => {
    try {
      await action();
    } catch (e) {
      console.error(e);
    }
  };

  const createDependent = async (dependent: DependentInterfaceWithoutId) => {
    await apiAction(async () => {
      const { data } = await dependentsDomain.create(
        fromDependentToCreateDependent(dependent)
      );
      setDependentId(data.id);
      setDependentChangedId(data.id);
      actions.createDependent(
        Object.assign(dependent, {
          id: data.id,
          individualId: data.individualId,
          ssn: ssnToMaskedSsn(data.ssn),
        })
      );
    }, 'Error, when trying to create dependent');
  };

  const editDependent = async (dependent: DependentInterface) => {
    await apiAction(async () => {
      await dependentsDomain.update(
        dependent.id,
        fromDependentToUpdateDependent(dependent)
      );

      setDependentId(dependent.id);
      setDependentChangedId(dependent.id);
      actions.editDependent({
        ...dependent,
        ssn: ssnToMaskedSsn(dependent.ssn),
      });
    }, 'Error, when trying to update dependent');
  };

  const addDependent = async (
    dependent: DependentInterface | DependentInterfaceWithoutId
  ) => {
    if (!(dependent as DependentInterface).id) {
      await createDependent(dependent);
    } else {
      await editDependent(dependent as DependentInterface);
    }
    setStep({ step: 4, dependentId: dependentChangedId });
  };

  const toggleSsnEditableWithCb = (cb: (ssnEditable: boolean) => void) => {
    setSsnEditable(!ssnEditable);
  };

  return (
    <Box className={styles.wrapper}>
      <Typography className={styles.stepTextSecondary}>
        {eventType === '1'
          ? "Please enter your child's information:"
          : "Please enter your spouse's information:"}
      </Typography>
      <AddDependent
        effectiveDate={effectiveDate}
        reason={eventType}
        ssnEditable={ssnEditable}
        toggleSsnEditableWithCb={toggleSsnEditableWithCb}
        userLastName={userLastName ?? ''}
        onSubmit={addDependent}
        dependent={dependents.find(
          (d) =>
            editedRelationTypeId &&
            d.relationshipTypeId &&
            +d.relationshipTypeId === +editedRelationTypeId
        )}
        setEditedRelationId={setEditedRelationTypeId}
      />
      <Feedbacker
        open={!!feedbackerMessage && !!feedbackerStatus}
        severity={feedbackerStatus}
        feedbackMessage={feedbackerMessage || ''}
        clearFeedback={clearFeedbacker}
      />
    </Box>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  userFirstName: store.user.firstName,
  userLastName: store.user.lastName,
  userAddress: fromUserProfileToUserAddress(store.userRegistration.profile),
  dependents: store.userRegistration.dependents,
  dependentLoading: store.userRegistration.loading,
  requireSsn: store.user.requireSsn,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators<
    {},
    {
      createDependent: typeof RegistrationActions.createDependent;
      editDependent: typeof RegistrationActions.editDependent;
      deleteDependent: typeof RegistrationActions.deleteDependent;
      loadUserDependents: typeof loadUserDependents.trigger;
      editUserData: typeof UserActions.editUserData;
    }
  >(
    {
      createDependent: RegistrationActions.createDependent,
      editDependent: RegistrationActions.editDependent,
      deleteDependent: RegistrationActions.deleteDependent,
      loadUserDependents: loadUserDependents.trigger,
      editUserData: UserActions.editUserData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
