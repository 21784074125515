import React from 'react';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Backdrop from 'components/Backdrop';
import { useSelector } from 'react-redux';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as fileIcon } from 'assets/file.svg';
import { ILivePlan } from 'types/plans';
import { AppStoreInterface } from 'store/app';
import { convertStringToNumber } from 'helpers/numberHelpers';
import Currency from 'components/Currency';
import CoverageTable from './CoverageTable';
import Disclaimer from './Disclaimer';
import styles from './styles.module.scss';

interface IProps {
  plan: ILivePlan;
  isFileLoading: boolean;
  handleOpenFile: () => Promise<void>;
  employeeId?: string | number | null;
}
/*const getPlansCoverages = async (
  _key: string,
  id: string,
  employeeId: string | number | null
) => {
  const { data } = await plansDomain.getCoverages(id, employeeId);
  return data;
};*/

const GroupLifePlan: React.FC<IProps> = ({
  plan,
  handleOpenFile,
  isFileLoading,
  employeeId,
}) => {
  /*const { isLoading, data: plansCoverages } = useQuery(
    ['plansCoverages', plan.id, employeeId],
    getPlansCoverages,
    {
      refetchOnWindowFocus: false,
    }
  );*/
  const { eoi } = useSelector((state: AppStoreInterface) => state.user);
  const benefitAmount =
    convertStringToNumber(plan.guaranteedAmount.substring(1)) ?? 0;
  const guaranteedAmount =
    convertStringToNumber(plan.guaranteedAmount.substring(1)) ?? 0;
  return (
    <>
      <Backdrop open={isFileLoading} />
      {plan.fileId && (
        <Box className={styles.fileLinkContainer}>
          <SvgIcon
            component={fileIcon}
            width="19"
            height="21"
            viewBox="0 0 19 21"
          />
          <Link className={styles.fileLink} onClick={handleOpenFile}>
            View Benefits Summary
          </Link>
        </Box>
      )}
      <Box className={styles.planNameContainer}>
        <Typography variant="h6" className={styles.title}>
          <PlanIcons planTypeId={Number(plan.planTypeId)} />
          {plan.title}
        </Typography>
        <Typography className={styles.mblComment} variant="subtitle1">
          {plan.mblComment}
        </Typography>
      </Box>
      <TableContainer className={styles.planTableContainer} component={Box}>
        <Table className={styles.planTable}>
          <TableBody>
            <TableRow>
              <TableCell scope="row">Plan Type:</TableCell>
              <TableCell scope="row">{plan.lifeType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Benefit Amount:</TableCell>
              <TableCell scope="row">
                <Currency>{benefitAmount}</Currency>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Guarantee Issue Amount:</TableCell>
              <TableCell scope="row">
                <Currency>{guaranteedAmount}</Currency>
              </TableCell>
            </TableRow>
            <CoverageTable
              plan={plan}
              employeeId={employeeId}
              guaranteeIssue={guaranteedAmount}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={styles.eoiWarning}>
        {eoi && (
          <Typography variant="caption" color="secondary">
            {`Your income is greater than the guaranteed issue amount of ${plan.guaranteedAmount}. To apply for the full benefit amount you are required to complete the Evidence of Insurability (EOI) form.`}
          </Typography>
        )}
      </Box>
      <Disclaimer />
    </>
  );
};

export default GroupLifePlan;
