export const DEFAULT_DATE_FORMAT = 'MM/DD/yyyy';

export const ORIGIN_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.AS_BASE_URL
    : window.location.origin;

export const ORIGIN_URL_WITH_PREFIX =
  process.env.NODE_ENV === 'development'
    ? `${process.env.AS_BASE_URL}/agencysmart`
    : `${window.location.origin}/agencysmart`;

export const WAIVER_OTHER_REASON_VALUE = -1;
