import React from 'react';
import Box from '@material-ui/core/Box';
import * as yup from 'yup';
import { Field, Form, Formik, FormikProps } from 'formik';
import { MenuItem } from '@material-ui/core';
import FormikDatePicker from 'components/Forms/Formik/Datepicker';
import FormikInput from 'components/Forms/Formik/Input';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import { IMidyearChangeData } from 'types/enrollments';
import styles from './styles.module.scss';

interface IProps {
  eventType?: string | null;
  setStep: (data: IMidyearChangeData) => void;
  setEffectiveDate: (effectiveDate: string | null) => void;
  setReasonId: (reasonId: string | null | undefined) => void;
}
interface IStepTwo {
  effectiveDate: string | null;
  reasonId?: string | null;
}

const StepTwo: React.FC<IProps> = ({
  eventType,
  setStep,
  setEffectiveDate,
  setReasonId,
}) => {
  const labels = [
    {
      value: '1',
      label: 'Enter date of birth or date of adoption of your child',
    },
    { value: '2', label: 'Enter date of marriage:' },
    { value: '3', label: "Enter date of your spouse's loss of coverage:" },
    { value: '4', label: "Enter date of your child's loss of coverage:" },
    { value: '5', label: 'Enter date of your divorce / legal separation:' },
    { value: '6', label: ' Enter date of death of your spouse:' },
    {
      value: '7',
      label: 'Enter the effective date of your change:',
    },
    { value: '8', label: 'Enter the effective date of your change:' },
    { value: '9', label: 'Enter the effective date of your change:' },
  ];
  const label = labels.find((l) => l.value === eventType);

  const initialValues: IStepTwo = {
    effectiveDate: null,
    reasonId: '',
  };

  const validationSchema = yup.object().shape({
    effectiveDate: yup.date().nullable().required('Field is required'),
    reasonId:
      eventType === '3' || eventType === '4'
        ? yup.string().required('Field is required')
        : yup.string().nullable(),
  });

  const handleSubmit = async (values: IStepTwo) => {
    setEffectiveDate(values.effectiveDate);
    setReasonId(values.reasonId);
    setStep({
      step: 3,
      reasonId: values.reasonId,
      effectiveDate: values.effectiveDate,
    });
  };

  const getErrorMessage = (
    key: keyof IStepTwo,
    formikProps: FormikProps<IStepTwo>
  ): string | undefined => {
    const { errors, touched } = formikProps;

    const error = errors[key];
    const isTouched = touched[key];
    return isTouched && error ? (error as string) : '';
  };

  const reasonOptions = [
    {
      label: 'Termination of employment',
      value: '1',
    },
    {
      label: 'Reduction in the number of hours of employment',
      value: '2',
    },
    {
      label: "Termination of the other plan's coverage",
      value: '3',
    },
  ];
  return (
    <Box className={styles.wrapper}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik: FormikProps<IStepTwo>) => (
          <Form className={styles.deleteEmployeeModalForm}>
            <FormikDatePicker
              value={formik.values.effectiveDate}
              label={label?.label}
              variant="dialog"
              name="effectiveDate"
              convertToDate
              error={!!getErrorMessage('effectiveDate', formik)}
              helperText={getErrorMessage('effectiveDate', formik)}
            />
            {(eventType === '3' || eventType === '4') && (
              <Field
                component={FormikInput}
                name="reasonId"
                label={
                  eventType === '3'
                    ? "Select the reason for your spouse's loss of coverage:"
                    : "Select the reason for your child's loss of coverage:"
                }
                variant="outlined"
                select
                fullWidth
              >
                <MenuItem
                  value=""
                  disabled
                  className={styles.optionPlaceholder}
                >
                  Select
                </MenuItem>
                {reasonOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    className={styles.option}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            )}
            <SubmitButton
              className={styles.submitBtn}
              disabled={formik.isSubmitting}
              color="primary"
              label="Next"
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default StepTwo;
