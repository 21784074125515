import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { useQuery } from 'react-query';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
  Typography,
  withStyles,
  SvgIcon,
} from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/radioOn.svg';
import { ReactComponent as offIcon } from 'assets/radioOff.svg';
import { ReactComponent as helpIcon } from 'assets/infoIcon.svg';
import ContentBox from 'components/ContentBox/ContentBoxComponent';
import plansDomain from 'api/domains/plans';
import { IMedicalPlan } from 'types/plans';
import { RadioButtonsGroupPropsInterface } from 'components/Forms/RadioGroup/RadioGroupComponent';

import styles from './styles.module.scss';

const getCurrentPlan = async (_: string, id: string) => {
  const { data } = await plansDomain.getOne(id);
  return data;
};
const CustomRadio = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Radio);
const RadioGroupPlanDetails: React.FC<RadioButtonsGroupPropsInterface> = (
  props
) => {
  const { value, onChange, options, name, disableAll } = props;

  const [currentPlanId, setCurrentPlanId] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { data, isLoading } = useQuery(
    ['currentPlanDetails', currentPlanId],
    getCurrentPlan,
    {
      enabled: currentPlanId,
      refetchOnWindowFocus: false,
    }
  );

  const planDetails = data as IMedicalPlan | undefined;

  const handlePopoverOpen = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => {
    setAnchorEl(e.currentTarget);
    setCurrentPlanId(id);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const popoverContentConfig = [
    {
      title: 'Deductible (single / family):',
      value: planDetails?.inDeductible,
    },
    {
      title: 'Coinsurance',
      value: planDetails?.inCoinsurance,
    },
    {
      title: 'Maximum out of pocket (single / family):',
      value: planDetails?.inMoop,
    },
    {
      title: 'Primary Care Physician (PCP) copay:',
      value: planDetails?.inPcpCopay,
    },
    {
      title: 'Rx:',
      value: planDetails?.rxRetail,
    },
  ];

  const popoverOpen = Boolean(anchorEl);

  return (
    <FormControl
      disabled={disableAll}
      className={styles.form}
      component="fieldset"
    >
      <RadioGroup value={value} onChange={handleChange} name={name}>
        {options.map((option) => (
          <Box className={styles.radioContainer}>
            <FormControlLabel
              key={option.value}
              disabled={option.disabled}
              value={option.value}
              label={
                <Typography className={styles.labelRadio}>
                  {option.label}
                </Typography>
              }
              className={
                options.length === 1 ? styles.radioControl : styles.labelRadio
              }
              control={
                <CustomRadio
                  icon={
                    <SvgIcon
                      className={styles.icon}
                      component={offIcon}
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                    />
                  }
                  checkedIcon={
                    <SvgIcon
                      className={styles.icon}
                      component={onIcon}
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                    />
                  }
                />
              }
            />
            <Box>
              <Box
                aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                  handlePopoverOpen(e, option.value)
                }
                onMouseLeave={handlePopoverClose}
                className={styles.box}
              >
                <SvgIcon
                  component={helpIcon}
                  className={styles.icon}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                />
              </Box>
              <Popover
                id="mouse-over-popover"
                className={styles.detailsPopover}
                open={popoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <ContentBox className={styles.helpBubbleContainer}>
                  {!isLoading ? (
                    <>
                      {popoverContentConfig.map(({ title, value }, i) => (
                        <Box
                          key={i}
                          className={styles.helpBubbleMessageContainer}
                        >
                          <Typography
                            variant="subtitle1"
                            className={styles.helpBubbleMessage}
                          >
                            {title}
                          </Typography>
                          <Typography variant="subtitle1">{value}</Typography>
                        </Box>
                      ))}
                    </>
                  ) : null}
                </ContentBox>
              </Popover>
            </Box>
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupPlanDetails;
