import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import ConfirmationModalWithCancel from 'components/Modal/ConfirmationModalWithCancel';
import logo from 'assets/mbllogo.png';
import { UserStoreInterface } from 'store/user';

import storage from 'helpers/storage';
import history from 'helpers/history';
import pickTextColorBasedOnBgColor from 'helpers/textColor';
import { CHANGE_PASSWORD_PATH } from 'constants/routes';
import { IMPERSONATE_TOKEN } from 'constants/storeKeys';
import { ORIGIN_URL } from 'constants/app';
import styles from './styles.module.scss';

type UserStorePickedProps = Pick<
  UserStoreInterface,
  | 'isAuthenticated'
  | 'firstName'
  | 'lastName'
  | 'clientName'
  | 'clientLogo'
  | 'colorConfiguration'
>;

interface IProps extends UserStorePickedProps {
  toggleSidebar: () => void;
  logout: () => void;
  isOnboardingProccess?: boolean;
  isRegistrationProccess?: boolean;
}

const Header: React.FC<IProps> = ({
  isAuthenticated,
  firstName,
  lastName,
  clientName,
  clientLogo,
  toggleSidebar,
  logout,
  isOnboardingProccess,
  isRegistrationProccess,
  colorConfiguration,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setModalOpen(false);
    logout();
  };

  const openLogoutModal = () => {
    handleClose();
    setModalOpen(true);
  };

  const onChangePassword = () => {
    handleClose();
    history.push(CHANGE_PASSWORD_PATH);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popover' : undefined;
  const isImpersonationMode = !!storage.get(IMPERSONATE_TOKEN);
  const textColor = {
    color: pickTextColorBasedOnBgColor(colorConfiguration.header),
  };

  const popover = (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      className={styles.popover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <List>
        <ListItem>
          <Box className={styles.userInitials}>
            {firstName} {lastName}
          </Box>
        </ListItem>
        <ListItem onClick={onChangePassword} button>
          Change Password
        </ListItem>
        <ListItem onClick={openLogoutModal} button>
          Log Out
        </ListItem>
      </List>
    </Popover>
  );

  const burgerIconVisible = matchesSm && isAuthenticated;

  const renderRightPanel = () => {
    const impersonationBlock = isImpersonationMode ? (
      <Box className={styles.impersonationBlock}>
        <Tooltip title="Log out from impersonation mode">
          <Button variant="contained" size="small" onClick={openLogoutModal}>
            Return To My Account
          </Button>
        </Tooltip>
      </Box>
    ) : null;

    if (isOnboardingProccess || isRegistrationProccess) {
      return (
        <>
          <Box className={styles.profileInfo}>
            {impersonationBlock}
            <>
              {clientLogo ? (
                <Box className={styles.logoContainer}>
                  <Box className={styles.logoBox}>
                    <img
                      src={`${ORIGIN_URL}${clientLogo}`}
                      className={styles.clientLogo}
                      alt="client-logo"
                    />
                  </Box>
                </Box>
              ) : (
                <Box className={styles.clientName} style={textColor}>
                  {clientName}
                </Box>
              )}
              <Box className={styles.userBlock}>
                <Box className={styles.firstName} style={textColor}>
                  {firstName} {lastName}
                </Box>
                <Box className={styles.avatarBlock}>
                  <Avatar
                    className={styles.avatar}
                    aria-describedby={id}
                    onClick={handleClick}
                  />
                </Box>
              </Box>
            </>
          </Box>
          {popover}
        </>
      );
    }

    return (
      <>
        <Box className={styles.profileInfo}>
          {impersonationBlock}
          {isAuthenticated && (
            <>
              {clientLogo ? (
                <Box className={styles.logoContainer}>
                  <Box className={styles.logoBox}>
                    <img
                      src={`${ORIGIN_URL}${clientLogo}`}
                      className={styles.clientLogo}
                      alt="client-logo"
                    />
                  </Box>
                </Box>
              ) : (
                <Box className={styles.clientName} style={textColor}>
                  {clientName}
                </Box>
              )}
              <Box className={styles.userBlock}>
                <Box className={styles.firstName} style={textColor}>
                  {firstName} {lastName}
                </Box>
                <Box className={styles.avatarBlock}>
                  <Avatar
                    className={styles.avatar}
                    aria-describedby={id}
                    onClick={handleClick}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
        {popover}
      </>
    );
  };

  return (
    <>
      <AppBar
        style={{ backgroundColor: colorConfiguration.header }}
        position="sticky"
        className={styles.appBar}
      >
        <Toolbar className={styles.headerContainer}>
          {burgerIconVisible && (
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              style={textColor}
              onClick={toggleSidebar}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          )}
          <img src={logo} className={styles.headerLogo} alt="logo" />
          {renderRightPanel()}
        </Toolbar>
      </AppBar>
      <ConfirmationModalWithCancel
        isOpen={modalOpen}
        isConfirm
        handleClose={() => setModalOpen(false)}
        onSubmit={handleLogout}
      >
        <Box>
          <Typography variant="h6">Are you sure?</Typography>
        </Box>
      </ConfirmationModalWithCancel>
    </>
  );
};

export default Header;
