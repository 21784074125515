import React from 'react';
import { Box, SvgIcon, Grid } from '@material-ui/core';
import { ReactComponent as logoIcon } from 'assets/mblLogo.svg';
import styles from './styles.module.scss';

export interface ContentBoxInterface {
  title?: string;
}

const BoxComponent: React.FC<ContentBoxInterface> = (props) => {
  const { children } = props;

  return (
    <Box className={styles.container}>
      <Grid container className={styles.mainGrid}>
        <Grid sm={12} md={7} item className={styles.logoGrid}>
          <SvgIcon
            component={logoIcon}
            className={styles.logoIcon}
            width="417"
            height="292"
            viewBox="0 0 417 292"
            fill="none"
          />
        </Grid>
        <Grid sm={12} md={5} item className={styles.formGrid}>
          <Box className={styles.form}>{children}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BoxComponent;
