import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ReactComponent as deleteIcon } from 'assets/trash.svg';
import { ReactComponent as editIcon } from 'assets/pencil.svg';

import {
  Box,
  SvgIcon,
  TableContainer,
  Typography,
  Link,
  Button,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  IconButton,
} from '@material-ui/core';
import Input from 'components/Forms/Input/InputComponent';

import { ReactComponent as plusIcon } from 'assets/plus.svg';
import { ReactComponent as lookIcon } from 'assets/look.svg';
import SectionTitle from 'components/SectionTitle';
import Backdrop from 'components/Backdrop';
import Feedbacker from 'components/Feedbacker';
import ConfirmationModal from 'components/Modal/ConfirmationModalComponent';
import OrderDirection from 'enums/orderDirection';

import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import reportsDomain from 'api/domains/reports';
import { IReport, IReportData } from 'types/reports';
import { DUPLICATED_HR_REPORT } from 'constants/messages';
import ReportTableSkeleton from './ReportTableSkeleton';
import ReportTable from './ReportTable';
import EditReport from './EditReport';
import AddReport from './AddReport';

import styles from './styles.module.scss';

const Reports: React.FC = () => {
  const [reports, setReports] = React.useState<IReport[]>([]);
  const [reportsIsLoading, setReportsIsLoading] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  const [showedReport, setShowedReport] = React.useState<IReport | undefined>();
  const [showedReportData, setShowedReportData] = React.useState<
    IReportData | undefined
  >();
  const [editedReport, setEditedReport] = React.useState<IReport | undefined>();
  const [addedReport, setAddedReport] = React.useState<boolean>(false);
  const [reportBuffer, setReportBuffer] = React.useState<IReport | undefined>();
  const [
    showDuplicateReportModal,
    setShowDuplicateReportModal,
  ] = React.useState<boolean>(false);

  const [searchValue, setSearchValue] = useState('');
  const [filteredReports, setFilteredReports] = useState<IReport[] | null>(
    null
  );

  useEffect(() => {
    if (reports) {
      setFilteredReports(reports);
    }
  }, [reports]);
  const onEditReport = (report: IReport) => {
    if (report.editable === '0') {
      setReportBuffer(report);
      setShowDuplicateReportModal(true);
      return;
    }
    setEditedReport(report);
  };

  const setTemporaryErrorMessage = (error: string) => {
    setErrorMessage(error);
    setTimeout(() => setErrorMessage(undefined), 5000);
  };

  const fetchReports = React.useCallback(async () => {
    try {
      setReportsIsLoading(true);
      const { data } = await reportsDomain.getList();
      setReports(data);
    } catch (e) {
      console.error(e);
      setTemporaryErrorMessage('Error, when trying to fetch reports');
    } finally {
      setReportsIsLoading(false);
    }
  }, []);

  const fetchReportData = async (
    report: IReport,
    orderBy?: string,
    orderDirection?: OrderDirection
  ) => {
    try {
      setShowBackdrop(true);
      const { data } = await reportsDomain.getOne(
        report.id,
        orderBy,
        orderDirection
      );
      setShowedReportData(data);
      setShowedReport(report);
    } catch (e) {
      console.error(e);
      setTemporaryErrorMessage('Error, when trying to fetch report info');
    } finally {
      setShowBackdrop(false);
    }
  };

  const deleteReportData = async (id: string | number) => {
    try {
      setShowBackdrop(true);
      await reportsDomain.deleteReport(id);
      await fetchReports();
    } catch (e) {
      console.error(e);
      setTemporaryErrorMessage('Cannot delete report');
    } finally {
      setShowBackdrop(false);
    }
  };

  const clearReportData = () => {
    setShowedReport(undefined);
    setShowedReportData(undefined);
  };

  const onReportClick = (report: IReport) => {
    return async () => fetchReportData(report);
  };

  const closeConfirmationModal = () => {
    setShowDuplicateReportModal(false);
    setReportBuffer(undefined);
  };

  const onReportModalSubmit = () => {
    setEditedReport(reportBuffer);
    setShowDuplicateReportModal(false);
  };

  const onDownloadReportError = () => {
    setTemporaryErrorMessage('Server error: Cannot download report.');
  };

  const onUpdateReport = async (reportId: number | string) => {
    setEditedReport(undefined);
    await fetchReports();
    const updatedReport = reports.find(
      (report) => report.id.toString() === reportId.toString()
    );
    if (updatedReport) {
      await fetchReportData(updatedReport);
    }
  };
  const onAddReport = async (reportId: number | string) => {
    try {
      setAddedReport(false);
      await fetchReports();
      setShowBackdrop(true);
      const { data: reportInfo } = await reportsDomain.getList();
      const { data } = await reportsDomain.getOne(reportId);
      const report =
        reportInfo &&
        reportInfo?.find(
          (report) => report.id.toString() === reportId.toString()
        );
      if (data) setShowedReportData(data);
      if (report) setShowedReport(report);
    } catch (e) {
      console.error(e);
      setTemporaryErrorMessage('Error, when trying to fetch report info');
    } finally {
      setShowBackdrop(false);
    }
  };
  const handleSearchReports = (value: string) => {
    if (reports && filteredReports) {
      value = value.toLowerCase().trim();
      const newReports = reports.filter(({ title }) => {
        title = title.toLowerCase();
        return title.includes(value);
      });
      setFilteredReports(newReports);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    handleSearchReports(value);
  };

  return (
    <>
      <Helmet>
        <title>MyBenefitLink | Reports</title>
      </Helmet>
      <Box className={styles.pageContainer}>
        {!!showedReport && !!showedReport ? (
          <ReportTable
            fetchReportData={fetchReportData}
            reportData={showedReportData}
            report={showedReport}
            onBack={clearReportData}
            onDownloadError={onDownloadReportError}
          />
        ) : !!editedReport ? (
          <EditReport
            report={editedReport}
            onBack={async () => {
              await fetchReports();
              setEditedReport(undefined);
            }}
            onUpdate={onUpdateReport}
          />
        ) : addedReport ? (
          <AddReport
            onBack={async () => {
              await fetchReports();
              setAddedReport(false);
            }}
            onUpdate={onAddReport}
          />
        ) : (
          <>
            <SectionTitle title="HR Reports" withoutBottomPadding />
            <Box className={styles.mainContainer}>
              {reportsIsLoading ? (
                <ReportTableSkeleton />
              ) : (
                <>
                  <Box className={styles.searchBlock}>
                    <Box className={styles.inputWrapper}>
                      <Input
                        className={styles.input}
                        inversePositionIcon
                        value={searchValue}
                        name="search"
                        onChange={handleSearchValueChange}
                        placeholder="Search"
                        icon={
                          <SvgIcon
                            component={lookIcon}
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            className={styles.icon}
                          />
                        }
                      />
                    </Box>
                    <Button
                      className={styles.newBtn}
                      onClick={() => setAddedReport(true)}
                      variant="contained"
                      color="primary"
                    >
                      <SvgIcon
                        component={plusIcon}
                        width="7"
                        height="6"
                        viewBox="0 0 7 6"
                        className={styles.iconBtn}
                      />
                      New Report
                    </Button>
                  </Box>
                  <ContentContainer title="Reports">
                    <TableContainer component={Box}>
                      <Table className={styles.reportsTable}>
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>Report Name</TableCell>
                            <TableCell
                              className={styles.actionsCell}
                              align="right"
                            >
                              Actions
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredReports?.length !== 0 ? (
                            filteredReports?.map((report) => (
                              <TableRow key={report.id}>
                                <TableCell />
                                <TableCell component="th" scope="row">
                                  <Link
                                    className={styles.reportName}
                                    onClick={onReportClick(report)}
                                  >
                                    {report.title}
                                  </Link>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={styles.actionsCell}
                                  align="right"
                                >
                                  <>
                                    <IconButton
                                      onClick={() =>
                                        deleteReportData(report.id)
                                      }
                                      component="span"
                                    >
                                      <SvgIcon
                                        component={deleteIcon}
                                        className={styles.icon}
                                        width="19"
                                        height="20"
                                        viewBox="0 0 19 20"
                                      />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => onEditReport(report)}
                                      component="span"
                                    >
                                      <SvgIcon
                                        className={styles.icon}
                                        component={editIcon}
                                        width="19"
                                        height="20"
                                        viewBox="0 0 19 20"
                                      />
                                    </IconButton>
                                  </>
                                </TableCell>

                                <TableCell />
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell />
                              <TableCell colSpan={2} align="center">
                                <Typography className={styles.placeholder}>
                                  No Reports Found
                                </Typography>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </ContentContainer>
                </>
              )}
            </Box>
          </>
        )}
        <Backdrop open={showBackdrop} />
        <Feedbacker
          feedbackMessage={errorMessage || ''}
          open={!!errorMessage}
          clearFeedback={() => setErrorMessage(undefined)}
          severity="error"
        />
        <ConfirmationModal
          isOpen={showDuplicateReportModal}
          onSubmit={onReportModalSubmit}
          handleClose={closeConfirmationModal}
          title="Pay Attention"
        >
          <Typography>{DUPLICATED_HR_REPORT}</Typography>
        </ConfirmationModal>
      </Box>
    </>
  );
};

export default Reports;
