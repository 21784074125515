import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Box,
  withStyles,
  SvgIcon,
} from '@material-ui/core';

import getCurrency from 'helpers/getCurrency';
import { ReactComponent as onIcon } from 'assets/radioOn.svg';
import { ReactComponent as offIcon } from 'assets/radioOff.svg';
import setClassNames from 'classnames';

import styles from './styles.module.scss';

export interface BasicRadioButtonInterface<T = any> {
  value: T;
  label: string;
  disabled?: boolean;
}

export interface RadioButtonsGroupPropsInterface<T = any> {
  options: BasicRadioButtonInterface<T>[];
  value?: T;
  onChange: (value: T) => void | Promise<void>;
  name: string;
  className?: string;
  disableAll?: boolean;
  contribution?: { [key: string]: number };
}
const CustomRadio = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Radio);

const RadioButtonsGroup: React.FC<RadioButtonsGroupPropsInterface> = (
  props
) => {
  const {
    value,
    onChange,
    options,
    className,
    name,
    disableAll,
    contribution,
  } = props;

  const classNames = setClassNames('radio-buttons-group', {
    [className || '']: Boolean(className),
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <FormControl
      disabled={disableAll}
      component="fieldset"
      className={`${classNames} ${styles.form}`}
    >
      <RadioGroup value={value} onChange={handleChange} name={name}>
        {options.map((option) => (
          <Box className={styles.radioContainer}>
            <FormControlLabel
              key={option.value}
              disabled={option.disabled}
              value={option.value}
              label={
                <Typography className={styles.labelRadio}>
                  {option.label}
                </Typography>
              }
              className={options.length === 1 ? styles.radioControl : ''}
              control={
                <CustomRadio
                  icon={
                    <SvgIcon
                      className={styles.icon}
                      component={offIcon}
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                    />
                  }
                  checkedIcon={
                    <SvgIcon
                      className={styles.icon}
                      component={onIcon}
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                    />
                  }
                />
              }
            />
            {contribution &&
            (contribution[option.value] || contribution[option.value] === 0) &&
            options.length > 1 ? (
              <Typography className={styles.amountLabel}>
                {' '}
                Contribution: {getCurrency(contribution[option.value], 2)} per
                pay period
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsGroup;
