import moment from 'moment';
import {
  IEmployeeTerminationEndpointPayload,
  IEmployeeTerminationPayload,
} from 'types/employee';

export const fromTerminateEmployeeToTerminateEmployeeEndpointFormat = (
  payload: IEmployeeTerminationPayload
): IEmployeeTerminationEndpointPayload => {
  return {
    ...payload,
    terminationDate: moment(payload.terminationDate).format('MM/DD/YYYY'),
    disabilityStartDate: payload.disabilityStartDate
      ? moment(payload.disabilityStartDate).format('MM/DD/YYYY')
      : '',
  };
};
