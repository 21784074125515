enum PlanTypeNumeric {
  Medical = 1,
  Dental = 2,
  Live = 3,
  ADD = 4,
  LTD = 6,
  STD = 7,
  Vision = 8,
  StateStd = 9,
  Pension = 10,
  LTC = 11,
  FSA = 12,
  S125 = 13,
  EAP = 15,
  Pediatric = 16,
  HRA = 19,
  HSA = 20,
  VoluntaryLife = 33,
  VoluntaryDental = 32,
  VoluntaryADD = 34,
  VoluntarySTD = 36,
  VoluntaryLTD = 37,
  VoluntaryVision = 38,
}

export default PlanTypeNumeric;
