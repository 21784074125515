import { ISubmitContactForm, IContact } from 'types/contacts';
import api from '../adapter';

export class ContactsDomain {
  get() {
    return api.makeRequest<IContact[]>('/contacts', 'GET');
  }

  submitForm(form: ISubmitContactForm) {
    return api.makeRequest<any>('/contacts', 'POST', form);
  }
}

export default new ContactsDomain();
