import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import ModalWindow from 'components/Modal/ModalWindowComponent';
import Typography from '@material-ui/core/Typography';

import styles from './styles.module.scss';
import PrivacyNotice from './PrivacyNotice';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  logout: () => void;
  accept: () => void;
}

const UpdatePrivacyNotice: React.FC<IProps> = ({
  isOpen,
  handleClose,
  logout,
  accept,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const textColor = {
    color: '#3db9fe',
  };
  const acceptPolicy = () => {
    accept();
    handleClose();
  };
  const modalActions = (
    <Box className={styles.btnBox}>
      <Button
        className={styles.submitBtn}
        variant="contained"
        color="primary"
        onClick={acceptPolicy}
      >
        Continue
      </Button>
      <Button
        className={styles.cancelBtn}
        variant="text"
        color="primary"
        onClick={logout}
      >
        Log Out
      </Button>
    </Box>
  );
  return (
    <ModalWindow
      isOpen={isOpen}
      className={styles.privacyNoticeModal}
      actions={modalActions}
      dividers
      title="Updates to our Privacy Policy"
    >
      <Box className={styles.privacyNoteContainer}>
        <Box mb="1em">
          {/* <Typography variant="h6">Updates to our Privacy Policy</Typography>*/}
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            We're constantly looking for ways to improve the MyBenefitLink
            experience and expand our feature offerings. In the past few years,
            we welcomed a variety of new features to the MyBenefitLink platform,
            and now we're making a few updates to our Privacy Policy to reflect
            these and some other changes.
          </Typography>
        </Box>

        <Box mb="1em">
          <Typography variant="h6">What Changed?</Typography>
          <Typography variant="body1"></Typography>
          <ul>
            <li>
              We added information to explain how data collection works if you
              use MyBenefitLink services.
            </li>
            <li>
              We've added information to clarify how we use your data when you
              interact with MyBenefitLink representatives in certain marketing
              contexts.
            </li>
          </ul>
        </Box>
        <Box mb="1em">
          <Typography variant="body1">
            Please look at the full{' '}
            <a
              className={styles.privacyBtn}
              style={textColor}
              rel="noreferrer"
              target="_blank"
              onClick={() => setModalOpen(true)}
            >
              Privacy Policy
            </a>
            .{' '}
            <b>
              By continuing to use MyBenefitLink, you are agreeing to the new
              terms
            </b>
            .
          </Typography>
        </Box>
      </Box>
      <PrivacyNotice
        isOpen={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
    </ModalWindow>
  );
};

export default UpdatePrivacyNotice;
