import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles, Typography } from '@material-ui/core';
import { ReactComponent as onIcon } from 'assets/radioOn.svg';
import { ReactComponent as offIcon } from 'assets/radioOff.svg';
import { IModifiedFormQuestion } from 'types/additionalInfo';
import styles from './styles.module.scss';

interface IProps {
  question: IModifiedFormQuestion;
  value: string | number | true;
  onChange: (_: any, value: string) => void;
  error: boolean;
  helperText?: string;
}
const CustomRadio = withStyles({
  root: {
    color: 'transparent',
    '&$checked': {
      color: 'transparent',
    },
    '&$disabled': {
      color: 'transparent',
    },
  },
  checked: {},
  disabled: {},
})(Radio);
const RadioGroupField: React.FC<IProps> = ({
  error,
  helperText,
  value,
  question,
  onChange,
}) => {
  return (
    <Box mb="1em">
      <FormControl component="fieldset" error={error}>
        <FormLabel component="legend" className={styles.label}>
          {question.userPrompt}
        </FormLabel>
        <RadioGroup value={value} onChange={onChange}>
          {question.options &&
            question.options.map((option, idx) => (
              <FormControlLabel
                key={idx}
                value={option.id}
                control={
                  <CustomRadio
                    icon={
                      <SvgIcon
                        component={offIcon}
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                      />
                    }
                    checkedIcon={
                      <SvgIcon
                        component={onIcon}
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                      />
                    }
                  />
                }
                label={
                  <Typography
                    className={
                      value === option.id ? styles.labelOn : styles.labelOff
                    }
                  >
                    {option.text}
                  </Typography>
                }
                color="primary"
              />
            ))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default RadioGroupField;
