import React, { useState, useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import { push } from 'connected-react-router';
import { NavLink, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as yup from 'yup';

import BoxComponent from 'components/LoginBox/BoxComponent';
import { Typography } from '@material-ui/core';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import FormikPasswordInput from 'components/Forms/Formik/PasswordInput';
import FormikInput from 'components/Forms/Formik/Input';
import Feedbacker from 'components/Feedbacker';
import getUrlSearchParam from 'helpers/urlSearchParams';
import ISecretQuestion from 'models/ISecretQuestion';
import storage from 'helpers/storage';
import userDomain from 'api/domains/user';
import { LOGIN_PATH } from 'constants/routes';
import { AppStoreInterface } from 'store/app';
import { RESET_PWD_TOKEN } from 'constants/storeKeys';
import * as validationHelper from 'helpers/validation';
import * as feedbackMessages from 'constants/feedbackMessages';
import styles from './styles.module.scss';

interface IFormValues {
  answer: string;
  password: string;
  reEnteredPassword: string;
}

const validationSchema = yup.object().shape({
  answer: yup.string().required('Field is required'),
  password: validationHelper.password,
  reEnteredPassword: validationHelper.reEnteredPassword,
});

interface IProps {
  pathname: string;
  search: string;
  push: (path: string) => void;
}

const ResetPassword: React.FC<IProps> = ({ search, pathname, push }) => {
  const initialValues: IFormValues = {
    answer: '',
    password: '',
    reEnteredPassword: '',
  };
  const [question, setQuestion] = useState<ISecretQuestion>();
  const [resetFeedback, setResetFeedback] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const tokenRef = useRef<string>();
  const history = useHistory();
  const token = getUrlSearchParam(search, 'token');
  tokenRef.current = token;

  useEffect(() => {
    if (tokenRef.current) {
      storage.set(RESET_PWD_TOKEN, tokenRef.current);
    }
    push(pathname);
    return () => {
      storage.remove(RESET_PWD_TOKEN);
    };
  }, [pathname, push]);

  useEffect(() => {
    (async () => {
      const token = storage.get(RESET_PWD_TOKEN);
      if (token) {
        try {
          const { data } = await userDomain.getSecretQuestion({
            token,
          });
          setQuestion(data);
        } catch (err) {
          setResetFeedback(feedbackMessages.INVALID_TOKEN);
        }
      } else {
        setResetFeedback(feedbackMessages.INVALID_TOKEN);
      }
    })();
  }, []);

  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    const { password, answer } = values;
    const token = storage.get(RESET_PWD_TOKEN) as string;
    const body = { token, password, answer };

    try {
      await userDomain.resetPassword(body);
      setResetFeedback(feedbackMessages.PASSWORD_CHANGED);
      setSuccess(true);
      history.push('/');
    } catch (err) {
      formikHelpers.setFieldError('answer', feedbackMessages.WRONG_ANSWER);
    }
  };

  const isPasswordChanged = resetFeedback === feedbackMessages.PASSWORD_CHANGED;
  const formClasses = question
    ? styles.form
    : `${styles.form} ${styles.disabledForm}`;

  const formVisible = { display: !success ? 'block' : 'none' };

  return (
    <BoxComponent>
      <Helmet>
        <title>MyBenefitLink | Reset Password</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik: FormikProps<IFormValues>) => (
          <Form style={formVisible} className={formClasses}>
            <Typography className={styles.title}>Forgot password</Typography>
            {question && (
              <>
                <Box className={styles.question}>
                  <Typography className={styles.labelQuestion}>
                    Secret Question
                  </Typography>
                  <Typography className={styles.textQuestion}>
                    {question.text}
                  </Typography>
                </Box>
                <Field
                  component={FormikInput}
                  label="Answer"
                  name="answer"
                  fullWidth
                  className={styles.formItem}
                />
              </>
            )}
            <Field
              component={FormikPasswordInput}
              name="password"
              label="New password"
              fullWidth
              className={styles.formItem}
            />
            <Field
              component={FormikPasswordInput}
              name="reEnteredPassword"
              label="Reenter password"
              fullWidth
              className={styles.formItem}
            />
            <SubmitButton
              disabled={formik.isSubmitting}
              label="Submit"
              className={styles.btn}
            />
            <Box>
              <NavLink to={LOGIN_PATH} className={styles.link}>
                Back to login
              </NavLink>
            </Box>
          </Form>
        )}
      </Formik>
      <Feedbacker
        open={!!resetFeedback}
        clearFeedback={() => setResetFeedback(null)}
        severity={isPasswordChanged ? 'success' : 'error'}
        feedbackMessage={resetFeedback as string}
        displayInTheMiddle={false}
      />
    </BoxComponent>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  search: store.router.location.search,
  pathname: store.router.location.pathname,
});

export default connect(mapStateToProps, { push })(ResetPassword);
