import React from 'react';

import IBasicOption from 'models/IBasicOption';
import getOptionLabelByValue from 'helpers/getOptionLabelByValue';
import { IEmployee, IEmployeePersonal } from 'types/employee';
import { IProfileCard } from 'types/profileCard';
import { EMPTY_VALUE } from 'constants/placeholders';
import { getDateWithAgeString } from 'helpers/dateHelpers';
import ProfileCardItem from './ProfileCardItem';

interface IProps {
  employee: IEmployee | null;
  personalData: IEmployeePersonal | null;
  maritalStatus: IBasicOption<number>[];
  gender: IBasicOption<number>[];
  employmentStatus: IBasicOption<number>[];
  handleHidePersonalData: () => void;
  handleOpenModal: () => void;
  handleShowPersonalData: (password: string) => Promise<void>;
  isPasswordPrompt: boolean;
}

const ProfileCardList: React.FC<IProps> = (props) => {
  const {
    employee,
    personalData,
    maritalStatus,
    employmentStatus,
    gender,
    isPasswordPrompt,
    handleHidePersonalData,
    handleShowPersonalData,
    handleOpenModal,
  } = props;

  const {
    maritalStatusId,
    employmentId,
    birthDate,
    officePhone,
    workHours,
    homePhone,
    firstName,
    hireDate,
    lastName,
    address1,
    address2,
    gender: genderId,
    state,
    title,
    email,
    city,
    zip,
    employmentType,
  } = employee || {};

  const cityStateZip = `${city || EMPTY_VALUE}, ${state || EMPTY_VALUE}, ${
    zip || EMPTY_VALUE
  }`;

  const cards: IProfileCard[] = [
    {
      title: 'Personal Information',
      rows: [
        {
          name: 'Full Name:',
          value: `${firstName} ${lastName}`,
        },
        {
          name: 'Date Of Birth:',
          value: birthDate && getDateWithAgeString(birthDate),
        },
        {
          name: 'Gender:',
          value: getOptionLabelByValue(gender, genderId),
        },
        {
          name: 'Marital Status:',
          value: getOptionLabelByValue(maritalStatus, maritalStatusId),
        },
        {
          name: 'Social Security Number:',
          value: personalData ? personalData.ssn || EMPTY_VALUE : null,
          isSecured: true,
        },
      ],
    },
    {
      title: 'Contact Information',
      rows: [
        {
          name: 'Home Address:',
          value: `${address1 || ''} ${address2 || ''}`,
        },
        {
          name: 'City, ST, Zip:',
          value: cityStateZip,
        },
        {
          name: 'Home Telephone:',
          value: homePhone || EMPTY_VALUE,
        },
        {
          name: 'Work Telephone:',
          value: officePhone || EMPTY_VALUE,
        },
        {
          name: 'Email Address:',
          value: email || EMPTY_VALUE,
        },
      ],
    },
    {
      title: 'Employment Information',
      rows: [
        {
          name: 'Employment:',
          value: getOptionLabelByValue(employmentStatus, employmentId),
        },
        {
          name: 'Job Title:',
          value: title || EMPTY_VALUE,
        },
        {
          name: 'Date Of Hire:',
          value: hireDate || EMPTY_VALUE,
        },
        {
          name: 'Work Hours Per Week:',
          value: workHours || EMPTY_VALUE,
        },
        {
          name:
            employmentType && +employmentType === 2 ? 'Wage/hour:' : 'Salary:',
          value: personalData ? personalData.income || EMPTY_VALUE : null,
          isSecured: true,
        },
      ],
    },
  ];

  return (
    <>
      {cards.map((card, i) => (
        <ProfileCardItem
          key={i}
          personalData={personalData}
          handleHidePersonalData={handleHidePersonalData}
          handleOpenModal={handleOpenModal}
          handleShowPersonalData={handleShowPersonalData}
          isPasswordPrompt={isPasswordPrompt}
          card={card}
        />
      ))}
    </>
  );
};

export default ProfileCardList;
