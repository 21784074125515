import {
  MIN_PASSWORD_LENGTH,
  GOOD_PASSWORD_LENGTH,
  NUMBERS,
  SPECIAL_CHARS,
} from '../constants/password';

export interface PasswordStreigthRuleInterface {
  rule: (password: string) => boolean;
  points: number;
}

const haveUpperCase = (password: string): boolean => {
  return password !== password.toLowerCase();
};

const checkLength = (length: number) => (password: string): boolean =>
  password.length >= length;

const haveChars = (chars: string[]) => (password: string) => {
  for (const char of chars) {
    if (password.includes(char)) {
      return true;
    }
  }
  return false;
};

const rules: PasswordStreigthRuleInterface[] = [
  {
    rule: haveUpperCase,
    points: 20,
  },
  {
    rule: haveChars(NUMBERS),
    points: 20,
  },
  {
    rule: checkLength(MIN_PASSWORD_LENGTH),
    points: 20,
  },
  {
    rule: checkLength(GOOD_PASSWORD_LENGTH),
    points: 20,
  },
  {
    rule: haveChars(SPECIAL_CHARS),
    points: 20,
  },
];

export function passwordStrength(password: string): number {
  let streigthPoints = 0;

  if (password.length < MIN_PASSWORD_LENGTH) {
    return streigthPoints;
  }

  rules.forEach((rule) => {
    if (rule.rule(password)) {
      streigthPoints += rule.points;
    }
  });

  return streigthPoints;
}
