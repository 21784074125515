import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import PlanIcons from 'components/PlanIcons/PlanIcons';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as fileIcon } from 'assets/file.svg';
import Backdrop from 'components/Backdrop';
import { IDentalPlan } from 'types/plans';
import Disclaimer from './Disclaimer';
import CoverageTable from './CoverageTable';

import styles from './styles.module.scss';

interface IProps {
  plan: IDentalPlan;
  handleOpenFile: () => Promise<void>;
  isFileLoading: boolean;
  employeeId?: string | number | null;
}

const GroupDentalPlan: React.FC<IProps> = ({
  plan,
  handleOpenFile,
  isFileLoading,
  employeeId,
}) => {
  return (
    <>
      <Backdrop open={isFileLoading} />
      {plan.fileId && (
        <Box className={styles.fileLinkContainer}>
          <SvgIcon
            component={fileIcon}
            width="19"
            height="21"
            viewBox="0 0 19 21"
          />
          <Link className={styles.fileLink} onClick={handleOpenFile}>
            View Benefits Summary
          </Link>
        </Box>
      )}
      <Box className={styles.planNameContainer}>
        <Typography variant="h6" className={styles.title}>
          <PlanIcons planTypeId={Number(plan.planTypeId)} />
          {plan.title}
        </Typography>
        <Typography className={styles.mblComment} variant="subtitle1">
          {plan.mblComment}
        </Typography>
      </Box>
      <TableContainer className={styles.planTableContainer} component={Box}>
        <Table className={styles.planTable}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <Box className={styles.titleBox}>In Network</Box>
              </TableCell>
              <TableCell>
                <Box className={styles.titleBox}>Out Of Network</Box>
              </TableCell>
              <TableCell>
                <Box className={styles.titleBox}>Waiting Period</Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell scope="row">Deductible (single / family):</TableCell>
              <TableCell scope="row">
                {plan.deductibleSingleIn} / {plan.deductibleFamilyIn}
              </TableCell>
              <TableCell scope="row">
                {plan.deductibleSingleOut} / {plan.deductibleFamilyOut}
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell scope="row">Annual maximum:</TableCell>
              <TableCell scope="row">{plan.annualMaxIn}</TableCell>
              <TableCell scope="row">{plan.annualMaxOut}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell scope="row">Preventive/Diag.:</TableCell>
              <TableCell scope="row">{plan.diagnosticIn}</TableCell>
              <TableCell scope="row">{plan.diagnosticOut}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell scope="row">Basic Services:</TableCell>
              <TableCell scope="row">{plan.basicIn}</TableCell>
              <TableCell scope="row">{plan.basicOut}</TableCell>
              <TableCell scope="row">{plan.basicWait}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Major Services:</TableCell>
              <TableCell scope="row">{plan.majorIn}</TableCell>
              <TableCell scope="row">{plan.majorOut}</TableCell>
              <TableCell scope="row">{plan.majorWait}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Periodontics:</TableCell>
              <TableCell scope="row">{plan.periodonticsIn}</TableCell>
              <TableCell scope="row">{plan.periodonticsOut}</TableCell>
              <TableCell scope="row">{plan.periodonticsWait}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Endodontics:</TableCell>
              <TableCell scope="row">{plan.endodonticsIn}</TableCell>
              <TableCell scope="row">{plan.endodonticsOut}</TableCell>
              <TableCell scope="row">{plan.endodonticsWait}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Orthodontics:</TableCell>
              <TableCell scope="row">{plan.orthoIn}</TableCell>
              <TableCell scope="row">{plan.orthoOut}</TableCell>
              <TableCell scope="row">{plan.orthoWait}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Orthodontic Max:</TableCell>
              <TableCell scope="row">{plan.orthoMaxIn}</TableCell>
              <TableCell scope="row">{plan.orthoMaxOut}</TableCell>
              <TableCell scope="row">{plan.orthoMaxType}</TableCell>
            </TableRow>
            <CoverageTable plan={plan} employeeId={employeeId} />
          </TableBody>
        </Table>
      </TableContainer>
      <Disclaimer />
    </>
  );
};

export default GroupDentalPlan;
