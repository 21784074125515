import moment from 'moment';
import {
  IEditEmployeePayload,
  IEditEmployeeEndpointFormat,
  IAddEmployeePayload,
  IAddEmployeeEndpointFormat,
} from 'types/employee';

export const fromEditEmployeeToEditEmployeeEndpointFormat = (
  payload: IEditEmployeePayload | IAddEmployeePayload
): IEditEmployeeEndpointFormat | IAddEmployeeEndpointFormat => {
  return {
    ...payload,
    birthDate: payload.birthDate
      ? moment(payload.birthDate).format('MM/DD/YYYY')
      : null,
    hireDate: moment(payload.hireDate).format('MM/DD/YYYY'),
  };
};
