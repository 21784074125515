import React from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Divider,
  IconButton,
  Box,
  Grid,
  SvgIcon,
} from '@material-ui/core';
import { ReactComponent as deleteIcon } from 'assets/trash.svg';
import { ReactComponent as editIcon } from 'assets/pencil.svg';
import classNames from 'classnames';
import femaleIcon from 'assets/female.png';
import maleIcon from 'assets/male.png';
import DependentStatus from 'components/DependentStatus';
import { DependentInterface } from 'types/dependents';
import IBasicOption from 'models/IBasicOption';

import Gender from 'enums/gender';
import { AppStoreInterface } from 'store/app';
import { validateDependentsFields } from 'helpers/validateDependents';

import styles from './styles.module.scss';

export interface DependentItemPropsInterface {
  dependent: DependentInterface;
  onEditClick?: () => void | Promise<void>;
  onDeleteClick?: () => void | Promise<void>;
}

interface DependentItemPropsFromStateInterface {
  relationshipOptions: IBasicOption[];
}

type AllProps = DependentItemPropsInterface &
  DependentItemPropsFromStateInterface;

const DependentItem: React.FC<AllProps> = (props) => {
  const { dependent, onEditClick, onDeleteClick, relationshipOptions } = props;
  const { errors, alerts, isValid } = validateDependentsFields(dependent);
  const relation = relationshipOptions.find((option) => {
    if (dependent.relationshipTypeId) {
      return option.value === +dependent.relationshipTypeId;
    }
    return false;
  });

  const getGenderIcon = () => {
    if (!dependent.gender) return null;
    return (
      <img
        className={styles.genderIcon}
        src={+dependent.gender === Gender.Male ? maleIcon : femaleIcon}
        alt={+dependent.gender === Gender.Male ? 'Male' : 'Female'}
      />
    );
  };
  const getBottomBorder = () => {
    if (dependent.relationshipType === 'Child') {
      if (!dependent.gender) return null;
      return +dependent.gender === Gender.Male
        ? styles.borderMale
        : styles.borderFemale;
    }
    return styles.borderDefault;
  };
  return (
    <Box className={styles.dependentItemWrapper} onClick={onEditClick}>
      <Box
        className={`${classNames(styles.dependentItem, {
          [styles.dependentItemWithError]: !isValid,
        })} ${getBottomBorder()} `}
      >
        <Grid container className={styles.itemData}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              className={styles.name}
            >{`${dependent.firstName} ${dependent.lastName}`}</Typography>
            {relation && (
              <Typography variant="subtitle1" className={styles.type}>
                {relation.label}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} className={styles.genderIconContainer}>
            {getGenderIcon()}
          </Grid>
        </Grid>
        <Divider />
        <Box className={styles.itemControl}>
          {onDeleteClick && onEditClick && (
            <Box>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteClick();
                }}
              >
                <SvgIcon
                  component={deleteIcon}
                  className={styles.controlIcon}
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                />
              </IconButton>
              <IconButton>
                <SvgIcon
                  component={editIcon}
                  className={styles.controlIcon}
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                />
              </IconButton>
            </Box>
          )}
          <Box>
            <DependentStatus errors={errors} alerts={alerts} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (store: AppStoreInterface) => ({
  relationshipOptions: store.options.relationshipType,
});

export default connect(mapStateToProps)(DependentItem);
