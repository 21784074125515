import React from 'react';
import Box from '@material-ui/core/Box';

import { IAuditLogDetails } from 'types/auditLogs';
import LogComparison from './LogComparison';

import styles from './styles.module.scss';

interface IProps {
  logDetails: IAuditLogDetails;
}

const LogDetailsFactory: React.FC<IProps> = ({ logDetails }) => {
  if (logDetails.data) {
    return <LogComparison logDetails={logDetails} />;
  } else {
    return (
      <Box className={styles.placeholder}>
        There are no additional details available for this log entry
      </Box>
    );
  }
};

export default LogDetailsFactory;
