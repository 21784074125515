import React from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Divider, SvgIcon } from '@material-ui/core';
import { ReactComponent as backIcon } from 'assets/back.svg';
import Footer from 'components/Footer';
import { AppStoreInterface } from 'store/app';
import { UserStoreInterface } from 'store/user';

import styles from './styles.module.scss';

type UserStorePickedProps = Pick<
  UserStoreInterface,
  'isAuthenticated' | 'agencyName' | 'agencyLogo' | 'colorConfiguration'
>;

export interface FullScreenWindowProps extends UserStorePickedProps {
  title: string;
  handleClose: () => void | Promise<void>;
  open: boolean;
}

const FullScreenWindow: React.FC<FullScreenWindowProps> = (props) => {
  const {
    handleClose,
    title,
    isAuthenticated,
    agencyLogo,
    agencyName,
    colorConfiguration,
  } = props;

  return (
    <div className={styles.window}>
      <Box className={styles.box}>
        <Box className={styles.title}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <SvgIcon
              component={backIcon}
              className={styles.icon}
              width="22"
              height="10"
              viewBox="0 0 22 10"
            />
          </IconButton>
          <Typography className={styles.titleWindow}>{title}</Typography>
        </Box>
        <Divider />
        <Box className={styles.body}>{props.children}</Box>

        <Box className={styles.footer}>
          <Footer
            colorConfiguration={colorConfiguration}
            isAuthenticated={isAuthenticated}
            agencyName={agencyName}
            agencyLogo={agencyLogo}
          />
        </Box>
      </Box>
    </div>
  );
};
const mapStateToProps = (store: AppStoreInterface) => ({
  isAuthenticated: store.user.isAuthenticated,
  agencyName: store.user.agencyName,
  agencyLogo: store.user.agencyLogo,
  colorConfiguration: store.user.colorConfiguration,
});

export default connect(mapStateToProps)(FullScreenWindow);
