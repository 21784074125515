import { IEmployeeFile } from 'types/files';
import api from '../adapter';

export class FileDomain {
  uploadFile(file: FormData) {
    return api.makeRequest<IEmployeeFile>('/files', 'POST', file, {
      'Content-type': 'multipart/form-data',
    });
  }

  uploadFileByEmployeeId(id: string, file: FormData) {
    return api.makeRequest<IEmployeeFile>(
      `/employees/${id}/files`,
      'POST',
      file,
      {
        'Content-type': 'multipart/form-data',
      }
    );
  }

  signature(body: { signature: string }) {
    return api.makeRequest<any>('/forms', 'POST', body);
  }

  signatureMidChanges(body: { signature: string }) {
    return api.makeRequest<any>('/mid-changes', 'POST', body);
  }

  getFiles() {
    return api.makeRequest<IEmployeeFile[]>('/files', 'GET');
  }

  getFilesByEmployeeId(id: string) {
    return api.makeRequest<IEmployeeFile[]>(
      `/employees/${id}/files`,
      'GET',
      undefined,
      undefined,
      {
        type: 'personal',
      }
    );
  }

  downloadFile(id: string) {
    return api.makeRequest<Blob>(
      `/files/${id}/download`,
      'GET',
      null,
      undefined,
      null,
      { responseType: 'blob' }
    );
  }
}

export default new FileDomain();
