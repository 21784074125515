import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import PrivacyNotice from 'components/Modal/PrivacyNotice';
import pickTextColorBasedOnBgColor from 'helpers/textColor';
import { UserStoreInterface } from 'store/user';
import { ORIGIN_URL } from 'constants/app';

import styles from './styles.module.scss';

type UserStorePickedProps = Pick<
  UserStoreInterface,
  'isAuthenticated' | 'agencyName' | 'agencyLogo' | 'colorConfiguration'
>;

const Footer: React.FC<UserStorePickedProps> = ({
  agencyName,
  agencyLogo,
  isAuthenticated,
  colorConfiguration,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const textColor = {
    color: pickTextColorBasedOnBgColor(colorConfiguration.footer),
  };

  return (
    <Paper
      style={{ backgroundColor: colorConfiguration.footer }}
      elevation={3}
      className={styles.footer}
    >
      <p className={styles.footerCopyright} style={textColor}>
        {`© ${new Date().getFullYear()} AgencySmart, LLC ${
          isAuthenticated ? '|' : ''
        }`}
      </p>
      {isAuthenticated && (
        <Button
          disableRipple
          className={styles.privacyBtn}
          style={textColor}
          variant="text"
          onClick={() => setModalOpen(true)}
        >
          PRIVACY NOTICE
        </Button>
      )}
      {isAuthenticated && (
        <>
          {agencyLogo ? (
            <Box className={styles.logoContainer}>
              <img
                src={`${ORIGIN_URL}${agencyLogo}`}
                className={styles.footerLogo}
                alt="footer-logo"
              />
            </Box>
          ) : (
            <Box className={styles.agencyName} style={textColor}>
              {agencyName}
            </Box>
          )}
        </>
      )}
      {isAuthenticated && (
        <PrivacyNotice
          isOpen={modalOpen}
          handleClose={() => setModalOpen(false)}
        />
      )}
    </Paper>
  );
};

export default Footer;
