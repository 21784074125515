import React from 'react';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import { ILivePlan } from 'types/plans';
import { Box, Typography } from '@material-ui/core';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import PlanTypeNumeric from 'enums/planTypeNumeric';

import styles from './styles.module.scss';

export interface ILifePlanCompareTableProps {
  plans: ILivePlan[];
  planId?: string | number;
  onChange?: (planId: string) => void;
}

const LifePlanCompareTable: React.FC<ILifePlanCompareTableProps> = ({
  plans,
  planId,
  onChange,
}) => {
  const mapPropToCell = (title: string, key: keyof ILivePlan) => {
    return (
      <TableRow>
        <TableCell className={styles.lifetableCell}>{title}</TableCell>
        {plans.map((plan) => (
          <TableCell
            className={`${plan.id === planId ? `${styles.myPlanCell}` : ''} ${
              styles.lifetableCell
            }`}
          >
            {plan[key]}
          </TableCell>
        ))}
      </TableRow>
    );
  };
  const mapPropToTitle = (title: string, key: keyof ILivePlan) => {
    return (
      <TableRow>
        <TableCell className={styles.medicalTitleCell}>{title}</TableCell>
        {plans.map((plan) => (
          <TableCell
            className={`${plan.id === planId ? `${styles.myPlanCell}` : ''} ${
              styles.lifeRowCell
            }`}
          >
            <Typography variant="h6" className={styles.title}>
              <PlanIcons planTypeId={PlanTypeNumeric.Live} />
              {plan[key]}
            </Typography>
            {plan.id !== planId && onChange && (
              <Box className={styles.wrapper}>
                <SubmitButton
                  label="Select"
                  variant="contained"
                  onClick={() => onChange(plan.id)}
                  className={styles.btn}
                />
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const mapMyPlan = () => {
    return (
      <TableRow>
        <TableCell />
        {plans.map((plan) => (
          <TableCell
            className={plan.id === planId ? `${styles.myPlanCell}` : ''}
          >
            <Typography variant="h6" className={styles.myPlan}>
              {plan.id === planId ? 'My plan' : ''}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const renderButtonSelect = () => {
    return onChange ? (
      <TableRow>
        <TableCell />
        {plans.map((plan) => (
          <TableCell
            className={plan.id === planId ? `${styles.myPlanCell}` : ''}
          >
            {plan.id !== planId && onChange && (
              <Box className={styles.wrapper}>
                <SubmitButton
                  label="Select"
                  variant="contained"
                  onClick={() => onChange(plan.id)}
                  className={styles.btn}
                />
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    ) : (
      <></>
    );
  };

  const checkMblComment = () => {
    return plans.filter((el) => el.mblComment !== null).length !== 0;
  };

  return (
    <TableContainer className={styles.planTableContainer} component={Box}>
      <Table className={styles.planTable}>
        <TableHead>
          {planId && mapMyPlan()}
          {mapPropToTitle('', 'title')}
        </TableHead>
        <TableBody>
          {mapPropToCell('Plan Type:', 'lifeType')}
          {mapPropToCell('Benefit Amount:', 'benefitAmount')}
          {mapPropToCell('Guaranteed Issue Amount:', 'guaranteedAmount')}
          {checkMblComment() && mapPropToCell('Plan Highlights:', 'mblComment')}
          {renderButtonSelect()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LifePlanCompareTable;
