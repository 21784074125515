import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { FormLabel, Typography } from '@material-ui/core';
import FormikInput from 'components/Forms/Formik/Input';
import FormikPasswordInput from 'components/Forms/Formik/PasswordInput';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import CheckBox from 'components/Forms/Checkbox/CheckboxComponent';
import Box from '@material-ui/core/Box';
import Feedbacker from 'components/Feedbacker';
import ILoginCredentials from 'models/ILoginCredentials';
import { FORGOT_PASSWORD_PATH } from 'constants/routes';
import { SESSION_EXPIRED } from 'constants/feedbackMessages';
import { Actions as UserActions } from 'redux/reducers/user/actions';
import * as validationHelper from 'helpers/validation';

import styles from './styles.module.scss';

interface IFormValues {
  username: string;
  password: string;
}
interface IProps {
  login: (payload: ILoginCredentials) => void;
  clearFeedback: typeof UserActions.clearFeedback;
  logining: boolean;
  error: string | null;
}

const validationSchema = yup.object().shape({
  username: yup.string().required('Field is required'),
  password: validationHelper.passwordLogin,
});

const LoginForm: React.FC<IProps> = ({
  login,
  clearFeedback,
  logining,
  error,
}) => {
  const [isPublicComputer, setIsPublicComputer] = useState(false);
  const initialValues: IFormValues = {
    username: '',
    password: '',
  };

  const handleSubmit = (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    login(values);
    formikHelpers.setSubmitting(false);
  };

  return (
    <Box className={styles.loginContainer}>
      <Typography className={styles.welcomeText}>Welcome back</Typography>
      <Typography className={styles.loginText}>
        Login to your account
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className={styles.form}>
          <Field
            component={FormikInput}
            fullWidth
            name="username"
            type="text"
            label="Email or Username"
            className={styles.formItem}
          />
          <FormLabel component="legend" className={styles.passwordLabel}>
            <Typography className={styles.password}>Password</Typography>
            <NavLink to={FORGOT_PASSWORD_PATH} className={styles.link}>
              Forgot password?
            </NavLink>
          </FormLabel>

          <Field
            withoutLabel
            component={FormikPasswordInput}
            fullWidth
            name="password"
          />
          <CheckBox
            label="This is a public computer"
            name="isPublicComputer"
            onChange={(checked) => setIsPublicComputer(checked)}
            checked={isPublicComputer}
            className={styles.formItem}
          />
          <SubmitButton
            label="Login"
            disabled={logining}
            className={styles.btn}
          />
        </Form>
      </Formik>
      <Feedbacker
        open={!!error}
        autoHideDuration={error !== SESSION_EXPIRED ? 5000 : null}
        clearFeedback={() => clearFeedback({ error: null })}
        severity={error === SESSION_EXPIRED ? 'info' : 'error'}
        feedbackMessage={error as string}
        displayInTheMiddle={false}
      />
    </Box>
  );
};

export default LoginForm;
