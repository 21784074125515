import React from 'react';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as fileIcon } from 'assets/file.svg';
import Box from '@material-ui/core/Box';
import Backdrop from 'components/Backdrop';
import Link from '@material-ui/core/Link';
import { IFsaPlan } from 'types/plans';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import Currency from 'components/Currency';
import Disclaimer from './Disclaimer';
import CoverageTable from './CoverageTable';
import styles from './styles.module.scss';

interface IProps {
  plan: IFsaPlan;
  isFileLoading: boolean;
  handleOpenFile: () => Promise<void>;
  employeeId?: string | number | null;
}

const GroupDentalPlan: React.FC<IProps> = ({
  plan,
  handleOpenFile,
  isFileLoading,
  employeeId,
}) => {
  return (
    <>
      <Backdrop open={isFileLoading} />
      {plan.fileId && (
        <Box className={styles.fileLinkContainer}>
          <SvgIcon
            component={fileIcon}
            width="19"
            height="21"
            viewBox="0 0 19 21"
          />
          <Link className={styles.fileLink} onClick={handleOpenFile}>
            View Summary of Benefits and Coverage (SBC)
          </Link>
        </Box>
      )}
      <Box className={styles.planNameContainer}>
        <Typography variant="h6" className={styles.title}>
          <PlanIcons planTypeId={Number(plan.planTypeId)} />
          {plan.title}
        </Typography>
        <Typography className={styles.mblComment} variant="subtitle1">
          {plan.mblComment}
        </Typography>
      </Box>
      <TableContainer className={styles.planTableContainer} component={Box}>
        <Table className={styles.planTable}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Box className={styles.titleBox}>Type</Box>
              </TableCell>
              <TableCell>
                <Box className={styles.titleBox}>
                  {employeeId
                    ? 'Employee Annual Contribution'
                    : 'Your Annual Contribution'}
                </Box>
              </TableCell>
              <TableCell>
                <Box className={styles.titleBox}>Contribution Limit</Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plan.fsaMed && (
              <TableRow>
                <TableCell scope="row">Health</TableCell>
                <TableCell scope="row">
                  <Currency>{plan.fsaContributionAmounts?.med}</Currency>
                </TableCell>
                <TableCell scope="row">{plan.fsaMedEeLimit}</TableCell>
              </TableRow>
            )}
            {plan.fsaDep && (
              <TableRow>
                <TableCell scope="row">Dependent Care</TableCell>
                <TableCell scope="row">
                  <Currency>{plan.fsaContributionAmounts?.dep}</Currency>
                </TableCell>
                <TableCell scope="row">{plan.fsaDepEeLimit}</TableCell>
              </TableRow>
            )}
            {plan.fsaTra && (
              <TableRow>
                <TableCell scope="row">Transit</TableCell>
                <TableCell scope="row">
                  <Currency>{plan.fsaContributionAmounts?.tra}</Currency>
                </TableCell>
                <TableCell scope="row">{plan.fsaTraEeLimit}</TableCell>
              </TableRow>
            )}
            {plan.fsaPark && (
              <TableRow>
                <TableCell scope="row">Qualified Parking</TableCell>
                <TableCell scope="row">
                  <Currency>{plan.fsaContributionAmounts?.park}</Currency>
                </TableCell>
                <TableCell scope="row">{plan.fsaParkEeLimit}</TableCell>
              </TableRow>
            )}
            {(plan.fsaGracePeriod || plan.fsaRollover) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Box className={styles.titleBox}>Plan Information</Box>
                </TableCell>
              </TableRow>
            )}
            {plan.fsaGracePeriod && (
              <TableRow>
                <TableCell scope="row">Grace Period</TableCell>
                <TableCell scope="row" colSpan={2}>
                  {plan.fsaGracePeriod}
                </TableCell>
              </TableRow>
            )}
            {plan.fsaRollover && (
              <TableRow>
                <TableCell scope="row">Rollover</TableCell>
                <TableCell scope="row" colSpan={2}>
                  <Currency>{plan.fsaRollover}</Currency>
                </TableCell>
              </TableRow>
            )}
            <CoverageTable plan={plan} employeeId={employeeId} />
          </TableBody>
        </Table>
      </TableContainer>
      <Disclaimer />
    </>
  );
};

export default GroupDentalPlan;
