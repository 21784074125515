import React, { forwardRef } from 'react';
import MaterialTable, { MaterialTableProps, Column } from 'material-table';
import { SvgIcon, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as deleteIcon } from 'assets/trash.svg';
import { ReactComponent as editIcon } from 'assets/pencil.svg';
import { ReactComponent as helpIcon } from 'assets/sidebar/open.svg';
import { ReactComponent as lookIcon } from 'assets/look.svg';
import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Box from '@material-ui/core/Box';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import styles from './styles.module.scss';
/**
 * List of default icons for table
 */
const tableIcons = {
  Add: forwardRef<any>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<any>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<any>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<any>((props, ref) => (
    <SvgIcon
      component={deleteIcon}
      width="19"
      height="20"
      viewBox="0 0 19 20"
      {...props}
      ref={ref}
    />
  )),
  DetailPanel: forwardRef<any>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef<any>((props, ref) => (
    <SvgIcon
      component={editIcon}
      width="19"
      height="20"
      viewBox="0 0 19 20"
      {...props}
      ref={ref}
    />
  )),
  Export: forwardRef<any>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<any>((props, ref) => (
    <SvgIcon component={lookIcon} {...props} ref={ref} />
  )),
  FirstPage: forwardRef<any>((props, ref) => (
    <FirstPage {...props} ref={ref} style={{ color: '#9FA2B4' }} />
  )),
  LastPage: forwardRef<any>((props, ref) => (
    <LastPage {...props} ref={ref} style={{ color: '#4979C0' }} />
  )),
  NextPage: forwardRef<any>((props, ref) => (
    <ChevronRight {...props} ref={ref} style={{ color: '#4979C0' }} />
  )),
  PreviousPage: forwardRef<any>((props, ref) => (
    <ChevronLeft {...props} ref={ref} style={{ color: '#9FA2B4' }} />
  )),
  ResetSearch: forwardRef<any>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<any>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<any>((props, ref) => (
    <SvgIcon
      component={helpIcon}
      className={styles.icon}
      width="12"
      height="6"
      viewBox="0 0 12 6"
      {...props}
      ref={ref}
    />
  )),
  ThirdStateCheck: forwardRef<any>((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef<any>((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

export type OmitedMaterialTableProps<RowData extends object> = Omit<
  MaterialTableProps<RowData>,
  'icons'
>;

export type TableColumn<RowData extends object> = Column<RowData>;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-footer': {
      padding: '5px 0 !important',
      border: 'none',
      '& .MuiTypography-caption': {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '16px',
        color: '#9FA2B4',
      },
      '& div': {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '16px',
        color: '#252733',
      },
      '& .MuiButtonBase-root': {
        [theme.breakpoints.down('sm')]: {
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
        },
      },
      '& .MuiInputBase-root': {
        '& .MuiSelect-icon': {
          color: '#4979C0 !important',
        },
      },
    },
    '& .MuiPaper-root': {
      border: 'none',
      boxShadow: 'none !important',
    },
    '& .MuiTableRow-root': {
      '&:not(:first-child)': {
        '& .MuiTableCell-root': {
          minWidth: '50px',
        },
        '& div': {
          justifyContent: 'flex-end',
          alignItems: 'center',
        },
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.2px',
        color: '#252733',
      },
      '&:first-child': {
        '& .MuiTableCell-root': {
          verticalAlign: 'baseline',
        },
      },
    },
    '& .MuiTableCell-root': {
      padding: '25px 20px 25px 0',
      '&:last-child': {
        padding: '25px 0',
      },
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-head': {
        padding: '15px 20px 15px 0',
        '&:last-child': {
          padding: '15px 0',
        },
      },
      '& svg': {
        opacity: 1,
      },
      '& .MuiSvgIcon-root': {
        width: '11px !important',
        height: '7px !important',
      },
      '& .MuiButtonBase-root': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.2px',
        color: '#9FA2B4',
      },
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    miwWidth: '100px',
    alignItems: 'center',
  },
  icon: {
    width: '11px',
    height: '7px',
    marginLeft: '4px',
    userSelect: 'none',
    marginRight: '4px',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
  },
}));

const TableComponent: React.FC<OmitedMaterialTableProps<any>> = <
  RowData extends object
>(
  props: OmitedMaterialTableProps<RowData>
) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MaterialTable
        localization={{
          header: {
            actions: (
              <Box className={classes.actions}>
                <Typography className={classes.title}>Actions</Typography>
              </Box>
            ),
          },
        }}
        icons={tableIcons}
        {...props}
      />
    </div>
  );
};

export default TableComponent;
