import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { AppStoreInterface } from 'store/app';

import history from 'helpers/history';
import user from './user/reducer';
import userRegistration from './userRegistration/reducer';
import options from './options/reducer';
import onboarding from './onboarding/reducer';

const appReducer = combineReducers<AppStoreInterface>({
  router: connectRouter(history),
  user,
  userRegistration,
  options,
  onboarding,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;
