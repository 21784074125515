import React from 'react';
import Box from '@material-ui/core/Box';

import ModalWindowComponent, {
  ModalWindowPropsInterface,
} from 'components/Modal/ModalWindowComponent';
import PlansFactory from 'components/PlansFactory';
import PlanSkeleton from 'components/PlansFactory/PlanSkeleton';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import Feedbacker from 'components/Feedbacker';

import plansDomain from 'api/domains/plans';
import { Plan } from 'types/plans';
import styles from './styles.module.scss';

export interface PlanSummaryModalProps
  extends Pick<ModalWindowPropsInterface, 'isOpen' | 'handleClose'> {
  planId?: string;
  employeeId?: string | number | null;
}

const PlanSummaryModal: React.FC<PlanSummaryModalProps> = (props) => {
  const { planId, employeeId, ...modalProps } = props;
  const [loading, setLoading] = React.useState(false);
  const [plan, setPlan] = React.useState<Plan>();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const clearMessage = () => setErrorMessage(undefined);

  const setTempErrorMessage = (error: string) => {
    setErrorMessage(error);
    setTimeout(clearMessage, 5000);
  };

  const fetchPlan = React.useCallback(
    async () => {
      if (!planId) return;
      try {
        setLoading(true);
        const { data } = await plansDomain.getOne(planId, employeeId);
        const { data: customFields } = await plansDomain.getCustomFields(
          planId,
          employeeId
        );
        setPlan({ ...data, customFields });
      } catch (e) {
        console.error(e);
        setTempErrorMessage('Error, when trying to fetch plan info');
        if (modalProps.handleClose) modalProps.handleClose();
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [setLoading, setPlan, planId]
  );
  React.useEffect(() => {
    fetchPlan();
  }, [fetchPlan]);

  const renderActions = () => {
    return (
      <SubmitButton
        className={styles.btn}
        onClick={modalProps.handleClose}
        label="Close"
        variant="contained"
      />
    );
  };

  return (
    <>
      <ModalWindowComponent
        dividers
        title="Plan Summary"
        actions={renderActions()}
        {...modalProps}
      >
        <Box className={styles.box}>
          {loading ? (
            <PlanSkeleton />
          ) : plan ? (
            <PlansFactory plan={plan as Plan} employeeId={employeeId} />
          ) : null}
        </Box>
      </ModalWindowComponent>
      <Feedbacker
        severity="error"
        open={!!errorMessage}
        clearFeedback={clearMessage}
        feedbackMessage={errorMessage || ''}
      />
    </>
  );
};

export default PlanSummaryModal;
