import React from 'react';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as fileIcon } from 'assets/file.svg';
import Backdrop from 'components/Backdrop';
import { IVisionPlan } from 'types/plans';
import Disclaimer from './Disclaimer';
import CoverageTable from './CoverageTable';

import styles from './styles.module.scss';

interface IProps {
  plan: IVisionPlan;
  handleOpenFile: () => Promise<void>;
  isFileLoading: boolean;
  employeeId?: string | number | null;
}

const GroupMedicalPlan: React.FC<IProps> = ({
  plan,
  handleOpenFile,
  isFileLoading,
  employeeId,
}) => {
  return (
    <>
      <Backdrop open={isFileLoading} />
      {plan.fileId && (
        <Box className={styles.fileLinkContainer}>
          <SvgIcon
            component={fileIcon}
            width="19"
            height="21"
            viewBox="0 0 19 21"
          />
          <Link className={styles.fileLink} onClick={handleOpenFile}>
            View Benefits Summary
          </Link>
        </Box>
      )}
      <Box className={styles.planNameContainer}>
        <Typography variant="h6" className={styles.title}>
          <PlanIcons planTypeId={Number(plan.planTypeId)} />
          {plan.title}
        </Typography>
        <Typography className={styles.mblComment} variant="subtitle1">
          {plan.mblComment}
        </Typography>
      </Box>
      <TableContainer className={styles.planTableContainer} component={Box}>
        <Table className={styles.planTable}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <Box className={styles.titleBox}>Frequency</Box>
              </TableCell>
              <TableCell>
                <Box className={styles.titleBox}>Copay</Box>
              </TableCell>
              <TableCell>
                <Box className={styles.titleBox}>In-Network Allowance</Box>
              </TableCell>
              <TableCell>
                <Box className={styles.titleBox}>Out-of-Network Allowance</Box>
              </TableCell>
              <TableCell>
                <Box className={styles.titleBox}>Deductible</Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell scope="row">Exam:</TableCell>
              <TableCell scope="row">{plan.examFrequency}</TableCell>
              <TableCell scope="row">{plan.examCopay}</TableCell>
              <TableCell scope="row">{plan.examAllowanceIn}</TableCell>
              <TableCell scope="row">{plan.examAllowanceOut}</TableCell>
              <TableCell scope="row">{plan.examDeductible}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Lenses:</TableCell>
              <TableCell scope="row">{plan.lensesFrequency}</TableCell>
              <TableCell scope="row">{plan.lensesCopay}</TableCell>
              <TableCell scope="row">{plan.lensesAllowanceIn}</TableCell>
              <TableCell scope="row">{plan.lensesAllowanceOut}</TableCell>
              <TableCell scope="row">{plan.lensesDeductible}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Frames:</TableCell>
              <TableCell scope="row">{plan.framesFrequency}</TableCell>
              <TableCell scope="row">{plan.framesCopay}</TableCell>
              <TableCell scope="row">{plan.framesAllowanceIn}</TableCell>
              <TableCell scope="row">{plan.framesAllowanceOut}</TableCell>
              <TableCell scope="row">{plan.framesDeductible}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Contacts:</TableCell>
              <TableCell scope="row">{plan.contactsFrequency}</TableCell>
              <TableCell scope="row">{plan.contactsCopay}</TableCell>
              <TableCell scope="row">{plan.contactsAllowanceIn}</TableCell>
              <TableCell scope="row">{plan.contactsAllowanceOut}</TableCell>
              <TableCell scope="row">{plan.contactsDeductible}</TableCell>
            </TableRow>
            <CoverageTable plan={plan} employeeId={employeeId} />
          </TableBody>
        </Table>
      </TableContainer>
      <Disclaimer />
    </>
  );
};

export default GroupMedicalPlan;
