const ssnToMaskedSsn = (ssn?: string) => {
  if (!ssn) return;
  const splitedSsn = ssn.split('-');
  splitedSsn[0] = 'XXX';
  splitedSsn[1] = 'XX';

  return splitedSsn.join('-');
};

export default ssnToMaskedSsn;
