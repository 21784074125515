import { CustomField, OnboardingFile } from 'types/onboarding';

export interface OnboardingStoreInterface {
  custom: {
    id: string | null;
    title: string;
    comment: string;
    includeInRegistration: boolean;
    requireFileUpload: boolean;
    requireFileView: boolean;
    requireAcknowledgment: boolean;
    requireSignature: boolean;
    requireApproval: boolean;
    files: OnboardingFile[];
    customFields: CustomField[];
  };
  loading: boolean;
}

export const initOnboardingStore = (): OnboardingStoreInterface => ({
  custom: {
    id: null,
    title: '',
    comment: '',
    includeInRegistration: false,
    requireFileUpload: false,
    requireFileView: false,
    requireAcknowledgment: false,
    requireSignature: false,
    requireApproval: false,
    customFields: [],
    files: [],
  },
  loading: false,
});
