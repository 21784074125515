import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import UploadIcon from '@material-ui/icons/CloudUpload';

import Feedbacker from 'components/Feedbacker';
import fileDomain from 'api/domains/file';
import bytesToMegabytes from 'helpers/bytesToMegabytes';
import { FILE_SIZE_LIMIT } from 'constants/file';
import styles from './styles.module.scss';

interface Props {
  errorMessage?: string;
  setIsUploaded?: (isUploaded: boolean) => void;
  setIsUploadedError?: (message: string) => void;
  setFileId?: (id: string) => void;
  setFileName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const FileUploader: React.FC<Props> = ({
  errorMessage,
  setIsUploaded,
  setIsUploadedError,
  setFileId,
  setFileName,
}) => {
  const [error, setError] = useState('');
  const [successFeedback, setSuccessFeedback] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const selectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files && files[0];

    if (file) {
      if (file.size > FILE_SIZE_LIMIT) {
        setError(
          `File size should be less than ${bytesToMegabytes(FILE_SIZE_LIMIT)}mb`
        );
      } else {
        setError('');
        await upload(file);
      }
    }
  };

  const upload = async (file: File) => {
    let formData = new FormData();
    formData.append('filename', file);
    try {
      setLoading(true);
      const { data } = await fileDomain.uploadFile(formData);
      setSuccessFeedback('File uploaded successfully!');
      if (setIsUploaded && setIsUploadedError) {
        setIsUploaded(true);
        setIsUploadedError('');
      }
      if (setFileId) {
        setFileId(data.id);
      }
      setFileName(data.title);
    } catch (err) {
      setError('Server error');
      if (setIsUploaded) {
        setIsUploaded(false);
      }
      setFileName(undefined);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <label className={styles.uploadLabel} htmlFor="upload-file">
        <input
          className={styles.hiddenInput}
          id="upload-file"
          name="upload-file"
          type="file"
          onChange={selectFile}
        />

        <Button
          variant="contained"
          color="default"
          component="span"
          startIcon={<UploadIcon />}
          disabled={loading}
        >
          Upload
        </Button>
        {error && (
          <Typography variant="subtitle2" color="secondary">
            {error}
          </Typography>
        )}
        {!!errorMessage && (
          <Typography variant="subtitle2" color="secondary">
            {errorMessage}
          </Typography>
        )}
      </label>

      <Feedbacker
        open={!!successFeedback}
        autoHideDuration={5000}
        clearFeedback={() => setSuccessFeedback(null)}
        severity="success"
        feedbackMessage={successFeedback as string}
      />
    </>
  );
};

export default FileUploader;
