enum FormCustomOnboardingFieldType {
  Text = 'text',
  Textarea = 'textarea',
  Date = 'date',
  CheckBox = 'checkbox',
  Dropdown = 'dropdown',
  Amount = 'amount',
  Phone = 'phone',
  Ssn = 'ssn',
  Email = 'email',
  State = 'state',
  Zip = 'zip',
}

export default FormCustomOnboardingFieldType;
