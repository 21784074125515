import React from 'react';
import setClassName from 'classnames';
import { Typography, Box, SvgIcon } from '@material-ui/core';
import { ReactComponent as helpIcon } from 'assets/infoIcon.svg';
import PopoverComponent from 'components/Popper/PopoverComponent';
import ContentBox from 'components/ContentBox/ContentBoxComponent';

import styles from './styles.module.scss';

export interface BlockWithHelpBubleProps {
  moreInfo: string;
  className?: string;
  iconClass?: string;
}

const BlockWithHelpBuble: React.FC<BlockWithHelpBubleProps> = (props) => {
  const { moreInfo, className, iconClass, children } = props;

  const classNames = setClassName(styles.blockWithHelpBuble, {
    [className || '']: Boolean(className),
  });

  const iconClassNames = setClassName(styles.helpIcon, {
    [iconClass || '']: Boolean(iconClass),
  });

  const helpBubble = (
    <ContentBox className={styles.helpBubbleMessage}>
      <Typography display="block" className={styles.infoBox}>
        {moreInfo}
      </Typography>
    </ContentBox>
  );

  const popover = (
    <PopoverComponent popoverMessage={helpBubble}>
      <SvgIcon component={helpIcon} className={iconClassNames} />
    </PopoverComponent>
  );

  return (
    <Box className={classNames}>
      {children}
      {popover}
    </Box>
  );
};

export default BlockWithHelpBuble;
