import React, { ChangeEvent, FocusEvent } from 'react';
import Box from '@material-ui/core/Box';
import MuiTextField from '@material-ui/core/TextField';
import {
  createStyles,
  FormLabel,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core';

interface IProps {
  label: string;
  helperText?: string;
  variant?: 'filled' | 'outlined' | 'standard' | undefined;
  error: boolean;
  value: string | number | true;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
}

const CustomTextField = withStyles({
  root: {
    '& ::placeholder': {
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#9FA2B4',
      opacity: 0.8,
    },
    '& .Mui-error': {
      '& .MuiOutlinedInput-input': {
        border: '1px solid #E51212',
        borderRadius: '5px',
        backgroundColor: '#FFE3E3',
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4979C0',
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      '& fieldset': {
        borderColor: '#E1E2E7',
      },
      '&:hover fieldset': {
        borderColor: '#4979C0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #4979C0',
      },
    },
  },
})(MuiTextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: 0,
    },
    input: {
      marginTop: '10px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#252733',
    },
  })
);

const TextField: React.FC<IProps> = ({
  label,
  value,
  error,
  onChange,
  onBlur,
  helperText,
}) => {
  const inputClasses = useStyles();
  return (
    <Box mb="1em">
      <FormLabel className={inputClasses.label} component="legend">
        {label}
      </FormLabel>
      <CustomTextField
        fullWidth
        className={inputClasses.input}
        variant="outlined"
        placeholder={`Enter ${label ?? ''}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
      />
    </Box>
  );
};

export default TextField;
