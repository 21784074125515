import React from 'react';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {
  makeStyles,
  createStyles,
  Theme,
  InputBase,
  withStyles,
  SvgIcon,
  Box,
} from '@material-ui/core';

import { ReactComponent as selectIcon } from 'assets/select.svg';

export interface SelectPropsInterface extends SelectProps {
  helperText?: string;
  size?: 'small' | 'medium';
  typeInput?: string;
}
const CustomInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '10px',
      width: '100%',
    },
    input: {
      padding: '17px 14px',
      borderRadius: '4px',
      position: 'relative',
      border: '1px solid #E1E2E7',
      '& .Mui-error': {
        '& fieldset': {
          border: '1px solid #E51212',
        },
      },
      '&:focus': {
        background: 'transparent',
        borderRadius: '4px',
        border: '1px solid #4979C0',
      },
      '&:hover': {
        background: 'transparent',
        borderRadius: '4px',
        border: '1px solid #4979C0',
      },
    },
  })
)(InputBase);

const CustomSelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'auto',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#3C4257',
    },
  })
)(MuiSelect);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    control: {
      marginBottom: '10px',
    },
    cssOutlinedInputInfo: {
      '&:not(hover):not($disabled):not($cssFocused):not($error) .MuiSelect-outlined': {
        borderColor: '#90B9F5',
      },
    },
    errorInput: {
      '& .MuiSelect-outlined': {
        border: '1px solid #E51212',
        background: '#FFE3E3',
      },
    },
    icon: {
      position: 'absolute',
      right: '0',
      marginTop: '10x',
      marginRight: '25px',
      display: 'inline-block',
      width: '15px',
      height: '7px',
      cursor: 'pointer',
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#252733',
    },
    dropdownStyle: {
      maxHeight: '150px',
      '& .MuiList-padding': {
        padding: 0,
      },
      borderRadius: '4px',
      '& .Mui-selected': {
        borderRadius: '4px',
        background: '#A8E0FF',
      },
    },
    placeholder: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#9FA2B4',
    },
  })
);
const Select: React.FC<SelectPropsInterface> = (props) => {
  const {
    label,
    placeholder,
    children,
    error,
    helperText,
    size,
    hidden,
    typeInput,
    ...rest
  } = props;
  const classes = useStyles();

  const getStyle = (): string | undefined => {
    if (!typeInput) return '';
    switch (typeInput) {
      case 'info':
        return classes.cssOutlinedInputInfo;
      default:
        return '';
    }
  };
  return !hidden ? (
    <>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <FormControl
        fullWidth
        variant="outlined"
        size={size}
        error={error}
        className={classes.control}
      >
        <CustomSelect
          inputProps={{
            classes: {
              root: getStyle(),
            },
          }}
          placeholder={placeholder ?? `Choose ${label}`}
          IconComponent={() => (
            <SvgIcon
              className={classes.icon}
              component={selectIcon}
              width="11"
              height="6"
              viewBox="0 0 11 6"
            />
          )}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            classes: { paper: classes.dropdownStyle },
          }}
          renderValue={
            rest.value !== ''
              ? undefined
              : () => (
                  <Box className={classes.placeholder}>
                    {placeholder ?? `Choose ${label}`}
                  </Box>
                )
          }
          displayEmpty
          input={
            <CustomInput
              className={
                error
                  ? classes.errorInput
                  : typeInput === 'info'
                  ? classes.cssOutlinedInputInfo
                  : ''
              }
            />
          }
          {...rest}
        >
          {children}
        </CustomSelect>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </>
  ) : (
    <></>
  );
};

export default Select;
