import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import IOnboardingStepProps from 'models/IOnboardingStepProps';
import { OnboardingFile } from 'types/onboarding';
import { AppStoreInterface } from 'store/app';
import onboardingDomain from 'api/domains/onboarding';
import File from './File';

import styles from './styles.module.scss';

const SummaryStep: React.FC<IOnboardingStepProps> = ({
  index,
  count,
  setIsLoading,
  moveForward,
}) => {
  const { newHire, openEnrollmentEndDate } = useSelector(
    (state: AppStoreInterface) => state.user
  );

  const isEnrollmentsOpen = () => {
    if (openEnrollmentEndDate) {
      return new Date(openEnrollmentEndDate) > new Date();
    }
    return false;
  };

  const finishBtnText =
    isEnrollmentsOpen() || newHire
      ? 'Continue to Benefit Enrollment'
      : 'Get Started with MyBenefitLink';

  const [files, setFiles] = useState<OnboardingFile[]>([]);

  useEffect(() => {
    (async () => {
      try {
        await setIsLoading(true);
        const { data } = await onboardingDomain.getGeneratedFiles();
        setFiles(data);
      } catch (e) {
        console.error(e);
        await setIsLoading(false);
      } finally {
        await setIsLoading(false);
      }
    })();
  }, [setIsLoading]);

  return (
    <ContentTableBox title="Signature" index={index} count={count} isBox>
      <Box className={styles.summaryContainerBlock}>
        <Typography className={styles.summaryMessage} variant="h3" gutterBottom>
          Congratulations!
        </Typography>
        <Typography
          className={styles.summaryMessageAlign}
          variant="h5"
          gutterBottom
        >
          You have successfully completed onboarding process in MyBenefitLink
        </Typography>
        <Box className={styles.fileBlock}>
          {files.length > 0 && (
            <>
              {files.map((file, i) => (
                <File key={`fileToDownload-${i}`} file={file} />
              ))}
            </>
          )}
        </Box>
      </Box>
      <Box className={styles.summaryContainerButton}>
        <SubmitButton label={finishBtnText} onClick={moveForward} />
      </Box>
    </ContentTableBox>
  );
};

export default SummaryStep;
