import IBasicOption from 'models/IBasicOption';
import ISystemOption from 'models/ISystemOption';

export function fromSystemOptionToBasicOption(
  systemOption: ISystemOption
): IBasicOption<number> {
  return {
    label: systemOption.text,
    value: +systemOption.id,
  };
}
export function fromSystemOptionToBasicOptionString(
  systemOption: ISystemOption
): IBasicOption<string> {
  return {
    label: systemOption.text,
    value: systemOption.id as string,
  };
}
