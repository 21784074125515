import React, { useState } from 'react';
import { Box, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { IProfileCard } from 'types/profileCard';

import { EMPTY_VALUE } from 'constants/placeholders';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import PersonalInfo from 'components/PersonalInfo';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import ConfirmationModalWithCancel from 'components/Modal/ConfirmationModalWithCancel';

import styles from './styles.module.scss';

interface IProps {
  card: IProfileCard;
  button?: React.ReactNode;
  handleHidePersonalData: () => void;
  handleOpenModal: () => void;
  handleShowPersonalData: (password: string) => Promise<void>;
  isPasswordPrompt: boolean;
  hideSsn: boolean;
}

const EmployeeCardItem: React.FC<IProps> = ({
  card,
  button,
  handleHidePersonalData,
  handleOpenModal,
  handleShowPersonalData,
  isPasswordPrompt,
  hideSsn,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const unlockSsn = () =>
    isPasswordPrompt ? handleOpenModal() : handleShowPersonalData('');
  const renderSsn = (ssn?: string) => {
    if (!ssn) {
      return (
        <Typography className={styles.value} variant="body2">
          {EMPTY_VALUE}
        </Typography>
      );
    }
    return (
      <PersonalInfo
        isLocked={hideSsn}
        onLockIconClick={unlockSsn}
        personalInfo={ssn}
        handleHidePersonalData={handleHidePersonalData}
      />
    );
  };
  return (
    <ContentContainer title={card.title} button={button} isBox>
      <Box className={styles.employeeCardContainer}>
        {card.rows.map((row, i) => (
          <Box className={styles.infoContainer} key={i}>
            {i !== 0 && <Divider />}
            <Box className={styles.infoRow}>
              <Typography className={styles.label}>
                {row.name === 'link' ? '' : row.name}
              </Typography>
              {row.name === 'Social Security Number:' ? (
                renderSsn(row.value as string)
              ) : row.name === 'link' ? (
                <Box className={styles.linkBox}>
                  <Link className={styles.link} onClick={() => setIsOpen(true)}>
                    Registration Link
                  </Link>
                  <SubmitButton
                    label="Copy"
                    variant="contained"
                    onClick={() =>
                      navigator.clipboard.writeText(row.value ?? '')
                    }
                    className={styles.btn}
                  />
                  <ConfirmationModalWithCancel
                    isOpen={!!isOpen}
                    handleClose={() => setIsOpen(false)}
                    onSubmit={() => window.location.replace(row.value ?? '')}
                  >
                    <Typography variant="h6">
                      Are you sure you want to register as this employee? This
                      will end your current session.
                    </Typography>
                  </ConfirmationModalWithCancel>
                </Box>
              ) : (
                <Typography className={styles.value}>{row.value}</Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </ContentContainer>
  );
};

export default EmployeeCardItem;
