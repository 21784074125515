import React from 'react';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import { Box, Typography } from '@material-ui/core';
import { MEDICAL_OUT_OF_NETWORK } from 'constants/messages';
import PlanFileLink from 'components/FileLink/PlanFileLink';
import { IMedicalPlan } from 'types/plans';
import styles from './styles.module.scss';

interface IProps {
  plans: IMedicalPlan[];
  planId?: string | number;
  onChange?: (planId: string) => void;
}

interface ISubHeaderProps {
  title: string;
  plans: IMedicalPlan[];
  planMapping?: (plan: IMedicalPlan) => React.ReactNode;
  planId?: string | number;
  onChange?: (planId: string) => void;
}

interface IPlansFilesProps {
  plans: IMedicalPlan[];
  planId?: string | number;
}

const BigCell: React.FC<TableCellProps> = (tableCellProps) => {
  return (
    <TableCell className={styles.tableCell} {...tableCellProps} colSpan={2} />
  );
};

const PlansFiles: React.FC<IPlansFilesProps> = ({ plans, planId }) => {
  return (
    <TableRow>
      <TableCell />
      {plans.map((plan) => (
        <BigCell
          className={`${plan.id === planId ? `${styles.myPlanCell}` : ''} ${
            styles.fileBox
          }`}
        >
          <PlanFileLink
            plan={plan}
            linkLabel="View Summary of Benefits and Coverage (SBC)"
          />
        </BigCell>
      ))}
    </TableRow>
  );
};

const SubHeader: React.FC<ISubHeaderProps> = ({
  plans,
  title,
  planMapping,
  planId,
}) => {
  return (
    <TableRow className={styles.subHeaderRow}>
      <TableCell className={`${styles.medicalTitleCell} ${styles.subHeader}`}>
        {title}
      </TableCell>
      {plans.map((plan) => (
        <BigCell className={plan.id === planId ? `${styles.myPlanCell}` : ''}>
          {planMapping ? planMapping(plan) : ''}
        </BigCell>
      ))}
    </TableRow>
  );
};

const GroupMedicalPlanCompareTable: React.FC<IProps> = ({
  plans,
  planId,
  onChange,
}) => {
  /**
   * This function is rendering plan's info
   * as TableRow
   */
  const mapToCell = (
    title: string,
    mapFunc: (plan: IMedicalPlan) => React.ReactNode
  ) => {
    return (
      <TableRow>
        <TableCell className={styles.medicalTitleCell}>{title}</TableCell>
        {plans.map((plan) => (
          <BigCell className={plan.id === planId ? `${styles.myPlanCell}` : ''}>
            {mapFunc(plan)}
          </BigCell>
        ))}
      </TableRow>
    );
  };

  const mapPropToCell = (title: string, key: keyof IMedicalPlan) => {
    return mapToCell(title, (plan) => plan[key]);
  };

  const mapPropToCellIfExist = (title: string, key: keyof IMedicalPlan) => {
    const isExist = plans.some((plan) => !!plan[key]);
    return isExist ? mapToCell(title, (plan) => plan[key]) : null;
  };

  const renderPropToCell = (
    title: string,
    render: (plan: IMedicalPlan) => React.ReactNode
  ) => {
    return mapToCell(title, render);
  };

  const renderOutOfNetwork = (title: string, key: keyof IMedicalPlan) => {
    return renderPropToCell(title, (plan) =>
      plan.outDeductible ? plan[key] : MEDICAL_OUT_OF_NETWORK
    );
  };

  const renderPharmacyBenefits = (
    title: string,
    retailKey: keyof IMedicalPlan,
    mailKey: keyof IMedicalPlan
  ) => {
    return (
      <TableRow>
        <TableCell className={styles.medicalTitleCell} scope="row">
          {title}
        </TableCell>
        {plans.map((plan) => (
          <>
            <TableCell
              scope="row"
              className={plan.id === planId ? `${styles.myPlanCell}` : ''}
            >
              {plan[retailKey]}
            </TableCell>
            <TableCell
              scope="row"
              className={plan.id === planId ? `${styles.myPlanCell}` : ''}
            >
              {plan[mailKey]}
            </TableCell>
          </>
        ))}
      </TableRow>
    );
  };

  const renderButtonSelect = () => {
    return onChange ? (
      <TableRow>
        <TableCell className={styles.medicalTitleCell} scope="row" />
        {plans.map((plan) => (
          <BigCell className={plan.id === planId ? `${styles.myPlanCell}` : ''}>
            {plan.id !== planId && onChange && (
              <Box className={styles.wrapper}>
                <SubmitButton
                  label="Select"
                  variant="contained"
                  onClick={() => onChange(plan.id)}
                  className={styles.btn}
                />
              </Box>
            )}
          </BigCell>
        ))}
      </TableRow>
    ) : (
      <></>
    );
  };

  return (
    <>
      <TableContainer className={styles.planTableContainer} component={Box}>
        <Table className={styles.planTable}>
          <TableHead className={styles.subHeaderRow}>
            <SubHeader
              title=" "
              plans={plans}
              planId={planId}
              onChange={onChange}
              planMapping={(plan) => (
                <Typography variant="h6" className={styles.myPlan}>
                  {plan.id === planId ? 'My plan' : ''}
                </Typography>
              )}
            />
          </TableHead>
          <TableHead className={styles.subHeaderRow}>
            <SubHeader
              title="In Network"
              plans={plans}
              planId={planId}
              planMapping={(plan) => (
                <Box>
                  <Typography variant="h6" className={styles.title}>
                    <PlanIcons planTypeId={Number(plan.planTypeId)} />
                    {plan.title}
                  </Typography>
                  {plan.id !== planId && onChange && (
                    <Box className={styles.wrapper}>
                      <SubmitButton
                        label="Select"
                        variant="contained"
                        onClick={() => onChange(plan.id)}
                        className={styles.btn}
                      />
                    </Box>
                  )}
                </Box>
              )}
            />
          </TableHead>
          <TableBody>
            <PlansFiles plans={plans} planId={planId} />

            {mapPropToCellIfExist('Plan Highlights:', 'mblComment')}
            {mapPropToCell('Deductible (single / family):', 'inDeductible')}
            {mapPropToCell('Coinsurance:', 'inCoinsurance')}
            {mapPropToCell(
              'Maximum out of pocket (single / family):',
              'inMoop'
            )}
            {mapPropToCell('Primary Care Physician (PCP) copay:', 'inPcpCopay')}
            {mapPropToCell('Specialist copay:', 'inSpecialistCopay')}
            {mapPropToCell('Inpatient hospital copay:', 'inHospitalCopay')}
            {mapPropToCell('Emergency room copay:', 'inEmergency')}
            {mapPropToCell('Diagnostic testing - Laboratory:', 'inLaboratory')}
            {mapPropToCell('Diagnostic testing - X-ray:', 'inXray')}
            {mapPropToCell('Imaging:', 'inImaging')}

            <SubHeader title="Out Of Network" plans={plans} />

            {renderOutOfNetwork(
              'Deductible (single / family):',
              'outDeductible'
            )}
            {renderOutOfNetwork('Coinsurance:', 'outCoinsurance')}
            {renderOutOfNetwork(
              'Maximum out of pocket (single / family):',
              'outMoop'
            )}
            {renderOutOfNetwork('UCR level:', 'outUcrLevel')}

            <SubHeader title="Pharmacy Benefits" plans={plans} />
            <TableRow>
              <TableCell className={styles.medicalTitleCell} />
              {plans.map((plan) => (
                <>
                  <TableCell
                    className={
                      plan.id === planId
                        ? `${styles.myPlanCell} ${styles.subHeader}`
                        : `${styles.subHeader}`
                    }
                  >
                    Retail
                  </TableCell>
                  <TableCell
                    className={
                      plan.id === planId
                        ? `${styles.myPlanCell} ${styles.subHeader}`
                        : `${styles.subHeader}`
                    }
                  >
                    {plan.rxMailOrderGeneric ? 'Mail order' : ''}
                  </TableCell>
                </>
              ))}
            </TableRow>

            {renderPharmacyBenefits(
              'Generic:',
              'rxRetailGeneric',
              'rxMailOrderGeneric'
            )}
            {renderPharmacyBenefits(
              'Preferred:',
              'rxRetailPreferred',
              'rxMailOrderPreferred'
            )}
            {renderPharmacyBenefits(
              'Non-preferred:',
              'rxRetailNonpreferred',
              'rxMailOrderNonpreferred'
            )}
            {renderPharmacyBenefits(
              'Deductible:',
              'rxRetailDeductible',
              'rxMailOrderDeductible'
            )}
            {renderButtonSelect()}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GroupMedicalPlanCompareTable;
