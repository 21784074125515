import {
  IFullDependent,
  ICreateDependent,
  IUpdateDependent,
  IDependentPersonal,
  DependentInterface,
  DependentAddressInterface,
} from 'types/dependents';
import api from '../adapter';

export class DependentsDomain {
  getList() {
    return api.makeRequest<IFullDependent[]>('/dependents', 'GET');
  }

  getPersonalDataList(password: string) {
    return api.makeRequest<IDependentPersonal[]>(
      '/dependents/personal',
      'POST',
      { password }
    );
  }

  getOne(id: string) {
    return api.makeRequest<IFullDependent>(`/dependents/${id}`, 'GET');
  }

  create(payload: ICreateDependent) {
    return api.makeRequest<IFullDependent>('/dependents', 'POST', payload);
  }

  update(id: string, payload: IUpdateDependent) {
    return api.makeRequest<void>(`/dependents/${id}`, 'PUT', payload);
  }

  delete(id: string) {
    return api.makeRequest<void>(`/dependents/${id}`, 'DELETE');
  }

  getListByEmployeeId(id: string) {
    return api.makeRequest<DependentInterface[]>(
      `/employees/${id}/dependents`,
      'GET'
    );
  }

  getDependentsAddresses() {
    return api.makeRequest<DependentAddressInterface[]>(
      '/dependents/addresses',
      'GET'
    );
  }

  updateDependentsAddresses(payload: DependentAddressInterface[]) {
    return api.makeRequest<void>(
      '/dependents/addresses/update',
      'PUT',
      payload
    );
  }

  updateDependentAddress(
    dependentId: string | number,
    payload: DependentAddressInterface
  ) {
    return api.makeRequest<any>(
      `/dependents/${dependentId}/address`,
      'PUT',
      payload
    );
  }
}

export default new DependentsDomain();
