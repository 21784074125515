import { all, put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Action } from 'redux-actions';

import ILoginCredentials from 'models/ILoginCredentials';
import IAuthResponse from 'models/IAuthResponse';
import AppVersion from 'enums/appVersion';
import userDomain from 'api/domains/user';
import storage from 'helpers/storage';
import parseJwt from 'helpers/parseJwt';
import * as feedbackMessages from 'constants/feedbackMessages';
import { fromSystemOptionToBasicOption } from 'mappers/common/fromSystemOptionToBasicOption';
import { ORIGIN_URL } from 'constants/app';
import {
  LOGIN_PATH,
  DEFAULT_PATH,
  BASE_NAME,
  OLD_VERSION_BASE_NAME,
} from 'constants/routes';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  IMPERSONATE_TOKEN,
  LOCATION_BEFORE_IMPERSONATION,
  EMPLOYEE_ID,
} from 'constants/storeKeys';
import { login, loadCurrentUser, logout } from './routines';

function* loginWorker(action: Action<ILoginCredentials>) {
  try {
    yield put(login.request());
    const { data: loginData } = yield call(userDomain.login, action.payload);
    /**
     * Redirect user to old version of application
     */
    if ((loginData as IAuthResponse).version === AppVersion.Lite) {
      return window.location.replace(`${ORIGIN_URL}${OLD_VERSION_BASE_NAME}`);
    }
    storage.set(ACCESS_TOKEN, loginData.jwt);
    yield put(loadCurrentUser.trigger());
    yield put(push(DEFAULT_PATH));
  } catch (err) {
    yield put(login.failure({ error: feedbackMessages.INVALID_CREDENTIALS }));
  } finally {
    yield put(login.fulfill());
  }
}

function* loadCurrentUserWorker() {
  try {
    yield put(loadCurrentUser.request());
    const jwt: string | null =
      storage.get(IMPERSONATE_TOKEN) || storage.get(ACCESS_TOKEN);
    if (!jwt) {
      yield put(loadCurrentUser.fulfill());
      return;
    }
    const { data } = yield call(userDomain.loadCurrentUser);
    const parseData = parseJwt(jwt as string);
    const jwtData = parseData?.data ?? parseData;
    yield put(
      loadCurrentUser.success({
        ...data,
        payrollMethods: data.payrollMethods.map(fromSystemOptionToBasicOption),
        roleType: +jwtData.admin,
      })
    );
  } catch (e) {
    console.error(e.response);
  } finally {
    yield put(loadCurrentUser.fulfill());
  }
}

function* logoutWorker() {
  try {
    yield call(userDomain.logout);
    const impersonationToken = storage.get(IMPERSONATE_TOKEN);
    const locationBeforeImpersonation = storage.get(
      LOCATION_BEFORE_IMPERSONATION
    );
    if (impersonationToken) {
      const parsedToken = parseJwt(impersonationToken);
      const isImpersonationValid = Date.now() < parsedToken.exp * 1000;
      if (isImpersonationValid) {
        storage.remove(IMPERSONATE_TOKEN);
        storage.set(EMPLOYEE_ID, parsedToken?.id ?? parsedToken.data.id);
        window.location.assign(
          `${BASE_NAME}${locationBeforeImpersonation as string}`
        );
        yield put(logout.success());
        return;
      } else {
        storage.remove(IMPERSONATE_TOKEN);
        storage.remove(ACCESS_TOKEN);
        storage.remove(REFRESH_TOKEN);
      }
    } else {
      storage.remove(ACCESS_TOKEN);
      storage.remove(REFRESH_TOKEN);
    }
    yield put(logout.success());
    yield put(push(LOGIN_PATH));
    window.location.reload();
  } catch (e) {
    console.error(e);
  }
}

function* watchLogin() {
  yield takeEvery(login.trigger, loginWorker);
}

function* watchLoadCurrentUser() {
  yield takeEvery(loadCurrentUser.trigger, loadCurrentUserWorker);
}

function* watchLogout() {
  yield takeEvery(logout.trigger, logoutWorker);
}

export default function* userSaga() {
  yield all([watchLogin(), watchLoadCurrentUser(), watchLogout()]);
}
