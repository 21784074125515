import React, { useState } from 'react';

import fileDomain from 'api/domains/file';
import PlanType from 'enums/planType';
import { Plan } from 'types/plans';
import GroupMedical from './GroupMedicalPlan';
import GroupDental from './GroupDentalPlan';
import GroupLife from './GroupLifePlan';
import GroupVision from './GroupVisionPlan';
import VoluntaryLife from './VoluntaryLifePlan';
import Ltd from './LtdPlan';
import Fsa from './FsaPlan';
import Std from './StdPlan';
import HraAndHsa from './HraAndHsa';
import Other from './OtherPlan';

interface IProps {
  plan: Plan;
  employeeId?: string | number | null;
}

const PlansFactory: React.FC<IProps> = ({ plan, employeeId }) => {
  const [isFileLoading, setFileLoading] = useState(false);

  const handleOpenFileInNewTab = async () => {
    if (plan.fileId) {
      try {
        setFileLoading(true);
        const { data } = await fileDomain.downloadFile(plan.fileId);
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL);
      } catch (err) {
        console.error(err);
      } finally {
        setFileLoading(false);
      }
    }
    return;
  };

  switch (plan.planType) {
    case PlanType.GroupMedical:
      return (
        <GroupMedical
          handleOpenFile={handleOpenFileInNewTab}
          plan={plan}
          isFileLoading={isFileLoading}
          employeeId={employeeId}
        />
      );
    case PlanType.GroupDental:
    case PlanType.VoluntaryDental:
    case PlanType.WorksiteBenefitsDental:
      return (
        <GroupDental
          handleOpenFile={handleOpenFileInNewTab}
          plan={plan}
          isFileLoading={isFileLoading}
          employeeId={employeeId}
        />
      );
    case PlanType.GroupVision:
    case PlanType.VoluntaryVision:
    case PlanType.WorksiteBenefitsVision:
      return (
        <GroupVision
          handleOpenFile={handleOpenFileInNewTab}
          plan={plan}
          isFileLoading={isFileLoading}
          employeeId={employeeId}
        />
      );

    case PlanType.GroupLife:
      return (
        <GroupLife
          handleOpenFile={handleOpenFileInNewTab}
          plan={plan}
          isFileLoading={isFileLoading}
          employeeId={employeeId}
        />
      );
    case PlanType.LTD:
      return (
        <Ltd
          handleOpenFile={handleOpenFileInNewTab}
          plan={plan}
          isFileLoading={isFileLoading}
          employeeId={employeeId}
        />
      );
    case PlanType.FSA:
      return (
        <Fsa
          handleOpenFile={handleOpenFileInNewTab}
          plan={plan}
          isFileLoading={isFileLoading}
          employeeId={employeeId}
        />
      );
    case PlanType.STD:
      return (
        <Std
          handleOpenFile={handleOpenFileInNewTab}
          plan={plan}
          isFileLoading={isFileLoading}
          employeeId={employeeId}
        />
      );
    case PlanType.HRA:
      return (
        <HraAndHsa
          handleOpenFile={handleOpenFileInNewTab}
          plan={plan}
          isFileLoading={isFileLoading}
          employeeId={employeeId}
        />
      );
    case PlanType.HSA:
      return (
        <HraAndHsa
          handleOpenFile={handleOpenFileInNewTab}
          plan={plan}
          isFileLoading={isFileLoading}
          employeeId={employeeId}
        />
      );
    case PlanType.VoluntaryLife:
      return (
        <VoluntaryLife
          handleOpenFile={handleOpenFileInNewTab}
          plan={plan}
          isFileLoading={isFileLoading}
          employeeId={employeeId}
        />
      );
    default:
      return (
        <Other
          handleOpenFile={handleOpenFileInNewTab}
          plan={plan}
          isFileLoading={isFileLoading}
          employeeId={employeeId}
        />
      );
  }
};

export default PlansFactory;
