import React, { useState, useEffect, ChangeEvent } from 'react';
import { Button, Box } from '@material-ui/core';

import ContentTableBox from 'components/ContentTableBox/ContentTableBoxComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import StepperButtons from 'components/Stepper/SimpleStepper/StepperButtonsComponent';
import IBasicOption from 'models/IBasicOption';
import IOnboardingStepProps from 'models/IOnboardingStepProps';
import onboardingDomain from 'api/domains/onboarding';
import { PartialPayrollI, PayrollI } from 'types/onboarding';
import { PayrollMethodValue, PayrollErrors, AmountType } from './types';
import PayrollForm from './PayrollForm';
import {
  convertToPartialPayroll,
  defaultPayrollData,
  formPaymentState,
  getCurrentAmountType,
  getDepositFieldsError,
  getCardOrCheckPayrollFieldsError,
  isRemainingAmount,
  convertToRemainingAmount,
  isPercentage,
} from './helpers';

import styles from './styles.module.scss';

type InputFieldName = 'amount' | 'routingNumber' | 'accountNumber';

const Payroll: React.FC<IOnboardingStepProps> = ({
  index,
  count,
  setIsLoading,
  moveForward,
  moveBack,
}) => {
  const [paymentsMap, setPaymentsMap] = useState<Map<string, PartialPayrollI>>(
    new Map()
  );
  const [paymentErrors, setPaymentErrors] = useState<
    Map<string, PayrollErrors>
  >(new Map());

  const setNewPaymentsMap = (newMap: Map<string, PartialPayrollI>) => {
    const isDollarType = Array.from(newMap.values()).every(
      (payment) => payment.amountType !== AmountType.Percentage
    );

    if (!isDollarType || newMap.size <= 1) {
      return setPaymentsMap(newMap);
    }

    const newMapWithRemainingAmount = new Map<string, PartialPayrollI>();
    newMap.forEach((payment, name) => {
      if (payment.sortOrder === newMap.size.toString()) {
        newMapWithRemainingAmount.set(name, {
          ...payment,
          amountType: AmountType.RemainingAmount,
          amount: '0',
        });
      } else {
        newMapWithRemainingAmount.set(name, {
          ...payment,
          amountType: AmountType.Dollars,
        });
      }
    });
    return setPaymentsMap(newMapWithRemainingAmount);
  };

  useEffect(() => {
    (async () => {
      try {
        await setIsLoading(true);
        const { data } = await onboardingDomain.getPayroll();

        setNewPaymentsMap(
          formPaymentState(data.sort((a, b) => +a.sortOrder - +b.sortOrder))
        );
      } catch (err) {
        console.error(err);
      } finally {
        await setIsLoading(false);
      }
    })();
  }, [setIsLoading]);

  const getAllPayrollMethodsToSave = () => {
    return Array.from(paymentsMap.values()).map((payment) =>
      payment.amountType === AmountType.RemainingAmount
        ? convertToRemainingAmount(payment)
        : payment
    );
  };

  const validate = () => {
    const newErrorMap = new Map<string, PayrollErrors>(paymentErrors);
    let totalAmount = 0;

    // All fields are required
    paymentsMap.forEach((payment, key) => {
      totalAmount += Number(payment.amount);
      if (payment.method === PayrollMethodValue.Deposit) {
        const errors = getDepositFieldsError(payment);
        newErrorMap.set(key, errors);
      } else {
        const errors = getCardOrCheckPayrollFieldsError(payment);
        newErrorMap.set(key, errors);
      }
    });

    // Validate amount

    // Validate percentage amount
    const isPercentageAmountType = isPercentage(paymentsMap);
    if (isPercentageAmountType && totalAmount !== 100) {
      paymentsMap.forEach((v, key) => {
        const errors = newErrorMap.get(key);
        newErrorMap.set(key, {
          ...errors,
          amount: 'Total summary must be equal 100%',
        });
      });
    }

    // Validate dollar amount
    if (!isPercentageAmountType) {
      paymentsMap.forEach((payment, key) => {
        if (payment.amountType !== AmountType.RemainingAmount) {
          const errors = newErrorMap.get(key);
          newErrorMap.set(key, {
            ...errors,
            amount: !payment.amount ? 'Field is required' : undefined,
          });
        }
      });
    }

    setPaymentErrors(newErrorMap);

    let errorCount = 0;
    newErrorMap.forEach((paymentErrors) => {
      const hasError = Object.values(paymentErrors).some((error) => !!error);
      if (hasError) {
        errorCount++;
      }
    });

    const isFormsValid = errorCount === 0;
    return isFormsValid;
  };

  const handleSubmit = async () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }
    const payrollPayloads = getAllPayrollMethodsToSave();
    try {
      await setIsLoading(true);
      await onboardingDomain.saveAllPayrolls(payrollPayloads);
      await moveForward();
    } catch (error) {
      console.error(error);
    } finally {
      await setIsLoading(false);
    }
  };

  const renderStepperButtons = () => {
    return (
      <StepperButtons
        handleNext={() => {
          handleSubmit();
        }}
        disabledNext={!paymentsMap.size}
        handleBack={moveBack}
        hideBackBtn={index === 3}
      />
    );
  };

  const handleAmountTypeChange = (value?: IBasicOption) => {
    const newMap = new Map();
    paymentsMap.forEach((payment, key) => {
      newMap.set(key, { ...payment, amountType: value?.value });
    });
    console.info(newMap);
    setNewPaymentsMap(newMap);
  };

  const handleAccountTypeChange = (name: string, value?: IBasicOption) => {
    const method = paymentsMap.get(name);

    if (method) {
      const newMap = new Map(paymentsMap);
      newMap.set(name, { ...method, accountType: value?.value });
      setNewPaymentsMap(newMap);
    }

    const newMap = new Map(paymentErrors);
    const error = paymentErrors.get(name);
    if (!value?.value) {
      newMap.set(name, {
        ...error,
        accountType: 'Select Account Type',
      });
    } else {
      newMap.set(name, {
        ...error,
        accountType: '',
      });
    }
    setPaymentErrors(newMap);
  };

  const handleChange = (name: string, field: InputFieldName, value: string) => {
    const method = paymentsMap.get(name);
    if (method) {
      const newMap = new Map(paymentsMap);
      newMap.set(name, { ...method, [field]: value });
      setNewPaymentsMap(newMap);
    }

    const newMap = new Map(paymentErrors);
    const error = paymentErrors.get(name);
    if (!value) {
      newMap.set(name, {
        ...error,
        [field]: 'Field is required',
      });
    } else {
      newMap.set(name, {
        ...error,
        [field]: '',
      });
    }
    setPaymentErrors(newMap);
  };

  const handleAmountChangeChange = (name: string, value: string) => {
    const method = paymentsMap.get(name);
    const newPaymentMap = new Map(paymentsMap);
    if (method) {
      newPaymentMap.set(name, { ...method, amount: value });
      setNewPaymentsMap(newPaymentMap);
    }

    // Validate
    const newErrorMap = new Map(paymentErrors);
    const error = paymentErrors.get(name);

    const amountType = getCurrentAmountType(paymentsMap);
    let totalAmount = 0;
    let totalAmountError = '';

    newPaymentMap.forEach(({ amount }) => (totalAmount += Number(amount)));

    if (amountType === AmountType.Percentage && totalAmount !== 100) {
      totalAmountError = 'Total percentage must be 100%';
    } else {
      totalAmountError = '';
    }

    if (!value) {
      newErrorMap.set(name, {
        ...error,
        amount: 'Amount is required',
      });
    } else {
      newErrorMap.set(name, {
        ...error,
        amount: '',
      });
    }

    paymentsMap.forEach((v, key) => {
      newErrorMap.set(key, { ...error, amount: totalAmountError });
    });

    setPaymentErrors(newErrorMap);
  };

  const handlePaymentMethodChange = (name: string, newValue: number) => {
    const method = paymentsMap.get(name);
    if (method) {
      const newMap = new Map(paymentsMap);
      newMap.set(name, { ...method, method: newValue });
      setNewPaymentsMap(newMap);
    }
  };

  const createPaymentMethod = async () => {
    const newMap = new Map(paymentsMap);
    const sortOrder = String(newMap.size + 1);

    const amountType = getCurrentAmountType(paymentsMap);
    const { id, ...rest } = defaultPayrollData;

    const { data } = await onboardingDomain.createPayroll({
      ...rest,
      sortOrder,
      amountType:
        amountType === AmountType.RemainingAmount
          ? AmountType.Dollars
          : amountType,
    });
    const result =
      Array.isArray(data) && data[0] ? data[0] : (data as PayrollI);
    newMap.set(
      result?.id,
      convertToPartialPayroll(result, isRemainingAmount(result, true))
    );
    setNewPaymentsMap(newMap);
  };

  const deletePayrollMethod = async (id: string) => {
    const newMap = new Map(paymentsMap);
    try {
      await onboardingDomain.deletePayrollById(id);
      newMap.delete(id);
      setNewPaymentsMap(newMap);
    } catch (e) {
      console.error(e);
    } finally {
      // this should be removed when DELETE /payroll will NOT return error on successful deletion
      newMap.delete(id);
      setNewPaymentsMap(newMap);
    }
  };

  const renderPayrollMethods = () => {
    const components = [] as JSX.Element[];

    let index = 0;
    paymentsMap.forEach((method, name) => {
      components.push(
        <PayrollForm
          index={index}
          key={name}
          name={name}
          accountNumber={method.accountNumber}
          routingNumber={method.routingNumber}
          amount={method.amount}
          method={method.method}
          amountType={method.amountType}
          accountType={method.accountType}
          error={paymentErrors.get(name)}
          remainingAmount={method.amountType === AmountType.RemainingAmount}
          handleAmountChange={(newValue: string) =>
            handleAmountChangeChange(name, newValue)
          }
          setAmountType={handleAmountTypeChange}
          handleTabChange={(e: ChangeEvent<{}>, newValue: number) =>
            handlePaymentMethodChange(name, newValue)
          }
          setRoutingNumber={(newValue: string) =>
            handleChange(name, 'routingNumber', newValue)
          }
          setAccountNumber={(newValue: string) =>
            handleChange(name, 'accountNumber', newValue)
          }
          setAccountType={(value?: IBasicOption) =>
            handleAccountTypeChange(name, value)
          }
          deletePayrollMethod={deletePayrollMethod}
        />
      );
      index++;
    });

    return <>{components}</>;
  };

  return (
    <>
      <ContentTableBox title="Payroll" index={index} count={count} isBox>
        <Box className={styles.main}>
          {!paymentsMap.size && (
            <SimpleAlert
              message="Please create payroll methods to continue"
              type="warning"
              className={styles.payrollAlert}
            />
          )}
          {renderPayrollMethods()}
          <div className={styles.createPaymentBtn}>
            <Button
              className={styles.btn}
              onClick={createPaymentMethod}
              variant="contained"
              color="primary"
            >
              Create new payroll method
            </Button>
          </div>
        </Box>
      </ContentTableBox>
      {renderStepperButtons()}
    </>
  );
};

export default Payroll;
