import React from 'react';
import { withFormik, FormikBag, FormikProps } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Typography } from '@material-ui/core';

import RadioGroup, {
  BasicRadioButtonInterface,
} from 'components/Forms/RadioGroup/RadioGroupComponent';
import FillFieldsList, {
  FieldsInterface,
} from 'components/FillFieldsList/FillFieldsListComponent';
import SimpleAlert from 'components/SimpleAlert/SimpleAlertComponent';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';

import { WAIVER_OTHER_REASON_VALUE } from 'constants/app';
import EnrollmentStatus from 'enums/enrollmentStatus';
import WaiverMember from 'enums/waiverMember';
import ExternalPlanType from 'enums/externalPlanType';
import { IUpdateEnrollmentPayload, IEnrollment } from 'types/enrollments';
import IBasicOption from 'models/IBasicOption';

interface DeclineEnrollmentFormProps {
  waiverReasons: IBasicOption<number>[];
  groupName: string;
  onDecline: (
    id: string,
    enrollment: IUpdateEnrollmentPayload
  ) => void | Promise<void>;
  enrollment?: IEnrollment;
  currentPlanType?: string;
}

interface IFormikValues {
  declineReason?: DeclineReason;
  waiverReason: number;
  insuranceCompany: string;
  policyNumber: string;
  reasonOther?: string;
  member: string;
  firstName: string;
  lastName: string;
}

type WithFormikProps = DeclineEnrollmentFormProps & FormikProps<IFormikValues>;

type DeclineReason = 'do_not_have_coverage' | 'have_other_coverage';

const useStyles = makeStyles({
  submitBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    margin: '1.5rem 0',
  },
  deletePaddingRight: {
    padding: '1rem 0 1rem 2rem',
  },
  declineFormContainer: {
    width: '90%',
    maxWidth: '500px',
  },
  text: {
    textAlign: 'justify',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.4px',
    color: '#000000',
    marginTop: '20px',
    marginBottom: '30px',
  },
  main: {
    margin: '20px',
  },
  div: {
    margin: '20px 0',
  },
  subBlockTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.4px',
    color: '#000000',
    marginBottom: '10px',
  },
});

const shouldAskDeclineReason = (planTypeId?: string): boolean => {
  return (
    planTypeId === ExternalPlanType.Dental ||
    planTypeId === ExternalPlanType.Medical ||
    planTypeId === ExternalPlanType.Vision
  );
};

const declineCoverageReasonsRadio = (
  groupName: string,
  planTypeId?: string
): BasicRadioButtonInterface<DeclineReason>[] => [
  {
    label: `I have other ${
      planTypeId === ExternalPlanType.Medical
        ? 'Group Medical Coverage, Medicare, or other valid'
        : groupName
    } coverage`,
    value: 'have_other_coverage',
  },
  {
    label: `I DO NOT have other ${groupName} coverage`,
    value: 'do_not_have_coverage',
  },
];

const getWaiverMemberOptions = (): IBasicOption<WaiverMember>[] => [
  { label: 'Self', value: WaiverMember.Self },
  { label: 'Spouse', value: WaiverMember.MySpouse },
  { label: 'Other', value: WaiverMember.Other },
];

const DeclineEnrollmentForm: React.FC<WithFormikProps> = (props) => {
  const {
    waiverReasons,
    groupName,
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    currentPlanType,
  } = props;

  const waivedMemberOptions = getWaiverMemberOptions();

  const classes = useStyles();

  const onChangeDeclineReason = (reason: DeclineReason) => {
    setFieldValue('declineReason', reason);
  };

  const getAdditionalWaivedFields = (): FieldsInterface[] => {
    return values.waiverReason === WAIVER_OTHER_REASON_VALUE
      ? [
          {
            key: 'reasonOther',
            inputComponentData: {
              value: values.reasonOther || '',
              onChange: handleChange,
              name: 'reasonOther',
              label: 'Other source',
              error: errors.reasonOther,
              onBlur: handleBlur,
            },
          },
          {
            key: 'member',
            selectComponentData: {
              value: values.member || '',
              options: waivedMemberOptions,
              onChange: handleChange,
              name: 'member',
              label: 'Policyholder',
              error: !!errors.member,
              helperText: errors.member,
              onBlur: handleBlur,
            },
          },
        ]
      : [];
  };

  const getAdditionalWaiverMemberFields = (): FieldsInterface[] => {
    return values.waiverReason === WAIVER_OTHER_REASON_VALUE &&
      values.member === WaiverMember.Other
      ? [
          {
            key: 'firstName',
            inputComponentData: {
              value: values.firstName,
              onChange: handleChange,
              name: 'firstName',
              label: 'Waiver first name',
              onBlur: handleBlur,
              error: errors.firstName,
            },
          },
          {
            key: 'lastName',
            inputComponentData: {
              value: values.lastName,
              onChange: handleChange,
              name: 'lastName',
              label: 'Waiver last name',
              onBlur: handleBlur,
              error: errors.lastName,
            },
          },
        ]
      : [];
  };

  const fields: FieldsInterface[] = [
    {
      key: 'waiverReason',
      selectComponentData: {
        options: waiverReasons,
        label: 'Source of other coverage',
        name: 'waiverReason',
        value: values.waiverReason,
        onChange: handleChange,
        error: !!errors.waiverReason,
        helperText: errors.waiverReason,
        onBlur: handleBlur,
      },
    },
    ...getAdditionalWaivedFields(),
    ...getAdditionalWaiverMemberFields(),
    {
      key: 'insuranceCompany',
      inputComponentData: {
        value: values.insuranceCompany,
        onChange: handleChange,
        name: 'insuranceCompany',
        label: 'Insurance Company',
        error: errors.insuranceCompany,
        onBlur: handleBlur,
      },
    },
    {
      key: 'policyNumber',
      inputComponentData: {
        value: values.policyNumber,
        onChange: handleChange,
        name: 'policyNumber',
        label: 'Policy Number',
        error: errors.policyNumber,
        onBlur: handleBlur,
      },
    },
  ];

  const disableDeclineButton =
    shouldAskDeclineReason(currentPlanType) && !values.declineReason;

  return (
    <>
      <SimpleAlert title="Decline Coverage" type="error" />
      <Box className={classes.main}>
        {shouldAskDeclineReason(currentPlanType) && (
          <>
            <Typography className={classes.subBlockTitle}>
              Choose Reason
            </Typography>
            <RadioGroup
              options={declineCoverageReasonsRadio(groupName, currentPlanType)}
              name="decline_reason"
              onChange={onChangeDeclineReason}
              value={values.declineReason}
            />
            <Divider className={classes.div} />
            {values.declineReason === 'have_other_coverage' && (
              <Box className={classes.declineFormContainer}>
                <FillFieldsList fields={fields} />
              </Box>
            )}
          </>
        )}
        <Typography className={classes.text}>
          If you decline coverage during your initial enrollment period, then
          you may not enroll until you experience a qualifying life event or
          until the next open enrollment period. For more information, please
          see Human Resources.
        </Typography>
        <Box className={classes.submitBtn}>
          <SubmitButton
            label={disableDeclineButton ? 'Please choose reason' : 'Decline'}
            color="primary"
            onClick={handleSubmit}
            disabled={disableDeclineButton}
          />
        </Box>
      </Box>
    </>
  );
};

const mapPropsToValues = (props: DeclineEnrollmentFormProps): IFormikValues => {
  const { enrollment, waiverReasons, currentPlanType } = props;

  const isReasonAsked = shouldAskDeclineReason(currentPlanType);
  const waivedMemberOptions = getWaiverMemberOptions();

  const getDeclineReason = () => {
    return enrollment?.status === EnrollmentStatus.Waived
      ? 'have_other_coverage'
      : enrollment?.status === EnrollmentStatus.Declined
      ? 'do_not_have_coverage'
      : undefined;
  };

  return {
    declineReason: isReasonAsked ? getDeclineReason() : undefined,
    waiverReason: enrollment?.waiver?.reasonId
      ? +enrollment.waiver.reasonId
      : waiverReasons[0]?.value,
    insuranceCompany: enrollment?.waiver?.carrierName || '',
    policyNumber: enrollment?.waiver?.policyNumber || '',
    reasonOther: enrollment?.waiver?.reasonOther,
    member: enrollment?.waiver?.member || waivedMemberOptions[0].value,
    firstName: enrollment?.waiver?.firstName || '',
    lastName: enrollment?.waiver?.lastName || '',
  };
};

const handleSubmit = async (
  values: IFormikValues,
  bag: FormikBag<DeclineEnrollmentFormProps, IFormikValues>
) => {
  const { onDecline, currentPlanType } = bag.props;
  const {
    declineReason,
    firstName,
    lastName,
    member,
    waiverReason,
    reasonOther,
    insuranceCompany,
    policyNumber,
  } = values;

  if (!currentPlanType || !onDecline) return;

  if (
    declineReason === 'do_not_have_coverage' ||
    !shouldAskDeclineReason(currentPlanType)
  ) {
    await onDecline(currentPlanType, { status: EnrollmentStatus.Declined });
  }
  if (declineReason === 'have_other_coverage') {
    await onDecline(currentPlanType, {
      status: EnrollmentStatus.Waived,
      waiver: {
        reasonId:
          waiverReason !== WAIVER_OTHER_REASON_VALUE
            ? waiverReason?.toString()
            : '-1',
        reasonOther:
          waiverReason === WAIVER_OTHER_REASON_VALUE ? reasonOther : undefined,
        member: waiverReason === WAIVER_OTHER_REASON_VALUE ? member : undefined,
        firstName:
          member === WaiverMember.Other &&
          waiverReason === WAIVER_OTHER_REASON_VALUE
            ? firstName
            : undefined,
        lastName:
          member === WaiverMember.Other &&
          waiverReason === WAIVER_OTHER_REASON_VALUE
            ? lastName
            : undefined,
        carrierName: insuranceCompany,
        policyNumber: policyNumber,
      },
    });
  }
};

const validationSchema = yup.object({
  declineReason: yup
    .string()
    .oneOf(['do_not_have_coverage', 'have_other_coverage'])
    .optional(),
  waiverReason: yup.number().when('declineReason', {
    is: 'have_other_coverage',
    then: yup.number().required('Field is required!'),
    otherwise: yup.number().optional(),
  }),
  insuranceCompany: yup.string().when('declineReason', {
    is: 'have_other_coverage',
    then: yup.string().required('Field is required!'),
    otherwise: yup.string().optional(),
  }),
  policyNumber: yup.string().when('declineReason', {
    is: 'have_other_coverage',
    then: yup.string().required('Field is required!'),
    otherwise: yup.string().optional(),
  }),
  reasonOther: yup.string().when('waiverReason', {
    is: WAIVER_OTHER_REASON_VALUE,
    then: yup.string().required('Field is required!'),
    otherwise: yup.string().optional(),
  }),
  member: yup.string().when('waiverReason', {
    is: WAIVER_OTHER_REASON_VALUE,
    then: yup
      .string()
      .oneOf([WaiverMember.MySpouse, WaiverMember.Other, WaiverMember.Self])
      .required('Field is required!'),
    otherwise: yup.string().optional(),
  }),
  firstName: yup.string().when('member', {
    is: WaiverMember.Other,
    then: yup.string().required('Field is required!'),
    otherwise: yup.string().optional(),
  }),
  lastName: yup.string().when('member', {
    is: WaiverMember.Other,
    then: yup.string().required('Field is required!'),
    otherwise: yup.string().optional(),
  }),
});

export default withFormik<DeclineEnrollmentFormProps, IFormikValues>({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
})(DeclineEnrollmentForm);
