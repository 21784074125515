import React from 'react';
import { useLocation } from 'react-router-dom';

interface PropsWithQueryParams {
  queryParams: URLSearchParams;
}

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export const withQueryParamsHooksHOC = function <
  P extends PropsWithQueryParams
>(Component: React.ComponentType<P>) {
  return (props: Omit<P, 'queryParams'>) => {
    return <Component {...(props as any)} queryParams={useQuery()} />;
  };
};

export default useQuery;
