import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { useQuery } from 'react-query';
import { FormControl, Box, Typography, SvgIcon } from '@material-ui/core';
import { ReactComponent as helpIcon } from 'assets/infoIcon.svg';
import ContentBox from 'components/ContentBox/ContentBoxComponent';
import { IMedicalPlan } from 'types/plans';

import plansDomain from 'api/domains/plans';
import styles from './styles.module.scss';

const getCurrentPlan = async (_: string, id: string) => {
  const { data } = await plansDomain.getOne(id);
  return data;
};
interface IMyPlan {
  planId: string | number;
}

const MyPlan: React.FC<IMyPlan> = (props) => {
  const { planId } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { data, isLoading } = useQuery(
    ['currentPlanDetails', planId],
    getCurrentPlan,
    {
      enabled: planId,
      refetchOnWindowFocus: false,
    }
  );

  const planDetails = data as IMedicalPlan | undefined;
  const handlePopoverOpen = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverContentConfig = [
    {
      title: 'Deductible (single / family):',
      value: planDetails?.inDeductible,
    },
    {
      title: 'Coinsurance',
      value: planDetails?.inCoinsurance,
    },
    {
      title: 'Maximum out of pocket (single / family):',
      value: planDetails?.inMoop,
    },
    {
      title: 'Primary Care Physician (PCP) copay:',
      value: planDetails?.inPcpCopay,
    },
    {
      title: 'Rx:',
      value: planDetails?.rxRetail,
    },
  ];

  const popoverOpen = Boolean(anchorEl);

  return (
    <FormControl className={styles.formPlan} component="fieldset">
      <Box className={styles.radioContainer}>
        <Box>
          <Box
            aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
              handlePopoverOpen(e, planId as string)
            }
            onMouseLeave={handlePopoverClose}
            className={styles.box}
          >
            <SvgIcon
              component={helpIcon}
              className={styles.icon}
              width="20"
              height="20"
              viewBox="0 0 20 20"
            />
          </Box>
          <Popover
            id="mouse-over-popover"
            className={styles.detailsPopover}
            open={popoverOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ContentBox className={styles.helpBubbleContainer}>
              {!isLoading ? (
                <>
                  {popoverContentConfig.map(({ title, value }, i) => (
                    <Box key={i} className={styles.helpBubbleMessageContainer}>
                      <Typography
                        variant="subtitle1"
                        className={styles.helpBubbleMessage}
                      >
                        {title}
                      </Typography>
                      <Typography variant="subtitle1">{value}</Typography>
                    </Box>
                  ))}
                </>
              ) : null}
            </ContentBox>
          </Popover>
        </Box>
      </Box>
    </FormControl>
  );
};

export default MyPlan;
