import { IOptions } from 'types/options';
import { AppOptionsInterface } from 'store/options';

import {
  fromSystemOptionToBasicOption,
  fromSystemOptionToBasicOptionString,
} from './fromSystemOptionToBasicOption';

export const fromOptionsToAppOptions = (
  options: IOptions
): AppOptionsInterface => ({
  gender: options.gender.map(fromSystemOptionToBasicOption),
  smoker: options.smoker.map(fromSystemOptionToBasicOption),
  state: options.state.map(fromSystemOptionToBasicOptionString),
  securityQuestions: options.securityQuestion.map(
    fromSystemOptionToBasicOption
  ),
  maritalStatus: options.maritalStatus.map(fromSystemOptionToBasicOption),
  lastNameReason: options.lastNameReason.map(fromSystemOptionToBasicOption),
  relationshipType: options.relationshipType.map(fromSystemOptionToBasicOption),
  accessGroup: options.accessGroup.map(fromSystemOptionToBasicOption),
  employmentStatus: options.employmentStatus.map(fromSystemOptionToBasicOption),
  officeLocation: options.office.map(fromSystemOptionToBasicOption),
  employmentType: options.employmentType.map(fromSystemOptionToBasicOption),
  qualifyingEvent: options.qualifyingEvent.map(fromSystemOptionToBasicOption),
  logEventType: options.logEventType.map(fromSystemOptionToBasicOption),
  planType: options.planType.map(fromSystemOptionToBasicOption),
  workEligibilityType: options.workEligibilityType.map(
    fromSystemOptionToBasicOption
  ),
  w4FilingStatus: options.w4FilingStatus.map(fromSystemOptionToBasicOption),
  payrollMethod: options.payrollMethod.map(fromSystemOptionToBasicOption),
  payrollAmountType: options.payrollAmountType.map(
    fromSystemOptionToBasicOption
  ),
  payrollAccountType: options.payrollAccountType.map(
    fromSystemOptionToBasicOption
  ),
  eeoRace: options.eeoRace.map(fromSystemOptionToBasicOption),
  eeoJobCategory: options.eeoJobCategory.map(fromSystemOptionToBasicOption),
  eeoMilitaryStatus: options.eeoMilitaryStatus.map(
    fromSystemOptionToBasicOption
  ),
  beneficiaryRelationshipType: options.beneficiaryRelationshipType.map(
    fromSystemOptionToBasicOption
  ),
  beneficiaryType: options.beneficiaryType.map(fromSystemOptionToBasicOption),
});
