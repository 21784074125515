import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  SvgIcon,
  Box,
  Typography,
  IconButton,
} from '@material-ui/core';
import { ReactComponent as logIcon } from 'assets/log.svg';
import { EMPTY_VALUE } from 'constants/placeholders';
import {
  IAction,
  IEmployeeWithActions,
  IShortActionPlanInfo,
} from 'types/actions';
import ActionFormsModal from './ActionFormsModal';
import { getActionStatus } from './helpers';

import styles from './styles.module.scss';

interface IActionTableProps {
  actions: IAction[];
  onChooseEmployee: (id: string) => void | Promise<void>;
}

function actionToShortActionInfo(action: IAction): IShortActionPlanInfo {
  return {
    actionId: action.id,
    planId: action.planId,
    planName: action.planName,
    status: action.status,
    statusId: action.statusId,
    planType: action.planType,
    files: action.files,
  };
}

function actionToEmployeeWithAction(action: IAction): IEmployeeWithActions {
  return {
    id: action.employeeId,
    fullName: `${action.firstName} ${action.lastName}`,
    actions: [actionToShortActionInfo(action)],
  };
}

function actionsToEmployeeWithActions(
  actions: IAction[]
): IEmployeeWithActions[] {
  const employeeIdToActionMap: Map<string, IEmployeeWithActions> = new Map();

  actions.forEach((action) => {
    let employeeWithActions = employeeIdToActionMap.get(action.employeeId);
    if (!employeeWithActions) {
      employeeWithActions = actionToEmployeeWithAction(action);
    } else {
      employeeWithActions.actions.push(actionToShortActionInfo(action));
    }
    employeeIdToActionMap.set(action.employeeId, employeeWithActions);
  });

  return Array.from(employeeIdToActionMap.values());
}

const ActionTable: React.FC<IActionTableProps> = ({
  actions,
  onChooseEmployee,
}) => {
  const [actionToShowFiles, setActionToShowFiles] = React.useState<IAction>();

  const setActionToShowFilesById = (actionId: string) => {
    setActionToShowFiles(actions.find((action) => action.id === actionId));
  };

  return !actions.length ? (
    <Box className={styles.noActionsFoundMessageBlock}>
      <Typography variant="h5" className={styles.placeholder}>
        No Actions Found
      </Typography>
    </Box>
  ) : (
    <>
      <TableContainer component={Box}>
        <Table className={styles.actionTable} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={styles.employeeName}>
                Employee Name
              </TableCell>
              <TableCell className={styles.status}>Status</TableCell>
              <TableCell className={styles.description}>Description</TableCell>
              <TableCell className={styles.file} align="right">
                Forms
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {actionsToEmployeeWithActions(actions).map(
              (employeeWithActions) => (
                <React.Fragment key={employeeWithActions.id}>
                  <TableRow>
                    <TableCell
                      className={styles.nameCell}
                      rowSpan={employeeWithActions.actions.length + 1}
                      onClick={() => onChooseEmployee(employeeWithActions.id)}
                    >
                      {employeeWithActions.fullName}
                    </TableCell>
                  </TableRow>
                  {employeeWithActions.actions.map((action, index) => (
                    <TableRow
                      className={
                        index + 1 !== employeeWithActions.actions.length
                          ? styles.rowAction
                          : ''
                      }
                      key={action.actionId}
                    >
                      <TableCell className={styles.fixed}>
                        {getActionStatus(action)}
                      </TableCell>
                      <TableCell>{action.planName}</TableCell>
                      <TableCell className={styles.fixed} align="right">
                        {action.files && action.files.length ? (
                          <IconButton
                            className={styles.btnFile}
                            onClick={() =>
                              setActionToShowFilesById(action.actionId)
                            }
                          >
                            <SvgIcon
                              component={logIcon}
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                            />
                          </IconButton>
                        ) : (
                          EMPTY_VALUE
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ActionFormsModal
        title="Action's forms"
        action={actionToShowFiles}
        isOpen={!!actionToShowFiles}
        handleClose={() => setActionToShowFiles(undefined)}
      />
    </>
  );
};

export default ActionTable;
