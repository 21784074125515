import React from 'react';
import Table from '@material-ui/core/Table/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import PlanIcons from 'components/PlanIcons/PlanIcons';
import { Box, Typography } from '@material-ui/core';
import PlanFileLink from 'components/FileLink/PlanFileLink';
import { IVisionPlan } from 'types/plans';
import styles from './styles.module.scss';

export interface IVisionPlanCompareProps {
  plans: IVisionPlan[];
  planId?: string | number;
  onChange?: (planId: string) => void;
}

const VisionPlanCompareTable: React.FC<IVisionPlanCompareProps> = ({
  plans,
  planId,
  onChange,
}) => {
  const mapPropsToRow = (title: string, keys: Array<keyof IVisionPlan>) => {
    return (
      <TableRow>
        <TableCell className={styles.visionTitleCell}>{title}</TableCell>
        {plans.map((plan) => (
          <>
            {keys.map((key) => (
              <TableCell
                className={plan.id === planId ? `${styles.myPlanCell}` : ''}
              >
                {plan[key]}
              </TableCell>
            ))}
          </>
        ))}
      </TableRow>
    );
  };

  const renderButtonSelect = () => {
    return onChange ? (
      <TableRow>
        <TableCell className={styles.dentalTitleCell} scope="row" />
        {plans.map((plan) => (
          <TableCell
            colSpan={5}
            className={plan.id === planId ? `${styles.myPlanCell}` : ''}
          >
            {plan.id !== planId && onChange && (
              <Box className={styles.wrapper}>
                <SubmitButton
                  label="Select"
                  variant="contained"
                  onClick={() => onChange(plan.id)}
                  className={styles.btn}
                />
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    ) : (
      <></>
    );
  };

  const checkMblComment = () => {
    return plans.filter((el) => el.mblComment !== null).length !== 0;
  };

  return (
    <TableContainer className={styles.planTableContainer} component={Box}>
      <Table className={styles.planTable}>
        <TableHead>
          <TableRow>
            <TableCell className={styles.visionTitleCell} />
            {plans.map((plan) => (
              <>
                <TableCell
                  className={plan.id === planId ? `${styles.myPlanCell}` : ''}
                  colSpan={5}
                >
                  {plan.id === planId && (
                    <Typography variant="h6" className={styles.myPlan}>
                      My Plan
                    </Typography>
                  )}
                  <Typography variant="h6" className={styles.title}>
                    <PlanIcons planTypeId={Number(plan.planTypeId)} />
                    {plan.title}
                  </Typography>
                  {plan.id !== planId && onChange && (
                    <Box className={styles.wrapper}>
                      <SubmitButton
                        label="Select"
                        variant="contained"
                        onClick={() => onChange(plan.id)}
                        className={styles.btn}
                      />
                    </Box>
                  )}
                </TableCell>
              </>
            ))}
          </TableRow>
          <TableRow>
            <TableCell />
            {plans.map((plan) => (
              <>
                <TableCell
                  colSpan={5}
                  className={`${
                    plan.id === planId ? `${styles.myPlanCell}` : ''
                  } ${styles.fileBox}`}
                >
                  <PlanFileLink
                    plan={plan}
                    linkLabel="View Summary of Benefits and Coverage (SBC)"
                    noFileMessage="No files"
                  />
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={styles.visionTitleCell} />
            {plans.map((_) => (
              <>
                <TableCell className={styles.subHeader}>
                  <Box className={styles.titleBox}>Frequency</Box>
                </TableCell>
                <TableCell className={styles.subHeader}>
                  <Box className={styles.titleBox}>Copay</Box>
                </TableCell>
                <TableCell className={styles.subHeader}>
                  <Box className={styles.titleBox}>In-Network Allowance</Box>
                </TableCell>
                <TableCell className={styles.subHeader}>
                  <Box className={styles.titleBox}>
                    Out-of-Network Allowance
                  </Box>
                </TableCell>
                <TableCell className={styles.subHeader}>
                  <Box className={styles.titleBox}>Deductible</Box>
                </TableCell>
              </>
            ))}
          </TableRow>
          {mapPropsToRow('Exam:', [
            'examFrequency',
            'examCopay',
            'examAllowanceIn',
            'examAllowanceOut',
            'examDeductible',
          ])}
          {mapPropsToRow('Lenses:', [
            'lensesFrequency',
            'lensesCopay',
            'lensesAllowanceIn',
            'lensesAllowanceOut',
            'lensesDeductible',
          ])}
          {mapPropsToRow('Frames:', [
            'framesFrequency',
            'framesCopay',
            'framesAllowanceIn',
            'framesAllowanceOut',
            'framesDeductible',
          ])}
          {mapPropsToRow('Contacts:', [
            'contactsFrequency',
            'contactsCopay',
            'contactsAllowanceIn',
            'contactsAllowanceOut',
            'examDeductible',
          ])}
          {renderButtonSelect()}
          {checkMblComment() && (
            <TableRow>
              <TableCell>Plan Highlights:</TableCell>
              {plans.map((plan) => (
                <>
                  <TableCell
                    className={`${
                      plan.id === planId ? `${styles.myPlanCell}` : ''
                    } ${styles.tableCell}`}
                    colSpan={5}
                  >
                    {plan.mblComment}
                  </TableCell>
                </>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VisionPlanCompareTable;
