import React, { ChangeEvent, FocusEvent } from 'react';
import Box from '@material-ui/core/Box';
import NumberFormat from 'react-number-format';

import * as inputFormats from 'constants/inputFormats';
import Input from 'components/Forms/Input/InputComponent';

interface IProps {
  label: string;
  helperText?: string;
  error: boolean;
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
}

const ZipField: React.FC<IProps> = ({
  label,
  value,
  error,
  onChange,
  onBlur,
  helperText,
}) => {
  return (
    <Box mb="1em">
      <NumberFormat
        customInput={Input}
        value={value}
        fullWidth
        variant="outlined"
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        format={inputFormats.zip}
      />
    </Box>
  );
};

export default ZipField;
