import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  SvgIcon,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { ReactComponent as openIcon } from 'assets/sidebar/open.svg';
import SubmitButton from 'components/Forms/SubmitButton/SubmitButtonComponent';
import { IReport, IReportData } from 'types/reports';
import OrderDirection from 'enums/orderDirection';
import { downloadFromBlob } from 'helpers/downloadFileFromBlob';
import { toSnake } from 'helpers/caseConverter';
import reportsDomain from 'api/domains/reports';
import { EMPTY_VALUE } from 'constants/placeholders';

import SectionTitle from 'components/SectionTitle';
import ContentContainer from 'components/ContentContainer/ContentContainerComponent';
import styles from './styles.module.scss';

interface IReportTableProps {
  reportData?: IReportData;
  report?: IReport;
  fetchReportData: (
    report: IReport,
    orderBy?: string,
    orderDirection?: OrderDirection
  ) => void | Promise<void>;
  onDownloadError?: () => void | Promise<void>;
  onBack?: () => void | Promise<void>;
}

const getReadableData = (data: string): string => {
  return data || EMPTY_VALUE;
};

const convertOrderDirection = (direction?: OrderDirection): 'asc' | 'desc' => {
  return direction === OrderDirection.DESC ? 'desc' : 'asc';
};

const ReportTable: React.FC<IReportTableProps> = ({
  reportData,
  report,
  fetchReportData,
  onDownloadError,
  onBack,
}) => {
  const [orderBy, setOrderBy] = React.useState<string | undefined>();
  const [orderDirection, setOrderDirection] = React.useState<
    OrderDirection | undefined
  >();

  useEffect(() => {
    if (reportData) {
      const { orderBy, orderDirection } = reportData.sort;
      setOrderBy(orderBy);
      setOrderDirection(orderDirection);
    }
  }, [reportData]);

  if (!reportData) return null;

  const { data, layout } = reportData;
  const fields = Object.keys(layout);

  const downloadExcel = async () => {
    if (!report) return;
    try {
      const { data } = await reportsDomain.downloadExcel(
        report?.id,
        orderBy && toSnake(orderBy),
        orderDirection
      );
      downloadFromBlob(data, `${report.title}.xlsx`);
    } catch (e) {
      console.error(e);
      if (typeof onDownloadError === 'function') onDownloadError();
    }
  };
  const sortHanler = async (property: string) => {
    if (!layout[property]?.sortField) return;

    let newOrderDirection;
    const sortField = layout[property]?.sortField;
    if (sortField) {
      newOrderDirection =
        orderBy && sortField === orderBy
          ? orderDirection === OrderDirection.ASC
            ? OrderDirection.DESC
            : OrderDirection.ASC
          : OrderDirection.DESC;
      if (report) {
        await fetchReportData(report, sortField, newOrderDirection);
      }
    }
  };

  return (
    <Box>
      <SectionTitle
        button={
          report &&
          onBack && (
            <SubmitButton
              className={styles.backBtn}
              label="Back"
              variant="text"
              onClick={onBack}
              icon={<ArrowBackIcon />}
              iconPosition="left"
            />
          )
        }
        withoutBottomPadding
      />
      <Box className={styles.mainContainer}>
        <ContentContainer
          title={report?.title}
          button={
            report && (
              <SubmitButton
                className={styles.downloadBtn}
                label="Download"
                variant="contained"
                color="secondary"
                onClick={downloadExcel}
              />
            )
          }
        >
          <Box className={styles.wrapper}>
            <TableContainer className={styles.wrapperTable} component={Box}>
              <Table className={styles.reportTable}>
                <TableHead>
                  <TableRow>
                    {fields.map((field) => (
                      <TableCell key={field}>
                        {layout[field].sortField ? (
                          <>
                            <TableSortLabel
                              className={styles.sortCell}
                              active={orderBy === layout[field].sortField}
                              IconComponent={() => (
                                <SvgIcon
                                  className={`${styles.helpIcons} 
                                ${
                                  orderBy === layout[field].sortField &&
                                  orderDirection
                                    ? orderDirection === OrderDirection.ASC
                                      ? styles.asc
                                      : styles.desc
                                    : styles.asc
                                }`}
                                  component={openIcon}
                                  width="11"
                                  height="7"
                                  viewBox="0 0 11 7"
                                />
                              )}
                              direction={
                                orderBy === layout[field].sortField &&
                                orderDirection
                                  ? convertOrderDirection(orderDirection)
                                  : 'asc'
                              }
                              onClick={() => sortHanler(field)}
                            >
                              {layout[field].label}
                            </TableSortLabel>
                          </>
                        ) : (
                          <>{layout[field].label}</>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((record, index) => (
                    <TableRow key={index}>
                      {fields.map((field) => (
                        <TableCell key={field}>
                          {getReadableData(record[field])}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </ContentContainer>
      </Box>
    </Box>
  );
};

export default ReportTable;
