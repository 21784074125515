import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SignaturePad from 'react-signature-canvas';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import styles from './styles.module.scss';

interface SignatureProps {
  sigCanvas: React.RefObject<SignaturePad>;
  setSignatureStored: (value: React.SetStateAction<boolean>) => void;
  minStrokeWidth?: number;
  maxStrokeWidth?: number;
}

const Signature: React.FC<SignatureProps> = ({
  sigCanvas,
  minStrokeWidth,
  maxStrokeWidth,
  setSignatureStored,
}) => {
  const [empty, setEmpty] = useState(true);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  const clear = () => {
    if (sigCanvas) {
      sigCanvas.current?.clear();
      setSignatureStored(false);
      setEmpty(true);
    }
  };
  const lineWidth = { min: 0.5, max: 2.5 };
  if (matchesMd) {
    lineWidth.min = 1;
    lineWidth.max = 5;
  }
  if (matchesLg) {
    lineWidth.min = 1.5;
    lineWidth.max = 7.5;
  }
  if (matchesXl) {
    lineWidth.min = 2;
    lineWidth.max = 10;
  }

  return (
    <Box className={styles.signature}>
      <Box className={styles.signBox}>
        {empty && (
          <Typography className={styles.placeholder}>
            Draw signature here
          </Typography>
        )}
        <SignaturePad
          penColor="#000000"
          ref={sigCanvas}
          minWidth={minStrokeWidth || lineWidth.min}
          maxWidth={maxStrokeWidth || lineWidth.max}
          onBegin={() => {
            setEmpty(false);
          }}
          onEnd={() => {
            setSignatureStored(true);
          }}
          canvasProps={{
            className: styles.sigCanvas,
          }}
        />
      </Box>
      <Box className={styles.footer}>
        <Button className={styles.button} variant="text" onClick={clear}>
          Clear
        </Button>
      </Box>
    </Box>
  );
};

export default Signature;
