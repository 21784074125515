import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { SvgIcon, FormHelperText } from '@material-ui/core';
import { ReactComponent as fileIcon } from 'assets/file.svg';

import fileDomain from 'api/domains/file';
import { OnboardingFile } from 'types/onboarding';
import Backdrop from 'components/Backdrop';
import styles from './styles.module.scss';

interface Props {
  file: OnboardingFile;
  error: boolean;
  setError: (hasError: boolean) => void;
  setIsFileOpened: (isFileOpened: boolean) => void;
}

const File: React.FC<Props> = ({ file, error, setError, setIsFileOpened }) => {
  const [isFileLoading, setFileLoading] = useState(false);

  const handleOpenFileInNewTab = async () => {
    if (file.id) {
      try {
        setFileLoading(true);
        const { data } = await fileDomain.downloadFile(file.id);
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL);
      } catch (err) {
        console.error(err);
      } finally {
        setFileLoading(false);
        // set isFileOpened error to false, in any case
        setError(false);
        setIsFileOpened(true);
      }
    }
    return;
  };

  return (
    <Box className={styles.fileLinkContainer}>
      <Backdrop open={isFileLoading} />
      <SvgIcon
        component={fileIcon}
        className={styles.fileIcon}
        width="19"
        height="21"
        viewBox="0 0 19 21"
      />
      <Link className={styles.fileLink} onClick={handleOpenFileInNewTab}>
        {file.title}
      </Link>
      {error && (
        <FormHelperText className={styles.errorMessage} error={error}>
          File is required to be opened
        </FormHelperText>
      )}
    </Box>
  );
};

export default File;
